import React, { memo } from "react";

const Delete = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M14.414 3.086h-3.453v-.947A1.783 1.783 0 009.188.35H6.812a1.783 1.783 0 00-1.773 1.789v.947H1.586a.591.591 0 000 1.182h.8L3.4 15.6h9.2l1.014-11.332h.8a.591.591 0 000-1.182zm-8.2-.947a.606.606 0 01.6-.608h2.374a.606.606 0 01.6.608v.947H6.21zm5.319 12.279H4.472l-.91-10.15h8.876z"
          fill="#ef3340"
        />
      </g>
    </svg>
  );
};

export default memo(Delete);
