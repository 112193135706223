import React, { forwardRef } from "react";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import classnames from "classnames";
import styles from "./FullscreenModal.module.scss";

export type IFullscreenModalProps = ModalProps & Partial<SlideProps>;

/**
 * FullscreenModal
 * Mainly used in Mobile layout to give the illusion of stacking a new page
 * without using the router
 * The open/close can be animated using the direction props
 */
function FullscreenModal({
  children,
  open,
  direction,
  className,
  ...otherProps
}: IFullscreenModalProps) {
  return (
    <Modal
      closeAfterTransition
      BackdropProps={{
        className: styles.backdrop
      }}
      open={open}
      {...otherProps}
    >
      {direction ? (
        <Slide direction={direction} in={open} {...otherProps}>
          <ContentWrapper className={className}>{children}</ContentWrapper>
        </Slide>
      ) : (
        <ContentWrapper className={className}>{children}</ContentWrapper>
      )}
    </Modal>
  );
}

const ContentWrapper = forwardRef(
  (
    { children, className }: Pick<ModalProps, "children" | "className">,
    ref
  ) => {
    return (
      <div
        className={classnames(styles.contentContainer, className)}
        ref={ref as any}
        tabIndex={-1}
      >
        <>{children}</>
      </div>
    );
  }
);

export default FullscreenModal;
