import { useSelector, useDispatch } from "react-redux";

import * as ratingsActions from "actions/RatingActions";
import * as snackbarActions from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { AppState } from "reducers";
import { Rating } from "reducers/rating";
import useCurrentUser from "hooks/useCurrentUser";

export const useRatingReplier = () => {
  const { getAuthInfos } = useCurrentUser();
  const { roles = [], userId } = getAuthInfos();
  const selectedSalonId = useSelector(
    (state: AppState) => state.salon.payload.selectedSalonId
  );
  let respondentId: number | undefined;
  let role: "USER" | "SALON" | undefined;
  if (roles.includes("USER")) {
    if (!userId) {
      throw new Error("The user infos are not consistent with its role");
    }
    respondentId = userId;
    role = "USER";
  }
  if (roles.includes("SALON") || roles.includes("SALON_MANAGER")) {
    respondentId = selectedSalonId;
    role = "SALON";
  }
  const dispatch = useDispatch();

  async function ratingReplier(rating: Rating, reply: string) {
    if (role && respondentId) {
      try {
        await dispatch(
          ratingsActions.replyToRating(rating.id, reply, respondentId, role)
        );
        dispatch(
          snackbarActions.pushMessage(
            "Votre réponse a bien été envoyée",
            MESSAGE_TYPES.SUCCESS
          )
        );
        return true;
      } catch (e) {
        dispatch(snackbarActions.pushMessage(e, MESSAGE_TYPES.ERROR));
      }
    } else {
      dispatch(
        snackbarActions.pushMessage(
          `Imposible de commenter en tant que ${roles[0]}`,
          MESSAGE_TYPES.WARNING
        )
      );
    }
    return false;
  }

  function replyToRating(rating: Rating) {
    return function curriedRatingReplier(reply: string) {
      return ratingReplier(rating, reply);
    };
  }
  return {
    ratingReplier,
    replyToRating
  };
};
