import { Rating, RatingState } from "../reducers/rating";
import { getFromCache } from "./cache";
import { Salon, Hairdresser } from "../reducers/salon";
import {
  query,
  safeError,
  mutation,
  callWithPagination,
  objToQueryArgs,
  IPaginationOptions,
} from "./graphqlClient";

const ratingGraphQlSignature = `
id,
${process.env.NODE_ENV === "production" ? "" : "comment,"}
${process.env.NODE_ENV === "production" ? "" : "rate,"}
user{
  id,
  firstName,
  lastName,
  gender,
  mobile,
  username,
},
hairdresser{
  id,
  firstName,
  lastName,
  stringId,
},
salon{
  id,
},
booking{
  id,
  status,
  cancellationReason,
  cancellationOrigin,
  dateStart,
  user{
    id,
    firstName,
    lastName,
    gender,
    phone,
    mobile,
    username,
  },
  snapshotPackages{
    id,
    status,
    name,
    description,
    category{
      id,
      name,
    },
    price{
      price,
      currency,
      variant{
        id,
        name,
      },
    },
  },
},
replies{
  id,
  status,
  content,
  role,
  respondentId,
  createdAt,
  updatedAt,
},
userRate,
hairdresserRate,
salonRate,
hairdresserComment,
userComment,
salonComment,
createdAt,
updatedAt,
`.replace(/\s+/g, "");

const graphqlRatingToStoreRating = (graphqlRating: any): Rating => {
  const rating: Rating = {
    id: graphqlRating.id,
    comment: graphqlRating.comment,
    rate: graphqlRating.rate,
    replies: graphqlRating.replies || [],
    hairdresser: graphqlRating.hairdresser,
    salonId: graphqlRating.salon && graphqlRating.salon.id,
    user: graphqlRating.user,
    booking: graphqlRating.booking,
    hairdresserRate: graphqlRating.hairdresserRate,
    hairdresserComment: graphqlRating.hairdresserComment,
    userRate: graphqlRating.userRate,
    userComment: graphqlRating.userComment,
    salonRate: graphqlRating.salonRate,
    salonComment: graphqlRating.salonComment,
    createdAt: graphqlRating.createdAt,
    updatedAt: graphqlRating.updatedAt,
  };
  return rating;
};

export const fetchRatingsByHairdresserId = (
  companyCode: string,
  hairdresserId: number,
  hairdresserStringId: string,
  options?: IPaginationOptions
) =>
  callWithPagination(
    (hairdresserId: number) => async (offset: number, limit: number) => {
      const args = objToQueryArgs({
        hairdresserId: Number(hairdresserId),
        hairdresserStringId: hairdresserStringId
          ? `${companyCode}|${hairdresserStringId}`
          : undefined,
        hairdresserRateMin: 0,
        status: "VOTED",
        limit,
        offset,
        sort: "-createdAt",
      });
      const res = await query(`ratings(${args}){${ratingGraphQlSignature}}`);
      const body = await res.json();
      console.assert(!body.errors, `${JSON.stringify(body.errors)}`);
      return res.ok
        ? Promise.resolve(
            (body.data.ratings &&
              body.data.ratings.reduce(
                (ratingsById: any, rating: any) => ({
                  ...ratingsById,
                  [rating.id]: graphqlRatingToStoreRating(rating),
                }),
                {}
              )) ||
              {}
          )
        : Promise.reject(safeError(body));
    },
    options && options.offset,
    options && options.limit,
    options
  )(hairdresserId);

export const fetchRatingsBySalonId = (
  salonId: number,
  options?: IPaginationOptions
) =>
  callWithPagination(
    (salonId: number) => async (offset: number, limit: number) => {
      const args = objToQueryArgs({
        salonId,
        salonRateMin: 0,
        status: "VOTED",
        limit,
        offset,
        sort: "-createdAt",
      });
      const res = await query(`ratings(${args}){${ratingGraphQlSignature}}`);
      const body = await res.json();
      console.assert(!body.errors, `${JSON.stringify(body.errors)}`);
      return res.ok
        ? Promise.resolve(
            (body.data.ratings &&
              body.data.ratings.reduce(
                (ratingsById: any, rating: any) => ({
                  ...ratingsById,
                  [rating.id]: graphqlRatingToStoreRating(rating),
                }),
                {}
              )) ||
              {}
          )
        : Promise.reject(safeError(body));
    },
    options && options.offset,
    options && options.limit,
    options
  )(salonId);

export const fetchSalonRatingsStats = async (salon: Salon) => {
  const sumArgs = objToQueryArgs({
    salonId: salon.id,
    summedField: "salonRate",
    status: "VOTED",
    salonRateMin: 1,
  });
  const countArgs = objToQueryArgs({
    salonId: salon.id,
    status: "VOTED",
    salonRateMin: 1,
  });
  const sumRes = await query(`sumRatings(${sumArgs}){sum}`);
  const sumBody = await sumRes.json();
  const countRes = await query(`countRatings(${countArgs}){count}`);
  const countBody = await countRes.json();

  console.assert(
    !sumBody.errors && !countBody.errors,
    `${JSON.stringify(sumBody.errors)}\n${JSON.stringify(countBody.errors)}`
  );
  if (sumRes.ok && countRes.ok) {
    const stats = {
      [salon.id]: {
        sum: sumBody?.data?.sumRatings?.sum,
        count: countBody?.data?.countRatings?.count,
      },
    };

    return Promise.resolve(stats);
  } else if (!sumRes.ok) {
    return Promise.reject(safeError(sumBody));
  } else if (!countRes.ok) {
    return Promise.reject(safeError(countBody));
  }
  return Promise.reject("Erreur innatendue");
};

export const fetchHairdresserRatingsStats = async (
  companyCode: string,
  hairdresser: Hairdresser
) => {
  const sumArgs = objToQueryArgs({
    hairdresserId: Number(hairdresser.id),
    hairdresserStringId: hairdresser.stringId
      ? `${companyCode}|${hairdresser.stringId}`
      : undefined,
    summedField: "hairdresserRate",
    status: "VOTED",
    hairdresserRateMin: 1,
  });
  const countArgs = objToQueryArgs({
    hairdresserId: Number(hairdresser.id),
    hairdresserStringId: hairdresser.stringId
      ? `${companyCode}|${hairdresser.stringId}`
      : undefined,
    status: "VOTED",
    hairdresserRateMin: 1,
  });
  const sumRes = await query(`sumRatings(${sumArgs}){sum}`);
  const sumBody = await sumRes.json();
  const countRes = await query(`countRatings(${countArgs}){count}`);
  const countBody = await countRes.json();

  console.assert(
    !sumBody.errors && !countBody.errors,
    `${JSON.stringify(sumBody.errors)}\n${JSON.stringify(countBody.errors)}`
  );
  if (sumRes.ok && countRes.ok) {
    const stats = {
      [hairdresser.id || Number(hairdresser.stringId)]: {
        sum: sumBody.data.sumRatings.sum,
        count: countBody.data.countRatings.count,
      },
    };

    return Promise.resolve(stats);
  } else if (!sumRes.ok) {
    return Promise.reject(safeError(sumBody));
  } else if (!countRes.ok) {
    return Promise.reject(safeError(countBody));
  }
  return Promise.reject("Erreur innatendue");
};

const createRatingReply = async (
  ratingId: number,
  reply: string,
  respondentId: number,
  role: string
) => {
  const res = await mutation(
    "createReply",
    {
      ratingId: {
        type: "Int",
        value: ratingId,
      },
      content: {
        type: "String",
        value: reply,
      },
      respondentId: {
        type: "Int",
        value: respondentId,
      },
      role: {
        type: "String",
        value: role,
      },
      status: {
        type: "String",
        value: "ENABLED",
      },
    },
    "id, status, content, role, respondentId, createdAt, updatedAt"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.createReply)
    : Promise.reject(safeError(body));
};

export type RatingStats = {
  ratings: Array<Rating>;
  sumRatingsOutOfTen: number;
  numberOfRatings: number;
  meanRatingsOutOfTen: number;
};

const getRatingCache = () => {
  return getFromCache("rating");
};

const getHairdresserRatings = (
  hairdresserId: number,
  hairdresserStringId: string,
  ratingState: RatingState
): Array<Rating> => {
  const ratingById = ratingState.payload.ratingById;
  return Object.values(ratingById).filter(
    (rating) =>
      rating.hairdresser &&
      ((rating.hairdresser.id && rating.hairdresser.id === hairdresserId) ||
        rating.hairdresser.stringId === hairdresserStringId) &&
      (rating.hairdresserRate ?? -1) > 0
  );
};

const getSalonRatings = (
  salonId: number | null,
  ratingState: RatingState
): Array<Rating> => {
  const ratingById = ratingState.payload.ratingById;
  return Object.values(ratingById).filter(
    (rating) => rating.salonId === salonId && (rating.salonRate ?? -1) > 0
  );
};

export {
  getRatingCache,
  createRatingReply,
  getHairdresserRatings,
  getSalonRatings,
};
