import { LastMinute } from "reducers/lastMinute";
import { query, mutation, safeError } from "utils/graphqlClient";

const lastMinuteFields = `
    id,
    hairdresserIds,
    hairdresserStringIds,
    salonId,
    discount,
    minutesBefore,
    createdAt,
    status,
    days {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
    }
`;

export const fetchBySalonId = async (salonId: number) => {
  const res = await query(
    `lastMinutes(salonId: ${salonId}, sort: "id", limit: -1){${lastMinuteFields}}`
  );

  const body = await res.json();

  if (res.ok) {
    return {
      lastMinutes:
        body?.data?.lastMinutes.filter(
          (lastMinute: LastMinute) =>
            lastMinute.discount > 0 && lastMinute.status !== "DELETED"
        ) || [],
    };
  }

  return Promise.reject(safeError(body));
};

export const createLastMinute = async (lastMinute: LastMinute) => {
  const res = await mutation(
    "createLastMinute",
    {
      hairdresserIds: {
        type: "[Int]",
        value: lastMinute.hairdresserIds,
      },
      hairdresserStringIds: {
        type: "[String]",
        value: lastMinute.hairdresserStringIds,
      },
      salonId: {
        type: "Int",
        value: lastMinute.salonId,
      },
      discount: {
        type: "Int",
        value: lastMinute.discount,
      },
      minutesBefore: {
        type: "Int",
        value: lastMinute.minutesBefore,
      },
      status: {
        type: "String",
        value: lastMinute.status,
      },
      days: {
        type: "LastMinuteDaysInput",
        value: lastMinute.days,
      },
    },
    lastMinuteFields
  );
  const body = await res.json();

  if (res.ok) {
    return true;
  }

  return Promise.reject(safeError(body));
};

export const updateLastMinute = async (lastMinute: LastMinute) => {
  const res = await mutation(
    "updateLastMinute",
    {
      id: {
        type: "Int",
        value: lastMinute.id,
      },
      hairdresserIds: {
        type: "[Int]",
        value: lastMinute.hairdresserIds,
      },
      hairdresserStringIds: {
        type: "[String]",
        value: lastMinute.hairdresserStringIds,
      },
      salonId: {
        type: "Int",
        value: lastMinute.salonId,
      },
      discount: {
        type: "Int",
        value: lastMinute.discount,
      },
      minutesBefore: {
        type: "Int",
        value: lastMinute.minutesBefore,
      },
      status: {
        type: "String",
        value: lastMinute.status,
      },
      days: {
        type: "LastMinuteDaysInput",
        value: lastMinute.days,
      },
    },
    lastMinuteFields
  );
  const body = await res.json();

  if (res.ok) {
    return true;
  }

  return Promise.reject(safeError(body));
};
