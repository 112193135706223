import React from "react";
import {
  useWheelPickerContext,
  SELECTION_LINES_HEIGHT,
  SELECTION_LINES_TOP,
  PICKER_WHEELS_HEIGHT
} from ".";
import useClickAway from "react-use/lib/useClickAway";
import Collapse from "@material-ui/core/Collapse";

/**
 * WheelPicker.Wheels
 * Wraps all the WheelPicker.Wheel
 * Its main purpose is to manage the positionning of the wheels
 * and to close the wheels when clicking outside
 * @param props
 */
const Wheels: React.FC = props => {
  const { children } = props;
  const {
    isWheelsOpened,
    toggleIsWheelsOpened,
    floatingWheels: floating,
    wheelPickerRef
  } = useWheelPickerContext();

  useClickAway(wheelPickerRef as any, () => {
    if (isWheelsOpened) {
      toggleIsWheelsOpened && toggleIsWheelsOpened();
    }
  });

  const containerStyle: {
    height: string;
    visibility: "visible" | "hidden";
    opacity: number;
  } = {
    height: PICKER_WHEELS_HEIGHT,
    visibility: "visible",
    opacity: 1
  };
  if (floating && !isWheelsOpened) {
    containerStyle.height = "0px";
    containerStyle.visibility = "hidden";
    containerStyle.opacity = 0;
  }

  return (
    <Collapse in={isWheelsOpened}>
      <div
        className={`wheel-picker_wheels_container ${
          floating ? "floating" : ""
        }`}
        style={containerStyle}
      >
        {children}
        <div
          className="wheel-picker_selection-lines"
          style={{
            height: SELECTION_LINES_HEIGHT,
            top: SELECTION_LINES_TOP
          }}
        />
      </div>
    </Collapse>
  );
};

export default Wheels;
