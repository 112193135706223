import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateSalon } from "actions/SalonActions";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import AccountSalonPage from "components/account/SalonPage/SalonPage";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Access, Salon, PartnerRef } from "reducers/salon";
import { mergeEventToForm } from "utils/form";
import { getNewPartnerRefList } from "utils/package";

const buildFormData = (salon: Salon | null) => ({
  id: salon && salon.id,
  name: salon ? salon.name : "",
  description: salon ? salon.description : "",
  address: salon ? salon.address : { address: "", city: "", zipCode: "" },
  geoLocation: salon ? salon.geoLocation : { lat: 0, lng: 0 },
  recommendation: salon ? salon.recommendation : "",
  logo: salon ? salon.logo : "",
  access: salon && salon.access ? salon.access : [],
  backgrounds: salon ? salon.backgrounds : [],
  carouselImages: salon ? salon.carouselImages : [],
  partnerRefs: salon?.partnerRefs || [],
  allowPaymentInSalon: salon?.allowPaymentInSalon || false,
});

function AccountSalonPageContainer() {
  const history = useHistory();
  const { salon } = useCurrentSalon();

  const [formData, setFormData] = useState(buildFormData(salon));

  useEffect(() => {
    setFormData(buildFormData(salon));
  }, [salon]);

  const updateFormData = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist && event.persist();
      setFormData((oldFormData) => mergeEventToForm(event, oldFormData));
    },
    []
  );

  const updatePartnerRefFormData = (name: string, id: string) =>
    setFormData({
      ...formData,
      partnerRefs: getNewPartnerRefList(formData.partnerRefs, name, id, true),
    });

  const dispatch = useDispatch();
  const { isMobile } = useResponsiveLayout();
  const onClose = history.goBack;
  const onSubmit = useCallback(() => {
    return salon
      ? ((dispatch(
          updateSalon(salon.id, {
            ...salon,
            ...formData,
            partnerRefs: formData.partnerRefs.filter(
              (item: PartnerRef) => item.id !== ""
            ),
            id: salon.id,
          })
        ) as unknown) as Promise<any>).then(() => {
          dispatch(
            pushMessage(
              "L'établissement a bien été mis à jour",
              MESSAGE_TYPES.SUCCESS
            )
          );
          isMobile && onClose();
        })
      : Promise.reject();
  }, [salon, formData, onClose]);

  const addAccess = useCallback(
    (newAccess: Access) => {
      setFormData((oldFormData) => {
        const newAccesses = JSON.parse(JSON.stringify(oldFormData.access));
        newAccesses.push(newAccess);

        return {
          ...oldFormData,
          access: newAccesses,
        };
      });
    },
    [setFormData]
  );

  const removeAccessByIndex = useCallback(
    (index: number) => {
      setFormData((oldFormData) => {
        const newAccesses = [
          ...oldFormData.access.slice(0, index),
          ...oldFormData.access.slice(index + 1),
        ];

        return {
          ...oldFormData,
          access: newAccesses,
        };
      });
    },
    [setFormData]
  );

  return (
    <React.Fragment>
      <AccountSalonPage
        onClose={onClose}
        formData={formData}
        updateFormData={updateFormData}
        addAccess={addAccess}
        removeAccessByIndex={removeAccessByIndex}
        onSubmit={onSubmit}
        salon={salon}
        updatePartnerRefFormData={updatePartnerRefFormData}
      />
    </React.Fragment>
  );
}

export default AccountSalonPageContainer;
