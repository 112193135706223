import { ISubLink } from "components/Menu/Desktop/MenuItem/MenuItem.desktop";
import { ISidebarLink } from "./SidebarLinks";

export function desktopMenuSublinkToSidebarLink(
  menuSublink: ISubLink
): ISidebarLink {
  let sidebarLink: ISidebarLink = {
    label: menuSublink.title,
    route: "#",
    conditions: menuSublink.conditions,
  };
  if ("link" in menuSublink && menuSublink.link) {
    sidebarLink.route = menuSublink.link;
  }
  return sidebarLink;
}
