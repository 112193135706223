import React from "react";
import { WHITE } from "../../constants/Style";

const PlusIcon = ({ color, height = 21, ...props }: any) => {
  return (
    <svg width={height} height={height} viewBox="0 0 21 21" {...props}>
      <g
        fillRule="nonzero"
        stroke={color || WHITE}
        strokeWidth={2}
        fill="none"
        strokeLinecap="round"
      >
        <path d="M10.254 1.521l.121 18M19.57 10.838l-17.999-.121" />
      </g>
    </svg>
  );
};

export default PlusIcon;
