import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

function TextInput({
  name,
  InputLabelProps,
  InputProps,
  inputProps,
  ...otherProps
}: TextFieldProps) {
  return (
    <TextField
      variant="standard"
      type="text"
      className="input-group"
      name={name}
      {...otherProps}
      InputLabelProps={{
        shrink: true,
        className: "input_label",
        htmlFor: name,
        ...InputLabelProps
      }}
      InputProps={{
        className: "input_input",
        ...InputProps
      }}
      // The material-ui API has duplicate props, differing only in casing
      // eslint is not happy with it...
      // eslint-disable-next-line
      inputProps={{
        id: name,
        ...inputProps
      }}
    />
  );
}

export default TextInput;
