import React from "react";
import { hasResponsiveUrlParameter } from "./utils";
import useResponsiveLayout from "./useResponsiveLayout";

interface RenderIfProps {
  isMobile: boolean;
  hasResponsiveUrlParameter: boolean;
}

interface MobileProps {
  children: React.ReactNode;
  renderIf?(args: RenderIfProps): boolean;
}

function renderIfMobile({ isMobile }: RenderIfProps) {
  return isMobile;
}

function Mobile({ children, renderIf = renderIfMobile }: MobileProps) {
  const { isMobile } = useResponsiveLayout();

  return renderIf({
    isMobile,
    get hasResponsiveUrlParameter() {
      return hasResponsiveUrlParameter();
    },
  }) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : null;
}

export default Mobile;
