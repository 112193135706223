import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { updateSalon, updateBroadcastList } from "actions/SalonActions";
import { pushMessage } from "actions/SnackbarActions";
import AccountSettingsPage from "components/account/SettingsPage/SettingsPage";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import { MESSAGE_TYPES } from "constants/SnackBar";
import useCurrentSalon from "hooks/useCurrentSalon";
import { mergeEventToForm } from "utils/form";
import { AppState } from "reducers";
import { Salon } from "reducers/salon";
import useCurrentUser from "hooks/useCurrentUser";

const isLoadingSelector = (state: AppState) =>
  state.salon.loading || state.auth.loading;

const buildFormData = (salon: Salon, connexionEmail = "") => ({
  connexionEmail,
  emailCopy: salon.broadcastList?.client?.[0] ?? "",
  emailCsv: salon.broadcastList?.csv?.[0] ?? "",
  phone: salon.phone ?? "",
  mobile: salon.mobile ?? "",
  hairdresserCopyEmails: salon.broadcastList?.hairdresser ?? [],
  invoiceCopyEmails: salon.broadcastList?.bill ?? [],
  reportingCopyEmails: salon.broadcastList?.report ?? [],
});

function AccountSettingsPageContainer() {
  const history = useHistory();
  const onClose = history.goBack;

  const { salon } = useCurrentSalon();
  const isLoading = useSelector(isLoadingSelector);
  const { getAuthInfos } = useCurrentUser();
  const connexionEmail = getAuthInfos().username;

  const [formData, setFormData] = useState(
    buildFormData(salon, connexionEmail)
  );
  useEffect(() => {
    setFormData(buildFormData(salon, connexionEmail));
  }, [salon.id, connexionEmail]);

  const updateFormData = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist && event.persist();
      setFormData((oldFormData) => mergeEventToForm(event, oldFormData));
    },
    [setFormData]
  );

  const addItem = useCallback(
    (name: string, newItem: any) => {
      setFormData((oldFormData: any) => {
        const newArray = JSON.parse(JSON.stringify(oldFormData[name]));
        newArray.push(newItem);

        return {
          ...oldFormData,
          [name]: newArray,
        };
      });
    },
    [setFormData]
  );

  const removeItemByIndex = useCallback(
    (name: string, index: number) => {
      setFormData((oldFormData: any) => {
        const oldArray = oldFormData[name];
        const newArray = [
          ...oldArray.slice(0, index),
          ...oldArray.slice(index + 1),
        ];

        return {
          ...oldFormData,
          [name]: newArray,
        };
      });
    },
    [setFormData]
  );

  const { isMobile } = useResponsiveLayout();
  const dispatch = useDispatch();
  const onSubmit = useCallback(() => {
    Promise.all([
      dispatch(
        updateSalon(salon.id, {
          ...salon,
          phone: formData.phone,
          mobile: formData.mobile,
        })
      ),
      dispatch(
        updateBroadcastList({
          id: salon.broadcastList.id,
          csv: [formData.emailCsv],
          bill: formData.invoiceCopyEmails,
          client: [formData.emailCopy],
          report: formData.reportingCopyEmails,
          hairdresser: formData.hairdresserCopyEmails,
        })
      ),
    ])
      .then(() => {
        dispatch(
          pushMessage("Le compte a bien été mis à jour", MESSAGE_TYPES.SUCCESS)
        );
      })
      .finally(() => {
        isMobile && onClose();
      });
  }, [onClose, salon.id, formData, isMobile]);
  return (
    <AccountSettingsPage
      formData={formData}
      updateFormData={updateFormData}
      addItem={addItem}
      removeItemByIndex={removeItemByIndex}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isLoading}
    />
  );
}

export default AccountSettingsPageContainer;
