import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import styles from "./MobileBottomDrawer.module.scss";
import CloseIcon from "../../../svgs/CloseIcon";

interface MobileBottomDrawerProps {
  children: React.ReactChild;
  title?: React.ReactNode;
  open: boolean;
  onClose(): void;
  onOpen(): void;
}

function MobileBottomDrawer({
  children,
  title,
  open,
  onClose,
  onOpen
}: MobileBottomDrawerProps) {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      classes={{ paper: styles.paper }}
      onClose={onClose}
      onOpen={onOpen}
      disableDiscovery={onOpen === nullFunction}
      disableSwipeToOpen={onOpen === nullFunction}
    >
      <div onClick={onClose} className={styles.close}>
        <CloseIcon />
      </div>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>{children}</div>
    </SwipeableDrawer>
  );
}

function nullFunction() {}

MobileBottomDrawer.defaultProps = {
  onClose: nullFunction,
  onOpen: nullFunction
};

export default MobileBottomDrawer;
