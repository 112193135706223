import React from "react";
import moment from "moment";
import Paper from "@material-ui/core/Paper";

import { WHITE, OPACITY } from "constants/Style";
import { AGENDA_EVENT_KINDS } from "components/agenda/AgendaPage/AgendaPage";
import { CalendarEvent } from "components/Calendar";
import { Booking } from "reducers/booking";
import { Unavailability } from "reducers/unavailability";
import { titleize } from "utils/string";
import { Slot } from "reducers/slot";
import { CalendarEventRendererProps } from "components/Calendar/CalendarColumnEvent";
import CoinsIcon from "components/svgs/Coins";
import { Availability } from "reducers/week";
import { LastMinute } from "reducers/lastMinute";

export interface ISlotEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.SLOT;
  slot: Slot;
}

export interface IBookingEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.BOOKING;
  booking: Booking;
}

export interface ICreatingEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.CREATING;
  partialSlot: Omit<Slot, "id">;
}

export interface IUnavailableEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.UNAVAILABLE;
  unavailability: Unavailability;
}

export interface IAvailableEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.AVAILABLE;
  availability: Availability;
}

export interface ILastMinuteEventMeta {
  kind: typeof AGENDA_EVENT_KINDS.LAST_MINUTE;
  lastMinute?: LastMinute;
}

export type IEventMeta =
  | ISlotEventMeta
  | IBookingEventMeta
  | ICreatingEventMeta
  | IUnavailableEventMeta
  | IAvailableEventMeta
  | ILastMinuteEventMeta;

export default function CalendarEventRenderer({
  event,
  color,
}: CalendarEventRendererProps<IEventMeta>) {
  const eventMeta = event.meta;
  let eventElement = null;
  switch (eventMeta.kind) {
    case AGENDA_EVENT_KINDS.SLOT:
      eventElement = <SlotRenderer color={color} slot={eventMeta.slot} />;
      break;
    case AGENDA_EVENT_KINDS.BOOKING:
      eventElement = (
        <BookingRenderer color={color} booking={eventMeta.booking} />
      );
      break;
    case AGENDA_EVENT_KINDS.CREATING:
      eventElement = <CreatingRenderer event={{ ...event, meta: eventMeta }} />;
      break;
    case AGENDA_EVENT_KINDS.UNAVAILABLE:
      eventElement = (
        <UnavailableRenderer event={{ ...event, meta: eventMeta }} />
      );
      break;
    case AGENDA_EVENT_KINDS.AVAILABLE:
      eventElement = (
        <AvailableRenderer
          color={color}
          availability={eventMeta.availability}
        />
      );
      break;
    case AGENDA_EVENT_KINDS.LAST_MINUTE:
      eventElement = (
        <LastMinuteRenderer
          color={color}
          event={{ ...event, meta: eventMeta }}
        />
      );
      break;
  }
  return <React.Fragment>{eventElement}</React.Fragment>;
}

export const BookingRenderer = ({
  booking,
  color,
}: {
  booking: Booking;
  color?: string;
}) => {
  const { user = { firstName: "", lastName: "" }, paymentType } = booking;
  return (
    <div
      className="agenda_slot agenda_slot-booked"
      style={{
        backgroundColor: color || "#06038d",
        backgroundImage: "none",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <div>
        <span className="agenda_slot-booked-firstname">
          {`${moment(booking.dateStart).format("HH:mm")} - ${moment(
            booking.dateEnd
          ).format("HH:mm")}`}
        </span>
        {paymentType === "IN_SALON" ? (
          <div className="agenda-inSalonContainer">
            <CoinsIcon color={WHITE} />
            <span className="agenda-inSalon">Paiement en salon</span>
          </div>
        ) : null}
        <span className="agenda_slot-booked-slotrange">
          {user.firstName} {user.lastName}
        </span>
      </div>
      <div>
        <span className="agenda_slot-booked-package">
          {booking.snapshotPackages[0].name}
        </span>
        <span className="agenda_slot-booked-discount">
          {booking.discount ? `-${booking.discount}%` : "Plein tarif"}
        </span>
      </div>
    </div>
  );
};

const getSlotBackgroundImage = (
  backgroundColor: string,
  stripeColor: string
) => {
  return `repeating-linear-gradient(
    -45deg,
    ${backgroundColor},
    ${backgroundColor} 7px,
    ${stripeColor} 7px 9px
  )`;
};

const repeatDiscount = (slot: Slot) => {
  const nbRepeat =
    moment(slot.range.end).diff(moment(slot.range.start), "hours") * 2 || 1;
  const rawArrayLength = Number((nbRepeat + 1).toFixed(0));
  return [...Array(rawArrayLength > -1 ? rawArrayLength : 0).keys()].map(
    (index) => (
      <span key={index} className={slot.discount ? "agenda_slot_discount" : ""}>
        {slot.discount ? `-${slot.discount}%` : "SANS promo"}
        <br />
        <br />
      </span>
    )
  );
};

export const AvailableRenderer = ({
  color,
  availability,
}: {
  color: string;
  availability: Availability;
}) => {
  return (
    <div
      title={
        availability.discount === 0
          ? "Disponible"
          : `-${availability.discount}%`
      }
      className="agenda_slot agenda_slot-free agenda_availability"
      style={{
        color: availability.discount === 0 ? color : "white",
        borderColor: color,
        backgroundColor: availability.discount === 0 ? "white" : color,
      }}
    >
      <div>
        {availability.discount === 0
          ? "DISPONIBLE"
          : `-${availability.discount}%`}
      </div>
    </div>
  );
};

export const SlotRenderer = ({
  color,
  slot,
}: {
  color: string;
  slot: Slot;
}) => {
  return (
    <div
      className="agenda_slot agenda_slot-free"
      style={{
        color: WHITE,
        borderColor: "rgba(0,0,0,0)",
        backgroundColor: `${color}`,
      }}
    >
      {repeatDiscount(slot)}
    </div>
  );
};

export const CreatingRenderer = ({
  event,
}: {
  event: CalendarEvent<ICreatingEventMeta>;
}) => {
  const startTime = event.start.format("HH:mm");
  const endTime = event.end.format("HH:mm");
  return (
    <Paper elevation={5} className="agenda_slot agenda_slot-creating">
      <span>Créer une dispo</span>
      <span>
        {startTime} - {endTime}
      </span>
    </Paper>
  );
};

export const UnavailableRenderer = ({
  event,
}: {
  event: CalendarEvent<IUnavailableEventMeta>;
}) => {
  const startTime = event.start.format("HH:mm");
  const endTime = event.end.format("HH:mm");
  const unavailability = event.meta.unavailability;
  const title = titleize(unavailability.partnerRef.name ?? "Indisponible");
  return <div className="agenda_slot agenda_slot-unavailable"></div>;
};

export const LastMinuteRenderer = ({
  color,
  event,
}: {
  color: string;
  event: CalendarEvent<ILastMinuteEventMeta>;
}) => {
  return (
    <div
      title={`LastMinute -${event.discount}%`}
      className="agenda_slot agenda_slot-free agenda_availability"
      style={{
        color: "white",
        borderColor: color,
        backgroundColor: color,
      }}
    >
      <div>Last Minute -{event.discount}%</div>
    </div>
  );
};
