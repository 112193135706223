import React from "react";
import { Booking } from "../../reducers/booking";
import { SALMON } from "constants/Style";

interface BookingPaymentTypeProps {
  booking: Booking;
}

const BookingPaymentType = ({
  booking: { paymentType },
}: BookingPaymentTypeProps) => (
  <div className="input-group">
    <label className="input_label">
      {paymentType === "IN_SALON" ? (
        <span style={{ color: SALMON }}>
          N'oubliez pas ! Ce RDV doit être payé en salon par le client.
        </span>
      ) : (
        "Paiement en ligne"
      )}
    </label>
  </div>
);

export default BookingPaymentType;
