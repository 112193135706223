import React from "react";
import classnames from "classnames";

interface LogoLeCiseauProps extends React.SVGProps<SVGSVGElement> {
  height?: number;
  withCircle?: boolean;
}

const LogoLeCiseau = ({
  height: heightProps,
  color: colorProps,
  withCircle,
  ...props
}: LogoLeCiseauProps) => {
  const circleHeigt = heightProps || 15;
  const height = withCircle ? circleHeigt * (31 / 54) : circleHeigt;
  const width = height * (11 / 15);

  const circleStyle = withCircle
    ? {
        height: circleHeigt,
        width: circleHeigt,
        borderRadius: Math.ceil(circleHeigt / 2)
      }
    : undefined;
  const circleClassNames = classnames({
    "logo-circle": withCircle
  });
  return (
    <div style={circleStyle} className={circleClassNames}>
      <svg width={width} height={height} viewBox="0 0 11 15" {...props}>
        <g fill={colorProps || "#FFF"} fillRule="evenodd">
          <path d="M6.348 2.981c.222-.785 1.02-1.239 1.785-1.007.76.23 1.198 1.06.974 1.844-.224.79-1.025 1.237-1.784 1.007-.765-.233-1.2-1.053-.975-1.844m-1.4 1.19l.983 4.57 3.78-3.2a3.02 3.02 0 0 0 .784-1.303c.449-1.58-.426-3.233-1.955-3.696-1.53-.464-3.13.44-3.579 2.02a3.102 3.102 0 0 0-.012 1.61" />
          <path d="M2.406.555C.884 1.041.033 2.708.504 4.281c.155.513.445.942.801 1.29L5.26 8.4l-.88-4.069c-.173.222-.41.395-.696.487-.756.241-1.563-.193-1.799-.98-.235-.78.193-1.616.948-1.859a1.409 1.409 0 0 1 1.55.499c.007-.029.01-.059.02-.087.137-.487.38-.919.685-1.291A2.805 2.805 0 0 0 2.406.555M4.053 11.866c.08.347.152.674.21.97.04.2.072.387.096.555.2 1.41.842 1.578 1.075 1.59V9.804l-2.5-2.04c.216.731.757 2.567 1.12 4.103M8.295 7.76l-1.72 1.447-.708.597v5.178c.226-.015.843-.187 1.044-1.591.023-.166.056-.353.094-.555.057-.293.126-.618.206-.961.338-1.471.838-3.256 1.084-4.115" />
        </g>
      </svg>
    </div>
  );
};

export default LogoLeCiseau;
