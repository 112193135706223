import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as EmailValidator from "email-validator";

import DesktopModalContainer from "../modal/Container/Container.desktop_old";
import { parseRoute } from "../../utils/route";
import { routeList } from "../../constants/Routes";
import TextField from "../inputs/Text";
import ButtonFab from "../button/Fab";
import { useForm } from "../../hooks/useForm";
import "./signup-page.scss";
import Mobile from "../ResponsiveLayout/Mobile";
import Desktop from "../ResponsiveLayout/Desktop";
import ExternalPageLayoutMobile from "../layouts/ExternalPageLayout/ExternalPageLayout.mobile";
import ExternalPageLayoutDesktop from "../layouts/ExternalPageLayout/ExternalPageLayout.desktop";

export type SalonInfos = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  name: string;
  postalCode: string;
};

interface SignupPageProps {
  onSubmit(salonInfos: SalonInfos): Promise<void>;
}

const signupFormValidator = (values: SalonInfos) => {
  const errors: Partial<SalonInfos> = {};
  Object.entries(values).forEach(([name, value]) => {
    if (value.length === 0) {
      errors[name as keyof SalonInfos] = "Doit être complété";
    } else if (name === "email" && !EmailValidator.validate(value)) {
      errors["email"] = "Le format de l'adresse email n'est pas valide";
    }
  });
  return errors;
};

const pageTitle = "Inscrire mon salon de coiffure";

function SignupPage({ onSubmit: onSubmitFromProps }: SignupPageProps) {
  const history = useHistory();
  const close = () => {
    history.push(parseRoute(routeList.LOGIN_PAGE));
  };

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const onSubmit = (values: SalonInfos) =>
    onSubmitFromProps(values).then(() => setIsSuccessModalOpened(true));

  const { handleSubmit, getFieldProps, getMuiErrorProps } = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      name: "",
      postalCode: "",
    },
    validate: signupFormValidator,
    onSubmit,
  });

  const getAllFieldProps = (fieldName: string) => ({
    ...getFieldProps(fieldName),
    ...getMuiErrorProps(fieldName),
  });

  const form = (
    <SignupPageForm
      handleSubmit={handleSubmit}
      getAllFieldProps={getAllFieldProps}
    />
  );
  return (
    <React.Fragment>
      <Desktop>
        <SignupPageDesktopLayout onClose={close} form={form} />
      </Desktop>
      <Mobile>
        <SignupPageMobileLayout onClose={close} form={form} />
      </Mobile>
      <SignupPageSuccessModal open={isSuccessModalOpened} onClose={close} />
    </React.Fragment>
  );
}

function SignupPageSuccessModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  return (
    <DesktopModalContainer fullWidth={false} open={open}>
      <div className="signup-success-modal">
        <span className="signup-success-modal-title">Merci</span>
        <span className="signup-success-modal-message">
          Nous vous recontactons rapidement
        </span>
        <Button
          variant="text"
          className="signup-success-modal-button"
          component="a"
          href="https://www.leciseau.fr/page/devenez-partenaire/fr"
          onClick={onClose}
        >
          Visitez la page pour devenir partenaire
        </Button>
      </div>
    </DesktopModalContainer>
  );
}

interface ISignupPageLoayoutProps {
  form: React.ReactNode;
  onClose(): void;
}

function SignupPageMobileLayout({ form, onClose }: ISignupPageLoayoutProps) {
  return (
    <ExternalPageLayoutMobile onClose={onClose} title={pageTitle}>
      {form}
    </ExternalPageLayoutMobile>
  );
}

function SignupPageDesktopLayout({ form, onClose }: ISignupPageLoayoutProps) {
  return (
    <ExternalPageLayoutDesktop
      onClose={onClose}
      title={pageTitle}
      className="signup-page-desktop-container"
    >
      {form}
    </ExternalPageLayoutDesktop>
  );
}

//@ts-ignore
function SignupPageForm({ handleSubmit, getAllFieldProps }) {
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      style={{ height: "100%" }}
    >
      <Grid item>
        <span className="signup-title">
          LeCiseau remplit les créneaux vides des salons de coiffure en heures
          creuses et à la dernière minute
        </span>
        <span className="signup-form-label">
          Pour devenir salon partenaire, remplissez ce formulaire de contact :
        </span>
        <form onSubmit={handleSubmit} className="signup-page-form">
          <Grid
            container
            wrap="nowrap"
            className="input-group input-group-inline"
          >
            <TextField
              fullWidth
              label="Nom"
              type="text"
              autoComplete="family-name"
              {...getAllFieldProps("lastName")}
            />
            <TextField
              fullWidth
              label="Prénom"
              type="text"
              autoComplete="given-name"
              {...getAllFieldProps("firstName")}
            />
          </Grid>
          <TextField
            fullWidth
            label="Email"
            type="email"
            autoComplete="email"
            {...getAllFieldProps("email")}
          />
          <TextField
            fullWidth
            label="Téléphone"
            type="tel"
            autoComplete="tel"
            {...getAllFieldProps("phone")}
          />
          <TextField
            fullWidth
            label="Nom de l'établissement"
            type="text"
            {...getAllFieldProps("name")}
          />
          <TextField
            fullWidth
            label="Code postal"
            type="text"
            {...getAllFieldProps("postalCode")}
          />
        </form>
      </Grid>
      <Grid>
        <ButtonFab onClick={handleSubmit}>Contactez-moi</ButtonFab>
        <span className="signup-submit-helper-text">
          En remplissant ce formulaire, vous serez rappelé dans les 24h par
          l'équipe LeCiseau.fr. Vous pouvez également nous contacter au 01 84 80
          04 40, du lundi au samedi, de 9h30 à 19h00
        </span>
      </Grid>
    </Grid>
  );
}

export default SignupPage;
export {
  signupFormValidator,
  SignupPageMobileLayout,
  SignupPageDesktopLayout,
  SignupPageForm,
  SignupPageSuccessModal,
};
