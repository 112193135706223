import React from "react";
import styles from "./PrestationItem.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import { getIsOldBooking, addCurrency, priceFormatter } from "utils/payments";
import { Booking } from "reducers/booking";
import CoinsIcon from "components/svgs/Coins";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export interface IPrestationItemProps {
  title: string;
  subtitle: string;
  value: string;
  valueAfterCommission: string;
  onClick?(): void;
  booking: Booking;
}

interface BadgeProps {
  status: string;
  paymentType: string;
}

const PaymentInSalonBadge = () => {
  return (
    <div className={styles.paymentInSalonContainer}>
      <CoinsIcon color="black" width="16px" />
      <span className={styles.paymentInSalonText}>Paiement en salon</span>
    </div>
  );
};

const Badge = ({ status, paymentType }: BadgeProps) => {
  if (paymentType === "IN_SALON" && status === "PAID") {
    return <PaymentInSalonBadge />;
  }
  const COLORS: {
    [key: string]: {
      backgroundColor: string;
      borderColor: string;
      text: string;
      icon: JSX.Element | null;
    };
  } = {
    PAID: {
      backgroundColor: "#e1f5f1",
      borderColor: "#6fcebe",
      text: "Payé",
      icon: <CheckIcon className={styles.icon} />,
    },
    CANCELED: {
      backgroundColor: "#fff0de",
      borderColor: "#ff9d2b",
      text: "Annulé",
      icon: <ClearIcon className={styles.icon} />,
    },
    NO_SHOW: {
      backgroundColor: "#fcdfe1",
      borderColor: "#ef3340",
      text: "Pas venu",
      icon: <ClearIcon className={styles.icon} />,
    },
  };
  const colorKey = Object.keys(COLORS).find((key) => key === status);
  if (!colorKey) return null;
  return (
    <div
      className={styles.badge}
      style={{
        backgroundColor: COLORS[colorKey].backgroundColor,
        border: `solid 1px ${COLORS[colorKey].borderColor}`,
      }}
    >
      <span
        className={styles.badgeTextContainer}
        style={{
          color: COLORS[colorKey].borderColor,
        }}
      >
        {COLORS[colorKey].icon}
        <span className={styles.badgeText}>{COLORS[colorKey].text}</span>
      </span>
    </div>
  );
};

function PrestationItem({
  title,
  subtitle,
  value,
  valueAfterCommission,
  onClick,
  booking,
}: IPrestationItemProps) {
  const isOldBooking = getIsOldBooking(booking);
  const isNewClient = booking?.commissionConditions?.isNewClient;
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.topContainer}>
        <span className={styles.title}>{title}</span>
        <Badge status={booking.status} paymentType={booking.paymentType} />
      </div>
      <div className={styles.infosContainer}>
        <div className={styles.packageContainer}>
          <span className={styles.subtitle}>{subtitle}</span>
          <div className={styles.priceBeforeCommissionContainer}>
            <span>Payé par le client : </span>
            <span className={styles.beforeCommissionValue}>
              {addCurrency(value)}
            </span>
          </div>
        </div>
        <div className={styles.paymentPriceAfterCommissionContainer}>
          <span>
            {booking.paymentType === "IN_SALON"
              ? "Commission due"
              : isOldBooking || isNewClient
              ? "Après commission"
              : "Après frais de paiement"}
            {isOldBooking || !isNewClient || booking.paymentType === "IN_SALON"
              ? ""
              : " et frais de paiement"}
          </span>
          <span
            className={styles.value}
            style={{
              textDecoration:
                booking.status !== "PAID" ? "line-through 2px" : "none",
            }}
          >
            {addCurrency(
              booking.paymentType === "IN_SALON"
                ? priceFormatter.format((booking.commissionAmount / 100) * -1)
                : valueAfterCommission
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export function PrestationItemSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.infosContainer}>
        <div>
          <Skeleton
            height={16}
            width={170}
            classes={{ text: styles.skeleton }}
          />
        </div>
        <Skeleton height={10} width={150} classes={{ text: styles.skeleton }} />
        <div className={styles.priceBeforeCommissionContainer}>
          <Skeleton
            height={10}
            width={100}
            classes={{ text: styles.skeleton }}
          />
        </div>
      </div>
      <div className={styles.paymentPriceAfterCommissionContainer}>
        <Skeleton height={10} width={120} classes={{ text: styles.skeleton }} />
        <Skeleton height={16} width={80} classes={{ text: styles.skeleton }} />
      </div>
    </div>
  );
}

export default PrestationItem;
