export const defaultCalendarFormat = "D MMMM YYYY";

export const buildCalendarFormat = (defaultFormat = defaultCalendarFormat) => ({
  lastWeek: defaultFormat,
  lastDay: "[Hier]",
  sameDay: "[Aujourd'hui]",
  nextDay: "[Demain]",
  nextWeek: defaultFormat,
  sameElse: defaultFormat
});

export const CalendarFormat = buildCalendarFormat();
