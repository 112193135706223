import { createMuiTheme } from "@material-ui/core/styles";
import {
  TEALISH,
  PINK,
  DARK,
  LIGHT_BLUE_GREY,
  SALMON,
  WHITE,
} from "../constants/Style";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Montserrat-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: TEALISH,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: SALMON,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: PINK,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    text: {
      primary: DARK,
      disabled: LIGHT_BLUE_GREY,
    },
  },
  overrides: {
    MuiPickersDay: {
      day: {
        color: DARK,
      },
      daySelected: {
        backgroundImage: "linear-gradient(241deg, #06038D 102%, #00a481 3%)",
        color: WHITE,
      },
    },
  },
});

export default theme;
