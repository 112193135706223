import React from "react";

const AgendaMenuIcon = (props: any) => (
  <svg viewBox="0 0 60 60" height={25} {...props}>
    <defs>
      <style>
        {`.agenda-1{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}`}
      </style>
    </defs>
    <path
      className="agenda-1"
      d="M10.79 14.57h38.43v36.34H10.79zM19.45 9.09v9.84M40.57 9.09v9.84"
    />
    <circle className="agenda-1" cx={19.15} cy={25.1} r={2.13} />
    <circle className="agenda-1" cx={30} cy={25.1} r={2.13} />
    <circle className="agenda-1" cx={40.85} cy={25.1} r={2.13} />
    <circle className="agenda-1" cx={19.15} cy={34.34} r={2.13} />
    <circle className="agenda-1" cx={30} cy={34.34} r={2.13} />
    <circle className="agenda-1" cx={40.85} cy={34.34} r={2.13} />
    <circle className="agenda-1" cx={19.15} cy={43.59} r={2.13} />
    <circle className="agenda-1" cx={30} cy={43.59} r={2.13} />
    <circle className="agenda-1" cx={40.85} cy={43.59} r={2.13} />
  </svg>
);

export default AgendaMenuIcon;
