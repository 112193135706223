import React, { useRef, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import moment from "moment";

import { COLUMN_WIDTH, CELL_HEIGHT } from "constants/Calendar";
import { TIME_STEP_DURATION } from "constants/Agenda";
import { isIE } from "utils/isIE";
import {
  getRulerTimeSteps,
  getEventPositionInfos,
  getColumnMouseEventDatetime,
} from "utils/calendar";
import CalendarRuler from "./CalendarRuler";
import CalendarColumn from "./CalendarColumn";
import { CalendarEventRendererProps } from "./CalendarColumnEvent";
import "./calendar.scss";
import { PartnerRef } from "reducers/salon";

export interface CalendarResource {
  id: number;
  stringId?: string;
  title: string;
  partnerRef?: PartnerRef;
  color: string;
  status: string;
  icon: React.SFC<{ height: number }>;
}

export interface CalendarEvent<T> {
  id: number | string;
  meta: T;
  resourceId: number;
  hairdresserStringId: string;
  start: moment.Moment;
  end: moment.Moment;
  discount?: number;
}

export type CalendarViewType = "DAY" | "WEEK";
const WEEK_LENGTH = 7;

const WEEKDAY_TITLE_FORMAT = "ddd DD";

type RessourceMouseEventHandler = (
  resource: CalendarResource,
  datetime: string,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;

export interface CalendarProps<T> {
  dayStart: moment.Duration;
  dayEnd: moment.Duration;
  defaultTime: moment.Duration;
  timeStepDuration: moment.Duration;
  date: moment.Moment;
  view: CalendarViewType;
  resources: Array<CalendarResource>;
  events: Array<CalendarEvent<T>>;
  /**
   * Everything Below should be refactored to not be necessary in Calendar
   * Top Level Props
   */
  eventRenderer?: { (props: CalendarEventRendererProps<T>): JSX.Element }; // Could be in CalendarEvent
  onClickEvent(event: CalendarEvent<T>): void; // Could be in CalendarEvent
  onClickRessource?: RessourceMouseEventHandler; // Could be in CalendarResource
  onMouseDownRessource?: RessourceMouseEventHandler; // Could be in CalendarResource
  onMouseUpRessource?: RessourceMouseEventHandler; // Could be in CalendarResource
  onMouseMoveRessource?: RessourceMouseEventHandler; // Could be in CalendarResource
  onMouseLeaveRessource?: RessourceMouseEventHandler; // Could be in CalendarResource
  isLoading?: boolean; // Not sure it belongs in Calendar ... It may be aded from outside
  showResourceIcon?: boolean; // Could be in CalendarResource
  scollableElementRef?: React.RefObject<HTMLElement>; // There must be a way to get rid of it...
}

function resetCalendarHeadersStickyPosition() {
  const headerItems = document.querySelectorAll<HTMLElement>(
    ".calendar_header_item"
  );

  function setHeadersPosition(position: string) {
    headerItems.forEach((headerElement) => {
      headerElement.style.position = position;
    });
  }

  setTimeout(() => {
    setHeadersPosition("relative");

    setTimeout(() => {
      setHeadersPosition("sticky");
    }, 20);
  }, 20);
}

export default function Calendar<T>({
  dayStart,
  dayEnd,
  defaultTime,
  timeStepDuration,
  date,
  view,
  eventRenderer,
  resources,
  events,
  onClickEvent,
  onClickRessource,
  onMouseDownRessource,
  onMouseUpRessource,
  onMouseMoveRessource,
  onMouseLeaveRessource,
  className,
  isLoading,
  showResourceIcon,
  scollableElementRef,
}: CalendarProps<T> & React.HTMLAttributes<HTMLDivElement>) {
  const eventPositionInfosGetter = getEventPositionInfos(
    moment.duration(dayStart),
    moment.duration(dayEnd),
    moment.duration(timeStepDuration)
  );

  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const getScrollTop = () => {
    // In Mobile layout the calendarContainerRef is not scrollable so we pass
    // the scollableElementRef from parent. It should exist a better way to do
    if (scollableElementRef && scollableElementRef.current) {
      return scollableElementRef.current.scrollTop;
    } else if (calendarContainerRef.current) {
      return calendarContainerRef.current.scrollTop;
    }
    return 0;
  };

  const setScrollTop = (scrollTop: number) => {
    // In Mobile layout the calendarContainerRef is not scrollable so we pass
    // the scollableElementRef from parent. It should exist a better way to do
    if (scollableElementRef && scollableElementRef.current) {
      scollableElementRef.current.scrollTop = scrollTop;
    } else if (calendarContainerRef.current) {
      calendarContainerRef.current.scrollTop = scrollTop;
    }
  };

  // Scroll the Calendar to Default Time on date change
  useEffect(() => {
    const scrollTop =
      (defaultTime.as("seconds") / TIME_STEP_DURATION.as("seconds")) *
      CELL_HEIGHT;

    setScrollTop(scrollTop);

    // Fix bug in Edge with Sticky position
    // TODO detect Browser and only reset when IE / EDGE
    if (isIE) {
      resetCalendarHeadersStickyPosition();
    }
  }, [date.startOf("day").valueOf()]);

  const timeGridContainerMinWidth =
    view === "WEEK"
      ? resources.length * WEEK_LENGTH * COLUMN_WIDTH.WEEK_MODE
      : resources.length * COLUMN_WIDTH.DAY_MODE;

  const days =
    view === "WEEK"
      ? Array.from({ length: WEEK_LENGTH }).map((_, index) =>
          moment(date).startOf("week").add(index, "days")
        )
      : [date];

  const timeSteps = getRulerTimeSteps(dayStart, dayEnd, timeStepDuration);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseEvent = (
    handler: RessourceMouseEventHandler | undefined,
    resource: CalendarResource,
    day: moment.Moment
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!handler) return;

    const datetime = getColumnMouseEventDatetime(
      event,
      getScrollTop(),
      tableContainerRef,
      moment.duration(dayStart),
      moment.duration(timeStepDuration),
      moment(day)
    );

    handler(resource, datetime, event);
  };

  return (
    <div className={className} ref={calendarContainerRef}>
      <div
        className={classnames("calendar-outer-container", {
          "calendar_timegrid-container--week-mode": view === "WEEK",
        })}
      >
        {isLoading && (
          <CircularProgress className="calendar-loader" color="primary" />
        )}
        {view === "WEEK" && (
          <div className="calendar-week-days-header">
            {days.map((day) => {
              const isSelected = day.isSame(moment(), "day");
              return (
                <div
                  key={`weekday_header${day.valueOf()}`}
                  className={classnames("calendar-week-days-header-item", {
                    "calendar-week-days-header-item--selected": isSelected,
                  })}
                >
                  {isSelected && (
                    <div className="calendar-week-days-header-selected-item--extra" />
                  )}
                  {day.format(WEEKDAY_TITLE_FORMAT).toUpperCase()}
                </div>
              );
            })}
          </div>
        )}
        <div className="calendar-table-container" ref={tableContainerRef}>
          <CalendarRuler
            timeSteps={timeSteps}
            showResourceIcon={showResourceIcon}
          />
          <div
            className="calendar_timegrid-tablecell-container"
            style={{
              minWidth: `${timeGridContainerMinWidth}px`,
            }}
          >
            <div
              className={classnames("calendar_timegrid-container", {
                "calendar_timegrid-container--with-icon": showResourceIcon,
              })}
            >
              {days.map((day, dayIndex) =>
                resources.map((resource, resourceIndex) => (
                  <CalendarColumn
                    key={`${day.valueOf()}_${resource.id}`}
                    classes={{
                      container: classnames({
                        "calendar_first-resource-of-day_container":
                          resourceIndex === 0 && dayIndex > 0,
                        "calendar_last-resource-of-day_container":
                          resourceIndex === resources.length - 1,
                      }),
                    }}
                    date={day}
                    view={view}
                    resource={resource}
                    showResourceIcon={showResourceIcon}
                    showResourceTitle={view === "DAY"}
                    events={events}
                    eventRenderer={eventRenderer}
                    getEventPositionInfos={eventPositionInfosGetter}
                    onClickEvent={onClickEvent}
                    onClick={handleMouseEvent(onClickRessource, resource, day)}
                    onMouseDown={handleMouseEvent(
                      onMouseDownRessource,
                      resource,
                      day
                    )}
                    onMouseUp={handleMouseEvent(
                      onMouseUpRessource,
                      resource,
                      day
                    )}
                    onMouseMove={handleMouseEvent(
                      onMouseMoveRessource,
                      resource,
                      day
                    )}
                    onMouseLeave={handleMouseEvent(
                      onMouseLeaveRessource,
                      resource,
                      day
                    )}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
