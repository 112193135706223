import React, { useState, useEffect, memo } from "react";
import { LastMinute } from "reducers/lastMinute";
import { Hairdresser, Salon } from "reducers/salon";
import Desktop from "components/ResponsiveLayout/Desktop";
import Mobile from "components/ResponsiveLayout/Mobile";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "components/layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import styles from "./styles.module.scss";
import { ACCOUNT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import Button from "../../button/Desktop/Button.desktop";
import {
  updateLastMinute,
  createLastMinute as createLastMinuteAPI,
} from "api/lastMinute";
import { useDispatch } from "react-redux";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import PlusCircle from "components/svgs/PlusCircle";
import MobilePageContainer from "../../page/Container.mobile";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import { createLastMinuteEmptyDays } from "utils/lastMinute";
import LastMinuteForm from "./LastMinuteForm";
import IFrameContainer from "components/iframe/IFrameContainer";
import MobilePageHeader from "../../page/Header.mobile";
import { isIFrame } from "utils/helpers";
import CloseIcon from "components/svgs/CloseIcon";
import useCurrentSalon from "hooks/useCurrentSalon";

interface LastMinutePageProps {
  salon: Salon;
  onClose(): void;
  lastMinutes: Array<LastMinute>;
  fetchCurrentLastMinutes(): void;
}

const LastMinutePage = ({
  salon,
  onClose,
  lastMinutes,
  fetchCurrentLastMinutes,
}: LastMinutePageProps) => {
  // When on this page, lastMinutes are editable until clicking on the "Submit" button
  // So we make a copy
  const [lastMinutesToSubmit, setLastMinutesToSubmit] = useState<
    Array<LastMinute>
  >([]);
  const dispatch = useDispatch();

  const { hairdressers, companyCode } = useCurrentSalon();

  useEffect(() => {
    setLastMinutesToSubmit(
      lastMinutes.map((lastMinute: LastMinute) => ({
        ...lastMinute,
      }))
    );
  }, [lastMinutes]);

  const saveLastMinute = (oldIndex: number, lastMinute: LastMinute) => {
    if (oldIndex === -1) {
      setLastMinutesToSubmit([...lastMinutesToSubmit, lastMinute]);
      return;
    }

    const newLastMinute = {
      ...lastMinute,
      days: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
    };

    setLastMinutesToSubmit(
      lastMinutesToSubmit
        .map((toSubmit: LastMinute, index: number) =>
          index === oldIndex ? newLastMinute : toSubmit
        ) // Add all hairdressers for now
        .map((lastMinute: LastMinute) => ({
          ...lastMinute,
          hairdresserIds: hairdressers
            .map(({ id }: Hairdresser) => id)
            .filter(Boolean),
          hairdresserStringIds: hairdressers
            .map(({ stringId }: Hairdresser) =>
              stringId && stringId !== "" ? `${companyCode}|${stringId}` : ""
            )
            .filter((val) => val !== ""),
        }))
    );
  };

  const saveLastMinutes = () => {
    Promise.all(
      lastMinutesToSubmit.map((lastMinute: LastMinute) => {
        if (lastMinute.id) {
          return updateLastMinute(lastMinute);
        }

        if (lastMinute.status !== "DELETED") {
          return createLastMinuteAPI(lastMinute);
        }
      })
    ).then(() => {
      dispatch(pushMessage("Last Minute sauvegardés", MESSAGE_TYPES.SUCCESS));
      fetchCurrentLastMinutes();
    });
  };

  const content = (
    <div className={styles.container}>
      <div>
        <p className={styles.lightBorder}>
          Proposer vos offres de dernière minute
        </p>
      </div>
      <div>
        {lastMinutesToSubmit
          .filter((lastMinute) => lastMinute.status !== "DELETED")
          .map((lastMinute: LastMinute, index: number) => (
            <LastMinuteForm
              lastMinute={lastMinute}
              index={index}
              saveLastMinute={saveLastMinute}
            />
          ))}
        <Button
          onClick={() =>
            saveLastMinute(-1, {
              id: 0,
              hairdresserIds: [],
              minutesBefore: 360,
              salonId: salon.id,
              status: "ENABLED",
              discount: 50,
              days: createLastMinuteEmptyDays(),
            })
          }
          className={styles.textButton}
          element={
            <span>
              <PlusCircle hover="true" />{" "}
              <span>Ajouter une offre last minute</span>
            </span>
          }
        />
      </div>
      <Button
        secondary
        style={{
          margin: "30px auto",
        }}
        className={styles.saveButton}
        onClick={() => saveLastMinutes()}
        text="Enregistrer les modifications"
      />
    </div>
  );

  return (
    <div>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
          >
            <div className={styles.mainContainer}>
              <h2>Promotions Last Minute</h2>
              {content}
            </div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={isIFrame() ? undefined : <CloseIcon onClick={onClose} />}
                center="Promotions Last Minute"
                parent="account"
              />
            }
            body={content}
          />
        </MobilePageContainer>
      </Mobile>
    </div>
  );
};

export default memo(LastMinutePage);
