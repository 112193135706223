import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { fetchPaidBookingsBySalonId } from "actions/PaymentsActions";
import { routeList } from "constants/Routes";
import { TURNOVER_PERIOD_TYPES } from "constants/Payment";
import TurnoverPageTemplate from "components/payment/TurnoverPage/TurnoverPageTemplate";
import PrestationDetails from "components/payment/PrestationDetails/PrestationDetails";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import useCurrentSalon from "hooks/useCurrentSalon";
import { AppState } from "reducers";
import { parseRoute } from "utils/route";
import { mergeFormValues } from "utils/form";
import { getPrestations, TurnoverFilters, Prestation } from "utils/payments";

function TurnoverPage() {
  const [selectedHairdresser, setSelectedHairdresser] = useState<
    "ALL" | number
  >("ALL");
  const [selectedPeriod, setSelectedPeriod] = useState({
    type: TURNOVER_PERIOD_TYPES.SINGLE,
    month: moment().month(),
    range: {
      from: moment().startOf("month").valueOf(),
      to: moment().valueOf(),
    },
  });

  const { isDesktop } = useResponsiveLayout();

  useEffect(() => {
    if (isDesktop) {
      setSelectedPeriod((selectedPeriod) => ({
        ...selectedPeriod,
        type: TURNOVER_PERIOD_TYPES.RANGE,
      }));
    }
  }, [isDesktop]);

  const filters: TurnoverFilters = {
    hairdresser: selectedHairdresser,
    period: selectedPeriod,
  };
  const filtersSetters = {
    hairdresser: setSelectedHairdresser,
    period: setSelectedPeriod,
  };
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ) => {
    const name = event.target.name;
    const eventValue = checked === undefined ? event.target.value : checked;
    const currentValue = (filters as any)[name];
    const newValue = mergeFormValues(currentValue, eventValue);
    (filtersSetters as any)[name] && (filtersSetters as any)[name](newValue);
  };

  const { salon, hairdresserById, hairdressers } = useCurrentSalon();
  const allHairdressersIds = Object.keys(hairdresserById).map((id) =>
    parseInt(id)
  );

  const { loading: isPaymentsLoading, payload: paymentsPayload } = useSelector(
    (state: AppState) => state.payments
  );
  const { bookingsByHairdresserId } = paymentsPayload;
  const prestations = getPrestations(
    allHairdressersIds,
    bookingsByHairdresserId,
    filters
  );

  const [prestationToOpen, setPrestationToOpen] = useState<Prestation>();

  const dispatch = useDispatch();
  useEffect(() => {
    const range =
      selectedPeriod.type === TURNOVER_PERIOD_TYPES.SINGLE
        ? {
            start: moment()
              .month(selectedPeriod.month)
              .startOf("month")
              .toISOString(),
            end: moment()
              .month(selectedPeriod.month)
              .endOf("month")
              .toISOString(),
          }
        : {
            start: moment(selectedPeriod.range.from).toISOString(),
            end: moment(selectedPeriod.range.to).toISOString(),
          };
    dispatch(fetchPaidBookingsBySalonId(salon.id, range));
  }, [selectedPeriod, salon.id]);
  return (
    <React.Fragment>
      <TurnoverPageTemplate
        title="Chiffre d'affaires par professionnel"
        closeLink={parseRoute(routeList.PAYMENT_PAGE)}
        hairdressers={hairdressers}
        filters={filters}
        prestations={prestations}
        onChangeFilters={changeHandler}
        onPrestationClick={(prestation: Prestation) =>
          setPrestationToOpen(prestation)
        }
      />
      {prestationToOpen && (
        <PrestationDetails
          open={!!prestationToOpen}
          prestation={prestationToOpen}
          onClose={() => setPrestationToOpen(undefined)}
        />
      )}
      {isPaymentsLoading && (
        <CircularProgress className="calendar-loader" color="primary" />
      )}
    </React.Fragment>
  );
}

export default TurnoverPage;
