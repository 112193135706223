import React, { useRef } from "react";

import ModalLayoutMobile from "components/modal/Layout/Layout.mobile";
import CloseIcon from "components/svgs/CloseIcon";
import MobilePageHeader from "components/page/Header.mobile";
import LargeClickable from "components/uiElements/LargeClickable";
import Mobile from "components/ResponsiveLayout/Mobile";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import Desktop from "components/ResponsiveLayout/Desktop";
import { Hairdresser } from "reducers/salon";
import FreeSlotFormContent from "./FreeSlotFormContent";

type IFreeSlotFormMode = "CREATE" | "UPDATE";

export interface IFreeSlotFormProps {
  mode: IFreeSlotFormMode;
  isLoading: boolean;
  hairdressers: Array<Hairdresser>;
  formValues: {
    hairdresserIds: Array<number>;
    hairdresserStringIds: Array<string>;
    date: string;
    start: string;
    end: string;
    repeatSlot: boolean;
    repeatSlotWeekDays: Array<number>;
    repeatSlotNumberOfWeek: number;
    discount: number;
  };
  onClose(): void;
  onSubmit(): void;
  onDelete(): void;
  onDeleteRecurrence(): void;
  onChange(event: any): void;
  recurrenceId?: number;
}

function FreeSlotForm(props: IFreeSlotFormProps) {
  const { mode, hairdressers, onClose } = props;
  const bodyRef = useRef<HTMLElement>();
  const enabledHairdressers = hairdressers.filter(
    (h) => h.status === "ENABLED"
  );

  const onError = () => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
      bodyRef.current.scrollLeft = 0;
    }
  };
  const title =
    mode === "CREATE"
      ? "Ajouter une disponibilité"
      : "Modifier la disponibilité";
  return (
    <ResponsiveContainer bodyRef={bodyRef} onClose={onClose} title={title}>
      <FreeSlotFormContent
        {...props}
        onError={onError}
        hairdressers={enabledHairdressers}
      />
    </ResponsiveContainer>
  );
}

interface ResponsiveContainerProps {
  children: React.ReactNode;
  bodyRef: React.MutableRefObject<HTMLElement | undefined>;
  onClose(): void;
  title: string;
}

function ResponsiveContainer({
  children,
  bodyRef,
  onClose,
  title,
}: ResponsiveContainerProps) {
  return (
    <>
      <Mobile>
        <ModalLayoutMobile
          refs={{ body: bodyRef }}
          header={
            <MobilePageHeader
              left={
                <LargeClickable onClick={onClose}>
                  <CloseIcon onClick={onClose} />
                </LargeClickable>
              }
              center={title}
            />
          }
          body={children}
        />
      </Mobile>
      <Desktop>
        <ModalLayoutDesktop onClose={onClose} header={title} body={children} />
      </Desktop>
    </>
  );
}

export default FreeSlotForm;
