import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { isIFrame } from "utils/helpers";

function useResponsiveLayout() {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isMobileIFrame = isIFrame() && window.innerWidth < 670;

  return {
    isDesktop: !isMobileIFrame && isDesktop,
    isMobile: isMobileIFrame || isMobile,
  };
}

export default useResponsiveLayout;
