import { STATUSES } from "constants/Account";
import { AgendaFilters } from "hooks/useAgendaFilterSelector";
import { Hairdresser } from "reducers/salon";

export const getDisplayCheckboxes = (formValues: AgendaFilters) => {
  return [
    {
      name: "showBookings",
      value: formValues.showBookings,
      display: "Rendez vous",
    },
    {
      name: "showFreeSlots",
      value: formValues.showFreeSlots,
      display: "Disponibilités",
    },
    {
      name: "showUnavailabilities",
      value: formValues.showUnavailabilities,
      display: "Indisponibilités",
    },
  ];
};

export const hairdresserToInput = (hairdresser: Hairdresser) => ({
  value: hairdresser.id || Number(hairdresser.stringId),
  display: `${hairdresser.firstName}${
    hairdresser.status === STATUSES.DISABLED ? " (Désactivé)" : ""
  }`,
  optional: hairdresser.status === "DISABLED",
  hairdresser,
});
