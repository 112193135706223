import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HairdressersRatings from "components/rating/HairdressersTab/HairdressersTab";
import { AppState } from "reducers";
import { Hairdresser } from "reducers/salon";
import { getHairdresserRatings } from "utils/ratings";
import {
  fetchHairdresserRatings,
  fetchHairdresserRatingsStats,
} from "actions/RatingActions";
import useCurrentSalon from "hooks/useCurrentSalon";

const ratingStateSelector = (state: AppState) => state.rating;

function HairdressersRatingsContainer() {
  const ratingState = useSelector(ratingStateSelector);
  const { hairdressers, salon, companyCode } = useCurrentSalon();
  const [selectedHaidresser, setSelectedHaidresserId] = useState(
    hairdressers[0]
  );
  useEffect(() => {
    setSelectedHaidresserId(hairdressers[0]);
  }, [salon.id]);
  const hairdresserRatings = getHairdresserRatings(
    (selectedHaidresser || {}).id,
    (selectedHaidresser || {}).stringId,
    ratingState
  );
  const dispatch = useDispatch();
  const fetchingPageByHairdresserId = useRef<{ [id: number]: number }>(
    hairdressers.reduce(
      (pageById, hairdresser) => ({
        ...pageById,
        [hairdresser.id || Number(hairdresser.stringId)]: 1,
      }),
      {}
    )
  );

  const fetchRatings = (hairdresser: Hairdresser, page = 1) => {
    return (dispatch(
      fetchHairdresserRatings(companyCode, hairdresser, { page })
    ) as unknown) as Promise<any>;
  };

  useEffect(() => {
    fetchRatings(selectedHaidresser);
    const interval = setInterval(() => fetchRatings(selectedHaidresser), 60000);
    return () => clearInterval(interval);
  }, [selectedHaidresser]);

  const fetchNextRatings = () => {
    fetchingPageByHairdresserId.current[
      selectedHaidresser.id || Number(selectedHaidresser.stringId)
    ]++;
    const fetchingPage =
      fetchingPageByHairdresserId.current[
        selectedHaidresser.id || Number(selectedHaidresser.stringId)
      ];
    return fetchRatings(selectedHaidresser, fetchingPage);
  };

  useEffect(() => {
    hairdressers.forEach((haidresser) => {
      dispatch(fetchHairdresserRatingsStats(companyCode, haidresser));
    });
  }, []);
  return (
    <HairdressersRatings
      selectedHaidresser={selectedHaidresser || {}}
      ratings={hairdresserRatings}
      onChange={(hairdresser) => setSelectedHaidresserId(hairdresser)}
      fetchNextRatings={fetchNextRatings}
      ratingsStatsById={ratingState.payload.stats.byHairdresserId}
    />
  );
}

export default HairdressersRatingsContainer;
