import * as React from "react";
import classnames from "classnames";
import { WHITE, BUTTON_GREY } from "constants/Style";
import "./button.desktop.scss";

interface IDesktopButtonProps {
  text?: string;
  onClick?(): void;
  icon?: React.SFC<{ color?: string }>;
  primary?: boolean;
  secondary?: boolean;
  dense?: boolean;
  disabled?: boolean;
  className?: string;
  element?: React.ReactNode;
  style?: object;
}

function DesktopButton({
  text,
  onClick,
  icon: Icon,
  primary,
  secondary,
  dense,
  disabled,
  className,
  element,
  style,
}: IDesktopButtonProps) {
  return (
    <button
      style={style}
      className={classnames("button-desktop", className, {
        "button-desktop--primary": primary,
        "button-desktop--secondary": secondary,
        "button-desktop--dense": dense,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon color={primary ? WHITE : BUTTON_GREY} />}
      {text || element}
    </button>
  );
}

export default DesktopButton;
