import { combineReducers } from "redux";
import auth from "./auth";
import rating from "./rating";
import salon from "./salon";
import slot from "./slot";
import unavailability from "./unavailability";
import booking from "./booking";
import snackbar from "./snackbar";
import payments from "./payments";
import week from "./week";
import lastMinute from "./lastMinute";

export const STORE_VERSION = 5;

const rootReducer = combineReducers({
  auth,
  rating,
  salon,
  slot,
  unavailability,
  booking,
  snackbar,
  payments,
  week,
  lastMinute,
});

export type AppState = ReturnType<typeof rootReducer>;

export type ThunkDispatch = {
  <TAction>(action: TAction): TAction extends (...args: any[]) => infer TResult
    ? TResult
    : TAction;
};

export default rootReducer;
