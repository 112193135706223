import React from "react";
import DesktopLayoutWithSidebarLinks from "components/layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import { PAYMENT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import IFrameContainer from "components/iframe/IFrameContainer";

function PaymentPageDesktopLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <IFrameContainer>
        <MenuDesktop />
        <DesktopLayoutWithSidebarLinks
          links={PAYMENT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
        >
          {children}
        </DesktopLayoutWithSidebarLinks>
      </IFrameContainer>
    </>
  );
}

export default PaymentPageDesktopLayout;
