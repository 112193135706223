import React from "react";
import { ModalProps } from "@material-ui/core/Modal";
import MobileContainer from "./Container.mobile";
import DesktopContainer from "./Container.desktop";
import FullscreenModal from "../FullscreenModal/FullscreenModal";
import { SlideProps } from "@material-ui/core/Slide";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";

type ResponsiveModalContainerProps = ModalProps &
  Partial<Pick<SlideProps, "direction">> & {
    fullScreenOnMobile?: boolean;
    mobileOnlyProps?: Partial<ModalProps>;
    desktopOnlyProps?: Partial<ModalProps>;
  };

function ResponsiveModalContainer({
  fullScreenOnMobile,
  direction,
  children,
  mobileOnlyProps,
  desktopOnlyProps,
  ...otherProps
}: ResponsiveModalContainerProps) {
  return (
    <>
      <Mobile>
        {fullScreenOnMobile ? (
          <FullscreenModal direction={direction} {...otherProps}>
            {children}
          </FullscreenModal>
        ) : (
          <MobileContainer {...otherProps} {...mobileOnlyProps}>
            {children}
          </MobileContainer>
        )}
      </Mobile>
      <Desktop>
        <DesktopContainer {...otherProps} {...desktopOnlyProps}>
          {children}
        </DesktopContainer>
      </Desktop>
    </>
  );
}

export default ResponsiveModalContainer;
