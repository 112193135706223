import React from "react";
import { ModalContentProps } from "./ResponsiveContent";
import classnames from "classnames";
import styles from "./Content.module.scss";

export default function ModalContent(props: ModalContentProps) {
  const {
    children,
    dense,
    extended,
    withChildrenSeparator,
    noPadding,
    noBackground
  } = props;
  const className = classnames(styles.mobileContainer, {
    [styles.dense]: dense,
    [styles.extended]: extended,
    [styles.withSeparator]: withChildrenSeparator,
    [styles.noPadding]: noPadding,
    [styles.noBackground]: noBackground
  });
  return <div className={className}>{children}</div>;
}
