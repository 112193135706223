type reactNativeMessageType = { type: string; payload?: any };

export type onReactNativeMessageType = {
  (message: reactNativeMessageType): void;
};

interface IReactNativeMessageHandler {
  postMessage: onReactNativeMessageType;
}

export const getReactNativeMessageHandler = (
  onMessage: onReactNativeMessageType
): IReactNativeMessageHandler => ({
  postMessage: onMessage
});

export const REACT_NATIVE_MESSAGE_TYPES = {
  GO_BACK: "GO_BACK"
};
