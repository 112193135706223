import React, { useRef, useEffect, useState } from "react";
import SidebarLinks, { ISidebarLinksProps } from "./SidebarLinks/SidebarLinks";
import styles from "./DesktopLayoutWithSidebarLinks.module.scss";
import SynchroSaasButton from "components/account/SynchroSaasButton/SynchroSaasButton";
import { isIFrame } from "utils/helpers";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import classnames from "classnames";

export type IDesktopLayoutWithSidebarLinksProps = ISidebarLinksProps &
  Pick<React.HTMLAttributes<HTMLDivElement>, "children">;

function DesktopLayoutWithSidebarLinks({
  links,
  children,
  showSynchroSaas,
}: IDesktopLayoutWithSidebarLinksProps) {
  const { isDesktop } = useResponsiveLayout();
  const containerRef = useRef<HTMLDivElement>(null);

  const [offsetTop, setOffsetTop] = useState(
    containerRef.current?.offsetTop ?? 0
  );
  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop ?? 0);
  }, [containerRef.current]);

  const contentContainerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={containerRef}
      className={classnames(styles.container, {
        [styles.containerIframe]: isIFrame(),
      })}
      style={{ height: `calc(100vh - ${offsetTop}px)` }}
    >
      {!isIFrame() && isDesktop ? (
        <div className={styles.sidebar}>
          <SidebarLinks links={links} />
          {showSynchroSaas && <SynchroSaasButton />}
        </div>
      ) : null}
      <div ref={contentContainerRef} className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default DesktopLayoutWithSidebarLinks;
