import * as React from "react";
import Slide from "@material-ui/core/Slide";
import "./external-page-layout.scss";
import classnames from "classnames";

export interface IExternalPageLayoutDesktopProps {
  title: string;
  onClose(): void;
  children: React.ReactNode;
  className?: string;
}

function ExternalPageLayoutDesktop({
  title,
  onClose,
  children,
  className
}: IExternalPageLayoutDesktopProps) {
  return (
    <div className={classnames("external-page-desktop-container", className)}>
      <Slide direction="left" in={true}>
        <div>
          <span className="external-page-desktop-back-button" onClick={onClose}>
            &larr; Retour
          </span>
          <div className="external-page-desktop-content-container">
            <span>{title}</span>
            {children}
          </div>
        </div>
      </Slide>
    </div>
  );
}

export default ExternalPageLayoutDesktop;
