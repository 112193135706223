import React, { useState, useCallback } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PageContainer from "../../../page/Container";
import MobilePageHeader from "../../../page/Header.mobile";
import PageContent from "../../../page/Content";
import PreviousIcon from "../../../svgs/PreviousIcon";
import { Link } from "react-router-dom";
import ModalContentMobile from "../../../modal/Content/Content.mobile";
import {
  priceFormatter,
  Prestation,
  getPriceCommission,
} from "../../../../utils/payments";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListByDate from "../../ListByDate/ListByDate";
import { Payout } from "../../../../reducers/payments";
import PayoutItem from "../PayoutItem/PayoutItem";
import PrestationItem from "../PrestationItem/PrestationItem";
import { IUseProgressiveList } from "../../../../hooks/useProgressiveList/useProgressiveList";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import PaymentPageDesktopLayout from "../../PaymentPageDesktopLayout/PaymentPageDesktopLayout";
import styles from "./PaymentListPageTemplate.module.scss";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import DesktopModalContainer from "components/modal/Container/Container.desktop_old";
import DesktopModalHeader from "components/modal/Header/Header.desktop";
import ButtonFab from "components/button/Fab";
import { isIFrame } from "utils/helpers";

interface PaymentListPageTemplateProps {
  title: string;
  closeLink: string;
  balance: number;
  prestations: IUseProgressiveList<Prestation>;
  payouts: IUseProgressiveList<Payout>;
  openPrestationDetails(prestation: Prestation | undefined): void;
  openPayoutDetails(payout: Payout): void;
  selectedMonthYear: string;
  setSelectedMonthYear(date: string): void;
}

export const prestationToItem = (prestation: Prestation) => ({
  id: prestation.id,
  date: prestation.date,
  title: prestation.clientName,
  subtitle: prestation.prestationName,
  value: priceFormatter.format(prestation.price),
  valueAfterCommission: priceFormatter.format(getPriceCommission(prestation)),
  booking: prestation.booking,
});

function PaymentListPageTemplate(props: PaymentListPageTemplateProps) {
  const {
    title,
    closeLink,
    balance,
    prestations,
    payouts,
    openPrestationDetails,
    openPayoutDetails,
    selectedMonthYear,
    setSelectedMonthYear,
  } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const openFilters = () => setIsFiltersOpen(true);
  const closeFilters = () => setIsFiltersOpen(false);

  const onPrestationClick = useCallback(
    (item) =>
      openPrestationDetails(
        prestations.list.find((prestation) => item.id === prestation.id)
      ),
    [openPrestationDetails, prestations.list]
  );
  const onPayoutClick = useCallback(
    (item) => {
      const payout = payouts.list.find((payout) => item.id === payout.id);
      if (payout) {
        openPayoutDetails(payout);
      }
    },
    [openPayoutDetails, payouts.list]
  );

  const balanceComponent = (
    <div className={styles.balanceContainer}>
      <span className={styles.title}>Solde actuel :</span>
      <span className={styles.amount}>{priceFormatter.format(balance)}€</span>
    </div>
  );

  const { isDesktop } = useResponsiveLayout();

  const prestationListTitle = "Paiements clients";
  const prestationListComponent = (
    <div className={styles.prestationListContainer}>
      {isDesktop && (
        <span className={styles.prestationsTitle}>{prestationListTitle}</span>
      )}
      <ListByDate
        infiniteScroll={prestations}
        sort="descending"
        list={prestations.list.map(prestationToItem)}
        itemComponent={(item) => (
          <PrestationItem {...item} onClick={() => onPrestationClick(item)} />
        )}
      />
    </div>
  );

  const payoutListTitle = "Virements reçus";
  const payoutListComponent = (
    <div className={styles.payoutListContainer}>
      {isDesktop && (
        <span className={styles.prestationsTitle}>{payoutListTitle}</span>
      )}
      <ListByDate
        infiniteScroll={payouts}
        sort="descending"
        hideDate
        list={payouts.list.map((payout) => ({
          id: payout.id,
          date: payout.createdAt * 1000,
          title: "Virement Kiute.fr",
          value: `${priceFormatter.format(payout.amount / 100)}€`,
        }))}
        itemComponent={(item) => (
          <PayoutItem {...item} onClick={() => onPayoutClick(item)} />
        )}
      />
    </div>
  );

  const datePickerComponent = (
    <div className={styles.datePickerContainer}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          autoFocus
          variant="inline"
          inputVariant="outlined"
          openTo="month"
          views={["year", "month"]}
          label="Sélectionner un mois"
          InputProps={{
            labelWidth: 165,
          }}
          inputProps={{
            className: styles.input,
          }}
          InputLabelProps={{
            className: styles.label,
          }}
          placeholder="Toutes les dates"
          value={selectedMonthYear !== "" ? moment(selectedMonthYear) : null}
          onChange={(momentDate) =>
            setSelectedMonthYear(momentDate?.toISOString() ?? "")
          }
        />
      </MuiPickersUtilsProvider>
    </div>
  );

  return (
    <>
      <Mobile>
        <PageContainer>
          <DesktopModalContainer open={isFiltersOpen}>
            <React.Fragment>
              <DesktopModalHeader onClose={closeFilters} title="Filtres" />
              {datePickerComponent}
              <ButtonFab onClick={closeFilters}>Valider</ButtonFab>
            </React.Fragment>
          </DesktopModalContainer>

          <MobilePageHeader
            left={
              isIFrame() ? undefined : (
                <Link to={closeLink}>
                  <PreviousIcon />
                </Link>
              )
            }
            center={<span>{title}</span>}
            right={
              <div
                className="calendar_filter_selector-mobile"
                onClick={openFilters}
              >
                Filtres
              </div>
            }
            parent="payment"
          />
          <PageContent>
            <ModalContentMobile dense>{balanceComponent}</ModalContentMobile>
            <ModalContentMobile dense extended>
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={selectedTabIndex}
                onChange={(_, value) => setSelectedTabIndex(value)}
                TabIndicatorProps={{
                  className: styles.tabIndicator,
                }}
                action={(action) => action && action.updateIndicator()}
                classes={{ flexContainer: styles.tabsFlexContainer }}
              >
                <Tab
                  label={prestationListTitle}
                  className={styles.tabContainer}
                />
                <Tab label={payoutListTitle} className={styles.tabContainer} />
              </Tabs>
              {selectedTabIndex === 0 && prestationListComponent}
              {selectedTabIndex === 1 && payoutListComponent}
            </ModalContentMobile>
          </PageContent>
        </PageContainer>
      </Mobile>
      <Desktop>
        <PaymentPageDesktopLayout>
          <div className={styles.desktopContainer}>
            {datePickerComponent}
            {balanceComponent}
            {prestationListComponent}
            {payoutListComponent}
          </div>
        </PaymentPageDesktopLayout>
      </Desktop>
    </>
  );
}

export default PaymentListPageTemplate;
