import React from "react";
import { GREY } from "constants/Style";

const CheckBoxIcon = ({
  color = GREY,
  height = 21,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={height} height={height} viewBox="0 0 21 21" {...props}>
    <rect
      x={400.5}
      y={462.5}
      width={20}
      height={20}
      rx={3}
      transform="translate(-400 -462)"
      fillRule="nonzero"
      stroke={color}
      fill="none"
    />
  </svg>
);

export default CheckBoxIcon;
