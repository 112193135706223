import React from "react";

import { routeList } from "constants/Routes";
import PaymentInfosPageTemplate from "components/payment/PaymentInfosPageTemplate/PaymentInfosPageTemplate";
import useCurrentSalon from "hooks/useCurrentSalon";
import { parseRoute } from "utils/route";

function PaymentInfosPage() {
  const { salon } = useCurrentSalon();
  const proxyholderData = {
    firstName: salon ? salon.billingFirstName : "",
    lastName: salon ? salon.billingLastName : "",
    nationality: salon ? salon.billingNationality : "",
    countryOfResidency: salon ? salon.billingCountry : "",
    email: salon ? salon.email : "",
    phone: salon ? salon.phone : ""
  };
  const companyData = {
    name: salon ? salon.companyName : "",
    address: salon ? salon.billingAddress.address : "",
    siret: salon ? salon.siret : "",
    vatNumber: salon ? salon.tvaNumber : "",
    iban: salon ? salon.iban : "",
    adwordsCampaignId: salon ? salon.adwordsCampaignId : ""
  };
  return (
    <PaymentInfosPageTemplate
      title="Informations de facturation"
      closeLink={parseRoute(routeList.PAYMENT_PAGE)}
      proxyholderData={proxyholderData}
      companyData={companyData}
    />
  );
}

export default PaymentInfosPage;
