import React from "react";

import Desktop from "components/ResponsiveLayout/Desktop";
import Mobile from "components/ResponsiveLayout/Mobile";
import useAgendaFilterSelector from "hooks/useAgendaFilterSelector";
import FilterSelectorDesktop from "./FiltersSelector.desktop/FiltersSelector";
import FilterSelectorMobile from "./FiltersSelector.mobile";

export type IFiltersSelectorProps = ReturnType<
  typeof useAgendaFilterSelector
> & {
  onClose(): void;
  open: boolean;
};

function FiltersSelector(props: IFiltersSelectorProps) {
  return (
    <React.Fragment>
      <Desktop>
        <FilterSelectorDesktop {...props} />
      </Desktop>
      <Mobile>
        <FilterSelectorMobile {...props} />
      </Mobile>
    </React.Fragment>
  );
}

export default FiltersSelector;
