import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import AppContainer from "./containers/App/App";
import configureStore from "./store/configureStore";
import history from "./utils/history";
import moment from "moment";
import "moment/locale/fr";
import { isIE } from "./utils/isIE";
import { IERedirectMessage } from "./components/IERedirectMessage/IERedirectMessage";
import { initSentry } from "./thirdParties/sentry";
import { ReactNativeAppSyncProvider } from "./ReactNativeAppSyncProvider";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import "array-flat-polyfill";
import { DebuggingProvider } from "./DebuggingProvider";

initSentry();

moment.locale("fr");

function Providers({ children }: { children: React.ReactNode }) {
  const store = configureStore({});
  return (
    <ReactNativeAppSyncProvider>
      <Router history={history}>
        <ReduxProvider store={store}>
          <DebuggingProvider>
            <ErrorBoundary>{children}</ErrorBoundary>
          </DebuggingProvider>
        </ReduxProvider>
      </Router>
    </ReactNativeAppSyncProvider>
  );
}

function App() {
  return (
    <Providers>{isIE ? <IERedirectMessage /> : <AppContainer />}</Providers>
  );
}

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
