import React, { memo } from "react";
import { Week } from "reducers/week";
import Button from "../../button/Desktop/Button.desktop";
import { DayName, DAYS } from "constants/Week";
import styles from "./DaySelector.module.scss";

interface DaySelectorProps {
  index: number;
  week: Week;
  saveWeek(index: number, week: Week): void;
}

const DaySelector = ({ index, week, saveWeek }: DaySelectorProps) => (
  <div>
    Jours applicables:
    <br />
    {Object.entries(week.days).map(([dayName, day]) => (
      <Button
        key={dayName}
        text={DAYS[dayName as DayName]}
        className={day ? styles.selectedDay : styles.unselectedDay}
        onClick={() => {
          saveWeek(index, {
            ...week,
            days: {
              ...week.days,
              [dayName]: day ? null : {},
            },
          });
        }}
      />
    ))}
  </div>
);

export default memo(DaySelector);
