import React, { ReactNode } from "react";
import MenuContainer from "../../containers/Menu";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";
import { PARENT_LINKS } from "constants/Menu";
import { Link } from "react-router-dom";

interface MobilePageHeaderProps {
  children?: ReactNode;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  dense?: boolean;
  classes?: {
    left?: string;
    center?: string;
    right?: string;
    container?: string;
  };
  parent?: string;
}

export default function MobilePageHeader({
  left,
  center,
  right,
  children,
  dense,
  classes,
  parent,
}: MobilePageHeaderProps) {
  const containerClassNames = classnames(
    "page-header-mobile",
    classes && classes.container,
    {
      dense,
    },
    {
      "page-header-mobile-iframe": isIFrame(),
    }
  );
  const mainLinecontainerClassNames = classnames(
    "page-header-menu-bar-mobile",
    {
      oneline: !children || dense,
    }
  );
  const leftClassNames = classnames(
    "page-header-menu-bar-left-mobile",
    classes && classes.left
  );
  const centerClassNames = classnames(
    "page-header-menu-bar-center-mobile",
    classes && classes.center
  );
  const rightClassNames = classnames(
    "page-header-menu-bar-right-mobile",
    classes && classes.right
  );
  return (
    <div className={containerClassNames}>
      <div className={mainLinecontainerClassNames}>
        <div className={leftClassNames}>{left ? left : <MenuContainer />}</div>
        {center && (
          <div className={centerClassNames}>
            {isIFrame() && parent && PARENT_LINKS[parent] && (
              <Link style={{ color: "white" }} to={PARENT_LINKS[parent].link}>
                {PARENT_LINKS[parent].text} &gt;{" "}
              </Link>
            )}
            {center}
          </div>
        )}
        {right && <div className={rightClassNames}>{right}</div>}
      </div>
      {children}
    </div>
  );
}
