import React from "react";
import classnames from "classnames";
import { Hairdresser } from "reducers/salon";
import { RatingStats } from "reducers/rating";
import RatingStars from "../RatingStars";
import RateNumber from "../RateNumber/RateNumber";
import HairdresserAvatar from "../../uiElements/HairdresserAvatar/HairdresserAvatar";
import styles from "./HairdresserRatingPreview.module.scss";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";

interface HairdresserRatingPreviewProps {
  hairdresser: Hairdresser;
  selected: boolean;
  onClick(): void;
  ratingStats?: RatingStats;
}

function HairdresserRatingPreview(props: HairdresserRatingPreviewProps) {
  const { hairdresser, selected, onClick, ratingStats } = props;
  const containerClasses = classnames(styles.container, {
    [styles.selected]: selected
  });
  const ratingMeanOutOfTen =
    ratingStats && ratingStats.sum && ratingStats.count
      ? ratingStats.sum / ratingStats.count
      : 0;
  const ratingMeanOutOfFive = ratingMeanOutOfTen / 2;
  return (
    <div className={containerClasses} onClick={onClick}>
      <HairdresserAvatar hairdresser={hairdresser} />
      <div className={styles.responsiveContainer}>
        <div className={styles.infosContainer}>
          <span className={styles.name}>
            {hairdresser.firstName}
            {hairdresser.status === "DISABLED" ? (
              <span className={styles.disabled}> Désactivé</span>
            ) : (
              ""
            )}
          </span>
          <RatingStars rate={ratingMeanOutOfFive} />
          <Mobile>
            <RateNumber rate={ratingMeanOutOfFive} />
          </Mobile>
          <span className="hairdresser-rating-preview-extra">{`Basé sur ${(ratingStats &&
            ratingStats.count) ||
            "0"} avis`}</span>
        </div>
        <Desktop>
          <RateNumber rate={ratingMeanOutOfFive} />
        </Desktop>
      </div>
    </div>
  );
}

export default HairdresserRatingPreview;
