import React from "react";
import { WHITE } from "../../constants/Style";

interface PickerArrowProps {
  color?: string;
  className?: string;
  large?: boolean;
  onClick?(): void;
}

const PickerArrow = ({ large, color, ...props }: PickerArrowProps) => {
  const width = large ? 11 : 9;
  return (
    <svg width={width + 2} height={width} viewBox="0 0 9 9" {...props}>
      <path d={`M4.5 9L9 0H0z`} fill={color || WHITE} fillRule="nonzero" />
    </svg>
  );
};

export default PickerArrow;
