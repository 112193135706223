import React from "react";
import { GREY } from "constants/Style";

function CheckBoxInderterminateIcon({
  color = GREY,
  height = 21,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={height} height={height} viewBox="0 0 21 21" {...props}>
      <g stroke={color} fill="none" fillRule="evenodd">
        <rect x={0.5} y={0.5} width={20} height={20} rx={3} />
        <path strokeLinecap="round" d="M4.45 10.5h12" />
      </g>
    </svg>
  );
}

export default CheckBoxInderterminateIcon;
