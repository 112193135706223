import React from "react";
import { WHITE } from "../../../constants/Style";

const BurgerMenuIcon = ({ color = WHITE, ...props }: any) => (
  <svg width={21} height={16} viewBox="0 0 21 16" {...props}>
    <g fill={color} fillRule="nonzero">
      <rect width={21} height={2} rx={1} />
      <rect y={7} width={21} height={2} rx={1} />
      <rect y={14} width={21} height={2} rx={1} />
    </g>
  </svg>
);

export default BurgerMenuIcon;
