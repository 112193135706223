import React from "react";
import { BLUE_GREY } from "constants/Style";

function FileDownloadIcon({
  color = BLUE_GREY,
  height = 27,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  const heightNumber = typeof height === "string" ? parseInt(height) : height;
  const width = (heightNumber / 27) * 22;
  return (
    <svg width={width} height={heightNumber} viewBox="0 0 22 27" {...props}>
      <g transform="translate(-859 -129)" fill="none" fillRule="evenodd">
        <path d="M345-108h1110v1173H345z" />
        <rect
          fill="#FFF"
          fillRule="nonzero"
          x={304.5}
          y={95.5}
          width={619}
          height={731}
          rx={4}
        />
        <g fill={color} fillRule="nonzero">
          <path d="M870 148.406a.845.845 0 01-.598-.247l-3.385-3.375a.841.841 0 010-1.193.847.847 0 011.197 0l2.786 2.778 2.786-2.778a.847.847 0 011.197 0c.33.33.33.863 0 1.193l-3.385 3.375a.845.845 0 01-.598.247z" />
          <path d="M870 148.406a.845.845 0 01-.846-.844v-8.437a.845.845 0 011.692 0v8.438c0 .466-.379.843-.846.843zM875.077 151.781h-10.154a.845.845 0 110-1.687h10.154a.844.844 0 110 1.687z" />
          <path d="M878.83 156h-17.657c-1.198 0-2.173-.948-2.173-2.112v-17.333c0-.733.299-1.451.818-1.97l4.782-4.768a2.778 2.778 0 011.976-.817h12.253c1.197 0 2.171.947 2.171 2.11v22.78c0 1.163-.973 2.11-2.17 2.11zm-12.254-25.313c-.294 0-.57.114-.779.322l-4.782 4.769a1.105 1.105 0 00-.323.777v17.333c0 .234.216.425.48.425h17.657c.264 0 .479-.19.479-.423v-22.78c0-.233-.215-.423-.48-.423h-12.252z" />
          <path d="M865.346 137.438h-2.961a.845.845 0 01-.847-.844c0-.466.38-.844.847-.844h2.961a.423.423 0 00.423-.422v-2.953a.845.845 0 011.693 0v2.953c0 1.163-.95 2.11-2.116 2.11z" />
        </g>
      </g>
    </svg>
  );
}

export default FileDownloadIcon;
