import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  deleteSlot as deleteSlotAction,
  deleteRecurrentSlot as deleteRecurrentSlotAction,
} from "actions/SlotActions";
import { pushMessage } from "actions/SnackbarActions";
import { dateInputFormat } from "constants/Input";
import { MESSAGE_TYPES } from "constants/SnackBar";
import FreeSlotForm from "components/agenda/FreeSlotForm/FreeSlotForm";
import CloseConfirmationModal from "components/agenda/FreeSlotForm/CloseConfirmationModal";
import useCurrentSalon from "hooks/useCurrentSalon";
import { AppState } from "reducers";
import { Slot } from "reducers/slot";
import { mergeFormValues } from "utils/form";
import { createSlotFromForm, IFormValues, MODE } from "utils/slot";

import EditInfoModal from "components/agenda/FreeSlotForm/EditInfoModal";
import { PartnerRef } from "reducers/salon";

function salonIsLoadingSelector(state: AppState) {
  return state.salon.loading;
}

interface FreeSlotFormContainerProps {
  onClose(): void;
  date?: moment.Moment;
  slotToEdit?: Slot | Omit<Slot, "id">;
  refetchSlots(): void;
}
function FreeSlotFormContainer({
  date: propsDate,
  onClose,
  slotToEdit,
  refetchSlots,
}: FreeSlotFormContainerProps) {
  const { hairdressers } = useCurrentSalon();
  const initialDate =
    slotToEdit && slotToEdit.range?.start
      ? moment(slotToEdit.range.start).format(dateInputFormat)
      : moment(propsDate).format(dateInputFormat);
  const [formValues, setFormValues] = useState<IFormValues>({
    hairdresserIds:
      slotToEdit && slotToEdit.hairdresserId ? [slotToEdit.hairdresserId] : [],
    hairdresserStringIds:
      slotToEdit && slotToEdit.hairdresserStringId
        ? [slotToEdit.hairdresserStringId]
        : [],
    date: initialDate,
    start:
      slotToEdit && slotToEdit.range?.start
        ? moment(slotToEdit.range?.start).format("HH:mm")
        : "",
    end:
      slotToEdit && slotToEdit.range?.end
        ? moment(slotToEdit.range?.end).format("HH:mm")
        : "",
    partnerRef: slotToEdit?.partnerRef,
    repeatSlot: false,
    repeatSlotWeekDays: [moment(initialDate).day()],
    repeatSlotNumberOfWeek: 0,
    discount: slotToEdit ? slotToEdit.discount : -1,
  });
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mode = slotToEdit && "id" in slotToEdit ? MODE.UPDATE : MODE.CREATE;

  const openConfirmationModal = () => setConfirmationModalOpened(true);

  const closeConfirmationModal = () => setConfirmationModalOpened(false);

  const [editInfoModalOpened, setEditInfoModalOpened] = useState(false);

  const cancel = () => {
    closeConfirmationModal();
    onClose();
  };

  const dispatch = useDispatch();
  const { salon, companyCode } = useCurrentSalon();

  const submitHandler = (hasAcceptedModif = false) => {
    const { start, end, discount } = formValues;
    if (!start || !end) return;
    // Displays a warning if discount has been changed
    if (slotToEdit && !hasAcceptedModif && slotToEdit.discount !== discount) {
      setEditInfoModalOpened(true);
      return;
    }
    setIsSubmitting(true);
    createSlotFromForm(
      formValues,
      slotToEdit,
      mode,
      dispatch,
      companyCode,
      salon?.options?.oneCatalogV2
    ).then(() => {
      dispatch(
        pushMessage("Le créneau a été crée avec succés", MESSAGE_TYPES.SUCCESS)
      );
      setIsSubmitting(false);
      onClose();
      refetchSlots();
    });
  };

  const deleteSlot = () => {
    if (slotToEdit && "id" in slotToEdit) {
      ((dispatch(deleteSlotAction(slotToEdit.id)) as unknown) as Promise<
        any
      >).then(() => {
        dispatch(
          pushMessage(
            "Le créneau a été supprimé avec succés",
            MESSAGE_TYPES.SUCCESS
          )
        );
        onClose();
      });
    }
  };

  const deleteRecurrentSlot = () => {
    if (slotToEdit && "id" in slotToEdit && slotToEdit?.recurrence?.id) {
      ((dispatch(
        deleteRecurrentSlotAction(slotToEdit.id)
      ) as unknown) as Promise<any>).then(() => {
        dispatch(
          pushMessage(
            "La reccurence a été supprimée avec succès",
            MESSAGE_TYPES.SUCCESS
          )
        );
        onClose();
      });
    }
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ) => {
    const name = event.target.name as keyof IFormValues;
    const eventValue =
      checked === true || checked === false ? checked : event.target.value;
    const currentValue = formValues[name];
    const newValue = mergeFormValues(currentValue, eventValue);

    setFormValues((oldFromValues) => ({
      ...oldFromValues,
      [name]: newValue,
    }));

    switch (name) {
      case "date":
        const repeatSlotWeekDays = formValues.repeatSlotWeekDays;
        const repeatSlot = formValues.repeatSlot;
        const day = moment(eventValue as string).day();
        if (!repeatSlotWeekDays.includes(day)) {
          changeHandler(({
            target: {
              name: "repeatSlotWeekDays",
              value: repeatSlot ? [...repeatSlotWeekDays, day] : [day],
            },
          } as unknown) as React.ChangeEvent<HTMLInputElement>);
        }
        break;
      case "repeatSlot":
        if (eventValue === false) {
          const { date } = formValues;
          const day = moment(date).day();
          changeHandler(({
            target: {
              name: "repeatSlotWeekDays",
              value: [day],
            },
          } as unknown) as React.ChangeEvent<HTMLInputElement>);
        }
        break;
      default:
        break;
    }
  };

  const salonIsLoading = useSelector(salonIsLoadingSelector);

  return (
    <React.Fragment>
      <FreeSlotForm
        mode={mode}
        isLoading={isSubmitting && salonIsLoading}
        hairdressers={hairdressers}
        formValues={formValues}
        onClose={openConfirmationModal}
        onSubmit={submitHandler}
        onDelete={deleteSlot}
        onDeleteRecurrence={deleteRecurrentSlot}
        onChange={changeHandler}
        recurrenceId={slotToEdit?.recurrence?.id || undefined}
      />
      <CloseConfirmationModal
        isOpened={confirmationModalOpened}
        onClose={closeConfirmationModal}
        onSubmit={cancel}
      />
      <EditInfoModal
        isOpened={editInfoModalOpened}
        onClose={() => setEditInfoModalOpened(false)}
        onSubmit={() => submitHandler(true)}
      />
    </React.Fragment>
  );
}

export default FreeSlotFormContainer;
