import { routes } from "../constants/Routes";

export const parseRoute = (routeName: string = "", params: any = {}) =>
  Object.keys(params).reduce(
    (routeStr, paramKey) => routeStr.replace(`:${paramKey}`, params[paramKey]),
    routes[process.env.REACT_APP_LOCALE as string][routeName]
  );

const slugify = (str: string) =>
  // [\u0300-\u036f] matches the accents separated from normalise()
  str
    .toLowerCase()
    .split(" ")
    .join("-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const getSalonLink = (
  slug: string = "",
  city: string = "",
  oldClient: boolean = false
) =>
  `${process.env.REACT_APP_LECISEAU_URL}salon/${slug}-${slugify(city)}${
    oldClient ? "/?source=salon" : ""
  }`;
