import React, { useState, useEffect, memo } from "react";
import { Day, Week, Days } from "reducers/week";
import styles from "./styles.module.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../button/Desktop/Button.desktop";
import LargeClickable from "../../uiElements/LargeClickable";
import CloseIcon from "components/svgs/CloseIcon";
import SingleDayForm from "./SingleDayForm";
import { DayName, DAYS } from "constants/Week";

interface WeekFormProps {
  week: Week;
  saveWeek(week: Week): void;
  isOpened: boolean;
  close(): void;
  hairdresserName: string;
}

const WeekForm = ({
  week,
  saveWeek,
  isOpened,
  close,
  hairdresserName,
}: WeekFormProps) => {
  const [days, setDays] = useState({} as Days);

  const setDay = (dayName: string, day: Day | null) => {
    setDays({
      ...days,
      [dayName]: day,
    });
  };

  useEffect(() => {
    setDays(week.days);
  }, [week.days]);

  return (
    <Dialog
      open={isOpened}
      onClose={() => close()}
      aria-labelledby="responsive-dialog-title"
      id="fuller-width-dialog"
      maxWidth={"xl"}
      fullWidth
    >
      <DialogTitle id="big-h2">
        Horaires d'ouverture - {hairdresserName}
      </DialogTitle>
      <DialogContent id="no-padding-dialog">
        <LargeClickable
          className={styles.stickTopRight}
          onClick={() => close()}
        >
          <CloseIcon onClick={() => close()} color={"#000000"} />
        </LargeClickable>
        <div className={styles.weekFormContainer}>
          {Object.keys(days).map((dayName: string) => {
            const textDayName = DAYS[dayName as DayName];
            return (
              <SingleDayForm
                setDay={setDay}
                dayName={dayName}
                textDayName={textDayName}
                day={days[dayName as DayName]}
              />
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          secondary
          onClick={() => {
            close();
            saveWeek({ ...week, days });
          }}
          text="Valider les horaires"
        />
      </DialogActions>
    </Dialog>
  );
};

export default memo(WeekForm);
