import React, { useState } from "react";
import moment from "moment";
import RatingStars from "../RatingStars";
import NextIcon from "../../svgs/NextIcon";
import { BLUE_GREY } from "../../../constants/Style";
import { Rating, RatingCommentReply } from "../../../reducers/rating";
import { hasKey } from "../../../utils/object";
import { GENDERS } from "../../../constants/Account";
import { Booking } from "../../../reducers/booking";
import styles from "./RatingComment.module.scss";
import classnames from "classnames";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";
import useResponsiveLayout from "../../ResponsiveLayout/useResponsiveLayout";
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import DesktopButton from "../../button/Desktop/Button.desktop";

const getRate = (rating: Rating, type: string) => {
  return hasKey(rating, `${type}Rate`) && (rating as any)[`${type}Rate`]
    ? (rating as any)[`${type}Rate`] / 2
    : (rating.rate && rating.rate / 2) || 0;
};

const getComment = (rating: Rating, type: string) => {
  return hasKey(rating, `${type}Comment`) && (rating as any)[`${type}Comment`]
    ? (rating as any)[`${type}Comment`]
    : rating.comment || "";
};

const getRatingUsername = (rating: Rating) =>
  rating.user ? `${rating.user.firstName} ${rating.user.lastName}` : "";

const getPackageName = (booking: Booking) => {
  return booking?.snapshotPackages[0]?.name ?? "";
};

const getHairdresserText = (booking: Booking, rating: Rating) => {
  return rating.hairdresser
    ? `par ${rating.hairdresser?.firstName} le ${moment(
        booking?.dateStart
      ).format("DD/MM/YYYY à HH[h]mm")}`
    : "";
};

const isReplyAllowed = (rating: Rating) => rating.replies.length < 1;

const noop = () => {};

type RatingType = "HAIRDRESSER" | "SALON" | "USER";

interface RatingCommentCommonProps {
  type: RatingType;
  rating: Rating;
  expanded?: boolean;
}

interface RatingCommentBaseProps extends RatingCommentCommonProps {
  onClickBody?(): void;
  children?: React.ReactNode;
}

export function RatingCommentBase({
  rating,
  type,
  onClickBody,
  expanded,
  children,
}: RatingCommentBaseProps) {
  const { booking } = rating;
  if (!booking) return null;
  const lowerCaseType = type.toLowerCase();
  const rate = getRate(rating, lowerCaseType);
  const comment = getComment(rating, lowerCaseType);
  const userName = getRatingUsername(rating);
  const packageName = getPackageName(booking);
  const hairdresserText = getHairdresserText(booking, rating);
  const containerClassNames = classnames(styles.container, {
    [styles.expanded]: expanded,
  });
  return (
    <div className={containerClassNames}>
      <div className={styles.headerContainer}>
        <div className={styles.left}>
          <RatingStars rate={rate} />
          <span className={styles.title}>{userName}</span>
        </div>
        <span className={styles.right}>{`Publié le ${moment(
          booking.dateStart
        ).format("DD/MM/YYYY")}`}</span>
      </div>
      <div className={styles.body} onClick={onClickBody}>
        <div className={styles.title}>
          <div className={styles.left}>
            <span className={styles.package}>{packageName}</span>
            <Mobile>
              <span className={styles.hairdresser}>{hairdresserText}</span>
            </Mobile>
          </div>
          <Mobile>
            {onClickBody && (
              <NextIcon color={BLUE_GREY} className={styles.right} />
            )}
          </Mobile>
          <Desktop>
            <span className={styles.hairdresser}>{hairdresserText}</span>
          </Desktop>
        </div>
        <span className={styles.comment}>{comment}</span>
        <div>
          {rating.replies.length > 0 &&
            rating.replies.map((reply: RatingCommentReply) => (
              <div key={reply.id} className={styles.replyContainer}>
                <div className={styles.replyInnerContainer}>
                  <span className={styles.content}>{reply.content}</span>
                  <span className={styles.meta}>
                    Réponse de l'établissement - Le{" "}
                    {moment(reply.createdAt).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            ))}
        </div>
        {children}
      </div>
    </div>
  );
}

interface RatingCommentProps extends RatingCommentCommonProps {
  openReplyModal(rating: Rating): void;
  onSubmitReply(reply: string): void;
}

function RatingComment({
  rating,
  openReplyModal,
  onSubmitReply,
  ...props
}: RatingCommentProps) {
  const { isMobile } = useResponsiveLayout();
  const userName = getRatingUsername(rating);
  const canReply = isReplyAllowed(rating);
  const onClickBody =
    isMobile && canReply ? () => openReplyModal(rating) : undefined;
  return (
    <RatingCommentBase rating={rating} onClickBody={onClickBody} {...props}>
      <Desktop>
        {canReply && (
          <ReplyInput
            label={`Répondre à ${userName}`}
            onSubmit={onSubmitReply}
          />
        )}
      </Desktop>
    </RatingCommentBase>
  );
}

function ReplyInput({
  onSubmit,
  ...props
}: Omit<OutlinedTextFieldProps, "variant" | "onSubmit"> & {
  onSubmit(value: string): void;
}) {
  const [value, setValue] = useState("");
  const canSubmit = value.trim().length > 0;
  const submit = () => {
    canSubmit && onSubmit(value);
  };
  const submitButton = canSubmit && (
    <DesktopButton
      dense
      primary
      text="Envoyer"
      onClick={submit}
      className={styles.submitReply}
    />
  );
  return (
    <div className={styles.replyInputContainer}>
      <TextField
        variant="outlined"
        fullWidth
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        multiline
        size="small"
        className={styles.replyTextField}
        InputLabelProps={{
          classes: {
            root: styles.labelRoot,
            shrink: styles.labelShrink,
          },
        }}
        InputProps={{
          classes: {
            root: styles.inputRoot,
            notchedOutline: styles.notchedOutline,
          },
          endAdornment: submitButton,
        }}
        {...props}
      />
    </div>
  );
}

export default RatingComment;
