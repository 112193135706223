import React from "react";
import { useForm } from "../../hooks/useForm";
import Checkbox from "../inputs/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ButtonFab from "../button/Fab";
import Desktop from "../ResponsiveLayout/Desktop";
import Mobile from "../ResponsiveLayout/Mobile";
import ExternalPageLayoutDesktop from "../layouts/ExternalPageLayout/ExternalPageLayout.desktop";
import ExternalPageLayoutMobile from "../layouts/ExternalPageLayout/ExternalPageLayout.mobile";
import "./term-agreement.scss";

export interface ITermAgreementFormProps {
  onValidate(): Promise<void>;
}

export interface ITermAgreementPageProps extends ITermAgreementFormProps {
  onCancel(): void;
}

const termsToAgree = {
  isLegalOwner:
    "Je déclare être le représentant légal de l'établissement et reconnait endosser la responsabilité de l'utilisation du compte ouvert au nom de l'établissement",
  acceptTerms:
    "J'ai lu et j'accepte les conditions générales d'utilisation Kiute, ainsi que les conditions générales d'utilisation du préstataire de paiement Stripe",
  pricesAreCorrect:
    "Je certifie que les prix déclarés auprès de Kiute sont identiques à ceux pratiqués en salon",
};

const formInitialValues = Object.keys(termsToAgree).reduce(
  (terms, currentTerm) => ({ ...terms, [currentTerm]: false }),
  {}
);

const formValidator = (values: any) => {
  const errors: any = {};
  Object.entries(values).forEach(([term, isAccepted]) => {
    if (!isAccepted) {
      errors[term] = "Doit être accépté";
    }
  });
  return errors;
};

function TermAgreementPageForm({ onValidate }: ITermAgreementFormProps) {
  const {
    handleSubmit,
    handleChange,
    values,
    getMuiErrorProps,
    isSubmitting,
  } = useForm({
    initialValues: formInitialValues,
    onSubmit: onValidate,
    validate: formValidator,
  });
  return (
    <div className="term-agreement-form-container">
      <form onSubmit={handleSubmit}>
        {Object.entries(termsToAgree).map(([term, label]) => {
          const { error, helperText } = getMuiErrorProps(term);
          return (
            <div key={term} className="term-agreement-chekbox-container">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values[term]}
                    value={term}
                    onChange={handleChange(term)}
                    classes={{ root: "term-agreement-chekbox" }}
                  />
                }
                label={label}
              />
              <FormHelperText
                error={error}
                classes={{ root: "term-agreement-chekbox-error" }}
              >
                {helperText}
              </FormHelperText>
            </div>
          );
        })}
      </form>
      <ButtonFab onClick={handleSubmit} isLoading={isSubmitting}>
        Confirmer
      </ButtonFab>
    </div>
  );
}

const pageTitle = "Conditions d'utilisation";

function TermAgreementPage({ onValidate, onCancel }: ITermAgreementPageProps) {
  const form = <TermAgreementPageForm onValidate={onValidate} />;
  return (
    <React.Fragment>
      <Desktop>
        <ExternalPageLayoutDesktop title={pageTitle} onClose={onCancel}>
          {form}
        </ExternalPageLayoutDesktop>
      </Desktop>
      <Mobile>
        <ExternalPageLayoutMobile title={pageTitle} onClose={onCancel}>
          {form}
        </ExternalPageLayoutMobile>
      </Mobile>
    </React.Fragment>
  );
}

export default TermAgreementPage;
export { TermAgreementPageForm, pageTitle };
