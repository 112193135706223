import React from "react";

const MetroIcon = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M24 12.059C24 5.399 18.42-.002 12.03-.002 5.4-.002 0 5.398 0 12.059c0 6.6 5.4 11.94 12.03 11.94 6.63 0 11.97-5.31 11.97-11.94zm-1.65 0c0 5.64-4.65 10.35-10.32 10.35-5.7 0-10.38-4.65-10.38-10.35 0-5.731 4.68-10.47 10.38-10.47 5.67 0 10.32 4.77 10.32 10.47zm-4.38 5.31V6.899c0-.6-.33-1.23-1.32-1.23-.75 0-1.05.329-1.38.99l-3.24 6.75H12L8.73 6.659c-.33-.661-.631-.991-1.381-.991-.99 0-1.32.63-1.32 1.23v10.47c0 .57.45.901.99.901.481 0 1.02-.33 1.02-.9v-7.8h.03l2.97 6.06c.21.42.48.66.96.66s.75-.24.96-.66l2.971-6.06h.03v7.8c0 .57.54.9 1.02.9.539 0 .99-.33.99-.9z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default MetroIcon;
