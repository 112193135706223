import React from "react";

const RerIcon = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M24 12.06C24 5.4 18.42 0 12.03 0 5.4 0 0 5.4 0 12.06 0 18.63 5.4 24 12.03 24 18.66 24 24 18.69 24 12.06zm-1.65 0c0 5.64-4.65 10.29-10.319 10.29-5.701 0-10.38-4.621-10.38-10.29 0-5.73 4.679-10.44 10.38-10.44 5.67 0 10.319 4.74 10.319 10.44zm-1.74-1.92c0-1.62-1.2-2.37-2.37-2.37h-2.25c-.42 0-.66.33-.659.69v7.05c0 .42.419.63.809.63.45 0 .81-.21.81-.63v-2.73h.69l1.35 3.03c.12.24.33.33.57.33.48 0 1.17-.45.9-.99l-1.29-2.7c.78-.39 1.44-1.08 1.44-2.31zm-6.09 5.22c0-.36-.24-.75-.66-.75h-2.28l.002-2.07h1.888c.42 0 .66-.36.66-.69 0-.36-.24-.69-.66-.69H11.58V9.27h2.1c.42 0 .659-.42.659-.75 0-.36-.24-.75-.66-.75h-3.06c-.42 0-.66.33-.66.69v7.02c0 .42.421.63.81.63h3.09c.42 0 .66-.42.66-.75zM9 10.14c0-1.62-1.2-2.37-2.37-2.37H4.38c-.419 0-.659.33-.66.69v7.05c0 .42.42.63.81.63.45 0 .811-.21.811-.63v-2.73h.69l1.35 3.03c.12.24.33.33.57.33.479 0 1.17-.45.9-.99l-1.29-2.7C8.34 12.06 9 11.37 9 10.14zm9.93.18c0 1.02-.93 1.26-1.5 1.26h-.48V9.15h.57c.75 0 1.41.33 1.41 1.17zm-11.61 0c0 1.02-.93 1.26-1.5 1.26h-.48l.001-2.43h.569c.75 0 1.41.33 1.41 1.17z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default RerIcon;
