import { createStore, StoreEnhancer, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistState } from "redux-devtools";
import rootReducer from "../reducers";
import DevTools from "../containers/DevTools";

const statePersistor = persistState(
  (window as any).location.href.match(/[?&]debug_session=([^&#]+)\b/)
);

const enhancer: StoreEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
      applyMiddleware(thunk),
      statePersistor
    )
  : compose(applyMiddleware(thunk), DevTools.instrument(), statePersistor);

export default function configureStoreDev(initialState: any) {
  const store = createStore(rootReducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept("../reducers", () =>
      store.replaceReducer(require("../reducers").default)
    );
  }

  return store;
}
