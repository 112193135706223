import React, { useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import BurgerMenuIcon from "../../svgs/Menu/BurgerMenuIcon";
import CloseIcon from "../../svgs/CloseIcon";
import ButtonText from "../../button/Text";
import { Link } from "react-router-dom";
import NextIcon from "../../svgs/NextIcon";
import { LIGHT_BLUE_GREY } from "../../../constants/Style";
import LargeClickable from "../../uiElements/LargeClickable";
import "./menu.mobile.scss";
import SalonSelector from "components/SalonSelector";
import PhaseTwo from "components/svgs/LogoTransition/PhaseTwo";
import { isIFrame } from "utils/helpers";
import { IDesktopMenuItem } from "components/Menu/Desktop/MenuItem/MenuItem.desktop";
import IFrameMenuItem from "components/Menu/Desktop/MenuItem/MenuItem.iframe";
import { MENU_ITEMS_DESKTOP } from "constants/Menu";
import { SublinksConditions } from "constants/Menu";

interface IMobileMenuItem {
  title: string;
  link: string;
  conditions?(conditions: SublinksConditions): boolean;
}

const MenuMobileIFrame = (props: IMenuProps<IMobileMenuItem>) => {
  const { onLogout } = props;
  const items = MENU_ITEMS_DESKTOP;
  const [isOpened, setIsOpened] = useState(false);
  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  return (
    <React.Fragment>
      <LargeClickable onClick={open}>
        <BurgerMenuIcon onClick={open} />
      </LargeClickable>
      <SwipeableDrawer
        onClose={close}
        onOpen={open}
        open={isOpened}
        classes={{ paper: "menu-mobile-drawer__paper" }}
      >
        <div className="menu-mobile-container menu-mobile-over-container-iframe">
          <div className="menu-mobile-scrollable-container menu-mobile-container-iframe">
            <div className="menu-mobile-header menu-mobile-header-iframe">
              <LargeClickable onClick={close}>
                <CloseIcon onClick={close} />
              </LargeClickable>
              <span className="mkpTitle">Market Place Kiute.fr</span>
            </div>
            <div className={"menu-desktop menu-desktop-iframe no-shadow"}>
              <div className={"menu-desktop-nav menu-desktop-nav-iframe"}>
                {items.map((item) => (
                  <IFrameMenuItem
                    key={item.title}
                    {...item}
                    onAction={() => {}}
                    extraClassName={undefined}
                    mobile
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="menu-mobile-footer">
            <ButtonText color="error" justify="flex-start" onClick={onLogout}>
              Se déconnecter
            </ButtonText>
          </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

function MenuMobile(props: IMenuProps<IMobileMenuItem>) {
  const { items, onLogout } = props;
  const [isOpened, setIsOpened] = useState(false);
  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  if (isIFrame()) {
    return <MenuMobileIFrame {...props} />;
  }

  return (
    <React.Fragment>
      <LargeClickable onClick={open}>
        <BurgerMenuIcon onClick={open} />
      </LargeClickable>
      <SwipeableDrawer
        onClose={close}
        onOpen={open}
        open={isOpened}
        classes={{ paper: "menu-mobile-drawer__paper" }}
      >
        <div className="menu-mobile-container">
          <div className="menu-mobile-header">
            <div>
              <PhaseTwo width={150} />
            </div>
            <LargeClickable onClick={close}>
              <CloseIcon dark onClick={close} />
            </LargeClickable>
          </div>
          <div className="menu-mobile-content">
            <SalonSelector onSalonChange={close} />
          </div>
          <div className="menu-mobile-scrollable-container">
            {items.map((item) => (
              <Link
                key={item.title}
                to={item.link}
                onClick={close}
                className="menu-mobile-list_item"
              >
                <span className="menu-mobile-list_item__text">
                  {item.title}
                </span>
                <NextIcon color={LIGHT_BLUE_GREY} />
              </Link>
            ))}
          </div>
          <div className="menu-mobile-footer">
            <ButtonText color="error" justify="flex-start" onClick={onLogout}>
              Se déconnecter
            </ButtonText>
          </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default MenuMobile;
