import React, { memo, useState, useEffect } from "react";
import { Day } from "reducers/week";
import styles from "./styles.module.scss";
import Button from "../../button/Desktop/Button.desktop";
import Switch from "@material-ui/core/Switch";
import DesktopTimePicker from "components/inputs/TimePicker/TimePicker.desktop";
import Delete from "components/svgs/Delete";
import SmallAddCircle from "components/svgs/SmallAddCircle";
import {
  dayToPeriods,
  periodsToDay,
  extractPeriodLimits,
  getDisabledHoursEnd,
  getDisabledHoursStart,
} from "utils/week";
import { Period } from "constants/Week";

interface SingleDayFormProps {
  dayName: string;
  textDayName: string;
  day: Day | null;
  setDay(dayName: string, day: Day | null): void;
}

const SingleDayForm = ({
  dayName,
  day,
  setDay,
  textDayName,
}: SingleDayFormProps) => {
  const [periods, setPeriods] = useState([{ start: "", end: "" }]);

  useEffect(() => {
    setPeriods(dayToPeriods(day));
  }, [day]);

  const updateSinglePeriod = (index: number, newPeriod: Period | undefined) => {
    const newPeriods = periods
      .map((period, pIndex) => {
        if (index === pIndex) {
          return newPeriod;
        }
        return period;
      })
      .filter(Boolean) as Array<Period>;
    setPeriods(newPeriods);
    setDay(dayName, periodsToDay(newPeriods));
  };

  const addPeriod = () => setPeriods([...periods, { start: "", end: "" }]);

  return (
    <div className={styles.dayFormLine}>
      <div className={styles.dayPart}>{textDayName}</div>
      {/* TODO: Put the following in a component */}
      <div className={styles.timePickers}>
        {day &&
          periods.map((period: Period, index: number) => {
            // Limit the period within the bounds of the previous
            // and next period
            const { minMax, periodMoment } = extractPeriodLimits(
              periods,
              period,
              index
            );
            return (
              <div
                className={
                  index > 0 ? styles.singlePickerSeparator : styles.singlePicker
                }
                key={index}
              >
                {index > 0 ? (
                  <div className={styles.separatorHideMobile}>/</div>
                ) : (
                  ""
                )}
                <DesktopTimePicker
                  className={styles.shortPicker}
                  label="Début :"
                  name="start"
                  value={periodMoment.start || undefined}
                  onChange={(event: any) =>
                    updateSinglePeriod(index, {
                      start: event.target.value,
                      end: period.end,
                    })
                  }
                  minuteStep={30}
                  format="HH:mm"
                  disabledHours={() =>
                    getDisabledHoursStart(periodMoment, minMax)
                  }
                />
                <DesktopTimePicker
                  className={styles.shortPicker}
                  label="Fin :"
                  name="end"
                  value={periodMoment.end || undefined}
                  onChange={(event: any) =>
                    updateSinglePeriod(index, {
                      start: period.start,
                      end: event.target.value,
                    })
                  }
                  minuteStep={30}
                  format="HH:mm"
                  disabledHours={() =>
                    getDisabledHoursEnd(periodMoment, minMax)
                  }
                />
                <div
                  className={styles.inlineBtn}
                  onClick={() => updateSinglePeriod(index, undefined)}
                >
                  <Delete />
                </div>
              </div>
            );
          })}
        {periods.length < 2 && !!day && (
          <div className={styles.dayPart}>
            <Button
              className={styles.textButton}
              onClick={() => addPeriod()}
              element={
                <span>
                  <SmallAddCircle />
                  Ajouter une période
                </span>
              }
            />
          </div>
        )}
      </div>
      <div className={styles.dayPart}>
        <Switch
          checked={!!day}
          onChange={() =>
            day
              ? setDay(dayName, null)
              : setDay(dayName, { start: "", end: "", unavailabilities: [] })
          }
        />
      </div>
    </div>
  );
};

export default memo(SingleDayForm);
