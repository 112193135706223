import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";

interface ButtonProps {
  children?: React.ReactNode;
  color?: string;
  onClick?(): void;
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
}

export default function ButtonTex(props: ButtonProps) {
  const { children, onClick, color, justify } = props;
  const buttonTextClasses = classNames("button-text", {
    "color-error": color === "error"
  });
  return (
    <Grid
      container
      justify={justify || "space-around"}
      className="button-container"
    >
      <Button variant="text" className={buttonTextClasses} onClick={onClick}>
        {children}
      </Button>
    </Grid>
  );
}
