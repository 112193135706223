/**
 * Colors
 */

export const LOGO_CIRCLE_COLOR = "#24bea1";
export const TEALISH = "#06038D";
export const DARK = "#252734";
export const GREY = "#A0A0A6";
export const BUTTON_GREY = "#7d7e86";
export const BLUE_GREY = "#929299";
export const LIGHT_GREY = "#979797";
export const LIGHT_BLUE_GREY = "#c9cacc";
export const VERY_LIGHT_BLUE = "#e7ebef";
export const VERY_LIGHT_GREY = "#f6f8fc";
export const RATING_GREY = "#4b4b4b";
export const PINK = "#ee7c56";
export const YELLOW = "#ffd200";
export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const FLEXY_RED = "#EF3340";
export const SALMON = "#f87c56";

export const OPACITY = {
  4: "0A",
  10: "1A",
  20: "33",
  30: "4D",
  40: "66",
  50: "80",
  60: "99",
  70: "B3",
  80: "CC",
  90: "E6",
};
