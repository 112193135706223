import { Reducer } from "redux";
import * as yup from "yup";

import { AuthActions } from "actions/AuthActions";
import * as types from "constants/ActionTypes";
// import { getAuthCache } from "utils/auth";

const ROLE = {
  USER: "USER" as const,
  SALON: "SALON" as const,
  ADMIN: "ADMIN" as const,
  SALON_MANAGER: "SALON_MANAGER" as const
};
const roles = Object.values(ROLE);

export const loginSchema = yup.object().shape({
  authId: yup.number().required(),
  token: yup.string().required(),
  userId: yup.number().nullable(),
  mobile: yup.string().nullable(),
  roles: yup
    .array()
    .required()
    .of(yup.mixed<keyof typeof ROLE>().oneOf(roles)),
  salonIds: yup
    .array()
    .of(yup.number())
    .nullable()
});

export type IAuthLogin = yup.InferType<typeof loginSchema>;
export type AuthInfos = IAuthLogin & {
  username: string;
};

export type AuthPayload = AuthInfos | {};

export interface AuthState {
  loading: boolean;
  payload: AuthPayload;
  error: string | null;
}

const getAuthCache = (key = "auth") => {
  try {
    const item = JSON.parse(localStorage.getItem(key) as string);
    return !item || item == null ? {} : item;
  } catch (err) {
    return {};
  }
};
const authCache = getAuthCache();

const initialState: AuthState = {
  loading: false,
  payload: authCache.token
    ? {
        userId: authCache.userId,
        username: authCache.username,
        roles: authCache.roles,
        mobile: authCache.mobile,
        token: authCache.token,
        authId: authCache.authId,
        salonIds: authCache.salonIds || []
      }
    : {},
  error: null
};

const auth: Reducer<AuthState, AuthActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.CLASSIC_LOGIN:
      return {
        ...state,
        ...{ loading: true, error: null }
      };
    case types.LOGIN_SUCCESS:
      localStorage.setItem("auth", JSON.stringify(action.payload));
      return {
        ...state,
        ...{ loading: false, payload: action.payload, error: null }
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        ...{ loading: false, error: action.error }
      };
    case types.LOGOUT:
      localStorage.setItem("auth", "");
      return {
        ...state,
        ...{
          loading: false,
          payload: {
            userId: undefined,
            roles: undefined,
            username: undefined,
            mobile: undefined,
            token: undefined,
            authId: undefined,
            salonIds: []
          }
        }
      };
    default:
      return state;
  }
};

export default auth;
