import moment from "moment";

const mergeFormValues = (currentValue: any, newValue: any): any => {
  if (Array.isArray(currentValue)) {
    if (Array.isArray(newValue)) {
      return newValue.filter((v) => v !== undefined && v !== null);
    }
    return [...currentValue, newValue].filter(
      (v) => v !== undefined && v !== null
    );
  } else if (typeof currentValue === "object") {
    return {
      ...currentValue,
      ...newValue,
    };
  }
  return newValue;
};

const timeInputToMoment = (
  time: string,
  date = moment().toISOString()
): moment.Moment => {
  const [hours, minutes] = time.split(":");
  return moment(date)
    .startOf("day")
    .add({ hours: parseInt(hours), minutes: parseInt(minutes) });
};

function toggleArrayItem<T>(arr: Array<T>, item: T): Array<T> {
  return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
}

// Borrowed from
// https://github.com/revelcw/react-hooks-helper/blob/develop/src/useForm.js
const mergeEventToForm = (
  event: React.ChangeEvent<HTMLInputElement>,
  formData: any
) => {
  const { target } = event;
  const { name, value, checked, type } = target;
  const isCheckbox = type === "checkbox";

  const dataClone = JSON.parse(JSON.stringify(formData));
  const keys = name.split("."); // 'a.b.c' => ['a', 'b', 'c'] | 'a' => ['a']
  const nestedSegments = keys.slice(0, -1); // ['a', 'b'] | []
  const [finalSegment] = keys.slice(-1); // 'c' | 'a'
  const finalData = nestedSegments.reduce((obj, key) => obj[key], dataClone);
  const finalValue = isCheckbox
    ? checked
    : event.type === "JSON"
    ? JSON.parse(value)
    : value;

  finalData[finalSegment] = finalValue;

  return dataClone;
};

export {
  mergeFormValues,
  timeInputToMoment,
  toggleArrayItem,
  mergeEventToForm,
};
