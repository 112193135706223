import React from "react";
import { Redirect } from "react-router-dom";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import MobilePageList from "components/MobilePageList";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import { ACCOUNT_PAGES } from "constants/Account";

function AccountPage() {
  return (
    <>
      <Mobile>
        <MobilePageList title="Mon compte" listItems={ACCOUNT_PAGES} />
      </Mobile>
      <Desktop>
        <Redirect
          to={{
            pathname: parseRoute(routeList.ACCOUNT_SALON_PAGE)
          }}
        />
      </Desktop>
    </>
  );
}

export default AccountPage;
