import React, { useState, useCallback } from "react";
import TextInput from "../Text";
import { TextFieldProps } from "@material-ui/core/TextField";
import { locality, featureToLocation } from "../../../utils/geo";
import { debounce } from "throttle-debounce";
import ModalContainer from "../../modal/Container/Container.mobile_old";
import AddressAutocompleteInputPage from "./InputPage";
import NextIcon from "../../svgs/NextIcon";
import { BLUE_GREY } from "../../../constants/Style";
import FakeInput from "../FakeInput";
import { Location } from "../../../reducers/salon";

const DEBOUNCE_DELAY = 300;

interface AddressAutocompleteProps {
  value: string;
  addressName: string;
  geoLocationName: string;
  readOnly?: boolean;
}

function AddressAutocomplete(props: TextFieldProps & AddressAutocompleteProps) {
  const {
    value,
    className,
    onChange: propsOnChange,
    addressName,
    geoLocationName,
    readOnly,
    ...otherProps
  } = props;

  const [locations, setLocations] = useState([]);
  const [query, setQuery] = useState("");

  const debouncedLocalitySearch = debounce(DEBOUNCE_DELAY, event =>
    locality(event.target.value)
      .then(response => response.json())
      .then(({ features }) => {
        setLocations(features.map(featureToLocation));
      })
  );

  const onChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.persist();
    debouncedLocalitySearch(event);
    setQuery(event.target.value);
  };

  /**
   * Triggers the props onChange with new address and geoLocation
   * then closes the input modal
   */
  const onLocationClick = useCallback(
    (location: Location) => {
      if (!propsOnChange) return;
      const addressEvent = ({
        target: {
          name: addressName,
          value: location.address
        }
      } as unknown) as React.ChangeEvent<HTMLInputElement>;
      propsOnChange(addressEvent);

      const geoLocationEvent = ({
        target: {
          name: geoLocationName,
          value: location.geoLocation
        }
      } as unknown) as React.ChangeEvent<HTMLInputElement>;
      propsOnChange(geoLocationEvent);

      setIsInputOpened(false);
    },
    [propsOnChange, addressName, geoLocationName]
  );

  const [isInputOpened, setIsInputOpened] = useState(false);
  return (
    <React.Fragment>
      <div className="input-group">
        <TextInput
          value={value}
          {...otherProps}
          onClick={() => setIsInputOpened(true)}
          InputProps={{
            inputComponent: FakeInput,
            endAdornment: !readOnly && <NextIcon color={BLUE_GREY} />,
            readOnly
          }}
        />
      </div>
      {!readOnly && (
        <ModalContainer open={isInputOpened} direction="left">
          <AddressAutocompleteInputPage
            query={query}
            locations={locations}
            onChange={onChange}
            onClose={() => setIsInputOpened(false)}
            onLocationClick={onLocationClick}
          />
        </ModalContainer>
      )}
    </React.Fragment>
  );
}

export default AddressAutocomplete;
