import { parseRoute } from "../utils/route";
import { routeList } from "./Routes";
import { SublinksConditions } from "constants/Menu";

export const ACCOUNT_PAGES = [
  {
    title: "L'établissement",
    link: parseRoute(routeList.ACCOUNT_SALON_PAGE),
  },
  {
    title: "Les professionnels",
    link: parseRoute(routeList.ACCOUNT_HAIRDRESSERS_PAGE),
  },
  {
    title: "Paramètres du compte",
    link: parseRoute(routeList.ACCOUNT_SETTINGS_PAGE),
  },
  {
    title: "Réservation en ligne",
    link: parseRoute(routeList.ACCOUNT_WEEKS_PAGE),
    conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
      isFlexySalon && autoCalendar,
  },
  {
    title: "Promotions Happy Hour",
    link: parseRoute(routeList.ACCOUNT_HAPPY_HOURS_PAGE),
    conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
      isFlexySalon && autoCalendar,
  },
  {
    title: "Promotions Last Minute",
    link: parseRoute(routeList.ACCOUNT_LAST_MINUTE_PAGE),
    // conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
    //  process.env.REACT_APP_ENV === "staging" || (isFlexySalon && autoCalendar),
  },
];

export const ACCESS_TYPES = {
  METRO: "METRO",
  BUS: "BUS",
  ROAD: "ROAD",
  PLACE: "PLACE",
  RER: "RER",
  TRAIN_STATION: "TRAIN STATION",
  PARKING: "PARKING",
  SHOPPING_MALL: "SHOPPING MALL",
  STREETCAR: "STREETCAR",
};

export const GENDERS = {
  M: "M",
  F: "F",
};

export const STATUSES = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const PICKABLE_COLORS = [
  "#ac725e",
  "#d06b64",
  "#f83a22",
  "#fa573c",
  "#ff7537",
  "#ffad46",
  "#fad165",
  "#fbe983",
  "#b3dc6c",
  "#7bd148",
  "#16a765",
  "#42d692",
  "#92e1c0",
  "#9fe1e7",
  "#4986e7",
  "#9fc6e7",
  "#9a9cff",
  "#b99aff",
  "#a47ae2",
  "#cd74e6",
  "#f691b2",
  "#cca6ac",
  "#cabdbf",
  "#c2c2c2",
];

export const FLEXY_PARTNER_NAME = "flexy";
export const HAIRNET_PARTNER_NAME = "hairnet";
