import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "styles/Theme";
import { logException } from "utils/logging";
import { useCrashRecovery } from "./useCrashRecovery";
import LogoLeCiseau from "components/svgs/LogoLeCiseau";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};
type ErrorBoundaryState = {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    return <CrashRecovery {...this.state}>{this.props.children}</CrashRecovery>;
  }
}

function CrashRecovery({
  hasError,
  error,
  errorInfo,
  children
}: React.PropsWithChildren<ErrorBoundaryState>) {
  const { tryToRecover } = useCrashRecovery({ error: String(error) });
  /**
   * TODO refactor with Xstate for clearer workflow and manage
   * step by step recover in development
   */
  const [errorComponent, setErrorComponent] = useState<React.ReactNode>();
  useEffect(() => {
    async function recover() {
      if (hasError) {
        const { failed } = await tryToRecover();
        if (failed) {
          setErrorComponent(<UnknownError />);
          logException(`Crash Recovery Failed :\nERROR : ${error}`, errorInfo);
        }
      }
    }
    if (
      hasError &&
      ["production", "staging"].includes(process.env.REACT_APP_ENV ?? "")
    ) {
      recover();
      setErrorComponent(<Loader />);
    }
  }, [hasError]);
  return (
    <>
      {hasError ? <ErrorContainer>{errorComponent}</ErrorContainer> : children}
    </>
  );
}

function ErrorContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        height: "95vh",
        width: "95vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px",
        textAlign: "center"
      }}
    >
      {children}
    </div>
  );
}

function UnknownError() {
  return (
    <>
      <div className="login-page-header__logo">
        <LogoLeCiseau height={51} withCircle />
      </div>
      <div className="login-page-header__title">
        <span className="le-ciseau-title">LeCiseau</span>
        <span className="le-ciseau-title-extra">Pro</span>
      </div>
      <div>
        Une erreur inatendue s'est produite.
        <br />
        Veuillez réessayer dans quelques instants.
      </div>
    </>
  );
}

function Loader() {
  return (
    <MuiThemeProvider theme={theme}>
      <CircularProgress />
    </MuiThemeProvider>
  );
}

export { CrashRecovery };
export default ErrorBoundary;
