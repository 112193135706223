import React, { useCallback, useState } from "react";
import { Hairdresser, Price, PartnerRef } from "../../reducers/salon";
import TextInput from "../inputs/Text";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TimePickerMobile from "../inputs/TimePicker/TimePicker.mobile";
import MobileSelect from "../inputs/Select/Mobile";
import { pluralize } from "../../utils/string";
import ReponsiveModalContent, {
  ModalContentProps,
} from "../modal/Content/ResponsiveContent";
import Variant from "../uiElements/Variant/Variant";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IOSSwitch } from "./PackageTitleItem/PackageTitleItem";
import useCurrentUser from "hooks/useCurrentUser";
import { PINK } from "constants/Style";
import PackagePartnerRef from "components/package/PackagePartnerRef/PackagePartnerRef";
import { getOpacityStyle } from "containers/Package/utils";

export interface PackageFormData {
  name: string;
  description: string;
  hairdresserIds: Array<number>;
  prices: Array<Price>;
  allowPromo: boolean;
  categoryId?: number;
  status?: string;
  partnerRefs: Array<PartnerRef>;
}

const ModalContent = ({ desktopProps, ...props }: ModalContentProps) => (
  <ReponsiveModalContent
    {...props}
    desktopProps={{ withPadding: true, ...desktopProps }}
  />
);

export interface PackageFormErrors {
  price: number;
  variant: number;
  anyLength: boolean;
}

interface PackageFormProps {
  type: "create" | "update";
  allHairdressers: Array<Hairdresser>;
  formData: PackageFormData;
  updateFormData(event: React.ChangeEvent<HTMLInputElement>): void;
  updatePartnerRefFormData(
    variant?: string,
    name?: string,
    value?: string
  ): void;
  addPrice(): void;
  removePrice(index: number): void;
  categories: Array<{ id: number; name: string }>;
  variants: Array<{ id: number; name: string; key: string }>;
  formErrors: PackageFormErrors;
  isOneCatalog: boolean;
}

const chooseVariant = (
  variantId: number,
  variants: Array<{ id: number; name: string; key: string }>
) => {
  if (variantId === -2) {
    return JSON.stringify({
      name: "undefined",
      id: 0,
      key: "",
    });
  }

  return variantId === -1
    ? null
    : JSON.stringify(variants.find((variant) => variant.id === variantId));
};

const PackageForm: React.FC<PackageFormProps> = ({
  type,
  allHairdressers,
  formData,
  updateFormData,
  addPrice,
  removePrice,
  categories,
  variants,
  formErrors,
  updatePartnerRefFormData,
  isOneCatalog,
}) => {
  const opacityStyle = getOpacityStyle(isOneCatalog);
  const baseVariants = formData.prices.reduce((acc, price, priceIndex) => {
    return {
      ...acc,
      [priceIndex]:
        type === "create" ? price?.variant?.id || -2 : price?.variant?.id || -1,
    };
  }, {}) as { [key: number]: number };
  const [selectedVariants, setSelectedVariants] = useState(baseVariants);
  const durationAsTime = useCallback((duration: string) => {
    const durationInt = parseInt(duration);
    const hours = Math.floor(durationInt / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (durationInt % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }, []);

  const timeAsDuration = (time: string) => {
    const [hours, minutes] = time.split(":").map((unit) => parseInt(unit));
    return hours * 60 + minutes;
  };

  const renderTimeValue = useCallback((time) => {
    const [hours, minutes] = time.split(":");
    return `${parseInt(hours)}h${minutes}`;
  }, []);

  const { isAdmin } = useCurrentUser();
  return (
    <React.Fragment>
      <ModalContent>
        {(type === "create" || isAdmin) && (
          <FormControl>
            <InputLabel className="input_label">Catégorie</InputLabel>
            <Select
              name="categoryId"
              value={formData.categoryId ?? ""}
              onChange={updateFormData as any}
              disabled={isOneCatalog}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div style={opacityStyle}>
          <FormControlLabel
            onClick={(e: any) => e.stopPropagation()}
            control={
              <IOSSwitch
                checked={formData?.allowPromo}
                onChange={(e: any) => {
                  if (isOneCatalog) {
                    return;
                  }
                  updateFormData(e);
                }}
                name="allowPromo"
              />
            }
            label="Réservable en promotion"
            labelPlacement="start"
            style={{ marginLeft: 0 }}
          />
        </div>
        <div>
          {formData?.allowPromo ? (
            <span
              style={{ color: "grey", fontSize: "0.8em", paddingRight: "11px" }}
            >
              Promotion autorisée (La promotion ne s'appliquera que sur les
              créneaux -30% et -50%)
            </span>
          ) : (
            <span style={{ color: "grey", fontSize: "0.8em" }}>
              La prestation sera toujours en plein tarif, y compris pendant les
              créneaux à -30% et -50%
            </span>
          )}
        </div>
        <TextInput
          disabled={isOneCatalog}
          fullWidth
          className="input-group"
          label="Nom du forfait"
          name="name"
          value={formData.name}
          onChange={updateFormData}
          InputProps={{
            readOnly: type === "update" && !isAdmin,
          }}
        />
        <TextInput
          disabled={isOneCatalog}
          fullWidth
          className="input-group"
          multiline
          label="Détail de la prestation"
          name="description"
          value={formData.description}
          onChange={updateFormData}
          InputProps={{
            readOnly: type === "update" && !isAdmin,
          }}
        />
        <MobileSelect
          disabled={isOneCatalog}
          fullWidth
          multiple
          label={`Assigné ${pluralize(
            "au professionnel",
            "aux professionnels",
            formData.hairdresserIds.length
          )}`}
          name="hairdresserIds"
          value={formData.hairdresserIds}
          listTitle="Selectionner les professionnels"
          list={allHairdressers.map((hairdresser) => ({
            value: hairdresser.id,
            display: hairdresser.firstName,
            optional: hairdresser.status === "DISABLED",
          }))}
          getOptionalsToggleTitle={(showOptionnals: boolean) =>
            showOptionnals
              ? "Cacher les professionnels désactivés"
              : "Afficher les professionnels désactivés"
          }
          onChange={updateFormData}
          emptyWarning="Plus aucun professionnel n'est assigné à cette prestation, la prestation va être désactivée, êtes vous sûr de vouloir continuer ?"
        />
      </ModalContent>
      {formData.prices.map((price, priceIndex) => (
        <ModalContent noPadding key={priceIndex}>
          {type === "update" && !isAdmin && price.variant && (
            <Variant variant={price.variant} className="package-form-variant" />
          )}
          {(type === "create" || isAdmin) && formData.prices.length > 1 && (
            <Box ml={2}>
              <Button
                disabled={isOneCatalog}
                variant="outlined"
                onClick={() => removePrice(priceIndex)}
              >
                - Supprimer cette variante
              </Button>
            </Box>
          )}
          <List>
            {(type === "create" || isAdmin) && variants && (
              <ListItem className="page-list_item_container-mobile">
                <FormControl>
                  <InputLabel className="input_label">Variante</InputLabel>
                  <Select
                    disabled={isOneCatalog}
                    name={`prices.${priceIndex}.variant`}
                    value={
                      (selectedVariants && selectedVariants[priceIndex]) || -2
                    }
                    onChange={({ target: { value: variantId } }) => {
                      setSelectedVariants({
                        ...selectedVariants,
                        [priceIndex]: Number(variantId),
                      });
                      updateFormData({
                        target: {
                          name: `prices.${priceIndex}.variant`,
                          value: chooseVariant(Number(variantId), variants),
                        },
                        type: "JSON",
                      } as React.ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    <MenuItem key="empty" value={-2}>
                      Selectionner une variante
                    </MenuItem>
                    <MenuItem key="no_variant" value={-1}>
                      Pas de variante
                    </MenuItem>
                    {variants.map((variant) => (
                      <MenuItem key={variant.id} value={variant.id}>
                        {variant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formErrors.variant && selectedVariants[priceIndex] === -2 ? (
                  <div>
                    <span style={{ color: PINK }}>
                      Merci de sélectionner une longueur
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </ListItem>
            )}
            <ListItem className="page-list_item_container-mobile">
              <TimePickerMobile
                disabled={isOneCatalog}
                fullWidth
                name={`prices.${priceIndex}.duration`}
                value={durationAsTime(price.duration)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateFormData({
                    target: {
                      name: event.target.name,
                      value: timeAsDuration(event.target.value).toString(),
                    },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
                className="fullwidth"
                step={900}
                max="08:15"
                renderInput={({ value, onClick }) => (
                  <div
                    onClick={onClick}
                    className="ui-space_between-line fullwidth clickable"
                  >
                    <span className="package-item-input-title">Durée</span>
                    <span>{renderTimeValue(value)}</span>
                  </div>
                )}
              />
            </ListItem>
            {formErrors.price && price.duration === "0" ? (
              <div style={{ marginLeft: "15px" }}>
                <span style={{ color: PINK }}>Merci d'indiquer une durée</span>
              </div>
            ) : (
              ""
            )}
            <ListItem className="page-list_item_container-mobile">
              <label className="package-price-input-container">
                <div className="ui-space_between-line fullwidth">
                  <span className="package-item-input-title">
                    Prix plein tarif
                  </span>
                  <div className="package-price-input-render-values">
                    <span>{price.price ? `${price.price} €` : ""}</span>
                  </div>
                </div>
                {(type === "create" || isAdmin) && (
                  <input
                    type="number"
                    name={`prices.${priceIndex}.price`}
                    value={price.price || ""}
                    onChange={(e: any) => {
                      if (isOneCatalog) {
                        return;
                      }

                      updateFormData(e);
                    }}
                    className="clickable"
                  />
                )}
              </label>
            </ListItem>
            {formErrors.price && Number(price.price) === 0 ? (
              <div style={{ marginLeft: "15px" }}>
                <span style={{ color: PINK }}>Merci d'indiquer un prix</span>
              </div>
            ) : (
              ""
            )}
            {/*
              Component to add form input to assign hairdressers by price variant

            <ListItem className="page-list_item_container-mobile">
              <div className="ui-space_between-line fullwidth">
                <span className="package-item-input-title">
                  Assigné au professionnel
                </span>
                <MobileSelect
                  multiple
                  name="hairdresserIds"
                  value={formData.hairdresserIds}
                  listTitle="Selectionner les professionnels"
                  list={allHairdressers.map(hairdresser => ({
                    value: hairdresser.id,
                    display: hairdresser.firstName
                  }))}
                  onChange={updateFormData}
                  render={({ SelectModal, value, openModal }) => (
                    <React.Fragment>
                      <div
                        className="package-price_select-hairdressers-container clickable"
                        onClick={openModal}
                      >
                        <span className="package-price_select-hairdressers">
                          {value}
                        </span>
                        <NextIcon className="no-shrink" color={BLUE_GREY} />
                      </div>
                      <SelectModal />
                    </React.Fragment>
                  )}
                />
              </div>
            </ListItem>
            */}
            <ListItem className="page-list_item_container-mobile no-underline">
              {type === "update" && !isAdmin && (
                <div className="ui-space_between-line fullwidth">
                  <span className="package-item-input-title">
                    Code prestation
                  </span>
                  <span className="package-price-package-number">
                    {price.packageNumber}
                  </span>
                </div>
              )}
              {(type === "create" || isAdmin) && (
                <TextInput
                  disabled={isOneCatalog}
                  fullWidth
                  className="input-group"
                  label="Code prestation"
                  name={`prices.${priceIndex}.packageNumber`}
                  value={price.packageNumber}
                  onChange={updateFormData}
                />
              )}
            </ListItem>
          </List>

          {type === "update" && isAdmin && !isOneCatalog && (
            <div className="package-partner-form-container">
              <PackagePartnerRef
                price={price}
                updatePartnerRefFormData={updatePartnerRefFormData}
              />
            </div>
          )}
        </ModalContent>
      ))}
      {(type === "create" || isAdmin) && (
        <Box ml={2}>
          <Button disabled={isOneCatalog} variant="outlined" onClick={addPrice}>
            + Ajouter une longueur
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default PackageForm;
