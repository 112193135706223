import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import SignupPage, { SalonInfos } from "../../components/signupPage/SignupPage";
import * as snackbarActions from "../../actions/SnackbarActions";
import { MESSAGE_TYPES } from "../../constants/SnackBar";
import { signup } from "./utils";

interface SignupPageContainerProps {
  snackbarActionDispatcher: typeof snackbarActions;
}

function SignupPageContainer(props: SignupPageContainerProps) {
  const submit = (salonInfos: SalonInfos) => {
    const { snackbarActionDispatcher } = props;
    return signup(salonInfos).catch((error: string) => {
      snackbarActionDispatcher.pushMessage(error, MESSAGE_TYPES.ERROR);
    });
  };
  return <SignupPage onSubmit={submit} />;
}

function mapDispatch(dispatch: Dispatch) {
  return {
    snackbarActionDispatcher: bindActionCreators(snackbarActions, dispatch)
  };
}

export default connect(null, mapDispatch)(SignupPageContainer);
