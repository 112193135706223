import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "reducers";
import useCurrentSalon from "hooks/useCurrentSalon";
import RatingPage from "components/rating/RatingPage";

interface RatingPageContainerProps {
  match: {
    params: {
      tab: string;
    };
  };
}

const ratingStateSelector = (state: AppState) => state.rating;

function RatingPageContainer({ match }: RatingPageContainerProps) {
  const { salon } = useCurrentSalon();
  const { loading: ratingIsLoading, payload: ratingPayload } = useSelector(
    ratingStateSelector
  );
  const isLoading =
    ratingIsLoading && Object.keys(ratingPayload.ratingById).length === 0;
  return (
    <RatingPage
      title={salon?.name ?? "Salon"}
      currentTab={match.params.tab}
      isLoading={isLoading}
    />
  );
}

export default RatingPageContainer;
