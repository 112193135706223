import React from "react";
import moment from "moment";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import Mobile from "components/ResponsiveLayout/Mobile";
import HairdresserAvatar from "components/uiElements/HairdresserAvatar/HairdresserAvatar";
import Desktop from "components/ResponsiveLayout/Desktop";
import { displayableVariantName } from "components/uiElements/Variant/utils";
import CoinsIcon from "components/svgs/Coins";
import { Booking, User } from "reducers/booking";
import { Hairdresser } from "reducers/salon";
import { priceFormatter } from "utils/payments";
import { capitalize } from "utils/string";
import { useHairdressersContext } from "../BookingsPage";
import styles from "./BookingItem.module.scss";
import { getBookingDiscountedPrice } from "utils/booking";
import { SALMON } from "constants/Style";

interface BookingItemProps {
  booking: Booking;
  onClick?(): void;
}

const unknownUser: User = {
  id: -1,
  firstName: "",
  lastName: "",
  username: "",
};

const unknownHairdresser: Hairdresser = {
  id: -1,
  stringId: "",
  firstName: "",
  lastName: "",
  gender: "",
  description: "",
  color: "",
  salonId: -1,
  status: "",
  packageIds: [],
  partnerRefs: [],
  serviceIds: [],
};

const timeFormat = "HH[h]mm";
const dateFormat = "dddd D MMMM";

function BookingItem({ booking, onClick }: BookingItemProps) {
  const { user = unknownUser, paymentType = "ONLINE" } = booking;
  const hairdressers = useHairdressersContext();
  const snapshotPackage = booking.snapshotPackages[0];
  const hairdresser =
    hairdressers.find(
      (h) =>
        (h.id && h.id === snapshotPackage.hairdresser.id) ||
        h.stringId === snapshotPackage.hairdresser.stringId
    ) ?? unknownHairdresser;
  const bookingDateReadable = capitalize(
    moment(booking.dateStart).format(dateFormat)
  );
  const bookingTimeReadable = `${moment(booking.dateStart).format(
    timeFormat
  )} à ${moment(booking.dateEnd).format(timeFormat)}`;
  const bookingDescription = snapshotPackage.description;
  const packageName = snapshotPackage.name;
  const variantName = displayableVariantName(
    snapshotPackage.price.variant.name
  );
  const priceNumber = snapshotPackage.price.price;
  const price = priceFormatter.format(priceNumber);
  const priceReduced = priceFormatter.format(
    getBookingDiscountedPrice(booking)
  );
  const props = {
    hairdresser,
    onClick,
    bookingDescription,
    bookingDateReadable,
    bookingTimeReadable,
    packageName,
    variantName,
    user,
    price,
    priceReduced,
    paymentType,
  };
  return (
    <>
      <Mobile>
        <BookingItemMobile {...props} />
      </Mobile>
      <Desktop>
        <BookingItemDesktop {...props} />
      </Desktop>
    </>
  );
}

interface BookingItemResponsiveProps {
  hairdresser: Hairdresser;
  onClick?(): void;
  bookingDescription: string;
  bookingDateReadable: string;
  bookingTimeReadable: string;
  packageName: string;
  variantName: string;
  user: User;
  price: string;
  priceReduced: string;
  paymentType: string;
}

function BookingItemMobile({
  hairdresser,
  onClick,
  bookingTimeReadable,
  packageName,
  variantName,
  user,
  price,
  priceReduced,
  paymentType,
}: BookingItemResponsiveProps) {
  return (
    <div
      className={styles.container}
      style={{ borderColor: hairdresser.color }}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.name} style={{ color: hairdresser.color }}>
          <span>{hairdresser.firstName}</span>
          <span> {bookingTimeReadable}</span>
        </div>
        {paymentType === "IN_SALON" ? (
          <div className={styles.inSalonContainer}>
            <CoinsIcon color={SALMON} />
            <span className={styles.inSalon}>Paiement en salon</span>
          </div>
        ) : null}
        <div className={styles.package}>
          <div>{packageName}</div>
          {variantName && (
            <div className={styles.variant}>
              <span>{variantName}</span>
            </div>
          )}
        </div>
        <div
          className={styles.hairdresserName}
        >{`${user.firstName} ${user.lastName}`}</div>
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.priceFull}>{price}€</div>
        <div className={styles.priceReduced}>{priceReduced}€</div>
      </div>
    </div>
  );
}

function BookingItemDesktop({
  hairdresser,
  onClick,
  bookingDateReadable,
  bookingTimeReadable,
  bookingDescription,
  packageName,
  variantName,
  user,
  price,
  priceReduced,
  paymentType,
}: BookingItemResponsiveProps) {
  const phoneNumber =
    parsePhoneNumberFromString(
      user.mobile ?? user.phone ?? "",
      "FR"
    )?.formatInternational() ?? "";
  return (
    <div
      className={styles.desktopContainer}
      style={{ borderColor: hairdresser.color }}
      onClick={onClick}
    >
      <div className={styles.leftContainer}>
        <HairdresserAvatar hairdresser={hairdresser} height={29} />
        <div className={styles.text} style={{ color: hairdresser.color }}>
          <span className={styles.hairdresserfirstName}>
            {hairdresser.firstName}
          </span>
          <span>{bookingDateReadable}</span>
          <span>{bookingTimeReadable}</span>
        </div>
      </div>
      <div className={styles.centerContainer}>
        <div className={styles.title}>
          <span>{packageName}</span>
          {variantName && (
            <div className={styles.variant}>
              <span>{variantName}</span>
            </div>
          )}
        </div>
        <span className={styles.description}>{bookingDescription}</span>
        <div className={styles.userInfos}>
          <span className={styles.userFullname}>
            {user.firstName} {user.lastName}
          </span>
          <span>{user.username}</span>
          <span>{phoneNumber}</span>
        </div>
        {paymentType === "IN_SALON" ? (
          <div className={styles.inSalonContainer}>
            <CoinsIcon color={SALMON} />
            <span className={styles.inSalon}>Paiement en salon</span>
          </div>
        ) : null}
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.priceFull}>{price}€</div>
        <div className={styles.priceReduced}>{priceReduced}€</div>
      </div>
    </div>
  );
}

export default BookingItem;
