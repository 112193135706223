import React from "react";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { VERY_LIGHT_GREY } from "../../../constants/Style";

export default function ModalContainer(props: ModalProps & SlideProps) {
  const { children, open, direction, className, ...otherProps } = props;
  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropProps={{
        style: {
          width: "100%",
          height: "100%",
          backgroundColor: VERY_LIGHT_GREY
        }
      }}
      {...otherProps}
    >
      {direction ? (
        <Slide direction={direction} in={open}>
          <div className={`modal_container-mobile ${className}`}>
            {children}
          </div>
        </Slide>
      ) : (
        children
      )}
    </Modal>
  );
}
