import { useEffect } from "react";
import { showSmartBanner } from "thirdParties/appsFlyer";
import { useReactNativeAppContext } from "ReactNativeAppSyncProvider";
import device from "current-device";

/**
 * The Smart Banner appaears to redirect users towards native app
 * It is only displayed on mobile website (not on mobile app)
 */
export function useSmartBanner() {
  const { isReactNativeApp } = useReactNativeAppContext();

  useEffect(() => {
    if ((device.mobile() || device.tablet()) && !isReactNativeApp) {
      showSmartBanner();
    }
  }, [isReactNativeApp]);
}
