import React, { memo } from "react";
import { Week } from "reducers/week";
import { Hairdresser } from "reducers/salon";
import styles from "./HairdresserModal.module.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../button/Desktop/Button.desktop";
import LargeClickable from "../../uiElements/LargeClickable";
import CloseIcon from "components/svgs/CloseIcon";
import { isHairdresserInWeek } from "utils/week";

interface HairdresserModalProps {
  isHairdresserSelectorOpen: boolean;
  setCurrentIndex(index: number): void;
  setHairdresserSelectorOpen(open: boolean): void;
  currentIndex: number;
  hairdressers: Array<Hairdresser>;
  weeksToSubmit: Array<Week>;
  saveWeek(index: number, week: Week): void;
  companyCode: string;
}

const HairdresserModal = ({
  isHairdresserSelectorOpen,
  setHairdresserSelectorOpen,
  currentIndex,
  setCurrentIndex,
  hairdressers,
  weeksToSubmit,
  saveWeek,
  companyCode,
}: HairdresserModalProps) => (
  <Dialog
    maxWidth={"sm"}
    fullWidth
    open={isHairdresserSelectorOpen && currentIndex > -1}
    onClose={() => {
      setHairdresserSelectorOpen(false);
      setCurrentIndex(-1);
    }}
  >
    <DialogTitle>Ajouter un coiffeur</DialogTitle>
    <DialogContent>
      <LargeClickable
        className={styles.stickTopRight}
        onClick={() => {
          setHairdresserSelectorOpen(false);
          setCurrentIndex(-1);
        }}
      >
        <CloseIcon
          onClick={() => {
            setHairdresserSelectorOpen(false);
            setCurrentIndex(-1);
          }}
          color={"#000000"}
        />
      </LargeClickable>
      {hairdressers.map(
        (hairdresser) =>
          currentIndex > -1 &&
          !isHairdresserInWeek(weeksToSubmit[currentIndex], hairdresser) && (
            <Button
              key={hairdresser.id || Number(hairdresser.stringId)}
              onClick={() => {
                saveWeek(currentIndex, {
                  ...weeksToSubmit[currentIndex],
                  ...(hairdresser.id
                    ? {
                        hairdresserIds: [
                          ...weeksToSubmit[currentIndex].hairdresserIds,
                          hairdresser.id,
                        ],
                      }
                    : {}),
                  ...(hairdresser.stringId !== ""
                    ? {
                        hairdresserStringIds: [
                          ...weeksToSubmit[currentIndex].hairdresserStringIds,
                          `${companyCode}|${hairdresser.stringId}`,
                        ],
                      }
                    : {}),
                });
              }}
              text={`${hairdresser.firstName} ${hairdresser.lastName}`}
              className={styles.hairdresserNamePopin}
              style={{
                color: hairdresser.color ? "white" : "darkgrey",
                backgroundColor: hairdresser.color || "white",
                borderColor: hairdresser.color || "white",
              }}
            />
          )
      )}
    </DialogContent>
  </Dialog>
);

export default memo(HairdresserModal);
