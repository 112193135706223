import * as types from "../constants/ActionTypes";
import {
  fetchSlotsByHairdresserIds as fetchSlotsByHairdresserIdsFromServer,
  updateSlotById,
  createSlot as createSlotOnServer,
  createRecurrentSlot as createRecurrentSlotOnServer,
  deleteSlotById,
  deleteRecurrentSlotById,
} from "../utils/slot";
import { SlotPayload, Slot, SlotInfos } from "../reducers/slot";
import { Dispatch } from "redux";
import { getFetchRangeOptions } from "../utils/slot";
import { errorToString } from "../utils/string";
import { pushMessage } from "./SnackbarActions";
import { AuthLogoutAction } from "./AuthActions";
import { MESSAGE_TYPES } from "../constants/SnackBar";

interface FetchSlotsAction {
  type: typeof types.FETCH_SLOTS;
}

export interface FetchSlotsSuccessAction {
  type: typeof types.FETCH_SLOTS_SUCCESS;
  payload: SlotPayload & {
    dateRange: {
      endGte: string;
      startLte: string;
    };
  };
}

interface FetchSlotsFailureAction {
  type: typeof types.FETCH_SLOTS_FAILURE;
  error: string;
}

interface UpdateSlotAction {
  type: typeof types.UPDATE_SLOT;
}

export interface UpdateSlotSuccessAction {
  type: typeof types.UPDATE_SLOT_SUCCESS;
  payload: Slot;
}

interface UpdateSlotFailureAction {
  type: typeof types.UPDATE_SLOT_FAILURE;
  error: string;
}

interface CreateSlotAction {
  type: typeof types.CREATE_SLOT;
}

export interface CreateSlotSuccessAction {
  type: typeof types.CREATE_SLOT_SUCCESS;
  payload: Slot;
}

interface CreateSlotFailureAction {
  type: typeof types.CREATE_SLOT_FAILURE;
  error: string;
}

interface CreateRecurrentSlotAction {
  type: typeof types.CREATE_RECURRENT_SLOT;
}

export interface CreateRecurrentSlotSuccessAction {
  type: typeof types.CREATE_RECURRENT_SLOT_SUCCESS;
  payload: Array<Slot>;
}

interface CreateRecurrentSlotFailureAction {
  type: typeof types.CREATE_RECURRENT_SLOT_FAILURE;
  error: string;
}

interface DeleteSlotAction {
  type: typeof types.DELETE_SLOT;
}

export interface DeleteSlotSuccessAction {
  type: typeof types.DELETE_SLOT_SUCCESS;
  payload: number;
}

interface DeleteSlotFailureAction {
  type: typeof types.DELETE_SLOT_FAILURE;
  error: string;
}

export type SlotActions =
  | FetchSlotsAction
  | FetchSlotsSuccessAction
  | FetchSlotsFailureAction
  | UpdateSlotAction
  | UpdateSlotSuccessAction
  | UpdateSlotFailureAction
  | CreateSlotAction
  | CreateSlotSuccessAction
  | CreateSlotFailureAction
  | CreateRecurrentSlotAction
  | CreateRecurrentSlotSuccessAction
  | CreateRecurrentSlotFailureAction
  | DeleteSlotAction
  | DeleteSlotSuccessAction
  | DeleteSlotFailureAction
  | AuthLogoutAction;

export const fetchSuccess = (
  data: SlotPayload,
  dateRange: {
    endGte: string;
    startLte: string;
  }
) => ({
  type: types.FETCH_SLOTS_SUCCESS,
  payload: { ...data, dateRange },
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_SLOTS_FAILURE,
  error,
});

export const updateSlotSuccess = (data: Slot) => ({
  type: types.UPDATE_SLOT_SUCCESS,
  payload: data,
});

export const updateSlotFailure = (error: string) => ({
  type: types.UPDATE_SLOT_FAILURE,
  error,
});

export const createSlotSuccess = (data: Slot) => ({
  type: types.CREATE_SLOT_SUCCESS,
  payload: data,
});

export const createSlotFailure = (error: string) => ({
  type: types.CREATE_SLOT_FAILURE,
  error,
});

export const createRecurrentSlotSuccess = (data: Array<Slot>) => ({
  type: types.CREATE_RECURRENT_SLOT_SUCCESS,
  payload: data,
});

export const createRecurrentSlotFailure = (error: string) => ({
  type: types.CREATE_RECURRENT_SLOT_FAILURE,
  error,
});

export const deleteSlotSuccess = (deletedSlotId: number) => ({
  type: types.DELETE_SLOT_SUCCESS,
  payload: deletedSlotId,
});

export const deleteSlotFailure = (error: string) => ({
  type: types.DELETE_SLOT_FAILURE,
  error,
});

export const deleteRecurrentSlotSuccess = (deletedRecurrentSlotId: number) => ({
  type: types.DELETE_RECURRENT_SLOT_SUCCESS,
  payload: deletedRecurrentSlotId,
});

export const deleteRecurrentSlotFailure = (error: string) => ({
  type: types.DELETE_RECURRENT_SLOT_FAILURE,
  error,
});

export const fetchSlotsByHairdresserIds = (
  hairdresserIds: Array<number>,
  hairdresserStringIds: Array<string>,
  dateTarget: string,
  { range = "DAY" }: { range?: "DAY" | "WEEK" | "MONTH" } = {}
) => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: types.FETCH_SLOTS });
  const options = getFetchRangeOptions(dateTarget, range);
  return fetchSlotsByHairdresserIdsFromServer(
    hairdresserIds,
    hairdresserStringIds,
    options
  )
    .then((slots: Record<number, Slot>) =>
      dispatch(fetchSuccess({ slotById: slots }, options))
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const updateSlot = (slot: Slot) => async (dispatch: Dispatch) => {
  dispatch({ type: types.UPDATE_SLOT });
  return updateSlotById(slot.id, slot)
    .then((updatedSlot: Slot) => {
      dispatch(updateSlotSuccess(updatedSlot));
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(updateSlotFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const deleteSlot = (id: number) => (dispatch: Dispatch) => {
  dispatch({ type: types.DELETE_SLOT });
  return deleteSlotById(id)
    .then((deletedSlotId: number) => {
      dispatch(deleteSlotSuccess(deletedSlotId));
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(deleteSlotFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const deleteRecurrentSlot = (id: number) => (dispatch: Dispatch) => {
  dispatch({ type: types.DELETE_RECURRENT_SLOT });
  return deleteRecurrentSlotById(id)
    .then((deletedSlotId: number) => {
      dispatch(deleteRecurrentSlotSuccess(deletedSlotId));
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(deleteRecurrentSlotFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const createSlot = (slotInfos: SlotInfos) => (dispatch: Dispatch) => {
  dispatch({ type: types.CREATE_SLOT });
  return createSlotOnServer(slotInfos)
    .then((createdSlot: Slot) => {
      return dispatch(createSlotSuccess(createdSlot));
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(createSlotFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const createRecurrentSlot = (slotInfos: SlotInfos) => (
  dispatch: Dispatch
) => {
  dispatch({ type: types.CREATE_RECURRENT_SLOT });
  return createRecurrentSlotOnServer(slotInfos)
    .then((createdSlots: Array<Slot>) => {
      return dispatch(createRecurrentSlotSuccess(createdSlots));
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(createRecurrentSlotFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
