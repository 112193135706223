import React from "react";
import { hasResponsiveUrlParameter } from "./utils";
import useResponsiveLayout from "./useResponsiveLayout";

interface RenderIfProps {
  isDesktop: boolean;
  hasResponsiveUrlParameter: boolean;
}

interface DesktopProps {
  children: React.ReactNode;
  renderIf?(args: RenderIfProps): boolean;
}

function renderIfDesktop({ isDesktop }: RenderIfProps) {
  return isDesktop;
}

function Desktop({ children, renderIf = renderIfDesktop }: DesktopProps) {
  const { isDesktop } = useResponsiveLayout();

  return renderIf({
    isDesktop,
    get hasResponsiveUrlParameter() {
      return hasResponsiveUrlParameter();
    },
  }) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : null;
}

export default Desktop;
