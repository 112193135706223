import React from "react";

const ShoppingMallIcon = (props: any) => (
  <svg width={21} height={24} viewBox="0 0 21 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M8.454 21.928l-1.99.655v.229a1.142 1.142 0 102.284 0v-1.025c-.09.059-.19.106-.294.14z" />
      <circle cx={8.994} cy={1.972} r={1.972} />
      <path d="M3.198 16.199v-.004c-.112-.482.062-.962.42-1.26l-.173-.524-.179.543-.186-.061a.603.603 0 00-.742.333L.071 20.48c-.14.325.029.7.365.811l2.32.765a.603.603 0 00.776-.436l.464-1.993-.798-3.428zM20.038 18.7l-3.794-4.284a.603.603 0 00-.81-.086l-.157.117-.34-.46v.551c.433.172.748.574.793 1.067v.003l.312 3.506 1.063 1.748a.603.603 0 00.873.172l1.966-1.45a.603.603 0 00.094-.885z" />
      <path d="M15.57 21.367l-.507-5.7a.603.603 0 00-.6-.549h-.196v-1.573l.026-.007c.432-.152.789-.629.559-1.282-.255-.727-1.755-5.007-2.01-5.75-.442-1.283-1.182-1.935-2.2-1.935H7.33c-1.018 0-1.758.652-2.2 1.936-.25.73-1.77 5.068-2.019 5.774-.206.59.11 1.096.568 1.257a.952.952 0 00.2.045l.557 1.693-.186.061a.603.603 0 00-.398.71l1.297 5.573c.08.346.44.546.776.436l2.32-.764a.603.603 0 00.365-.812l-2.267-5.254a.603.603 0 00-.553-.364c-.119 0-.18.028-.375.092l-.602-1.831a.952.952 0 00.078-.168l1.69-4.827-.987 5.228.276.839c.476.03.897.324 1.088.766l1.79 4.15v-3.2h.494v6.9a1.142 1.142 0 102.283 0v-.609a1.276 1.276 0 01-.265-.897l.265-2.94v-2.454h.221l.028-.305c.044-.494.36-.897.794-1.069l-1.149-6.332c.044.125 1.666 4.781 1.725 4.89.03.055.061.1.096.142l-.003 1.88h-.195a.603.603 0 00-.601.549l-.514 5.699a.603.603 0 00.6.657l2.443.001a.603.603 0 00.601-.656z" />
    </g>
  </svg>
);

export default ShoppingMallIcon;
