import React, { createContext, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PageContainer from "../page/Container.mobile";
import MobilePageHeader from "../page/Header.mobile";
import CalendarMonthPickerMobile from "../Calendar/DatePickers/CalendarMonthPicker.mobile";
import PageContent from "../page/Content.mobile";
import moment from "moment";
import { Hairdresser } from "../../reducers/salon";
import { TEALISH } from "../../constants/Style";
import { Booking } from "../../reducers/booking";
import BookingsByDate from "./BookingsByDate/BookingsByDate";
import Mobile from "../ResponsiveLayout/Mobile";
import Desktop from "../ResponsiveLayout/Desktop";
import MenuDesktop from "../Menu/Desktop/Menu.desktop";
import BookingsToolbar from "./Toolbar/Toolbar.desktop";
import styles from "./BookingsPage.module.scss";
import { filterBookingsCanceled } from "utils/booking";
import DesktopModalContainer from "components/modal/Container/Container.desktop_old";
import DesktopModalHeader from "components/modal/Header/Header.desktop";
import ButtonFab from "components/button/Fab";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "components/inputs/Radio";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";

export const HairdressersContext = createContext<Array<Hairdresser>>([]);

export function useHairdressersContext() {
  const context = React.useContext(HairdressersContext);
  if (!context) {
    throw new Error(
      `BookingsPage compound components cannot be rendered outside the BookingsPage component`
    );
  }
  return context;
}
export interface BookingsPageProps {
  date: moment.Moment;
  updateDate(date: string): void;
  hairdressers: Array<Hairdresser>;
  selectedHairdresserId: number | null;
  setSelectedHairdresserId(id: number | null): void;
  bookings: Array<Booking>;
  editBooking(booking: Booking): void;
  showCanceled: boolean;
  setShowCanceled(showCanceled: boolean): void;
}

function BookingsPage({
  date,
  updateDate,
  hairdressers,
  selectedHairdresserId,
  setSelectedHairdresserId,
  bookings,
  editBooking,
  showCanceled,
  setShowCanceled,
}: BookingsPageProps) {
  const bookingsList =
    bookings.length > 0 ? (
      <BookingsByDate
        bookings={filterBookingsCanceled(bookings, showCanceled)}
        onBookingClick={editBooking}
      />
    ) : (
      <span className={styles.noBookings}>
        Aucun rendez-vous pour cette période
      </span>
    );
  const props = {
    date,
    updateDate,
    hairdressers,
    selectedHairdresserId,
    setSelectedHairdresserId,
    bookingsList,
    showCanceled,
    setShowCanceled,
  };
  return (
    <HairdressersContext.Provider value={hairdressers}>
      <Mobile>
        <BookingsPageMobile {...props} />
      </Mobile>
      <Desktop>
        <BookingsPageDesktop {...props} />
      </Desktop>
    </HairdressersContext.Provider>
  );
}

interface BookingsPageNonResponsiveProps
  extends Omit<BookingsPageProps, "bookings" | "editBooking"> {
  bookingsList: JSX.Element;
}

function BookingsPageMobile({
  date,
  updateDate,
  hairdressers,
  selectedHairdresserId,
  setSelectedHairdresserId,
  bookingsList,
  showCanceled,
  setShowCanceled,
}: BookingsPageNonResponsiveProps) {
  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDate(event.target.value);
  };

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const openFilters = () => setIsFiltersOpen(true);
  const closeFilters = () => setIsFiltersOpen(false);

  return (
    <PageContainer>
      <DesktopModalContainer open={isFiltersOpen}>
        <React.Fragment>
          <DesktopModalHeader onClose={closeFilters} title="Filtres" />
          <RadioGroup
            aria-label="showCanceled"
            name="showCanceled"
            value={showCanceled}
            onChange={(event) => setShowCanceled(event.target.value === "true")}
          >
            <FormControlLabel
              className={styles.showCanceledDesktopLabel}
              checked={!showCanceled}
              value="false"
              control={<Radio />}
              label="Réservations confirmées"
            />
            <FormControlLabel
              className={styles.showCanceledDesktopLabel}
              checked={showCanceled}
              value="true"
              control={<Radio />}
              label="Réservations annulées"
            />
          </RadioGroup>
          <ButtonFab onClick={closeFilters}>Valider</ButtonFab>
        </React.Fragment>
      </DesktopModalContainer>

      <MobilePageHeader
        center={
          <CalendarMonthPickerMobile
            value={date.toISOString()}
            onChange={onChangeDate}
          />
        }
        right={
          <div
            className="calendar_filter_selector-mobile"
            onClick={openFilters}
          >
            Filtres
          </div>
        }
      />
      <PageContent>
        <div className={styles.hairdresserFilterContainer}>
          <div
            style={{ color: TEALISH }}
            onClick={() => setSelectedHairdresserId(null)}
          >
            <span>Tous</span>
            {selectedHairdresserId === null && <Underline color={TEALISH} />}
          </div>
          {hairdressers.map((hairdresser) => (
            <div
              key={hairdresser.id || Number(hairdresser.stringId)}
              style={{ color: hairdresser.color }}
              onClick={() =>
                setSelectedHairdresserId(
                  hairdresser.id || Number(hairdresser.stringId)
                )
              }
            >
              <span>{hairdresser.firstName}</span>
              {selectedHairdresserId === hairdresser.id ||
                (selectedHairdresserId === Number(hairdresser.stringId) && (
                  <Underline color={hairdresser.color} />
                ))}
            </div>
          ))}
        </div>
        <div className={styles.bookingsContainer}>{bookingsList}</div>
      </PageContent>
    </PageContainer>
  );
}

const Underline: React.FC<{ color: string }> = ({ color }) => (
  <div className={styles.underline} style={{ backgroundColor: color }} />
);

function BookingsPageDesktop({
  date,
  updateDate,
  hairdressers,
  selectedHairdresserId,
  setSelectedHairdresserId,
  bookingsList,
  showCanceled,
  setShowCanceled,
}: BookingsPageNonResponsiveProps) {
  return (
    <>
      <div
        className={classnames("container-no-iframe", {
          "container-iframe": isIFrame(),
        })}
      >
        <MenuDesktop noShadow />
        <div
          className={classnames("", {
            "grid-toolbar": isIFrame(),
          })}
        >
          <BookingsToolbar
            {...{
              date,
              updateDate,
              hairdressers,
              selectedHairdresserId,
              setSelectedHairdresserId,
              showCanceled,
              setShowCanceled,
            }}
          />
          <div
            className={styles.bookingsContainer}
            style={{ gridArea: "content" }}
          >
            {bookingsList}
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingsPage;
