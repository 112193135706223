import React, { useState, memo } from "react";

function PlusCircle(props: any) {
  const [hover, setHover] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
      <g>
        <path fill="#fff" d="M0 0h30v30H0z" />
        <path
          fill={hover ? "#060385" : "#d3d3d3"}
          d="M15 0a15 15 0 1015 15A15.005 15.005 0 0015 0zm6 16h-5v5h-2v-5H9v-2h5V9h2v5h5z"
        />
      </g>
    </svg>
  );
}

export default memo(PlusCircle);
