import React from "react";
import { TEALISH } from "../../constants/Style";

const CheckBoxCheckedIconMobile = ({
  color,
  height = 19,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={height} height={height} viewBox="0 0 19 19" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-14-135h375v667H-14z" />
      <g transform="translate(1 1)">
        <rect
          stroke={color || TEALISH}
          fill={color || TEALISH}
          width={17}
          height={17}
          rx={2}
        />
        <path
          stroke="#FFF"
          strokeWidth={2}
          strokeLinecap="round"
          d="M4 8.938L7.043 12 14 5"
        />
      </g>
    </g>
  </svg>
);

export default CheckBoxCheckedIconMobile;
