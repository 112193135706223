import React, { useCallback } from "react";
import Drawer from "@material-ui/core/Drawer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import { useToggle } from "react-use";

import { LIGHT_BLUE_GREY } from "constants/Style";
import LargeClickable from "components/uiElements/LargeClickable";
import CloseIcon from "components/svgs/CloseIcon";
import Checkbox from "components/inputs/Checkbox";
import { Hairdresser } from "reducers/salon";
import { IFiltersSelectorProps } from "../index";
import { getDisplayCheckboxes, hairdresserToInput } from "../utils";
import styles from "./FiltersSelector.module.scss";

function FiltersSelector({
  formValues,
  allHairdressers,
  onChange,
  open,
  onClose,
  onSubmit,
}: IFiltersSelectorProps) {
  const displayFilterCheckboxes = getDisplayCheckboxes(formValues);
  const enabledHairdresserInputs = allHairdressers
    .filter((h) => h.status === "ENABLED")
    .map(hairdresserToInput);
  const disabledHairdresserInputs = allHairdressers
    .filter((h) => h.status === "DISABLED")
    .map(hairdresserToInput);
  const handleHairdresserChange = useCallback(
    (hairdresser: Hairdresser) => (_e: unknown, checked: boolean) => {
      const newHairdresserIds = formValues.hairdresserIds.filter(
        (id) => id !== hairdresser.id && id !== Number(hairdresser.stringId)
      );
      if (checked) {
        newHairdresserIds.push(hairdresser.id || Number(hairdresser.stringId));
      }
      onChange({
        target: {
          name: "hairdresserIds",
          value: newHairdresserIds,
        },
      } as any);
    },
    [formValues.hairdresserIds, onChange]
  );
  const close = () => {
    onSubmit();
    onClose();
  };

  const [showDisabledHairdressers, toggleShowDisabledHairdressers] = useToggle(
    false
  );
  const disabledHairdresserToggleTitle = showDisabledHairdressers
    ? `▲ Cacher les professionnels désactivés`
    : `▼ Afficher les professionnels désactivés`;

  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <div className={styles.container}>
        <div className={styles.titleBar}>
          <span className={styles.title}>Filtres</span>
          <LargeClickable onClick={close} className={styles.closeButton}>
            <CloseIcon color={LIGHT_BLUE_GREY} />
          </LargeClickable>
        </div>
        <div>
          <div className={styles.filterGroup}>
            <span className={styles.title}>Afficher</span>
            <div>
              {displayFilterCheckboxes.map((item) => (
                <div key={item.name}>
                  <FormControlLabel
                    name={item.name}
                    classes={{
                      root: styles.labelRoot,
                      label: styles.label,
                    }}
                    control={<Checkbox classes={{ root: styles.checkbox }} />}
                    label={item.display}
                    checked={item.value}
                    onChange={onChange}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.filterGroup}>
            <span className={styles.title}>Les professionnels</span>
            <div>
              {enabledHairdresserInputs.map((item) => (
                <div key={item.value} style={{ color: item.hairdresser.color }}>
                  <FormControlLabel
                    name="hairdresserIds"
                    classes={{
                      root: styles.labelRoot,
                      label: styles.label,
                    }}
                    control={
                      <Checkbox
                        classes={{ root: styles.checkbox }}
                        checkedColor={item.hairdresser.color}
                      />
                    }
                    label={item.display}
                    checked={formValues.hairdresserIds.includes(
                      Number(item.value)
                    )}
                    onChange={handleHairdresserChange(item.hairdresser)}
                    value={item.value}
                  />
                </div>
              ))}
              {disabledHairdresserInputs.length > 0 && (
                <>
                  <div
                    onClick={toggleShowDisabledHairdressers}
                    className={styles.disabledHairdressersToggle}
                  >
                    {disabledHairdresserToggleTitle}
                  </div>
                  <Collapse in={showDisabledHairdressers}>
                    {disabledHairdresserInputs.map((item) => (
                      <div
                        key={item.value}
                        style={{ color: item.hairdresser.color }}
                      >
                        <FormControlLabel
                          name="hairdresserIds"
                          classes={{
                            root: styles.labelRoot,
                            label: styles.label,
                          }}
                          control={
                            <Checkbox
                              classes={{ root: styles.checkbox }}
                              checkedColor={item.hairdresser.color}
                            />
                          }
                          label={item.display}
                          checked={formValues.hairdresserIds.includes(
                            Number(item.value)
                          )}
                          onChange={handleHairdresserChange(item.hairdresser)}
                          value={Number(item.value)}
                        />
                      </div>
                    ))}
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default FiltersSelector;
