import React from "react";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import PickerArrow from "../svgs/PickerArrow";
import { BLUE_GREY } from "../../constants/Style";
import { capitalize } from "../../utils/string";
import { defaultCalendarFormat, dateInputFormat } from "../../constants/Input";
import moment from "moment";

interface DateInputProps extends StandardTextFieldProps {
  value: string;
  calendarFormat?: moment.CalendarSpec;
  min?: string;
}
const getDisplayedDate = (
  value: string,
  calendarFormat: moment.CalendarSpec | undefined
) => {
  const date = moment(value);
  return capitalize(
    date.calendar(undefined, calendarFormat || defaultCalendarFormat)
  );
};

export default function DateInput(props: DateInputProps) {
  const { disabled, value, calendarFormat, min, ...otherProps } = props;
  return (
    <div className="input-group input_date-container">
      <TextField
        variant="standard"
        type="date"
        helperText={getDisplayedDate(value, calendarFormat)}
        disabled={disabled}
        {...otherProps}
        value={moment(value).format(dateInputFormat)}
        InputLabelProps={{
          shrink: true,
          className: "input_label",
          ...props.InputLabelProps
        }}
        InputProps={{
          className: "input_input input_date",
          ...props.InputProps
        }}
        // The material-ui API has duplicate props, differing only in casing
        // eslint is not happy with it...
        // eslint-disable-next-line
        inputProps={{
          min
        }}
        FormHelperTextProps={{
          className: "input_date_helper_text"
        }}
      />
      {!disabled && (
        <PickerArrow
          large
          className="input_date-picker-arrow"
          color={BLUE_GREY}
        />
      )}
    </div>
  );
}
