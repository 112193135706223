import { useState, useCallback, useRef } from "react";

function fetchEmptyArray() {
  return Promise.resolve([]);
}

export interface FetchOptions {
  offset?: number;
  limit?: number;
  dateMin?: string;
  dateMax?: string;
}

export interface UseProgressiveListOptions<ListItem> {
  initialList: ListItem[];
  initialOffset: number;
  limit: number;
}

export interface IUseProgressiveList<ListItem> {
  list: ListItem[];
  fetchMore(): void;
  canFetchMore: boolean;
  isFetching: boolean;
  resetFetchFn(fetchFn: IFetchFn<ListItem>): void;
}

type IFetchFn<ListItem> = (opts?: FetchOptions) => Promise<ListItem[]>;

const defaultList = [] as never[];
const defaultOffset = 0;
const defaultLimit = 10;

export default function useProgressiveList<ListItem>(
  fetchFn: IFetchFn<ListItem> = fetchEmptyArray,
  {
    initialList = defaultList,
    initialOffset = defaultOffset,
    limit = defaultLimit
  }: Partial<UseProgressiveListOptions<ListItem>> = {}
): IUseProgressiveList<ListItem> {
  const [localFetchFn, setLocalFetchFn] = useState(() => fetchFn);
  const [list, setList] = useState(initialList);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const offsetRef = useRef(initialOffset);
  const fetchMore = useCallback(() => {
    setIsFetching(true);
    localFetchFn({ offset: offsetRef.current, limit })
      .then(newListItems => {
        setList(currentList => [...currentList, ...newListItems]);
        offsetRef.current += limit;
        if (newListItems.length < limit) {
          setCanFetchMore(false);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [localFetchFn]);
  const resetFetchFn = (fetchFn: {
    (opts?: FetchOptions): Promise<ListItem[]>;
  }) => {
    offsetRef.current = initialOffset;
    setLocalFetchFn(() => fetchFn);
    setList(initialList);
    setCanFetchMore(true);
    setIsFetching(false);
  };
  return {
    list,
    canFetchMore,
    fetchMore,
    isFetching,
    resetFetchFn
  };
}
