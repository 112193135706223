import { mutation, safeError, query } from "utils/graphqlClient";
import getEsClient from "utils/esClient";

export const uploadBackgrounds = async (
  salonId: number,
  fileName: string,
  data: string
) => {
  const res = await mutation(
    "uploadBackgrounds",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
      data: {
        type: "String",
        value: data,
      },
    },
    "id, backgrounds"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.uploadBackgrounds)
    : Promise.reject(safeError(body));
};

export const uploadCarousel = async (
  salonId: number,
  fileName: string,
  data: string
) => {
  const res = await mutation(
    "uploadCarousel",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
      data: {
        type: "String",
        value: data,
      },
    },
    "id, carouselImages"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.uploadCarousel)
    : Promise.reject(safeError(body));
};

export const uploadLogo = async (
  salonId: number,
  fileName: string,
  data: string
) => {
  const res = await mutation(
    "uploadLogo",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
      data: {
        type: "String",
        value: data,
      },
    },
    "id, logo"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.uploadLogo)
    : Promise.reject(safeError(body));
};

export const deleteLogo = async (salonId: number) => {
  const res = await mutation(
    "updateSalon",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      logo: {
        type: "String",
        value: "",
      },
    },
    "id, logo"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updateSalon)
    : Promise.reject(safeError(body));
};

export const uploadPhoto = async (
  hairdresserId: number,
  salonId: number,
  fileName: string,
  data: string
) => {
  const res = await mutation(
    "uploadPhoto",
    {
      id: {
        type: "Int",
        value: Number(hairdresserId),
      },
      salonId: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
      data: {
        type: "String",
        value: data,
      },
    },
    "id, image"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.uploadPhoto)
    : Promise.reject(safeError(body));
};

export const deleteBackgrounds = async (salonId: number, fileName: string) => {
  const res = await mutation(
    "deleteBackgrounds",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
    },
    "id, backgrounds"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.deleteBackgrounds)
    : Promise.reject(safeError(body));
};

export const deleteCarousel = async (salonId: number, fileName: string) => {
  const res = await mutation(
    "deleteCarousel",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      fileName: {
        type: "String",
        value: fileName,
      },
    },
    "id, carouselImages"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.deleteCarousel)
    : Promise.reject(safeError(body));
};

export const setSalonPublic = async (salonId: number) => {
  const res = await mutation(
    "updateSalon",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      status: {
        type: "String",
        value: "PUBLIC",
      },
    },
    "id, status"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updateSalon)
    : Promise.reject(safeError(body));
};

const ESSalonByNameQuery = (name: string, fields?: string[]) => {
  const _source = fields ? fields : undefined;
  return {
    index: "salons",
    _source,
    body: {
      query: {
        query_string: {
          fields: ["name"],
          query: `*${name}*`,
          default_operator: "AND",
        },
      },
    },
  };
};

export async function searchSalonsByName<T>(name: string, fields?: string[]) {
  const query = ESSalonByNameQuery(name, fields);
  try {
    const esClient = await getEsClient();
    const result = await esClient.search(query);
    const { hits } = result.hits;
    return hits.map((h) => h._source as T);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const updateSalonPackages = async (
  salonId: number,
  packageIds: Array<number>
) => {
  try {
    const res = await mutation(
      "updateSalon",
      {
        id: {
          type: "Int",
          value: salonId,
        },
        packageIds: {
          type: "[Int]",
          value: packageIds,
        },
      },
      "id, packageIds"
    );
    const body = await res.json();
    return res.ok
      ? Promise.resolve(body.data.updateSalon)
      : Promise.reject(safeError(body));
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const createIncreasePriceLog = async (
  salonId: number,
  amount: number,
  author: string,
  performanceIds: Number[]
) => {
  const res = await mutation(
    "createIncreasePriceLog",
    {
      salonId: {
        type: "Int",
        value: salonId,
      },
      amount: {
        type: "Float",
        value: amount,
      },
      performanceIds: {
        type: "[Int]",
        value: performanceIds,
      },
      author: {
        type: "String",
        value: author,
      },
    },
    "id"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.createIncreasePriceLog)
    : Promise.reject(safeError(body));
};

export const getIncreasePriceLogs = async (salonId: Number) => {
  const res = await query(`increasePriceLogs(salonId: ${salonId}){amount}`);

  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.increasePriceLogs)
    : Promise.reject(safeError(body));
};

export const matchFlexyPackages = async (
  companyCode: string,
  packageIds: Array<number>
) => {
  try {
    const res = await mutation(
      "matchFlexyPackages",
      {
        companyCode: {
          type: "String",
          value: companyCode,
        },
        packageIds: {
          type: "[Int]",
          value: packageIds,
        },
      },
      "found,flexyId,packageId,name"
    );
    const body = await res.json();
    return res.ok
      ? Promise.resolve(body.data.matchFlexyPackages)
      : Promise.reject(safeError(body));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSalonsByCompanyCode = async (
  token: string,
  companyCode: string
) => {
  const res = await query(
    `salons(partnerRefsId: "${companyCode}",limit: 20){id,auths{id,username,roles}}`,
    { token }
  );

  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.salons)
    : Promise.reject(safeError(body));
};
