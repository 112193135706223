import React from "react";
import { WHITE } from "../../constants/Style";

const FiltersIcon = ({
  height = 23,
  color = WHITE,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={height} height={height} viewBox="0 0 23 23" {...props}>
    <g
      transform="translate(0 1)"
      stroke={color}
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <path
        strokeLinecap="round"
        d="M3.607.5v20.504M11.607.5v20.504M19.607.5v20.504"
      />
      <circle fill="#FFF" cx={3.5} cy={6.5} r={2.5} />
      <circle fill="#FFF" cx={19.5} cy={6.5} r={2.5} />
      <circle fill="#FFF" cx={11.5} cy={15.5} r={2.5} />
    </g>
  </svg>
);

export default FiltersIcon;
