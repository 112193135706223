import { mutation, safeError } from "utils/graphqlClient";
import { AuthInfos } from "reducers/auth";
import { loginSchema } from "reducers/auth";
import * as yup from "yup";

export const singleSignOnSchema = yup.object().shape({
  username: yup.string().email().required(),
  token: yup.string().required(),
});

export type ISingleSignOn = yup.InferType<typeof singleSignOnSchema>;

export const sso = async ({ username, token }: ISingleSignOn) => {
  const res = await mutation(
    "sso",
    {
      username: {
        type: "String",
        value: username,
      },
    },
    "userId, mobile, token, authId, salonIds, roles",
    { token }
  );
  const body = await res.json();
  if (res.ok) {
    const loginInfos = await loginSchema.validate(body.data.sso);
    return loginInfos;
  }
  throw new Error(safeError(res));
};

export const flexySso = async () => {
  try {
    const splitedUrl = window.location.pathname.split("/");
    if (splitedUrl.length === 3 && splitedUrl[1] === "sso") {
      const token = splitedUrl[2];
      const headers = {
        "X-Api-Key": process.env.REACT_APP_FLEXY_PUBLIC || "",
        "content-type": "application/json",
      };
      const res = await fetch(
        `${process.env.REACT_APP_FLEXY_SERVICE_URL}/sso/url/${token}`,
        {
          method: "GET",
          headers,
        }
      );
      const body = await res.json();
      return body.result;
    }
  } catch (err) {
    return Promise.resolve();
  }
  return Promise.resolve();
};
