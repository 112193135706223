import React from "react";
import MaterialCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";

import { LIGHT_BLUE_GREY } from "constants/Style";
import CheckBoxIcon from "components/svgs/CheckBoxIcon";
import CheckBoxCheckedIconMobile from "components/svgs/CheckBoxCheckedIcon.mobile";
import CheckBoxCheckedIconDesktop from "components/svgs/CheckBoxCheckedIcon.desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import CheckBoxInderterminateIcon from "components/svgs/CheckBoxInderterminateIcon";

interface ICheckboxProps extends CheckboxProps {
  checkedColor?: string;
}

function Checkbox({ checkedColor, disabled, ...props }: ICheckboxProps) {
  const { isDesktop } = useResponsiveLayout();
  const CheckedIcon = isDesktop
    ? CheckBoxCheckedIconDesktop
    : CheckBoxCheckedIconMobile;

  const iconHeight = isDesktop ? 21 : 19;
  return (
    <MaterialCheckbox
      icon={
        <CheckBoxIcon
          color={disabled ? LIGHT_BLUE_GREY : undefined}
          height={iconHeight}
        />
      }
      checkedIcon={<CheckedIcon color={checkedColor} height={iconHeight} />}
      indeterminateIcon={
        <CheckBoxInderterminateIcon
          color={disabled ? LIGHT_BLUE_GREY : undefined}
          height={iconHeight}
        />
      }
      disabled={disabled}
      {...props}
    />
  );
}

export default Checkbox;
