import React from "react";
import { WHITE } from "../../constants/Style";

const SearchIcon = ({ color = WHITE, ...props }: any) => (
  <svg width={21} height={21} {...props}>
    <g
      transform="rotate(-45 9.39 8.132)"
      fillRule="nonzero"
      stroke={color}
      strokeWidth={2}
      fill="none"
    >
      <circle cx={8.5} cy={8.5} r={7.5} />
      <path d="M8.5 16v7.898" strokeLinecap="round" />
    </g>
  </svg>
);

export default SearchIcon;
