import React from "react";
import { Payout } from "reducers/payments";

import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import ModalContainer from "components/modal/Container/Container.desktop";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import { bookingToPrestation } from "utils/payments";
import ListByDate, { ListByDateSkeleton } from "../ListByDate/ListByDate";
import MobileBottomDrawer from "../PaymentListPage/MobileBottomDrawer/MobileBottomDrawer";
import { prestationToItem } from "../PaymentListPage/PaymentListPageTemplate/PaymentListPageTemplate";
import PrestationItem, {
  PrestationItemSkeleton
} from "../PaymentListPage/PrestationItem/PrestationItem";
import styles from "./PayoutDetails.module.scss";

interface PayoutDetailsProps {
  open: boolean;
  isLoading: boolean;
  onClose(): void;
  payout?: Payout;
}

const title = "Liste des prestations relatives au virement";

function PayoutDetails({
  open,
  isLoading,
  onClose,
  payout
}: PayoutDetailsProps) {
  const { isMobile } = useResponsiveLayout();

  const isEmptyResult =
    !isLoading && (!payout?.bookings || payout?.bookings?.length === 0);

  const content = isEmptyResult ? (
    <div className={styles.emptyResultContainer}>
      Il semble y avoir une erreur, nous n'arrivons pas à trouver de prestations
      associés à ce paiement
    </div>
  ) : (
    <div className={styles.resultContainer}>
      {isLoading || !payout ? (
        <ListByDateSkeleton itemComponent={PrestationItemSkeleton} />
      ) : (
        payout.bookings && (
          <ListByDate
            className={styles.listContainer}
            list={payout.bookings.map(booking =>
              prestationToItem(bookingToPrestation(booking))
            )}
            itemComponent={PrestationItem}
          />
        )
      )}
    </div>
  );
  return (
    <>
      <Mobile>
        <MobileBottomDrawer
          open={open}
          onClose={onClose}
          title={isMobile && <span className={styles.title}>{title}</span>}
        >
          {content}
        </MobileBottomDrawer>
      </Mobile>
      <Desktop>
        <ModalContainer open={open}>
          <ModalLayoutDesktop onClose={onClose} header={title} body={content} />
        </ModalContainer>
      </Desktop>
    </>
  );
}

export default PayoutDetails;
