const getFromCache = (key: string) => {
  try {
    const item = JSON.parse(localStorage.getItem(key) as string);
    return !item || item == null ? {} : item;
  } catch (err) {
    return {};
  }
};

export { getFromCache };
