import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import PhaseTwo from "components/svgs/LogoTransition/PhaseTwo";

function AppLoading() {
  return (
    <Grid
      style={{ height: "100vh" }}
      container
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item classes={{ root: "login-page-header__logo" }}>
          <PhaseTwo />
        </Grid>
      </Grid>
      <Grid item>
        <Typography color="primary">Chargement de votre compte...</Typography>
      </Grid>
      <Grid item>
        <CircularProgress color="primary" size={20} />
      </Grid>
    </Grid>
  );
}

export { AppLoading };
