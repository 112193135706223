export const hasSalonOption = async (
  option: string,
  companyCode: string
): Promise<boolean> => {
  try {
    const response = await fetch(
      `https://serverless.leciseau.fr/lambda/salonOption?env=${process.env.REACT_APP_SERVERLESS_ENV}&companyCode=${companyCode}&option=${option}`
    );
    const body = await response.json();

    return body === true;
  } catch (err) {
    return false;
  }
};
