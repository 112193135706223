import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MomentUtils from "@date-io/moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DARK } from "constants/Style";
import PageToolbarDesktop from "components/PageToolbar/PageToolbar.desktop";
import Radio from "components/inputs/Radio";
import PickerArrow from "components/svgs/PickerArrow";
import { BookingsPageProps } from "../BookingsPage";
import styles from "./Toolbar.module.scss";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type BookingsToolbarProps = Pick<
  BookingsPageProps,
  | "date"
  | "updateDate"
  | "hairdressers"
  | "selectedHairdresserId"
  | "setSelectedHairdresserId"
  | "showCanceled"
  | "setShowCanceled"
>;

function BookingsToolbar({
  date,
  updateDate,
  hairdressers,
  selectedHairdresserId,
  setSelectedHairdresserId,
  showCanceled,
  setShowCanceled,
}: BookingsToolbarProps) {
  const onChangeHairdresser = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    _child: React.ReactNode
  ) => {
    const value = e.target.value as number | null;
    const newId = value === -1 ? null : value;
    setSelectedHairdresserId(newId);
  };

  return (
    <PageToolbarDesktop>
      <div className={styles.container}>
        <div>
          <FormControl variant="outlined" className={styles.hairdresserSelect}>
            <InputLabel
              id="hairdresser-select-label"
              htmlFor="hairdresser-select"
              classes={{
                root: styles.label,
              }}
            >
              Sélectionner un professionnel
            </InputLabel>
            <Select
              labelWidth={220}
              id="hairdresser-select"
              labelId="hairdresser-select-label"
              value={selectedHairdresserId ?? -1}
              renderValue={(hairdresserId) =>
                hairdresserId === -1
                  ? "Tous les professionnels"
                  : (
                      hairdressers.find(
                        (h) =>
                          (h.id && h.id === hairdresserId) ||
                          Number(h.stringId) === hairdresserId
                      ) || {}
                    ).firstName
              }
              onChange={onChangeHairdresser}
              classes={{
                root: styles.input,
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              IconComponent={() => (
                <PickerArrow
                  large
                  color={DARK}
                  className={styles.pickerArrow}
                />
              )}
            >
              <MenuItem key="all_hairdressers" value={-1}>
                <Radio checked={!selectedHairdresserId} />
                <ListItemText>Tous les professionnels</ListItemText>
              </MenuItem>
              {hairdressers.map((hairdresser) => (
                <MenuItem
                  key={hairdresser.id || Number(hairdresser.stringId)}
                  value={hairdresser.id || Number(hairdresser.stringId)}
                >
                  <Radio
                    checked={
                      selectedHairdresserId === hairdresser.id ||
                      selectedHairdresserId === Number(hairdresser.stringId)
                    }
                  />
                  <ListItemText style={{ color: hairdresser.color }}>
                    {hairdresser.firstName}
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={styles.datePickerContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              openTo="month"
              views={["year", "month"]}
              label="Sélectionner le mois"
              InputProps={{
                labelWidth: 165,
              }}
              value={date}
              onChange={(momentDate) =>
                updateDate(momentDate?.toISOString() ?? "")
              }
              inputProps={{
                className: styles.input,
              }}
            />
          </MuiPickersUtilsProvider>
          <PickerArrow large color={DARK} className={styles.pickerArrow} />
        </div>
        <div className={styles.toolbarShowCanceled}>
          <RadioGroup
            aria-label="showCanceled"
            name="showCanceled"
            value={showCanceled}
            onChange={(event) => setShowCanceled(event.target.value === "true")}
          >
            <FormControlLabel
              className={styles.showCanceledDesktopLabel}
              checked={!showCanceled}
              value="false"
              control={<Radio />}
              label="Réservations confirmées"
            />
            <FormControlLabel
              className={styles.showCanceledDesktopLabel}
              checked={showCanceled}
              value="true"
              control={<Radio />}
              label="Réservations annulées"
            />
          </RadioGroup>
        </div>
      </div>
    </PageToolbarDesktop>
  );
}

export default BookingsToolbar;
