import * as types from "../constants/ActionTypes";
import {
  CHANGE_HAIDRESSER_ERRORS,
  CHANGE_HAIDRESSER_OK,
  CHANGE_HAIDRESSER_GRAPHQL_ERROR,
  DEFAULT_BOOKING_DISCOUNT,
} from "../constants/Booking";
import { Booking } from "../reducers/booking";
import { Dispatch } from "redux";
import {
  cancelBookingById,
  updateBookingHairdresser,
  fetchBookingsBySalonId as fetchBookingsBySalonIdFromServer,
  getFetchBookingDateOptions,
} from "../utils/booking";
import { errorToString } from "../utils/string";
import { pushMessage } from "./SnackbarActions";
import { MESSAGE_TYPES } from "../constants/SnackBar";
import { AuthLogoutAction } from "./AuthActions";
import { AppState } from "../reducers";

interface FetchBookingsAction {
  type: typeof types.FETCH_BOOKINGS;
}

export interface FetchBookingsSuccessAction {
  type: typeof types.FETCH_BOOKINGS_SUCCESS;
  payload: Record<number, Booking>;
}

interface FetchBookingsFailureAction {
  type: typeof types.FETCH_BOOKINGS_FAILURE;
  error: string;
}

interface UpdateBookingAction {
  type: typeof types.UPDATE_BOOKING;
}

export interface UpdateBookingSuccessAction {
  type: typeof types.UPDATE_BOOKING_SUCCESS;
  payload: Booking;
}

interface UpdateBookingFailureAction {
  type: typeof types.UPDATE_BOOKING_FAILURE;
  error: string;
}

interface CancelBookingAction {
  type: typeof types.CANCEL_BOOKING;
}

export interface CancelBookingSuccessAction {
  type: typeof types.CANCEL_BOOKING_SUCCESS;
  payload: Booking;
}

interface CancelBookingFailureAction {
  type: typeof types.CANCEL_BOOKING_FAILURE;
  error: string;
}

export type BookingActions =
  | FetchBookingsAction
  | FetchBookingsSuccessAction
  | FetchBookingsFailureAction
  | UpdateBookingAction
  | UpdateBookingSuccessAction
  | UpdateBookingFailureAction
  | CancelBookingAction
  | CancelBookingSuccessAction
  | CancelBookingFailureAction
  | AuthLogoutAction;

export const fetchBookingsBySalonId = (
  salonId: number,
  dateTarget: string,
  { range = "DAY" }: { range?: "DAY" | "WEEK" | "MONTH" } = {}
) => (dispatch: Dispatch, _getState: { (): AppState }): Promise<any> => {
  dispatch({ type: types.FETCH_BOOKINGS });
  const options = getFetchBookingDateOptions(dateTarget, range);
  return fetchBookingsBySalonIdFromServer(salonId, options)
    .then((bookings: Array<Booking>) => {
      const bookingById = bookings.reduce(
        (byId, booking) => ({
          ...byId,
          [booking.id]: {
            ...booking,
            discount: booking.discount ?? DEFAULT_BOOKING_DISCOUNT,
          },
        }),
        {}
      );
      dispatch({
        type: types.FETCH_BOOKINGS_SUCCESS,
        payload: bookingById,
      });
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch({ type: types.FETCH_BOOKINGS_FAILURE, payload: error });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const updateBookingHairdresserSuccess = (data: Booking) => ({
  type: types.UPDATE_BOOKING_SUCCESS,
  payload: data,
});

export const updateBookingHairdresserFailure = (error: string) => ({
  type: types.UPDATE_BOOKING_FAILURE,
  error,
});

export const cancelBooking = (booking: Booking, cancellationReason: string) => (
  dispatch: Dispatch
) => {
  if (!booking) {
    return;
  }
  dispatch({ type: types.CANCEL_BOOKING });
  return cancelBookingById(booking.id, cancellationReason)
    .then((canceledBooking: Booking) =>
      dispatch({
        type: types.CANCEL_BOOKING_SUCCESS,
        payload: { ...canceledBooking },
      })
    )
    .catch((error: string) =>
      dispatch({
        type: types.CANCEL_BOOKING_FAILURE,
        error,
      })
    );
};

export const changeBookingHairdresser = (
  booking: Booking,
  newHairdresserId: number,
  newHairdresserStringId: string
) => async (dispatch: Dispatch) => {
  dispatch({ type: types.UPDATE_BOOKING });
  return updateBookingHairdresser(
    booking,
    newHairdresserId,
    newHairdresserStringId
  )
    .then((updatedBooking: Booking) => {
      dispatch(updateBookingHairdresserSuccess(updatedBooking));
      return { status: CHANGE_HAIDRESSER_OK };
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(updateBookingHairdresserFailure(error));
      if (error === CHANGE_HAIDRESSER_GRAPHQL_ERROR.NO_SLOT_OPENED) {
        return Promise.resolve({
          status: CHANGE_HAIDRESSER_ERRORS.NO_SLOT_OPENED,
        });
      }
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
