import * as yup from "yup";

import { approvalRequestSchema } from "reducers/salon";
import { query, safeError } from "utils/graphqlClient";

export const fetchBySalonId = async (salonId: number, type?: string) => {
  const res = await query(
    `approvalRequests(salonId: ${salonId}, status: "WAITING"${
      type ? `, type: "${type}"` : ""
    }){
        id,
        type,
        status,
        comment,
        rejectionReason,
        dateStatusUpdated,
        payload{
            type,
            variables,
            status,
            rejectionReason
        },
        createdAt,
        updatedAt
    }`
  );
  const body = await res.json();
  if (res.ok) {
    try {
      const approvalRequests = await yup
        .array()
        .of(approvalRequestSchema)
        .validate(body?.data?.approvalRequests);
      return Promise.resolve(approvalRequests);
    } catch (error) {
      console.error({ error });
    }
  }
  return Promise.reject(safeError(body));
};
