import { LastMinuteDays } from "reducers/lastMinute";
import { DAYS } from "constants/Week";

export const allowedHoursBefore = [6, 12, 24, 48, 72];

export const createLastMinuteEmptyDays = (): LastMinuteDays =>
  Object.keys(DAYS).reduce(
    (acc, dayName) => ({
      ...acc,
      [dayName]: true,
    }),
    {} as LastMinuteDays
  );

export const hoursToMinutes = (hours: number): number => hours * 60;
export const minutesToHours = (minutes: number): number => minutes / 60;
