import React, { memo } from "react";
import { Week } from "reducers/week";
import { Hairdresser } from "reducers/salon";
import styles from "./styles.module.scss";
import Switch from "@material-ui/core/Switch";
import Delete from "components/svgs/Delete";
import { Conflict } from "constants/Week";
import PromoSelector from "../PromoSelector/PromoSelector";
import HairdresserList from "../HairdresserList/HairdresserList";
import DaySelector from "../DaySelector/DaySelector";
import HourSelector from "../HourSelector/HourSelector";

interface HappyHourFormProps {
  index: number;
  week: Week;
  saveWeek(index: number, week: Week): void;
  conflicts: Array<Conflict>;
  setCurrentIndex(index: number): void;
  setHairdresserSelectorOpen(open: boolean): void;
  hairdressers: Array<Hairdresser>;
}

const findHairdresserConflict = (
  hairdressers: Array<Hairdresser>,
  conflict: Conflict
) =>
  hairdressers.find(
    (h) =>
      (h.id && h.id === conflict.hairdresserId) ||
      (conflict.hairdresserStringId || "")?.indexOf(h.stringId) > -1
  );

const HappyHourForm = ({
  index,
  week,
  saveWeek,
  conflicts,
  setCurrentIndex,
  setHairdresserSelectorOpen,
  hairdressers,
}: HappyHourFormProps) => {
  const conflict = conflicts.find(
    (conflict: Conflict) => conflict.happyHour === index
  );
  return (
    <div key={index}>
      <div className={styles.hhTitle}>
        <Switch
          checked={week.status === "ENABLED"}
          onChange={() =>
            saveWeek(index, {
              ...week,
              status: week.status === "ENABLED" ? "DISABLED" : "ENABLED",
            })
          }
        />
        <h3>Promotion happy hour #{index + 1}</h3>
        <div
          className={styles.inlineBtn}
          onClick={() => saveWeek(index, { ...week, status: "DELETED" })}
        >
          <Delete />
        </div>
      </div>
      <PromoSelector object={week} index={index} saveObject={saveWeek} />
      <br />
      <DaySelector week={week} index={index} saveWeek={saveWeek} />
      <br />
      <br />
      <HourSelector week={week} index={index} saveWeek={saveWeek} />
      <br />
      <HairdresserList
        week={week}
        index={index}
        setHairdresserSelectorOpen={setHairdresserSelectorOpen}
        setCurrentIndex={setCurrentIndex}
        hairdressers={hairdressers}
        saveWeek={saveWeek}
      />
      {conflict && (
        <div className={styles.conflictBox}>
          Attention: la promotion Happy Hour #{index + 1} entre en conflit avec
          la promotion Happy Hour #{conflict.conflict + 1} déjà existante.
          <br />
          Le professionnel{" "}
          {findHairdresserConflict(hairdressers, conflict)?.firstName}{" "}
          {findHairdresserConflict(hairdressers, conflict)?.lastName} sera
          automatiquement retiré de la promotion #{conflict.conflict + 1}
        </div>
      )}
      <br />
      <br />
    </div>
  );
};

export default memo(HappyHourForm);
