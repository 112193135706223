import React, { useEffect } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { getMonthDaysOf, getById } from "../../../utils/calendar";
import classnames from "classnames";

interface CalendarDayPickerMobileProps {
  value: string;
  onChange(event: React.ChangeEvent): void;
}

function CalendarDayPickerMobile(props: CalendarDayPickerMobileProps) {
  const triggerChange = (day: moment.Moment) => {
    const { onChange } = props;
    onChange({
      target: {
        value: day.toISOString()
      }
    } as any);
  };

  const scrollToWeek = (target: moment.Moment) => {
    const elem = getById("calendar_day_picker-window");
    if (elem) {
      elem.scrollLeft =
        (elem.clientWidth / 7) *
        (moment(target)
          .startOf("week")
          .date() -
          1);
    }
  };

  const { value } = props;
  const date = moment(value);
  const monthDays = getMonthDaysOf(date);

  useEffect(() => scrollToWeek(date), []);

  useEffect(() => {
    const elem = getById(`calendar_day_picker-day-group${date.valueOf()}`);
    elem && elem.scrollIntoView();
  }, [date]);

  return (
    <div id="calendar_day_picker-window" className="calendar_day_picker-window">
      <Grid
        container
        direction="row"
        justify="space-around"
        wrap="nowrap"
        className="calendar-day_picker-list"
        style={{
          width: `${(100 * monthDays.length) / 7}%`
        }}
      >
        {monthDays.map(day => {
          const containerClassName = classnames(
            "calendar_day_picker-day-group",
            {
              selected: day.isSame(date, "day")
            }
          );
          return (
            <Grid
              item
              key={day.valueOf()}
              className={containerClassName}
              id={`calendar_day_picker-day-group${day.valueOf()}`}
              onClick={() => triggerChange(day)}
            >
              <Grid container direction="column" alignItems="center">
                <span className="calendar_day_picker-day-name">
                  {day.format("dd").substr(0, 1)}
                </span>
                <span className="calendar_day_picker-day-number">
                  {day.format("D")}
                </span>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default CalendarDayPickerMobile;
