import React from "react";

const BookingMenuIcon = (props: any) => (
  <svg viewBox="0 0 60 60" height={25} {...props}>
    <defs>
      <style>
        {
          ".booking-1{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}"
        }
      </style>
    </defs>
    <path
      className="booking-1"
      d="M41 26a2.34 2.34 0 00-.72-1A3.25 3.25 0 0038 24a3.34 3.34 0 00-2.3.95l-1 1-2.34 2.4-2.83 2.91-1.41 1.45-3.05-3-.76-.71A3.34 3.34 0 0022 28a2.37 2.37 0 00-1.25.28 2.37 2.37 0 00-1 .67 3.25 3.25 0 00-.95 2.29 3.34 3.34 0 00.95 2.3l2.09 2.05 3.31 3.25.76.75a2.51 2.51 0 001.05.68 2.41 2.41 0 001.25.27 3.33 3.33 0 002.3-.95l1-1 2.34-2.4 2.84-2.91 2.44-2.51 1.2-1.22a3.34 3.34 0 00.95-2.29A2.33 2.33 0 0041 26z"
    />
    <path
      className="booking-1"
      d="M8.22 16.03h43.57v32H8.22zM15.24 11.97v6.38M44.05 11.97v6.38"
    />
  </svg>
);

export default BookingMenuIcon;
