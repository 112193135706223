import React from "react";
import { Hairdresser } from "reducers/salon";
import Select from "components/inputs/Select";
import Checkbox from "components/inputs/Checkbox";
import styles from "./FreeSlotForm.module.scss";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import MobileSelect from "components/inputs/Select/Mobile";

type MobileHairdresserSelectProps = {
  field: string;
  value: any;
  list: Array<{ display: string; value: string | number }>;
  onChange: any;
  mode: string;
  isError: any;
  errors: any;
};

type DesktopHairdresserSelectProps = Omit<
  MobileHairdresserSelectProps,
  "list"
> & {
  hairdressers: Array<Hairdresser>;
  getHairdresserValue: any;
  isHairdresserChecked: any;
};

export const DesktopHairdresserSelect = ({
  field,
  value,
  onChange,
  mode,
  isError,
  errors,
  hairdressers,
  getHairdresserValue,
  isHairdresserChecked,
}: DesktopHairdresserSelectProps) => {
  return (
    <Select
      fullWidth
      multiple
      label="Choix du professionnel"
      name={field}
      value={value}
      renderValue={(hairdresserIds) =>
        (hairdresserIds as string[])
          .map(
            (id) =>
              (hairdressers.find((h) => getHairdresserValue(h) === id) || {})
                .firstName
          )
          .filter((firstName) => firstName)
          .join(", ")
      }
      onChange={onChange}
      disabled={mode === "UPDATE"}
      error={isError(field)}
      helperText={errors[field]}
    >
      {hairdressers.map((hairdresser: Hairdresser) => (
        <MenuItem
          key={hairdresser.id || Number(hairdresser.stringId)}
          value={getHairdresserValue(hairdresser)}
          classes={{
            selected: styles.selectedMenuItem,
          }}
        >
          <Checkbox
            checked={isHairdresserChecked(value, hairdresser)}
            checkedColor={hairdresser.color}
          />
          <ListItemText style={{ color: hairdresser.color }}>
            {hairdresser.firstName}
          </ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

export const MobileHairdresserSelect = ({
  field,
  value,
  list,
  onChange,
  mode,
  isError,
  errors,
}: MobileHairdresserSelectProps) => (
  <MobileSelect
    fullWidth
    multiple
    label="Choix du professionnel"
    name={field}
    value={value}
    listTitle="Sélection professionnels"
    list={list}
    onChange={onChange}
    disabled={mode === "UPDATE"}
    error={isError(field)}
    helperText={errors[field]}
  />
);
