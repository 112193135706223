import { ERRORS } from "../constants/SnackBar";

const translateSnackbarMessage = (message: string): string => {
  switch (message) {
    case "Failed to fetch":
      return "Erreur réseau";
    case "403 Forbidden":
      return "Erreur serveur";
    default:
      return message;
  }
};

/**
 * Hides Errror Message to User that are not very informative
 * and may confuse more than anything else
 * @param message
 * @param type
 */
const shouldHideMessage = (message: string, _type: string): boolean => {
  // Random error message when opening the app ...
  if (message.toLowerCase().includes("canceled")) {
    return true;
  }
  switch (message) {
    case "Failed to fetch":
    case "Bad request":
    case ERRORS.UNKNOWN:
      return true;
    default:
      return false;
  }
};

export { translateSnackbarMessage, shouldHideMessage };
