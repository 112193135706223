import React from "react";

function MobilePageContainer(
  props: React.AllHTMLAttributes<React.ReactElement>
) {
  const { children } = props;
  return (
    <div className="page-container-mobile">
      <div className="page-container-inner-mobile">{children}</div>
    </div>
  );
}

export default MobilePageContainer;
