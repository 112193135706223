import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import Wheels from "./Wheels";
import { Wheel, Separator } from "./Wheel";
import { Input } from "./Input";

const ITEM_HEIGHT = 30;
const ITEM_HEIGHT_UNIT = "px";
const ITEMS_PADDING = 2;
const SELECTED_ITEM_FONT_SIZE = 1.814;
const FONT_SIZE_UNIT = "rem";
const FONT_SIZE_COEF = 0.2;
const OPACITY_COEF = 0.4;

const ITEMS_PER_VIEWPORT = ITEMS_PADDING * 2 + 1;
const PICKER_WHEELS_HEIGHT = `${ITEMS_PER_VIEWPORT *
  ITEM_HEIGHT}${ITEM_HEIGHT_UNIT}`;
const SELECTION_LINES_HEIGHT = `${ITEM_HEIGHT}${ITEM_HEIGHT_UNIT}`;
const SELECTION_LINES_TOP = `${ITEMS_PADDING * ITEM_HEIGHT}${ITEM_HEIGHT_UNIT}`;
const ITEM_HEIGHT_WHIT_UNIT = `${ITEM_HEIGHT}${ITEM_HEIGHT_UNIT}`;
const SELECTED_ITEM_FONT_SIZE_WITH_UNIT = `${SELECTED_ITEM_FONT_SIZE}${FONT_SIZE_UNIT}`;

interface IWheelPickerContext {
  wheelsValue?: any;
  onChange?(value: any): void;
  registerWheel?(name: any, values: any): void;
  isWheelsOpened?: boolean;
  toggleIsWheelsOpened?(): void;
  inputValue?: string;
  floatingWheels?: boolean;
  wheelPickerRef?: React.MutableRefObject<HTMLElement | null>;
}

const WheelPickerContext = createContext<IWheelPickerContext>({});

function useWheelPickerContext() {
  const context = React.useContext(WheelPickerContext);
  if (!context) {
    throw new Error(
      `WheelPicker compound components cannot be rendered outside the WheelPicker component`
    );
  }
  return context;
}

interface IWheelPickerProps<T> {
  values?: T;
  defaultValues?: T;
  onChange?(values: T): void;
  renderValues?(values: T): string;
  children?: React.ReactNode;
  floatingWheels?: boolean;
}

function WheelPicker<T>(props: TextFieldProps & IWheelPickerProps<T>) {
  const {
    children,
    renderValues,
    values: controlledValues,
    onChange: controlledOnChange,
    defaultValues,
    className,
    disabled,
    floatingWheels
  } = props;

  const [isWheelsOpened, setIsWheelsOpened] = useState(false);

  /**
   * The current values of the WheelPicker
   * it can be controlled as a normal input
   */
  const [internalValues, setInternalValues] = useState<any>(
    controlledValues || {}
  );

  /**
   * Current values of the Wheels
   * It is independent of the internal values because the
   * interval values are only updated once you close the wheels
   */
  const [wheelsValue, setWheelsValue] = useState<any>(internalValues);

  const onWheelChange = useCallback(
    wheelValue =>
      setWheelsValue((oldValue: any) => ({ ...oldValue, ...wheelValue })),
    []
  );

  /**
   * String that represents the internal or controller values
   * It is used as the value prop on the Input
   */
  const [inputValue, setInputValue] = useState<string>(
    (renderValues && renderValues(controlledValues || internalValues)) || ""
  );

  /**
   * Updates the inputValue string each time the internal values changes
   */
  useEffect(() => {
    setInputValue(
      (renderValues && renderValues(controlledValues || internalValues)) || ""
    );
  }, [renderValues, controlledValues, internalValues]);

  /**
   * Toggle the Wheels selector
   * if the Wheels selector is opened it updates the internal value
   * and fires the onChange props with the current wheelsValue
   */
  const toggleIsWheelsOpened = useCallback(() => {
    if (disabled) {
      return;
    }
    if (isWheelsOpened) {
      setIsWheelsOpened(false);
      setInternalValues(wheelsValue);
      controlledOnChange && controlledOnChange(wheelsValue);
    } else {
      setIsWheelsOpened(true);
    }
  }, [disabled, isWheelsOpened, controlledOnChange, wheelsValue]);

  /**
   * Initialize the wheels value
   */
  const registerWheel = useCallback(
    (name: string, values: Array<any>) => {
      setWheelsValue((oldValue: any) => {
        if (oldValue[name] === undefined) {
          return {
            ...oldValue,
            [name]: (defaultValues && (defaultValues as any)[name]) || values[0]
          };
        }
        return oldValue;
      });
    },
    [defaultValues]
  );

  useEffect(() => {
    if (
      Object.keys(internalValues).length === 0 &&
      defaultValues &&
      Object.keys(defaultValues).length > 0
    ) {
      setWheelsValue((oldValue: any) => ({
        ...oldValue,
        ...defaultValues
      }));
    }
  }, [defaultValues, internalValues]);

  const wheelPickerRef = useRef(null);

  return (
    <WheelPickerContext.Provider
      value={{
        wheelsValue,
        onChange: onWheelChange,
        registerWheel,
        isWheelsOpened,
        toggleIsWheelsOpened,
        inputValue,
        floatingWheels,
        wheelPickerRef
      }}
    >
      <div
        className={`wheel-picker_container ${className || ""}`}
        ref={wheelPickerRef}
      >
        {children}
      </div>
    </WheelPickerContext.Provider>
  );
}

WheelPicker.Input = Input;
WheelPicker.Wheels = Wheels;
WheelPicker.Wheel = Wheel;
WheelPicker.Separator = Separator;

export {
  useWheelPickerContext,
  ITEM_HEIGHT,
  ITEMS_PADDING,
  SELECTED_ITEM_FONT_SIZE,
  FONT_SIZE_UNIT,
  FONT_SIZE_COEF,
  OPACITY_COEF,
  PICKER_WHEELS_HEIGHT,
  SELECTION_LINES_HEIGHT,
  SELECTION_LINES_TOP,
  ITEM_HEIGHT_WHIT_UNIT,
  SELECTED_ITEM_FONT_SIZE_WITH_UNIT
};
export default WheelPicker;
