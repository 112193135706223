import React from "react";
import Grid from "@material-ui/core/Grid";
import ButtonText from "./button/Text";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import SalonSelector from "./SalonSelector";
import useCurrentUser from "hooks/useCurrentUser";

function AdminSalonSelection() {
  const { logout } = useCurrentUser();
  return <AdminSalonSelectionTemplate onLogout={logout} />;
}

interface IAdminSalonSelectionTemplateProps {
  onLogout(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      marginTop: theme.spacing(2),
    },
  })
);

export function AdminSalonSelectionTemplate({
  onLogout,
}: IAdminSalonSelectionTemplateProps) {
  const classes = useStyles();
  return (
    <Container>
      <Paper className={classes.root}>
        <Grid container alignItems="center" direction="column">
          <span>Vous êtes connecté en tant qu'Admin</span>
          <span style={{ marginTop: "50px" }}>
            Veuillez selectionner un établissement
          </span>
          <Box width="100%">
            <SalonSelector />
          </Box>
        </Grid>
      </Paper>
      <Box position="fixed" bottom="0" left="0" right="0">
        <ButtonText color="error" onClick={onLogout}>
          Se déconnecter
        </ButtonText>
      </Box>
    </Container>
  );
}

export default AdminSalonSelection;
