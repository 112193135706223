import React from "react";
import { VERY_LIGHT_BLUE, WHITE } from "../../constants/Style";

const StarIcon = ({
  color = VERY_LIGHT_BLUE,
  ...props
}: {
  color?: string;
}) => {
  return (
    <svg width={15} height={14} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-71-244h375V868H-71z" />
        <path
          d="M7.5 11.609L2.865 14l.885-5.065L0 5.348l5.182-.74L7.5 0l2.318 4.609L15 5.348l-3.75 3.587.885 5.065z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default StarIcon;
