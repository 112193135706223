import { Reducer } from "redux";
import moment from "moment";
import * as yup from "yup";

import {
  SlotActions,
  FetchSlotsSuccessAction,
  CreateSlotSuccessAction,
  UpdateSlotSuccessAction,
  DeleteSlotSuccessAction,
  CreateRecurrentSlotSuccessAction,
} from "actions/SlotActions";
import { ResetAction } from "actions/ResetAction";
import * as types from "constants/ActionTypes";
import { getSlotsCache } from "utils/slot";
import { PartnerRef } from "./salon";

export const recurrenceSchema = yup.object().shape({
  id: yup.number().nullable().notRequired(),
  rules: yup.string().required(),
  description: yup.string().ensure().notRequired(),
  occurrences: yup.number().required(),
});
export type Recurrence = yup.InferType<typeof recurrenceSchema>;

export interface SlotInfos {
  hairdresserId?: number;
  hairdresserStringId?: string;
  partnerRef?: PartnerRef;
  range: {
    start: string;
    end: string;
  };
  discount: number;
  recurrence?: Recurrence;
  companyCode?: string;
}
export interface Slot extends SlotInfos {
  id: number;
}

export interface SlotPayload {
  slotById: Record<number, Slot>;
}

export interface SlotState {
  loading: boolean;
  payload: SlotPayload;
  error: string | null;
}

const getInitialState = (): SlotState => ({
  loading: false,
  payload: {
    slotById: getSlotsCache().slotById || {},
  },
  error: null,
});

type updateActions = CreateSlotSuccessAction | UpdateSlotSuccessAction;

const deleteSlotInDateRange = (
  slotById: Record<number, Slot>,
  dateRange: {
    endGte: string;
    startLte: string;
  }
) => {
  const startLte = moment(dateRange.startLte);
  const endGte = moment(dateRange.endGte);
  return Object.values(slotById).reduce((newSlotById, slot) => {
    if (
      moment(slot.range.start).isSameOrBefore(startLte) &&
      moment(slot.range.end).isSameOrAfter(endGte)
    ) {
      return newSlotById;
    }
    return {
      ...newSlotById,
      [slot.id]: slot,
    };
  }, {});
};

const fetchSlotSuccessReducer = (
  state: SlotState,
  action: FetchSlotsSuccessAction
) => {
  const newSlotById = deleteSlotInDateRange(
    state.payload.slotById,
    action.payload.dateRange
  );

  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      slotById: {
        ...newSlotById,
        ...action.payload.slotById,
      },
    },
  };
};

const updateSlotSuccessReducer = (
  state: SlotState,
  action: updateActions
): SlotState => {
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      slotById: {
        ...state.payload.slotById,
        [action.payload.id]: {
          ...(state.payload.slotById &&
            state.payload.slotById[action.payload.id]),
          ...action.payload,
        },
      },
    },
  };
};

const createRecurrentSlotSuccessReducer = (
  state: SlotState,
  action: CreateRecurrentSlotSuccessAction
): SlotState => {
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      slotById: {
        ...state.payload.slotById,
        ...action.payload.reduce(
          (slotById, slot) => ({
            ...slotById,
            [slot.id]: {
              ...(state.payload.slotById && state.payload.slotById[slot.id]),
              ...slot,
            },
          }),
          {}
        ),
      },
    },
  };
};

const deleteSlotSuccessReducer = (
  state: SlotState,
  action: DeleteSlotSuccessAction
): SlotState => {
  const canceledSlotId = action.payload;
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      slotById: {
        ...Object.values(state.payload.slotById)
          .filter((slot) => slot.id !== canceledSlotId)
          .reduce(
            (slotById, slot) => ({
              ...slotById,
              [slot.id]: slot,
            }),
            {}
          ),
      },
    },
  };
};

const slot: Reducer<SlotState, SlotActions | ResetAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case types.FETCH_SLOTS:
    case types.UPDATE_SLOT:
    case types.CREATE_SLOT:
    case types.CREATE_RECURRENT_SLOT:
    case types.DELETE_SLOT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_SLOTS_SUCCESS:
      const newState = fetchSlotSuccessReducer(state, action);
      localStorage.setItem("slot", JSON.stringify(newState.payload));
      return newState;

    case types.UPDATE_SLOT_SUCCESS:
      return updateSlotSuccessReducer(state, action);
    case types.CREATE_SLOT_SUCCESS:
      return updateSlotSuccessReducer(state, action);
    case types.CREATE_RECURRENT_SLOT_SUCCESS:
      return createRecurrentSlotSuccessReducer(state, action);
    case types.DELETE_SLOT_SUCCESS:
      return deleteSlotSuccessReducer(state, action);
    case types.FETCH_SLOTS_FAILURE:
    case types.UPDATE_SLOT_FAILURE:
    case types.CREATE_SLOT_FAILURE:
    case types.CREATE_RECURRENT_SLOT_FAILURE:
    case types.DELETE_SLOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem("slot", "");
      return getInitialState();
    default:
      return state;
  }
};

export default slot;
