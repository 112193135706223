import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import MuiSnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { SnackbarState } from "../reducers/snackbar";
import * as snackbarAction from "../actions/SnackbarActions";
import { AppState } from "../reducers";
import WarningIcon from "../components/svgs/WarningIcon";
import CloseIcon from "../components/svgs/CloseIcon";
import IconButton from "@material-ui/core/IconButton";
import { SnackbarMessage } from "../reducers/snackbar";
import { MESSAGE_TYPES } from "../constants/SnackBar";
import CheckIcon from "../components/svgs/Check";

interface SnackbarProps {
  snackbarState: SnackbarState;
  snackbarActionDispatcher: typeof snackbarAction;
}

const iconByType = {
  [MESSAGE_TYPES.ERROR]: WarningIcon,
  [MESSAGE_TYPES.WARNING]: WarningIcon,
  [MESSAGE_TYPES.INFO]: WarningIcon,
  [MESSAGE_TYPES.SUCCESS]: CheckIcon
};

function Snackbar({ snackbarState, snackbarActionDispatcher }: SnackbarProps) {
  const onClose = (message: SnackbarMessage) => {
    clearTimeout(message.timeoutHandle);
    snackbarActionDispatcher.closeMessage(message.id);
  };

  const message = snackbarState.messages[0];
  const Icon = message && iconByType[message.type];
  const lowerCaseType = message && message.type.toLowerCase();
  return (
    <React.Fragment>
      {message && (
        <MuiSnackbar
          key={message.id}
          open
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <SnackbarContent
            className={`snackbar-container snackbar-container-${lowerCaseType}`}
            message={
              <div
                className={`snackbar-message snackbar-message-${lowerCaseType}`}
              >
                <span
                  className={`snackbar-icon snackbar-icon-${lowerCaseType}`}
                >
                  <Icon />
                </span>
                <span>{message.text}</span>
              </div>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => onClose(message)}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </MuiSnackbar>
      )}
    </React.Fragment>
  );
}

const mapState = (state: AppState) => ({
  snackbarState: state.snackbar
});

const mapDispatch = (dispatch: Dispatch) => ({
  snackbarActionDispatcher: bindActionCreators(snackbarAction, dispatch)
});

export default connect(mapState, mapDispatch)(Snackbar);
