import React from "react";
import "./menu.desktop.scss";
import MenuItem, {
  IDesktopMenuItem,
  ISubLink,
} from "./MenuItem/MenuItem.desktop";
import { LOGOUT, MENU_ITEMS_DESKTOP } from "constants/Menu";
import useCurrentUser from "hooks/useCurrentUser";
import SalonSelector from "components/SalonSelector";
import PhaseTwo from "components/svgs/LogoTransition/PhaseTwo";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";
import IFrameMenuItem from "./MenuItem/MenuItem.iframe";

interface IMenuDesktopProps extends Partial<IMenuProps<IDesktopMenuItem>> {
  noShadow?: boolean;
}

interface GetNavProps {
  items: Array<IDesktopMenuItem>;
  handleLogout(): void;
  noShadow?: boolean;
}

const GetNav = ({ items, handleLogout, noShadow }: GetNavProps) => {
  if (isIFrame()) {
    return (
      <div
        className={classnames("menu-desktop", {
          "no-shadow": noShadow,
          "menu-desktop-iframe": true,
        })}
      >
        <span className="mkpTitle">Market Place Kiute.fr</span>
        <IFrameNav items={items} onLogout={handleLogout} />
      </div>
    );
  }

  return (
    <div
      className={classnames("menu-desktop", {
        "no-shadow": noShadow,
      })}
    >
      <Logo />
      <Nav items={items} onLogout={handleLogout} />
      <div className="menu-desktop-salon-selector">
        <SalonSelector />
      </div>
    </div>
  );
};

const MenuDesktop = ({
  items = MENU_ITEMS_DESKTOP,
  onLogout,
  noShadow,
}: IMenuDesktopProps) => {
  const { logout } = useCurrentUser();
  const handleLogout = onLogout ? onLogout : logout;
  return (
    <GetNav items={items} handleLogout={handleLogout} noShadow={noShadow} />
  );
};

const Logo = () => (
  <div className="menu-desktop-logo">
    <PhaseTwo width={150} />
  </div>
);

const Nav = ({
  items,
  onLogout,
}: {
  items: IDesktopMenuItem[];
  onLogout(): void;
}) => {
  const onAction = (action: string) => {
    action === LOGOUT && onLogout();
  };

  const extraClassName = (sublink: ISubLink) => {
    if ("action" in sublink && sublink.action === LOGOUT) {
      return "logoutLink";
    }
  };

  return (
    <div className={"menu-desktop-nav"}>
      {items.map((item) => (
        <MenuItem
          key={item.title}
          {...item}
          onAction={onAction}
          extraClassName={extraClassName}
        />
      ))}
    </div>
  );
};

const IFrameNav = ({
  items,
  onLogout,
}: {
  items: IDesktopMenuItem[];
  onLogout(): void;
}) => {
  const onAction = (action: string) => {
    action === LOGOUT && onLogout();
  };

  const extraClassName = (sublink: ISubLink) => {
    if ("action" in sublink && sublink.action === LOGOUT) {
      return "logoutLink";
    }
  };

  return (
    <div className={"menu-desktop-nav-iframe"}>
      {items.map((item) => (
        <IFrameMenuItem
          key={item.title}
          {...item}
          onAction={onAction}
          extraClassName={extraClassName}
        />
      ))}
    </div>
  );
};

export default MenuDesktop;
