import React from "react";
import classnames from "classnames";

const MobilePageContent = React.forwardRef<any, any>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames("page-content-mobile", className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default MobilePageContent;
