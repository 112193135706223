export interface Period {
  start: string;
  end: string;
}

export type DayName =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

// CONSTS
export const DAYS = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
} as Record<DayName, string>;

export type Conflict = {
  hairdresserId?: number;
  hairdresserStringId?: string;
  happyHour: number;
  conflict: number;
};
