import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PageContainer from "../../page/Container";
import MobilePageHeader from "../../page/Header.mobile";
import PageContent from "../../page/Content";
import PreviousIcon from "../../svgs/PreviousIcon";
import ModalContentResponsive, {
  ModalContentProps,
} from "../../modal/Content/ResponsiveContent";
import TextInput from "../../inputs/Text";
import FakeInput from "../../inputs/FakeInput";
import PaymentPageDesktopLayout from "../PaymentPageDesktopLayout/PaymentPageDesktopLayout";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import styles from "./PaymentInfosPageTemplate.module.scss";
import { isIFrame } from "utils/helpers";

interface PaymentInfosPageTemplateProps {
  title: string;
  closeLink: string;
  proxyholderData: {
    firstName: string;
    lastName: string;
    nationality: string;
    countryOfResidency: string;
    email: string;
    phone: string;
  };
  companyData: {
    name: string;
    address: string;
    siret: string;
    vatNumber: string;
    iban: string;
    adwordsCampaignId: string;
  };
}

const ModalContent = (props: ModalContentProps) => (
  <ModalContentResponsive
    desktopProps={{ classes: { container: styles.desktopModalContent } }}
    {...props}
  />
);

function PaymentInfosPageTemplate(props: PaymentInfosPageTemplateProps) {
  const { title, closeLink, proxyholderData, companyData } = props;
  const companyFields = [
    {
      label: "Raison sociale",
      value: companyData.name,
    },
    {
      label: "Adresse de facturation",
      value: companyData.address,
    },
    {
      label: "Siret",
      value: companyData.siret,
    },
    {
      label: "TVA",
      value: companyData.vatNumber,
    },
    {
      label: "IBAN",
      value: companyData.iban,
    },
    {
      label: "FORM.CAMPAIGNID",
      value: companyData.adwordsCampaignId,
    },
  ];

  const content = (
    <>
      <ModalContent>
        <h1 className={styles.title}>
          Information sur le représentant légal de l'établissement
        </h1>
        <Grid
          container
          wrap="nowrap"
          className="input-group input-group-inline"
        >
          <TextInput
            fullWidth
            label="Prénom"
            value={proxyholderData.firstName}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
          <TextInput
            fullWidth
            label="Nom"
            value={proxyholderData.lastName}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
        </Grid>
        <Grid
          container
          wrap="nowrap"
          className="input-group input-group-inline"
        >
          <TextInput
            fullWidth
            label="Nationalité"
            value={proxyholderData.nationality}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
          <TextInput
            fullWidth
            label="Pays de résidence"
            value={proxyholderData.countryOfResidency}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
        </Grid>
        <div className="input-group">
          <TextInput
            fullWidth
            label="Email"
            value={proxyholderData.email}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
        </div>
        <div className="input-group">
          <TextInput
            className={styles.phoneInput}
            label="Téléphone"
            value={proxyholderData.phone}
            InputProps={{
              inputComponent: FakeInput,
            }}
          />
        </div>
      </ModalContent>
      <div className={styles.lastGroup}>
        <ModalContent>
          <h1 className={styles.title}>Information sur la société</h1>
          {companyFields.map((companyField) => (
            <div className="input-group" key={companyField.label}>
              <TextInput
                fullWidth
                label={companyField.label}
                value={companyField.value}
                InputProps={{
                  inputComponent: FakeInput,
                }}
              />
            </div>
          ))}
          <div className={styles.contact}>
            <a href="mailto:partenaire@kiute.com">
              Contacter le Service Partenaire
            </a>
            <span>pour toute modification</span>
          </div>
        </ModalContent>
      </div>
    </>
  );
  return (
    <>
      <Mobile>
        <PageContainer>
          <MobilePageHeader
            left={
              isIFrame() ? undefined : (
                <Link to={closeLink}>
                  <PreviousIcon />
                </Link>
              )
            }
            center={<span>{title}</span>}
            parent="payment"
          />
          <PageContent>{content}</PageContent>
        </PageContainer>
      </Mobile>
      <Desktop>
        <PaymentPageDesktopLayout>{content}</PaymentPageDesktopLayout>
      </Desktop>
    </>
  );
}

export default PaymentInfosPageTemplate;
