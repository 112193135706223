import React from "react";
import { Redirect } from "react-router-dom";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import MobilePageList from "components/MobilePageList";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import { PAYMENT_PAGES } from "constants/Payment";

function PaymentPage() {
  return (
    <>
      <Mobile>
        <MobilePageList title="Paiements" listItems={PAYMENT_PAGES} />
      </Mobile>
      <Desktop>
        <Redirect
          to={{
            pathname: parseRoute(routeList.PAYMENT_LIST_PAGE)
          }}
        />
      </Desktop>
    </>
  );
}

export default PaymentPage;
