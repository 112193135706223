import React from "react";
import styles from "./DataList.module.scss";
import classnames from "classnames";

export type ListItem = {
  id: number | string;
  label: React.ReactNode;
  value: React.ReactNode;
};

export interface IDataListProps {
  list: Array<ListItem>;
  classes?: {
    listContainer?: string;
    itemContainer?: string;
    label?: string;
    value?: string;
  };
}

const getClassNamesFor = (
  styles: Array<Record<string, string | undefined> | undefined>
) => (key: string): string =>
  classnames(styles.map(style => style && style[key]));

export function DataList({ list, classes }: IDataListProps) {
  const classNamesFor = getClassNamesFor([styles, classes]);
  return (
    <div className={classNamesFor("listContainer")}>
      {list.map(item => (
        <div key={item.id} className={classNamesFor("itemContainer")}>
          <div className={classNamesFor("label")}>{item.label}</div>
          <div className={classNamesFor("value")}>{item.value}</div>
        </div>
      ))}
    </div>
  );
}
