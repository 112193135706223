import React from "react";

interface ColorBrushIconProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const ColorBrushIcon = ({ color, ...props }: ColorBrushIconProps) => {
  return (
    <svg width={38} height={38} {...props}>
      <defs>
        <circle id="prefix__a" cx={16} cy={16} r={16} />
      </defs>
      <g transform="translate(3 3)" fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use
          stroke={color}
          strokeWidth={5}
          fill={color}
          fillRule="nonzero"
          xlinkHref="#prefix__a"
        />
        <g mask="url(#prefix__b)" fill="#FFF" fillRule="nonzero">
          <path d="M40.332-6.248c-1.34-1.34-2.64-.464-4 .876L15.304 13.936c1.272.268 2.692 1.572 3.8 2.8.536.602.84 1.374.86 2.18l.072-.028 19.42-21.152c1.34-1.344 2.216-2.64.876-3.984zM13.74 15.044c-2.608-.156-3.172 2.528-3.656 4.444s-4.36 3.66-5.084 3.66c0 0 2.888 1.96 7.396 1.752a8.844 8.844 0 005.116-2.12 3.384 3.384 0 00.368-4.868c-1.204-1.356-2.784-2.784-4.14-2.868z" />
        </g>
      </g>
    </svg>
  );
};

export default ColorBrushIcon;
