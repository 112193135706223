import React, { memo } from "react";
import { Day, Week } from "reducers/week";
import { Hairdresser } from "reducers/salon";
import styles from "./styles.module.scss";
import Button from "../../button/Desktop/Button.desktop";
import { getSlotsFromDay, matchHairdresserWeek } from "utils/week";
import { DayName, DAYS } from "constants/Week";

const WeekDayComponent = ({ name, day }: { name: string; day: Day | null }) => {
  if (!day) {
    return (
      <div className={styles.hairdresserDayClosed}>
        <b>{name}</b>
      </div>
    );
  }
  const slots = getSlotsFromDay(day);
  return (
    <div className={styles.hairdresserDay}>
      <b>{name}</b>
      <div>
        {slots.map((slot, key) => (
          <div className={styles.slotHours}>
            {key % 2 !== 0 ? <div className={styles.separator}>/</div> : ""}
            <div className={styles.slotHour}>
              {slot.start} - {slot.end}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface WeekDayListProps {
  weeksByHairdresserId: Record<number, Week | null>;
  editWeek(week: Week): void;
  hairdressers: Array<Hairdresser>;
}
const WeekDayList = ({
  weeksByHairdresserId,
  editWeek,
  hairdressers,
}: WeekDayListProps) => (
  <div className={styles.hairdresserWeeksContainer}>
    {Object.values(weeksByHairdresserId).map(
      (week: Week | null) =>
        week && (
          <div className={styles.hairdresserWeek}>
            <div
              className={styles.hairdresserName}
              style={{
                color:
                  matchHairdresserWeek(week, hairdressers)?.color || "darkgrey",
              }}
            >
              {matchHairdresserWeek(week, hairdressers)?.firstName || ""}{" "}
              {matchHairdresserWeek(week, hairdressers)?.lastName || ""}
            </div>
            <div>
              {Object.keys(week.days).map((dayName: string) => {
                const textDayName = DAYS[dayName as DayName];
                return (
                  <WeekDayComponent
                    name={textDayName}
                    day={week.days[dayName as DayName]}
                  />
                );
              })}
            </div>
            <br />
            <Button
              className={styles.flatBtn}
              primary
              onClick={() => editWeek(week)}
              text="Modifier"
            />
          </div>
        )
    )}
  </div>
);

export default memo(WeekDayList);
