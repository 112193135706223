import * as React from "react";
import ModalContainerMobile from "../../modal/Container/Container.mobile_old";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import ModalHeaderMobile from "../../modal/Header/Header.mobile";
import ModalContentMobile from "../../modal/Content/Content.mobile";

export interface IExternalPageLayoutMobileProps {
  title: string;
  onClose(): void;
  children: React.ReactNode;
}

function ExternalPageLayoutMobile({
  title,
  onClose,
  children
}: IExternalPageLayoutMobileProps) {
  return (
    <ModalContainerMobile open={true} direction="left">
      <ModalLayoutMobile
        header={
          <ModalHeaderMobile
            closeIconVariant="previous"
            onClose={onClose}
            title={title}
          />
        }
        body={<ModalContentMobile extended>{children}</ModalContentMobile>}
      />
    </ModalContainerMobile>
  );
}

export default ExternalPageLayoutMobile;
