import { Reducer } from "redux";
import * as yup from "yup";

import {
  BookingActions,
  UpdateBookingSuccessAction,
  CancelBookingSuccessAction,
  FetchBookingsSuccessAction,
} from "actions/BookingActions";
import { ResetAction } from "actions/ResetAction";
import * as types from "constants/ActionTypes";
import { getBookingsCache } from "utils/booking";

export const userSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  gender: yup.string().nullable().notRequired(),
  phone: yup.string().ensure().notRequired(),
  mobile: yup.string().ensure().notRequired(),
});
export type User = yup.InferType<typeof userSchema>;

// export interface SnapshotPackage {
//   id: number;
//   name: string;
//   description: string;
//   status: "ENABLED" | "DISABLED";
//   hairdresser: {
//     id: number;
//   };
//   category: {
//     id: number;
//     name: string;
//   };
//   price: {
//     price: number;
//     currency: "EUR";
//     variant: {
//       id: number;
//       name: string;
//     };
//   };
// }

const SNAPSHOT_PACKAGE_STATUSES = {
  ENABLED: "ENABLED" as const,
  DISABLED: "DISABLED" as const,
};

const PRICE_CURRENCIES = {
  EUR: "EUR" as const,
};

const snapshotPackageSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  status: yup
    .mixed<keyof typeof SNAPSHOT_PACKAGE_STATUSES>()
    .required()
    .oneOf(Object.values(SNAPSHOT_PACKAGE_STATUSES)),
  hairdresser: yup
    .object()
    .shape({
      id: yup.number().required(),
      stringId: yup.string().required(),
    })
    .required(),
  category: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
  price: yup
    .object()
    .shape({
      price: yup.number().required(),
      currency: yup
        .mixed<keyof typeof PRICE_CURRENCIES>()
        .required()
        .oneOf(Object.values(PRICE_CURRENCIES)),
      variant: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
      packageNumber: yup.string().required(),
    })
    .required(),
});
export type SnapshotPackage = yup.InferType<typeof snapshotPackageSchema>;

// export interface Booking {
//   id: number;
//   status: "PAID" | "CONFIRMED" | "CANCELED" | "PENDING" | "ABANDON";
//   user?: User;
//   cancellationReason: string;
//   cancellationOrigin: string;
//   dateStart: string;
//   dateEnd: string;
//   snapshotPackages: Array<SnapshotPackage>;
// }

const snapshotFeesSchema = yup.object().shape({
  commission: yup.number().required(),
  taxRate: yup.number().required(),
  percentFees: yup.number().required(),
  statedFees: yup.number().required(),
});

const PAYMENT_TYPES = {
  ONLINE: "ONLINE" as const,
  IN_SALON: "IN_SALON" as const,
};

const BOOKING_STATUSES = {
  PAID: "PAID" as const,
  CONFIRMED: "CONFIRMED" as const,
  CANCELED: "CANCELED" as const,
  PENDING: "PENDING" as const,
  ABANDON: "ABANDON" as const,
};
export const bookingSchema = yup.object().shape({
  id: yup.number().required(),
  discount: yup.number().required(),
  status: yup
    .mixed<keyof typeof BOOKING_STATUSES>()
    .oneOf(Object.values(BOOKING_STATUSES)),
  user: userSchema.notRequired(),
  paymentType: yup
    .mixed<keyof typeof PAYMENT_TYPES>()
    .oneOf(Object.values(PAYMENT_TYPES)),
  cancellationReason: yup.string().required(),
  cancellationOrigin: yup.string().required(),
  dateStart: yup.string().required(),
  dateEnd: yup.string().required(),
  snapshotFees: snapshotFeesSchema,
  snapshotPackages: yup.array().required().of(snapshotPackageSchema),
  transactionId: yup.string().required(),
  commissionAmount: yup.number().required(),
  commissionConditions: yup.object().shape({
    isNewClient: yup.bool().required(),
  }),
  createdAt: yup.string().required(),
});
export type Booking = yup.InferType<typeof bookingSchema>;

export interface BookingPayload {
  bookingById: { [id: number]: Booking };
}

export interface BookingState {
  loading: boolean;
  payload: BookingPayload;
  error: string | null;
}

const getinitialState = (): BookingState => ({
  loading: false,
  payload: {
    bookingById: getBookingsCache().bookingById || {},
  },
  error: null,
});

const fetchBookingSuccessReducer = (
  state: BookingState,
  action: FetchBookingsSuccessAction
): BookingState => {
  const fetchedBookingById = action.payload;
  const bookingById = Object.values({
    ...state.payload.bookingById,
    ...fetchedBookingById,
  })
    .filter((booking) =>
      ["CONFIRMED", "PAID", "CANCELED"].includes(booking.status)
    )
    .reduce(
      (byId, booking) => ({
        ...byId,
        [booking.id]: booking,
      }),
      {}
    );
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      bookingById,
    },
  };
};

const updateBookingSuccessReducer = (
  state: BookingState,
  action: UpdateBookingSuccessAction | CancelBookingSuccessAction
): BookingState => {
  const booking = action.payload;
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      bookingById: {
        ...state.payload.bookingById,
        [booking.id]: booking,
      },
    },
  };
};

const cancelBookingSuccessReducer = (
  state: BookingState,
  action: UpdateBookingSuccessAction | CancelBookingSuccessAction
): BookingState => {
  const canceledBooking = action.payload;
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      bookingById: {
        ...Object.values(state.payload.bookingById)
          .filter((booking) => booking.id !== canceledBooking.id)
          .reduce(
            (bookingById, booking) => ({
              ...bookingById,
              [booking.id]: booking,
            }),
            {}
          ),
      },
    },
  };
};

const booking: Reducer<BookingState, BookingActions | ResetAction> = (
  state = getinitialState(),
  action
) => {
  switch (action.type) {
    case types.FETCH_BOOKINGS:
    case types.UPDATE_BOOKING:
    case types.CANCEL_BOOKING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_BOOKINGS_SUCCESS:
      const fetchedState = fetchBookingSuccessReducer(state, action);
      localStorage.setItem("booking", JSON.stringify(fetchedState.payload));
      return fetchedState;
    case types.UPDATE_BOOKING_SUCCESS:
      const updatedState = updateBookingSuccessReducer(state, action);
      localStorage.setItem("booking", JSON.stringify(updatedState.payload));
      return updatedState;
    case types.CANCEL_BOOKING_SUCCESS:
      const conceledState = cancelBookingSuccessReducer(state, action);
      localStorage.setItem("booking", JSON.stringify(conceledState.payload));
      return conceledState;
    case types.FETCH_BOOKINGS_FAILURE:
    case types.UPDATE_BOOKING_FAILURE:
    case types.CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem("booking", "");
      return getinitialState();
    default:
      return state;
  }
};

export default booking;
