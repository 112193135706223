import React, { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import MobilePageHeader from "components/page/Header.mobile";
import PackageTitleItem from "components/package/PackageTitleItem/PackageTitleItem";
import PackageFormContainer from "containers/Package/PackageForm";
import PageContainer from "components/page/Container";
import PageContent from "components/page/Content";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import MobileContent from "components/modal/Content/Content.mobile";
import DesktopButton from "components/button/Desktop/Button.desktop";
import { PackagesPricesForm } from "components/package/PackagesPricesForm/PackagesPricesForm";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import Checkbox from "components/inputs/Checkbox";
import ButtonFab from "components/button/Fab";
import PictureListInput, {
  FileWithData,
} from "components/inputs/PictureListInput/PictureListInput";
import { useCurrentSalonPackages } from "hooks/useCurrentSalonPackages";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Package, Hairdresser } from "reducers/salon";
import styles from "./styles.module.scss";
import ModalLayoutMobile from "components/modal/Layout/Layout.mobile";
import ModalHeaderMobile from "components/modal/Header/Header.mobile";
import useCurrentUser from "hooks/useCurrentUser";
import CovidAddPriceModal from "components/package/CovidAddPriceModal/CovidAddPriceModal";
import FlexyMatchingModal from "components/package/FlexyMatchingModal/FlexyMatchingModal";
import { isIFrame } from "utils/helpers";
import IFrameContainer from "components/iframe/IFrameContainer";

function packageToInputsReducer(inputs: Record<string, number>, pack: Package) {
  return {
    ...inputs,
    ...pack.prices.reduce(
      (values, price, index) => ({
        ...values,
        [`${pack.id}.${index}`]: price.price,
      }),
      {}
    ),
  };
}

type FormReducerState = {
  isformModalOpened: boolean;
  packageFormType?: "create" | "update";
  packToUpdate?: Package;
};

const formReducer = (
  state: FormReducerState,
  action: {
    type: "editPackage" | "createPackage" | "closeModal";
    payload?: { pack?: Package };
  }
): FormReducerState => {
  switch (action.type) {
    case "editPackage":
      return {
        isformModalOpened: true,
        packageFormType: "update",
        packToUpdate: action.payload?.pack,
      };
    case "createPackage":
      return { isformModalOpened: true, packageFormType: "create" };
    case "closeModal":
      return { isformModalOpened: false };
    default:
      throw new Error();
  }
};

function PackagesPage() {
  const {
    packagesGroupedByCategory,
    packages,
    createPackagesApprovalRequest,
    waitingApprovalRequests,
    refetchWaitingApprovalRequests,
    updatePackageStatus,
    updatePackageAllowPromo,
    updateAllPackagesPrices,
  } = useCurrentSalonPackages();

  const { salon, hairdressers, refetchSalon } = useCurrentSalon();

  useEffect(() => {
    refetchWaitingApprovalRequests();
  }, [salon.id]);

  const [packFormState, packFormDispatch] = useReducer(formReducer, {
    isformModalOpened: false,
  });
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);
  const [isCovidModalOpened, setIsCovidModalOpened] = useState(false);
  const [showCovidValidation, setShowCovidValidation] = useState(false);
  const [isFlexyMatchingModalOpened, setIsFlexyMatchingModalOpened] = useState(
    false
  );
  const [showDisabled, setShowDisabled] = useState(false);
  const [showAllowPromo, setShowAllowPromo] = useState(true);
  const [showNotAllowPromo, setShowNotAllowPromo] = useState(true);
  const [packagePricesInputs, setPackagePricesInputs] = useState(
    packages.reduce<Record<string, number>>(packageToInputsReducer, {})
  );
  const onChangePackagePrice = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setPackagePricesInputs((inputs) => ({
      ...inputs,
      [name]: Number(value),
    }));
  };

  useEffect(() => {
    refetchSalon();
  }, []);

  useEffect(() => {
    setPackagePricesInputs(
      packages.reduce<Record<string, number>>(packageToInputsReducer, {})
    );
  }, [salon.id]);

  const [files, setFiles] = useState<FileWithData[]>([]);
  const [filesError, setFilesError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const submitNewPrices = () => {
    if (files.length === 0) {
      setFilesError(
        "Veuillez ajouter la grille des tarifs pour pouvoir les modifier."
      );
      return;
    }
    setFilesError(null);
    setIsSubmitting(true);
    createPackagesApprovalRequest({
      files: files.map((file) => ({
        name: file.file.name,
        data: file.data.split(",")[1],
      })),
      packages: packages
        .filter((pack) => {
          // We should only send Prices which have been updated
          return pack.prices.some((price, index) => {
            return (
              price.price !==
              parseInt(packagePricesInputs[`${pack.id}.${index}`].toString())
            );
          });
        })
        .map((pack) => ({
          id: pack.id,
          prices: pack.prices.map((price, index) => ({
            ...price,
            price: parseInt(
              packagePricesInputs[`${pack.id}.${index}`].toString()
            ),
          })),
        })),
    })
      .then(() => {
        dispatch(
          pushMessage(
            "La demande de mise à jour des tarifs est bien prise en compte.",
            MESSAGE_TYPES.SUCCESS
          )
        );
      })
      .catch(() => {
        dispatch(
          pushMessage(
            "Il y a eu une erreur lors de la demande de mise à jour.",
            MESSAGE_TYPES.ERROR
          )
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUpdateModalOpened(false);
      });
  };

  const updatePackageStatusHandler = (
    packageId: number,
    status: string
  ): void => {
    updatePackageStatus(packageId, status)
      .then(() => {
        dispatch(pushMessage("Statut mis à jour", MESSAGE_TYPES.SUCCESS));
      })
      .catch(() => {
        dispatch(
          pushMessage(
            "Il y a eu une erreur lors de la demande de mise à jour du statut.",
            MESSAGE_TYPES.ERROR
          )
        );
      });
  };

  const updatePackageAllowPromoHandler = (
    packageId: number,
    allowPromo: boolean
  ): void => {
    updatePackageAllowPromo(packageId, allowPromo)
      .then(() => {
        dispatch(pushMessage("Mis à jour", MESSAGE_TYPES.SUCCESS));
      })
      .catch(() => {
        dispatch(
          pushMessage(
            "Il y a eu une erreur lors de la demande de mise à jour.",
            MESSAGE_TYPES.ERROR
          )
        );
      });
  };

  const { isAdmin, getAuthInfos } = useCurrentUser();

  const content = packagesGroupedByCategory.map(({ category, packages }) => {
    const list = (
      <PackagesList
        packages={packages}
        hairdressers={hairdressers}
        onClick={(pack) =>
          packFormDispatch({ type: "editPackage", payload: { pack } })
        }
        updatePackageStatusHandler={updatePackageStatusHandler}
        updatePackageAllowPromoHandler={updatePackageAllowPromoHandler}
        showDisabled={showDisabled}
        showAllowPromo={showAllowPromo}
        showNotAllowPromo={showNotAllowPromo}
        isAdmin={isAdmin}
      />
    );
    return (
      <React.Fragment key={`category_${category.id}`}>
        <span className={styles.categoryName}>{category.name}</span>
        <Mobile>
          <MobileContent noPadding>{list}</MobileContent>
        </Mobile>
        <Desktop>{list}</Desktop>
      </React.Fragment>
    );
  });

  const isWaitingApproval = waitingApprovalRequests.length > 0;

  const priceForm = (
    <div className={styles.packagePricesFormContainer}>
      <div className={styles.updateFormOuterContainer}>
        <PackagesPricesForm
          classes={{ container: styles.updateFormContainer }}
          packages={packages}
          inputs={packagePricesInputs}
          onInputChange={onChangePackagePrice}
        />
      </div>
      <div className={styles.picturesListLabel}>
        Veuillez joindre la grille des tarifs de l'établissement :
      </div>
      <PictureListInput
        onChange={setFiles}
        classes={{ container: styles.picturesListContainer }}
      />
      {filesError && (
        <FormHelperText className={styles.error} error>
          {filesError}
        </FormHelperText>
      )}
      <ButtonFab onClick={submitNewPrices}>
        {isSubmitting ? <CircularProgress /> : "Valider"}
      </ButtonFab>
    </div>
  );

  const checkboxes = (
    <div className={styles.disableDiv}>
      <label>
        <Checkbox
          className={styles.disabledButton}
          checked={showDisabled}
          onClick={() => setShowDisabled(!showDisabled)}
        />
        <div className={styles.rightHand}>
          Afficher les prestations désactivées
        </div>
      </label>
      <br />
      <label>
        <Checkbox
          className={styles.disabledButton}
          checked={showAllowPromo}
          onClick={() => setShowAllowPromo(!showAllowPromo)}
        />
        <div className={styles.rightHand}>
          Voir la carte des prestations avec promotions autorisées
        </div>
      </label>
      <br />
      <label>
        <Checkbox
          className={styles.disabledButton}
          checked={showNotAllowPromo}
          onClick={() => setShowNotAllowPromo(!showNotAllowPromo)}
        />
        <div className={styles.rightHand}>
          Voir la carte des prestations avec promotions non applicables
        </div>
      </label>
    </div>
  );

  return (
    <>
      <Mobile>
        <PageContainer>
          <MobilePageHeader
            center="Prestations"
            right={
              <div
                className={classnames(styles.updateButton, {
                  [styles.disabled]: isWaitingApproval,
                })}
                onClick={() =>
                  !isWaitingApproval && setIsUpdateModalOpened(true)
                }
              >
                {isWaitingApproval
                  ? "Demande de mise à jour enregistrée"
                  : "Mettre à jour les tarifs"}
              </div>
            }
          />
          <PageContent>
            {checkboxes}
            {salon.isOneCatalog && (
              <Paper>
                <Box textAlign="center" m={2}>
                  La modification des prestations est disponible sur{" "}
                  <a href="https://app.kiute.com/#/app/service" target="_blank">
                    app.kiute.com
                  </a>
                </Box>
              </Paper>
            )}
            {content}
          </PageContent>
        </PageContainer>
      </Mobile>
      <Desktop>
        <>
          <IFrameContainer>
            <MenuDesktop />
            <div className={styles.contentContainer}>
              <Paper
                className={classnames(styles.adminZone, {
                  [styles.adminZoneIframe]: isIFrame(),
                })}
              >
                {checkboxes}
                {isAdmin && (
                  <div>
                    <br />
                    <Box textAlign="center" mb={2}>
                      Admins Uniquement
                    </Box>
                    <DesktopButton
                      text="Créer une prestation"
                      onClick={() =>
                        packFormDispatch({ type: "createPackage" })
                      }
                    />
                    <br />
                    <DesktopButton
                      text="Supplément Covid"
                      onClick={() => setIsCovidModalOpened(true)}
                    />
                    <br />
                    <DesktopButton
                      text="Mapping Flexy"
                      onClick={() => setIsFlexyMatchingModalOpened(true)}
                    />
                  </div>
                )}
              </Paper>
              {isAdmin && (
                <CovidAddPriceModal
                  isCovidModalOpened={isCovidModalOpened}
                  setIsCovidModalOpened={setIsCovidModalOpened}
                  showCovidValidation={showCovidValidation}
                  setShowCovidValidation={setShowCovidValidation}
                  getAuthInfos={getAuthInfos}
                  updateAllPackagesPrices={updateAllPackagesPrices}
                  packages={packages}
                  salonId={salon.id}
                />
              )}
              {isAdmin && (
                <FlexyMatchingModal
                  isFlexyMatchingModalOpened={isFlexyMatchingModalOpened}
                  setIsFlexyMatchingModalOpened={setIsFlexyMatchingModalOpened}
                  packages={packages}
                  salon={salon}
                  refetchSalon={refetchSalon}
                />
              )}
              {isWaitingApproval ? (
                <DesktopButton
                  className={classnames(styles.updateButton, {
                    [styles.updateButtonIframe]: isIFrame(),
                  })}
                  disabled
                  text="Demande de mise à jour enregistrée"
                />
              ) : (
                <DesktopButton
                  className={classnames(styles.updateButton, {
                    [styles.updateButtonIframe]: isIFrame(),
                  })}
                  text="Mettre à jour les tarifs"
                  onClick={() => setIsUpdateModalOpened(true)}
                />
              )}
              {salon.isOneCatalog && (
                <Paper style={{ marginTop: "20px" }}>
                  <Box textAlign="center" m={2}>
                    La modification des prestations est disponible sur{" "}
                    <a href="https://app.kiute.com/#/app/service" target="_blank">
                      app.kiute.com
                    </a>
                  </Box>
                </Paper>
              )}
              {content}
            </div>
          </IFrameContainer>
        </>
      </Desktop>
      <ResponsiveModalContainer
        fullScreenOnMobile
        open={packFormState.isformModalOpened}
        direction="left"
      >
        <PackageFormContainer
          type={packFormState.packageFormType}
          pack={packFormState.packToUpdate}
          onClose={() => packFormDispatch({ type: "closeModal" })}
        />
      </ResponsiveModalContainer>
      <ResponsiveModalContainer
        fullScreenOnMobile
        open={isUpdateModalOpened}
        direction="up"
      >
        <>
          <Desktop>
            <ModalLayoutDesktop
              header="Mise à jour des tarifs"
              onClose={() => setIsUpdateModalOpened(false)}
              body={priceForm}
            />
          </Desktop>
          <Mobile>
            <ModalLayoutMobile
              header={
                <ModalHeaderMobile
                  onClose={() => setIsUpdateModalOpened(false)}
                  title="Mise à jour des tarifs"
                />
              }
              body={priceForm}
            />
          </Mobile>
        </>
      </ResponsiveModalContainer>
    </>
  );
}

interface PackagesListProps {
  packages: Array<Package>;
  onClick(pack: Package): void;
  updatePackageStatusHandler(packageId: number, status: string): void;
  updatePackageAllowPromoHandler(packageId: number, allowPromo: boolean): void;
  hairdressers: Array<Hairdresser>;
  showDisabled: boolean;
  showAllowPromo: boolean;
  showNotAllowPromo: boolean;
  isAdmin?: boolean;
}

function PackagesList({
  packages,
  onClick,
  updatePackageStatusHandler,
  updatePackageAllowPromoHandler,
  hairdressers,
  showDisabled,
  showAllowPromo,
  showNotAllowPromo,
  isAdmin,
}: PackagesListProps) {
  const { salon } = useCurrentSalon();
  return (
    <List classes={{ root: styles.categoryListContainer }}>
      {packages.map((pack, index, packs) =>
        (showDisabled || pack.status === "ENABLED") &&
        ((pack.allowPromo && showAllowPromo) ||
          (!pack.allowPromo && showNotAllowPromo)) ? (
          <ListItem key={`package_${pack.id}`}>
            <PackageTitleItem
              pack={pack}
              onItemClick={() => onClick(pack)}
              withUnderline={index !== packs.length - 1}
              updatePackageStatusHandler={updatePackageStatusHandler}
              displayEnableCheckbox={true}
              updatePackageAllowPromoHandler={updatePackageAllowPromoHandler}
              hairdressersCount={hairdressers.length}
              isAdmin={isAdmin}
              isOneCatalog={salon.isOneCatalog}
            />
          </ListItem>
        ) : (
          ""
        )
      )}
    </List>
  );
}

export default PackagesPage;
