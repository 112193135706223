import React from "react";
import classnames from "classnames";

import { BLUE_GREY } from "constants/Style";
import { STATUSES } from "constants/Account";
import HairdresserAvatar from "components/uiElements/HairdresserAvatar/HairdresserAvatar";
import NextIcon from "components/svgs/NextIcon";
import WarningIcon from "components/svgs/WarningIcon";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Hairdresser, Package } from "reducers/salon";
import { pluralize } from "utils/string";
import styles from "./HairdresserMenuItem.module.scss";
import { getAssignedPackageList } from "utils/package";
import { isIFrame } from "utils/helpers";

interface HairdresserMenuItemProps {
  hairdresser: Hairdresser;
  totalPackages: number;
  onClick(): void;
  isSelected: boolean;
  nbPackageAssigned: number;
  inSelect?: boolean;
}

function HairdresserMenuItem(
  props: Omit<HairdresserMenuItemProps, "totalPackages" | "nbPackageAssigned">
) {
  const { packages } = useCurrentSalon();

  const nbPackageAssigned =
    props.hairdresser.serviceIds && props.hairdresser.serviceIds.length > 0
      ? getAssignedPackageList(props.hairdresser, packages).length
      : props.hairdresser.packageIds.length;

  return (
    <HairdresserMenuItemTemplate
      totalPackages={packages.length}
      nbPackageAssigned={nbPackageAssigned}
      {...props}
    />
  );
}

export function HairdresserMenuItemTemplate({
  hairdresser,
  totalPackages,
  onClick,
  isSelected,
  nbPackageAssigned,
  inSelect,
}: HairdresserMenuItemProps) {
  const { isDesktop, isMobile } = useResponsiveLayout();

  return (
    <div
      onClick={onClick}
      className={classnames(styles.container, {
        [styles.disabled]: hairdresser.status === STATUSES.DISABLED,
        [styles.selected]: isSelected,
        [styles.inSelect]: inSelect && isMobile,
      })}
    >
      <HairdresserAvatar
        hairdresser={hairdresser}
        height={isDesktop ? 65 : undefined}
      />
      <div className={styles.content}>
        <span className={styles.name}>
          {hairdresser.firstName} {hairdresser.lastName}
          {hairdresser.status === STATUSES.DISABLED && (
            <span className={styles.disabledText}> Désactivé</span>
          )}
        </span>
        <span
          className={classnames(styles.packages, {
            [styles.warning]: nbPackageAssigned === 0,
          })}
        >
          {nbPackageAssigned === 0 && (
            <span className={styles.icon}>
              <WarningIcon />
            </span>
          )}
          {`${pluralize(
            `${nbPackageAssigned} prestation assignée`,
            `${nbPackageAssigned} prestations assignées`,
            nbPackageAssigned
          )} (sur ${totalPackages})`}
        </span>
      </div>
      {(isMobile || (isDesktop && isSelected)) && !inSelect && (
        <NextIcon
          color={BLUE_GREY}
          style={
            (isIFrame() &&
              isMobile && { transform: "rotate(90deg)", marginLeft: "10px" }) ||
            {}
          }
        />
      )}
    </div>
  );
}

export default HairdresserMenuItem;
