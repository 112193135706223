import React, { memo } from "react";
import { Week } from "reducers/week";
import { LastMinute } from "reducers/lastMinute";
import styles from "./PromoSelector.module.scss";

interface PromoSelectorProps {
  index: number;
  object: Week | LastMinute;
  saveObject(index: number, object: Week | LastMinute): void;
}
const discounts = [10, 20, 30, 40, 50];

const styleConditions = [
  (sameDiscount: boolean, index: number) => {
    if (index === 0) {
      return sameDiscount ? styles.firstDiscountSelected : styles.firstDiscount;
    }
  },
  (sameDiscount: boolean, index: number) => {
    if (index === discounts.length - 1) {
      return sameDiscount ? styles.lastDiscountSelected : styles.lastDiscount;
    }
  },
  (sameDiscount: boolean): string =>
    sameDiscount ? styles.middleDiscountSelected : styles.middleDiscount,
];

const getStyleForDiscount = (
  object: Week | LastMinute,
  discount: number,
  index: number
): string =>
  styleConditions
    .map((condition) => condition(object.discount === discount, index))
    .find((result) => result !== undefined) as string;

const PromoSelector = ({ object, index, saveObject }: PromoSelectorProps) => (
  <div>
    Appliquer une promotion de :
    <div className={styles.promoSelect}>
      {discounts.map((discount, promoIdx) => (
        <div
          key={discount}
          className={getStyleForDiscount(object, discount, promoIdx)}
          onClick={() => saveObject(index, { ...object, discount })}
        >
          {discount}%
        </div>
      ))}
    </div>
  </div>
);

export default memo(PromoSelector);
