import { SalonInfos } from "../components/signupPage/SignupPage";
import { mutation, safeError } from "../utils/graphqlClient";

export const sendContactPartner = async (salonInfos: SalonInfos) => {
  const res = await mutation(
    "sendContactPartner",
    {
      firstName: {
        type: "String",
        value: salonInfos.firstName
      },
      lastName: {
        type: "String",
        value: salonInfos.lastName
      },
      email: {
        type: "String",
        value: salonInfos.email
      },
      phone: {
        type: "String",
        value: salonInfos.phone
      },
      name: {
        type: "String",
        value: salonInfos.name
      },
      postalCode: {
        type: "String",
        value: salonInfos.postalCode
      }
    },
    "from"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.from)
    : Promise.reject(safeError(body));
};
