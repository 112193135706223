// import elasticsearch from "elasticsearch-browser";

// const esClient = new elasticsearch.Client({
//   host: process.env.REACT_APP_ES_URL
// });

// export default esClient;

const getEsClient = async () => {
  const elasticsearch = await import("elasticsearch-browser");
  const esClient = new elasticsearch.Client({
    host: process.env.REACT_APP_ES_URL
  });
  return esClient;
};

export default getEsClient;
