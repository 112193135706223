import React from "react";
import { Booking } from "../../reducers/booking";
import Variant from "../uiElements/Variant/Variant";
import { getBookingDiscountedPrice } from "utils/booking";
import { priceFormatter } from "utils/payments";

interface BookingSnapshotPackageProps {
  booking: Booking;
}

export default function BookingSnapshotPackage(
  props: BookingSnapshotPackageProps
) {
  const { booking } = props;
  return (
    <div className="booked-slot-form_package input-group">
      <label className="booked-slot-form_package-label input_label">
        Préstation
      </label>
      <div>
        <div className="booked-slot-form_package-name">
          {booking.snapshotPackages[0].name}
        </div>
        <div className="booked-slot-form_package-price">
          <span className="booked-slot-form_package-price_full">
            {priceFormatter.format(
              booking.snapshotPackages[0].price.price ?? 0
            )}
            €
          </span>
          <span className="booked-slot-form_package-price_reduced">
            {priceFormatter.format(getBookingDiscountedPrice(booking))}€
          </span>
        </div>
      </div>
      {booking.snapshotPackages[0].price.variant.id !== 0 && (
        <Variant
          variant={booking.snapshotPackages[0].price.variant}
          className="booked-slot-form_package-price-variant"
        />
      )}
      <div className="booked-slot-form_package-description">
        {booking.snapshotPackages[0].description}
      </div>
      <div className="booking-modal-ref">
        Ref: {booking.snapshotPackages[0].price.packageNumber}
      </div>
    </div>
  );
}
