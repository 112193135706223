// SALON
export const FETCH_SALONS = "FETCH_SALONS";
export const FETCH_SALONS_SUCCESS = "FETCH_SALONS_SUCCESS";
export const FETCH_SALONS_FAILURE = "FETCH_SALONS_FAILURE";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAILURE = "UPDATE_PACKAGE_FAILURE";
export const UPDATE_SALON = "UPDATE_SALON";
export const UPDATE_SALON_SUCCESS = "UPDATE_SALON_SUCCESS";
export const UPDATE_SALON_FAILURE = "UPDATE_SALON_FAILURE";
export const CREATE_HAIRDRESSER = "CREATE_HAIRDRESSER";
export const CREATE_HAIRDRESSER_SUCCESS = "CREATE_HAIRDRESSER_SUCCESS";
export const CREATE_HAIRDRESSER_FAILURE = "CREATE_HAIRDRESSER_FAILURE";
export const UPDATE_HAIRDRESSER = "UPDATE_HAIRDRESSER";
export const UPDATE_HAIRDRESSER_SUCCESS = "UPDATE_HAIRDRESSER_SUCCESS";
export const UPDATE_HAIRDRESSER_FAILURE = "UPDATE_HAIRDRESSER_FAILURE";
export const UPDATE_BROADCAST_LIST = "UPDATE_BROADCAST_LIST";
export const UPDATE_BROADCAST_LIST_SUCCESS = "UPDATE_BROADCAST_LIST_SUCCESS";
export const UPDATE_BROADCAST_LIST_FAILURE = "UPDATE_BROADCAST_LIST_FAILURE";
export const SET_PUBLIC_SALON = "SET_PUBLIC_SALON";
export const SET_PUBLIC_SALON_SUCCESS = "SET_PUBLIC_SALON_SUCCESS";
export const SET_PUBLIC_SALON_FAILURE = "SET_PUBLIC_SALON_FAILURE";
export const FETCH_APPROVAL_REQUESTS = "FETCH_APPROVAL_REQUESTS";
export const FETCH_APPROVAL_REQUESTS_SUCCESS =
  "FETCH_APPROVAL_REQUESTS_SUCCESS";
export const FETCH_APPROVAL_REQUESTS_FAILURE =
  "FETCH_APPROVAL_REQUESTS_FAILURE";

export const FETCH_SALON_STRIPE_CAPABILITIES =
  "FETCH_SALON_STRIPE_CAPABILITIES";
export const FETCH_SALON_STRIPE_CAPABILITIES_SUCCESS =
  "FETCH_SALON_STRIPE_CAPABILITIES_SUCCESS";
export const FETCH_SALON_STRIPE_CAPABILITIES_FAILURE =
  "FETCH_SALON_STRIPE_CAPABILITIES_FAILURE";

export const FETCH_SALON_STRIPE_FORM = "FETCH_SALON_STRIPE_FORM";
export const FETCH_SALON_STRIPE_FORM_SUCCESS =
  "FETCH_SALON_STRIPE_FORM_SUCCESS";
export const FETCH_SALON_STRIPE_FORM_FAILURE =
  "FETCH_SALON_STRIPE_FORM_FAILURE";

// SLOT
export const FETCH_SLOTS = "FETCH_SLOTS";
export const FETCH_SLOTS_SUCCESS = "FETCH_SLOTS_SUCCESS";
export const FETCH_SLOTS_FAILURE = "FETCH_SLOTS_FAILURE";
export const UPDATE_SLOT = "UPDATE_SLOT";
export const UPDATE_SLOT_SUCCESS = "UPDATE_SLOT_SUCCESS";
export const UPDATE_SLOT_FAILURE = "UPDATE_SLOT_FAILURE";
export const CREATE_SLOT = "CREATE_SLOT";
export const CREATE_SLOT_SUCCESS = "CREATE_SLOT_SUCCESS";
export const CREATE_SLOT_FAILURE = "CREATE_SLOT_FAILURE";
export const CREATE_RECURRENT_SLOT = "CREATE_RECURRENT_SLOT";
export const CREATE_RECURRENT_SLOT_SUCCESS = "CREATE_RECURRENT_SLOT_SUCCESS";
export const CREATE_RECURRENT_SLOT_FAILURE = "CREATE_RECURRENT_SLOT_FAILURE";
export const DELETE_SLOT = "DELETE_SLOT";
export const DELETE_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS";
export const DELETE_SLOT_FAILURE = "DELETE_SLOT_FAILURE";
export const DELETE_RECURRENT_SLOT = "DELETE_SLOT";
export const DELETE_RECURRENT_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS";
export const DELETE_RECURRENT_SLOT_FAILURE = "DELETE_SLOT_FAILURE";

// UNAVAILABILITY
export const FETCH_UNAVAILABILITIES = "FETCH_UNAVAILABILITIES";
export const FETCH_UNAVAILABILITIES_SUCCESS = "FETCH_UNAVAILABILITIES_SUCCESS";
export const FETCH_UNAVAILABILITIES_FAILURE = "FETCH_UNAVAILABILITIES_FAILURE";

// BOOKING
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILURE = "FETCH_BOOKINGS_FAILURE";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILURE = "UPDATE_BOOKING_FAILURE";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE";

// PAYMENTS
export const FETCH_PAID_BOOKINGS = "FETCH_PAID_BOOKINGS";
export const FETCH_PAID_BOOKINGS_SUCCESS = "FETCH_PAID_BOOKINGS_SUCCESS";
export const FETCH_PAID_BOOKINGS_FAILURE = "FETCH_PAID_BOOKINGS_FAILURE";
export const FETCH_PAYOUTS = "FETCH_PAYOUTS";
export const FETCH_PAYOUTS_SUCCESS = "FETCH_PAYOUTS_SUCCESS";
export const FETCH_PAYOUTS_FAILURE = "FETCH_PAYOUTS_FAILURE";

// AUTH
export const CLASSIC_LOGIN = "CLASSIC_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// STORE VERSIONNING
export const RESET = "RESET";

// SNACKBAR
export const PUSH_MESSAGE = "PUSH_MESSAGE";
export const CLOSE_MESSAGE = "CLOSE_MESSAGE";

// RATINGS
export const FETCH_SALON_RATINGS = "FETCH_SALON_RATINGS";
export const FETCH_HAIRDRESSER_RATINGS = "FETCH_HAIRDRESSER_RATINGS";
export const FETCH_RATINGS_SUCCESS = "FETCH_RATINGS_SUCCESS";
export const FETCH_RATINGS_FAILURE = "FETCH_RATINGS_FAILURE";
export const FETCH_SALON_RATINGS_STATS = "FETCH_SALON_RATINGS_STATS";
export const FETCH_SALON_RATINGS_STATS_SUCCESS =
  "FETCH_SALON_RATINGS_STATS_SUCCESS";
export const FETCH_SALON_RATINGS_STATS_FAILURE =
  "FETCH_SALON_RATINGS_STATS_FAILURE";
export const FETCH_HAIRDRESSER_RATINGS_STATS =
  "FETCH_HAIRDRESSER_RATINGS_STATS";
export const FETCH_HAIRDRESSER_RATINGS_STATS_SUCCESS =
  "FETCH_HAIRDRESSER_RATINGS_STATS_SUCCESS";
export const FETCH_HAIRDRESSER_RATINGS_STATS_FAILURE =
  "FETCH_HAIRDRESSER_RATINGS_STATS_FAILURE";
export const REPLY_TO_RATING = "REPLY_TO_RATING";
export const REPLY_SUCCESS = "REPLY_SUCCESS";
export const REPLY_FAILURE = "REPLY_FAILURE";

// WEEKS
export const FETCH_WEEKS = "FETCH_WEEKS";
export const FETCH_WEEKS_SUCCESS = "FETCH_WEEKS_SUCCESS";
export const FETCH_WEEKS_FAILURE = "FETCH_WEEKS_FAILURE";
// AVAILABILITIES
export const FETCH_AVAILABILITIES = "FETCH_AVAILABILITIES";
export const FETCH_AVAILABILITIES_SUCCESS = "FETCH_AVAILABILITIES_SUCCESS";
export const FETCH_AVAILABILITIES_FAILURE = "FETCH_AVAILABILITIES_FAILURE";
// LAST_MINUTES
export const FETCH_LAST_MINUTES = "FETCH_LAST_MINUTES";
export const FETCH_LAST_MINUTES_SUCCESS = "FETCH_LAST_MINUTES_SUCCESS";
export const FETCH_LAST_MINUTES_FAILURE = "FETCH_LAST_MINUTES_FAILURE";
