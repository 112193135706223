import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";

import {
  FIRST_HOUR,
  LAST_HOUR,
  TIME_STEP_DURATION,
  DEFAULT_HOUR,
} from "constants/Agenda";
import {
  dateInputFormat,
  defaultDisplayFormatWithFullDay,
} from "constants/Input";
import { buildCalendarFormat } from "constants/Date";
import ButtonFab from "components/button/Fab";
import ButtonText from "components/button/Text";
import MobileSelect from "components/inputs/Select/Mobile";
import Select from "components/inputs/Select";
import ResponsiveModalContent from "components/modal/Content/ResponsiveContent";
import TimePickerMobile from "components/inputs/TimePicker/TimePicker.mobile";
import DatePickerMobile from "components/inputs/DatePicker/DatePicker.mobile";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import DatePickerDesktop from "components/inputs/DatePicker/DatePicker.desktop";
import DesktopTimePicker from "components/inputs/TimePicker/TimePicker.desktop";
import { Hairdresser } from "reducers/salon";
import { timeInputToMoment } from "utils/form";
import SlotRepetitionForm from "../SlotRepetitionForm/SlotRepetitionForm";
import { IFreeSlotFormProps } from "./FreeSlotForm";
import styles from "./FreeSlotForm.module.scss";
import { earliestPossibleBookingCreation } from "../utils";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentSalon from "hooks/useCurrentSalon";
import {
  MobileHairdresserSelect,
  DesktopHairdresserSelect,
} from "./HairdresserSelect";

export interface IFreeSlotFormContentProps extends IFreeSlotFormProps {
  onError(): void;
}

const CalendarFormat = buildCalendarFormat(defaultDisplayFormatWithFullDay);

interface IFreeSlotFormErrors {
  hairdresserIds: string;
  start: string;
  end: string;
  date: string;
  discount: string;
}

interface ValidForm {
  isValid: boolean;
  newErrors: IFreeSlotFormErrors;
}

const defaultHour = moment().startOf("day").add(DEFAULT_HOUR);

const defaultHourDesktop = moment().startOf("day").add(8, "hours");

const discounts = [
  {
    value: 50,
    display: "-50%",
  },
  {
    value: 40,
    display: "-40%",
  },
  {
    value: 30,
    display: "-30%",
  },
  {
    value: 20,
    display: "-20%",
  },
  {
    value: 10,
    display: "-10%",
  },
  {
    value: 0,
    display: "Pas de promotion",
  },
];

const validations = [
  {
    func: (formValues: any) =>
      formValues.hairdresserIds.length > 0 ||
      formValues.hairdresserStringIds.length > 0,
    errKey: "hairdresserIds",
    errString: "Choisir un professionnel",
  },
  {
    func: (formValues: any) =>
      formValues.hairdresserStringIds.length > 0 ||
      formValues.hairdresserIds.length > 0,
    errKey: "hairdresserStringIds",
    errString: "Choisir un professionnel",
  },
  {
    func: (formValues: any, mode: any, now: moment.Moment) =>
      mode != "CREATE" || moment(formValues.date).isSameOrAfter(now, "day"),
    errKey: "date",
    errString: "Impossible de créer des disponibilités dans le passé",
  },
  {
    func: (formValues: any) => formValues.start && formValues.start.length > 0,
    errKey: "start",
    errString: "Saisir une heure de début",
  },
  {
    func: (
      formValues: any,
      mode: any,
      now: moment.Moment,
      errors: IFreeSlotFormErrors
    ) =>
      timeInputToMoment(formValues.start, formValues.date).isAfter(now) &&
      errors.date.length === 0,
    errKey: "start",
    errString: "Impossible de créer des disponibilités dans le passé",
  },
  {
    func: (formValues: any) => formValues.end && formValues.end.length > 0,
    errKey: "end",
    errString: "Saisir une heure de fin",
  },
  {
    func: (formValues: any) =>
      formValues.discount !== undefined && formValues.discount >= 0,
    errKey: "discount",
    errString: "Saisir une promotion",
  },
];

function FreeSlotFormContent({
  mode,
  hairdressers,
  onSubmit,
  onError,
  onDelete,
  onDeleteRecurrence,
  onChange,
  formValues,
  isLoading,
  recurrenceId,
}: IFreeSlotFormContentProps) {
  const [errors, setErrors] = useState<IFreeSlotFormErrors>({
    hairdresserIds: "",
    start: "",
    end: "",
    date: "",
    discount: "",
  });

  const {
    salon: { options },
    companyCode,
  } = useCurrentSalon();
  const { isAdmin } = useCurrentUser();

  const isFormValid = () => {
    const now = moment();
    const { isValid, newErrors } = validations.reduce(
      (acc: ValidForm, validation: any): ValidForm => {
        if (!validation.func(formValues, mode, now, acc.newErrors)) {
          return {
            isValid: false,
            newErrors: {
              ...acc.newErrors,
              [validation.errKey]: validation.errString,
            },
          };
        }

        return {
          isValid: acc.isValid,
          newErrors: {
            ...acc.newErrors,
            [validation.errKey]: "",
          },
        };
      },
      { isValid: true, newErrors: errors }
    );

    return { isValid, newErrors };
  };

  const submit = () => {
    const { isValid, newErrors } = isFormValid();

    setErrors(newErrors);
    if (isValid) {
      onSubmit();
    } else {
      onError();
    }
  };
  const isError = (name: string): boolean => {
    const error = (errors as any)[name];
    return error && error.length > 0 ? true : false;
  };

  return (
    <div className={styles.contentContainer}>
      <Box>
        <ResponsiveModalContent>
          <form onSubmit={onSubmit}>
            <Mobile>
              <MobileSelect
                fullWidth
                label="Promotion"
                name="discount"
                value={formValues.discount}
                listTitle="Sélection promotion"
                list={discounts}
                onChange={onChange}
                error={isError("promo")}
                helperText={errors.discount}
                valueAsDisplay={false}
              />
              {options?.oneCatalogV2 && companyCode ? (
                <MobileHairdresserSelect
                  field="hairdresserStringIds"
                  value={formValues.hairdresserStringIds}
                  list={hairdressers.map((hairdresser) => ({
                    value: `${companyCode}|${hairdresser.stringId}`,
                    display: hairdresser.firstName,
                  }))}
                  onChange={onChange}
                  mode={mode}
                  isError={isError}
                  errors={errors}
                />
              ) : (
                <MobileHairdresserSelect
                  field="hairdresserIds"
                  value={formValues.hairdresserIds}
                  list={hairdressers.map((hairdresser) => ({
                    value: hairdresser.id,
                    display: hairdresser.firstName,
                  }))}
                  onChange={onChange}
                  mode={mode}
                  isError={isError}
                  errors={errors}
                />
              )}
            </Mobile>
            <Desktop>
              <Select
                fullWidth
                label="Promotion"
                name="discount"
                value={formValues.discount}
                onChange={onChange}
                error={isError("discount")}
                helperText={errors.discount}
              >
                {discounts.map((disc: { value: number; display: string }) => (
                  <MenuItem
                    key={disc.value}
                    value={disc.value}
                    classes={{
                      selected: styles.selectedMenuItem,
                    }}
                  >
                    <ListItemText>{disc.display}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
              {options?.oneCatalogV2 && companyCode ? (
                <DesktopHairdresserSelect
                  field="hairdresserStringIds"
                  value={formValues.hairdresserStringIds}
                  onChange={onChange}
                  mode={mode}
                  isError={isError}
                  errors={errors}
                  hairdressers={hairdressers}
                  getHairdresserValue={(hairdresser: Hairdresser) =>
                    `${companyCode}|${hairdresser.stringId}`
                  }
                  isHairdresserChecked={(
                    value: Array<string>,
                    hairdresser: Hairdresser
                  ) => value.includes(`${companyCode}|${hairdresser.stringId}`)}
                />
              ) : (
                <DesktopHairdresserSelect
                  field="hairdresserIds"
                  value={formValues.hairdresserIds}
                  onChange={onChange}
                  mode={mode}
                  isError={isError}
                  errors={errors}
                  hairdressers={hairdressers}
                  getHairdresserValue={(hairdresser: Hairdresser) =>
                    hairdresser.id
                  }
                  isHairdresserChecked={(
                    value: Array<number>,
                    hairdresser: Hairdresser
                  ) => value.includes(hairdresser.id)}
                />
              )}
            </Desktop>
            <div className="input-group">
              <Mobile>
                <DatePickerMobile
                  fullWidth
                  label="Date"
                  name="date"
                  min={moment(earliestPossibleBookingCreation).format(
                    dateInputFormat
                  )}
                  calendarFormat={CalendarFormat}
                  value={
                    mode === "CREATE" &&
                    moment().valueOf() > moment(formValues.date).valueOf()
                      ? moment().toISOString()
                      : formValues.date
                  }
                  onChange={onChange}
                  disabled={mode === "UPDATE"}
                  error={isError("date")}
                  helperText={errors.date}
                />
              </Mobile>
              <Desktop>
                <DatePickerDesktop
                  label="Date"
                  name="date"
                  calendarFormat={CalendarFormat}
                  minDate={
                    mode === "CREATE"
                      ? moment(earliestPossibleBookingCreation).format(
                          dateInputFormat
                        )
                      : undefined
                  }
                  value={
                    mode === "CREATE" &&
                    moment().valueOf() > moment(formValues.date).valueOf()
                      ? moment().toISOString()
                      : formValues.date
                  }
                  onChange={onChange}
                  disabled={mode === "UPDATE"}
                />
              </Desktop>
            </div>
            <Grid
              container
              wrap="nowrap"
              className="input-group input-group-inline"
            >
              <Mobile>
                <TimePickerMobile
                  floatingWheels
                  label="Heure de début"
                  name="start"
                  className="input-group"
                  value={formValues.start}
                  onChange={onChange}
                  min={moment().startOf("day").add(FIRST_HOUR).format("HH:mm")}
                  max={
                    formValues.end.length > 0
                      ? moment()
                          .startOf("day")
                          .add({
                            hours: parseInt(formValues.end.split(":")[0]),
                            minutes: parseInt(formValues.end.split(":")[1]),
                          })
                          .subtract(1, "minutes")
                          .format("HH:mm")
                      : moment()
                          .startOf("day")
                          .add(LAST_HOUR)
                          .subtract(15, "minutes")
                          .format("HH:mm")
                  }
                  step={TIME_STEP_DURATION.asSeconds()}
                  defaultValue={
                    formValues.end.length > 0
                      ? moment()
                          .startOf("day")
                          .add({
                            hours: parseInt(formValues.end.split(":")[0]),
                            minutes: parseInt(formValues.end.split(":")[1]),
                          })
                          .subtract(2, "hours")
                          .format("HH:mm")
                      : defaultHour.format("HH:mm")
                  }
                  error={isError("start")}
                  helperText={errors.start}
                />
                <TimePickerMobile
                  floatingWheels
                  label="Heure de fin"
                  name="end"
                  className="input-group right-floating-wheel-picker"
                  value={formValues.end}
                  onChange={onChange}
                  min={
                    formValues.start.length > 0
                      ? moment()
                          .startOf("day")
                          .add({
                            hours: parseInt(formValues.start.split(":")[0]),
                            minutes: parseInt(formValues.start.split(":")[1]),
                          })
                          .add(15, "minutes")
                          .format("HH:mm")
                      : moment()
                          .startOf("day")
                          .add(FIRST_HOUR)
                          .add(15, "minutes")
                          .format("HH:mm")
                  }
                  max={moment().startOf("day").add(LAST_HOUR).format("HH:mm")}
                  step={TIME_STEP_DURATION.asSeconds()}
                  defaultValue={
                    formValues.start.length > 0
                      ? moment()
                          .startOf("day")
                          .add({
                            hours: parseInt(formValues.start.split(":")[0]),
                            minutes: parseInt(formValues.start.split(":")[1]),
                          })
                          .add(2, "hours")
                          .format("HH:mm")
                      : defaultHour.format("HH:mm")
                  }
                  error={isError("end")}
                  helperText={errors.end}
                />
              </Mobile>
              <Desktop>
                <DesktopTimePicker
                  id="start-time-input"
                  label="Heure de début"
                  name="start"
                  className="input-group"
                  value={
                    formValues.start.length > 0
                      ? moment()
                          .hours(parseInt(formValues.start.split(":")[0]))
                          .minutes(parseInt(formValues.start.split(":")[1]))
                      : undefined
                  }
                  onChange={onChange}
                  error={isError("start")}
                  helperText={errors.start}
                  minuteStep={TIME_STEP_DURATION.asMinutes()}
                  format="HH:mm"
                  defaultOpenValue={
                    formValues.end.length > 0
                      ? moment()
                          .startOf("day")
                          .add({
                            hours: parseInt(formValues.end.split(":")[0]),
                            minutes: parseInt(formValues.end.split(":")[1]),
                          })
                          .subtract(3, "hours")
                      : moment(defaultHourDesktop)
                  }
                  disabledHours={() => {
                    const firstHourAvailable = parseInt(
                      moment().startOf("day").add(FIRST_HOUR).format("HH")
                    );
                    const lastHourAvailable = parseInt(
                      formValues.end.length > 0
                        ? moment()
                            .startOf("day")
                            .add({
                              hours: parseInt(formValues.end.split(":")[0]),
                              minutes: parseInt(formValues.end.split(":")[1]),
                            })
                            .subtract(15, "minutes")
                            .format("HH")
                        : moment()
                            .startOf("day")
                            .add(LAST_HOUR)
                            .subtract(15, "minutes")
                            .format("HH")
                    );
                    return [
                      ...Array.from({
                        length: firstHourAvailable,
                      }).map((_, hour) => hour),
                      ...Array.from({
                        length: 23 - lastHourAvailable,
                      }).map(
                        (_, hourIndex) => hourIndex + 1 + lastHourAvailable
                      ),
                    ];
                  }}
                  disabledMinutes={(selectedHour) => {
                    const firstMinuteAvailable =
                      FIRST_HOUR.hours() === selectedHour
                        ? parseInt(
                            moment().startOf("day").add(FIRST_HOUR).format("mm")
                          )
                        : 0;
                    const lastMinuteAvailable = parseInt(
                      formValues.end.length > 0 &&
                        parseInt(formValues.end.split(":")[0]) === selectedHour
                        ? moment()
                            .startOf("day")
                            .add({
                              hours: parseInt(formValues.end.split(":")[0]),
                              minutes: parseInt(formValues.end.split(":")[1]),
                            })
                            .subtract(15, "minutes")
                            .format("mm")
                        : LAST_HOUR.hours() === selectedHour
                        ? moment()
                            .startOf("day")
                            .add(LAST_HOUR)
                            .subtract(15, "minutes")
                            .format("mm")
                        : "59"
                    );
                    return [
                      ...Array.from({
                        length: firstMinuteAvailable,
                      }).map((_, hour) => hour),
                      ...Array.from({
                        length: 59 - lastMinuteAvailable,
                      }).map(
                        (_, hourIndex) => hourIndex + 1 + lastMinuteAvailable
                      ),
                    ];
                  }}
                />
                <DesktopTimePicker
                  id="end-time-input"
                  label="Heure de fin"
                  name="end"
                  className="input-group"
                  value={
                    formValues.end.length > 0
                      ? moment()
                          .hours(parseInt(formValues.end.split(":")[0]))
                          .minutes(parseInt(formValues.end.split(":")[1]))
                      : undefined
                  }
                  onChange={onChange}
                  minuteStep={TIME_STEP_DURATION.asMinutes()}
                  format="HH:mm"
                  error={isError("end")}
                  helperText={errors.end}
                  defaultOpenValue={
                    formValues.start.length > 0
                      ? parseInt(formValues.start.split(":")[0]) === 23
                        ? moment()
                            .startOf("day")
                            .add({
                              hours: 23,
                              minutes: parseInt(formValues.start.split(":")[1]),
                            })
                            .add(15, "minutes")
                        : moment()
                            .startOf("day")
                            .add({
                              hours:
                                parseInt(formValues.start.split(":")[0]) + 1,
                            })
                      : moment(defaultHourDesktop)
                  }
                  disabledHours={() => {
                    const firstHourAvailable = parseInt(
                      formValues.start.length > 0
                        ? moment()
                            .startOf("day")
                            .add({
                              hours: parseInt(formValues.start.split(":")[0]),
                              minutes: parseInt(formValues.start.split(":")[1]),
                            })
                            .add(15, "minutes")
                            .format("HH")
                        : moment()
                            .startOf("day")
                            .add(FIRST_HOUR)
                            .add(15, "minutes")
                            .format("HH")
                    );
                    const lastHourAvailable = parseInt(
                      moment().startOf("day").add(LAST_HOUR).format("HH")
                    );
                    return [
                      ...Array.from({
                        length: firstHourAvailable,
                      }).map((_, hour) => hour),
                      ...Array.from({
                        length: 23 - lastHourAvailable,
                      }).map(
                        (_, hourIndex) => hourIndex + 1 + lastHourAvailable
                      ),
                    ];
                  }}
                  disabledMinutes={(selectedHour) => {
                    const firstMinuteAvailable = parseInt(
                      formValues.start.length > 0 &&
                        parseInt(formValues.start.split(":")[0]) ===
                          selectedHour
                        ? moment()
                            .startOf("day")
                            .add({
                              hours: parseInt(formValues.start.split(":")[0]),
                              minutes: parseInt(formValues.start.split(":")[1]),
                            })
                            .add(15, "minutes")
                            .format("mm")
                        : FIRST_HOUR.hours() === selectedHour
                        ? moment()
                            .startOf("day")
                            .add(FIRST_HOUR)
                            .add(15, "minutes")
                            .format("mm")
                        : "0"
                    );
                    const lastMinuteAvailable =
                      LAST_HOUR.hours() === selectedHour
                        ? parseInt(
                            moment().startOf("day").add(LAST_HOUR).format("mm")
                          )
                        : 59;
                    return [
                      ...Array.from({
                        length: firstMinuteAvailable,
                      }).map((_, hour) => hour),
                      ...Array.from({
                        length: 59 - lastMinuteAvailable,
                      }).map(
                        (_, hourIndex) => hourIndex + 1 + lastMinuteAvailable
                      ),
                    ];
                  }}
                />
              </Desktop>
            </Grid>
          </form>
        </ResponsiveModalContent>
        {mode === "CREATE" && (
          <ResponsiveModalContent dense>
            <SlotRepetitionForm
              dayToRepeat={moment(formValues.date).day()}
              repeatSlot={formValues.repeatSlot}
              repeatSlotWeekDays={formValues.repeatSlotWeekDays}
              repeatSlotNumberOfWeek={formValues.repeatSlotNumberOfWeek}
              onChange={onChange}
            />
          </ResponsiveModalContent>
        )}
      </Box>
      <Box>
        <ButtonFab onClick={submit} isLoading={isLoading}>
          Valider
        </ButtonFab>
        {mode === "UPDATE" && (
          <ButtonText onClick={onDelete} color="error">
            Supprimer la disponibilité
          </ButtonText>
        )}
        {mode === "UPDATE" && recurrenceId && isAdmin && (
          <ButtonText onClick={onDeleteRecurrence} color="error">
            Supprimer la réccurence
          </ButtonText>
        )}
      </Box>
    </div>
  );
}

export default FreeSlotFormContent;
