import React from "react";
import TimePicker, { TimePickerProps } from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import styles from "./TimePicker.module.scss";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import classnames from "classnames";
import PickerArrow from "../../svgs/PickerArrow";
import { DARK } from "../../../constants/Style";

export interface IDesktopTimePickerProps
  extends Omit<TimePickerProps, "onChange"> {
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

function DesktopTimePicker({
  name,
  onChange,
  label,
  error,
  helperText,
  className,
  disabled,
  ...props
}: IDesktopTimePickerProps) {
  const changeHandler = (date: moment.Moment) =>
    onChange &&
    date &&
    onChange({
      target: { value: date.format("HH:mm"), name }
    } as React.ChangeEvent<HTMLInputElement>);
  return (
    <div className={classnames(className, styles.desktopContainer)}>
      <InputLabel className="input_label" shrink>
        {label}
      </InputLabel>
      <TimePicker
        className={styles.desktopPicker}
        focusOnOpen
        showSecond={false}
        allowEmpty={false}
        popupClassName={styles.desktopPopover}
        minuteStep={15}
        placeholder="-- : --"
        disabled={disabled}
        {...props}
        onChange={onChange ? changeHandler : undefined}
        inputIcon={
          <>
            {!disabled && (
              <PickerArrow className={styles.desktopIcon} large color={DARK} />
            )}
          </>
        }
      />
      {error && helperText && helperText.length > 0 && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
}

export default DesktopTimePicker;
