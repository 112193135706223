import React from "react";
import { TEALISH } from "../../constants/Style";

const RadioCheckedIcon = ({ color, ...props }: { color?: string }) => (
  <svg width={19} height={19} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-14-135h375v667H-14z" />
      <g transform="translate(1 1)">
        <circle
          stroke={color || TEALISH}
          fill={color || TEALISH}
          cx={8.5}
          cy={8.5}
          r={8.5}
        />
        <circle fill="#FFF" cx={8.5} cy={8.5} r={3.5} />
      </g>
    </g>
  </svg>
);

export default RadioCheckedIcon;
