import React from "react";
import classnames from "classnames";
import styles from "./PageToolbar.module.scss";
import { isIFrame } from "utils/helpers";

function PageToolbarDesktop({
  children,
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={classnames(styles.desktopToolbar, {
        "desktop-toolbar-iframe": isIFrame(),
      })}
      {...props}
      style={{ gridArea: "toolbar" }}
    >
      {children}
    </div>
  );
}

export default PageToolbarDesktop;
