import React, { useState, useEffect, useCallback, useRef } from "react";
import WheelPicker from "../WheelPicker";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import PickerArrow from "../../svgs/PickerArrow";
import { BLUE_GREY } from "../../../constants/Style";
import FakeInput from "../FakeInput";

interface TimePickerMobileProps {
  label?: string;
  name: string;
  min?: string;
  max?: string;
  step?: number;
  value?: string;
  defaultValue?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  renderInput?(props: { value: any; onClick(): any }): React.ReactNode;
  floatingWheels?: boolean;
}

const TimePickerMobile: React.FC<TextFieldProps &
  TimePickerMobileProps> = props => {
  const {
    label,
    name,
    min = "00:00",
    max = "23:59",
    step = 60,
    value: controlledValue,
    onChange: controlledOnChange,
    error,
    helperText,
    className,
    disabled,
    defaultValue,
    renderInput,
    fullWidth,
    floatingWheels
  } = props;

  const isControlled = controlledValue !== undefined;

  const timeStringToObject = (time?: string) => {
    if (!time) return {};
    const [hours, minutes] = (time || "").split(":");
    return {
      hours: hours,
      minutes: minutes
    };
  };

  const [internaValues, setInternalValues] = useState<{
    hours?: string;
    minutes?: string;
  }>(timeStringToObject(controlledValue));

  useEffect(() => {
    setInternalValues(timeStringToObject(controlledValue));
  }, [controlledValue]);

  const [selectedHour, setSelectedHour] = useState(internaValues.hours);
  const [minHour, minMinutes] = min.split(":").map(num => parseInt(num));
  const [maxHour, maxMinutes] = max.split(":").map(num => parseInt(num));

  const hourValues = Array.from({
    length: maxHour + 1 - minHour
  }).map((_, index) => (index + minHour).toString().padStart(2, "0"));

  const minuteStep = step / 60;

  const getMinuteValues = useCallback(() => {
    const selectedHourInt = selectedHour && parseInt(selectedHour);
    const minuteStart = selectedHourInt === minHour ? minMinutes : 0;
    const minuteEnd = selectedHourInt === maxHour ? maxMinutes : 59;
    const numberOfSteps = (minuteEnd + 1 - minuteStart) / minuteStep;
    return Array.from({
      length: numberOfSteps
    }).map((_, index) =>
      (index * minuteStep + minuteStart).toString().padStart(2, "0")
    );
  }, [selectedHour, maxHour, maxMinutes, minHour, minMinutes, minuteStep]);

  const renderValues = (values: any, separator = ":") => {
    return values.hours && values.minutes
      ? `${values.hours}${separator}${values.minutes}`
      : "";
  };

  const onChange = (values: any) => {
    if (isControlled) {
      controlledOnChange &&
        controlledOnChange({
          target: {
            name,
            value: renderValues(values)
          }
        } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setInternalValues(values);
    }
  };

  return (
    <WheelPicker
      renderValues={renderValues}
      defaultValues={timeStringToObject(defaultValue)}
      values={internaValues}
      onChange={onChange}
      className={className}
      disabled={disabled}
      floatingWheels={floatingWheels}
    >
      <WheelPicker.Input
        render={({ value, onClick }) =>
          renderInput ? (
            renderInput({ value, onClick })
          ) : (
            <div className="time-picker_input-container">
              <TextField
                label={label}
                onClick={onClick}
                {...{ fullWidth }}
                variant="standard"
                type="text"
                className="input-group"
                error={error}
                helperText={helperText}
                value={value}
                InputLabelProps={{
                  shrink: true,
                  className: "input_label",
                  htmlFor: name
                }}
                InputProps={{
                  className: "input_input input_time",
                  inputComponent: FakeInput
                }}
                // The material-ui API has duplicate props, differing only in casing
                // eslint is not happy with it...
                // eslint-disable-next-line
                inputProps={{
                  name
                }}
              />
              <PickerArrow
                large
                className="input_date-picker-arrow"
                color={BLUE_GREY}
              />
            </div>
          )
        }
      />
      <WheelPicker.Wheels>
        <WheelPicker.Wheel
          name="hours"
          values={hourValues}
          onChange={hour => {
            setSelectedHour(hour);
          }}
        />
        <WheelPicker.Separator>H</WheelPicker.Separator>
        <WheelPicker.Wheel name="minutes" values={getMinuteValues()} />
      </WheelPicker.Wheels>
    </WheelPicker>
  );
};

export default TimePickerMobile;
