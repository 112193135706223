import React, { ReactNode } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import PageContainer from "./page/Container";
import MobilePageHeader from "./page/Header.mobile";
import PageContent from "./page/Content";
import NextIcon from "./svgs/NextIcon";
import { LIGHT_BLUE_GREY } from "../constants/Style";
import classnames from "classnames";
import { LocationDescriptor } from "history";
import CircularProgress from "@material-ui/core/CircularProgress";
import useCurrentSalon from "hooks/useCurrentSalon";
import { SublinksConditions } from "constants/Menu";
import { FLEXY_PARTNER_NAME } from "constants/Account";

export interface IListItem {
  title: string;
  subtitle?: string;
  link: LocationDescriptor;
  conditions?(conditions: SublinksConditions): boolean;
}

interface MobilePageListProps {
  left?: ReactNode;
  title: string;
  listItems: Array<IListItem>;
  isLoading?: boolean;
  emptyListComponent?: React.ReactNode;
  parent?: string;
}

function MobilePageList(props: MobilePageListProps) {
  const {
    left,
    title,
    listItems,
    isLoading,
    emptyListComponent,
    parent,
  } = props;
  const { salon } = useCurrentSalon();
  const linksConditions = {
    isFlexySalon: !!salon?.partnerRefs?.find(
      (ref) => ref.name === FLEXY_PARTNER_NAME
    ),
    autoCalendar: salon.options?.autoCalendar || false,
  };
  return (
    <PageContainer>
      <MobilePageHeader
        left={left}
        center={<span>{title}</span>}
        parent={parent}
      />
      <PageContent>
        {isLoading ? (
          <CircularProgress className="ui-loader-centered" />
        ) : listItems.length > 0 ? (
          <List className="page-list_container-mobile">
            {listItems
              .filter(
                (link) => !link.conditions || link.conditions(linksConditions)
              )
              .map((item) => (
                <ListItem
                  key={item.title}
                  className={classnames({ "with-subtitle": item.subtitle })}
                >
                  <Link
                    to={item.link}
                    className="page-list_item_container-mobile"
                  >
                    <div className="page-list_item-text_container-mobile">
                      <span className="page-list_item_title-mobile">
                        {item.title}
                      </span>
                      {item.subtitle && (
                        <span className="page-list_item_subtitle-mobile">
                          {item.subtitle}
                        </span>
                      )}
                    </div>
                    <NextIcon color={LIGHT_BLUE_GREY} />
                  </Link>
                </ListItem>
              ))}
          </List>
        ) : (
          emptyListComponent
        )}
      </PageContent>
    </PageContainer>
  );
}

export default MobilePageList;
