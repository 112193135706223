import React, { useRef, useState, useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import moment from "moment";

import * as AGENDA from "constants/Agenda";
import * as CALENDAR from "constants/Calendar";
import Calendar, {
  CalendarViewType,
  CalendarEvent,
  CalendarResource,
} from "components/Calendar";
import { CALENDAR_COLUMN_DATA_ID } from "components/Calendar/CalendarColumn";
import CalendarDayPickerMobile from "components/Calendar/DatePickers/CalendarDayPicker.mobile";
import PlusIcon from "components/svgs/PlusIcon";
import MobilePageContainer from "components/page/Container.mobile";
import MobilePageHeader from "components/page/Header.mobile";
import PageContent from "components/page/Content";
import CalendarMonthPickerMobile from "components/Calendar/DatePickers/CalendarMonthPicker.mobile";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import CalendarEventRenderer, {
  IEventMeta,
  IBookingEventMeta,
  ISlotEventMeta,
  ICreatingEventMeta,
} from "components/agenda/EventRenderer/EventRenderer";
import FiltersSelector from "components/agenda/FiltersSelector";
import AgendaToolbar from "components/agenda/Toolbar.desktop/Toolbar";
import useAgendaFilterSelector, {
  AgendaFilters,
} from "hooks/useAgendaFilterSelector";
import { Slot } from "reducers/slot";
import "./agenda-page.scss";
import { earliestPossibleBookingCreation } from "../utils";
import { DEFAULT_BOOKING_DISCOUNT } from "constants/Booking";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";
import { fillPartnerRef } from "utils/slot";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import DesktopModalHeader from "components/modal/Header/Header.desktop";

export const AGENDA_EVENT_KINDS = {
  BOOKING: "BOOKING" as const,
  SLOT: "SLOT" as const,
  CREATING: "CREATING" as const,
  UNAVAILABLE: "UNAVAILABLE" as const,
  AVAILABLE: "AVAILABLE" as const,
  LAST_MINUTE: "LAST_MINUTE" as const,
};

interface AgendaPageProps {
  resources: Array<CalendarResource>;
  events: Array<CalendarEvent<IEventMeta>>;
  date: moment.Moment;
  editEvent(
    event: CalendarEvent<
      ICreatingEventMeta | ISlotEventMeta | IBookingEventMeta
    >
  ): void;
  updateDate(date: string): void;
  updateFilters(filters: AgendaFilters): void;
  createSlot(partialSlot?: Omit<Slot, "id">): Promise<unknown>;
  filters: AgendaFilters;
  isLoading: boolean;
  mode: keyof typeof CALENDAR.VIEWS;
  onSelectMode(mode: keyof typeof CALENDAR.VIEWS): void;
}

function AgendaPage({ editEvent, ...props }: AgendaPageProps) {
  const { updateDate, filters, updateFilters } = props;
  const [lmModalOpened, setLmModalOpened] = useState(false);
  const onChangeDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDate(moment(event.target.value).toISOString());
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const openFilters = () => setIsFiltersOpen(true);
  const closeFilters = () => setIsFiltersOpen(false);

  /**
   * TODO replace onClickEvent with click handler on Event Renderer
   */
  const onClickEvent = (event: CalendarEvent<IEventMeta>) => {
    const eventMeta = event.meta;
    switch (eventMeta.kind) {
      case AGENDA_EVENT_KINDS.SLOT:
      case AGENDA_EVENT_KINDS.BOOKING:
        editEvent({ ...event, meta: eventMeta });
        break;
      case AGENDA_EVENT_KINDS.LAST_MINUTE:
        setLmModalOpened(true);
        break;
    }
  };

  return (
    <React.Fragment>
      {lmModalOpened && (
        <ResponsiveModalContainer open={lmModalOpened}>
          <>
            <Mobile>
              <DesktopModalHeader
                onClose={() => setLmModalOpened(false)}
                title={"Attention"}
              />
              <div style={{ padding: "0 10px" }}>
                <p>
                  Il s’agit d’une disponibilité avec promotion Last Minute,
                  <br />
                  si vous souhaitez la modifier veuillez vous rendre dans la
                  <br />
                  section “Mon compte : Promotions Last Minute”
                </p>
              </div>
            </Mobile>
            <Desktop>
              <ModalLayoutDesktop
                onClose={() => setLmModalOpened(false)}
                header={"Attention"}
                body={
                  <div style={{ padding: "0 20px" }}>
                    <p>
                      Il s’agit d’une disponibilité avec promotion Last Minute,
                      <br />
                      si vous souhaitez la modifier veuillez vous rendre dans la
                      <br />
                      section “Mon compte : Promotions Last Minute”
                    </p>
                  </div>
                }
              />
            </Desktop>
          </>
        </ResponsiveModalContainer>
      )}
      <Desktop>
        <DesktopAgendaPage
          {...props}
          onChangeDay={onChangeDay}
          onOpenFilters={openFilters}
          onClickEvent={onClickEvent}
        />
      </Desktop>
      <Mobile>
        <MobileAgendaPage
          {...props}
          onChangeDay={onChangeDay}
          onOpenFilters={openFilters}
          onClickEvent={onClickEvent}
        />
      </Mobile>
      <FiltersSelector
        {...useAgendaFilterSelector({
          filters,
          onSubmit: (selected) => updateFilters(selected),
        })}
        open={isFiltersOpen}
        onClose={closeFilters}
      />
      )}
    </React.Fragment>
  );
}

function getCreatingEvent(resource: CalendarResource, datetime: string) {
  const endOfDay = moment(datetime).endOf("day");
  const partialSlot: Omit<Slot, "id"> = {
    hairdresserId: Number(resource.id),
    hairdresserStringId: resource.stringId || "",
    discount: DEFAULT_BOOKING_DISCOUNT,
    range: {
      start: moment(datetime).toISOString(),
      end: moment
        .min(moment(datetime).add({ hours: 2 }), endOfDay)
        .toISOString(),
    },
  };
  const slot = fillPartnerRef(partialSlot, resource);
  const creatingEvent: CalendarEvent<ICreatingEventMeta> = {
    id: "creating",
    resourceId: resource.id,
    hairdresserStringId: resource.stringId || "",
    start: moment(slot.range.start),
    end: moment(slot.range.end),
    meta: {
      kind: AGENDA_EVENT_KINDS.CREATING,
      partialSlot: slot,
    },
  };
  return creatingEvent;
}

const MOUSE_LEFT_BUTTON = 0;

function DesktopAgendaPage({
  resources,
  events,
  onOpenFilters,
  date,
  onClickEvent,
  isLoading,
  onChangeDay,
  mode,
  onSelectMode,
  createSlot,
}: Omit<AgendaPageProps, "filters" | "updateFilters" | "editEvent"> & {
  onChangeDay(event: React.ChangeEvent<HTMLInputElement>): void;
  onOpenFilters(): void;
  onClickEvent(event: CalendarEvent<IEventMeta>): void;
}) {
  const { isMobile } = useResponsiveLayout();

  const [creatingEvent, setCreatingEvent] = useState<CalendarEvent<
    ICreatingEventMeta
  > | null>(null);

  useEffect(() => {
    if (creatingEvent) {
      document.body.style.cursor = "grabbing";
    } else {
      document.body.style.cursor = "inherit";
    }
  }, [creatingEvent]);

  const [
    mouseDownTimeout,
    setMouseDownTimeout,
  ] = useState<NodeJS.Timeout | null>(null);
  const onMouseDownRessource = (
    resource: CalendarResource,
    datetime: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (isMobile || event.button !== MOUSE_LEFT_BUTTON) return;
    const target = event.target as HTMLDivElement;
    const isCalendarColumn = Boolean(target.dataset[CALENDAR_COLUMN_DATA_ID]);

    if (
      isCalendarColumn &&
      moment(datetime).isAfter(earliestPossibleBookingCreation)
    ) {
      const creatingEvent = getCreatingEvent(resource, datetime);
      setMouseDownTimeout(
        // Start creating the event after a short timeout to make sure
        // the click is intentional (timeout is cleared on mouseup)
        setTimeout(() => {
          setCreatingEvent(creatingEvent);
          setMouseDownTimeout(null);
        }, 80)
      );
    }
  };

  const onMouseMoveRessource = (
    resource: CalendarResource,
    datetime: string
  ) => {
    if (!creatingEvent) return;

    const now = moment();
    if (
      moment(datetime).isAfter(now) && // Cancel Slot Creation if dragged before now
      resource.id === creatingEvent.resourceId // or dragged on another hairdresser
    ) {
      const start = moment(datetime).toISOString();
      // Update only if necessary
      if (start !== creatingEvent.start.toISOString()) {
        const creatingEvent = getCreatingEvent(resource, datetime);
        setCreatingEvent(creatingEvent);
      }
    } else {
      setCreatingEvent(null);
    }
  };

  const [canCancelEventCreation, setCanCancelEventCreation] = useState(true);
  const onMouseUpRessource = () => {
    if (mouseDownTimeout) {
      clearTimeout(mouseDownTimeout);
      setMouseDownTimeout(null);
    }

    if (creatingEvent) {
      // Prevent cancelling when creating slot so it stays visible on the agenda
      setCanCancelEventCreation(false);

      document.body.style.cursor = "inherit";
      const { partialSlot } = creatingEvent.meta;
      createSlot(partialSlot).finally(() => {
        setCanCancelEventCreation(true);
        setCreatingEvent(null);
      });
    }
  };

  const onMouseLeaveRessource = () => {
    if (creatingEvent && canCancelEventCreation) {
      setCreatingEvent(null);
    }
  };

  const eventToDisplay = creatingEvent ? [...events, creatingEvent] : events;

  return (
    <div
      className={classnames("agenda-page-desktop-container", {
        "agenda-page-desktop-container-iframe": isIFrame(),
      })}
    >
      <MenuDesktop noShadow />
      <div
        className={classnames("", {
          "grid-toolbar": isIFrame(),
          "simple-toolbar": !isIFrame(),
        })}
      >
        <AgendaToolbar
          mode={mode}
          onSelectMode={onSelectMode}
          date={date.toISOString()}
          onChangeDay={onChangeDay}
          onOpenFilters={onOpenFilters}
          onCreateSlot={createSlot}
        />
        <Calendar
          className={classnames("agenda_desktop-calendar-container", {
            "agenda_desktop-calendar-container-iframe": isIFrame(),
          })}
          defaultTime={AGENDA.DEFAULT_HOUR}
          dayStart={AGENDA.FIRST_HOUR}
          dayEnd={AGENDA.LAST_HOUR}
          timeStepDuration={AGENDA.TIME_STEP_DURATION}
          date={date}
          view={mode}
          resources={resources}
          showResourceIcon
          events={eventToDisplay}
          eventRenderer={CalendarEventRenderer}
          onClickEvent={onClickEvent}
          onMouseDownRessource={onMouseDownRessource}
          onMouseUpRessource={onMouseUpRessource}
          onMouseMoveRessource={onMouseMoveRessource}
          onMouseLeaveRessource={onMouseLeaveRessource}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

function MobileAgendaPage({
  resources,
  events,
  date,
  onClickEvent,
  updateDate,
  createSlot,
  isLoading,
  onChangeDay,
  onOpenFilters,
}: Omit<AgendaPageProps, "filters" | "updateFilters" | "editEvent"> & {
  onChangeDay(event: React.ChangeEvent<HTMLInputElement>): void;
  onOpenFilters(): void;
  onClickEvent(event: CalendarEvent<IEventMeta>): void;
}) {
  const onChangeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = moment(event.target.value).startOf("month");

    if (!date.isSame(newDate, "month")) {
      updateDate(newDate.toISOString());
    }
  };

  const scollableElementRef = useRef<HTMLDivElement>(null);

  const preventOverscroll = (e: React.UIEvent) => {
    const { scrollLeft, scrollTop } = e.target as any;
    const scrollElement = scollableElementRef.current;
    if (!scrollElement) return;
    if (scrollLeft < -80 || scrollTop < -80) {
      scrollElement.style["-webkit-overflow-scrolling" as any] = "auto";
      if (scollableElementRef.current) {
        scollableElementRef.current.scrollTop = Math.max(scrollTop, 0);
        scollableElementRef.current.scrollLeft = Math.max(scrollLeft, 0);
      }
      setTimeout(function () {
        scrollElement.style["-webkit-overflow-scrolling" as any] = "touch";
      }, 5);
    }
  };
  return (
    <MobilePageContainer>
      <MobilePageHeader
        dense
        classes={{ container: "agenda-page-header" }}
        center={
          <CalendarMonthPickerMobile
            value={date.toISOString()}
            onChange={onChangeMonth}
          />
        }
        right={
          <div
            className="calendar_filter_selector-mobile"
            onClick={onOpenFilters}
          >
            Filtres
          </div>
        }
      >
        <CalendarDayPickerMobile
          value={date.toISOString()}
          onChange={onChangeDay}
        />
      </MobilePageHeader>
      {/* this one shows the popup but with no button */}
      {/*<DiscoverFlexy noButton />*/}
      <PageContent
        className="agenda-page-content"
        ref={scollableElementRef}
        onScroll={preventOverscroll}
      >
        <Calendar
          className="agenda_calendar-container"
          defaultTime={AGENDA.DEFAULT_HOUR}
          dayStart={AGENDA.FIRST_HOUR}
          dayEnd={AGENDA.LAST_HOUR}
          timeStepDuration={AGENDA.TIME_STEP_DURATION}
          date={date}
          view={CALENDAR.VIEWS.DAY as CalendarViewType}
          resources={resources}
          events={events}
          eventRenderer={CalendarEventRenderer}
          onClickEvent={onClickEvent}
          isLoading={isLoading}
          scollableElementRef={scollableElementRef}
        />
      </PageContent>
      <Fab
        className="fab_button-mobile"
        size="medium"
        onClick={() => createSlot()}
      >
        <PlusIcon />
      </Fab>
    </MobilePageContainer>
  );
}

export default AgendaPage;
export { DesktopAgendaPage, MobileAgendaPage };
