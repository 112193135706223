import React from "react";

const ParginkIcon = (props: any) => (
  <svg width={24} height={22} viewBox="0 0 24 22" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M3.776 5.832c.111.016.255.024.415.024.72 0 1.335-.175 1.75-.568.319-.304.495-.751.495-1.279 0-.528-.232-.975-.577-1.246-.359-.288-.893-.431-1.645-.431-.743 0-1.27.047-1.645.111v5.314h1.207V5.832zm0-2.533c.087-.024.254-.048.502-.048.607 0 .95.296.95.79 0 .552-.399.88-1.046.88-.176 0-.303-.009-.407-.033v-1.59z" />
      <path d="M1.5 9.424h6.005c.76 0 1.376-.616 1.376-1.376V2.042c0-.76-.616-1.376-1.376-1.376H1.5C.74.666.124 1.282.124 2.042v6.006c0 .76.616 1.376 1.375 1.376zm-.29-7.3c0-.206.167-.372.372-.372h5.841c.205 0 .372.167.372.371v5.843a.372.372 0 01-.372.371H1.582a.372.372 0 01-.372-.371V2.123zM5.694 16.613a2.402 2.402 0 10-.001 4.804 2.402 2.402 0 00.001-4.804zm0 3.128a.727.727 0 01-.726-.726c0-.4.327-.725.726-.725a.727.727 0 010 1.45z" />
      <path d="M23.186 17.047l-.394-3.197a1.176 1.176 0 00-1.232-1.03l-.86.048-2.674-2.409a1.398 1.398 0 00-.936-.359h-5.431a5.35 5.35 0 00-3.351 1.18l-3.03 2.434-4.074 1.018a1.177 1.177 0 00-.891 1.14v1.174A.314.314 0 000 17.36v1.553c0 .221.18.4.4.4h2.3c-.009-.1-.03-.195-.03-.297a3.022 3.022 0 116.045 0c0 .103-.021.198-.03.297h6.37c-.01-.1-.03-.195-.03-.297a3.021 3.021 0 116.043 0c0 .103-.02.198-.03.297h2.145a.4.4 0 00.4-.4v-1.465a.397.397 0 00-.397-.401zm-8.06-3.929l-7.577.384 1.45-1.166a4.43 4.43 0 012.78-.98h3.346v1.762zm1.258-.064v-1.697h.493c.171 0 .335.063.462.178l1.547 1.392-2.502.127z" />
      <path d="M18.048 16.613a2.402 2.402 0 10-.001 4.804 2.402 2.402 0 00.001-4.804zm0 3.128a.727.727 0 01-.725-.726c0-.4.326-.725.725-.725a.727.727 0 010 1.45z" />
    </g>
  </svg>
);

export default ParginkIcon;
