import React from "react";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import Variant, { IVariant } from "components/uiElements/Variant/Variant";
import Checkbox from "components/inputs/Checkbox";
import NextIcon from "components/svgs/NextIcon";
import { BLUE_GREY, TEALISH, PINK, GREY } from "constants/Style";
import { Package } from "reducers/salon";
import { priceFormatter } from "utils/payments";
import styles from "./PackageTitleItem.module.scss";
import classnames from "classnames";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import WarningIcon from "components/svgs/WarningIcon";
import { getOpacityStyle } from "containers/Package/utils";

interface PackageTitleItemProps {
  selectable?: boolean;
  selected?: boolean;
  pack: Package;
  withUnderline: boolean;
  fullWidthUnderline?: boolean;
  onItemClick(): void;
  updatePackageStatusHandler?(packageId: number, status: string): void;
  displayEnableCheckbox?: boolean;
  updatePackageAllowPromoHandler(packageId: number, allowPromo: boolean): void;
  hairdressersCount?: number;
  isAdmin?: boolean;
  isOneCatalog: boolean;
}

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: TEALISH,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: TEALISH,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  labelPlacementTop: {
    marginLeft: "8px",
  },
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function PackageTitleItem({
  selectable,
  selected,
  pack,
  withUnderline,
  fullWidthUnderline,
  onItemClick,
  updatePackageStatusHandler,
  displayEnableCheckbox,
  updatePackageAllowPromoHandler,
  hairdressersCount,
  isAdmin,
  isOneCatalog,
}: PackageTitleItemProps) {
  const smallestPrice = pack.prices.reduce(
    (smallest, price) => Math.min(smallest, price.price),
    pack.prices[0].price
  );
  const variants = pack.prices.reduce(
    (variants, price) =>
      price.variant ? [...variants, price.variant] : variants,
    [] as Array<IVariant>
  );
  const opacityStyle = getOpacityStyle(isOneCatalog);
  const { isMobile } = useResponsiveLayout();
  return (
    <div
      onClick={onItemClick}
      className={classnames(styles.container, {
        [styles.withUnderline]: withUnderline,
        [styles.fullWidthUnderline]: fullWidthUnderline,
      })}
    >
      <div className={styles.smallFlexContainer} style={opacityStyle}>
        <Checkbox
          style={{ display: displayEnableCheckbox ? "block" : "none" }}
          checked={pack.status === "ENABLED"}
          onClick={(e) => {
            e.stopPropagation();
            if (isOneCatalog) {
              return;
            }
            updatePackageStatusHandler &&
              updatePackageStatusHandler(
                pack.id,
                pack.status === "ENABLED" ? "DISABLED" : "ENABLED"
              );
            if (pack.status === "ENABLED") {
              updatePackageAllowPromoHandler(pack.id, false);
            }
          }}
        />
      </div>
      <div className={styles.primaryContentContainer}>
        {selectable && (
          <Checkbox className={styles.checkbox} checked={selected} />
        )}
        <div className={styles.titleAndVariant}>
          <span
            className={
              pack.status === "ENABLED" ? styles.title : styles.titleDisabled
            }
          >
            {pack.name}
          </span>
          {variants.length > 0 && (
            <div>
              {variants.map((variant) => (
                <Variant
                  key={variant.id}
                  variant={variant}
                  className={styles.variant}
                />
              ))}
            </div>
          )}
          <br />
          {hairdressersCount ? (
            <span
              className={styles.smallText}
              style={{
                color:
                  pack.hairdressersAssigned?.length === 0 ? PINK : "initial",
              }}
            >
              {pack.hairdressersAssigned?.length === 0 ? (
                <span className={styles.warningIcon}>
                  <WarningIcon />
                </span>
              ) : (
                ""
              )}
              {pack.hairdressersAssigned?.length || 0} professionnel
              {pack.hairdressersAssigned?.length || 0 > 1 ? "s" : ""} assigné
              {pack.hairdressersAssigned?.length || 0 > 1 ? "s" : ""} (sur{" "}
              {hairdressersCount})
              {isAdmin && (
                <div onClick={(e) => e.stopPropagation()}>
                  <br />
                  {pack.prices &&
                    pack.prices.length > 0 &&
                    pack.prices.map(
                      (price) =>
                        price.stringId &&
                        price.stringId !== "" && (
                          <div>
                            <b style={{ userSelect: "all" }}>
                              {price?.variant?.name || "Pas de variante"}
                            </b>
                            :{" "}
                            <span style={{ userSelect: "all" }}>
                              {price.stringId}
                            </span>
                          </div>
                        )
                    )}
                </div>
              )}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={styles.secondaryContentContainer}>
        <div className={styles.priceOutterContainer}>
          <div className={styles.title}>Prix plein tarif</div>
          <div className={styles.priceContainer}>{`${smallestPrice} €`}</div>
        </div>
      </div>
      {isAdmin ? (
        <div className={styles.ternaryContainer} style={opacityStyle}>
          <FormControlLabel
            onClick={(e: any) => e.stopPropagation()}
            control={
              <IOSSwitch
                checked={pack.allowPromo}
                onChange={() => {
                  if (isOneCatalog) {
                    return;
                  }
                  updatePackageAllowPromoHandler(pack.id, !pack.allowPromo);
                  if (
                    pack.status === "DISABLED" &&
                    !pack.allowPromo &&
                    updatePackageStatusHandler
                  ) {
                    updatePackageStatusHandler(pack.id, "ENABLED");
                  }
                }}
                name="allowPromo"
              />
            }
            label="Réservable en promotion"
            labelPlacement={isMobile ? "top" : "start"}
            className={styles.discreetLabel}
          />
        </div>
      ) : (
        <div className={styles.ternaryContainer}>
          {pack.allowPromo ? (
            <span style={{ color: TEALISH }}>Promotion autorisée</span>
          ) : (
            <span style={{ color: GREY }}>Promotion non applicable</span>
          )}
        </div>
      )}
      <div className={styles.quaternaryContainer}>
        {isMobile && !selectable && (
          <NextIcon className={styles.nextIcon} color={BLUE_GREY} />
        )}
      </div>
    </div>
  );
}

export default PackageTitleItem;
