import React from "react";
import { WHITE } from "constants/Style";

const CoinsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="22px" height="22px" viewBox="0 0 16.22 14.02" {...props}>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-20.000000, -4480.000000)"
        fill={props.color || WHITE}
        fill-rule="nonzero"
      >
        <g transform="translate(20.000000, 4480.000000)">
          <path
            id="Tracé_192"
            data-name="Tracé 192"
            d="M10.65,0C7.89,0,5.09.81,5.09,2.37a1.25,1.25,0,0,0,.12.54C2.57,3,0,3.79,0,5.28v6.37C0,13.21,2.8,14,5.56,14s5.57-.81,5.57-2.37v-.54c2.6-.09,5.09-.9,5.09-2.37V2.37C16.22.81,13.42,0,10.65,0Zm0,1.11c2.94,0,4.46.88,4.46,1.26s-1.52,1.26-4.46,1.26c-.36,0-.72,0-1.07,0A9.44,9.44,0,0,0,6.91,3c-.56-.26-.71-.5-.71-.6,0-.38,1.51-1.26,4.45-1.26ZM5.56,4C8.5,4,10,4.9,10,5.28S8.5,6.54,5.56,6.54,1.11,5.66,1.11,5.28,2.62,4,5.56,4ZM1.11,6.76a9.88,9.88,0,0,0,4.45.89A9.92,9.92,0,0,0,10,6.76v1.7c0,.38-1.52,1.27-4.46,1.27S1.11,8.84,1.11,8.46Zm4.45,6.15c-2.94,0-4.45-.88-4.45-1.26V9.94a9.89,9.89,0,0,0,4.45.9A9.93,9.93,0,0,0,10,9.94v1.71c0,.38-1.51,1.26-4.46,1.26ZM11.13,10V7.92a9.32,9.32,0,0,0,4-.89V8.74c0,.36-1.36,1.17-4,1.26Zm0-3.19V5.28A1.13,1.13,0,0,0,11,4.74a9.64,9.64,0,0,0,4.1-.89V5.56c0,.35-1.35,1.16-4,1.25Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CoinsIcon;
