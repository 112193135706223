import React from "react";
import HeaderMobile from "../../page/Header.mobile";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import TextInput from "../Text";
import CloseIcon from "../../svgs/CloseIcon";
import ModalContent from "../../modal/Content/Content.mobile";
import { Location } from "../../../reducers/salon";

interface AddressAutocompleteInputPageProps {
  query: string;
  locations: Array<Location>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onClose(): void;
  onLocationClick(location: Location): void;
}

function AddressAutocompleteInputPage(
  props: AddressAutocompleteInputPageProps
) {
  const { query, onChange, locations, onClose, onLocationClick } = props;
  const placeholder = "Adresse";
  return (
    <ModalLayoutMobile
      header={
        <HeaderMobile
          classes={{
            left: "autocomplete-input_container",
            right: "autocomplete-input_right-container"
          }}
          left={
            <TextInput
              type="search"
              autoFocus
              fullWidth
              className="autocomplete-input"
              value={query}
              onChange={onChange}
              placeholder={placeholder}
              InputProps={{
                disableUnderline: true
              }}
              // The material-ui API has duplicate props, differing only in casing
              // eslint is not happy with it...
              // eslint-disable-next-line
              inputProps={{
                style: {
                  minWidth: "300px",
                  padding: "unset",
                  minHeight: "21px" // Fixes M-UI design issue where scrollbar is shown when text empty
                } // https://github.com/mui-org/material-ui/pull/15331
              }}
            />
          }
          right={<CloseIcon onClick={onClose} />}
        />
      }
      body={
        <React.Fragment>
          {locations.length > 0 && (
            <ModalContent dense withChildrenSeparator>
              {locations.map(location => (
                <div
                  key={location.address.address}
                  onClick={() => onLocationClick(location)}
                  className="dense-list_item-container-mobile"
                >
                  <span>{location.address.address}</span>
                </div>
              ))}
            </ModalContent>
          )}
        </React.Fragment>
      }
    />
  );
}

export default AddressAutocompleteInputPage;
