import React, { useState } from "react";
import { Rating } from "../../reducers/rating";
import HeaderMobile from "../page/Header.mobile";
import ModalLayoutMobile from "../modal/Layout/Layout.mobile";
import PreviousIcon from "../svgs/PreviousIcon";
import classnames from "classnames";
import { RatingCommentBase } from "./RatingComment/RatingComment";
import TextInput from "../inputs/Text";
import LargeClickable from "../uiElements/LargeClickable";

interface RatingReplyFormProps {
  type: "HAIRDRESSER" | "SALON" | "USER";
  rating: Rating;
  onClose(): void;
  submitRatingReply(reply: string): void;
}

function RatingReplyForm({
  type,
  rating,
  onClose,
  submitRatingReply
}: RatingReplyFormProps) {
  const { user = { firstName: "", lastName: "" } } = rating;
  const userName = `${user.firstName} ${user.lastName}`;
  const title = `Répondre à ${userName}`;
  const [reply, setReply] = useState("");
  const disabled = reply.trim() === "";
  const submit = () => {
    if (disabled) {
      return;
    }
    submitRatingReply(reply);
    onClose();
  };
  const submitClassName = classnames("rating-reply-submit-text", {
    disabled
  });
  return (
    <ModalLayoutMobile
      plainBackground
      header={
        <HeaderMobile
          left={
            <LargeClickable onClick={onClose}>
              <PreviousIcon onClick={onClose} />
            </LargeClickable>
          }
          center={title}
          right={
            <span className={submitClassName} onClick={submit}>
              OK
            </span>
          }
        />
      }
      body={
        <div className="rating-reply-comment-wrapper">
          <RatingCommentBase expanded type={type} rating={rating} />
        </div>
      }
      footer={
        <TextInput
          multiline
          className="rating-reply-input"
          value={reply}
          onChange={event => setReply(event.target.value)}
          placeholder={title}
          rowsMax={10}
          InputProps={{
            disableUnderline: true
          }}
          // The material-ui API has duplicate props, differing only in casing
          // eslint is not happy with it...
          // eslint-disable-next-line
          inputProps={{
            style: {
              minHeight: "21px" // Fixes M-UI design issue where scrollbar is shown when text empty
            } // https://github.com/mui-org/material-ui/pull/15331
          }}
        />
      }
    />
  );
}

export default RatingReplyForm;
