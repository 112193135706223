import React from "react";
import SyncSelect, { Props as ReactSelectProps } from "react-select";
import AsyncSelect, {
  Props as ReactAsyncSelectProps
} from "react-select/async";
import ArrowBottomIcon from "../../svgs/ArrowBottomIcon";
import { TEALISH, OPACITY } from "constants/Style";
import "./select-autocomplete.scss";

export type IOptionType = {
  value: string;
  label: string;
};

export type ISelectAutocomplete = Omit<ReactSelectProps, "loadOptions"> & {
  value?: IOptionType;
  options: IOptionType[];
  onChange(value: any): void;
  noOptionsMessage?:
    | ((obj: { inputValue: string }) => string | null)
    | undefined;
  placeholder?: React.ReactNode;
} & Partial<Pick<ReactAsyncSelectProps<IOptionType>, "loadOptions">>;

function SelectAutocomplete({
  value,
  options,
  onChange,
  noOptionsMessage,
  placeholder,
  loadOptions,
  ...otherProps
}: ISelectAutocomplete) {
  return (
    <>
      {loadOptions ? (
        <AsyncSelect
          loadOptions={loadOptions}
          className="autocomplete-select"
          classNamePrefix="react-select"
          components={{
            DropdownIndicator: ({ innerProps }) => (
              <ArrowBottomIcon {...innerProps} color={TEALISH} />
            )
          }}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `${TEALISH}${OPACITY[30]}`,
              primary50: `${TEALISH}${OPACITY[50]}`,
              primary75: `${TEALISH}${OPACITY[80]}`,
              primary: TEALISH
            }
          })}
          value={value}
          onChange={onChange}
          options={options}
          {...otherProps}
        />
      ) : (
        <SyncSelect
          className="autocomplete-select"
          classNamePrefix="react-select"
          components={{
            DropdownIndicator: ({ innerProps }) => (
              <ArrowBottomIcon {...innerProps} color={TEALISH} />
            )
          }}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `${TEALISH}${OPACITY[30]}`,
              primary50: `${TEALISH}${OPACITY[50]}`,
              primary75: `${TEALISH}${OPACITY[80]}`,
              primary: TEALISH
            }
          })}
          value={value}
          onChange={onChange}
          options={options}
          {...otherProps}
        />
      )}
    </>
  );
}

export default SelectAutocomplete;
