import React from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { Booking, User } from "reducers/booking";
import styles from "./BookingClientInfos.module.scss";

interface BookingClientInfosProps {
  booking: Booking;
}

const nulllUser: User = {
  id: -1,
  firstName: "",
  lastName: "",
  username: "",
  mobile: ""
};

export default function BookingClientInfos({
  booking: { user = nulllUser as any } // TODO: can't find the typings error...
}: BookingClientInfosProps) {
  const phoneNumber = parsePhoneNumberFromString(
    user.mobile ?? user.phone ?? "",
    "FR"
  );
  return (
    <div className={styles.container}>
      <div className={styles.name}>
        {user.firstName} {user.lastName}
      </div>
      <div className={styles.email}>{user.username}</div>
      {phoneNumber && (
        <a className={styles.phone} href={phoneNumber.getURI()}>
          {phoneNumber.formatInternational()}
        </a>
      )}
    </div>
  );
}
