import { Location } from "../reducers/salon";

const FULL_LAYERS =
  "postalcode,localadmin,neighbourhood,address,street,locality";

export const locality = (input: string, layers = FULL_LAYERS) =>
  fetch(
    `https://api.jawg.io/places/v1/autocomplete?sources=oa,osm,wof&layers=${layers}&boundary.country=FR&access-token=${process.env.REACT_APP_JAWG_TOKEN}&text=${input}`
  );

/**
 * Map the feature from JAWG API to a Location object
 * @param feature
 */
export const featureToLocation = (feature: any): Location => {
  const [lng, lat] = feature.geometry.coordinates;
  return {
    address: {
      address: feature.properties.label,
      city: feature.properties.locality,
      zipCode: feature.properties.postalcode
    },
    geoLocation: {
      lat,
      lng
    }
  };
};
