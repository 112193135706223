import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../reducers";
import { AuthState } from "../reducers/auth";
import { parseRoute } from "../utils/route";
import { routeList } from "../constants/Routes";
import { SalonState } from "../reducers/salon";
import useCurrentUser from "../hooks/useCurrentUser";

interface PrivateRouteProps extends RouteProps {
  authState: AuthState;
  salonState: SalonState;
}

function PrivateRoute({
  component,
  authState,
  salonState,
  ...rest
}: PrivateRouteProps) {
  const {
    payload: { selectedSalonId, salonById }
  } = salonState;
  const { isLoggedIn, isAdmin } = useCurrentUser();
  const location = useLocation();

  const needsToAcceptTerms =
    selectedSalonId && salonById[selectedSalonId]?.status === "CREATED";
  const isTermPage =
    location.pathname === parseRoute(routeList.TERM_AGREEMENT_PAGE);
  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: parseRoute(routeList.LOGIN_PAGE),
                state: { from: props.location }
              }}
            />
          );
        }
        if (!isTermPage && !isAdmin && needsToAcceptTerms) {
          return (
            <Redirect
              to={{
                pathname: parseRoute(routeList.TERM_AGREEMENT_PAGE),
                state: { from: props.location }
              }}
            />
          );
        }
        return <Route component={component} {...rest} />;
      }}
    />
  );
}

function mapState(state: AppState) {
  return {
    authState: state.auth,
    salonState: state.salon
  };
}

export default connect(mapState)(PrivateRoute);
