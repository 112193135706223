import { mutation, safeError } from "./graphqlClient";
import { getFromCache } from "./cache";
import { LOGIN_ERRORS } from "../constants/Login";
import { loginSchema } from "../reducers/auth";

export interface AuthLogin {
  username: string;
  password: string;
}

const loginError = (response: Response) => {
  const statusString = response.status.toString();
  if (statusString.startsWith("4")) {
    return LOGIN_ERRORS.WRONG_CREDENTIALS;
  } else if (statusString.startsWith("5")) {
    return LOGIN_ERRORS.SERVER_ERROR;
  } else {
    return safeError(response.body);
  }
};

const classicLogin = async (variables: AuthLogin) => {
  const res = await mutation(
    "login",
    {
      username: {
        type: "String",
        value: variables.username
      },
      password: {
        type: "String",
        value: variables.password
      }
    },
    "userId, mobile, token, authId, salonIds, roles"
  );
  const body = await res.json();
  if (res.ok) {
    try {
      const authInfos = await loginSchema.validate(body.data.login);
      return Promise.resolve(authInfos);
    } catch (error) {
      console.error({ error });
    }
  }
  return Promise.reject(loginError(res));
};

const getAuthCache = () => {
  return getFromCache("auth");
};

export { classicLogin, getAuthCache };
