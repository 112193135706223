import React, { useState } from "react";
import DesktopModalContainer from "../modal/Container/Container.desktop_old";
import DesktopModalHeader from "../modal/Header/Header.desktop";
import ButtonFab from "../button/Fab";
import ButtonText from "../button/Text";
import { Hairdresser } from "../../reducers/salon";

interface UnavailableSlotModalProps {
  type: "NO_SLOT" | "SLOT_TAKEN";
  hairdresser: Hairdresser;
  isOpened: boolean;
  onClose(): void;
  onSubmit(): void;
}

function UnavailableSlotModal(props: UnavailableSlotModalProps) {
  const { type, hairdresser, isOpened, onClose, onSubmit } = props;
  const message =
    type === "NO_SLOT"
      ? `${hairdresser.firstName} n'a pas de créneau ouvert à l'heure de ce rendez vous`
      : `${hairdresser.firstName} a déjà un rendez vous sur ce créneau horaire. Nous ne pouvons pas lui attribuer un deuxième rendez vous`;
  const [isLoading, setIsLoading] = useState(false);
  const submit = () => {
    setIsLoading(true);
    onSubmit();
  };
  return (
    <DesktopModalContainer open={isOpened}>
      <React.Fragment>
        <DesktopModalHeader onClose={onClose} title="Attention" />
        <div className="modal-confirmation-message">{message}</div>
        {type === "NO_SLOT" && (
          <ButtonFab onClick={submit} isLoading={isLoading}>
            Confirmer
          </ButtonFab>
        )}
        <ButtonText onClick={onClose}>Annuler</ButtonText>
      </React.Fragment>
    </DesktopModalContainer>
  );
}

export default UnavailableSlotModal;
