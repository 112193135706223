import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconVisibilityON from "@material-ui/icons/Visibility";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { ACCESS_TYPES } from "constants/Account";
import { useForm } from "hooks/useForm";
import { ACCOUNT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import { Access, Salon, PartnerRef } from "reducers/salon";
import { mergeEventToForm } from "utils/form";
import ResponsiveModalContent from "../../modal/Content/ResponsiveContent";
import TextInput from "../../inputs/Text";
import RemoveIconWithCircle from "../../svgs/RemoveIconWithCircle";
import AddIconWithCircle from "../../svgs/AddIconWithCircle";
import AddressAutocomplete from "../../inputs/AddressAutocomplete";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import MobilePageHeader from "../../page/Header.mobile";
import CloseIcon from "../../svgs/CloseIcon";
import ButtonFab from "../../button/Fab";
import Mobile from "../../ResponsiveLayout/Mobile";
import MenuDesktop from "../../Menu/Desktop/Menu.desktop";
import Desktop from "../../ResponsiveLayout/Desktop";
import DesktopLayoutWithSidebarLinks from "../../layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import styles from "./SalonPage.module.scss";
import useResponsiveLayout from "../../ResponsiveLayout/useResponsiveLayout";
import MobilePageContainer from "../../page/Container.mobile";
import AccessSelector from "../AccessSelector/AccessSelector";
import SalonPicturesList from "./SalonPicturesList/SalonPicturesList";
import { getSalonLink } from "utils/route";
import { MESSAGE_TYPES, COPY_PASTE } from "constants/SnackBar";
import { pushMessage } from "actions/SnackbarActions";
import PartnerRefs from "components/package/PackagePartnerRef/PartnerRefs";
import useCurrentUser from "hooks/useCurrentUser";
import { isIFrame } from "utils/helpers";
import IFrameContainer from "components/iframe/IFrameContainer";
import { isSalonSaas } from "utils/saas";

interface AccountSalonPageFormData {
  name: string;
  description: string;
  address: {
    address: string;
    city: string;
    zipCode: string;
  };
  geoLocation: {
    lat: number;
    lng: number;
  };
  recommendation: string;
  access: Array<Access>;
  id: number | null;
  backgrounds: Array<string>;
  carouselImages: Array<string>;
  partnerRefs: Array<PartnerRef>;
  logo: string;
  allowPaymentInSalon: boolean;
}

interface AccountSalonPageProps {
  formData: AccountSalonPageFormData;
  updateFormData(event: React.ChangeEvent<any>): void;
  addAccess(data: Access): void;
  removeAccessByIndex(index: number): void;
  onClose(): void;
  onSubmit(values: any): Promise<any>;
  salon: Salon;
  updatePartnerRefFormData(name: string, id: string): void;
}

const nullAccess: Access = {
  type: ACCESS_TYPES.METRO,
  name: "",
};

const AccountSalonPage: React.FC<AccountSalonPageProps> = ({
  onClose,
  formData,
  updateFormData,
  addAccess,
  removeAccessByIndex,
  onSubmit,
  salon,
  updatePartnerRefFormData,
}) => {
  const dispatch = useDispatch();
  const [accessToAdd, setAccessToAdd] = useState<Access>(nullAccess);
  const [accessToAddError, setAccessToAddError] = useState<string>("");

  const updateAccessToAdd = useCallback(
    (event: React.ChangeEvent<any>) => {
      event.persist && event.persist();
      setAccessToAdd((oldFormData) => mergeEventToForm(event, oldFormData));
    },
    [setAccessToAdd]
  );

  const validate = useCallback((values: any) => {
    const fields = ["name"];
    const errors: any = fields.reduce((acc, field: string) => {
      const fieldValue = values[field];
      if (fieldValue === undefined || fieldValue === "") {
        return {
          ...acc,
          [field]: "Ce champs est requis",
        };
      }

      return acc;
    }, {});

    return errors;
  }, []);

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    isSubmitting,
  } = useForm({
    initialValues: formData,
    onSubmit,
    validate,
  });

  const { isDesktop } = useResponsiveLayout();

  const { isAdmin } = useCurrentUser();

  const salonIsFromSaas = isSalonSaas(salon);

  const submitButton = (
    <ButtonFab onClick={handleSubmit} isLoading={isSubmitting}>
      Enregistrer les modifications
    </ButtonFab>
  );

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        dispatch(pushMessage(COPY_PASTE.SUCCESS, MESSAGE_TYPES.SUCCESS) as any);
      },
      () => {
        dispatch(pushMessage(COPY_PASTE.ERROR, MESSAGE_TYPES.ERROR) as any);
      }
    );
  };
  const salonLink = getSalonLink(salon.slug, salon?.address?.city, false);
  const salonLinkOldClient = getSalonLink(
    salon.slug,
    salon?.address?.city,
    true
  );

  const salonOldLink = (
    <span className={styles.green}>
      <a className={styles.bigLink} href={salonLinkOldClient} target="_blank">
        Lien de l'établissement Client fidélisé
        <br />
        <span className={styles.smallLink}>{salonLinkOldClient}</span>
      </a>
      <span
        className={styles.copy}
        onClick={() => copyText(salonLinkOldClient)}
      >
        Copier
      </span>
    </span>
  );

  const content = (
    <ResponsiveModalContent
      desktopProps={{ classes: { container: styles.desktopContentContainer } }}
    >
      <Mobile>
        <div className={styles.preview}>{salonOldLink}</div>
      </Mobile>
      <Desktop>
        <div className={styles.header}>
          <span className={styles.title}>L'établissement</span>
          <div className={styles.preview}>
            <a href={salonLink} target="_blank">
              <IconVisibilityON color="secondary" className={styles.eyeLeft} />
              <span className={styles.middleGreen}>
                Visualiser ma page établissement
              </span>
            </a>
            <br />
            <br />
            {salonOldLink}
          </div>
        </div>
      </Desktop>
      {isAdmin && salon?.thirdPartyPaymentId && (
        <div>
          <a
            className={styles.middleGreen}
            target="_blank"
            href={`https://dashboard.stripe.com/connect/accounts/${salon.thirdPartyPaymentId}`}
          >
            Compte Stripe
          </a>
        </div>
      )}
      {salonIsFromSaas && (
        <div>
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps("allowPaymentInSalon", {
                  checked: formData.allowPaymentInSalon,
                  onChange: updateFormData,
                })}
              />
            }
            classes={{
              label: formData.allowPaymentInSalon ? styles.black : styles.grey,
            }}
            labelPlacement="end"
            label="Autoriser la réservation avec paiement sur place"
          />
          <p className={styles.inSalonExplain}>
            En activant cette option, vous permettez au client de choisir entre
            <strong> pré-payer en ligne sur</strong>{" "}
            <a href="https://www.kiute.fr">Kiute.fr</a> ou{" "}
            <strong>payer directement en salon le jour du Rendez-vous. </strong>
            Nous vous recommandons fortement d’activer cette option :
            <br />
            <br />
            <CheckCircleIcon
              style={{ fontSize: "0.9em" }}
              color="primary"
              className={styles.icon}
            />
            Fini les frais de paiement de ligne ! Vous touchez{" "}
            <strong>100% de la prestation</strong> (sur vos clients fidélisés)
            <br />
            <br />
            <CheckCircleIcon
              style={{ fontSize: "0.9em" }}
              color="primary"
              className={styles.icon}
            />
            Vous aurez plus de nouveaux clients et de rendez-vous grâce à cette
            possibilité pour l’internaute de payer sur place
            <br />
            <br />
            <CheckCircleIcon
              style={{ fontSize: "0.9em" }}
              color="primary"
              className={styles.icon}
            />
            Un SMS de rappel sera gratuitement envoyé au client la veille du RDV
            pour réduire le risque de no-Show (client "pas venu")
          </p>
        </div>
      )}
      <TextInput
        fullWidth
        multiline
        className="input-group"
        label="Nom de l'établissement"
        name="name"
        {...getFieldProps("name", {
          value: formData.name,
          onChange: updateFormData,
        })}
        InputProps={{
          readOnly: true,
        }}
        error={errors.name && touched.name}
        helperText={touched.name && errors.name}
      />
      <TextInput
        fullWidth
        className="input-group"
        multiline
        label="Description"
        name="description"
        {...getFieldProps("description", {
          value: formData.description,
          onChange: updateFormData,
        })}
        InputProps={{
          readOnly: true,
        }}
        error={errors.description && touched.description}
        helperText={touched.description && errors.description}
      />
      <AddressAutocomplete
        fullWidth
        readOnly
        multiline
        className="input-group"
        label="Adresse"
        addressName="address"
        geoLocationName="geoLocation"
        value={formData.address.address}
        onChange={updateFormData}
      />
      <div className="input-group">
        <InputLabel shrink className="input_label">
          Accès
        </InputLabel>
        {formData.access.map((accessItem, accessIndex) => (
          <div key={accessIndex} className="salon-form-access-container">
            <Mobile>
              <RemoveIconWithCircle
                onClick={() => removeAccessByIndex(accessIndex)}
              />
            </Mobile>
            <AccessSelector
              name={`access.${accessIndex}.type`}
              value={accessItem.type}
              onChange={updateFormData}
            />
            <Input
              name={`access.${accessIndex}.name`}
              className="input_input salon-access-name"
              placeholder="Ajouter un accès"
              value={accessItem.name}
              onChange={updateFormData}
              endAdornment={
                isDesktop ? (
                  <RemoveIconWithCircle
                    className="clickable"
                    onClick={() => removeAccessByIndex(accessIndex)}
                  />
                ) : undefined
              }
            />
          </div>
        ))}
        <div className="salon-form-access-container">
          <Mobile>
            <AddIconWithCircle
              className="clickable"
              onClick={() => {
                if (accessToAdd.name.length > 0) {
                  addAccess(accessToAdd);
                  setAccessToAdd(nullAccess);
                } else {
                  setAccessToAddError("Ce champs est requis");
                }
              }}
            />
          </Mobile>
          <AccessSelector
            name="type"
            value={accessToAdd.type}
            onChange={updateAccessToAdd}
          />
          <TextInput
            name="name"
            className="input_input salon-access-name"
            placeholder="Ajouter un accès"
            value={accessToAdd.name}
            onChange={(event) => {
              setAccessToAddError("");
              updateAccessToAdd(event);
            }}
            error={accessToAddError.length > 0}
            helperText={accessToAddError}
            InputProps={{
              endAdornment: isDesktop ? (
                <AddIconWithCircle
                  className="clickable"
                  onClick={() => {
                    if (accessToAdd.name.length > 0) {
                      addAccess(accessToAdd);
                      setAccessToAdd(nullAccess);
                    } else {
                      setAccessToAddError("Ce champs est requis");
                    }
                  }}
                />
              ) : undefined,
            }}
          />
        </div>
      </div>
      <TextInput
        fullWidth
        multiline
        className="input-group"
        label="Référence de l'établissement"
        name="id"
        value={formData.id || ""}
        onChange={updateFormData}
        InputProps={{
          readOnly: true,
        }}
      />
      <SalonPicturesList
        title="Logo de l'établissement"
        type="logo"
        pictures={
          formData.logo
            ? [
                {
                  filename: formData.logo,
                  alt: "salon-logo",
                },
              ]
            : []
        }
        single
      />
      <SalonPicturesList
        title="Photos de l'établissement"
        type="background"
        pictures={
          formData.backgrounds?.map((background, index) => ({
            filename: background,
            alt: `salon-background-${index}`,
          })) || []
        }
      />
      <SalonPicturesList
        title="Photos des réalisations"
        type="carousel"
        pictures={
          formData.carouselImages?.map((carousel, index) => ({
            filename: carousel,
            alt: `salon-carousel-${index}`,
          })) || []
        }
      />
      {isAdmin && (
        <div
          className="package-partner-form-container"
          style={{ marginTop: "10px" }}
        >
          <div>
            {formData?.partnerRefs?.length === 0 &&
              "Aucun code partenaire pour cet établissement"}
            <PartnerRefs
              currentRefs={formData.partnerRefs}
              updateSingleRef={updatePartnerRefFormData}
            />
          </div>
        </div>
      )}
      <Desktop>{submitButton}</Desktop>
    </ResponsiveModalContent>
  );

  return (
    <>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={isIFrame() ? undefined : <CloseIcon onClick={onClose} />}
                center="L'établissement"
                right={
                  <a href={salonLink} target="_blank">
                    <IconVisibilityON color="secondary" />
                  </a>
                }
                parent="account"
              />
            }
            body={content}
            footer={submitButton}
          />
        </MobilePageContainer>
      </Mobile>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
            showSynchroSaas={true}
          >
            <div className={styles.desktopContainer}>{content}</div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
    </>
  );
};

export default AccountSalonPage;
