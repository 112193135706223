import React, { useEffect, useRef } from "react";
import { Hairdresser } from "reducers/salon";
import RatingList from "../RatingList/RatingList";
import { Rating, RatingStats } from "reducers/rating";
import styles from "./HairdressersTab.module.scss";
import HairdresserSelector from "../HaidresserSelector/HaidresserSelector";
import { isIFrame } from "utils/helpers";
import classnames from "classnames";

interface HairdressersRatingsProps {
  selectedHaidresser: Hairdresser;
  ratings: Array<Rating>;
  onChange(hairdresser: Hairdresser): void;
  fetchNextRatings(): Promise<any>;
  ratingsStatsById: Record<number, RatingStats>;
}

function HairdressersRatings(props: HairdressersRatingsProps) {
  const {
    selectedHaidresser,
    ratings,
    onChange,
    fetchNextRatings,
    ratingsStatsById,
  } = props;
  const hairdresserTitle = selectedHaidresser
    ? `${selectedHaidresser.firstName || ""} ${
        selectedHaidresser.lastName || ""
      }`
    : "";

  const ratingsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ratingsContainerRef.current) {
      ratingsContainerRef.current.scrollTop = 0;
    }
  }, [selectedHaidresser]);
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerIframe]: isIFrame(),
      })}
    >
      <div className={styles.selectorContainer}>
        <HairdresserSelector
          selectedHaidresser={selectedHaidresser}
          onChange={onChange}
        />
      </div>
      <div
        ref={ratingsContainerRef}
        className={classnames(styles.ratingsContainer, {
          [styles.ratingsContainerIframe]: isIFrame(),
        })}
      >
        {(selectedHaidresser.id || Number(selectedHaidresser.stringId)) && (
          <RatingList
            type="HAIRDRESSER"
            ratings={ratings}
            title={hairdresserTitle}
            infinite
            fetchNextRatings={fetchNextRatings}
            ratingStats={
              ratingsStatsById[
                selectedHaidresser.id || Number(selectedHaidresser.stringId)
              ]
            }
          />
        )}
      </div>
    </div>
  );
}

export default HairdressersRatings;
