import React from "react";
import moment from "moment";
import styles from "./PayoutItem.module.scss";

export interface IPaymentItemProps {
  title: string;
  value: string;
  onClick(): void;
  date: number;
}

function PaymentItem({
  title = "Virement LeCiseau",
  value,
  onClick = () => {},
  date,
}: IPaymentItemProps) {
  return (
    <div className={styles.container} onClick={onClick}>
      <span className={styles.date}>
        {moment(date).year() === moment().year()
          ? moment(date).format("dddd D MMMM")
          : moment(date).format("dddd D MMMM YYYY")}{" "}
        -
      </span>
      <div className={styles.infosContainer}>
        <span className={styles.title}>{title}</span>
      </div>
      <span className={styles.value}>{value}</span>
    </div>
  );
}

PaymentItem.defaultProps = {
  title: "Virement LeCiseau",
  onClick: () => {},
};

export default PaymentItem;
