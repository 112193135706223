export const MESSAGE_TYPES = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  SUCCESS: "SUCCESS",
};

export const ERRORS = {
  UNKNOWN: "Unknown error",
};

export const COPY_PASTE = {
  SUCCESS: "Le lien a bien été copié",
  ERROR: "Impossible de copier le lien",
};
