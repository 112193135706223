import React, { useState } from "react";
import { PartnerRef, Price } from "reducers/salon";
import TextInput from "components/inputs/Text";
import styles from "./PackagePartnerRef.module.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getPartnerRefsAsKeyValue } from "utils/package";
import { Button } from "@material-ui/core";
import PartnerRefs from "./PartnerRefs";

interface PackagePartnerRefProps {
  price: Price;
  updatePartnerRefFormData(
    variant?: string,
    name?: string,
    value?: string
  ): void;
}

function PackagePartnerRef({
  price,
  updatePartnerRefFormData,
}: PackagePartnerRefProps) {
  const [currentRefs, setCurrentRefs] = useState(
    price?.partnerRefs || ([] as Array<PartnerRef>)
  );

  const updateSingleRef = (name: string, id: string) => {
    setCurrentRefs(
      Object.values({
        ...getPartnerRefsAsKeyValue(currentRefs),
        [name]: {
          name,
          id,
        },
      }) as Array<PartnerRef>
    );

    // Update formdata without slowing the render of this deep element
    updatePartnerRefFormData(price?.variant?.name || "empty", name, id);
  };

  return (
    <div>
      {currentRefs.length === 0 && "Aucun code partenaire pour ce prix"}
      <PartnerRefs
        currentRefs={currentRefs}
        updateSingleRef={updateSingleRef}
      />
    </div>
  );
}

export default PackagePartnerRef;
