import React, { useEffect, useCallback } from "react";
import TermAgreementPage from "../components/termAgreement/TermAgreementPage";
import { useHistory } from "react-router";
import { parseRoute } from "../utils/route";
import { routeList } from "../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/AuthActions";
import { setPublic } from "../actions/SalonActions";
import { pushMessage } from "../actions/SnackbarActions";
import { AppState } from "../reducers";
import { MESSAGE_TYPES } from "../constants/SnackBar";

function TermAgreementPageContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedSalonId, salonById } = useSelector(
    (state: AppState) => state.salon.payload
  );

  useEffect(() => {
    if (!selectedSalonId || salonById[selectedSalonId].status !== "CREATED") {
      // No salon selected or doesn't need to accept terms => return to app
      history.push(parseRoute(routeList.AGENDA_PAGE));
    }
  }, []);

  const onValidate = useCallback(() => {
    return ((dispatch(setPublic()) as unknown) as Promise<any>)
      .then(() => history.push(parseRoute(routeList.AGENDA_PAGE)))
      .catch((error: string) => {
        dispatch(pushMessage(error, MESSAGE_TYPES.ERROR));
      });
  }, []);

  const onCancel = useCallback(() => {
    dispatch(logout());
    history.push(parseRoute(routeList.LOGIN_PAGE));
  }, []);
  return <TermAgreementPage onValidate={onValidate} onCancel={onCancel} />;
}

export default TermAgreementPageContainer;
