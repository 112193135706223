import React from "react";
import Collapse from "@material-ui/core/Collapse";
import useToggle from "react-use/lib/useToggle";
import { useSelector } from "react-redux";

import useCurrentSalon from "hooks/useCurrentSalon";
import { AppState } from "reducers";
import { Hairdresser } from "reducers/salon";
import { RatingStats } from "reducers/rating";
import { getHairdresserRatings } from "utils/ratings";
import HairdresserRatingPreview from "../HairdresserRatingPreview/HairdresserRatingPreview";
import styles from "./HaidresserSelector.module.scss";

type HairdresserSelectorProps = Pick<
  HairdresserSelectorTemplateProps,
  "selectedHaidresser" | "onChange"
>;

function HairdresserSelector(props: HairdresserSelectorProps) {
  const { hairdressers } = useCurrentSalon();
  const ratingState = useSelector((state: AppState) => state.rating);
  const hairdressersWithRatings = hairdressers.map((hairdresser) => {
    hairdresser.ratings = getHairdresserRatings(
      hairdresser.id,
      hairdresser.stringId,
      ratingState
    );
    return hairdresser;
  });
  return (
    <HairdresserSelectorTemplate
      {...props}
      hairdressers={hairdressersWithRatings}
      ratingsStatsById={ratingState.payload.stats.byHairdresserId}
    />
  );
}

interface HairdresserSelectorTemplateProps {
  selectedHaidresser: Hairdresser;
  onChange(hairdresser: Hairdresser): void;
  hairdressers: Array<Hairdresser>;
  ratingsStatsById: Record<number, RatingStats>;
}

export function HairdresserSelectorTemplate({
  selectedHaidresser,
  onChange,
  hairdressers,
  ratingsStatsById,
}: HairdresserSelectorTemplateProps) {
  const enabledHairdressers = hairdressers.filter(
    (h) => h.status === "ENABLED"
  );
  const disabledHairdressers = hairdressers.filter(
    (h) => h.status === "DISABLED"
  );
  const [showDisabled, toggleShowDisabled] = useToggle(false);
  const showDisabledTitle = showDisabled
    ? `▲ Cacher les professionnels désactivés`
    : `▼ Afficher les professionnels désactivés`;
  return (
    <div className={styles.outterContainer}>
      <div className={styles.container}>
        {enabledHairdressers.map((hairdresser) => (
          <HairdresserRatingPreview
            key={hairdresser.id || Number(hairdresser.stringId)}
            hairdresser={hairdresser}
            selected={
              hairdresser.id === selectedHaidresser.id ||
              Number(hairdresser.stringId) ===
                Number(selectedHaidresser.stringId)
            }
            onClick={() => onChange(hairdresser)}
            ratingStats={
              ratingsStatsById[hairdresser.id || Number(hairdresser.stringId)]
            }
          />
        ))}
      </div>
      {disabledHairdressers.length > 0 && (
        <>
          <div onClick={toggleShowDisabled} className={styles.toggle}>
            {showDisabledTitle}
          </div>
          <Collapse
            in={showDisabled}
            classes={{ wrapperInner: styles.collapseWrapperInner }}
          >
            {disabledHairdressers.map((hairdresser) => (
              <HairdresserRatingPreview
                key={hairdresser.id || Number(hairdresser.stringId)}
                hairdresser={hairdresser}
                selected={
                  hairdresser.id === selectedHaidresser.id ||
                  Number(hairdresser.stringId) ===
                    Number(selectedHaidresser.stringId)
                }
                onClick={() => onChange(hairdresser)}
                ratingStats={
                  ratingsStatsById[
                    hairdresser.id || Number(hairdresser.stringId)
                  ]
                }
              />
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
}

export default HairdresserSelector;
