import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ImportantInformations } from "../../components/ImportantInformations/ImportantInformations";

import * as salonActions from "../../actions/SalonActions";
import { AppState } from "../../reducers";

function ImportantInformationsContainer() {
  return <ImportantInformations />;
}

function mapState(state: AppState) {
  return {
    salonState: state.salon,
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    salonActionsDispatcher: bindActionCreators(salonActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(ImportantInformationsContainer);
