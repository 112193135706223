import React, { useState, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import { useToggle } from "react-use";

import FullscreenModal from "components/modal/FullscreenModal/FullscreenModal";
import ModalHeader from "components/modal/Header/Header";
import ResponsiveModalContent from "components/modal/Content/ResponsiveContent";
import ButtonFab from "components/button/Fab";
import ModalLayoutMobile from "components/modal/Layout/Layout.mobile";
import Checkbox from "../Checkbox";
import Radio from "../Radio";
import { MobileSelectListItem } from "./Mobile";
import styles from "./styles.module.scss";

interface MobileSelectModalProps {
  opened: boolean;
  title: string;
  multiple?: boolean;
  list: Array<MobileSelectListItem>;
  value: any;
  onClose(): void;
  onSubmit(newValues: Array<any>): Promise<any>;
  confirmationHelperText?: string;
  allowUserInput?: boolean;
  getOptionalsToggleTitle?(showOptionnals: boolean): string;
}

const CUSTOM_INPUT = {
  value: "customInput",
  display: "Autre :"
};

export default function MobileSelectModal({
  multiple,
  opened,
  title,
  list,
  onClose,
  confirmationHelperText,
  allowUserInput,
  value,
  onSubmit,
  getOptionalsToggleTitle
}: MobileSelectModalProps) {
  const [internalValue, setInternalValue] = useState(value);
  const [isLoading, seIsLoading] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [customError, setCustomError] = useState("");

  useEffect(() => {
    setInternalValue(value);
  }, [opened]);

  const hasOptionalItems = list.some(item => item.optional);
  const [showOptionnals, toggleShowOptionnals] = useToggle(false);
  const optionalsToggleTitle =
    getOptionalsToggleTitle && getOptionalsToggleTitle(showOptionnals);

  const mainList = list.filter(item => !Boolean(item.optional));
  const optionalList = list.filter(item => item.optional);

  const displayedList = showOptionnals ? list : mainList;

  const selectAll = () => {
    setInternalValue(displayedList.map(item => item.value));
  };

  const unselectAll = () => {
    setInternalValue([]);
  };

  const toggleAll = (_event: unknown, checked: boolean) => {
    checked ? selectAll() : unselectAll();
  };

  const handleChange = (item: MobileSelectListItem) => (
    _event: unknown,
    checked: boolean
  ) => {
    if (Array.isArray(internalValue)) {
      setInternalValue((oldInternalValue: any) => [
        ...oldInternalValue.filter(
          (itemValue: MobileSelectListItem) => item.value !== itemValue
        ),
        checked ? item.value : null
      ]);
    } else {
      setInternalValue(item.value);
    }
    setCustomError("");
  };

  const submit = () => {
    if (internalValue === CUSTOM_INPUT.value && customInput.length === 0) {
      setCustomError("Veuilliez inscrire une raison");
      return;
    }
    const value =
      internalValue === CUSTOM_INPUT.value
        ? `${CUSTOM_INPUT.display} ${customInput}`
        : multiple
        ? internalValue.filter((value: any) =>
            displayedList.map(item => item.value).includes(value)
          )
        : internalValue;

    seIsLoading(true);
    onSubmit(value).then(() => seIsLoading(false));
  };

  const isChecked = (item: MobileSelectListItem) => {
    if (Array.isArray(internalValue)) {
      return internalValue.includes(item.value);
    }
    return internalValue === item.value;
  };

  const getClassNames = (isSelected: boolean, withUnderline = true) => {
    return classnames("label-checkbox", {
      selected: isSelected,
      "list-underline": withUnderline
    });
  };

  const Control = multiple ? Checkbox : Radio;

  const allSelected = displayedList.every(item => isChecked(item));
  return (
    <FullscreenModal open={opened} direction="left">
      <ModalLayoutMobile
        plainBackground
        header={
          <ModalHeader
            closeIconVariant="previous"
            onClose={onClose}
            title={title}
          />
        }
        body={
          <ResponsiveModalContent extended noBackground>
            <form onSubmit={submit}>
              <FormGroup>
                {multiple && (
                  <FormControlLabel
                    key="all"
                    classes={{
                      label: getClassNames(allSelected)
                    }}
                    control={<Control />}
                    label={`Tous (${displayedList.length})`}
                    checked={allSelected}
                    onChange={toggleAll}
                    value="all"
                  />
                )}
                {mainList.map(item => (
                  <FormControlLabel
                    key={item.value}
                    classes={{
                      label: getClassNames(isChecked(item))
                    }}
                    control={<Control />}
                    label={item.display}
                    checked={isChecked(item)}
                    onChange={handleChange(item)}
                    value={item.value}
                  />
                ))}
                {optionalsToggleTitle && hasOptionalItems && (
                  <>
                    <div
                      onClick={toggleShowOptionnals}
                      className={styles.optionalsTitle}
                    >
                      {optionalsToggleTitle}
                    </div>
                    <Collapse in={showOptionnals}>
                      <div className={styles.optionalsContainer}>
                        {optionalList.map(item => (
                          <FormControlLabel
                            key={item.value}
                            classes={{
                              label: getClassNames(isChecked(item))
                            }}
                            control={<Control />}
                            label={item.display}
                            checked={isChecked(item)}
                            onChange={handleChange(item)}
                            value={item.value}
                          />
                        ))}
                      </div>
                    </Collapse>
                  </>
                )}
                {allowUserInput && (
                  <FormControlLabel
                    key={CUSTOM_INPUT.value}
                    classes={{
                      label: getClassNames(
                        isChecked(CUSTOM_INPUT),
                        !isChecked(CUSTOM_INPUT)
                      )
                    }}
                    control={<Control />}
                    label={
                      <React.Fragment>
                        <span>{CUSTOM_INPUT.display}</span>
                        {isChecked(CUSTOM_INPUT) && (
                          <TextField
                            fullWidth
                            autoFocus
                            type="text"
                            value={customInput}
                            onChange={event =>
                              setCustomInput(event.target.value)
                            }
                            error={customError.length > 0}
                            helperText={customError}
                          />
                        )}
                      </React.Fragment>
                    }
                    checked={isChecked(CUSTOM_INPUT)}
                    onChange={handleChange(CUSTOM_INPUT)}
                  />
                )}
              </FormGroup>
            </form>
          </ResponsiveModalContent>
        }
        footer={
          <React.Fragment>
            <ButtonFab onClick={submit} isLoading={isLoading}>
              Valider
            </ButtonFab>
            {confirmationHelperText && (
              <div className="mobile-select-confirmation-helper">
                {confirmationHelperText}
              </div>
            )}
          </React.Fragment>
        }
      ></ModalLayoutMobile>
    </FullscreenModal>
  );
}
