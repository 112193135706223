import React, { useState } from "react";
import { PartnerRef, Price } from "reducers/salon";
import TextInput from "components/inputs/Text";
import styles from "./PackagePartnerRef.module.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getPartnerRefsAsKeyValue } from "utils/package";
import { Button } from "@material-ui/core";
import { FLEXY_PARTNER_NAME, HAIRNET_PARTNER_NAME } from "constants/Account";

const allowedPartnerRefs = [FLEXY_PARTNER_NAME, HAIRNET_PARTNER_NAME];

interface PartnerRefsProps {
  currentRefs: Array<PartnerRef>;
  updateSingleRef(name?: string, id?: string): void;
}

function PartnerRefs({ currentRefs, updateSingleRef }: PartnerRefsProps) {
  const [selectedPartner, setSelectedPartner] = useState(FLEXY_PARTNER_NAME);
  return (
    <div>
      {currentRefs.map((ref) => (
        <div className={styles.input} key={ref.name}>
          <TextInput
            className="input-group"
            label={ref.name}
            name={ref.name}
            value={ref.id}
            onChange={(e: any) =>
              updateSingleRef(ref?.name || "", e.target.value)
            }
          />
        </div>
      ))}
      <div>
        <Select
          name="newPartnerRef"
          value={selectedPartner}
          onChange={(event: any) =>
            setSelectedPartner(event?.target?.value || "")
          }
        >
          {allowedPartnerRefs.map((ref, index) => (
            <MenuItem key={index} value={ref}>
              {ref}
            </MenuItem>
          ))}
        </Select>
        <Button
          color="primary"
          onClick={() => updateSingleRef(selectedPartner, "")}
        >
          Ajouter un code partenaire
        </Button>
      </div>
    </div>
  );
}

export default PartnerRefs;
