import React from "react";
import { TEALISH } from "../../constants/Style";

const CheckBoxCheckedIconDesktop = ({
  color,
  height = 21,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={height} height={height} viewBox="0 0 21 21" {...props}>
    <path
      d="M18 0a3 3 0 013 3v15a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h15zm-3.945 7.333l-4.821 5.388-2.21-2.315a1 1 0 00-1.447 1.382l3.705 3.879 6.263-7a1 1 0 10-1.49-1.334z"
      fill={color || TEALISH}
      fillRule="nonzero"
    />
  </svg>
);

export default CheckBoxCheckedIconDesktop;
