import React from "react";
import styles from "./RateNumber.module.scss";
import classnames from "classnames";

interface RateNumberProps {
  rate: number;
  classes?: {
    container?: string;
    emphasis?: string;
  };
  noEmphasis?: boolean;
}

const numberFormat = new Intl.NumberFormat(undefined, {
  style: "decimal",
  maximumFractionDigits: 2
});

function RateNumber({ rate, noEmphasis, classes }: RateNumberProps) {
  const rateOrDash = rate <= 0 ? "-" : numberFormat.format(rate);
  return (
    <div className={classnames(styles.container, classes?.container)}>
      <span
        className={classnames(
          {
            [styles.empasis]: !noEmphasis
          },
          classes?.emphasis
        )}
      >
        {rateOrDash}
      </span>
      /5
    </div>
  );
}

export default RateNumber;
