import React, { memo } from "react";
import { useDispatch } from "react-redux";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentSalon from "hooks/useCurrentSalon";
import { synchroSaas } from "utils/agenda";
import styles from "./SynchroSaasButton.module.scss";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { FLEXY_PARTNER_NAME } from "constants/Account";

const SynchroSaasButton = memo(() => {
  const dispatch = useDispatch();
  const { isAdmin } = useCurrentUser();
  const {
    salon: { partnerRefs },
  } = useCurrentSalon();

  const flexyPartnerRef = partnerRefs?.find(
    (pr) => pr.name === FLEXY_PARTNER_NAME
  );

  return isAdmin && flexyPartnerRef ? (
    <div
      className={styles.button}
      onClick={() =>
        synchroSaas(flexyPartnerRef.id || "").then(() =>
          dispatch(
            pushMessage(
              "Agenda en cours de synchronisation",
              MESSAGE_TYPES.SUCCESS
            )
          )
        )
      }
    >
      Synchroniser avec le SaaS
    </div>
  ) : null;
});

export default SynchroSaasButton;
