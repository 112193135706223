import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MobileSelectModal from "./MobileSelectModal";
import { SelectProps } from ".";
import NextIcon from "../../svgs/NextIcon";
import { BLUE_GREY } from "../../../constants/Style";
import classnames from "classnames";
import FakeInput from "../FakeInput";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import ButtonFab from "components/button/Fab";
import styles from "./styles.module.scss";

export type MobileSelectListItem = {
  value: any;
  display: string;
  optional?: boolean;
};

interface MobileSelectInputProps extends SelectProps {
  value: any;
  listTitle: string;
  list: Array<MobileSelectListItem>;
  onChange(event: any): void;
  inline?: boolean;
  multiline?: boolean;
  confirmationHelperText?: string;
  helperText?: string;
  allowUserInput?: boolean;
  getOptionalsToggleTitle?(showOptionnals: boolean): string;
  emptyWarning?: string;
  valueAsDisplay?: boolean;
}

export default function MobileSelectInput({
  onChange,
  multiple,
  fullWidth,
  disabled,
  listTitle,
  list,
  label,
  value,
  name,
  inline,
  multiline,
  confirmationHelperText,
  error,
  helperText,
  allowUserInput,
  getOptionalsToggleTitle,
  emptyWarning,
  valueAsDisplay = true,
}: MobileSelectInputProps) {
  const [listOpened, setListOpened] = useState(false);
  const [subModalOpened, setSubModalOpened] = useState(false);
  const [currentValue, setCurrentValue] = useState([] as Array<any>);

  const openList = () => {
    setListOpened(true);
  };

  const closeList = () => {
    setListOpened(false);
  };

  const triggerOnChange = (newValues: Array<any>) => {
    if (newValues.length === 0 && emptyWarning) {
      setCurrentValue(newValues);
      setSubModalOpened(true);
      return Promise.resolve(true);
    }
    return Promise.all([
      onChange({
        target: {
          value: newValues,
          name,
        },
      } as any),
    ]).then(closeList);
  };

  const submitWarning = () => {
    setSubModalOpened(false);
    return Promise.all([
      onChange({
        target: {
          value: currentValue,
          name,
        },
      } as any),
    ]).then(closeList);
  };

  const getValue = () => {
    if (multiple) {
      return list
        .filter((item) => value.includes(item.value))
        .map((item) => item.display)
        .join(", ");
    }
    const item = list.find((item) => value === item.value);
    return (item && item.display) || (valueAsDisplay && value) || "";
  };

  const iconClassnames = classnames("input_input-icon", {
    inline,
  });
  return (
    <React.Fragment>
      <ResponsiveModalContainer
        fullScreenOnMobile={true}
        open={subModalOpened}
        direction="left"
      >
        <div className={styles.warningDiv}>
          {emptyWarning || ""}
          <div className={styles.warningBtns}>
            <ButtonFab onClick={submitWarning}>Je valide</ButtonFab>
            <ButtonFab onClick={() => setSubModalOpened(false)}>
              Annuler
            </ButtonFab>
          </div>
        </div>
      </ResponsiveModalContainer>
      <div className="input-group">
        <TextField
          variant="standard"
          value={getValue()}
          disabled={disabled}
          fullWidth={fullWidth}
          multiline={multiline || multiple}
          label={label}
          InputLabelProps={{
            shrink: !inline,
            disableAnimation: inline,
            className: inline ? "input_label_inline" : "input_label",
          }}
          InputProps={{
            className: inline ? "input_input_inline" : "input_input",
            disableUnderline: inline,
            inputComponent: FakeInput,
          }}
          onClick={() => !disabled && openList()}
          error={error}
          helperText={helperText}
        />
        {!disabled && (
          <div className={iconClassnames}>
            <NextIcon color={BLUE_GREY} />
          </div>
        )}
      </div>
      <MobileSelectModal
        opened={listOpened}
        multiple={multiple}
        title={listTitle}
        list={list}
        value={value}
        onClose={closeList}
        onSubmit={triggerOnChange}
        confirmationHelperText={confirmationHelperText}
        allowUserInput={allowUserInput}
        getOptionalsToggleTitle={getOptionalsToggleTitle}
      />
    </React.Fragment>
  );
}
