import React from "react";
import ButtonFab from "../../button/Fab";
import ButtonText from "../../button/Text";
import ResponsiveModalContainer from "../Container/ResponsiveContainer";
import DesktopModalHeader from "../Header/Header.desktop";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";
import ModalLayoutDesktop from "../Layout/Layout.desktop";

interface IConfirmationModalProps {
  title: React.ReactNode;
  message: React.ReactNode;
  confirmText: React.ReactNode;
  cancelText: React.ReactNode;
  isOpened: boolean;
  onClose(): void;
  onCancel(): void;
  onConfirm(): void;
  allowCancel?: boolean;
}

function ConfirmationModal({
  title,
  message,
  confirmText,
  cancelText,
  isOpened,
  onClose,
  onCancel,
  onConfirm,
  allowCancel,
}: IConfirmationModalProps) {
  const body = (
    <>
      <div className="modal-confirmation-message">{message}</div>
      <ButtonFab onClick={onConfirm}>{confirmText}</ButtonFab>
      {allowCancel && <ButtonText onClick={onCancel}>{cancelText}</ButtonText>}
    </>
  );
  return (
    <ResponsiveModalContainer open={isOpened}>
      <>
        <Mobile>
          <DesktopModalHeader onClose={onClose} title={title} />
          {body}
        </Mobile>
        <Desktop>
          <ModalLayoutDesktop
            onClose={onClose}
            header={title}
            body={<div style={{ padding: "0 40px" }}>{body}</div>}
          />
        </Desktop>
      </>
    </ResponsiveModalContainer>
  );
}

export default ConfirmationModal;
