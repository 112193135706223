import { Reducer } from "redux";
import * as yup from "yup";
import { ResetAction } from "actions/ResetAction";
import {
  SalonActions,
  SalonFetchInvoicesSuccessAction,
  PackageUpdateSuccessAction,
  HairdresserUpdateSuccessAction,
  BroadcastListUpdateSuccessAction,
  HairdresserCreateSuccessAction,
  FetchStripeCapabilitiesSuccessAction,
} from "actions/SalonActions";
import * as types from "constants/ActionTypes";
import { getSalonCache } from "utils/salon";
import { Rating } from "./rating";
import { type } from "os";

export interface BroadcastList {
  id: number;
  csv: Array<string>;
  bill: Array<string>;
  client: Array<string>;
  report: Array<string>;
  hairdresser: Array<string>;
}

export interface Hairdresser {
  id: number;
  stringId: string;
  salonId: number;
  firstName: string;
  lastName: string;
  gender: string;
  status: string;
  description: string;
  color: string;
  packageIds: Array<number>;
  image?: string;
  ratings?: Array<Rating>;
  partnerRefs: Array<PartnerRef>;
  serviceIds: Array<string>;
  imageUrl?: string;
}

export const hairdresserSchema = yup.object().shape({
  id: yup.number().required(),
  salonId: yup.number().required(),
  firstName: yup.string().ensure(),
  lastName: yup.string().ensure(),
  gender: yup.string().ensure(),
  status: yup.string().ensure(),
  description: yup.string().ensure(),
  color: yup.string().ensure(),
  packageIds: yup.array().of(yup.number()),
  image: yup.string().nullable().notRequired(),
  ratings: yup.array().of(yup.object()).notRequired(),
});
// export type Hairdresser = yup.InferType<typeof hairdresserSchema>;

export interface Invoice {
  id: number;
  slug: string;
  url: string;
  pdfUrl: string;
  createdAt: string;
}

interface Slug {
  locale: string;
  slug: string;
}

export interface StripeCapabilitiesRequirement {
  current_deadline: number;
  currently_due: Array<string>;
  disabled_reason: string;
  eventually_due: Array<string>;
  past_due: Array<string>;
  pending_verification: Array<string>;
}

export interface Category {
  id: number;
  name: string;
  key: string;
  isSearchable: boolean;
  slugs: Array<Slug>;
  image?: string;
  gender: string;
}

export interface PriceVariant {
  id: number;
  name: string;
  key: string;
}

export interface Price {
  variant?: PriceVariant;
  duration: string;
  price: number;
  currency: string;
  packageNumber: string;
  partnerRefs: Array<PartnerRef>;
  stringId?: string;
}

export interface PartnerRef {
  name?: string;
  id?: string;
  companyCode?: string;
}

export interface Package {
  id: number;
  category: Category;
  name: string;
  description: string;
  status: string;
  prices: Array<Price>;
  allowPromo: boolean;
  hairdressersAssigned?: Hairdresser[];
  partnerRefs: Array<PartnerRef>;
}

export interface Access {
  type: string;
  name: string;
}

export interface Location {
  address: {
    address: string;
    city: string;
    zipCode: string;
  };
  geoLocation: {
    lat: number;
    lng: number;
  };
}

export const approvalRequestSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string().required(),
  status: yup.string().required(),
  comment: yup.string().ensure(),
  rejectionReason: yup.string().ensure(),
  dateStatusUpdated: yup.string().required(),
  createdAt: yup.string().required(),
  updatedAt: yup.string().required(),
  payload: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        type: yup.string().required(),
        status: yup.string().required(),
        rejectionReason: yup.string().ensure(),
        variables: yup.object().required(),
      })
    ),
});

export type ApprovalRequest = yup.InferType<typeof approvalRequestSchema>;

export interface Salon extends Location {
  id: number;
  slug: string;
  name: string;
  status: string;
  description: string;
  email: string;
  phone: string;
  mobile: string;
  logo: string;
  background: string;
  backgrounds: Array<string>;
  adwordsCampaignId: string;
  companyName: string;
  billingFirstName: string;
  billingLastName: string;
  billingNationality: string;
  billingCountry: string;
  siret: string;
  iban: string;
  tvaNumber: string;
  recommendation: string;
  carouselImages: Array<string>;
  access: Array<Access>;
  billingAddress: {
    address: string;
    city: string;
    zipCode: string;
  };
  hairdresserById: Record<number, Hairdresser>;
  invoices: Array<Invoice>;
  packages: Array<Package>;
  waitingApprovalRequests: Array<ApprovalRequest>;
  broadcastList: BroadcastList;
  capabilities: CapabilitiesPayload;
  thirdPartyPaymentId: string;
  partnerRefs: Array<PartnerRef>;
  allowPaymentInSalon: boolean;
  options?: {
    autoCalendar: boolean;
    oneCatalogV2: boolean;
  };
  isOneCatalog: boolean;
}

export interface SalonPayload {
  salonById: Record<number, Salon>;
  selectedSalonId?: number;
}

export interface CapabilitiesPayload {
  status: string;
  requirements: StripeCapabilitiesRequirement;
}

export interface SalonState {
  loading: boolean;
  payload: SalonPayload;
  error: string | null;
}

const fetchInvoicesSuccessReducer = (
  state: SalonState,
  action: SalonFetchInvoicesSuccessAction
): SalonState => {
  const { salonId, invoices } = action.payload;

  const newPayload = {
    ...state.payload,
    salonById: {
      ...state.payload.salonById,
      [salonId]: {
        ...state.payload.salonById[salonId],
        invoices,
      },
    },
  };

  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

const updateHairdresserSuccessReducer = (
  state: SalonState,
  action: HairdresserUpdateSuccessAction
) => {
  const updatedHairdresser = action.payload;

  const newPayload = {
    ...state.payload,
    salonById: {
      ...state.payload.salonById,
      [updatedHairdresser.salonId]: {
        ...state.payload.salonById[updatedHairdresser.salonId],
        hairdresserById: {
          ...state.payload.salonById[updatedHairdresser.salonId]
            .hairdresserById,
          [updatedHairdresser.id]: updatedHairdresser,
        },
      },
    },
  };

  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

const updateBroadcastListSuccessReducer = (
  state: SalonState,
  action: BroadcastListUpdateSuccessAction
) => {
  const salonId = action.payload.salon.id;

  const newPayload = {
    ...state.payload,
    salonById: {
      ...state.payload.salonById,
      [salonId]: {
        ...state.payload.salonById[salonId],
        broadcastList: action.payload,
      },
    },
  };

  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

const createHairdresserSuccessReducer = (
  state: SalonState,
  action: HairdresserCreateSuccessAction
) => {
  const hairdresser = action.payload;
  const { salonId } = hairdresser;

  const newPayload = {
    ...state.payload,
    salonById: {
      ...state.payload.salonById,
      [salonId]: {
        ...state.payload.salonById[salonId],
        hairdresserById: {
          ...state.payload.salonById[salonId].hairdresserById,
          [hairdresser.id]: {
            ...hairdresser,
          },
        },
      },
    },
  };

  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

const updatePackageSuccessReducer = (
  state: SalonState,
  action: PackageUpdateSuccessAction
) => {
  const { salonId, pack, hairdressers } = action.payload;

  let newPayload = {
    ...state.payload,
    salonById: {
      ...state.payload.salonById,
      [salonId]: {
        ...state.payload.salonById[salonId],
        packages: state.payload.salonById[salonId].packages.map((p) =>
          p.id === pack.id ? { ...p, ...pack } : p
        ),
      },
    },
  };

  if (hairdressers) {
    const updatedHairdressersById: {
      [id: number]: Hairdresser;
    } = hairdressers.reduce(
      (hairdresserById, hairdresser) => ({
        ...hairdresserById,
        [hairdresser.id]: hairdresser,
      }),
      {}
    );

    const newHairdressersById: {
      [id: number]: Hairdresser;
    } = Object.values(state.payload.salonById[salonId].hairdresserById).reduce(
      (hairdresserById, hairdresser) => ({
        ...hairdresserById,
        [hairdresser.id]: {
          ...hairdresser,
          ...(updatedHairdressersById[hairdresser.id] || {}),
        },
      }),
      {}
    );

    newPayload.salonById[salonId].hairdresserById = newHairdressersById;
  }

  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

const updateSalonCapabilities = (
  state: SalonState,
  action: FetchStripeCapabilitiesSuccessAction
) => {
  // At this point salon is already selected
  const salonId: any = state.payload.selectedSalonId;
  const newPayload = {
    ...state.payload,
    salonById: {
      [salonId]: {
        ...state.payload.salonById[salonId],
        capabilities: action.payload,
      },
    },
  };
  localStorage.setItem("salon", JSON.stringify(newPayload));
  return {
    ...state,
    loading: false,
    payload: newPayload,
    error: null,
  };
};

// Because of the dataset Record<number, Salon> top level of destructuration does not work properly
const deepSalonDestructuration = (
  stateOne: Record<number, Salon>,
  stateTwo: Record<number, Salon>
) => {
  if (Object.keys(stateOne).length == 0) {
    return stateTwo;
  }
  return Object.values(stateTwo).reduce<Record<number, Salon>>((acc, cur) => {
    if (stateOne[cur.id]) {
      acc[cur.id] = { ...stateOne[cur.id], ...cur };
    } else {
      acc[cur.id] = cur;
    }
    return acc;
  }, {});
};

const getInitialState = (): SalonState => ({
  loading: false,
  payload: {
    salonById: getSalonCache().salonById || {},
    selectedSalonId: getSalonCache().selectedSalonId,
  },
  error: null,
});

const salon: Reducer<SalonState, SalonActions | ResetAction> = (
  state = getInitialState(),
  action
) => {
  let newPayload;
  switch (action.type) {
    case types.FETCH_SALONS:
    case types.UPDATE_SALON:
    case types.FETCH_SALON_STRIPE_CAPABILITIES:
    case types.UPDATE_HAIRDRESSER:
    case types.FETCH_INVOICES:
    case types.FETCH_APPROVAL_REQUESTS:
    case types.UPDATE_PACKAGE:
    case types.UPDATE_BROADCAST_LIST:
    case types.CREATE_HAIRDRESSER:
    case types.SET_PUBLIC_SALON:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.FETCH_SALONS_FAILURE:
    case types.UPDATE_SALON_FAILURE:
    case types.UPDATE_HAIRDRESSER_FAILURE:
    case types.FETCH_INVOICES_FAILURE:
    case types.UPDATE_PACKAGE_FAILURE:
    case types.UPDATE_BROADCAST_LIST_FAILURE:
    case types.CREATE_HAIRDRESSER_FAILURE:
    case types.SET_PUBLIC_SALON_FAILURE:
    case types.FETCH_APPROVAL_REQUESTS_FAILURE:
    case types.FETCH_SALON_STRIPE_CAPABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_SALONS_SUCCESS:
      newPayload = {
        salonById: deepSalonDestructuration(
          state.payload.salonById,
          action.payload.salonById
        ),
        selectedSalonId:
          action.payload.selectedSalonId || state.payload.selectedSalonId,
      };
      if (!newPayload.selectedSalonId) {
        const selectedSalonId = Object.keys(newPayload.salonById)[0];
        newPayload.selectedSalonId =
          selectedSalonId === undefined
            ? selectedSalonId
            : parseInt(selectedSalonId);
      }
      localStorage.setItem("salon", JSON.stringify(newPayload));
      return {
        ...state,
        loading: false,
        payload: newPayload,
        error: null,
      };

    case types.UPDATE_SALON_SUCCESS:
      newPayload = {
        ...state.payload,
        salonById: {
          ...state.payload.salonById,
          [action.payload.id]: {
            ...state.payload.salonById[action.payload.id],
            ...action.payload,
          },
        },
      };
      localStorage.setItem("salon", JSON.stringify(newPayload));
      return {
        ...state,
        loading: false,
        payload: newPayload,
        error: null,
      };

    case types.SET_PUBLIC_SALON_SUCCESS:
      newPayload = {
        ...state.payload,
        salonById: {
          ...state.payload.salonById,
          [action.payload.id]: {
            ...state.payload.salonById[action.payload.id],
            status: "PUBLIC",
          },
        },
      };
      localStorage.setItem("salon", JSON.stringify(newPayload));
      return {
        ...state,
        loading: false,
        payload: newPayload,
        error: null,
      };

    case types.FETCH_APPROVAL_REQUESTS_SUCCESS:
      const waitingApprovalRequests = action.payload.approvalRequests.filter(
        (request) => request.status === "WAITING"
      );
      const payloadType = action.payload.type;
      newPayload = {
        ...state.payload,
        salonById: {
          ...state.payload.salonById,
          [action.payload.salonId]: {
            ...state.payload.salonById[action.payload.salonId],
            waitingApprovalRequests: payloadType
              ? [
                  ...waitingApprovalRequests.filter(
                    (request) => request.type === payloadType
                  ),
                  ...state.payload.salonById[
                    action.payload.salonId
                  ].waitingApprovalRequests.filter(
                    (request) => request.type !== payloadType
                  ),
                ]
              : waitingApprovalRequests,
          },
        },
      };
      localStorage.setItem("salon", JSON.stringify(newPayload));
      return {
        ...state,
        loading: false,
        payload: newPayload,
        error: null,
      };

    case types.CREATE_HAIRDRESSER_SUCCESS:
      return createHairdresserSuccessReducer(state, action);

    case types.UPDATE_HAIRDRESSER_SUCCESS:
      return updateHairdresserSuccessReducer(state, action);

    case types.FETCH_INVOICES_SUCCESS:
      return fetchInvoicesSuccessReducer(state, action);

    case types.UPDATE_PACKAGE_SUCCESS:
      return updatePackageSuccessReducer(state, action);

    case types.UPDATE_BROADCAST_LIST_SUCCESS:
      return updateBroadcastListSuccessReducer(state, action);

    case types.FETCH_SALON_STRIPE_CAPABILITIES_SUCCESS:
      return updateSalonCapabilities(state, action);

    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem("salon", "");
      return getInitialState();
    default:
      return state;
  }
};

export default salon;
