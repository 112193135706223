import React from "react";
import MaterialRadio, { RadioProps } from "@material-ui/core/Radio";
import RadioIcon from "../svgs/RadioIcon";
import RadioCheckedIcon from "../svgs/RadioCheckedIcon";

const Radio = (props: RadioProps) => (
  <MaterialRadio
    icon={<RadioIcon />}
    checkedIcon={<RadioCheckedIcon />}
    {...props}
  />
);

export default Radio;
