import { Reducer } from "redux";
import * as types from "../constants/ActionTypes";
import { SnackbarActions } from "../actions/SnackbarActions";

const CAN_QUEUE_MESSAGES = false;

export type SnackbarMessage = {
  id: number;
  type: string;
  text: string;
  onClose(): void;
  timeoutHandle: number;
};

export interface SnackbarState {
  messages: Array<SnackbarMessage>;
}

const initialState: SnackbarState = {
  messages: []
};

const resetTimeout = (
  handle: number,
  callback: { (): void },
  delay = 0
): number => {
  clearTimeout(handle);
  return (setTimeout(callback, delay) as unknown) as number;
};

const snackbar: Reducer<SnackbarState, SnackbarActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.PUSH_MESSAGE:
      if (CAN_QUEUE_MESSAGES) {
        return {
          messages: [...state.messages, action.payload]
        };
      }
      if (state.messages.length > 0) return state;
      return { messages: [action.payload] };
    case types.CLOSE_MESSAGE:
      const newMessages = state.messages.slice();
      newMessages.splice(
        newMessages.findIndex(m => m.id === action.payload),
        1
      );
      if (newMessages[0]) {
        newMessages[0].timeoutHandle = resetTimeout(
          newMessages[0].timeoutHandle,
          newMessages[0].onClose,
          2500
        );
      }
      return {
        messages: newMessages
      };
    default:
      return state;
  }
};

export default snackbar;
