/* eslint-disable */

/**
 * AppsFlyer Smart Banners Snippet
 * https://support.appsflyer.com/hc/en-us/articles/360000764837-Smart-banners#smart-banner-sdk
 */

export function showSmartBanner() {
  AF.Banner.showBanner({ bannerContainerQuery: "#root" });
}
