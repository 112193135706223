import React, { useState } from "react";
import useToggle from "react-use/lib/useToggle";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import classnames from "classnames";
import moment from "moment";

import DateInput from "components/inputs/Date";
import ModalHeader from "components/modal/Header/Header";
import ResponsiveModalContent from "components/modal/Content/ResponsiveContent";
import ModalFooter from "components/modal/Footer/Footer";
import MobileSelect from "components/inputs/Select/Mobile";
import ButtonText from "components/button/Text";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import Select from "components/inputs/Select";
import Radio from "components/inputs/Radio";
import { Booking } from "reducers/booking";
import { Hairdresser } from "reducers/salon";
import BookingSnapshotPackage from "../BookingSnapshotPackage";
import BookingPaymentType from "../BookingPaymentType";
import BookingClientInfos from "./BookingClientInfos/BookingClientInfos";
import { BookingFormDetails } from "./BookingFormDetails";
import styles from "./BookingForm.module.scss";
import { hairdresserToInput } from "components/agenda/FiltersSelector/utils";

const defaultDateFormat = "dddd D MMMM";
const defaultTimeFormat = "[à] H[h]mm";
const defaultCalendarFormat = `${defaultDateFormat} ${defaultTimeFormat}`;
const CalendarFormat = {
  lastWeek: defaultCalendarFormat,
  lastDay: `[Hier] ${defaultTimeFormat}`,
  sameDay: `[Aujourd'hui] ${defaultTimeFormat}`,
  nextDay: `[Demain] ${defaultTimeFormat}`,
  nextWeek: defaultCalendarFormat,
  sameElse: defaultCalendarFormat,
};

interface BookingFormProps {
  bookingToEdit?: Booking;
  hairdressers: Array<Hairdresser>;
  formValues: {
    hairdresserId?: number;
    hairdresserStringId?: string;
  };
  isCancelable: boolean;
  onClose(): void;
  onCancelRequest(): void;
  onChangeHairdresser(event: any): void;
  withDetails?: boolean;
}

const title = "Modifier le rendez-vous";

function BookingForm({
  bookingToEdit,
  hairdressers,
  formValues,
  isCancelable,
  onClose,
  onChangeHairdresser,
  onCancelRequest,
  withDetails,
}: BookingFormProps) {
  const startDate = moment(bookingToEdit && bookingToEdit.dateStart);
  const enabledHairdressers = hairdressers.filter(
    (hairdresser) => hairdresser.status === "ENABLED"
  );
  const disabledHairdressers = hairdressers.filter(
    (hairdresser) => hairdresser.status === "DISABLED"
  );
  const [showDisabled, toggleShowDisabled] = useToggle(false);
  const showDisabledTitle = showDisabled
    ? `▲ Cacher les professionnels désactivés`
    : `▼ Afficher les professionnels désactivés`;
  const disabledTogglerId = "disabledToggler";
  const onChangeDesktopHairdresser = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    if ((child as any)?.props?.id === disabledTogglerId) {
      toggleShowDisabled();
    } else {
      onChangeHairdresser(event as React.ChangeEvent<HTMLInputElement>);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
  };
  const onCloseDesktopHairdresserSelect = (event: React.ChangeEvent<{}>) => {
    // Prevent Closing the Select Menu when clicking on disabledToggler
    if ((event.nativeEvent?.target as any)?.id !== disabledTogglerId) {
      close();
    }
  };

  return (
    <React.Fragment>
      <Mobile>
        <ModalHeader onClose={onClose} title={title} />
        <ResponsiveModalContent>
          {bookingToEdit && <BookingClientInfos booking={bookingToEdit} />}
          <MobileSelect
            fullWidth
            label="Choix du professionnel"
            name="hairdresserId"
            value={
              formValues.hairdresserId || Number(formValues.hairdresserStringId)
            }
            confirmationHelperText="Le client sera averti du changement de professionnel par email et sms"
            listTitle="Changer de professionnel"
            list={hairdressers.map(hairdresserToInput)}
            onChange={onChangeHairdresser}
            getOptionalsToggleTitle={(showOptional) =>
              showOptional
                ? `▲ Cacher les professionnels désactivés`
                : `▼ Afficher les professionnels désactivés`
            }
          />
          <DateInput
            fullWidth
            label="Date"
            calendarFormat={CalendarFormat}
            value={startDate.toISOString()}
            disabled
          />
          {bookingToEdit && <BookingSnapshotPackage booking={bookingToEdit} />}
          {bookingToEdit && <BookingPaymentType booking={bookingToEdit} />}
        </ResponsiveModalContent>
        <ModalFooter>
          {isCancelable && (
            <ButtonText onClick={onCancelRequest} color="error">
              Annuler et rembourser le client
            </ButtonText>
          )}
        </ModalFooter>
      </Mobile>
      <Desktop>
        <ModalLayoutDesktop
          onClose={onClose}
          header={title}
          body={
            <Grid
              container
              direction="column"
              justify="space-between"
              className={classnames(styles.desktopContainer, {
                [styles.cancellable]: isCancelable,
                [styles.withDetails]: withDetails,
              })}
            >
              <Box className={styles.clientInfos}>
                {bookingToEdit && (
                  <BookingClientInfos booking={bookingToEdit} />
                )}
              </Box>
              <Grid container>
                <Box flexGrow={1} flexBasis={0}>
                  <Select
                    open={isOpen}
                    onOpen={open}
                    onClose={onCloseDesktopHairdresserSelect}
                    fullWidth
                    label="Choix du professionnel"
                    name="hairdresserId"
                    value={
                      formValues.hairdresserId || formValues.hairdresserStringId
                    }
                    renderValue={(hairdresserId) => {
                      const hairdresser = enabledHairdressers.find(
                        (h) =>
                          (h.stringId !== "" && h.stringId === hairdresserId) ||
                          (h.id && h.id === (hairdresserId as number))
                      );
                      return hairdresser ? hairdresser.firstName : "";
                    }}
                    onChange={onChangeDesktopHairdresser}
                  >
                    {enabledHairdressers.map((hairdresser: Hairdresser) => (
                      <MenuItem
                        key={hairdresser.id || hairdresser.stringId}
                        value={hairdresser.id || hairdresser.stringId}
                      >
                        <Radio
                          checked={
                            (hairdresser.stringId &&
                              hairdresser.stringId !== "" &&
                              hairdresser.stringId ===
                                formValues.hairdresserStringId) ||
                            (hairdresser.id &&
                              hairdresser.id === formValues.hairdresserId) ||
                            false
                          }
                        />
                        <ListItemText style={{ color: hairdresser.color }}>
                          {hairdresser.firstName}
                        </ListItemText>
                      </MenuItem>
                    ))}
                    {disabledHairdressers.length > 0 && (
                      <ListSubheader
                        id={disabledTogglerId}
                        className={styles.toggleDisabledHairdressers}
                      >
                        {showDisabledTitle}
                      </ListSubheader>
                    )}
                    {showDisabled &&
                      disabledHairdressers.map((hairdresser) => (
                        <MenuItem key={hairdresser.id} value={hairdresser.id}>
                          <Radio
                            checked={
                              hairdresser.id === formValues.hairdresserId
                            }
                          />
                          <ListItemText style={{ color: hairdresser.color }}>
                            {hairdresser.firstName}
                          </ListItemText>
                        </MenuItem>
                      ))}
                  </Select>
                  <DateInput
                    fullWidth
                    label="Date"
                    calendarFormat={CalendarFormat}
                    value={startDate.toISOString()}
                    disabled
                  />
                  {bookingToEdit && (
                    <BookingSnapshotPackage booking={bookingToEdit} />
                  )}
                  <Box flexGrow={1} flexBasis={0}>
                    {bookingToEdit && (
                      <BookingPaymentType booking={bookingToEdit} />
                    )}
                  </Box>
                </Box>
                {withDetails && bookingToEdit && (
                  <Box
                    flexGrow={1}
                    flexBasis={0}
                    className={styles.detailsContainer}
                  >
                    <BookingFormDetails booking={bookingToEdit} />
                  </Box>
                )}
              </Grid>
              <Box>
                {isCancelable && (
                  <ButtonText onClick={onCancelRequest} color="error">
                    Annuler et rembourser le client
                  </ButtonText>
                )}
              </Box>
            </Grid>
          }
        />
      </Desktop>
    </React.Fragment>
  );
}

export default BookingForm;
