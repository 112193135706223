import React from "react";
import { LIGHT_GREY } from "../../constants/Style";

const RadioIcon = ({ color, ...props }: { color?: string }) => (
  <svg width={19} height={19} viewBox="0 0 19 19" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-14-93h375v667H-14z" />
      <circle
        cx={8.5}
        cy={8.5}
        r={8.5}
        transform="translate(1 1)"
        stroke={color || LIGHT_GREY}
      />
    </g>
  </svg>
);

export default RadioIcon;
