import React from "react";
import styles from "./IERedirectMessage.module.scss";
import Paper from "@material-ui/core/Paper";

export function IERedirectMessage() {
  return (
    <Paper className={styles.container}>
      LeCiseau Pro ne fonctionne pas sour Internet Explorer. Vous pouvez accéder
      au site via le{" "}
      <a href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a>,{" "}
      <a href="https://www.mozilla.org/fr/firefox/new/">Firefox</a> ou bien Edge
    </Paper>
  );
}
