import React from "react";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";

interface CloseConfirmationModalProps {
  isOpened: boolean;
  onClose(): void;
  onSubmit(): void;
}

const title = "Attention";
const message =
  "Vous êtes sur le point de retourner à l'agenda sans enregistrer vos modifications";
const confirmText = "J'ai compris";
const cancelText = "Annuler";

function CloseConfirmationModal(props: CloseConfirmationModalProps) {
  const { isOpened, onClose, onSubmit } = props;
  return (
    <ConfirmationModal
      title={title}
      message={message}
      confirmText={confirmText}
      cancelText={cancelText}
      isOpened={isOpened}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onSubmit}
      allowCancel={true}
    />
  );
}

export default CloseConfirmationModal;
