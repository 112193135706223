import React from "react";

const PackagesMenuIcon = (props: any) => (
  <svg viewBox="0 0 60 60" height={25}>
    <defs>
      <style>
        {".pack-1{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}"}
      </style>
    </defs>
    <path className="pack-1" d="M10.06 14.34H38.6V51.6H10.06z" />
    <path
      className="pack-1"
      d="M14.28 14.34L42.19 8.4l7.75 36.45-11.35 2.41M14.28 21.03h19.4M14.28 26.65h19.4M14.28 32.27h19.4M14.28 37.88h19.4M14.28 43.5h10.05"
    />
    <circle className="pack-1" cx={33.08} cy={46.03} r={0.76} />
  </svg>
);

export default PackagesMenuIcon;
