export const CHANGE_HAIDRESSER_OK = "OK";

export const CHANGE_HAIDRESSER_GRAPHQL_ERROR = {
  NO_SLOT_OPENED: "No slot available for hairdresser",
};

export const CHANGE_HAIDRESSER_ERRORS = {
  NO_SLOT_OPENED: "NO_SLOT_OPENED",
};

export const DEFAULT_BOOKING_DISCOUNT = 50;
