import React from "react";
import { Package, PriceVariant } from "reducers/salon";
import styles from "./PackagesPricesForm.module.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Variant from "components/uiElements/Variant/Variant";
import { priceFormatter } from "utils/payments";

const defaultVariant: PriceVariant = {
  id: -1,
  key: "",
  name: "Pas de variante",
};

interface PackagesPricesFormProps {
  packages: Package[];
  inputs: Record<string, number | string>;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  classes?: {
    container?: string;
  };
}

export function PackagesPricesForm({
  packages,
  inputs,
  onInputChange,
  classes,
}: PackagesPricesFormProps) {
  return (
    <TableContainer className={classes?.container}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className={styles.titleCol} />
            <TableCell align="right" className={styles.currentPriceCol}>
              Plein Tarif Actuel
            </TableCell>
            <TableCell align="right" className={styles.newPriceCol}>
              Nouveau Plein tarif
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packages.map((pack) => (
            <React.Fragment key={pack.id}>
              <TableRow>
                <TableCell colSpan={4} className={styles.packName}>
                  {pack.name}
                </TableCell>
              </TableRow>
              {pack.prices.map((price, index) => {
                const variant = price.variant || defaultVariant;
                const inputName = `${pack.id}.${index}`;
                return (
                  <TableRow hover key={`${variant}_${price.packageNumber}`}>
                    <TableCell className={styles.padding} />
                    <TableCell>
                      <Variant variant={variant} />
                    </TableCell>
                    <TableCell align="right" className={styles.currentPriceCol}>
                      {priceFormatter.format(price.price)} €
                    </TableCell>
                    <TableCell align="right" className={styles.newPriceCol}>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        name={inputName}
                        InputProps={{ endAdornment: "€" }}
                        inputProps={{ min: 0 }}
                        value={inputs[inputName]}
                        onChange={onInputChange}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
