import React, { ReactNode } from "react";
import styles from "./Layout.module.scss";
import LargeClickable from "../../uiElements/LargeClickable";
import CloseIcon from "../../svgs/CloseIcon";

interface ModalLayoutDesktopProps {
  header?: ReactNode;
  body?: ReactNode;
  onClose?(): void;
}

function ModalLayoutDesktop({
  header,
  body,
  onClose
}: ModalLayoutDesktopProps) {
  return (
    <div className={styles.outerContainer}>
      {onClose && (
        <div className={styles.closeButton}>
          <LargeClickable onClick={onClose}>
            <CloseIcon onClick={onClose} />
          </LargeClickable>
        </div>
      )}
      <div className={styles.container}>
        {header && <div className={styles.header}>{header}</div>}
        {body && (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>{body}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalLayoutDesktop;
