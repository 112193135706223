import React from "react";
import styles from "./PrestationItem.module.scss";

export interface IPrestationItemProps {
  title: string;
  subtitle: string;
  value: string;
  valueAfterCommission: string;
  onClick(): void;
}

function PrestationItem({
  title,
  subtitle,
  value,
  valueAfterCommission,
  onClick = () => {},
}: IPrestationItemProps) {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.infosContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
      <span className={styles.value}>{value}</span>
      <span className={styles.value}>{valueAfterCommission}</span>
    </div>
  );
}

PrestationItem.defaultProps = {
  onClick: () => {},
};

export default PrestationItem;
