import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { SalonState, Hairdresser } from "../../reducers/salon";
import { getHairdressers } from "../../utils/salon";
import * as bookingActions from "../../actions/BookingActions";
import * as slotActions from "../../actions/SlotActions";
import * as snackbarActions from "../../actions/SnackbarActions";
import BookedSlotForm from "../../components/bookings/BookingForm/BookingForm";
import { AppState } from "../../reducers";
import BookingCancellationForm from "./BookingCancellationForm";
import {
  CHANGE_HAIDRESSER_OK,
  CHANGE_HAIDRESSER_ERRORS,
} from "../../constants/Booking";
import UnavailableSlotModal from "../../components/agenda/UnavailableSlotModal";
import moment from "moment";
import { MESSAGE_TYPES } from "../../constants/SnackBar";
import { BookingState, Booking } from "../../reducers/booking";
import ResponsiveModalContainer from "../../components/modal/Container/ResponsiveContainer";
import ConfirmationModal from "../../components/modal/ConfirmationModal/ConfirmationModal";
import useCurrentSalon from "hooks/useCurrentSalon";

interface BookingFormContainerProps {
  onClose(): void;
  bookingToEdit?: Booking;
  salonState: SalonState;
  bookingState: BookingState;
  bookingActionsDispatcher: typeof bookingActions;
  slotActionsDispatcher: typeof slotActions;
  snackbarActionsDispatcher: typeof snackbarActions;
  withDetails?: boolean;
}

const confirmationTitle = "Changement de professionnel";
const confirmationMessage = (
  <>
    Cette reservation va être affectée a un autre professionnel.
    <br />
    Voulez vous confirmer cette action ?
  </>
);
const confirmationButtonText = "Confirmer";
const cancellationButtonText = "Annuler";

function BookinkFormContainer({
  bookingState,
  salonState,
  bookingToEdit,
  slotActionsDispatcher,
  bookingActionsDispatcher,
  onClose,
  snackbarActionsDispatcher,
  withDetails,
}: BookingFormContainerProps) {
  const { companyCode } = useCurrentSalon();
  const [formValues, setFormValues] = useState({
    hairdresserId:
      bookingToEdit && bookingToEdit.snapshotPackages[0].hairdresser.id,
    hairdresserStringId:
      bookingToEdit && bookingToEdit.snapshotPackages[0].hairdresser.stringId,
  });
  const [cancellationFormOpened, setCancellationFormOpened] = useState(false);
  const [unavailableSlotModalOpened, setUnavailableSlotModalOpened] = useState(
    false
  );
  const [unavailabilityType, setUnavailabilityType] = useState<
    "NO_SLOT" | "SLOT_TAKEN"
  >("NO_SLOT");

  const openBookingCancellation = () => setCancellationFormOpened(true);
  const closeBookingCancellation = () => setCancellationFormOpened(false);

  const closeUnavailableSlotModal = () => setUnavailableSlotModalOpened(false);
  const openUnavailableSlotModal = () => {
    setUnavailabilityType(getUnavailabilityType());
    setUnavailableSlotModalOpened(true);
  };

  const cancelUpdate = () => {
    setFormValues({
      hairdresserId:
        bookingToEdit && bookingToEdit.snapshotPackages[0].hairdresser.id,
      hairdresserStringId:
        bookingToEdit && bookingToEdit.snapshotPackages[0].hairdresser.stringId,
    });
    closeUnavailableSlotModal();
  };

  const createSlotThenUpdateHairdresser = () => {
    const { hairdresserId, hairdresserStringId } = formValues;
    bookingToEdit &&
      (hairdresserId || hairdresserStringId) &&
      (slotActionsDispatcher.createSlot({
        ...(hairdresserId && { hairdresserId }),
        ...(hairdresserStringId && { hairdresserStringId }),
        discount: bookingToEdit.discount,
        range: {
          start: bookingToEdit.dateStart,
          end: bookingToEdit.dateEnd,
        },
      }) as any).then(updateBookingHairdresser);
  };

  const updateBookingHairdresser = (hairdresserId: string | number) => {
    const hairdresserStringId =
      typeof hairdresserId === "string"
        ? `${companyCode}|${hairdresserId}`
        : "";
    const hairdresserIntId =
      typeof hairdresserId === "number" ? hairdresserId : 0;
    return (
      bookingToEdit &&
      (bookingActionsDispatcher.changeBookingHairdresser(
        bookingToEdit,
        hairdresserIntId,
        hairdresserStringId
      ) as any)
        .then(({ status }: { status: string }) => {
          switch (status) {
            case CHANGE_HAIDRESSER_OK:
              closeUnavailableSlotModal();
              snackbarActionsDispatcher.pushMessage(
                "Le changement de professionnel a bien été enregistré",
                MESSAGE_TYPES.SUCCESS
              );
              onClose();
              break;
            case CHANGE_HAIDRESSER_ERRORS.NO_SLOT_OPENED:
              openUnavailableSlotModal();
              break;
          }
        })
        .catch((error: string) => {
          console.log({ error });
        })
    );
  };

  const getUnavailabilityType = () => {
    const { hairdresserId, hairdresserStringId } = formValues;
    const allBookings = bookingState.payload.bookingById;
    const overlapingBooking =
      bookingToEdit &&
      Object.values(allBookings)
        .filter(
          (booking) =>
            booking.snapshotPackages[0].hairdresser.id === hairdresserId ||
            booking.snapshotPackages[0].hairdresser.stringId ===
              hairdresserStringId
        )
        .find(
          (booking) =>
            moment(booking.dateStart).isSameOrBefore(
              moment(bookingToEdit.dateStart)
            ) &&
            moment(booking.dateEnd).isSameOrAfter(moment(bookingToEdit.dateEnd))
        );
    return overlapingBooking ? "SLOT_TAKEN" : "NO_SLOT";
  };

  const hairdressers = getHairdressers(salonState);
  const newHaidresser = hairdressers.find(
    (hairdresser) =>
      (formValues.hairdresserId &&
        hairdresser.id === formValues.hairdresserId) ||
      hairdresser.stringId === String(formValues.hairdresserStringId)
  ) as Hairdresser;
  const now = moment();
  const bookingStart = moment(bookingToEdit && bookingToEdit.dateStart);
  const bookingEnd = moment(bookingToEdit && bookingToEdit.dateEnd);
  const isCancelable =
    bookingStart.add(15, "minutes").isBefore(now) &&
    bookingEnd.add(72, "hours").isAfter(now);

  const [newHairdresserId, setNewHairdresserId] = useState<
    null | number | string
  >(null);

  const closeConfirmationModal = () => setNewHairdresserId(null);
  return (
    <React.Fragment>
      <BookedSlotForm
        bookingToEdit={bookingToEdit}
        hairdressers={hairdressers}
        formValues={formValues}
        onClose={onClose}
        isCancelable={isCancelable}
        onCancelRequest={openBookingCancellation}
        onChangeHairdresser={(e) => setNewHairdresserId(e.target.value)}
        withDetails={withDetails}
      />
      {isCancelable && (
        <ResponsiveModalContainer
          open={cancellationFormOpened}
          direction="left"
        >
          <React.Fragment>
            {bookingToEdit && (
              <BookingCancellationForm
                booking={bookingToEdit}
                onClose={closeBookingCancellation}
                onCanceled={onClose}
              />
            )}
          </React.Fragment>
        </ResponsiveModalContainer>
      )}
      <ConfirmationModal
        title={confirmationTitle}
        message={confirmationMessage}
        confirmText={confirmationButtonText}
        cancelText={cancellationButtonText}
        isOpened={newHairdresserId !== null}
        onClose={closeConfirmationModal}
        onCancel={closeConfirmationModal}
        onConfirm={() =>
          newHairdresserId !== null &&
          updateBookingHairdresser(newHairdresserId)
        }
      />
      <UnavailableSlotModal
        type={unavailabilityType}
        hairdresser={newHaidresser}
        isOpened={unavailableSlotModalOpened}
        onClose={cancelUpdate}
        onSubmit={createSlotThenUpdateHairdresser}
      />
    </React.Fragment>
  );
}

function mapState(state: AppState) {
  return {
    salonState: state.salon,
    bookingState: state.booking,
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    bookingActionsDispatcher: bindActionCreators(bookingActions, dispatch),
    slotActionsDispatcher: bindActionCreators(slotActions, dispatch),
    snackbarActionsDispatcher: bindActionCreators(snackbarActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(BookinkFormContainer);
