import React from "react";
import PickerArrow from "../../svgs/PickerArrow";
import { dateInputFormat } from "../../../constants/Input";
import moment from "moment";
import DatePickerMobile from "../../inputs/DatePicker/DatePicker.mobile";

interface CalendarMonthPickerMobileProps {
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const CalendarMonthPickerMobile: React.FC<CalendarMonthPickerMobileProps> = props => {
  const { value, onChange } = props;
  const selectedDay = moment(value);

  const getLabel = (selectedDay: moment.Moment) => {
    return selectedDay.year() === moment().year()
      ? selectedDay.format("MMMM")
      : selectedDay.format("MMMM YYYY");
  };

  return (
    <DatePickerMobile
      noDay
      value={moment(value).format(dateInputFormat)}
      onChange={onChange}
      className="agenda-month-picker"
      renderInput={({ onClick }: { onClick(): void }) => (
        <div onClick={onClick}>
          <label
            className="calendar_month_picker-label-mobile"
            htmlFor="calendar-day"
          >
            {getLabel(selectedDay)}
            <PickerArrow className="calendar_month_picker-arrow" />
          </label>
        </div>
      )}
    />
  );
};

export default CalendarMonthPickerMobile;
