import React from "react";

const isObject = (obj: any) => obj !== null && typeof obj === "object";

function setNestedObjectValues(
  obj: any,
  value: any,
  visited = new WeakMap(),
  response: any = {}
) {
  for (let key of Object.keys(obj)) {
    const val = obj[key];
    if (isObject(val)) {
      if (!visited.get(val)) {
        visited.set(val, true);
        // In order to keep array values consistent for both dot path and
        // bracket syntax, we need to check if this is an array so that
        // this will output  { friends: [true] } and not { friends: { "0": true } }
        response[key] = Array.isArray(val) ? [] : {};
        setNestedObjectValues(val, value, visited, response[key]);
      }
    } else {
      response[key] = value;
    }
  }

  return response;
}

const callAll = (...fns: Array<undefined | ((...args: any) => any)>) => (
  ...args: any
) => fns.forEach(fn => fn && fn(...args));

function reducer(state: any, action: { type: string; payload?: any }) {
  switch (action.type) {
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload
      };
    case "SET_FIELD_VALUE":
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    case "SET_FIELD_TOUCHED":
      return {
        ...state,
        touched: {
          ...state.touched,
          ...action.payload
        }
      };
    case "SUBMIT_ATTEMPT":
      return {
        ...state,
        isSubmitting: true,
        touched: setNestedObjectValues(state.values, true)
      };
    case "SUBMIT_SUCCESS":
      return {
        ...state,
        isSubmitting: false
      };
    case "SUBMIT_FAILURE":
      return {
        ...state,
        isSubmitting: false,
        submitError: action.payload
      };
    case "RESET_VALUES":
      return {
        values: action.payload,
        errors: {},
        touched: {},
        isSubmitting: false
      };
    default:
      return state;
  }
}

interface useFormProps<T> {
  onSubmit(values: T): Promise<any>;
  initialValues: T;
  validate(values: T): any;
}

export function useForm<T>(props: useFormProps<T>) {
  if (!props.onSubmit) {
    throw new Error("You forgot to pass onSubmit to useForm!");
  }

  const [state, dispatch] = React.useReducer(reducer, {
    values: props.initialValues,
    errors: {},
    touched: {},
    isSubmitting: false
  });

  React.useEffect(() => {
    if (props.validate) {
      const errors = props.validate(state.values);
      dispatch({ type: "SET_ERRORS", payload: errors });
    }
  }, [state.values, props.validate]);

  const handleChange = (fieldName: string) => (
    event: React.ChangeEvent<any>
  ) => {
    event.persist && event.persist();
    const {
      target: { type, checked, value }
    } = event;
    const fieldValue = type === "checkbox" ? checked : value;
    dispatch({
      type: "SET_FIELD_VALUE",
      payload: { [fieldName]: fieldValue }
    });
  };

  const handleBlur = (fieldName: string) => (event: any) => {
    dispatch({
      type: "SET_FIELD_TOUCHED",
      payload: { [fieldName]: true }
    });
  };

  const getFieldProps = (
    fieldName: string,
    {
      value,
      name,
      onChange,
      onBlur,
      ...props
    }: {
      value?: any;
      name?: string;
      onChange?(event: React.ChangeEvent<any>): void;
      onBlur?(event: any): void;
    } = {}
  ) => ({
    value: value || state.values[fieldName],
    name: name || fieldName,
    onChange: callAll(onChange, handleChange(fieldName)),
    onBlur: callAll(onBlur, handleBlur(fieldName)),
    ...props
  });

  const getMuiErrorProps = (fieldName: string) => {
    const error = state.errors[fieldName];
    const touched = state.touched[fieldName];
    return {
      error: Boolean(touched && error),
      helperText: touched && error ? error : undefined
    };
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    dispatch({ type: "SUBMIT_ATTEMPT" });
    const errors = props.validate(state.values);
    if (!Object.keys(errors).length) {
      try {
        await props.onSubmit(state.values);
        dispatch({ type: "SUBMIT_SUCCESS" });
      } catch (submitError) {
        dispatch({ type: "SUBMIT_FAILURE", payload: submitError });
      }
    } else {
      dispatch({ type: "SET_ERRORS", payload: errors });
      dispatch({ type: "SUBMIT_FAILURE" });
    }
  };

  const resetValues = (values: T) => {
    dispatch({ type: "RESET_VALUES", payload: values });
  };

  return {
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    getMuiErrorProps,
    resetValues,
    ...state
  };
}
