import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchSalonRatings,
  fetchSalonRatingsStats
} from "actions/RatingActions";
import RatingList from "components/rating/RatingList/RatingList";
import { AppState } from "reducers";
import { Salon } from "reducers/salon";
import { getSalonRatings } from "utils/ratings";
import useCurrentSalon from "hooks/useCurrentSalon";

const ratingStateSelector = (state: AppState) => state.rating;

function SalonRatingsContainer() {
  const { salon } = useCurrentSalon();
  const ratingState = useSelector(ratingStateSelector);
  const salonRatings = getSalonRatings(salon.id, ratingState);
  const salonTitle = salon.name;

  const dispatch = useDispatch();
  const fetchingPage = useRef(1);

  const fetchRatings = (salon: Salon, page = 1) => {
    return (dispatch(fetchSalonRatings(salon, { page })) as unknown) as Promise<
      any
    >;
  };

  useEffect(() => {
    fetchRatings(salon);
    const interval = setInterval(() => fetchRatings(salon), 60000);
    return () => clearInterval(interval);
  }, [salon.id]);

  const fetchNextRatings = () => {
    fetchingPage.current++;
    return fetchRatings(salon, fetchingPage.current);
  };

  useEffect(() => {
    dispatch(fetchSalonRatingsStats(salon));
  }, [salon.id]);

  return (
    <div className="rating-salon-tab-container">
      <RatingList
        type="SALON"
        ratings={salonRatings}
        title={salonTitle}
        titleWithStars
        infinite
        fetchNextRatings={fetchNextRatings}
        ratingStats={ratingState.payload.stats.bySalonId[salon.id]}
      />
    </div>
  );
}

export default SalonRatingsContainer;
