import React, { useEffect, memo } from "react";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import useCurrentSalon from "hooks/useCurrentSalon";
import WeeksPage from "components/account/Weeks/WeeksPage";
import { useHistory } from "react-router-dom";

const AccountWeeksPage = () => {
  const {
    salon,
    hairdressers,
    weeksByHairdresserId,
    fetchCurrentWeeks,
    fetchCurrentAvailabilities,
  } = useCurrentSalon();

  useEffect(() => {
    fetchCurrentWeeks();
  }, []);

  useEffect(() => {
    fetchCurrentWeeks();
  }, [salon]);

  const history = useHistory();

  const { isDesktop } = useResponsiveLayout();
  return (
    <WeeksPage
      onClose={() => history.goBack()}
      salon={salon}
      isDesktop={isDesktop}
      hairdressers={hairdressers.filter(
        (hairdresser) => hairdresser.status === "ENABLED"
      )}
      weeksByHairdresserId={weeksByHairdresserId}
      fetchCurrentWeeks={fetchCurrentWeeks}
      fetchCurrentAvailabilities={fetchCurrentAvailabilities}
    />
  );
};

export default memo(AccountWeeksPage);
