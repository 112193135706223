import { useDispatch } from "react-redux";
import * as PackagesAPI from "api/packages";
import { fetchWaitingApprovalRequests as fetchWaitingApprovalRequestsAction } from "actions/SalonActions";
import * as types from "constants/ActionTypes";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Package, Price } from "reducers/salon";
import { updateHaidresserPackages } from "api/hairdressers";
import { updateSalonPackages, createIncreasePriceLog } from "api/salon";
import { doesHairdresserDoVariant } from "utils/package";

function packagesByCategoryIdReducer(
  dict: Record<number, Package[]>,
  pack: Package
) {
  return {
    ...dict,
    [pack.category.id]: [...(dict[pack.category.id] || []), pack],
  };
}

const covidPrefix = "Kit d'hygiène - ";
const getNewDescription = (description: string, amount: number): string => {
  if (amount < 0) {
    return description.replace(covidPrefix, "");
  }

  return description.indexOf(covidPrefix) === -1
    ? `${covidPrefix}${description}`
    : description;
};

interface PackagesApprovalRequest {
  files: { name: string; data: string }[];
  packages: Partial<Package>[];
}

export function useCurrentSalonPackages() {
  const {
    salon,
    hairdresserById,
    refetchSalon,
    hairdressers,
  } = useCurrentSalon();

  const salonPackages = salon.packages || [];

  const packages =
    salonPackages.map((pck: Package, index: number) => ({
      ...pck,
      id: pck.id || index,
    })) || [];
  const packagesWithHairdressers = packages?.map((pack) => ({
    ...pack,
    hairdressersAssigned: hairdressers.filter(
      (hairdresser) =>
        hairdresser.packageIds?.indexOf(pack.id) !== -1 ||
        doesHairdresserDoVariant(hairdresser.serviceIds, pack.prices)
    ),
  }));
  const packagesByCategoryId =
    packagesWithHairdressers?.reduce<Record<number, Package[]>>(
      packagesByCategoryIdReducer,
      {}
    ) ?? {};
  const packagesGroupedByCategory = Object.values(packagesByCategoryId).map(
    (categoryPackages) => ({
      category: categoryPackages?.[0].category,
      packages: categoryPackages,
    })
  );

  function createPackagesApprovalRequest({
    files,
    packages,
  }: PackagesApprovalRequest) {
    return PackagesAPI.createPackagesApprovalRequest(
      salon.id,
      files,
      packages
    ).then(() => {
      refetchWaitingApprovalRequests();
    });
  }

  async function createPackage(pack: Package, hairdresserIds: number[]) {
    const newPackage = await PackagesAPI.createPackage(pack);

    const packages = salon.packages || [];

    await updateSalonPackages(salon.id, [
      ...packages.map((pack) => pack.id),
      newPackage.id,
    ]);

    await Promise.all(
      hairdresserIds.map((id) => {
        const hairdresser = hairdresserById[id];
        return updateHaidresserPackages(id, [
          ...hairdresser.packageIds,
          newPackage.id,
        ]);
      })
    );

    refetchSalon();
  }

  const dispatch = useDispatch();
  function updatePackageStatus(packageId: number, status: string) {
    return PackagesAPI.updatePackageStatus(packageId, status).then(
      (updatedPackage) =>
        dispatch({
          type: types.UPDATE_PACKAGE_SUCCESS,
          payload: {
            salonId: salon.id,
            pack: updatedPackage,
          },
        })
    );
  }

  function updatePackageAllowPromo(packageId: number, allowPromo: boolean) {
    return PackagesAPI.updatePackageAllowPromo(packageId, allowPromo).then(
      (updatedPackage) =>
        dispatch({
          type: types.UPDATE_PACKAGE_SUCCESS,
          payload: {
            salonId: salon.id,
            pack: updatedPackage,
          },
        })
    );
  }

  function refetchWaitingApprovalRequests() {
    return dispatch(
      fetchWaitingApprovalRequestsAction(salon.id, { type: "PACKAGES" })
    );
  }

  const waitingApprovalRequests = salon.waitingApprovalRequests.filter(
    (request) => request.type === "PACKAGES"
  );

  const updateAllPackagesPrices = async (amount: number, author: string) => {
    const modificationResult = await packages.reduce(
      async (arr: Promise<Number[]>, pack: Package) => {
        const previousValue = await arr;
        const newPrices = pack.prices.map((price) => ({
          ...price,
          price: price.price + amount,
          stringId: undefined,
        }));

        const newDescription = getNewDescription(pack.description, amount);
        const newPackage = {
          prices: newPrices,
          description: newDescription,
        };

        const updatePackage = await PackagesAPI.updatePackagePricesAndDesc(
          pack.id,
          newPackage.prices,
          newPackage.description
        );

        return Promise.resolve([...previousValue, pack.id]);
      },
      Promise.resolve([])
    );

    await createIncreasePriceLog(salon.id, amount, author, modificationResult);
    await refetchSalon();
    return true;
  };

  return {
    packages,
    packagesGroupedByCategory,
    createPackagesApprovalRequest,
    waitingApprovalRequests,
    refetchWaitingApprovalRequests,
    updatePackageStatus,
    updatePackageAllowPromo,
    createPackage,
    updateAllPackagesPrices,
  };
}
