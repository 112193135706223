import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { logout as logoutAction, loginSuccess } from "actions/AuthActions";
import { AppState } from "reducers";
import { AuthPayload, AuthInfos } from "reducers/auth";

function useAuth() {
  const authPayload = useSelector((state: AppState) => state.auth.payload);
  return {
    rawAuthPayload: authPayload,
    getAuthInfos: () => {
      if ("token" in authPayload) {
        return authPayload;
      } else {
        throw new Error(
          "Trying to access authInfos while they are unavailable"
        );
      }
    },
  };
}

const ADMIN = "ADMIN";
const SALON_MANAGER = "SALON_MANAGER";

function authPayloadToUserInfos(authPayload: AuthPayload) {
  if ("token" in authPayload) {
    const { token, roles } = authPayload;
    const isLoggedIn = Boolean(token && token.length > 0);
    const isAdmin = Boolean(isLoggedIn && roles && roles.includes(ADMIN));
    const isSalonManager = Boolean(
      isLoggedIn && roles && roles.includes(SALON_MANAGER)
    );

    return {
      isLoggedIn,
      isAdmin,
      isSalonManager,
    };
  }
  return {
    isLoggedIn: false,
    isAdmin: false,
    isSalonManager: false,
  };
}

function useCurrentUser() {
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    dispatch(logoutAction());
    sessionStorage.clear();
  }, [dispatch]);
  const manualLogin = (payload: AuthInfos) => dispatch(loginSuccess(payload));
  const { rawAuthPayload, getAuthInfos } = useAuth();

  const userInfos = authPayloadToUserInfos(rawAuthPayload);
  return {
    ...userInfos,
    getAuthInfos,
    logout,
    manualLogin,
  };
}

export default useCurrentUser;
