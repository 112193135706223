import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { STORE_VERSION, ThunkDispatch, AppState } from "reducers";

import { RESET } from "constants/ActionTypes";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import { fetchSalons } from "actions/SalonActions";
import useCurrentUser from "./useCurrentUser";

export const STORE_STATE = {
  SAFE: "SAFE" as const,
  UNSAFE: "UNSAFE" as const,
};
type StoreState = keyof typeof STORE_STATE;

/**
 * Ensure minimal necessary data is available
 * and Resets the Redux Store when its layout is updated
 */
export function useEnsureStoreConsistency() {
  const history = useHistory();
  const [salonState, setSalonState] = useState<StoreState>(STORE_STATE.UNSAFE);
  const [isLoading, setIsLoading] = useState(false);
  const [localStoreVersion, setLocalStoreVersion] = useState<string | null>(
    localStorage.getItem("leCiseauStoreVersion")
  );
  const [storeVersionState, setStoreVersionState] = useState<StoreState>(
    STORE_STATE.UNSAFE
  );
  const { isLoggedIn, getAuthInfos } = useCurrentUser();
  const selectedSalonId = useSelector(selectedSalonSelector);
  const dispatch = useDispatch<ThunkDispatch>();
  const { isAdmin } = useCurrentUser();

  useEffect(() => {
    if (isLoggedIn && !selectedSalonId && !isAdmin) {
      if (!isLoading) {
        setSalonState(STORE_STATE.UNSAFE);
        const authInfos = getAuthInfos();
        dispatch(fetchSalons({ auth: authInfos }));
        setIsLoading(true);
      }
    } else {
      setSalonState(STORE_STATE.SAFE);
    }
  }, [isLoggedIn, selectedSalonId, getAuthInfos]);

  useEffect(() => {
    if (localStoreVersion && parseInt(localStoreVersion) === STORE_VERSION) {
      setStoreVersionState(STORE_STATE.SAFE);
    } else {
      dispatch({ type: RESET });
      localStorage.setItem("leCiseauStoreVersion", STORE_VERSION.toString());
      setLocalStoreVersion(STORE_VERSION.toString());
      history.push(parseRoute(routeList.AGENDA_PAGE));
    }
  }, [localStoreVersion]);

  return {
    storeState:
      salonState === STORE_STATE.SAFE && storeVersionState === STORE_STATE.SAFE
        ? STORE_STATE.SAFE
        : STORE_STATE.UNSAFE,
  };
}

function selectedSalonSelector(appState: AppState) {
  return appState.salon.payload.selectedSalonId;
}
