import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useToggle from "react-use/lib/useToggle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment";

import { TURNOVER_PERIOD_TYPES } from "constants/Payment";
import { BLUE_GREY, TEALISH } from "constants/Style";
import PageContainer from "components/page/Container";
import MobilePageHeader from "components/page/Header.mobile";
import PageContent from "components/page/Content";
import PreviousIcon from "components/svgs/PreviousIcon";
import ModalContentMobile from "components/modal/Content/Content.mobile";
import MobileSelect from "components/inputs/Select/Mobile";
import NextIcon from "components/svgs/NextIcon";
import MobileModalContainer from "components/modal/Container/Container.mobile_old";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import PickerArrow from "components/svgs/PickerArrow";
import Radio from "components/inputs/Radio";
import DatePickerDesktop from "components/inputs/DatePicker/DatePicker.desktop";
import { Hairdresser } from "reducers/salon";
import {
  periodToString,
  Prestation,
  TurnoverFilters,
  Period,
  priceFormatter,
} from "utils/payments";
import ListByDate, { ListItem } from "../ListByDate/ListByDate";
import PaymentPageDesktopLayout from "../PaymentPageDesktopLayout/PaymentPageDesktopLayout";
import TurnoverPeriodForm from "../TurnoverPeriodForm";
import PrestationItem from "./PrestationItem/PrestationItem";
import styles from "./TurnoverPageTemplate.module.scss";
import { getPriceCommission } from "utils/payments";
import { isIFrame } from "utils/helpers";

interface TurnoverPageTemplateProps {
  title: string;
  closeLink: string;
  filters: TurnoverFilters;
  hairdressers: Array<Hairdresser>;
  prestations: Array<Prestation>;
  onChangeFilters(
    event: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ): void;
  onPrestationClick(prestation: Prestation): void;
}

const getHairdresserValue = (hairdresser: Hairdresser) =>
  hairdresser.id || Number(hairdresser.stringId);

function TurnoverPageTemplate(props: TurnoverPageTemplateProps) {
  const {
    title,
    closeLink,
    prestations,
    filters,
    hairdressers,
    onChangeFilters,
    onPrestationClick,
  } = props;
  const [isPeriodModalOpened, setIsPeriodModalOpened] = useState(false);
  const turnoverTotal = prestations.reduce(
    (total, prestation) => total + prestation.price,
    0
  );

  const totalCommissionPeriod = prestations.reduce(
    (total, prestation) => total + getPriceCommission(prestation),
    0
  );

  const onItemClick = (item: ListItem) => {
    const prestation = prestations.find((p) => p.id === item.id);
    prestation && onPrestationClick(prestation);
  };

  const prestationsList = (
    <ListByDate
      list={prestations.map((prestation) => ({
        id: prestation.id,
        date: prestation.date,
        title: prestation.clientName,
        subtitle: prestation.prestationName,
        value: `${priceFormatter.format(prestation.price)}€`,
        valueAfterCommission: `${priceFormatter.format(
          getPriceCommission(prestation)
        )}€`,
        booking: prestation.booking,
      }))}
      itemComponent={(item) => (
        <PrestationItem {...item} onClick={() => onItemClick(item)} />
      )}
    />
  );

  return (
    <>
      <Mobile>
        <PageContainer>
          <MobilePageHeader
            left={
              isIFrame() ? undefined : (
                <Link to={closeLink}>
                  <PreviousIcon />
                </Link>
              )
            }
            center={<span>{title}</span>}
            parent="payment"
          />
          <PageContent>
            <>
              <ModalContentMobile dense withChildrenSeparator>
                <MobileSelect
                  fullWidth
                  inline
                  label=""
                  name="hairdresser"
                  value={filters.hairdresser}
                  listTitle="Choix du professionnel"
                  list={[
                    { value: "ALL", display: "Tous les professionnels" },
                    ...hairdressers.map((hairdresser) => ({
                      value: getHairdresserValue(hairdresser),
                      display: hairdresser.firstName,
                      optional: hairdresser.status === "DISABLED",
                    })),
                  ]}
                  getOptionalsToggleTitle={(showDisabled) =>
                    showDisabled
                      ? `▲ Cacher les professionnels désactivés`
                      : `▼ Afficher les professionnels désactivés`
                  }
                  onChange={onChangeFilters}
                />
                <div
                  className="input-group dense-list_item-container-mobile"
                  onClick={() => setIsPeriodModalOpened(true)}
                >
                  <span>Période</span>
                  <div className="dense-list_item-right_container-mobile">
                    <span>{periodToString(filters.period)}</span>
                    <NextIcon color={BLUE_GREY} />
                  </div>
                </div>
                <MobileModalContainer open={isPeriodModalOpened} direction="up">
                  <TurnoverPeriodForm
                    onClose={() => setIsPeriodModalOpened(false)}
                    period={filters.period}
                    onChange={(newPeriod: Period) =>
                      onChangeFilters({
                        target: {
                          value: newPeriod,
                          name: "period",
                        },
                      } as any)
                    }
                  />
                </MobileModalContainer>
              </ModalContentMobile>
              <ModalContentMobile dense extended>
                {prestationsList}
              </ModalContentMobile>
            </>
          </PageContent>
          <div className={styles.topFooterMobile}>
            <span className={styles.CAMargin}>Chiffre d'affaires</span>
            <span>Après commission</span>
          </div>
          <div className={styles.footerMobile}>
            <span>Total sur la période</span>
            <span>{priceFormatter.format(turnoverTotal)}€ TTC</span>
            <span>{priceFormatter.format(totalCommissionPeriod)}€ TTC</span>
          </div>
        </PageContainer>
      </Mobile>
      <Desktop>
        <PaymentPageDesktopLayout>
          <div className={styles.container}>
            <div className={styles.header}>{title}</div>
            <div className={styles.selectHairdresser}>
              <div className={styles.label}>Choix du professionnel :</div>
              <DesktopHairdresserSelector
                hairdressers={hairdressers}
                value={filters.hairdresser}
                onChange={onChangeFilters}
              />
            </div>
            <div className={styles.selectDate}>
              <div className={styles.label}>Période :</div>
              <div className={styles.rangePickersContainer}>
                <span>du</span>
                <DatePickerDesktop
                  value={moment(filters.period.range.from).toISOString()}
                  onChange={(event) =>
                    onChangeFilters({
                      target: {
                        name: "period",
                        value: {
                          type: TURNOVER_PERIOD_TYPES.RANGE,
                          range: {
                            from: event.target.value,
                            to: filters.period.range.to,
                          },
                        },
                      },
                    } as any)
                  }
                />
                <span>au</span>
                <DatePickerDesktop
                  value={moment(filters.period.range.to).toISOString()}
                  onChange={(event) =>
                    onChangeFilters({
                      target: {
                        name: "period",
                        value: {
                          type: TURNOVER_PERIOD_TYPES.RANGE,
                          range: {
                            from: filters.period.range.from,
                            to: event.target.value,
                          },
                        },
                      },
                    } as any)
                  }
                />
              </div>
            </div>
            <div className={styles.prestations}>
              <div className={styles.prestationTop}>
                <span className={styles.smallSpan}></span>
                <span className={styles.smallSpanMargin}>
                  Chiffre d'affaires
                </span>
                <span className={styles.smallSpan}>
                  Montant versé après commission
                  <br /> et frais de paiement
                </span>
              </div>
              <div className={styles.prestationHeader}>
                <span className={styles.flex}>Total sur la période</span>
                <span className={styles.amount}>
                  {priceFormatter.format(turnoverTotal)}€ TTC
                </span>
                <span className={styles.amount}>
                  {priceFormatter.format(totalCommissionPeriod)}€ TTC
                </span>
              </div>
              <div className={styles.prestationsList}>{prestationsList}</div>
            </div>
          </div>
        </PaymentPageDesktopLayout>
      </Desktop>
    </>
  );
}

interface DesktopHairdresserSelectorProps {
  hairdressers: Hairdresser[];
  value: number | string;
  onChange(event: React.ChangeEvent<HTMLInputElement>, checked?: boolean): void;
}

function DesktopHairdresserSelector({
  hairdressers,
  value,
  onChange: onChangeFromProps,
}: DesktopHairdresserSelectorProps) {
  const enabledHairdressers = hairdressers.filter(
    (h) => h.status === "ENABLED"
  );
  const disabledHairdressers = hairdressers.filter(
    (h) => h.status === "DISABLED"
  );

  const [showDisabled, toggleShowDisabled] = useToggle(false);
  const showDisabledTitle = showDisabled
    ? `▲ Cacher les professionnels désactivés`
    : `▼ Afficher les professionnels désactivés`;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
  };

  const disabledTogglerId = "disabledToggler";
  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    if ((child as any)?.props?.id === disabledTogglerId) {
      toggleShowDisabled();
    } else {
      onChangeFromProps(event as React.ChangeEvent<HTMLInputElement>);
    }
  };

  useEffect(() => {
    if (
      !showDisabled &&
      disabledHairdressers
        .map((h) => (h.id || h.stringId).toString())
        .includes(value.toString())
    ) {
      onChangeFromProps({
        target: {
          name: "hairdresser",
          value: "ALL",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [showDisabled, value]);

  const onClose = (event: React.ChangeEvent<{}>) => {
    // Prevent Closing the Select Menu when clicking on disabledToggler
    if ((event.nativeEvent?.target as any)?.id !== disabledTogglerId) {
      close();
    }
  };
  return (
    <Select
      open={isOpen}
      onOpen={open}
      onClose={onClose}
      id="hairdresser-select"
      name="hairdresser"
      disableUnderline
      value={value ?? "ALL"}
      renderValue={(hairdresserId) =>
        hairdresserId === "ALL"
          ? "Tous les professionnels"
          : (
              hairdressers.find(
                (h) =>
                  (h.id && h.id === hairdresserId) ||
                  h.stringId === String(hairdresserId)
              ) || {}
            ).firstName
      }
      onChange={onChange}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      }}
      IconComponent={() => <PickerArrow large color={TEALISH} />}
      classes={{ root: styles.select }}
    >
      <MenuItem key="all_hairdressers" value={"ALL"}>
        <Radio checked={value === "ALL"} />
        <ListItemText>Tous les professionnels</ListItemText>
      </MenuItem>
      {enabledHairdressers.map((hairdresser) => (
        <MenuItem
          key={getHairdresserValue(hairdresser)}
          value={getHairdresserValue(hairdresser)}
        >
          <Radio
            checked={
              (hairdresser.id && value === hairdresser.id) ||
              value === Number(hairdresser.stringId)
            }
          />
          <ListItemText style={{ color: hairdresser.color }}>
            {hairdresser.firstName}
          </ListItemText>
        </MenuItem>
      ))}
      {disabledHairdressers.length > 0 && (
        <ListSubheader
          id={disabledTogglerId}
          className={styles.toggleDisabledHairdressers}
        >
          {showDisabledTitle}
        </ListSubheader>
      )}
      {showDisabled &&
        disabledHairdressers.map((hairdresser) => (
          <MenuItem
            key={getHairdresserValue(hairdresser)}
            value={getHairdresserValue(hairdresser)}
          >
            <Radio
              checked={
                (hairdresser.id && value === hairdresser.id) ||
                value === Number(hairdresser.stringId)
              }
            />
            <ListItemText style={{ color: hairdresser.color }}>
              {hairdresser.firstName}
            </ListItemText>
          </MenuItem>
        ))}
    </Select>
  );
}

export default TurnoverPageTemplate;
