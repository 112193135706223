import React from "react";
import { WHITE } from "../../constants/Style";

const PreviousIcon = ({ height = 18, color, ...props }: any) => (
  <svg width={(height / 3) * 2} height={height} viewBox="0 0 10 15" {...props}>
    <path
      d="M8.435 1L2 7.435l6.435 6.434"
      fillRule="nonzero"
      stroke={color || WHITE}
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
    />
  </svg>
);

export default PreviousIcon;
