import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiSnackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { routeList } from "constants/Routes";
import { parseRoute } from "utils/route";
import theme from "styles/Theme";
import AdminSalonSelection from "components/AdminSalonSelection";
import useCurrentUser from "hooks/useCurrentUser";
import { useServiceWorker } from "hooks/useServiceWorker";
import { useSmartBanner } from "hooks/useSmartBanner";
import {
  useEnsureStoreConsistency,
  STORE_STATE,
} from "hooks/useEnsureStoreConsistency";
import { AppState } from "reducers";
import { identifyUser } from "utils/logging";
import AccountPage from "Pages/account";
import AccountHaidressersPage from "Pages/account/hairdressers";
import AccountSalonPage from "Pages/account/salon";
import AccountSettingsPage from "Pages/account/settings";
import PaymentPage from "Pages/payment";
import PaymentListPage from "Pages/payment/list";
import PaymentTurnoverPage from "Pages/payment/turnover";
import PaymentInvoicesPage from "Pages/payment/invoices";
import InvoicePreviewPage from "Pages/payment/invoices/_slug";
import PaymentInfosPage from "Pages/payment/informations";
import PackagesPage from "Pages/packages";
import { RestoreSessionPage } from "Pages/restoreSession";
import AgendaPage from "./Agenda/AgendaPage";
import TermAgreementPage from "./TermAgreementPage";
import LoginPage from "./LoginPage/LoginPage";
import SignupPage from "./SignupPage/SignupPage";
import RatingPage from "./Rating";
import BookingsPage from "./Bookings/BookingsPage";
import PrivateRoute from "./PrivateRoute";
import Snackbar from "./Snackbar";
import { AppLoading } from "components/AppLoading/AppLoading";
import { useSSO } from "Pages/restoreSession/useSSO";
import { CrashRecovery } from "components/ErrorBoundary/ErrorBoundary";
import AccountWeeksPage from "Pages/account/weeks";
import AccountHappyHoursPage from "Pages/account/happyHours";
import AccountLastMinutePage from "Pages/account/lastMinute";
import ImportantInformations from "containers/ImportantInformations/ImportantInformations";

const selectSelectedSalonId = (state: AppState) =>
  state.salon.payload.selectedSalonId;

const postMessageToReactNative = (message: Object) =>
  (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(message));

function Main() {
  const location = useLocation();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const { forceSkipWaiting } = useServiceWorker({
    onEvent: (event, registration) => {
      setUpdateAvailable(Boolean(registration?.waiting));
    },
  });
  useSmartBanner();
  const { storeState } = useEnsureStoreConsistency();

  const { isLoggedIn, getAuthInfos, isAdmin, manualLogin } = useCurrentUser();
  const selectedSalonId = useSelector(selectSelectedSalonId);

  useEffect(() => {
    if (isLoggedIn) {
      const authInfos = getAuthInfos();
      identifyUser(authInfos, selectedSalonId);
      postMessageToReactNative({ userId: authInfos.userId });
    } else {
      postMessageToReactNative({ userId: null });
    }
  }, [isLoggedIn, getAuthInfos, selectedSalonId]);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(parseRoute(routeList.AGENDA_PAGE));
    }
  }, [isLoggedIn]);

  /**
   * Ensure that a session is restored
   * (fixes a bug where restoreSession didn't work on first loading of the app)
   * This one is from legacy
   */
  const sso = useSSO();
  const history = useHistory();
  useEffect(() => {
    if (sso.canRestoreSession) {
      history.replace(parseRoute(routeList.RESTORE_SESSION));
    }

    async function checkFlexySSo() {
      const flexySSO = await sso.flexySign();
      if (flexySSO !== undefined) {
        history.replace(parseRoute(routeList.AGENDA_PAGE));
      }
    }
    checkFlexySSo();
  }, []);

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <MuiSnackbar open={updateAvailable}>
          <Alert
            severity="success"
            action={<Button onClick={forceSkipWaiting}>METTRE À JOUR</Button>}
          >
            Une mise à jour est disponible
          </Alert>
        </MuiSnackbar>
        <ImportantInformations />
        {isAdmin && !selectedSalonId ? (
          <AdminSalonSelection />
        ) : storeState === STORE_STATE.UNSAFE ? (
          <AppLoading />
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={parseRoute(routeList.AGENDA_PAGE)} />}
            />
            <Route
              exact
              path={parseRoute(routeList.RESTORE_SESSION)}
              component={RestoreSessionPage}
            />
            <Route
              exact
              path={parseRoute(routeList.LOGIN_PAGE)}
              component={LoginPage}
            />
            <Route
              exact
              path={parseRoute(routeList.SIGNUP_PAGE)}
              component={SignupPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.TERM_AGREEMENT_PAGE)}
              component={TermAgreementPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.AGENDA_PAGE)}
              component={AgendaPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.RATING_PAGE)}
              component={RatingPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_PAGE)}
              component={PaymentPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_LIST_PAGE)}
              component={PaymentListPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_TURNOVER_PAGE)}
              component={PaymentTurnoverPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_INVOICES_PAGE)}
              component={PaymentInvoicesPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_INVOICE_PREVIEW_PAGE)}
              component={InvoicePreviewPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PAYMENT_INFORMATIONS_PAGE)}
              component={PaymentInfosPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_PAGE)}
              component={AccountPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_SALON_PAGE)}
              component={AccountSalonPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_HAIRDRESSERS_PAGE)}
              component={AccountHaidressersPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_SETTINGS_PAGE)}
              component={AccountSettingsPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_WEEKS_PAGE)}
              component={AccountWeeksPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_HAPPY_HOURS_PAGE)}
              component={AccountHappyHoursPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.ACCOUNT_LAST_MINUTE_PAGE)}
              component={AccountLastMinutePage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.PACKAGES_PAGE)}
              component={PackagesPage}
            />
            <PrivateRoute
              exact
              path={parseRoute(routeList.BOOKINGS_PAGE)}
              component={BookingsPage}
            />
            <Route path="*">
              <CrashRecovery hasError error="404" />
            </Route>
          </Switch>
        )}
        <Snackbar />
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default Main;
