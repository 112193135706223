import React from "react";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import VisibilitySensor from "react-visibility-sensor";

export interface ListItem {
  id: number | string;
  date: number;
}

interface ListByDateProps<T> {
  list: Array<T>;
  sort: "ascending" | "descending";
  itemComponent: React.FC<T>;
  infiniteScroll?: {
    isFetching: boolean;
    canFetchMore: boolean;
    fetchMore(): void;
  };
  className?: string;
  hideDate?: boolean;
}

function ListByDate<T extends ListItem>({
  list,
  sort = "ascending",
  itemComponent: Item,
  infiniteScroll,
  className,
  hideDate,
}: ListByDateProps<T>) {
  const itemsByDate = list.reduce<{ [dateStr: string]: Array<T> }>(
    (itemsByDate, item) => {
      const normalizedDate = moment(item.date).startOf("day").valueOf();
      itemsByDate[normalizedDate]
        ? itemsByDate[normalizedDate].push(item)
        : (itemsByDate[normalizedDate] = [item]);
      return itemsByDate;
    },
    {}
  );
  const sortCompareDateString =
    sort === "descending"
      ? (a: string, b: string) => {
          return parseInt(b) - parseInt(a);
        }
      : (a: string, b: string) => {
          return parseInt(a) - parseInt(b);
        };
  const sortCompareItems =
    sort === "descending"
      ? (a: ListItem, b: ListItem) => {
          return b.date - a.date;
        }
      : (a: ListItem, b: ListItem) => {
          return a.date - b.date;
        };
  return (
    <React.Fragment>
      {Object.keys(itemsByDate)
        .sort(sortCompareDateString)
        .map((dateStr) => {
          const date = parseInt(dateStr);
          return (
            <div
              className={classnames("list-by-date_container", className)}
              key={date}
            >
              {hideDate ? null : (
                <span className="list-by-date_date_text">
                  {moment(date).year() === moment().year()
                    ? moment(date).format("dddd D MMMM")
                    : moment(date).format("dddd D MMMM YYYY")}
                </span>
              )}
              {itemsByDate[dateStr].sort(sortCompareItems).map((item) => (
                <div className="list-by-date_item_container" key={item.id}>
                  <Item {...item} />
                </div>
              ))}
            </div>
          );
        })}
      {infiniteScroll && infiniteScroll.canFetchMore && (
        <VisibilitySensor
          onChange={(isVisible) => isVisible && infiniteScroll.fetchMore()}
          partialVisibility
        >
          {(isVisible) =>
            isVisible && infiniteScroll.isFetching ? (
              <CircularProgress size={25} className="infinite-scroll-loader" />
            ) : (
              <div className="infinite-scroll-sensor" />
            )
          }
        </VisibilitySensor>
      )}
    </React.Fragment>
  );
}

ListByDate.defaultProps = {
  sort: "ascending",
};

export function ListByDateSkeleton({
  itemComponent: Item,
}: {
  itemComponent: React.FC;
}) {
  return (
    <div className="list-by-date_container">
      <Skeleton width="45%" />
      {Array.from({ length: 3 }).map((_, index) => (
        <Item key={index} />
      ))}
    </div>
  );
}

export default ListByDate;
