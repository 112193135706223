import React from "react";
import WarningIcon from "../svgs/WarningIcon";

type WarningBoxProps = {
  text: string;
};

function WarningBox(props: WarningBoxProps) {
  const { text } = props;
  return (
    <div className="ui_warning-box">
      <div className="ui_warning-box_icon">
        <WarningIcon />
      </div>
      <span className="ui_warning-box_text">{text}</span>
    </div>
  );
}

export default WarningBox;
