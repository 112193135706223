import React from "react";
import moment from "moment";

import * as CALENDAR from "constants/Calendar";
import NextIcon from "components/svgs/NextIcon";
import PreviousIcon from "components/svgs/PreviousIcon";
import DatePicker from "components/inputs/DatePicker/DatePicker.desktop";
import { titleize } from "utils/string";
import "./calendar-date-picker.desktop.scss";

interface ICalendarDesktopDatePickerProps {
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  mode: keyof typeof CALENDAR.VIEWS;
}

interface IMoveButtonProps {
  type: keyof typeof moveButtonIcons;
  onClick(): void;
}

const moveButtonIcons = {
  previous: PreviousIcon,
  next: NextIcon
};
function MoveButton({ type, onClick }: IMoveButtonProps) {
  const Icon = moveButtonIcons[type];
  return (
    <div className="calendar-date-picker-move-button" onClick={onClick}>
      <Icon height={15} />
    </div>
  );
}

const datePickerHumanDates = {
  [CALENDAR.VIEWS.DAY]: (date: string | moment.Moment) =>
    titleize(moment(date).format("dddd D MMMM YYYY")),
  [CALENDAR.VIEWS.WEEK]: (date: string | moment.Moment) => {
    const startOfWeek = moment(date).startOf("week");
    const endOfWeek = moment(date).endOf("week");
    const isSameMonth = moment(startOfWeek).isSame(moment(endOfWeek), "month");

    const startOfWeekStr = isSameMonth
      ? moment(startOfWeek).format("D")
      : moment(startOfWeek).format("D MMMM YYYY");
    const endOfWeekStr = moment(endOfWeek).format("D MMMM YYYY");
    return `${titleize(startOfWeekStr)} - ${titleize(endOfWeekStr)}`;
  }
};

function DatePickerWrapper({
  value,
  onChange,
  mode
}: ICalendarDesktopDatePickerProps) {
  const toHumanDate = (date: string | moment.Moment | null) =>
    date ? datePickerHumanDates[mode](date) : "";
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      labelFunc={toHumanDate}
      classes={{
        container: "calendar-date-picker-full-date",
        popover: "calendar-date-picker-full-date-popover"
      }}
      noIcon
      TextFieldComponent={({
        error,
        helperText,
        defaultValue,
        value,
        inputRef,
        InputProps,
        InputLabelProps,
        fullWidth,
        ...props
      }) => (
        <span ref={inputRef} {...props}>
          {value as string}
        </span>
      )}
      InputProps={{
        disableUnderline: true
      }}
    />
  );
}

function CalendarDesktopDatePicker({
  value,
  onChange,
  mode
}: ICalendarDesktopDatePickerProps) {
  const changeDate = (date: string) => {
    const dateEvent = { target: { value: date } } as React.ChangeEvent<
      HTMLInputElement
    >;
    onChange(dateEvent);
  };
  const moveRange = mode.toLowerCase() as "day" | "week";
  const previousDate = () =>
    moment(value)
      .subtract(1, moveRange)
      .toISOString();
  const nextDate = () =>
    moment(value)
      .add(1, moveRange)
      .toISOString();
  return (
    <div className="calendar-date-picker-group calendar-date-picker-group--desktop">
      <MoveButton type="previous" onClick={() => changeDate(previousDate())} />
      <MoveButton type="next" onClick={() => changeDate(nextDate())} />
      <DatePickerWrapper value={value} onChange={onChange} mode={mode} />
    </div>
  );
}

export default CalendarDesktopDatePicker;
