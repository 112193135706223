import React, { useState, useEffect } from "react";

import useCurrentSalon from "hooks/useCurrentSalon";
import { enabledHairdresserFirst } from "utils/agenda";
import { mergeFormValues } from "utils/form";

export interface AgendaFilters {
  hairdresserIds: Array<number>;
  showBookings: boolean;
  showFreeSlots: boolean;
  showUnavailabilities: boolean;
}

type AgendaFilterSelectorProps = {
  filters: AgendaFilters;
  onSubmit(filters: AgendaFilters): void;
};

function useAgendaFilterSelector({
  filters,
  onSubmit,
}: AgendaFilterSelectorProps) {
  const [internalFormValues, setInternalFormValues] = useState(filters);

  useEffect(() => {
    setInternalFormValues(filters);
  }, [filters]);

  const submit = () => {
    onSubmit(internalFormValues);
  };

  const changeHandler = (event: React.ChangeEvent<{}>, checked?: boolean) => {
    const target = event.target as EventTarget & HTMLInputElement;
    const name = target.name as keyof AgendaFilters;
    const eventValue = checked === undefined ? target.value : checked;
    const currentValue = internalFormValues[name];
    const newValue = mergeFormValues(currentValue, eventValue);
    setInternalFormValues((oldFormValues) => ({
      ...oldFormValues,
      [name]: newValue,
    }));
  };

  const allHairdressers = useCurrentSalon().hairdressers.sort(
    enabledHairdresserFirst
  );
  return {
    formValues: internalFormValues,
    allHairdressers,
    onChange: changeHandler,
    onSubmit: submit,
  };
}

export default useAgendaFilterSelector;
