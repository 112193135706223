import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilitySensor from "react-visibility-sensor";

import RatingReplyFormContainer from "containers/Rating/RatingReplyForm";
import { useRatingReplier } from "containers/Rating/useRatingReplier";
import ModalContainer from "components/modal/Container/Container.mobile_old";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import { Rating, RatingStats } from "reducers/rating";
import RatingStars from "../RatingStars";
import RateNumber from "../RateNumber/RateNumber";
import RatingComment from "../RatingComment/RatingComment";
import styles from "./RatingList.module.scss";

const sortRatingsByDate = (ratings: Array<Rating>) =>
  ratings.sort(
    (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  );

const canFetchMore = (ratings: Array<Rating>, ratingStats?: RatingStats) =>
  Boolean(
    ratingStats && ratingStats.count && ratingStats.count > ratings.length
  );

interface RatingListProps {
  type: "HAIRDRESSER" | "SALON" | "USER";
  ratings: Array<Rating>;
  title: string;
  titleWithStars?: boolean;
  infinite?: boolean;
  fetchNextRatings?(): Promise<any>;
  ratingStats?: RatingStats;
}

function RatingList(props: RatingListProps) {
  const {
    type,
    ratings,
    title,
    titleWithStars,
    infinite,
    fetchNextRatings,
    ratingStats
  } = props;
  const ratingMeanOutOfTen =
    ratingStats && ratingStats.sum && ratingStats.count
      ? ratingStats.sum / ratingStats.count
      : 0;
  const ratingMeanOutOfFive = ratingMeanOutOfTen / 2;
  const [ratingToReply, setRatingToReply] = useState<Rating>();
  const [isReplying, setIsReplying] = useState(false);
  const openReplyModal = (rating: Rating) => {
    setRatingToReply(rating);
    setIsReplying(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchMore = (isVisible: boolean) => {
    if (isVisible && fetchNextRatings) {
      setIsLoading(true);
      fetchNextRatings().finally(() => setIsLoading(false));
    }
  };

  const { replyToRating } = useRatingReplier();

  const ratingsSortedByDate = sortRatingsByDate(ratings);

  const ratingCount = (
    <span className={styles.stats}>{` (${(ratingStats && ratingStats.count) ||
      "0"} avis)`}</span>
  );

  const ratingCountWithStars = (
    <div className={styles.ratingCountWithStars}>
      <RatingStars rate={ratingMeanOutOfFive} />
      <span className="hairdresser-rating-preview-extra">{`Basé sur ${(ratingStats &&
        ratingStats.count) ||
        "0"} avis`}</span>
    </div>
  );

  const { isMobile, isDesktop } = useResponsiveLayout();
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.mainLine}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            {!titleWithStars && ratingCount}
          </div>
          {(isMobile || titleWithStars) && (
            <div className={styles.ratingContainer}>
              <RateNumber
                rate={ratingMeanOutOfFive}
                noEmphasis={isDesktop && titleWithStars}
                classes={{
                  container: styles.rateNumber
                }}
              />
              <Desktop>{titleWithStars && ratingCountWithStars}</Desktop>
            </div>
          )}
        </div>
        <Mobile>{titleWithStars && ratingCountWithStars}</Mobile>
      </div>
      {ratingsSortedByDate.map(rating => (
        <RatingComment
          key={rating.id}
          rating={rating}
          type={type}
          openReplyModal={openReplyModal}
          onSubmitReply={replyToRating(rating)}
        />
      ))}
      {infinite && canFetchMore(ratings, ratingStats) && (
        <VisibilitySensor onChange={fetchMore} partialVisibility>
          {isVisible =>
            isVisible && isLoading ? (
              <CircularProgress size={25} className="infinite-scroll-loader" />
            ) : (
              <div className="infinite-scroll-sensor" />
            )
          }
        </VisibilitySensor>
      )}
      <Mobile>
        <ModalContainer open={isReplying} direction="left">
          <>
            {ratingToReply && (
              <RatingReplyFormContainer
                type={type}
                rating={ratingToReply}
                onClose={() => setIsReplying(false)}
              />
            )}
          </>
        </ModalContainer>
      </Mobile>
    </>
  );
}

export default RatingList;
