import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import { fetchStripeCapabilities } from "actions/SalonActions";
import { AppState } from "reducers";
import { generateStripeForm } from "utils/salon";

const selectSelectedSalonId = (state: AppState) =>
  state.salon.payload.selectedSalonId;

const salonById = (state: AppState) => state.salon.payload.salonById;

const useStyles = makeStyles((theme) =>
  createStyles({
    alertRoot: {
      borderRadius: 0,
      ["&:hover"]: {
        cursor: "pointer",
      },
    },
    buttonRoot: {
      paddingBottom: 40,
    },
  })
);

const StripeAccountUpdate = () => {
  const { isMobile } = useResponsiveLayout();
  // Start open so we are sure users see at least once on opening the app
  const [isOpen, setIsOpen] = React.useState(true);
  const [stripeOpened, setStripeOpened] = React.useState(true);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const [stripeFormUrl, setStripeFormUrl] = useState("");
  const [display, setDisplay] = useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();

  const selectedSalonId = useSelector(selectSelectedSalonId);
  const salon = useSelector(salonById);

  const selectedSalon = selectedSalonId ? salon[selectedSalonId] : undefined;

  useEffect(() => {
    if (selectedSalonId) {
      dispatch(fetchStripeCapabilities(selectedSalonId));
    }
  }, [salonById, selectedSalonId]);

  useEffect(() => {
    if (
      // Check if the salon cannot receive transfers now
      (selectedSalonId &&
        selectedSalon?.capabilities?.status &&
        selectedSalon?.capabilities?.status !== "active" &&
        selectedSalon?.capabilities?.status !== "pending" &&
        selectedSalon?.capabilities?.status !== "unrequested") ||
      // Check if the salon will not be able to receive transfers later
      (selectedSalonId &&
        (selectedSalon?.capabilities?.requirements?.currently_due || [])
          .length > 0)
    ) {
      setDisplay(true);
      generateStripeForm(selectedSalonId)
        .then((url: string) => {
          setStripeFormUrl(url);
          setStripeOpened(true);
          setIsOpen(true);
        })
        .catch((err: any) => console.error(err));
    }
  }, [selectedSalon]);

  return display && stripeOpened ? (
    <>
      <Box width="100vw">
        <Alert
          variant="filled"
          severity="info"
          color="error"
          classes={{ root: styles.alertRoot }}
          onClose={() => setStripeOpened(false)}
        >
          <AlertTitle onClick={open}>
            IMPORTANT : Pour continuer à recevoir vos virements bancaires,
            veuillez mettre à jour vos informations personnelles au plus vite.
          </AlertTitle>
        </Alert>
      </Box>
      <Dialog
        fullScreen={isMobile}
        open={isOpen}
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Important : Veuillez mettre à jour vos informations personnelles
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography paragraph>
              Afin de continuer à recevoir vos virements bancaires, vous devez
              impérativement mettre à jour vos informations chez notre
              prestataire de paiement Stripe.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: styles.buttonRoot }}>
          <Button
            onClick={() => (window.location.href = stripeFormUrl)}
            color="primary"
          >
            Démarrer
          </Button>
          <Button onClick={close} color="primary">
            Mettre à jour Plus tard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export { StripeAccountUpdate };
