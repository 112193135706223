import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getSalonBalance,
  getSalonPrestations,
  getSalonPayouts,
  fetchPayoutWithBookings,
} from "api/payments";
import useProgressiveList, {
  FetchOptions,
} from "hooks/useProgressiveList/useProgressiveList";
import { Prestation } from "utils/payments";
import { Payout } from "reducers/payments";
import PaymentListPageTemplate from "components/payment/PaymentListPage/PaymentListPageTemplate/PaymentListPageTemplate";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import PrestationDetails from "components/payment/PrestationDetails/PrestationDetails";
import PayoutDetails from "components/payment/PayoutDetails/PayoutDetails";
import useCurrentSalon from "hooks/useCurrentSalon";

async function fetchTotalSalonBalance(salonId: number) {
  const { totalAvailable, totalPending } = await getSalonBalance(salonId);
  return totalAvailable + totalPending;
}

function extractDateInfo(dateString: string) {
  if (dateString === "") {
    return {
      createdGte: undefined,
      createdLte: undefined,
      dateMax: undefined,
    };
  }

  const momentObject = moment(dateString);

  return {
    createdGte: Number(momentObject.startOf("month").format("X")),
    createdLte: Number(momentObject.endOf("month").format("X")),
    dateMin: momentObject.startOf("month").toISOString(),
    dateMax: momentObject.endOf("month").toISOString(),
  };
}

function getFetchPrestationsForSalon(
  salonId: number,
  dateMin: string | undefined,
  dateMax: string | undefined
) {
  return async function fetchPrestations(opts?: FetchOptions) {
    const prestations = (await getSalonPrestations(salonId, {
      ...opts,
      dateMin,
      dateMax,
    })) as Prestation[];
    return prestations;
  };
}

function getFetchPayoutsForSalon(
  salonId: number,
  lastIdRef: React.MutableRefObject<string | undefined>,
  createdGte: number | undefined,
  createdLte: number | undefined
) {
  return async function fetchPayouts(opts?: FetchOptions) {
    const payouts = (await getSalonPayouts(salonId, {
      lastId: lastIdRef.current,
      limit: opts && opts.limit,
      createdGte,
      createdLte,
    })) as Payout[];
    const lastPayout = payouts[payouts.length - 1];
    if (lastPayout) {
      lastIdRef.current = lastPayout.id;
    }
    return payouts;
  };
}

function PaymentListPage() {
  const { salon } = useCurrentSalon();

  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const { createdGte, createdLte, dateMin, dateMax } = extractDateInfo(
    selectedMonthYear
  );

  const prestations = useProgressiveList<Prestation>(
    getFetchPrestationsForSalon(salon.id, dateMin, dateMax)
  );
  useEffect(() => {
    prestations.resetFetchFn(
      getFetchPrestationsForSalon(salon.id, dateMin, dateMax)
    );
  }, [salon.id, selectedMonthYear]);
  const [prestationToOpen, setPrestationToOpen] = useState<Prestation>();

  const lastPayoutIdRef = useRef<string>();
  const payouts = useProgressiveList<Payout>(
    getFetchPayoutsForSalon(salon.id, lastPayoutIdRef, createdGte, createdLte)
  );
  useEffect(() => {
    lastPayoutIdRef.current = undefined;
    payouts.resetFetchFn(
      getFetchPayoutsForSalon(salon.id, lastPayoutIdRef, createdGte, createdLte)
    );
  }, [salon.id, selectedMonthYear]);

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (salon.thirdPartyPaymentId && salon.thirdPartyPaymentId !== "") {
      fetchTotalSalonBalance(salon.id).then((balance) => setBalance(balance));
      return;
    }

    setBalance(0);
  }, [salon.id, selectedMonthYear]);

  const [payoutToOpen, setPayoutToOpen] = useState<Payout | undefined>();
  const [payoutDetailsIsOpened, setPayoutDetailsIsOpened] = useState(false);
  const [payoutDetailsIsLoadong, setPayoutDetailsIsLoadong] = useState(false);

  const openPayoutDetails = (payout: Payout) => {
    setPayoutDetailsIsLoadong(true);
    setPayoutDetailsIsOpened(true);
    fetchPayoutWithBookings(payout.id, salon.id).then(
      (payoutWithBookings: Payout) => {
        setPayoutToOpen(payoutWithBookings);
        setPayoutDetailsIsLoadong(false);
      }
    );
  };

  const closePayoutDetails = () => {
    setPayoutDetailsIsOpened(false);
    setPayoutToOpen(undefined);
    setPayoutDetailsIsLoadong(false);
  };

  return (
    <React.Fragment>
      <PaymentListPageTemplate
        title="Liste des paiements"
        closeLink={parseRoute(routeList.PAYMENT_PAGE)}
        balance={balance}
        prestations={prestations}
        payouts={payouts}
        openPrestationDetails={(prestation: Prestation) =>
          setPrestationToOpen(prestation)
        }
        openPayoutDetails={openPayoutDetails}
        selectedMonthYear={selectedMonthYear}
        setSelectedMonthYear={setSelectedMonthYear}
      />
      {prestationToOpen && (
        <PrestationDetails
          open={!!prestationToOpen}
          prestation={prestationToOpen}
          onClose={() => setPrestationToOpen(undefined)}
        />
      )}
      <PayoutDetails
        open={payoutDetailsIsOpened}
        payout={payoutToOpen}
        onClose={closePayoutDetails}
        isLoading={payoutDetailsIsLoadong}
      />
      {(prestations.isFetching || payouts.isFetching) && (
        <CircularProgress className="calendar-loader" color="primary" />
      )}
    </React.Fragment>
  );
}

export default PaymentListPage;
