import React, { Suspense } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Main from "../Main";

const DevTools = React.lazy(() => import("../DevTools"));

function AppDev() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Main />
      {!(window as any).__REDUX_DEVTOOLS_EXTENSION__ && <DevTools />}
    </Suspense>
  );
}

export default AppDev;
