import { Reducer } from "redux";
import * as yup from "yup";

import {
  LastMinuteActions,
  FetchLastMinutesSuccessAction,
} from "actions/LastMinuteActions";
import * as types from "constants/ActionTypes";

export const daysSchema = yup.object().shape({
  monday: yup.boolean().required(),
  tuesday: yup.boolean().required(),
  wednesday: yup.boolean().required(),
  thursday: yup.boolean().required(),
  friday: yup.boolean().required(),
  saturday: yup.boolean().required(),
  sunday: yup.boolean().required(),
});

export const lastMinuteSchema = yup.object().shape({
  id: yup.number().required(),
  createdAt: yup.string().notRequired(),
  hairdresserIds: yup.array().of(yup.number()).notRequired(),
  hairdresserStringIds: yup.array().of(yup.string()).notRequired(),
  salonId: yup.number().required(),
  discount: yup.number().required(),
  minutesBefore: yup.number().required(),
  status: yup.string().required(),
  days: daysSchema.notRequired(),
});
export type LastMinute = yup.InferType<typeof lastMinuteSchema>;
export type LastMinuteDays = yup.InferType<typeof daysSchema>;

export interface LastMinutePayload {
  lastMinutes?: Array<LastMinute>;
}

interface LastMinuteState {
  loading: boolean;
  error: string | null;
  payload: LastMinutePayload;
}

const getInitialState: { (): LastMinuteState } = () => ({
  loading: false,
  error: null,
  payload: {
    lastMinutes: [],
  },
});

const fetchLastMinutesSuccessReducer = (
  state: LastMinuteState,
  action: FetchLastMinutesSuccessAction
) => {
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      lastMinutes: [...action.payload.lastMinutes],
    },
  };
};

const lastMinute: Reducer<LastMinuteState, LastMinuteActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case types.FETCH_LAST_MINUTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_LAST_MINUTES_SUCCESS:
      return fetchLastMinutesSuccessReducer(state, action);
    case types.FETCH_LAST_MINUTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default lastMinute;
