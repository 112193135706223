import React from "react";
import Select, { SelectProps } from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { ACCESS_TYPES } from "../../../constants/Account";
import MetroIcon from "../../svgs/SalonAccess/MetroIcon";
import CarIcon from "../../svgs/SalonAccess/CarIcon";
import BusIcon from "../../svgs/SalonAccess/BusIcon";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ArrowBottomIcon from "../../svgs/ArrowBottomIcon";
import TrainStationIcon from "../../svgs/SalonAccess/TrainStationIcon";
import ParginkIcon from "../../svgs/SalonAccess/ParkingIcon";
import RerIcon from "../../svgs/SalonAccess/RerIcon";
import StreetcarIcon from "../../svgs/SalonAccess/StreetcarIcon";
import ShoppingMallIcon from "../../svgs/SalonAccess/ShoppingMallIcon";
import styles from "./AccessSelector.module.scss";

function CustomMenuItem(props: any) {
  return <MenuItem classes={{ root: styles.menuItemRoot }} {...props} />;
}

function AccessSelector({ value, onChange, name, disabled }: SelectProps) {
  return (
    <Select
      disabled={disabled}
      autoWidth
      name={name}
      value={value}
      onChange={onChange}
      IconComponent={ArrowBottomIcon}
      classes={{
        select: styles.select,
        icon: styles.icon
      }}
      input={
        <OutlinedInput
          className={styles.selectInput}
          labelWidth={0}
          name="age"
          id="outlined-age-simple"
        />
      }
    >
      <CustomMenuItem value={ACCESS_TYPES.METRO}>
        <MetroIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.ROAD}>
        <CarIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.BUS}>
        <BusIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.RER}>
        <RerIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.TRAIN_STATION}>
        <TrainStationIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.PARKING}>
        <ParginkIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.SHOPPING_MALL}>
        <ShoppingMallIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.STREETCAR}>
        <StreetcarIcon />
      </CustomMenuItem>
      <CustomMenuItem value={ACCESS_TYPES.PLACE}>
        <LocationOnIcon />
      </CustomMenuItem>
    </Select>
  );
}

export default AccessSelector;
