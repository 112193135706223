import React, { useCallback, useState, useEffect } from "react";
import { Package, Price } from "../../reducers/salon";
import PackageForm, {
  PackageFormData,
  PackageFormErrors,
} from "../../components/package/PackageForm";
import { AppState } from "../../reducers";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as salonActions from "../../actions/SalonActions";
import * as snackbarActions from "../../actions/SnackbarActions";
import ModalLayoutMobile from "../../components/modal/Layout/Layout.mobile";
import ButtonFab from "../../components/button/Fab";
import { mergeEventToForm } from "../../utils/form";
import { MESSAGE_TYPES } from "../../constants/SnackBar";
import MobilePageHeader from "../../components/page/Header.mobile";
import PreviousIcon from "../../components/svgs/PreviousIcon";
import LargeClickable from "../../components/uiElements/LargeClickable";
import Mobile from "../../components/ResponsiveLayout/Mobile";
import Desktop from "../../components/ResponsiveLayout/Desktop";
import ModalLayoutDesktop from "../../components/modal/Layout/Layout.desktop";
import useCurrentSalon from "hooks/useCurrentSalon";
import { useCurrentSalonPackages } from "hooks/useCurrentSalonPackages";
import { fetchCategories, fetchVariants, getOpacityStyle } from "./utils";
import { PINK } from "constants/Style";
import { getErrorsInPackage, getNewPartnerRefList } from "utils/package";
import { doesHairdresserDoVariant } from "utils/package";

const emptyPrice: Price = {
  duration: "0",
  price: 0,
  currency: "EUR",
  packageNumber: "",
  partnerRefs: [],
};
interface PackageFormContainerProps {
  type?: "create" | "update";
  pack: Package | null | undefined;
  onClose(): void;
}

const baseErrors = {
  price: 0,
  variant: 0,
  anyLength: false,
};

const errorDiv = (formErrors: PackageFormErrors) => (
  <div style={{ padding: "10px" }}>
    {formErrors.price || formErrors.variant ? (
      <span style={{ color: PINK }}>Le formulaire contient des erreurs</span>
    ) : (
      ""
    )}
    {formErrors.anyLength ? (
      <span style={{ color: PINK }}>
        <br />
        Impossible de sélectionner "Pas de variante" avec d'autres variantes
      </span>
    ) : (
      ""
    )}
  </div>
);

const PackageFormContainer: React.FC<
  ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch> &
    PackageFormContainerProps
> = ({
  type = "update",
  pack,
  salonState,
  onClose,
  salonActionsDispatcher,
  snackbarActionsDispatcher,
}) => {
  const [formErrors, setFormErrors] = useState(baseErrors);
  const title =
    type === "update" ? "Modifier la prestation" : "Créer une prestation";
  const buttonText =
    type === "update" ? "Modifier la prestation" : "Créer la prestation";
  const {
    salon,
    hairdressers: allHairdressers,
    activeHairdresserIds,
  } = useCurrentSalon();

  const getPackHairdresserIds = useCallback(
    (pack: Package): Array<number> => {
      return allHairdressers
        .filter(
          (h) =>
            h.packageIds?.includes(pack.id) ||
            doesHairdresserDoVariant(h.serviceIds, pack.prices)
        )
        .map((h) => h.id);
    },
    [allHairdressers]
  );

  const [formData, setFormData] = useState<PackageFormData>({
    name: pack?.name ?? "",
    description: pack?.description ?? "",
    hairdresserIds: pack
      ? getPackHairdresserIds(pack)
      : type === "create"
      ? activeHairdresserIds
      : [],
    prices: type === "create" ? [emptyPrice] : pack?.prices ?? [],
    categoryId: pack?.category.id,
    allowPromo: (type === "create" || pack?.allowPromo) ?? false,
    partnerRefs: pack?.partnerRefs || [],
  });

  const [categories, setCategories] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const fetchAndSetCategories = async () => {
    try {
      const fetchedCategories = await fetchCategories();
      setCategories(fetchedCategories);
      setFormData((currenFormData: any) => ({
        ...currenFormData,
        categoryId: currenFormData.categoryId ?? fetchedCategories[0]?.id,
      }));
    } catch (error) {
      console.error(error);
      snackbarActionsDispatcher.pushMessage(
        "Une erreur s'est produite lors de la récupération des catégories disponibles",
        MESSAGE_TYPES.ERROR
      );
    }
  };

  const [variants, setVariants] = useState<
    Array<{ id: number; name: string; key: string }>
  >([]);

  const fetchAndSetVariants = async () => {
    try {
      const fetchedVariants = await fetchVariants();
      setVariants(fetchedVariants);
    } catch (error) {
      console.error(error);
      snackbarActionsDispatcher.pushMessage(
        "Une erreur s'est produite lors de la récupération des variantes disponibles",
        MESSAGE_TYPES.ERROR
      );
    }
  };

  useEffect(() => {
    fetchAndSetCategories();
    fetchAndSetVariants();
  }, []);

  const updatePartnerRefFormData = (
    variant?: string,
    name?: string,
    value?: string
  ) => {
    if (!variant || !name || value === undefined) {
      return;
    }

    const newPrices = formData.prices.map((price: Price) => {
      if (variant === "empty" || variant === price?.variant?.name) {
        const newPartnerRefs = getNewPartnerRefList(
          price?.partnerRefs || [],
          name,
          value
        );
        return {
          ...price,
          partnerRefs: newPartnerRefs,
        };
      }

      return price;
    }) as Array<Price>;

    setFormData({
      ...formData,
      prices: newPrices,
    });
  };

  const updateFormData = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist && event.persist();
      setFormData((oldFormData) => mergeEventToForm(event, oldFormData));
    },
    [setFormData]
  );

  const { createPackage } = useCurrentSalonPackages();

  const onSubmit = useCallback(() => {
    // Errors are identified only when
    // the form is submitted
    const errors = getErrorsInPackage(formData.prices);
    setFormErrors(errors);
    if (errors.price || errors.variant || errors.anyLength) {
      return;
    }
    if (type === "update") {
      const newStatus =
        formData.hairdresserIds.length > 0 ? "ENABLED" : "DISABLED";
      if (pack && pack.status !== newStatus) {
        snackbarActionsDispatcher.pushMessage(
          newStatus === "ENABLED"
            ? "La prestation a été automatiquement activée"
            : "La prestation a été automatiquement désactivée",
          MESSAGE_TYPES.SUCCESS
        );
      }
      pack &&
        ((salonActionsDispatcher.updatePackage(
          salon.id,
          pack.id,
          {
            name: formData.name,
            description: formData.description,
            prices: formData.prices,
            allowPromo: formData.allowPromo,
            category: {
              id: formData.categoryId as number,
            },
            status: newStatus,
            partnerRefs: formData.partnerRefs,
          },
          {
            beforeUpdate: getPackHairdresserIds(pack),
            afterUpdate: formData.hairdresserIds,
          }
        ) as unknown) as Promise<any>)
          .then(() => {
            snackbarActionsDispatcher.pushMessage(
              "La prestation a bien été mise à jour",
              MESSAGE_TYPES.SUCCESS
            );
            onClose();
          })
          .catch(() => {
            snackbarActionsDispatcher.pushMessage(
              "Il y a eu une erreur lors de la mise à jour de la prestation.",
              MESSAGE_TYPES.ERROR
            );
          });
    }
    if (type === "create") {
      (createPackage(
        {
          name: formData.name,
          description: formData.description,
          prices: formData.prices,
          allowPromo: formData.allowPromo,
          category: {
            id: formData.categoryId,
          },
        } as Package,
        formData.hairdresserIds
      ) as Promise<any>)
        .then(() => {
          snackbarActionsDispatcher.pushMessage(
            "La prestation a bien été créée",
            MESSAGE_TYPES.SUCCESS
          );
          onClose();
        })
        .catch(() => {
          snackbarActionsDispatcher.pushMessage(
            "Il y a eu une erreur lors de la création de la prestation.",
            MESSAGE_TYPES.ERROR
          );
        });
    }
  }, [
    salonActionsDispatcher,
    salonState,
    pack,
    formData,
    onClose,
    getPackHairdresserIds,
    snackbarActionsDispatcher,
  ]);

  const addPrice = useCallback(() => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      prices: [...currentFormData.prices, emptyPrice],
    }));
  }, [variants]);

  const removePrice = useCallback((index: number) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      prices: currentFormData.prices.filter(
        (_price, priceIndex) => priceIndex !== index
      ),
    }));
  }, []);

  const body = (
    <PackageForm
      type={type}
      allHairdressers={allHairdressers}
      formData={formData}
      updateFormData={updateFormData}
      addPrice={addPrice}
      removePrice={removePrice}
      categories={categories}
      variants={variants}
      formErrors={formErrors}
      updatePartnerRefFormData={updatePartnerRefFormData}
      isOneCatalog={salon.isOneCatalog}
    />
  );

  const opacityStyle = getOpacityStyle(salon.isOneCatalog);

  return (
    <>
      <Mobile>
        <ModalLayoutMobile
          header={
            <MobilePageHeader
              left={
                <LargeClickable onClick={onClose}>
                  <PreviousIcon onClick={onClose} />
                </LargeClickable>
              }
              center={title}
            />
          }
          body={body}
          footer={
            <div>
              {errorDiv(formErrors)}
              <div style={opacityStyle}>
                <ButtonFab
                  disabled={salon.isOneCatalog}
                  onClick={onSubmit}
                  isLoading={salonState.loading}
                >
                  {buttonText}
                </ButtonFab>
              </div>
            </div>
          }
        />
      </Mobile>
      <Desktop>
        <ModalLayoutDesktop
          header={title}
          onClose={onClose}
          body={
            <div className="package-form--desktop-content-container">
              {body}
              {errorDiv(formErrors)}
              <div style={opacityStyle}>
                <ButtonFab
                  style={opacityStyle}
                  disabled={salon.isOneCatalog}
                  onClick={onSubmit}
                  isLoading={salonState.loading}
                >
                  {buttonText}
                </ButtonFab>
              </div>
            </div>
          }
        />
      </Desktop>
    </>
  );
};

function mapState(state: AppState) {
  return {
    salonState: state.salon,
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    salonActionsDispatcher: bindActionCreators(salonActions, dispatch),
    snackbarActionsDispatcher: bindActionCreators(snackbarActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(PackageFormContainer);
