import React from "react";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

interface LargeClickableProps {
  children: React.ReactNode;
  onClick(): void;
  className?: string;
}

function LargeClickable(props: LargeClickableProps) {
  const { children, onClick, className } = props;
  return (
    <div className={`large-clickable-container ${className || ""}`}>
      <div>
        {children}
        <div className="large-clickable" onClick={onClick}>
          <TouchRipple />
        </div>
      </div>
    </div>
  );
}

export default LargeClickable;
