import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "components/inputs/Checkbox";
import ModalLayoutMobile from "components/modal/Layout/Layout.mobile";
import ModalContent from "components/modal/Content/Content.mobile";
import PackageTitleItem from "components/package/PackageTitleItem/PackageTitleItem";
import PreviousIcon from "components/svgs/PreviousIcon";
import MobilePageHeader from "components/page/Header.mobile";
import LargeClickable from "components/uiElements/LargeClickable";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import ButtonFab from "components/button/Fab";
import { useCurrentSalonPackages } from "hooks/useCurrentSalonPackages";
import { Category, Package } from "reducers/salon";
import styles from "./HairdresserPackageSelector.module.scss";
import useCurrentSalon from "hooks/useCurrentSalon";

interface HairdresserPackageSelectorProps {
  name: string;
  value: Array<number>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onClose(): void;
  title: string;
}

const getNewPackageIds = (id: number, selectedPackageIds: number[]) => {
  if (selectedPackageIds.includes(id)) {
    const index = selectedPackageIds.findIndex(
      (currentId: number) => currentId === id
    );
    return [
      ...selectedPackageIds.slice(0, index),
      ...selectedPackageIds.slice(index + 1),
    ];
  }

  return [...selectedPackageIds, id];
};

function HairdresserPackageSelector({
  value,
  name,
  onChange,
  ...otherProps
}: HairdresserPackageSelectorProps) {
  const {
    packagesGroupedByCategory,
    packages,
    updatePackageAllowPromo,
  } = useCurrentSalonPackages();
  const [selectedPackageIds, setSelectedPackageIds] = useState(value);

  /**
   * Toggle the item from the packages list
   * @param id
   */
  const onItemClick = (id: number) => {
    setSelectedPackageIds(getNewPackageIds(id, selectedPackageIds));
  };

  const onSubmit = () => {
    onChange(({
      target: {
        name: name,
        value: selectedPackageIds,
      },
    } as unknown) as React.ChangeEvent<HTMLInputElement>);
  };

  const isAllSelected = packages.length === selectedPackageIds.length;

  const toggleSelectAll = () => {
    if (isAllSelected) {
      // Unselect All
      setSelectedPackageIds([]);
    } else {
      // Select All
      setSelectedPackageIds(packages.map((p) => p.id));
    }
  };

  const dispatch = useDispatch();
  const updatePackageAllowPromoHandler = (
    packageId: number,
    allowPromo: boolean
  ): void => {
    updatePackageAllowPromo(packageId, allowPromo)
      .then(() => {
        dispatch(pushMessage("Mis à jour", MESSAGE_TYPES.SUCCESS));
      })
      .catch(() => {
        dispatch(
          pushMessage(
            "Il y a eu une erreur lors de la demande de mise à jour.",
            MESSAGE_TYPES.ERROR
          )
        );
      });
  };

  return (
    <HairdresserPackageSelectorTemplate
      selectedPackageIds={selectedPackageIds}
      onItemClick={onItemClick}
      packagesGroupedByCategory={packagesGroupedByCategory}
      onSubmit={onSubmit}
      isAllSelected={isAllSelected}
      toggleSelectAll={toggleSelectAll}
      updatePackageAllowPromoHandler={updatePackageAllowPromoHandler}
      {...otherProps}
    />
  );
}

interface HairdresserPackageSelectorTemplateProps
  extends Omit<PackageListProps, "packages"> {
  onClose(): void;
  onSubmit(): void;
  isAllSelected: boolean;
  toggleSelectAll(): void;
  packagesGroupedByCategory: Array<{
    category: Category;
    packages: Package[];
  }>;
  title: string;
  updatePackageAllowPromoHandler(packageId: number, allowPromo: boolean): void;
}

export function HairdresserPackageSelectorTemplate({
  onClose,
  onSubmit,
  isAllSelected,
  toggleSelectAll,
  packagesGroupedByCategory,
  title,
  selectedPackageIds,
  onItemClick,
  updatePackageAllowPromoHandler,
}: HairdresserPackageSelectorTemplateProps) {
  const selectAllToggler = (
    <div className={styles.selectAllToggler}>
      <Checkbox
        onClick={toggleSelectAll}
        checked={isAllSelected}
        indeterminate={!isAllSelected && selectedPackageIds.length > 0}
      />
      Tout sélectionner / désélectionner
    </div>
  );

  const { isMobile } = useResponsiveLayout();
  const content = (
    <div className={styles.contentContainer}>
      {isMobile && selectAllToggler}
      {packagesGroupedByCategory.map(({ category, packages }) => {
        const packageList = (
          <PackageList
            packages={packages}
            selectedPackageIds={selectedPackageIds}
            onItemClick={onItemClick}
            updatePackageAllowPromoHandler={updatePackageAllowPromoHandler}
          />
        );
        return (
          <React.Fragment key={`category_${category.id}`}>
            <span className={styles.categoryName}>{category.name}</span>
            <Mobile>
              <ModalContent noPadding>{packageList}</ModalContent>
            </Mobile>
            <Desktop>{packageList}</Desktop>
          </React.Fragment>
        );
      })}
    </div>
  );

  const submitAndClose = () => {
    onSubmit();
    onClose();
  };
  return (
    <>
      <Mobile>
        <ModalLayoutMobile
          header={
            <MobilePageHeader
              left={
                <LargeClickable onClick={submitAndClose}>
                  <PreviousIcon />
                </LargeClickable>
              }
              center={title}
            />
          }
          body={content}
        />
      </Mobile>
      <Desktop>
        <ModalLayoutDesktop
          header={title}
          onClose={onClose}
          body={
            <>
              {selectAllToggler}
              {content}
              <ButtonFab onClick={submitAndClose}>Valider</ButtonFab>
            </>
          }
        />
      </Desktop>
    </>
  );
}

interface PackageListProps {
  selectedPackageIds: Array<number>;
  onItemClick(id: number): void;
  packages: Package[];
  updatePackageAllowPromoHandler(packageId: number, allowPromo: boolean): void;
}

function PackageList({
  selectedPackageIds,
  onItemClick,
  packages,
  updatePackageAllowPromoHandler,
}: PackageListProps) {
  const { isMobile, isDesktop } = useResponsiveLayout();
  const { salon } = useCurrentSalon();
  return (
    <List classes={{ root: styles.packagesListContainer }}>
      {packages.map((pack, index, packs) => (
        <ListItem key={`package_${pack.id}`} disableGutters={isDesktop}>
          <PackageTitleItem
            selectable
            selected={
              selectedPackageIds.includes(pack.id) ||
              selectedPackageIds.includes(
                Number(pack.prices[0]?.stringId || "0")
              )
            }
            pack={pack}
            onItemClick={() => onItemClick(pack.id)}
            withUnderline={isMobile ? index !== packs.length - 1 : true}
            fullWidthUnderline={isDesktop}
            displayEnableCheckbox={false}
            updatePackageAllowPromoHandler={updatePackageAllowPromoHandler}
            isOneCatalog={salon.isOneCatalog}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default HairdresserPackageSelector;
