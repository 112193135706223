import React from "react";
import { PINK, WHITE } from "../../constants/Style";

const RemoveIconWithCircle: React.FC<any> = ({ color = PINK, ...props }) => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <g fillRule="nonzero" fill="none">
      <circle fill={color} cx={11} cy={11} r={11} />
      <path
        d="M6 11.5h9.505"
        stroke={WHITE}
        strokeWidth={2}
        strokeLinecap="square"
      />
    </g>
  </svg>
);

export default RemoveIconWithCircle;
