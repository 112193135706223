import React, { useState, useEffect } from "react";
import styles from "./PictureListInput.module.scss";
import CloseIcon from "components/svgs/CloseIcon";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import PlusIcon from "components/svgs/PlusIcon";
import { BLUE_GREY } from "constants/Style";
import classnames from "classnames";

export interface FileWithData {
  id: string;
  file: File;
  data: string;
}

export const getFileData = (file: File) => {
  const fileReader = new FileReader();

  const promise = new Promise<string>(resolve => {
    fileReader.addEventListener(
      "load",
      function() {
        const base64File = fileReader.result;
        if (base64File && typeof base64File === "string") {
          resolve(base64File);
        }
      },
      false
    );
  });

  fileReader.readAsDataURL(file);

  return promise;
};

interface PictureListInputProps {
  onChange?(filesWithData: FileWithData[]): void;
  classes?: {
    container?: string;
  };
}

function PictureListInput({
  classes,
  onChange: onChangeProps
}: PictureListInputProps) {
  const { isMobile } = useResponsiveLayout();
  const deleteIconHeight = isMobile ? 7 : 12;
  const [files, setFiles] = useState<FileWithData[]>([]);
  const inputId = "files_input";
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files } = target;
    const filesWithData = await Promise.all(
      [...files].map(async file => {
        const data = await getFileData(file);
        return {
          id: `${file.name}_${file.size}`,
          file,
          data
        };
      })
    );
    setFiles(currentFiles => [
      ...currentFiles,
      ...filesWithData.filter(
        file => !currentFiles.map(file => file.id).includes(file.id)
      )
    ]);

    // Reset input value
    target.value = "";
  };

  const onDelete = (fileToDelete: FileWithData) => {
    setFiles(currentFiles =>
      currentFiles.filter(file => file !== fileToDelete)
    );
  };

  useEffect(() => {
    onChangeProps && onChangeProps(files);
  }, [onChangeProps, files]);
  return (
    <div className={classnames(styles.container, classes?.container)}>
      {files.map(file => (
        <div className={styles.imageContainer} key={file.id}>
          <CloseIcon
            onClick={() => onDelete(file)}
            height={deleteIconHeight}
            className={styles.deleteIcon}
          />
          <img alt={file.file.name} src={file.data} />
        </div>
      ))}
      <div className={styles.imageContainer}>
        <label htmlFor={inputId}>
          <PlusIcon color={BLUE_GREY} />
        </label>
        <input
          id={inputId}
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default PictureListInput;
