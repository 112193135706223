import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DesktopModalContainer from "components/modal/Container/Container.desktop_old";
import DesktopModalHeader from "components/modal/Header/Header.desktop";
import MobileSelect from "components/inputs/Select/Mobile";
import ButtonFab from "components/button/Fab";
import Checkbox from "components/inputs/Checkbox";
import { IFiltersSelectorProps } from "./index";
import { getDisplayCheckboxes, hairdresserToInput } from "./utils";

function MobileCalendarFilterSelector({
  formValues,
  allHairdressers,
  onSubmit,
  onChange,
  onClose,
  open,
}: IFiltersSelectorProps) {
  const displayFilterCheckboxes = getDisplayCheckboxes(formValues);

  const submit = () => {
    onSubmit();
    onClose();
  };
  return (
    <DesktopModalContainer open={open}>
      <React.Fragment>
        <DesktopModalHeader onClose={onClose} title="Filtres" />
        <form className="agenda-filters-form-mobile" onSubmit={submit}>
          <MobileSelect
            fullWidth
            multiple
            multiline
            label="Choix du professionnel"
            name="hairdresserIds"
            value={formValues.hairdresserIds}
            listTitle="Selectionner les professionnels"
            list={allHairdressers.map(hairdresserToInput)}
            onChange={onChange}
            getOptionalsToggleTitle={(showOptional) =>
              showOptional
                ? `▲ Cacher les professionnels désactivés`
                : `▼ Afficher les professionnels désactivés`
            }
          />
          <div className="input-group">
            {displayFilterCheckboxes.map((item) => (
              <div key={item.name}>
                <FormControlLabel
                  name={item.name}
                  classes={{ label: "label-checkbox" }}
                  control={<Checkbox />}
                  label={item.display}
                  checked={item.value}
                  onChange={onChange}
                  value={item.display}
                />
              </div>
            ))}
          </div>
        </form>
        <ButtonFab onClick={submit}>Valider</ButtonFab>
      </React.Fragment>
    </DesktopModalContainer>
  );
}

export default MobileCalendarFilterSelector;
