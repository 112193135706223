import { Dispatch } from "redux";

import * as API from "api/week";
import * as types from "constants/ActionTypes";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { Week, WeekPayload, Availability } from "reducers/week";
import { errorToString } from "utils/string";
import { pushMessage } from "./SnackbarActions";

interface FetchWeeksAction {
  type: typeof types.FETCH_WEEKS;
}

export interface FetchWeeksSuccessAction {
  type: typeof types.FETCH_WEEKS_SUCCESS;
  payload: WeekPayload;
}

interface FetchWeeksFailureAction {
  type: typeof types.FETCH_WEEKS_FAILURE;
  error: string;
}

interface FetchAvailabilitiesAction {
  type: typeof types.FETCH_AVAILABILITIES;
}

export interface FetchAvailabilitiesSuccessAction {
  type: typeof types.FETCH_AVAILABILITIES_SUCCESS;
  payload: WeekPayload;
}

interface FetchAvailabilitiesFailureAction {
  type: typeof types.FETCH_AVAILABILITIES_FAILURE;
  error: string;
}

export type WeekActions =
  | FetchWeeksAction
  | FetchWeeksSuccessAction
  | FetchWeeksFailureAction
  | FetchAvailabilitiesAction
  | FetchAvailabilitiesSuccessAction
  | FetchAvailabilitiesFailureAction;

export const fetchSuccess = (data: WeekPayload) => ({
  type: types.FETCH_WEEKS_SUCCESS,
  payload: { ...data },
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_WEEKS_FAILURE,
  error,
});

export const fetchWeeksBySalonId = (salonId: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: types.FETCH_WEEKS });
  return API.fetchBySalonId(salonId)
    .then(
      ({
        weeks,
        weeksByHairdresserId,
      }: {
        weeks: Array<Week>;
        weeksByHairdresserId: Record<number, Week>;
      }) => dispatch(fetchSuccess({ weeks, weeksByHairdresserId }))
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchAvailabilitiesSuccess = (data: WeekPayload) => ({
  type: types.FETCH_AVAILABILITIES_SUCCESS,
  payload: { ...data },
});

export const fetchAvailabilitiesFailure = (error: string) => ({
  type: types.FETCH_AVAILABILITIES_FAILURE,
  error,
});

export const fetchAvailabilitiesBySalonId = (salonId: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: types.FETCH_AVAILABILITIES });
  return API.getSalonAvailabilities(salonId)
    .then((availabilities: Record<number, Array<Availability>>) =>
      dispatch(
        fetchAvailabilitiesSuccess({
          availabilitiesByHairdresserId: availabilities,
        })
      )
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchAvailabilitiesFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
