import React, { Suspense } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorResult, GithubPickerProps } from "react-color";

import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";

const CirclePicker = React.lazy(() =>
  import("react-color").then(reactColor => ({
    default: reactColor.CirclePicker
  }))
);

const GithubPicker = React.lazy(() =>
  import("react-color").then(reactColor => ({
    default: reactColor.GithubPicker
  }))
);

interface ColorPickerProps {
  value: string;
  name: string;
  onChange?(event: React.ChangeEvent<any>): void;
  colors: Array<string>;
}

function ColorPicker(props: ColorPickerProps) {
  const { value, name, onChange, colors, ...otherProps } = props;
  const { isMobile } = useResponsiveLayout();
  const width = isMobile ? `${42 * colors.length}px` : "100%";

  const mobileProps = {
    width,
    colors,
    color: value,
    onChange: (newColor: ColorResult) => {
      const event = ({
        target: { name, value: newColor.hex }
      } as unknown) as React.ChangeEvent<any>;
      onChange && onChange(event);
    },
    ...otherProps
  };

  const desktopProps: GithubPickerProps = {
    triangle: "top-left",
    ...mobileProps
  };
  return (
    <Suspense fallback={<CircularProgress />}>
      <Mobile>
        <CirclePicker {...mobileProps} />
      </Mobile>
      <Desktop>
        <GithubPicker {...(desktopProps as any)} />
      </Desktop>
    </Suspense>
  );
}

export default ColorPicker;
