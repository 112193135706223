import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import useClickAway from "react-use/lib/useClickAway";

import { updateHairdresser, createHairdresser } from "actions/SalonActions";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { BLACK, BLUE_GREY, WHITE } from "constants/Style";
import { GENDERS, STATUSES, PICKABLE_COLORS } from "constants/Account";
import CameraIconLarge from "components/svgs/CameraIconLarge";
import Radio from "components/inputs/Radio";
import ResponsiveModalContent from "components/modal/Content/ResponsiveContent";
import TextInput from "components/inputs/Text";
import ColorBrushIcon from "components/svgs/ColorBrushIcon";
import NextIcon from "components/svgs/NextIcon";
import ButtonFab from "components/button/Fab";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import FakeInput from "components/inputs/FakeInput";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import HairdresserPackageSelector from "components/account/HairdresserPackageSelector/HairdresserPackageSelector";
import {
  getFileData,
  FileWithData,
} from "components/inputs/PictureListInput/PictureListInput";
import useCurrentSalon from "hooks/useCurrentSalon";
import { useForm } from "hooks/useForm";
import { useImageManager } from "hooks/useImageManager";
import {
  Hairdresser,
  Salon,
  hairdresserSchema,
  PartnerRef,
} from "reducers/salon";
import { pluralize } from "utils/string";
import styles from "./HairdresserForm.module.scss";
import ColorPicker from "./ColorPicker";
import useCurrentUser from "hooks/useCurrentUser";
import { getAssignedPackageList, getNewPartnerRefList } from "utils/package";
import PartnerRefs from "components/package/PackagePartnerRef/PartnerRefs";
import { isIFrame } from "utils/helpers";

const getNullHairdresser = (salon?: Salon): Hairdresser => ({
  id: -1,
  stringId: "",
  firstName: "",
  lastName: "",
  image: "",
  color: "",
  ratings: [],
  salonId: salon?.id ?? -1,
  packageIds: [],
  status: "",
  description: "",
  gender: "",
  partnerRefs: [],
  serviceIds: [],
});

const MODE = {
  CREATE: "CREATE" as const,
  UPDATE: "UPDATE" as const,
};

type IFormMode = keyof typeof MODE;

export interface HairdresserFormProps {
  hairdresser?: Hairdresser;
  mode: IFormMode;
  onHairdresserCreated(hairdresser: Hairdresser): void;
  onClose?(): void;
}

function HairdresserForm({
  hairdresser,
  mode,
  onHairdresserCreated,
  onClose,
}: HairdresserFormProps) {
  const { salon, packages } = useCurrentSalon();
  const dispatch = useDispatch();
  const [currentRefs, setCurrentRefs] = useState(
    hairdresser?.partnerRefs || []
  );

  const packagesAssigned =
    hairdresser && hairdresser?.serviceIds?.length > 0 && salon.isOneCatalog
      ? getAssignedPackageList(hairdresser, packages).map(({ prices }) =>
          Number(prices[0]?.stringId || "0")
        )
      : hairdresser?.packageIds || [];

  if (hairdresser) {
    hairdresser.packageIds = packagesAssigned;
  }

  const updatePartnerRefFormData = (name: string, id: string) =>
    setCurrentRefs(getNewPartnerRefList(currentRefs, name, id, true));

  const [newImage, setNewImage] = useState<FileWithData | null>(null);
  useEffect(() => {
    setNewImage(null);
    setCurrentRefs(hairdresser?.partnerRefs || []);
  }, [hairdresser?.id]);

  const onSubmit = useCallback(
    (editedHairdresser: Hairdresser) => {
      setCurrentRefs(currentRefs.filter((item: PartnerRef) => item.id !== ""));

      const update = () =>
        ((dispatch(
          updateHairdresser(salon.id, {
            ...editedHairdresser,
            partnerRefs: currentRefs.filter(
              (item: PartnerRef) => item.id !== ""
            ),
            packageIds: salon.isOneCatalog ? [] : editedHairdresser.packageIds,
          })
        ) as unknown) as Promise<any>).then(() => {
          dispatch(
            pushMessage(
              "Le profil a bien été mis à jour",
              MESSAGE_TYPES.SUCCESS
            )
          );
        });

      const create = () =>
        ((dispatch(
          createHairdresser(salon.id, {
            ...editedHairdresser,
            image: newImage?.data.split(",")[1],
            partnerRefs: currentRefs.filter(
              (item: PartnerRef) => item.id !== ""
            ),
          })
        ) as unknown) as Promise<any>).then(
          (createdHairdresser: Hairdresser) => {
            dispatch(
              pushMessage("Le profil a bien été crée", MESSAGE_TYPES.SUCCESS)
            );
            onHairdresserCreated(createdHairdresser);
          }
        );

      return mode === MODE.UPDATE ? update() : create();
    },
    [salon, mode, newImage, currentRefs]
  );
  return (
    <HairdresserFormTemplate
      hairdresser={hairdresser || getNullHairdresser(salon)}
      newImage={newImage}
      setNewImage={setNewImage}
      mode={mode}
      onSubmit={onSubmit}
      onClose={onClose}
      currentRefs={currentRefs}
      updatePartnerRefFormData={updatePartnerRefFormData}
    />
  );
}

interface HairdresserFormTemplateProps {
  hairdresser: Hairdresser;
  newImage: FileWithData | null;
  setNewImage(image: FileWithData | null): any;
  onClose?(): void;
  onSubmit(editedHairdresser: Hairdresser): Promise<any>;
  mode: IFormMode;
  currentRefs: Array<PartnerRef>;
  updatePartnerRefFormData(name: string, id: string): void;
}

export function HairdresserFormTemplate({
  hairdresser,
  newImage,
  setNewImage,
  mode,
  onClose,
  onSubmit: onSubmitFromProps,
  currentRefs,
  updatePartnerRefFormData,
}: HairdresserFormTemplateProps) {
  const disabled = hairdresser.id === 0 && hairdresser.stringId !== "";
  const { salon } = useCurrentSalon();
  const [isPackageFormOpened, setIsPackageFormOpened] = useState(false);
  const [isColorPickerOpened, setIsColorPickerOpened] = useState(false);

  const validate = useCallback((values: any) => {
    const errors = [
      "lastName",
      "firstName",
      "description",
      "gender",
      "status",
      "color",
    ].reduce((acc, field) => {
      const fieldValue = values[field];
      if (fieldValue === undefined || fieldValue === "") {
        return {
          ...acc,
          [field]: "Ce champs est requis",
        };
      }

      return acc;
    }, {});

    return errors;
  }, []);

  const { isMobile, isDesktop } = useResponsiveLayout();

  const onSubmit = (editedHairdresser: Hairdresser) => {
    if (disabled) {
      return Promise.resolve();
    }
    return onSubmitFromProps(editedHairdresser).then(() => {
      isMobile && onClose && onClose();
    });
  };

  const {
    values: formValues,
    handleSubmit,
    getFieldProps,
    getMuiErrorProps,
    touched,
    errors,
    isSubmitting,
    resetValues,
  } = useForm({
    initialValues: getNullHairdresser(),
    onSubmit,
    validate,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      resetValues(hairdresserSchema.validateSync(hairdresser));
    } catch (e) {
      dispatch(
        pushMessage(
          "Une erreur est survenur lors du chargement du professionnel",
          MESSAGE_TYPES.ERROR
        )
      );
      console.error(e);
      isMobile && onClose && onClose();
    }
  }, [hairdresser.id, hairdresser.stringId]);

  useEffect(() => {
    // It feels more intuitive to save the packagesId as soon as you complete
    // the packages modal in the Desktop UI
    if (isDesktop && formValues.packageIds !== hairdresser.packageIds) {
      handleSubmit();
    }
  }, [formValues.packageIds, isDesktop]);

  const submitButton = (
    <ButtonFab
      disabled={disabled}
      onClick={handleSubmit}
      isLoading={isSubmitting}
    >
      Valider
    </ButtonFab>
  );

  const colorPickerContainerRef = useRef(null);
  useClickAway(colorPickerContainerRef, () => {
    if (isColorPickerOpened) {
      setIsColorPickerOpened(false);
    }
  });

  const avatar = (
    <Avatar
      hairdresser={formValues}
      onChangeImage={getFieldProps("image").onChange}
    />
  );

  const onChangeNewImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;
    const { files } = target;
    const file = files?.[0];
    if (!file) {
      setNewImage(null);
      return;
    }

    const fileWithData = {
      id: `${file.name}_${file.size}`,
      file,
      data: await getFileData(file),
    };
    setNewImage(fileWithData);
  };

  const { isAdmin } = useCurrentUser();

  return (
    <React.Fragment>
      <div className={styles.container}>
        {mode === MODE.UPDATE && isMobile && avatar}
        <ResponsiveModalContent
          desktopProps={{ classes: { container: styles.content } }}
          noBackground={isIFrame()}
        >
          {mode === MODE.CREATE &&
            (newImage ? (
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={newImage.data}
                  alt="profil"
                  onError={() => setNewImage(null)}
                />
                <label htmlFor="upload-haidresser-image">
                  <CameraIconLarge
                    className={styles.cameraIcon}
                    color={WHITE}
                  />
                </label>
                <input
                  id="upload-haidresser-image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onChangeNewImage}
                />
              </div>
            ) : (
              <div className={styles.newHairdresserImageContainer}>
                <label htmlFor="upload-haidresser-image">
                  <CameraIconLarge />
                  <span>Ajouter une photo</span>
                </label>
                <input
                  id="upload-haidresser-image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onChangeNewImage}
                  disabled={disabled}
                />
              </div>
            ))}
          {mode === MODE.UPDATE && isDesktop && avatar}
          <div className="input-group input-group-inline flex">
            <TextInput
              fullWidth
              label="Nom"
              name="lastName"
              disabled={disabled}
              InputProps={{
                readOnly: mode === MODE.UPDATE && !isAdmin,
              }}
              {...getFieldProps("lastName", {
                error: errors.lastName && touched.lastName,
                helperText:
                  errors.lastName && touched.lastName
                    ? errors.lastName
                    : "(non visible sur le site)",
              })}
            />
            <TextInput
              fullWidth
              label="Prénom"
              name="firstName"
              disabled={disabled}
              InputProps={{
                readOnly: mode === MODE.UPDATE && !isAdmin,
              }}
              {...getFieldProps("firstName")}
              {...getMuiErrorProps("firstName")}
            />
          </div>
          <TextInput
            fullWidth
            multiline
            disabled={disabled}
            label="Description"
            name="description"
            className="input-group"
            {...getFieldProps("description")}
            {...getMuiErrorProps("description")}
          />
          <FormGroup className="input-group">
            <InputLabel className="input_label">Genre</InputLabel>
            <div className={styles.radiosContainer}>
              <FormControlLabel
                disabled={disabled}
                label="Femme"
                name="gender"
                classes={{
                  label: "label-checkbox",
                  root: "hairdresser-form-radio-input",
                }}
                control={<Radio />}
                {...getFieldProps("gender", {
                  value: GENDERS.F,
                  checked: formValues.gender === GENDERS.F,
                })}
              />
              <FormControlLabel
                disabled={disabled}
                label="Homme"
                name="gender"
                classes={{
                  label: "label-checkbox",
                  root: "hairdresser-form-radio-input",
                }}
                control={<Radio />}
                {...getFieldProps("gender", {
                  value: GENDERS.M,
                  checked: formValues.gender === GENDERS.M,
                })}
              />
              {errors.gender && touched.gender && (
                <FormHelperText error>{errors.gender}</FormHelperText>
              )}
            </div>
          </FormGroup>
          <FormGroup className="input-group">
            <InputLabel className="input_label">Statut</InputLabel>
            <div className={styles.radiosContainer}>
              <FormControlLabel
                label="Activé"
                name="status"
                disabled={disabled}
                classes={{
                  label: "label-checkbox",
                  root: "hairdresser-form-radio-input",
                  disabled: "input-disabled-no-style",
                }}
                control={<Radio />}
                {...getFieldProps("status", {
                  value: STATUSES.ENABLED,
                  checked: formValues.status === STATUSES.ENABLED,
                })}
              />
              <FormControlLabel
                label="Désactivé"
                name="status"
                disabled={disabled}
                classes={{
                  label: "label-checkbox",
                  root: "hairdresser-form-radio-input",
                  disabled: "input-disabled-no-style",
                }}
                control={<Radio />}
                {...getFieldProps("status", {
                  value: STATUSES.DISABLED,
                  checked: formValues.status === STATUSES.DISABLED,
                })}
              />
              {errors.status && touched.status && (
                <FormHelperText error>{errors.status}</FormHelperText>
              )}
            </div>
            <div className="input-group flex">
              <ColorBrushIcon color={formValues.color || BLACK} />
              <div className={styles.colorInputContainer}>
                <div className="input-group">
                  <TextField
                    disabled={disabled}
                    variant="standard"
                    value={
                      isColorPickerOpened
                        ? ""
                        : formValues.color.length > 0
                        ? "Modifier"
                        : "Choisir"
                    }
                    fullWidth
                    label="Couleur agenda"
                    InputLabelProps={{
                      shrink: true,
                      className: "input_label",
                    }}
                    InputProps={{
                      className: "input_input",
                      endAdornment: <NextIcon color={BLUE_GREY} />,
                    }}
                    onClick={() => !disabled && setIsColorPickerOpened(true)}
                    error={errors.color && touched.color}
                    helperText={touched.color && errors.color}
                  />
                  {isColorPickerOpened && (
                    <div
                      className={styles.colorPickerContainer}
                      ref={colorPickerContainerRef}
                    >
                      <ColorPicker
                        {...getFieldProps("color", {
                          onChange: () => {
                            setIsColorPickerOpened(false);
                          },
                          colors: PICKABLE_COLORS,
                        })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="input-group">
              <TextField
                disabled={salon.isOneCatalog}
                variant="standard"
                value={`${pluralize(
                  `${formValues.packageIds.length} prestation assignée`,
                  `${formValues.packageIds.length} prestations assignées`,
                  formValues.packageIds.length
                )}`}
                fullWidth
                label="Prestations assignées"
                InputLabelProps={{
                  shrink: false,
                  className: "input_label",
                }}
                InputProps={{
                  className: "input_input",
                  inputComponent: FakeInput,
                  endAdornment: <NextIcon color={BLUE_GREY} />,
                }}
                onClick={() => {
                  setIsPackageFormOpened(true);
                }}
              />
              {salon.isOneCatalog && (
                <span>
                  L'assignation des prestations est disponible sur{" "}
                  <a
                    href="https://app.kiute.com/#/app/humanResource?sDm=skill"
                    target="_blank"
                  >
                    app.kiute.com
                  </a>
                </span>
              )}
            </div>
          </FormGroup>
          {isAdmin && (
            <div
              className="package-partner-form-container"
              style={{ marginTop: "10px" }}
            >
              <div>
                {currentRefs.length === 0 &&
                  "Aucun code partenaire pour ce professionnel"}
                <PartnerRefs
                  currentRefs={currentRefs}
                  updateSingleRef={updatePartnerRefFormData}
                />
              </div>
            </div>
          )}
          {isDesktop && submitButton}
        </ResponsiveModalContent>
        {isMobile && submitButton}
      </div>
      <ResponsiveModalContainer
        open={isPackageFormOpened}
        fullScreenOnMobile
        direction="left"
      >
        <HairdresserPackageSelector
          {...getFieldProps("packageIds", {
            onClose: () => {
              setIsPackageFormOpened(false);
            },
            title: "Prestations assignées",
          })}
        />
      </ResponsiveModalContainer>
    </React.Fragment>
  );
}

function Avatar({
  hairdresser,
  onChangeImage,
}: {
  hairdresser: Hairdresser;
  onChangeImage(event: React.ChangeEvent<any>): void;
}) {
  const { getHairdresserImageUrl, hairdresserFileUploader } = useImageManager();
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { target } = event;
    const { files } = target;
    if (isLoading) {
      target.value = "";
      return;
    }
    setIsLoading(true);
    hairdresserFileUploader(hairdresser.id, files).then((result) => {
      target.value = "";
      onChangeImage({ target: { value: result?.image } } as React.ChangeEvent<
        any
      >);
      setIsLoading(false);
    });
  };

  const icon = isLoading ? (
    <div className={styles.loaderContainer}>
      <CircularProgress size={15} />
    </div>
  ) : (
    <CameraIconLarge className={styles.cameraIcon} color={WHITE} />
  );

  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    setImageError(false);
  }, [hairdresser.image]);
  return (
    <div className={styles.imageContainer}>
      <img
        className={styles.image}
        src={getHairdresserImageUrl(
          imageError ? { ...hairdresser, image: undefined } : hairdresser
        )}
        alt="profil"
        onError={() => setImageError(true)}
      />
      <label htmlFor="upload-haidresser-image">{icon}</label>
      <input
        id="upload-haidresser-image"
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChange}
      />
    </div>
  );
}

export default HairdresserForm;
