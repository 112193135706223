import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchRatingPageContainer from "containers/Rating/SearchRatingPage";
import HairdressersTab from "containers/Rating/HairdressersTab";
import SalonTab from "containers/Rating/SalonTab";
import { paramList, routeList } from "constants/Routes";
import { DARK } from "constants/Style";
import FullscreenModal from "components/modal/FullscreenModal/FullscreenModal";
import { parseRoute } from "utils/route";
import styles from "./RatingPage.module.scss";
import PageContainer from "../page/Container";
import MobilePageHeader from "../page/Header.mobile";
import PageContent from "../page/Content";
import SearchIcon from "../svgs/SearchIcon";
import Mobile from "../ResponsiveLayout/Mobile";
import Desktop from "../ResponsiveLayout/Desktop";
import MenuDesktop from "../Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "../layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import IFrameContainer from "components/iframe/IFrameContainer";

interface RatingPageProps {
  title: string;
  currentTab: string;
  isLoading: boolean;
}

const tabs = [
  {
    value: paramList.RATING.HAIRDRESSERS,
    label: "Professionnels",
    component: HairdressersTab,
  },
  {
    value: paramList.RATING.SALON,
    label: "Salon",
    component: SalonTab,
  },
];

const getTabInfos = (tabValue: string) => {
  return (
    tabs.find((tab) => tab.value === tabValue) ?? {
      component: null,
      value: null,
      label: null,
    }
  );
};

function RatingPage(props: RatingPageProps) {
  const history = useHistory();
  const { title, currentTab, isLoading } = props;
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const { value, component: Component } = getTabInfos(currentTab);
  const goToTab = (value: string) => history.push(value);
  const content = (
    <>
      {Component && <Component />}
      {isLoading && (
        <CircularProgress className="calendar-loader" color="primary" />
      )}
    </>
  );
  return (
    <React.Fragment>
      <Mobile>
        <PageContainer>
          <MobilePageHeader
            dense
            center={<span>{title}</span>}
            right={<SearchIcon onClick={() => setIsSearchOpened(true)} />}
          >
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={(_, value) => goToTab(value)}
              className={styles.tabsContainer}
              TabIndicatorProps={{
                className: styles.tabIndicator,
              }}
              action={(action) => action && action.updateIndicator()}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  className={styles.tabContainer}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </MobilePageHeader>
          <PageContent>{content}</PageContent>
        </PageContainer>
        <FullscreenModal open={isSearchOpened} direction="up">
          <SearchRatingPageContainer onClose={() => setIsSearchOpened(false)} />
        </FullscreenModal>
      </Mobile>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          {isSearchOpened ? (
            <SearchRatingPageContainer
              onClose={() => setIsSearchOpened(false)}
            />
          ) : (
            <SearchIcon
              className={styles.searchIcon}
              color={DARK}
              onClick={() => setIsSearchOpened(true)}
            />
          )}
          <DesktopLayoutWithSidebarLinks
            links={[
              {
                label: "Avis sur les professionnels",
                route: parseRoute(routeList.RATING_PAGE_HAIRDRESSERS),
              },
              {
                label: "Avis sur l'établissement",
                route: parseRoute(routeList.RATING_PAGE_SALON),
              },
            ]}
          >
            {content}
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
    </React.Fragment>
  );
}

export default RatingPage;
