import React from "react";
import Fab, { FabProps } from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import useResponsiveLayout from "../ResponsiveLayout/useResponsiveLayout";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?(): void;
  warning?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
}

function ButtonFab({
  children,
  onClick,
  warning,
  isLoading,
  disabled,
  fullWidth
}: ButtonProps & FabProps) {
  const { isMobile } = useResponsiveLayout();
  const gridClassNames = classNames("button-container", {
    "button-container-mobile": isMobile,
    "button-container-fullWidth": fullWidth,
    "button-container-warning": warning
  });
  return (
    <Grid container justify="space-around" className={gridClassNames}>
      <Fab
        variant="extended"
        className="button"
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading ? <CircularProgress color="secondary" /> : children}
      </Fab>
    </Grid>
  );
}

export default ButtonFab;
