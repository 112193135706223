import moment from "moment";
import scheduleManager, {
  CommonRange,
  SameDiscountAndHairdresserId,
} from "@leciseau/schedule-manager";
import { Slot } from "reducers/slot";
import { Availability } from "reducers/week";
import { AGENDA_EVENT_KINDS } from "components/agenda/AgendaPage/AgendaPage";
import { CalendarEvent } from "components/Calendar";

export const eventToCommonRange = (event: Slot | Availability) =>
  ({
    ...event,
  } as CommonRange);

export const commonRangeToCalendarEvent = (
  commonRange: CommonRange & { hairdresserStringId?: string }
) => {
  return {
    id: 0,
    resourceId:
      commonRange.hairdresserId ||
      (commonRange.hairdresserStringId &&
        Number(commonRange.hairdresserStringId.split("|")[1])),
    hairdresserStringId: commonRange.hairdresserStringId,
    start: moment(commonRange.range.start),
    end: moment(commonRange.range.end),
    discount: commonRange.discount,
    meta: {
      availability: commonRange,
      kind: AGENDA_EVENT_KINDS.AVAILABLE,
    },
  } as CalendarEvent<any>;
};

const rangePerDayPerHairdresser = (ranges: Array<CommonRange>) =>
  ranges.reduce(
    (acc: Record<string, Array<CommonRange>>, range: CommonRange) => {
      const day =
        typeof range.range.start === "string"
          ? range.range.start.split("T")[0]
          : "";
      const key = `${day}:${range.hairdresserId}`;
      return {
        ...acc,
        [key]: [...(acc[key] || []), range],
      };
    },
    {}
  );

export const removeEachDay = (
  avails: Array<CommonRange>,
  slots: Array<CommonRange>
) => {
  const orderedAvails = rangePerDayPerHairdresser(avails);
  const orderedSlots = rangePerDayPerHairdresser(slots);

  const availsPerDay = Object.keys(orderedAvails).map((key: any) => {
    const availsForDay = orderedAvails[key];
    const slotsForDay = orderedSlots[key] || [];
    const result = scheduleManager.Remove(availsForDay, slotsForDay);

    return [...result];
  });

  const finalAvails = availsPerDay.reduce(
    (acc: any, avails: any) => [...acc, ...avails],
    []
  );

  return finalAvails;
};

export const mergeCommonRanges = (ranges: Array<CommonRange>) => {
  return scheduleManager.Merge(ranges, SameDiscountAndHairdresserId);
};

export const splitRangesWithLastMinutes = (
  ranges: CommonRange[],
  // These lastMinutes are not bound by the boundaries of slots and avails
  rawLastMinutes: CommonRange[]
): { newRanges: CommonRange[]; realLastMinuteRanges: CommonRange[] } => {
  const results = ranges.reduce(
    (acc, range) => {
      const overlaps = rawLastMinutes
        .filter(
          (lm) =>
            moment(lm.range.start).isBefore(range.range.end) &&
            moment(lm.range.end).isAfter(range.range.start) &&
            (lm?.discount || 0) > (range?.discount || 0) &&
            lm.hairdresserId === range.hairdresserId
        )
        .sort((a, b) => (a?.discount || 0) - (b?.discount || 0));
      if (overlaps.length === 0) {
        return {
          newRanges: [...acc.newRanges, range],
          realLastMinuteRanges: [...acc.realLastMinuteRanges],
        };
      }
      const overlap = overlaps[0];
      const splitStart = moment(overlap.range.start).isBefore(range.range.start)
        ? range.range.start
        : overlap.range.start;
      const splitEnd = moment(overlap.range.end).isAfter(range.range.end)
        ? range.range.end
        : overlap.range.end;

      const realLastMinuteRange = {
        ...overlap,
        range: {
          start: splitStart,
          end: splitEnd,
        },
      };

      const preSplitStart = range.range.start;
      const preSplitEnd = splitStart;

      const postSplitStart = splitEnd;
      const postSplitEnd = range.range.end;

      const newRanges = [
        ...(preSplitStart !== preSplitEnd
          ? [{ ...range, range: { start: preSplitStart, end: preSplitEnd } }]
          : []),
        ...(postSplitStart !== postSplitEnd
          ? [{ ...range, range: { start: postSplitStart, end: postSplitEnd } }]
          : []),
      ];

      if (newRanges.length > 0) {
        const subResults = splitRangesWithLastMinutes(
          newRanges,
          rawLastMinutes
        );
        return {
          newRanges: [...acc.newRanges, ...subResults.newRanges],
          realLastMinuteRanges: [
            ...acc.realLastMinuteRanges,
            ...subResults.realLastMinuteRanges,
            realLastMinuteRange,
          ],
        };
      }

      return {
        newRanges: [...acc.newRanges, ...newRanges],
        realLastMinuteRanges: [
          ...acc.realLastMinuteRanges,
          realLastMinuteRange,
        ],
      };
    },
    { newRanges: [], realLastMinuteRanges: [] } as {
      newRanges: CommonRange[];
      realLastMinuteRanges: CommonRange[];
    }
  );

  return results;
};
