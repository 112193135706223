import React, { useState } from "react";
import DesktopButton from "components/button/Desktop/Button.desktop";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import { Package, Salon, PartnerRef } from "reducers/salon";
import { matchFlexyPackages } from "api/salon";
import { FLEXY_PARTNER_NAME } from "constants/Account";
import { synchroSaas } from "utils/agenda";
import { useDispatch } from "react-redux";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";

const launchMapping = async (
  setMappingResult: (result: []) => void,
  setLoading: (loading: boolean) => void,
  companyCode: string,
  packageIds: Array<number>,
  refetchSalon: () => void
) => {
  setLoading(true);
  const results = await matchFlexyPackages(companyCode, packageIds);
  setMappingResult(results);
  setLoading(false);
  refetchSalon();
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center" as "center",
  },
  sub: {
    paddingBottom: "20px",
  },
  list: {
    height: "100px",
    overflowY: "scroll" as "scroll",
  },
  item: {
    textAlign: "left" as "left",
  },
};

const packagesAsIds = (packages: Array<Package>) =>
  packages.map((pack) => pack.id);

interface FlexyMatchingModalProps {
  isFlexyMatchingModalOpened: boolean;
  setIsFlexyMatchingModalOpened(opened: boolean): void;
  packages: Array<Package>;
  salon: Salon;
  refetchSalon(): void;
}

export const FlexyMatchingModal = ({
  isFlexyMatchingModalOpened,
  setIsFlexyMatchingModalOpened,
  packages,
  salon,
  refetchSalon,
}: FlexyMatchingModalProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mappingResult, setMappingResult] = useState([]);

  const partnerRef = salon?.partnerRefs?.find(
    (ref: PartnerRef) => ref.name === FLEXY_PARTNER_NAME
  );

  return (
    <ResponsiveModalContainer
      fullScreenOnMobile
      open={isFlexyMatchingModalOpened}
      direction="left"
    >
      <div style={styles.container}>
        <div>
          <DesktopButton
            text="Fermer"
            onClick={() => setIsFlexyMatchingModalOpened(false)}
          />
          <br />
          <div style={styles.sub}>
            {loading && "Chargement..."}
            {!loading && !partnerRef && (
              <p>
                Cet établissement n'est pas relié à Flexy, impossible de mapper
                les prestations.
                <br />
                Si ce n'est pas le cas, essayer de recharger la page.
              </p>
            )}
            {!loading && partnerRef && (
              <DesktopButton
                text="Lancer le mapping"
                onClick={() =>
                  launchMapping(
                    setMappingResult,
                    setLoading,
                    partnerRef.id || "",
                    packagesAsIds(packages),
                    refetchSalon
                  ).then(() => {
                    synchroSaas(partnerRef.id || "").then(() =>
                      dispatch(
                        pushMessage(
                          "Agenda en cours de synchronisation",
                          MESSAGE_TYPES.SUCCESS
                        )
                      )
                    );
                  })
                }
              />
            )}
            <br />
            <br />
            <div
              style={{
                ...styles.list,
                display: mappingResult.length > 0 ? "block" : "none",
              }}
            >
              <div style={styles.item}>
                Nombre d'erreurs:{" "}
                {!loading &&
                  mappingResult.filter((result: any) => !result.found).length}
              </div>
              <br />
              {!loading &&
                mappingResult
                  .sort(
                    (r1: any, r2: any) =>
                      (r2.found ? 0 : 1) - (r1.found ? 0 : 1)
                  )
                  .map((result: any) => (
                    <div key={result.flexyId} style={styles.item}>
                      {result.name} <small>({result.flexyId})</small>{" "}
                      <span
                        style={{ color: result.found ? "green" : "salmon" }}
                      >
                        {result.found ? "Succès" : "Échec"}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </ResponsiveModalContainer>
  );
};

export default FlexyMatchingModal;
