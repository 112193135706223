import React, { useState, useEffect, memo } from "react";
import { Week } from "reducers/week";
import { Salon, Hairdresser } from "reducers/salon";
import Desktop from "components/ResponsiveLayout/Desktop";
import Mobile from "components/ResponsiveLayout/Mobile";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "components/layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import styles from "./styles.module.scss";
import { ACCOUNT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import Button from "../../button/Desktop/Button.desktop";
import { updateWeek, createWeek as createWeekAPI } from "api/week";
import { useDispatch } from "react-redux";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import PlusCircle from "components/svgs/PlusCircle";
import MobilePageContainer from "../../page/Container.mobile";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import { createEmptyDays, createConflicts } from "utils/week";
import { DayName } from "constants/Week";
import { Conflict } from "constants/Week";
import HappyHourForm from "./HappyHourForm";
import HairdresserModal from "../HairdresserModal/HairdresserModal";
import IFrameContainer from "components/iframe/IFrameContainer";
import MobilePageHeader from "../../page/Header.mobile";
import { isIFrame } from "utils/helpers";
import CloseIcon from "components/svgs/CloseIcon";
import useCurrentSalon from "hooks/useCurrentSalon";

interface HappyHoursPageProps {
  salon: Salon;
  onClose(): void;
  hairdressers: Array<Hairdresser>;
  weeks: Array<Week>;
  fetchCurrentWeeks(): void;
  fetchCurrentAvailabilities(): void;
}

const HappyHoursPage = ({
  salon,
  onClose,
  hairdressers,
  weeks,
  fetchCurrentWeeks,
  fetchCurrentAvailabilities,
}: HappyHoursPageProps) => {
  // When on this page, weeks are editable until clicking on the "Submit" button
  // So we make a copy
  const [weeksToSubmit, setWeeksToSubmit] = useState<Array<Week>>([]);
  const [conflicts, setConflicts] = useState<Array<Conflict>>([]);
  const [isHairdresserSelectorOpen, setHairdresserSelectorOpen] = useState(
    false
  );
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { companyCode } = useCurrentSalon();
  const dispatch = useDispatch();

  useEffect(() => {
    setWeeksToSubmit(
      weeks
        .filter((week) => week.discount !== 0)
        .map((week: Week) => ({
          ...week,
          start: Object.values(week.days).find((day) => day)?.start,
          end: Object.values(week.days).find((day) => day)?.end,
        }))
    );
  }, [weeks]);

  const saveWeek = (oldIndex: number, week: Week) => {
    if (oldIndex === -1) {
      setWeeksToSubmit([...weeksToSubmit, week]);
      return;
    }

    const newWeek = {
      ...week,
      days: Object.keys(week.days).reduce(
        (acc, dayName) => ({
          ...acc,
          [dayName as DayName]: week.days[dayName as DayName]
            ? {
                start: week.start,
                end: week.end,
              }
            : null,
        }),
        createEmptyDays()
      ),
    };

    setWeeksToSubmit(
      weeksToSubmit.map((toSubmit: Week, index: number) =>
        index === oldIndex ? newWeek : toSubmit
      )
    );
  };

  const updateConflicts = () => {
    const allConflicts = createConflicts(weeksToSubmit);

    setConflicts(allConflicts.filter(Boolean) as Array<Conflict>);
  };

  useEffect(() => {
    updateConflicts();
  }, [weeksToSubmit]);

  const resolveConflicts = (weeks: Array<Week>, conflicts: Array<Conflict>) =>
    weeks
      .map((week: Week, index: number) => ({
        ...week,
        hairdresserIds: week.hairdresserIds
          ? week.hairdresserIds.filter(
              (id) =>
                !conflicts.find(
                  (conflict: Conflict) =>
                    conflict.conflict === index && conflict.hairdresserId === id
                )
            )
          : [],
        hairdresserStringIds: week.hairdresserStringIds
          ? week.hairdresserStringIds.filter(
              (stringId) =>
                !conflicts.find(
                  (conflict: Conflict) =>
                    conflict.conflict === index &&
                    conflict.hairdresserStringId &&
                    conflict.hairdresserStringId.indexOf(stringId) !== -1
                )
            )
          : [],
      }))
      .map((week: Week) => ({
        ...week,
        status:
          week.hairdresserIds.length > 0 ||
          week.hairdresserStringIds.length > 0 ||
          week.status === "DELETED"
            ? week.status
            : "DISABLED",
      }));

  const saveWeeks = () => {
    const weeksToSave = resolveConflicts(weeksToSubmit, conflicts);

    Promise.all(
      weeksToSave.map((week: Week) => {
        if (week.id) {
          return updateWeek(week);
        }

        if (week.status !== "DELETED") {
          return createWeekAPI(week);
        }
      })
    ).then(() => {
      dispatch(pushMessage("Happy Hours sauvegardés", MESSAGE_TYPES.SUCCESS));
      fetchCurrentWeeks();
      fetchCurrentAvailabilities();
    });
  };

  const content = (
    <div className={styles.container}>
      <HairdresserModal
        isHairdresserSelectorOpen={isHairdresserSelectorOpen}
        setHairdresserSelectorOpen={setHairdresserSelectorOpen}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        hairdressers={hairdressers}
        weeksToSubmit={weeksToSubmit}
        saveWeek={saveWeek}
        companyCode={companyCode}
      />
      <div>
        <p className={styles.lightBorder}>
          Proposer des offres récurrentes pour remplir vos heures creuses
        </p>
      </div>
      <div>
        {weeksToSubmit
          .filter((week) => week.status !== "DELETED")
          .map((week: Week, index: number) => (
            <HappyHourForm
              week={week}
              index={index}
              conflicts={conflicts}
              setHairdresserSelectorOpen={setHairdresserSelectorOpen}
              setCurrentIndex={setCurrentIndex}
              hairdressers={hairdressers}
              saveWeek={saveWeek}
            />
          ))}
        <Button
          onClick={() =>
            saveWeek(-1, {
              id: 0,
              hairdresserIds: [],
              hairdresserStringIds: [],
              duration: 8,
              salonId: salon.id,
              status: "ENABLED",
              discount: 50,
              days: createEmptyDays(),
            })
          }
          className={styles.textButton}
          element={
            <span>
              <PlusCircle hover /> <span>Ajouter une happy hour</span>
            </span>
          }
        />
      </div>
      <Button
        secondary
        style={{
          margin: "30px auto",
        }}
        className={styles.saveButton}
        onClick={() => saveWeeks()}
        text="Enregistrer les modifications"
      />
    </div>
  );

  return (
    <div>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
          >
            <div className={styles.mainContainer}>
              <h2>Promotions Happy Hour</h2>
              {content}
            </div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={isIFrame() ? undefined : <CloseIcon onClick={onClose} />}
                center="Promotions Happy Hour"
                parent="account"
              />
            }
            body={content}
          />
        </MobilePageContainer>
      </Mobile>
    </div>
  );
};

export default memo(HappyHoursPage);
