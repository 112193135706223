import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import LoginPage from "../../components/login/LoginPage";
import * as authActions from "../../actions/AuthActions";
import * as snackbarActions from "../../actions/SnackbarActions";
import { AuthState } from "../../reducers/auth";
import { AppState } from "../../reducers";
import { resetPassword } from "./utils";
import { MESSAGE_TYPES } from "../../constants/SnackBar";
import { routeList } from "../../constants/Routes";
import { parseRoute } from "../../utils/route";
import { withRouter, RouteComponentProps } from "react-router";

interface LoginPageContainerProps extends RouteComponentProps<any> {
  authActionDispatcher: typeof authActions;
  snackbarActionDispatcher: typeof snackbarActions;
  authState: AuthState;
}

function LoginPageContainer({
  authState,
  authActionDispatcher,
  snackbarActionDispatcher,
  history,
}: LoginPageContainerProps) {
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((oldState) => ({
      ...oldState,
      [event.target.name]: event.target.value,
    }));
  };

  const login = () => {
    (authActionDispatcher.classicFormlogin(state) as any).then(
      (loginSuccess: boolean) => {
        if (loginSuccess) {
          history.push(parseRoute(routeList.AGENDA_PAGE));
        }
      }
    );
  };

  const onResetPassword = (email: string) => {
    return resetPassword(email)
      .then(() => {
        snackbarActionDispatcher.pushMessage(
          "Nous venons d'envoyer un email à cette adresse pour mettre à jour le mot de passe.",
          MESSAGE_TYPES.SUCCESS
        );
      })
      .catch((error: string) => {
        snackbarActionDispatcher.pushMessage(error, MESSAGE_TYPES.ERROR);
      });
  };

  return (
    <LoginPage
      {...state}
      onChange={handleChange}
      onSubmit={login}
      isLoading={authState.loading}
      onResetPassword={onResetPassword}
    />
  );
}

function mapState(state: AppState) {
  return {
    authState: state.auth,
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    authActionDispatcher: bindActionCreators(authActions, dispatch),
    snackbarActionDispatcher: bindActionCreators(snackbarActions, dispatch),
  };
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(LoginPageContainer as any) as any);
