import React from "react";

const PaymentMenuIcon = (props: any) => (
  <svg viewBox="0 0 60 60" height={25}>
    <defs>
      <style>
        {
          ".payment-1{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}"
        }
      </style>
    </defs>
    <path className="payment-1" d="M29.22 21.31h16.07l3.13 9.98" />
    <path className="payment-1" d="M9.72 31.27h40.65v13H9.72z" />
    <path
      className="payment-1"
      d="M11.68 31.29l3.9-9.98h5.28M17.75 26.3h11.08"
    />
    <path
      className="payment-1"
      d="M18.72 26.3s3-3.14 2.67-10.5h7.68s1 7.12-1.95 10.5M23.34 37.06v1.97h13.41v-2.38"
    />
  </svg>
);

export default PaymentMenuIcon;
