import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import classnames from "classnames";

import { WEEK_DAYS } from "constants/Agenda";
import { BLUE_GREY } from "constants/Style";
import Check from "components/inputs/Check";
import Switch from "components/inputs/Switch";
import PickerArrow from "components/svgs/PickerArrow";
import WheelPicker from "components/inputs/WheelPicker";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import Checkbox from "components/inputs/Checkbox";
import Select from "components/inputs/Select";
import { toggleArrayItem } from "utils/form";
import { pluralize } from "utils/string";
import styles from "./SlotRepetitionForm.module.scss";

interface SlotRepetitionFormProps {
  dayToRepeat: number;
  repeatSlot: boolean;
  repeatSlotWeekDays: Array<number>;
  repeatSlotNumberOfWeek: number;
  onChange(event: React.ChangeEvent<{}>, checked?: boolean): void;
}

function SlotRepetitionForm(props: SlotRepetitionFormProps) {
  const {
    dayToRepeat,
    repeatSlot,
    repeatSlotWeekDays,
    repeatSlotNumberOfWeek,
    onChange
  } = props;
  const isSelected = (index: number) =>
    repeatSlot && repeatSlotWeekDays.includes(index);
  const onDaysChange = (day: number) => {
    if (dayToRepeat === day) return;
    const newSelectedDays = toggleArrayItem(repeatSlotWeekDays, day);
    onChange({
      target: {
        value: newSelectedDays,
        name: "repeatSlotWeekDays"
      }
    } as any);
  };
  const onChangeNumberOfDays = ({ repeatSlotNumberOfWeek }: any) => {
    onChange({
      target: {
        value: parseInt(repeatSlotNumberOfWeek),
        name: "repeatSlotNumberOfWeek"
      }
    } as any);
  };

  const toggleRepeatSlotNumberOfWeekSwitch = ({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    const open = target.value;
    onChangeNumberOfDays({ repeatSlotNumberOfWeek: open ? 1 : 0 });
  };

  const labelClassName = (index: number) =>
    classnames("label-checkbox", {
      selected: isSelected(index)
    });
  return (
    <React.Fragment>
      <Mobile>
        <div className="ui-space_between-line">
          <span>Répeter la disponibilité cette semaine</span>
          <Switch name="repeatSlot" value={repeatSlot} onChange={onChange} />
        </div>
        <Collapse in={repeatSlot}>
          <div className="wide-list-underline" />
          {WEEK_DAYS.map(day => (
            <div
              key={day.value}
              className="agenda-repeat_slot-day_line-container"
            >
              <FormControlLabel
                name="repeatSlotWeekDays"
                classes={{ label: labelClassName(day.value) }}
                control={<Check />}
                label={day.label}
                checked={isSelected(day.value)}
                onChange={() => onDaysChange(day.value)}
                value={day.label}
                labelPlacement="start"
              />
            </div>
          ))}
          <div className="input-group">
            <div className="ui-space_between-line">
              <span>Répeter sur les semaines prochaines</span>
              <Switch
                name="repeatSlotNumberOfWeekSwitch"
                value={repeatSlotNumberOfWeek > 0}
                onChange={toggleRepeatSlotNumberOfWeekSwitch}
              />
            </div>
            <Collapse in={repeatSlotNumberOfWeek > 0}>
              <div className="wide-list-underline" />
              <WheelPicker
                values={{ repeatSlotNumberOfWeek }}
                onChange={onChangeNumberOfDays}
                renderValues={({ repeatSlotNumberOfWeek: nWeeks }) =>
                  nWeeks
                    ? `${nWeeks} ${pluralize(
                        "semaine",
                        "semaines",
                        parseInt(nWeeks)
                      )}`
                    : ""
                }
              >
                <WheelPicker.Input
                  render={({ value, onClick }) => (
                    <div className="ui-space_between-line mobile_picker-container">
                      <span className="mobile_picker-label">Pendant</span>
                      <Input
                        fullWidth
                        type="number"
                        readOnly
                        className="input_input"
                        inputProps={{
                          className: "mobile_picker-input"
                        }}
                        name="repeatSlotNumberOfWeek"
                      />
                      <span
                        onClick={onClick}
                        className="mobile_picker-display_value"
                      >
                        {value}
                      </span>
                      <PickerArrow
                        onClick={onClick}
                        large
                        className="input_date-picker-arrow repeat-slot-weeks-arrow"
                        color={BLUE_GREY}
                      />
                    </div>
                  )}
                />
                <WheelPicker.Wheels>
                  <WheelPicker.Wheel
                    name="repeatSlotNumberOfWeek"
                    values={Array.from({ length: 9 }).map((_, index) =>
                      (index + 1).toString()
                    )}
                    renderValue={nWeeks =>
                      nWeeks
                        ? `${nWeeks} ${pluralize(
                            "semaine",
                            "semaines",
                            parseInt(nWeeks)
                          )}`
                        : ""
                    }
                  />
                </WheelPicker.Wheels>
              </WheelPicker>
            </Collapse>
          </div>
        </Collapse>
      </Mobile>
      <Desktop>
        <Box className={styles.desktopRepeatInput}>
          <Switch name="repeatSlot" value={repeatSlot} onChange={onChange} />
          <span className={styles.repeatLabel}>
            Répeter la disponibilité cette semaine
          </span>
        </Box>
        <Box className={styles.desktopDaySelection}>
          {WEEK_DAYS.map(day => (
            <FormControlLabel
              key={day.value}
              name="repeatSlotWeekDays"
              classes={{
                root: styles.checkoxControl,
                label: classnames({
                  [styles.selectedLabel]: isSelected(day.value)
                })
              }}
              control={<Checkbox />}
              label={day.label}
              checked={isSelected(day.value)}
              onChange={() => onDaysChange(day.value)}
              value={day.label}
              labelPlacement="end"
              disabled={!repeatSlot}
            />
          ))}
        </Box>
        <Box className={styles.desktopFollowingWeeksInput}>
          <Switch
            name="repeatSlotNumberOfWeekSwitch"
            value={repeatSlot && repeatSlotNumberOfWeek > 0}
            onChange={toggleRepeatSlotNumberOfWeekSwitch}
            disabled={!repeatSlot}
          />
          <span
            className={classnames(styles.repeatLabel, {
              [styles.disabled]: !repeatSlot
            })}
          >
            Répeter sur les semaines prochaines
          </span>
          <Collapse in={repeatSlot && repeatSlotNumberOfWeek > 0}>
            <Select
              fullWidth
              label="Pendant"
              name="repeatSlotNumberOfWeek"
              value={repeatSlotNumberOfWeek || ""}
              labelProps={{
                shrink: false,
                className: styles.desktopWeeksSelectLabel
              }}
              classes={{
                root: styles.desktopWeeksSelect
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                }
              }}
              renderValue={nWeeks =>
                (nWeeks as number)
                  ? `${nWeeks} ${pluralize(
                      "semaine",
                      "semaines",
                      nWeeks as number
                    )}`
                  : ""
              }
              onChange={e =>
                onChangeNumberOfDays({ repeatSlotNumberOfWeek: e.target.value })
              }
              disabled={!repeatSlot || repeatSlotNumberOfWeek === 0}
            >
              {Array.from({ length: 9 }).map((_, index) => (
                <MenuItem key={index} value={index + 1}>
                  <ListItemText>
                    {`${index + 1} ${pluralize(
                      "semaine",
                      "semaines",
                      index + 1
                    )}`}
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
          </Collapse>
        </Box>
      </Desktop>
    </React.Fragment>
  );
}

export default SlotRepetitionForm;
