import React from "react";
import MobileContent from "./Content.mobile";
import DesktopContent, { IDesktopModalContentProps } from "./Content.desktop";
import useResponsiveLayout from "../../ResponsiveLayout/useResponsiveLayout";

export interface ModalContentProps {
  children: React.ReactNode;
  extended?: boolean;
  dense?: boolean;
  withChildrenSeparator?: boolean;
  noPadding?: boolean;
  noBackground?: boolean;
  desktopProps?: Omit<IDesktopModalContentProps, "children">;
}

function ResponsiveModalContent({
  children,
  desktopProps,
  ...props
}: ModalContentProps) {
  const { isMobile } = useResponsiveLayout();
  return isMobile ? (
    <MobileContent {...props}>{children}</MobileContent>
  ) : (
    <DesktopContent {...desktopProps}>{children}</DesktopContent>
  );
}

export default ResponsiveModalContent;
