import React from "react";
import classnames from "classnames";

interface SwitchProps {
  name: string;
  value: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
}

function Switch({ name, value, onChange: propsChange, disabled }: SwitchProps) {
  const onChange = () => {
    if (disabled) return;
    propsChange({
      target: {
        value: !value,
        name
      }
    } as any);
  };
  return (
    <div
      className={classnames("input-switch-container", {
        "input-switch-container--disabled": disabled
      })}
      onClick={onChange}
    >
      <input
        name={name}
        type="checkbox"
        checked={value}
        onChange={propsChange}
        disabled={disabled}
      />
      <span></span>
    </div>
  );
}

export default Switch;
