import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "reducers";

import { fetchSalons } from "actions/SalonActions";
import {
  fetchWeeksBySalonId,
  fetchAvailabilitiesBySalonId,
} from "actions/WeekActions";
import { fetchLastMinutesBySalonId } from "actions/LastMinuteActions";
import { Hairdresser } from "reducers/salon";
import { FLEXY_PARTNER_NAME } from "constants/Account";

function useCurrentSalon() {
  const { salonById, selectedSalonId } = useSelector(salonPayloadSelector);

  if (selectedSalonId === undefined) {
    throw new Error(
      "Trying to access current Salon but the store hasn't any selectedSalonId yet."
    );
  }

  const selectedSalon = salonById[selectedSalonId];

  const { hairdresserById } = selectedSalon;
  const packages = selectedSalon.packages || [];
  const partnerRefs = selectedSalon.partnerRefs || [];

  const hairdresserIds = Object.keys(hairdresserById).map((id) => parseInt(id));
  const hairdresserStringIds = Object.values(hairdresserById).map(
    ({ stringId }) => stringId
  );
  const hairdressers = Object.values(hairdresserById).map((hairdresser) => ({
    ...hairdresser,
    packageIds: [
      ...(hairdresser.serviceIds &&
      hairdresser.serviceIds.length !== 0 &&
      selectedSalon.isOneCatalog
        ? hairdresser.serviceIds.map((id: string) => Number(id))
        : hairdresser.packageIds),
    ],
    color: hairdresser.color != "#null" ? hairdresser.color : "#06038d",
  }));

  const activeHairdresserIds = hairdressers
    .filter((hairdresser: Hairdresser) => hairdresser.status === "ENABLED")
    .map((hairdresser: Hairdresser) => hairdresser.id);

  const lastMinutes = useSelector(lastMinutesSelector) || [];
  const fetchCurrentLastMinutes = () =>
    dispatch(fetchLastMinutesBySalonId(selectedSalonId));

  const slotById = useSelector(slotByIdSelector) || {};
  const slots = Object.values(slotById).filter(
    (slot) =>
      hairdresserIds.includes(Number(slot.hairdresserId)) ||
      hairdresserStringIds.includes(
        slot?.hairdresserStringId?.split("|")[1] || ""
      )
  );

  const unavailabilityById = useSelector(unavailabilityByIdSelector) || {};
  const unavailabilities = Object.values(unavailabilityById).filter(
    (unavailability) =>
      hairdresserIds.includes(unavailability.hairdresserId) ||
      hairdresserStringIds.includes(
        unavailability?.hairdresserStringId?.split("|")[1] || ""
      )
  );

  const bookingById = useSelector(bookingByIdSelector) || {};
  const bookings = Object.values(bookingById).filter(
    (booking) =>
      hairdresserIds.includes(booking.snapshotPackages?.[0]?.hairdresser?.id) ||
      hairdresserStringIds.includes(
        booking.snapshotPackages?.[0]?.hairdresser?.stringId
      )
  );

  const dispatch = useDispatch();
  const refetchSalon = () =>
    (dispatch(fetchSalons({ ids: [selectedSalonId] })) as unknown) as Promise<
      any
    >;

  const weeks = useSelector(weeksSelector) || [];
  const weeksByHairdresserId = useSelector(weeksByHairdresserIdSelector) || {};
  const fetchCurrentWeeks = () =>
    dispatch(fetchWeeksBySalonId(selectedSalonId));

  const availabilitiesByHairdresserId =
    useSelector(availabilitiesByHairdresserIdSelector) || {};
  const fetchCurrentAvailabilities = () =>
    dispatch(fetchAvailabilitiesBySalonId(selectedSalonId));

  const companyCode =
    partnerRefs.find((p) => p.name === FLEXY_PARTNER_NAME)?.id || "";

  return useMemo(
    () => ({
      salon: selectedSalon,
      hairdresserById,
      hairdresserIds,
      activeHairdresserIds,
      hairdressers,
      packages,
      slots,
      bookings,
      unavailabilities,
      refetchSalon,
      fetchCurrentWeeks,
      weeksByHairdresserId,
      availabilitiesByHairdresserId,
      fetchCurrentAvailabilities,
      weeks,
      companyCode,
      lastMinutes,
      fetchCurrentLastMinutes,
    }),
    [
      selectedSalonId,
      hairdresserById,
      packages,
      slots,
      unavailabilities,
      bookings,
      weeksByHairdresserId,
      availabilitiesByHairdresserId,
      weeks,
      companyCode,
      lastMinutes,
    ]
  );
}

function salonPayloadSelector(appState: AppState) {
  return appState.salon.payload;
}

function slotByIdSelector(appState: AppState) {
  return appState.slot.payload.slotById;
}

function bookingByIdSelector(appState: AppState) {
  return appState.booking.payload.bookingById;
}

function unavailabilityByIdSelector(appState: AppState) {
  return appState.unavailability.payload.unavailabilityById;
}

const weeksByHairdresserIdSelector = (appState: AppState) =>
  appState.week.payload.weeksByHairdresserId;

const availabilitiesByHairdresserIdSelector = (appState: AppState) =>
  appState.week.payload.availabilitiesByHairdresserId;

const weeksSelector = (appState: AppState) => appState.week.payload.weeks;
const lastMinutesSelector = (appState: AppState) =>
  appState.lastMinute.payload.lastMinutes;

export default useCurrentSalon;
