import { query, safeError, objToQueryArgs } from "../utils/graphqlClient";
import { FetchOptions } from "../hooks/useProgressiveList/useProgressiveList";
import { bookingFields } from "../utils/booking";
import {
  payoutFields,
  bookingToPrestation,
  grapQlToPayout,
} from "../utils/payments";

export const getSalonBalance = async (salonId: number) => {
  const res = await query(
    `balance(salonId: ${salonId}){totalAvailable, totalPending}`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.balance)
    : Promise.reject(safeError(body));
};

export const getSalonPrestations = async (
  salonId: number,
  { offset, limit, dateMin, dateMax }: FetchOptions = {}
) => {
  const args = objToQueryArgs({
    salonId,
    offset,
    limit,
    dateMin,
    dateMax,
    sort: "-id",
    statuses: "PAID,NO_SHOW,CANCELED",
  });
  const res = await query(`bookings(${args}){${bookingFields}}`);
  const body = await res.json();
  return res.ok
    ? Promise.resolve((body.data.bookings || []).map(bookingToPrestation))
    : Promise.reject(safeError(body));
};

export const getSalonPayouts = async (
  salonId: number,
  {
    lastId,
    limit,
    createdGte,
    createdLte,
  }: {
    lastId?: string;
    limit?: number;
    createdGte?: number;
    createdLte?: number;
  } = {}
) => {
  const args = objToQueryArgs({
    salonId,
    lastId,
    limit,
    createdGte,
    createdLte,
  });
  const res = await query(`payouts(${args}){${payoutFields}}`);
  const body = await res.json();
  return res.ok
    ? Promise.resolve((body.data.payouts || []).map(grapQlToPayout))
    : Promise.reject(safeError(body));
};

export const fetchPayoutWithBookings = async (id: string, salonId: number) => {
  const args = objToQueryArgs({
    salonId,
    id,
  });
  const res = await query(
    `payout(${args}){${payoutFields},bookings{${bookingFields}}}`
  );
  const body = await res.json();
  return res.ok && body.data.payout
    ? Promise.resolve(grapQlToPayout(body.data.payout))
    : Promise.reject(safeError(body));
};
