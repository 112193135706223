import React from "react";
import { WHITE } from "../../constants/Style";

const NextIcon = ({ height = 18, color, ...otherProps }: any) => (
  <svg
    width={(height / 3) * 2}
    height={height}
    viewBox="0 0  10 15"
    {...otherProps}
  >
    <path
      d="M1.565 1L8 7.435l-6.435 6.434"
      fillRule="nonzero"
      stroke={color || WHITE}
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
    />
  </svg>
);

export default NextIcon;
