import React from "react";

const AccountMenuIcon = (props: any) => (
  <svg viewBox="0 0 60 60" height={25}>
    <defs>
      <style>
        {
          ".account-1{;troke-linecap:round;stroke-linejoin:round;stroke-width:3px}"
        }
      </style>
    </defs>
    <circle className="account-1" cx={30} cy={30} r={8.44} />
    <path
      className="account-1"
      d="M51.65 33.93v-7.86h-5.22a16.87 16.87 0 00-2-4.91l3.69-3.69-5.56-5.56-3.72 3.69a16.87 16.87 0 00-4.91-2V8.35h-7.86v5.22a15.68 15.68 0 00-4.91 2l-3.69-3.69-5.56 5.56 3.69 3.69a16.87 16.87 0 00-2 4.91H8.35v7.86h5.22a16.87 16.87 0 002 4.91l-3.69 3.69 5.56 5.56 3.69-3.69a16.87 16.87 0 004.91 2v5.22h7.86v-5.16a16.87 16.87 0 004.91-2l3.69 3.69 5.56-5.56-3.66-3.72a16.87 16.87 0 002-4.91z"
    />
  </svg>
);

export default AccountMenuIcon;
