import React from "react";

const CarIcon: React.FC = props => (
  <svg width={20} height={16} viewBox="0 0 20 16" {...props}>
    <g fill="#252734" fillRule="evenodd">
      <path d="M3.63 5.55h12.74L15.2 2.657c-.246-.709-.615-1.358-1.354-1.358H6.154c-.8 0-1.108.65-1.416 1.358L3.631 5.55zm14.524.059C19.2 5.786 20 6.672 20 7.734v5.432H0V7.734C0 6.672.8 5.786 1.846 5.61l1.477-3.897C3.693.827 4.123 0 5.108 0h9.723c.984 0 1.477.827 1.846 1.712l1.477 3.897zm-1.785 2.539c-.923 0-1.661.708-1.661 1.594 0 .885.738 1.594 1.661 1.594s1.662-.709 1.662-1.594c0-.886-.739-1.594-1.662-1.594zm-12.8 0c.923 0 1.662.708 1.662 1.594 0 .885-.739 1.594-1.662 1.594-.861 0-1.6-.709-1.6-1.594 0-.886.739-1.594 1.6-1.594z" />
      <path d="M1.538 12.576h3.816v1.712c0 .944-.8 1.712-1.785 1.712h-.246c-.985 0-1.785-.768-1.785-1.712v-1.712zM18.462 12.576h-3.877v1.712c0 .944.8 1.712 1.784 1.712h.308c.985 0 1.785-.768 1.785-1.712v-1.712z" />
    </g>
  </svg>
);

export default CarIcon;
