import React, { useState, useEffect } from "react";

import { useImageManager } from "hooks/useImageManager";
import { Hairdresser } from "reducers/salon";
import styles from "./HairdresserAvatar.module.scss";

interface HairdresserAvatarProps {
  hairdresser: Hairdresser;
  height?: number;
  defaultType?: "IMAGE" | "INITIALS";
}

const getInitial = (str: string): string => (str ? str[0]?.toUpperCase() : "");
const getInitials = (strs: string[], separator = ".") =>
  strs.map(getInitial).join(separator);

function HairdresserAvatar({
  hairdresser,
  height = 73,
  defaultType = "INITIALS"
}: HairdresserAvatarProps) {
  const style: React.CSSProperties = {
    borderRadius: (height + 1) / 2,
    borderColor: hairdresser.color,
    color: hairdresser.color,
    fontSize: `${height / 2.5}px`,
    width: height,
    height
  };
  const [imageError, setImageError] = useState(false);
  const { getHairdresserImageUrl } = useImageManager();
  const Image = () => (
    <img
      style={style}
      className={styles.image}
      src={getHairdresserImageUrl(hairdresser)}
      alt={`${hairdresser.firstName}-${hairdresser.lastName}-avatar`}
      onError={() => setImageError(true)}
    />
  );
  useEffect(() => {
    setImageError(false);
  }, [hairdresser.image]);
  const Initials = () => {
    const initials = getInitials([
      hairdresser.firstName || "",
      hairdresser.lastName || ""
    ]);
    return (
      <div style={style} className={styles.initialsContainer}>
        <span>{initials}</span>
      </div>
    );
  };
  return (
    <>
      {!imageError && (hairdresser.image || defaultType === "IMAGE") ? (
        <Image />
      ) : (
        <Initials />
      )}
    </>
  );
}

export default HairdresserAvatar;
