import moment from "moment";
import { Day, Week, Days } from "reducers/week";
import { DayName, DAYS, Period, Conflict } from "constants/Week";
import { Hairdresser } from "reducers/salon";
import WeekDayList from "components/account/Weeks/WeekDayList";

export const createEmptyDays = (): Days =>
  Object.keys(DAYS).reduce(
    (acc, dayName) => ({
      ...acc,
      [dayName]: null,
    }),
    {} as Days
  );

export const createWeek = (
  hairdresserIds: Array<number>,
  hairdresserStringIds: Array<string>,
  salonId: number
): Week => ({
  hairdresserIds,
  hairdresserStringIds,
  salonId,
  discount: 0,
  duration: 0,
  status: "ENABLED",
  days: createEmptyDays(),
});

export const getSlotsFromDay = (day: Day) => {
  if (day.unavailabilities.length === 0) {
    return [
      {
        start: day.start,
        end: day.end,
      },
    ];
  }

  return [
    {
      start: day.start,
      end: day.unavailabilities[0].start,
    },
    {
      start: day.unavailabilities[0].end,
      end: day.end,
    },
  ];
};

// TODO: This is a simplification for our case
// We could make that work for N periods
export const periodsToDay = (periods: Array<Period>): Day | null => {
  if (periods.length === 0) {
    return null;
  }
  if (periods.length === 1) {
    return {
      start: periods[0].start,
      end: periods[0].end,
      unavailabilities: [],
    };
  }

  if (periods[0].end === periods[1].start && periods[1].end) {
    return {
      start: periods[0].start,
      end: periods[1].end,
      unavailabilities: [],
    };
  }

  return {
    start: periods[0].start,
    end: periods[1].end,
    unavailabilities: [
      {
        start: periods[0].end,
        end: periods[1].start,
      },
    ],
  };
};

export const dayToPeriods = (day: Day | null): Array<Period> => {
  if (!day) {
    return [];
  }
  if (day.unavailabilities.length === 0) {
    return [{ start: day.start, end: day.end }];
  }

  return [
    { start: day.start, end: day.unavailabilities[0].start },
    { start: day.unavailabilities[0].end, end: day.end },
  ];
};

export const extractPeriodLimits = (
  periods: Array<Period>,
  period: Period,
  index: number
) => {
  const minStart = periods?.[index - 1]?.end || "00:00";
  const maxEnd = periods?.[index + 1]?.start || "23:59";
  const minMax = {
    minStart: moment()
      .hours(parseInt(minStart.split(":")[0]))
      .minutes(parseInt(minStart.split(":")[1])),
    maxEnd: moment()
      .hours(parseInt(maxEnd.split(":")[0]))
      .minutes(parseInt(maxEnd.split(":")[1])),
    max: moment().hours(23).minutes(59),
  };
  const periodMoment = {
    start:
      period.start !== "" &&
      moment()
        .hours(parseInt(period.start.split(":")[0]))
        .minutes(parseInt(period.start.split(":")[1])),
    end:
      period.end !== "" &&
      moment()
        .hours(parseInt(period.end.split(":")[0]))
        .minutes(parseInt(period.end.split(":")[1])),
  };

  return { minMax, periodMoment };
};

export const getDisabledHoursStart = (periodMoment: any, minMax: any) => {
  const end = periodMoment.end || minMax.maxEnd;
  return [
    ...Array.from({
      length: parseInt(minMax.minStart.format("HH")),
    }).map((_, hour) => hour),
    ...Array.from({
      length: 23 - parseInt(end.format("HH")),
    }).map((_, hourIndex) => hourIndex + 1 + parseInt(end.format("HH"))),
  ];
};

export const getDisabledHoursEnd = (periodMoment: any, minMax: any) => {
  const start = periodMoment.start || minMax.minStart;
  return [
    ...Array.from({
      length: parseInt(start.format("HH")),
    }).map((_, hour) => hour),
    ...Array.from({
      length: 23 - parseInt(minMax.maxEnd.format("HH")),
    }).map(
      (_, hourIndex) => hourIndex + 1 + parseInt(minMax.maxEnd.format("HH"))
    ),
  ];
};

export const getDisabledHoursMinMax = (min: any, max: any) => {
  const start = min;
  return [
    ...Array.from({
      length: parseInt(start.format("HH")),
    }).map((_, hour) => hour),
    ...Array.from({
      length: 23 - parseInt(max.format("HH")),
    }).map((_, hourIndex) => hourIndex + 1 + parseInt(max.format("HH"))),
  ];
};

export const hourMinutesToNb = (str: string): number =>
  Number(str.split(":").join(""));

type IdKey = "hairdresserIds" | "hairdresserStringIds";

const IdKeyToSingle = {
  hairdresserIds: "hairdresserId",
  hairdresserStringIds: "hairdresserStringId",
};

const createConflictsFromTwoWeeks = (
  index: number,
  week: Week,
  indexCompare: number,
  weekCompare: Week,
  idKey: IdKey
): Array<Conflict | null> => {
  const ids = week[idKey] as Array<number | string>;
  if (!ids) {
    return [];
  }
  return ids.map((hId: number | string) => {
    const compareIds = weekCompare[idKey] as Array<number | string>;
    if (compareIds.indexOf(hId) === -1) {
      return null;
    }

    // There is no conflict if you disabled, allowing you to have many
    // potential HH and switch them
    if (week.status !== "ENABLED" || weekCompare.status !== "ENABLED") {
      return null;
    }

    if (week.discount !== weekCompare.discount) {
      return null;
    }

    // At least one same day check
    if (
      Object.keys(week.days).filter(
        (day) => weekCompare.days[day as DayName] && week.days[day as DayName]
      ).length === 0
    ) {
      return null;
    }

    // Overlap check
    if (
      week.start &&
      week.end &&
      weekCompare.start &&
      weekCompare.end &&
      hourMinutesToNb(weekCompare.end) > hourMinutesToNb(week.start) &&
      hourMinutesToNb(weekCompare.start) < hourMinutesToNb(week.end)
    ) {
      return {
        [IdKeyToSingle[idKey]]: hId,
        happyHour: index,
        conflict: indexCompare,
      };
    }

    return null;
  });
};

export const createConflicts = (weeks: Array<Week>) =>
  weeks.reduce((acc: Array<Conflict | null>, week: Week, index) => {
    const conflicts = weeks.reduce(
      (acc2: Array<Conflict | null>, weekCompare: Week, indexCompare) => {
        if (indexCompare === index) {
          return acc2;
        }

        // Only newly created HH can conflict with older (lower index) HHs
        if (indexCompare > index) {
          return acc2;
        }

        const idConfs = createConflictsFromTwoWeeks(
          index,
          week,
          indexCompare,
          weekCompare,
          "hairdresserIds"
        );
        const stringIdConfs = createConflictsFromTwoWeeks(
          index,
          week,
          indexCompare,
          weekCompare,
          "hairdresserStringIds"
        );

        return [...acc2, ...idConfs, ...stringIdConfs];
      },
      []
    );

    return [...acc, ...conflicts];
  }, []);

export const matchHairdresserWeek = (
  weekToSubmit: Week,
  hairdressers: Array<Hairdresser>
) => {
  const weekHairdresserId =
    weekToSubmit.hairdresserIds && weekToSubmit.hairdresserIds.length
      ? weekToSubmit.hairdresserIds[0]
      : 0;
  const weekHairdresserStringId =
    weekToSubmit.hairdresserStringIds &&
    weekToSubmit.hairdresserStringIds.length
      ? weekToSubmit.hairdresserStringIds[0]
      : "";
  return hairdressers.find(
    ({ id, stringId }) =>
      (id && id === weekHairdresserId) ||
      (weekHairdresserStringId != "" &&
        weekHairdresserStringId.indexOf(stringId) != -1)
  );
};

export const isHairdresserInWeek = (week: Week, hairdresser: Hairdresser) =>
  (hairdresser.id &&
    week.hairdresserIds &&
    week.hairdresserIds.indexOf(hairdresser.id) != -1) ||
  (week.hairdresserStringIds &&
    week.hairdresserStringIds.find(
      (stringId) => stringId.indexOf(hairdresser.stringId) != -1
    ));
