import { parseRoute } from "../utils/route";
import { routeList } from "../constants/Routes";
import AgendaMenuIcon from "../components/svgs/Menu/AgendaMenuIcon";
import BookingMenuIcon from "../components/svgs/Menu/BookingMenuIcon";
import RatingMenuIcon from "../components/svgs/Menu/RatingMenuIcon";
import PackagesMenuIcon from "../components/svgs/Menu/PackagesMenuIcon";
import AccountMenuIcon from "../components/svgs/Menu/AccountMenuIcon";
import PaymentMenuIcon from "../components/svgs/Menu/PaymentMenuIcon";

export const LOGOUT = "LOGOUT";

export const MENU_ITEMS_MOBILE = [
  {
    title: "Agenda",
    link: parseRoute(routeList.AGENDA_PAGE),
  },
  {
    title: "Rendez-vous",
    link: parseRoute(routeList.BOOKINGS_PAGE),
  },
  {
    title: "Avis Clients",
    link: parseRoute(routeList.RATING_PAGE_HAIRDRESSERS),
  },
  {
    title: "Prestations",
    link: parseRoute(routeList.PACKAGES_PAGE),
  },
  {
    title: "Mon compte",
    link: parseRoute(routeList.ACCOUNT_PAGE),
  },
  {
    title: "Paiements",
    link: parseRoute(routeList.PAYMENT_PAGE),
  },
];

export const RATINGS_SUBLINKS = [
  {
    title: "Avis sur les professionnels",
    link: parseRoute(routeList.RATING_PAGE_HAIRDRESSERS),
  },
  {
    title: "Avis sur l'établissement",
    link: parseRoute(routeList.RATING_PAGE_SALON),
  },
];

export interface SublinksConditions {
  isFlexySalon: boolean;
  autoCalendar: boolean;
}

export const ACCOUNT_SUBLINKS = [
  {
    title: "L'établissement",
    link: parseRoute(routeList.ACCOUNT_SALON_PAGE),
  },
  {
    title: "Les professionnels",
    link: parseRoute(routeList.ACCOUNT_HAIRDRESSERS_PAGE),
  },
  {
    title: "Paramètres du compte",
    link: parseRoute(routeList.ACCOUNT_SETTINGS_PAGE),
  },
  {
    title: "Réservation en ligne",
    link: parseRoute(routeList.ACCOUNT_WEEKS_PAGE),
    conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
      isFlexySalon && autoCalendar,
  },
  {
    title: "Promotions Happy Hour",
    link: parseRoute(routeList.ACCOUNT_HAPPY_HOURS_PAGE),
    conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
      isFlexySalon && autoCalendar,
  },
  {
    title: "Promotions Last Minute",
    link: parseRoute(routeList.ACCOUNT_LAST_MINUTE_PAGE),
    // conditions: ({ isFlexySalon, autoCalendar }: SublinksConditions) =>
    //  process.env.REACT_APP_ENV === "staging" || (isFlexySalon && autoCalendar),
  },
];

export const PAYMENT_SUBLINKS = [
  {
    title: "Liste des paiements",
    link: parseRoute(routeList.PAYMENT_LIST_PAGE),
  },
  {
    title: "Chiffre d'affaires par professionnel",
    link: parseRoute(routeList.PAYMENT_TURNOVER_PAGE),
  },
  {
    title: "Factures",
    link: parseRoute(routeList.PAYMENT_INVOICES_PAGE),
  },
  {
    title: "Informations de facturation",
    link: parseRoute(routeList.PAYMENT_INFORMATIONS_PAGE),
  },
];

export const MENU_ITEMS_DESKTOP = [
  {
    title: "Agenda",
    link: parseRoute(routeList.AGENDA_PAGE),
    icon: AgendaMenuIcon,
  },
  {
    title: "Rendez-vous",
    link: parseRoute(routeList.BOOKINGS_PAGE),
    icon: BookingMenuIcon,
  },
  {
    title: "Avis Clients",
    icon: RatingMenuIcon,
    sublinks: RATINGS_SUBLINKS,
  },
  {
    title: "Prestations",
    link: parseRoute(routeList.PACKAGES_PAGE),
    icon: PackagesMenuIcon,
  },
  {
    title: "Mon compte",
    icon: AccountMenuIcon,
    sublinks: [
      ...ACCOUNT_SUBLINKS,
      {
        title: "Se déconnecter",
        action: LOGOUT,
      },
    ],
  },
  {
    title: "Paiements",
    icon: PaymentMenuIcon,
    sublinks: PAYMENT_SUBLINKS,
  },
];

export const PARENT_LINKS = {
  account: {
    link: parseRoute(routeList.ACCOUNT_PAGE),
    text: "Mon compte",
  },
  payment: {
    link: parseRoute(routeList.PAYMENT_PAGE),
    text: "Paiements",
  },
} as Record<string, { link: string; text: string }>;
