import React from "react";
import classnames from "classnames";
import moment from "moment";

import { filterColumnEvents } from "utils/calendar";
import CalendarColumnEvent, {
  CalendarEventRendererProps,
} from "./CalendarColumnEvent";
import { CalendarResource, CalendarEvent, CalendarViewType } from ".";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Button, Tooltip, makeStyles } from "@material-ui/core";
import Mobile from "components/ResponsiveLayout/Mobile";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import { useHistory } from "react-router-dom";

export interface CalendarColumnProps<T> {
  date: moment.Moment;
  // This props would feel better in a CalendarContext
  view: CalendarViewType;
  resource: CalendarResource;
  events: Array<CalendarEvent<T>>;
  eventRenderer?: { (props: CalendarEventRendererProps<T>): JSX.Element };
  getEventPositionInfos(
    event: CalendarEvent<T>
  ): {
    topOffset: number;
    height: number;
  };
  onClickEvent(event: CalendarEvent<T>): void;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseDown?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseUp?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseMove?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseLeave?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  showResourceIcon?: boolean;
  showResourceTitle?: boolean;
  classes?: {
    container?: string;
  };
}

export const CALENDAR_COLUMN_DATA_ID = "calendarcolumn";

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: "black",
  },
  tooltip: {
    backgroundColor: "black",
  },
}));

export default function CalendarColumn<T>(props: CalendarColumnProps<T>) {
  const {
    date,
    view,
    resource,
    events,
    eventRenderer,
    getEventPositionInfos,
    onClickEvent,
    onClick,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
    showResourceIcon,
    showResourceTitle = true,
    classes,
  } = props;
  const { color } = resource;
  const ResourceIcon = resource.icon;
  const { lastMinutes } = useCurrentSalon();
  const filteredLMs = lastMinutes.filter((lm) => lm.status === "ENABLED");
  const tooltipClasses = useStylesBootstrap();
  const history = useHistory();
  return (
    <div
      className={classnames(
        "calendar_column-container",
        {
          "calendar_column-container--week-mode": view === "WEEK",
          "calendar_column-container--with-icon": showResourceIcon,
          "calendar_column-container--disabled": resource.status === "DISABLED",
        },
        classes && classes.container
      )}
    >
      <div
        className={classnames("calendar_header_item", {
          "calendar_header_item--with-icon": showResourceIcon,
        })}
        style={{ color }}
      >
        {showResourceIcon && <ResourceIcon height={35} />}
        {showResourceTitle && (
          <span className="calendar_header_item--with-icon_title">
            {resource.title}{" "}
          </span>
        )}
      </div>
      <div
        {...{ [`data-${CALENDAR_COLUMN_DATA_ID}`]: true }} // used to detect this element in events target
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={classnames("calendar_column-events-container", {
          "calendar_column-events-container--with-icon": showResourceIcon,
          "calendar_column-events-container--week-mode": view === "WEEK",
        })}
        style={{ color }}
      >
        {filterColumnEvents(events, resource, date).map((event) => (
          <CalendarColumnEvent
            key={event.id}
            event={event}
            color={resource.color}
            eventRenderer={eventRenderer}
            getEventPositionInfos={getEventPositionInfos}
            onClickEvent={onClickEvent}
          />
        ))}
      </div>
    </div>
  );
}
