import React from "react";
import { Rating } from "../../reducers/rating";
import RatingReplyForm from "../../components/rating/RatingReplyForm";
import { useRatingReplier } from "./useRatingReplier";

interface RatingReplyFormContainerProps {
  type: "HAIRDRESSER" | "SALON" | "USER";
  rating: Rating;
  onClose(): void;
  onReplySuccess?(): void;
}

function RatingReplyFormContainer({
  type,
  rating,
  onClose,
  onReplySuccess
}: RatingReplyFormContainerProps) {
  const { ratingReplier } = useRatingReplier();
  const submitRatingReply = async (reply: string) => {
    const replySuccess = await ratingReplier(rating, reply);
    if (replySuccess) {
      onReplySuccess && onReplySuccess();
    }
  };
  return (
    <RatingReplyForm
      type={type}
      rating={rating}
      onClose={onClose}
      submitRatingReply={submitRatingReply}
    />
  );
}

export default RatingReplyFormContainer;
