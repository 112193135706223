import getEsClient from "utils/esClient";
import { Rating, RatingCommentReply } from "reducers/rating";

interface IESRating {
  id: number;
  updatedAt: string;
  hairdresserComment: string;
  salonComment: string;
  hairdresserRate: number;
  salonRate: number;
  hairdresser: {
    id: number;
    stringId: string;
    firstName: string;
    lastName: string;
    gender: "F" | "M";
  };
  salon: {
    id: number;
    name: string;
  };
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  booking: {
    id: number;
    dateStart: string;
    packageId: number;
    packageName: string;
  };
  replies: unknown[];
  status: "VOTED" | string;
}

const ESRatingToRating = (esRating: IESRating): Rating => {
  const type = esRating.hairdresserRate
    ? "HAIRDRESSER"
    : esRating.salonRate
    ? "SALON"
    : undefined;
  const rating: Rating = {
    id: esRating.id,
    type,
    hairdresser: esRating.hairdresser,
    hairdresserRate: esRating.hairdresserRate,
    hairdresserComment: esRating.hairdresserComment,
    replies: (esRating.replies as RatingCommentReply[]) || [],
    salonId: esRating.salon.id,
    salonRate: esRating.salonRate,
    salonComment: esRating.salonComment,
    booking: {
      ...esRating.booking,
      snapshotPackages: [
        {
          name: esRating.booking.packageName,
        },
      ],
    } as any, // TODO: can't find the typings error...
    user: esRating.user as any, // TODO: can't find the typings error...
    updatedAt: esRating.updatedAt,
    createdAt: esRating.updatedAt,
  };
  return rating;
};

const ESSalonRatingByUserQueryRequest = (salonId: number, query: string) => ({
  index: "ratings",
  body: {
    from: 0,
    size: 20,
    query: {
      bool: {
        must: [
          {
            match: {
              "salon.id": salonId,
            },
          },
          {
            query_string: {
              fields: ["user.firstName", "user.lastName"],
              query: `*${query}*`,
              default_operator: "AND",
            },
          },
        ],
      },
    },
  },
});

export const searchSalonRatingsByUsernameQuery = async (
  salonId: number,
  query: string
): Promise<Rating[]> => {
  if (!query || query.length === 0) return [];
  try {
    const esClient = await getEsClient();
    const result = await esClient.search(
      ESSalonRatingByUserQueryRequest(salonId, query)
    );
    const { hits } = result.hits;
    return hits.map((h) => ESRatingToRating(h._source as IESRating));
  } catch (e) {
    console.log(e);
    return [];
  }
};
