import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { CoronavirusAlert } from "../CoronavirusAlert/CoronavirusAlert";
import { StripeAccountUpdate } from "../StripeAccountUpdate/StripeAccountUpdate";
import { AppState } from "reducers";

const wrapper: React.CSSProperties = {
  top: 0,
  width: "100vw",
  position: "fixed",
  zIndex: 99,
};

const childrenInfoWrapper: React.CSSProperties = {
  position: "relative",
};

const ImportantInformations = () => {
  const alertRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    document.body.style.paddingTop = `${alertRef.current?.clientHeight}px`;
  }, []);
  return (
    <section ref={alertRef} style={wrapper}>
      <StripeAccountUpdate />
      {/* <CoronavirusAlert /> */}
    </section>
  );
};

export { ImportantInformations };
