import {
  safeError,
  mutation,
  callWithPagination,
  objToQueryArgs,
  query,
} from "./graphqlClient";
import { Booking, BookingState } from "../reducers/booking";
import { getFromCache } from "./cache";
import { Hairdresser } from "../reducers/salon";
import moment from "moment";
import { DEFAULT_BOOKING_DISCOUNT } from "constants/Booking";

const getBookingsCache = () => {
  return getFromCache("booking");
};

export const bookingFields = `
  id,
  createdAt,
  discount,
  commissionAmount,
  commissionConditions{
      isNewClient
  },
  transactionId,
  status,
  cancellationReason,
  cancellationOrigin,
  dateStart,
  dateEnd,
  paymentType,
  user{
      id,
      firstName,
      lastName,
      gender,
      phone,
      mobile,
      username
  },
  snapshotFees{
    commission,
    taxRate,
    percentFees,
    statedFees
  },
  snapshotPackages{
    id,
    category{
      id,
      name,
      key,
      slugs{
        locale,
        slug
      }
    },
    status,
    hairdresser{
      id,
      stringId,
    },
    name,
    description,
    price{
      variant{
        id,
        name,
        key,
        sort
      }
      duration,
      price,
      currency,
      packageNumber
    }
  }
`.replace(/\s+/g, "");

export const getBookingDiscountedPrice = (booking: Booking): number => {
  const fullPrice = booking.snapshotPackages[0]?.price?.price ?? 0;
  const discountPercentage =
    (booking.discount ?? DEFAULT_BOOKING_DISCOUNT) / 100.0;
  return fullPrice * (1.0 - discountPercentage);
};

const fetchBookingsBySalonId = callWithPagination(
  (salonId: number, opts: any = {}) => async (
    offset: number,
    limit: number
  ) => {
    const args = objToQueryArgs({
      limit,
      offset,
      ...opts,
      salonId,
    });
    const res = await query(`bookings(${args}){${bookingFields}}`);
    const body = await res.json();
    return res.ok
      ? Promise.resolve(body.data.bookings)
      : Promise.reject(safeError(body));
  }
);

const updateBookingHairdresser = async (
  booking: Booking,
  hairdresserId: number,
  hairdresserStringId: string
) => {
  const res = await mutation(
    "changeBookingHairdresser",
    {
      id: {
        type: "Int",
        value: booking && booking.id,
      },
      ...(hairdresserId
        ? {
            hairdresserId: {
              type: "Int",
              value: hairdresserId,
            },
          }
        : {}),
      ...(hairdresserStringId !== ""
        ? {
            hairdresserStringId: {
              type: "String",
              value: hairdresserStringId,
            },
          }
        : {}),
    },
    bookingFields
  );
  const body = await res.json();
  if (res.ok) {
    return Promise.resolve(body.data.changeBookingHairdresser);
  }
  return Promise.reject(safeError(body));
};

const cancelBookingById = async (
  bookingId: number,
  cancellationReason: string
) => {
  const res = await mutation(
    "salonCancelBooking",
    {
      id: {
        type: "Int",
        value: bookingId,
      },
      cancellationReason: {
        type: "String",
        value: cancellationReason,
      },
    },
    `id, status`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.salonCancelBooking)
    : Promise.reject(safeError(body));
};

const getBookingsByHairdressers = (
  hairdressers: Array<Hairdresser>,
  bookingState: BookingState
): Array<Booking> => {
  const hairdresserIds = hairdressers.map((h) => h.id || Number(h.stringId));
  return Object.values(bookingState.payload.bookingById || {}).filter(
    (booking) => {
      const hairdresser = booking.snapshotPackages[0]?.hairdresser || {};
      return (
        hairdresserIds.includes(hairdresser.id) ||
        hairdresserIds.includes(Number(hairdresser.stringId))
      );
    }
  );
};

const getFetchBookingDateOptions = (
  targetDate: string,
  range: "DAY" | "WEEK" | "MONTH" = "DAY"
) => {
  let startDate;
  let endDate;

  if (range === "MONTH") {
    startDate = moment(targetDate).startOf("month").toISOString();
    endDate = moment(targetDate).endOf("month").toISOString();
  } else if (range === "WEEK") {
    startDate = moment(targetDate).startOf("week").toISOString();
    endDate = moment(targetDate).endOf("week").toISOString();
  } else {
    startDate = moment(targetDate).startOf("day").toISOString();
    endDate = moment(targetDate).endOf("day").toISOString();
  }
  return {
    dateMin: startDate,
    dateMax: endDate,
  };
};

const filterBookingsCanceled = (
  bookings: Array<Booking>,
  showCanceled: boolean
): Array<Booking> =>
  bookings.filter((booking: Booking): boolean => {
    const bookingHasRightStatus =
      (showCanceled && booking.status === "CANCELED") ||
      (!showCanceled && ["PAID", "CONFIRMED"].includes(booking.status));

    const bookingIsLinkedToTransaction = booking.transactionId !== "";

    return bookingHasRightStatus && bookingIsLinkedToTransaction;
  });

export {
  getBookingsCache,
  fetchBookingsBySalonId,
  updateBookingHairdresser,
  cancelBookingById,
  getBookingsByHairdressers,
  getFetchBookingDateOptions,
  filterBookingsCanceled,
};
