import { mutation, safeError } from "utils/graphqlClient";

export const sendResetPasswordEmail = async (username: string) => {
  const res = await mutation(
    "sendResetPasswordEmail",
    {
      username: {
        type: "String",
        value: username
      }
    },
    "result"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.sendResetPasswordEmail)
    : Promise.reject(safeError(body));
};
