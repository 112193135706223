import { useDispatch } from "react-redux";
import moment from "moment";
import { getFromCache } from "./cache";
import {
  query,
  mutation,
  objToQueryArgs,
  safeError,
  callWithPagination,
} from "./graphqlClient";
import { SlotState, Slot, SlotInfos } from "../reducers/slot";
import { Hairdresser, PartnerRef } from "../reducers/salon";
import { earliestPossibleBookingCreation } from "components/agenda/utils";
import { timeInputToMoment } from "utils/form";
import {
  createRecurrentSlot as createRecurrentSlotAction,
  createSlot as createSlotAction,
  updateSlot,
} from "actions/SlotActions";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import useCurrentSalon from "hooks/useCurrentSalon";
import { CalendarResource } from "components/Calendar";

const slotFields = `
  id,
  hairdresserStringId,
  hairdresser {
      id,
  },
  partnerRef {
    id,
    name,
    companyCode
  },
  range{
      start,
      end
  },
  discount,
  recurrence{
    id,
    rules,
    description
    ${process.env.NODE_ENV === "production" ? "" : ",occurrences"}
  }
`.replace(/\s+/g, "");

const getSlotsCache = () => {
  return getFromCache("slot");
};

const buildSlotPayload = (fetchSlotsResult: any): { [id: number]: Slot } => {
  return fetchSlotsResult.reduce(
    (slots: { [id: number]: Slot }, slotFromGraphQl: any) => ({
      ...slots,
      [slotFromGraphQl.id]: graphqlSlotToStoreSlot(slotFromGraphQl),
    }),
    {}
  );
};

const fetchSlotsByHairdresserIds = callWithPagination(
  (
    hairdresserIds: Array<number>,
    hairdresserStringIds: Array<string>,
    opts: any = {}
  ) => async (offset: number, limit: number) => {
    const args = objToQueryArgs({
      limit,
      offset,
      ...opts,
    });
    const res = await query(
      `slots(${args}, ${
        hairdresserIds.length
          ? `hairdresserIds: "${hairdresserIds.toString()}", `
          : ""
      }${
        hairdresserStringIds.length
          ? `hairdresserStringIds: "${hairdresserStringIds.join(",")}", `
          : ""
      }){${slotFields}}`
    );
    const body = await res.json();
    return res.ok
      ? Promise.resolve(buildSlotPayload(body.data.slots || []))
      : Promise.reject(safeError(body));
  }
);

const createSlot = async (slotInfos: SlotInfos) => {
  const partialDatas = {
    ...(slotInfos.hairdresserId
      ? {
          hairdresserId: {
            type: "Int",
            value: slotInfos.hairdresserId,
          },
        }
      : {}),
    ...(slotInfos.hairdresserStringId
      ? {
          hairdresserStringId: {
            type: "String",
            value: slotInfos.hairdresserStringId,
          },
        }
      : {}),
    range: {
      type: "RangeInput",
      value: {
        start: moment(slotInfos.range.start).format(),
        end: moment(slotInfos.range.end).format(),
      },
    },
    discount: {
      type: "Float",
      value: slotInfos.discount,
    },
  };
  const partnerRef = {
    type: "PartnerRefInput",
    value: {
      id: slotInfos.partnerRef?.id,
      name: slotInfos.partnerRef?.name,
      companyCode: slotInfos.partnerRef?.companyCode,
    },
  };
  const datas = slotInfos.partnerRef
    ? { ...partialDatas, partnerRef }
    : partialDatas;
  const res = await mutation("createSlot", datas, slotFields);
  const body = await res.json();
  if (res.ok && body.data.createSlot) {
    return graphqlSlotToStoreSlot(body.data.createSlot);
  }
  return Promise.reject(safeError(body));
};

const createRecurrentSlot = async (slotInfos: SlotInfos) => {
  const firstSlotOfRecurrence = getFirstSlotOfRecurrence(slotInfos);
  if (firstSlotOfRecurrence.recurrence?.occurrences === 0) {
    throw new Error("Recurrence Arguments Error");
  }
  const partialDatas = {
    ...(firstSlotOfRecurrence.hairdresserId
      ? {
          hairdresserId: {
            type: "Int",
            value: firstSlotOfRecurrence.hairdresserId,
          },
        }
      : {}),
    ...(firstSlotOfRecurrence.hairdresserStringId
      ? {
          hairdresserStringId: {
            type: "String",
            value: firstSlotOfRecurrence.hairdresserStringId,
          },
        }
      : {}),
    range: {
      type: "RangeInput",
      value: {
        start: moment(firstSlotOfRecurrence.range.start).format(),
        end: moment(firstSlotOfRecurrence.range.end).format(),
      },
    },
    discount: {
      type: "Float",
      value: firstSlotOfRecurrence.discount,
    },
    rules: {
      type: "String",
      value:
        firstSlotOfRecurrence.recurrence &&
        firstSlotOfRecurrence.recurrence.rules,
    },
    occurrences: {
      type: "Int",
      value:
        firstSlotOfRecurrence.recurrence &&
        firstSlotOfRecurrence.recurrence.occurrences,
    },
  };
  const partnerRef = {
    type: "PartnerRefInput",
    value: {
      id: slotInfos.partnerRef?.id,
      name: slotInfos.partnerRef?.name,
      companyCode: slotInfos.partnerRef?.companyCode,
    },
  };
  const datas = slotInfos.partnerRef
    ? { ...partialDatas, partnerRef }
    : partialDatas;
  const res = await mutation("createRecurrentSlot", datas, slotFields);
  const body = await res.json();
  if (res.ok && body.data.createRecurrentSlot) {
    return body.data.createRecurrentSlot.map(graphqlSlotToStoreSlot);
  }
  throw new Error(safeError(body));
};

const updateSlotById = async (slotId: number, slotInfos: Slot) => {
  const res = await mutation(
    "updateSlot",
    {
      id: {
        type: "Int",
        value: slotId,
      },
      hairdresserId: {
        type: "Int",
        value: slotInfos.hairdresserId,
      },
      range: {
        type: "RangeInput",
        value: {
          start: moment(slotInfos.range.start).format(),
          end: moment(slotInfos.range.end).format(),
        },
      },
      discount: {
        type: "Float",
        value: slotInfos.discount,
      },
    },
    slotFields
  );
  const body = await res.json();
  return res.ok && body.data.updateSlot
    ? Promise.resolve(graphqlSlotToStoreSlot(body.data.updateSlot))
    : Promise.reject(safeError(body));
};

const deleteSlotById = async (slotId: number) => {
  const res = await mutation(
    "deleteSlot",
    {
      id: {
        type: "Int",
        value: slotId,
      },
    },
    "id"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.deleteSlot.id)
    : Promise.reject(safeError(body));
};

const deleteRecurrentSlotById = async (slotId: number) => {
  const res = await mutation(
    "deleteRecurrentSlot",
    {
      id: {
        type: "Int",
        value: slotId,
      },
    },
    "id"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.deleteRecurrentSlot.id)
    : Promise.reject(safeError(body));
};

const graphqlSlotToStoreSlot = (graphqlSlot: any): Slot => {
  const slot: Slot = {
    id: graphqlSlot.id,
    hairdresserId: (graphqlSlot.hairdresser && graphqlSlot.hairdresser.id) || 0,
    hairdresserStringId: graphqlSlot.hairdresserStringId,
    range: {
      start: graphqlSlot.range && graphqlSlot.range.start,
      end: graphqlSlot.range && graphqlSlot.range.end,
    },
    discount: graphqlSlot.discount,
    recurrence: graphqlSlot.recurrence,
  };
  return slot;
};

const getSlotsByHairdressers = (
  hairdressers: Array<Hairdresser>,
  slotState: SlotState
): Array<Slot> => {
  const hairdresserIds = hairdressers.map((h) => h.id);
  const hairdresserStringIds = hairdressers.map((h) => h.stringId);
  return Object.values(slotState.payload.slotById || {})
    .filter((slot) => hairdresserIds.includes(slot.hairdresserId || 0))
    .filter((slot) =>
      hairdresserStringIds.includes(slot.hairdresserStringId || "")
    );
};

const getFetchRangeOptions = (
  targetDate: string,
  range: "DAY" | "WEEK" | "MONTH" = "DAY"
) => {
  let startDate;
  let endDate;

  if (range === "MONTH") {
    startDate = moment(targetDate).startOf("month").toISOString();
    endDate = moment(targetDate).endOf("month").toISOString();
  } else if (range === "WEEK") {
    startDate = moment(targetDate).startOf("week").toISOString();
    endDate = moment(targetDate).endOf("week").toISOString();
  } else {
    startDate = moment(targetDate).startOf("day").toISOString();
    endDate = moment(targetDate).endOf("day").toISOString();
  }
  return {
    endGte: startDate,
    startLte: endDate,
  };
};

/**
 *  Maps [3, 0, 5, 1] to => "0,1,3,5"
 * @param momentWeekDays
 */
const momentWeekDaysToRecurrenceRules = (
  momentWeekDays: Array<number>
): string => {
  return momentWeekDays
    .sort((a, b) => a - b) // ascending sort
    .join(",");
};

const isSunday = (date: string | number) => moment(date).day() === 0;

const getFirstSlotOfRecurrence = (slotInfos: SlotInfos) => {
  if (!slotInfos.recurrence) return slotInfos;

  const { rules } = slotInfos.recurrence;
  const now = moment();
  const daysNum = rules
    .split(",")
    .map((d) => parseInt(d))
    .sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b));
  const firstDayNum = daysNum.find((day) =>
    moment(slotInfos.range.start).day(day).isAfter(now)
  );
  if (firstDayNum === undefined) {
    return {
      ...slotInfos,
      recurrence: {
        ...slotInfos.recurrence,
        occurrences: 0,
      },
    };
  }
  return {
    ...slotInfos,
    range: {
      start: moment(slotInfos.range.start)
        .day(firstDayNum)
        .subtract(isSunday(slotInfos.range.start) ? 1 : 0, "week")
        .toISOString(),
      end: moment(slotInfos.range.end)
        .day(firstDayNum)
        .subtract(isSunday(slotInfos.range.end) ? 1 : 0, "week")
        .toISOString(),
    },
    recurrence: {
      ...slotInfos.recurrence,
      occurrences:
        slotInfos.recurrence.occurrences -
        daysNum.findIndex((day) => day === firstDayNum),
    },
  };
};

export const MODE = {
  CREATE: "CREATE" as const,
  UPDATE: "UPDATE" as const,
};

export interface IFormValues {
  hairdresserIds: Array<number>;
  hairdresserStringIds: Array<string>;
  date: string;
  start: string;
  end: string;
  repeatSlot: boolean;
  repeatSlotWeekDays: Array<number>;
  repeatSlotNumberOfWeek: number;
  discount: number;
  partnerRef?: PartnerRef;
}

const createSlotFromForm = async (
  formValues: IFormValues,
  slotToEdit: Slot | Omit<Slot, "id"> | undefined,
  mode: string,
  dispatch: any,
  companyCode: string,
  isOneCatalogV2?: boolean
) => {
  const {
    start,
    end,
    hairdresserIds,
    hairdresserStringIds,
    date,
    repeatSlot,
    repeatSlotWeekDays,
    repeatSlotNumberOfWeek,
    discount,
    partnerRef,
  } = formValues;
  const startTime = timeInputToMoment(start, date);
  const endTime = timeInputToMoment(end, date);
  if (moment(startTime).isBefore(moment(earliestPossibleBookingCreation))) {
    dispatch(
      pushMessage(
        "Impossible de créer un créneau à ce moment.",
        MESSAGE_TYPES.ERROR
      )
    );
  }
  const typeHairdresserId = isOneCatalogV2 ? "string" : "number";
  const listHairdresserIds = isOneCatalogV2
    ? hairdresserStringIds
    : hairdresserIds.map((int) => String(int));
  if (mode === MODE.CREATE) {
    await Promise.all(
      listHairdresserIds.map((hairdresserId: any) => {
        if (repeatSlot) {
          return dispatch(
            createRecurrentSlotAction({
              ...((!isOneCatalogV2 && {
                hairdresserId: Number(hairdresserId),
              }) ||
                {}),
              ...((isOneCatalogV2 && {
                hairdresserStringId: hairdresserId,
              }) ||
                {}),
              range: {
                start: startTime.toISOString(),
                end: endTime.toISOString(),
              },
              recurrence: {
                rules: momentWeekDaysToRecurrenceRules(repeatSlotWeekDays),
                occurrences:
                  repeatSlotWeekDays.length * (1 + repeatSlotNumberOfWeek),
              },
              discount,
              partnerRef,
            })
          );
        } else {
          return dispatch(
            createSlotAction({
              ...((typeHairdresserId === "number" && {
                hairdresserId: Number(hairdresserId),
              }) ||
                {}),
              ...((typeHairdresserId === "string" && {
                hairdresserStringId: hairdresserId,
              }) ||
                {}),
              range: {
                start: startTime.toISOString(),
                end: endTime.toISOString(),
              },
              discount,
              partnerRef,
              companyCode,
            })
          );
        }
      })
    );
  } else if (slotToEdit && "id" in slotToEdit) {
    dispatch(
      updateSlot({
        ...slotToEdit,
        range: {
          start: startTime.toISOString(),
          end: endTime.toISOString(),
        },
        discount,
      })
    );
  }

  return Promise.resolve();
};

const fillPartnerRef = (
  partialSlot: Omit<Slot, "id">,
  resource: CalendarResource
): Omit<Slot, "id"> => {
  const salonStorage = localStorage.getItem("salon");
  const slot = { ...partialSlot };
  if (salonStorage) {
    const salon = JSON.parse(salonStorage);
    const salonKey = Object.keys(salon.salonById)[0];
    const isFlexyPartner = salon.salonById[salonKey].partnerRefs?.find(
      (partner: PartnerRef) => partner.name === "flexy"
    );
    if (isFlexyPartner && resource.partnerRef?.id) {
      slot.partnerRef = {
        companyCode: isFlexyPartner.id,
        id: resource.partnerRef.id,
        name: isFlexyPartner.name,
      };
    }
  }
  return slot;
};

export {
  getSlotsCache,
  fetchSlotsByHairdresserIds,
  getSlotsByHairdressers,
  createSlot,
  createRecurrentSlot,
  updateSlotById,
  deleteSlotById,
  getFetchRangeOptions,
  momentWeekDaysToRecurrenceRules,
  deleteRecurrentSlotById,
  createSlotFromForm,
  fillPartnerRef,
};
