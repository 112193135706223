import { AuthInfos } from "reducers/auth";
import { identifySentryUser, captureException } from "thirdParties/sentry";
import packageJson from "../../package.json";

export const identifyUser = (auth: AuthInfos, selectedSalonId?: number) => {
  // We don't want to send the identification token to Sentry
  const { token, ...safeData } = auth;
  identifySentryUser({
    username: safeData.username,
    email: safeData.username,
    auth: safeData,
    salonId: selectedSalonId,
    appVersion: packageJson.version,
  });
};

export const logException = (error: any, extra?: any) => {
  if (process.env.REACT_APP_ENV === "production") {
    error && captureException(error, extra);
  } else {
    console.error("logException", { error, extra });
  }
};
