import React from "react";
import { DARK } from "../../constants/Style";

const ArrowBottomIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={9} height={9} viewBox="0 0 9 9" {...props}>
    <path d="M4.5 9L9 0H0z" fill={props.color || DARK} fillRule="evenodd" />
  </svg>
);

export default ArrowBottomIcon;
