import { getAuthCache } from "./auth";
import { DEFAULT_API_PAGE_SIZE } from "../constants/Api";
import { ERRORS } from "../constants/SnackBar";

interface graphqlInput {
  type: string;
  value: any;
}

interface graphqlInputDictionnary {
  [key: string]: graphqlInput;
}

export const query = (req: string, options?: { token?: string }) =>
  fetch(`${process.env.REACT_APP_GRAPHQL_URL}?query=query{${req}}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + (options?.token ?? getAuthCache().token),
      "content-type": "application/json",
      Accept: "application/json"
    }
  });

export const createQueryString = (
  method: string,
  rawInputs: graphqlInputDictionnary,
  fields: string
) => {
  const inputs = Object.keys(rawInputs);
  const input = inputs.reduce(
    (accumulator, currentValue) =>
      `${accumulator}$${currentValue}:${rawInputs[currentValue].type},`,
    ""
  );
  const params = inputs.reduce(
    (accumulator, currentValue) =>
      `${accumulator}${currentValue}:$${currentValue},`,
    ""
  );
  return `mutation(${input}){${method}(${params}){${fields}}}`;
};

const createVariables = (inputs: graphqlInputDictionnary) =>
  Object.keys(inputs).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...{ [currentValue]: inputs[currentValue].value }
    }),
    {}
  );

export const mutation = (
  method: string,
  inputs: graphqlInputDictionnary,
  fields: string,
  options?: { token?: string }
) =>
  fetch(process.env.REACT_APP_GRAPHQL_URL as RequestInfo, {
    method: "POST",
    body: JSON.stringify({
      query: createQueryString(method, inputs, fields),
      variables: createVariables(inputs)
    }),
    headers: {
      authorization: options?.token ?? getAuthCache().token,
      "content-type": "application/json",
      Accept: "application/json"
    }
  });

const valueToGraphQl = (value: any): any => {
  if (typeof value === "string") {
    return `"${value}"`;
  }
  return value;
};

export const objToQueryArgs = (obj: any): string => {
  return Object.entries(obj)
    .filter(([_key, value]) => value)
    .map(([key, value]) => `${key}: ${valueToGraphQl(value)}`)
    .join(", ");
};

export const safeError = (body: any, defaultMessage = ERRORS.UNKNOWN) =>
  (body.errors && body.errors[0] && body.errors[0].message) || defaultMessage;

export interface IPaginationOptions {
  offset?: number;
  limit?: number;
  page?: number;
}

export function callWithPagination<
  T extends any[],
  U extends { (offset: number, limit: number): Promise<any> }
>(
  fn: { (...args: T): U },
  offset = 0,
  limit = DEFAULT_API_PAGE_SIZE,
  options?: IPaginationOptions
) {
  return function(...args: T): any {
    const page = (options && options.page) || 1;
    return fn(...args)(offset + limit * (page - 1), limit).then(
      (list: any | Array<any>) => {
        const newOffset = offset + limit;
        if (options && options.page !== undefined) {
          return list;
        }
        if (Array.isArray(list) && list.length === limit) {
          return callWithPagination(
            fn,
            newOffset,
            limit,
            options
          )(...args).then((nextlist: Array<any>) => [...list, ...nextlist]);
        } else if (
          typeof list === "object" &&
          list.constructor === Object &&
          Object.keys(list).length === limit
        ) {
          return callWithPagination(
            fn,
            newOffset,
            limit,
            options
          )(...args).then((nextlist: any) => ({ ...list, ...nextlist }));
        }

        return list;
      }
    );
  };
}
