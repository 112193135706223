import React from "react";
import { TEALISH } from "../../constants/Style";

const Check = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={15} height={12} {...props}>
    <path
      d="M1 5.865L5.093 10 14 1"
      fillRule="nonzero"
      stroke={props.color || TEALISH}
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
    />
  </svg>
);

export default Check;
