import React from "react";
import moment, { Moment } from "moment";

import { STATUSES } from "constants/Account";
import {
  IUnavailableEventMeta,
  IBookingEventMeta,
  ISlotEventMeta,
  IAvailableEventMeta,
  ILastMinuteEventMeta,
} from "components/agenda/EventRenderer/EventRenderer";
import { CalendarResource, CalendarEvent } from "components/Calendar";
import { AGENDA_EVENT_KINDS } from "components/agenda/AgendaPage/AgendaPage";
import HairdresserAvatar from "components/uiElements/HairdresserAvatar/HairdresserAvatar";
import { AgendaFilters } from "hooks/useAgendaFilterSelector";
import { Booking } from "reducers/booking";
import { Hairdresser } from "reducers/salon";
import { Slot } from "reducers/slot";
import { Unavailability } from "reducers/unavailability";
import { Availability } from "reducers/week";
import { LastMinute } from "reducers/lastMinute";
import { allowedHoursBefore, hoursToMinutes } from "./lastMinute";
import { CommonRange } from "@leciseau/schedule-manager";

const hairdresserToResource = (
  hairdresser: Hairdresser,
  companyCode: string,
  isOneCatalog?: boolean
): CalendarResource => ({
  id: hairdresser.id || Number(hairdresser.stringId) || 0,
  stringId:
    companyCode &&
    isOneCatalog &&
    hairdresser.stringId &&
    hairdresser.stringId !== ""
      ? `${companyCode}|${hairdresser.stringId}`
      : "",
  title: hairdresser.firstName,
  color: hairdresser.color,
  status: hairdresser.status,
  partnerRef: hairdresser.partnerRefs?.find(
    (partner) => partner.name === "flexy"
  ),
  icon: (props) => <HairdresserAvatar hairdresser={hairdresser} {...props} />,
});

const slotShouldBeDisplayed = (filters: AgendaFilters) => (slot: Slot) =>
  filters.showFreeSlots &&
  (filters.hairdresserIds.includes(slot.hairdresserId || 0) ||
    filters.hairdresserIds.includes(
      Number(slot.hairdresserStringId?.split("|")[1]) || 0
    ));

const unavailabilityShouldBeDisplayed = (filters: AgendaFilters) => (
  unavailability: Unavailability
) =>
  filters.showUnavailabilities &&
  (filters.hairdresserIds.includes(unavailability.hairdresserId) ||
    filters.hairdresserIds.includes(
      Number(unavailability.hairdresserStringId?.split("|")[1]) || 0
    ));

const bookingShouldBeDisplayed = (filters: AgendaFilters) => (
  booking: Booking
) =>
  filters.showBookings &&
  (filters.hairdresserIds.includes(
    booking.snapshotPackages[0].hairdresser.id
  ) ||
    filters.hairdresserIds.includes(
      Number(booking.snapshotPackages[0].hairdresser.stringId)
    )) &&
  ["CONFIRMED", "PAID"].includes(booking.status);

const slotToEvent = (slot: Slot): CalendarEvent<ISlotEventMeta> => ({
  id: slot.id,
  meta: {
    slot,
    kind: AGENDA_EVENT_KINDS.SLOT,
  },
  resourceId:
    ((!slot?.hairdresserStringId || slot?.hairdresserStringId === "") &&
      slot.hairdresserId) ||
    Number(slot?.hairdresserStringId?.split("|")[1] || "") ||
    0,
  hairdresserStringId: slot.hairdresserStringId || "",
  start: moment(slot.range.start),
  end: moment(slot.range.end),
});

const commonRangeToSlot = (
  commonRange: CommonRange & { hairdresserStringId?: string }
): CalendarEvent<ISlotEventMeta> => ({
  id: commonRange.id || 0,
  meta: {
    slot: commonRange as Slot,
    kind: AGENDA_EVENT_KINDS.SLOT,
  },
  resourceId: commonRange.hairdresserId,
  hairdresserStringId: commonRange.hairdresserStringId || "",
  start: moment(commonRange.range.start),
  end: moment(commonRange.range.end),
});

const lmToEvents = (
  slot: CommonRange & { hairdresserStringId?: string }
): CalendarEvent<ILastMinuteEventMeta> => ({
  id: 0,
  meta: {
    kind: AGENDA_EVENT_KINDS.LAST_MINUTE,
  },
  resourceId: slot.hairdresserId,
  hairdresserStringId: slot.hairdresserStringId || "",
  start: moment(slot.range.start),
  end: moment(slot.range.end),
  discount: slot.discount,
});

const availabilitiesToEvents = (
  availabilitiesByHairdresserId: Record<number, Array<Availability>>
): Array<CalendarEvent<IAvailableEventMeta>> =>
  Object.values(availabilitiesByHairdresserId).reduce(
    (
      acc: Array<CalendarEvent<IAvailableEventMeta>>,
      availabilities: Array<Availability>
    ) => [
      ...acc,
      ...availabilities.map((availability) => ({
        id: 0,
        meta: {
          availability,
          kind: AGENDA_EVENT_KINDS.AVAILABLE,
        },
        resourceId:
          availability.hairdresserId ||
          Number(availability?.hairdresserStringId?.split("|")[1] || "") ||
          0,
        hairdresserStringId: availability.hairdresserStringId || "",
        start: moment(availability.range.start),
        end: moment(availability.range.end),
      })),
    ],
    [] as Array<CalendarEvent<IAvailableEventMeta>>
  );

const unavailabilityToEvent = (
  unavailability: Unavailability
): CalendarEvent<IUnavailableEventMeta> => ({
  id: unavailability.id,
  meta: {
    unavailability,
    kind: AGENDA_EVENT_KINDS.UNAVAILABLE,
  },
  resourceId:
    unavailability.hairdresserId ||
    Number(unavailability?.hairdresserStringId?.split("|")[1] || "") ||
    0,
  hairdresserStringId: unavailability.hairdresserStringId || "",
  start: moment(unavailability.range.start),
  end: moment(unavailability.range.end),
});

const bookingToEvent = (
  booking: Booking
): CalendarEvent<IBookingEventMeta> => ({
  id: booking.id,
  meta: {
    booking,
    kind: AGENDA_EVENT_KINDS.BOOKING,
  },
  resourceId:
    booking.snapshotPackages[0].hairdresser.id ||
    Number(booking.snapshotPackages[0].hairdresser.stringId) ||
    "" ||
    0,
  hairdresserStringId: booking.snapshotPackages[0].hairdresser.stringId || "",
  start: moment(booking.dateStart),
  end: moment(booking.dateEnd),
});

const getStartViaIndex = (start: moment.Moment, index: number) => {
  if (index === 0) {
    return start;
  }

  return moment(start).add(index, "days").startOf("day");
};

const getEndViaIndex = (
  start: moment.Moment,
  end: moment.Moment,
  index: number,
  range: number[]
) => {
  if (index === range.length - 1) {
    return end;
  }

  return moment(start).add(index, "days").endOf("day");
};

const splitCommonRangePerDay = (slot: CommonRange): CommonRange[] => {
  const slotStart = moment(slot.range.start);
  const slotEnd = moment(slot.range.end);
  const daysToAdd = slotEnd.diff(slotStart, "days");
  const range = new Array(daysToAdd + 1).fill(undefined);
  const splits = range.map((_, index) => ({
    ...slot,
    start: getStartViaIndex(slotStart, index),
    end: getEndViaIndex(slotStart, slotEnd, index, range),
  }));

  return splits;
};

const lastMinutesToCommonRanges = (
  lastMinutes: LastMinute[],
  resources: CalendarResource[]
): CommonRange[] => {
  const resourcesSlots = resources.reduce(
    (allSlots: CommonRange[], resource: CalendarResource) => {
      const orderedLastMinutes = allowedHoursBefore
        .map((hours: number) => {
          const foundLastMinutes = lastMinutes.filter(
            (lastM) => lastM.minutesBefore >= hoursToMinutes(hours)
          );
          if (!foundLastMinutes.length) {
            return undefined;
          }
          foundLastMinutes.sort((a, b) => b.discount - a.discount);
          return {
            duration: hoursToMinutes(hours),
            ...foundLastMinutes[0],
          };
        })
        .filter(Boolean) as Array<LastMinute & { duration: number }>;

      const rawSlots = orderedLastMinutes.reduce(
        (
          slots: CommonRange[],
          lastMinute: LastMinute & { duration: number },
          lmIndex
        ) => {
          const start =
            slots && slots.length
              ? moment(slots[slots.length - 1].range.end)
              : moment();
          const end =
            lmIndex > 0
              ? moment(start).add(
                  lastMinute.duration -
                    orderedLastMinutes[lmIndex - 1].duration,
                  "minutes"
                )
              : moment(start).add(lastMinute.duration, "minutes");
          return [
            ...slots,
            {
              hairdresserId: resource.id,
              discount: lastMinute.discount,
              range: {
                start: start.format(),
                end: end.format(),
              },
            },
          ];
        },
        []
      );

      const splittedSlots = rawSlots.reduce(
        (slots: CommonRange[], slot: CommonRange) => {
          const splitted = splitCommonRangePerDay(slot);
          return [...slots, ...splitted];
        },
        []
      );

      return [...allSlots, ...splittedSlots];
    },
    []
  );

  return resourcesSlots;
};

const enabledHairdresserFirst = (
  { status: a }: Hairdresser,
  { status: b }: Hairdresser
) => {
  if (a === b) return 0;
  return a === STATUSES.ENABLED ? -1 : 1;
};

const synchroSaas = (companyId: string) =>
  fetch(`${process.env.REACT_APP_UPDATE_SAAS_URL}?id=${companyId}`, {
    method: "GET",
    mode: "no-cors",
  });

export {
  hairdresserToResource,
  slotShouldBeDisplayed,
  unavailabilityShouldBeDisplayed,
  bookingShouldBeDisplayed,
  slotToEvent,
  unavailabilityToEvent,
  bookingToEvent,
  enabledHairdresserFirst,
  synchroSaas,
  availabilitiesToEvents,
  lastMinutesToCommonRanges,
  lmToEvents,
  commonRangeToSlot,
};
