export const DAYS_PER_WEEK = 7;

export const VIEWS = {
  DAY: "DAY" as const,
  WEEK: "WEEK" as const
};

export const HEADER_HEIGHT = 35;

// TODO find a way to share is with sass
export const CELL_HEIGHT = 25;

export const COLUMN_WIDTH = {
  DAY_MODE: 85,
  WEEK_MODE: 48
};
export const RULER_WIDTH = 45;
