import * as types from "../constants/ActionTypes";
import { fetchPaidBookings, fetchPayouts } from "../utils/payments";
import { Dispatch } from "redux";
import { errorToString } from "../utils/string";
import { pushMessage } from "./SnackbarActions";
import { Payout } from "../reducers/payments";
import { Booking } from "../reducers/booking";
import { AuthLogoutAction } from "./AuthActions";
import { PAYOUT_LIMIT } from "../constants/Payment";
import { MESSAGE_TYPES } from "../constants/SnackBar";

interface FetchPaidBookingsAction {
  type: typeof types.FETCH_PAID_BOOKINGS;
}

export interface FetchPaidBookingsSuccessAction {
  type: typeof types.FETCH_PAID_BOOKINGS_SUCCESS;
  payload: Array<Booking>;
}

interface FetchPaidBookingsFailureAction {
  type: typeof types.FETCH_PAID_BOOKINGS_FAILURE;
  error: string;
}

interface FetchPayoutsAction {
  type: typeof types.FETCH_PAYOUTS;
}

export interface FetchPayoutsSuccessAction {
  type: typeof types.FETCH_PAYOUTS_SUCCESS;
  payload: { salonId: number; payouts: Array<Payout> };
}

interface FetchPayoutsFailureAction {
  type: typeof types.FETCH_PAYOUTS_FAILURE;
  error: string;
}

export type PaymentsActions =
  | FetchPaidBookingsAction
  | FetchPaidBookingsSuccessAction
  | FetchPaidBookingsFailureAction
  | FetchPayoutsAction
  | FetchPayoutsSuccessAction
  | FetchPayoutsFailureAction
  | AuthLogoutAction;

export const fetchPaidBookingsSuccess = (
  bookings: Array<Booking>
): FetchPaidBookingsSuccessAction => ({
  type: types.FETCH_PAID_BOOKINGS_SUCCESS,
  payload: bookings
});

export const fetchPaidBookingsFailure = (
  error: string
): FetchPaidBookingsFailureAction => ({
  type: types.FETCH_PAID_BOOKINGS_FAILURE,
  error
});

export const fetchPaidBookingsBySalonId = (
  salonId: number,
  range: { start: string; end: string }
) => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: types.FETCH_PAID_BOOKINGS });
  return fetchPaidBookings(salonId, range)
    .then((bookings: Array<Booking>) =>
      dispatch(fetchPaidBookingsSuccess(bookings))
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchPaidBookingsFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchPayoutsSuccess = (
  salonId: number,
  payouts: Array<Payout>
): FetchPayoutsSuccessAction => ({
  type: types.FETCH_PAYOUTS_SUCCESS,
  payload: { salonId, payouts }
});

export const fetchPayoutsFailure = (
  error: string
): FetchPayoutsFailureAction => ({
  type: types.FETCH_PAYOUTS_FAILURE,
  error
});

export const fetchPayoutsBySalonId = (salonId: number, lastId?: string) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: types.FETCH_PAYOUTS });
  return fetchPayouts(salonId, lastId)
    .then((payouts: Array<Payout>) => {
      dispatch(fetchPayoutsSuccess(salonId, payouts));
      return payouts;
    })
    .then(payouts => {
      if (payouts.length === PAYOUT_LIMIT) {
        return dispatch(
          fetchPayoutsBySalonId(salonId, payouts[payouts.length - 1].id) as any
        );
      }
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchPayoutsFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
