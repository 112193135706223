import React from "react";

const RatingMenuIcon = ({ color, ...props }: any) => (
  <svg viewBox="0 0 60 60" height={25}>
    <defs>
      <style>
        {
          ".rating-1,.rating-2{stroke-linecap:round;stroke-linejoin:round}.rating-1{stroke-width:3px}.rating-2{stroke-width:2.4px}"
        }
      </style>
    </defs>
    <path
      className="rating-1"
      d="M14.05 22.65H9.58a.75.75 0 00-.73.73v19.8a.72.72 0 00.73.73h5l-.88 3.94a.83.83 0 00.34.78 1.15 1.15 0 00.34.09.63.63 0 00.49-.19l5.06-4.62h12a.75.75 0 00.73-.73v-4"
    />
    <path
      className="rating-1"
      d="M50.42 11.28H16.73A.75.75 0 0016 12v24.56a.75.75 0 00.73.73h21.78l5.88 5.39a.62.62 0 00.49.2.72.72 0 00.34-.1.65.65 0 00.34-.78l-1-4.71h5.88a.75.75 0 00.73-.73V12a.75.75 0 00-.75-.72z"
    />
    <circle className="rating-2" cx={25.88} cy={24.23} r={2.03} />
    <circle className="rating-2" cx={33.03} cy={24.23} r={2.03} />
    <circle className="rating-2" cx={40.18} cy={24.23} r={2.03} />
  </svg>
);

export default RatingMenuIcon;
