import { Dispatch } from "redux";

import * as API from "api/unavailabilities";
import * as types from "constants/ActionTypes";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { Unavailability, UnavailabilityPayload } from "reducers/unavailability";
import { errorToString } from "utils/string";
import { getFetchRangeOptions } from "utils/slot";
import { pushMessage } from "./SnackbarActions";

interface FetchUnavailabilityAction {
  type: typeof types.FETCH_UNAVAILABILITIES;
}

export interface FetchUnavailabilitySuccessAction {
  type: typeof types.FETCH_UNAVAILABILITIES_SUCCESS;
  payload: UnavailabilityPayload & {
    dateRange: {
      endGte: string;
      startLte: string;
    };
  };
}

interface FetchUnavailabilityFailureAction {
  type: typeof types.FETCH_UNAVAILABILITIES_FAILURE;
  error: string;
}

export type UnavailabilityActions =
  | FetchUnavailabilityAction
  | FetchUnavailabilitySuccessAction
  | FetchUnavailabilityFailureAction;

export const fetchSuccess = (
  data: UnavailabilityPayload,
  dateRange: {
    endGte: string;
    startLte: string;
  }
) => ({
  type: types.FETCH_UNAVAILABILITIES_SUCCESS,
  payload: { ...data, dateRange },
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_UNAVAILABILITIES_FAILURE,
  error,
});

export const fetchUnavailabilitiesByHairdresserIds = (
  hairdresserIds: Array<number>,
  hairdresserStringIds: Array<string>,
  dateTarget: string,
  { range = "DAY" }: { range?: "DAY" | "WEEK" | "MONTH" } = {}
) => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: types.FETCH_UNAVAILABILITIES });
  const options = getFetchRangeOptions(dateTarget, range);
  return API.fetchByHairdresserIds(
    hairdresserIds,
    hairdresserStringIds,
    options
  )
    .then((unavailabilities: Record<number, Unavailability>) =>
      dispatch(fetchSuccess({ unavailabilityById: unavailabilities }, options))
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
