import React, { useState } from "react";
import { useKey } from "react-use";
import * as EmailValidator from "email-validator";

import TextField from "components/inputs/Text";
import PasswordField from "components/inputs/Password";
import ButtonFab from "components/button/Fab";
import DesktopModalContainer from "components/modal/Container/Container.desktop_old";
import DesktopModalHeader from "components/modal/Header/Header.desktop";
import ButtonText from "components/button/Text";
import "./login-page.scss";
import PhaseTwo from "components/svgs/LogoTransition/PhaseTwo";

interface ResetPasswordModalProps {
  isOpened: boolean;
  onClose(): void;
  onSubmit(email: string): Promise<void>;
}

function ResetPasswordModal(props: ResetPasswordModalProps) {
  const { isOpened, onClose, onSubmit } = props;
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>();
  const submit = () => {
    if (!email || email.length === 0) {
      setError("Veuillez saisir votre email");
    } else if (!EmailValidator.validate(email)) {
      setError("Le format de votre adresse email n'est pas valide");
    } else {
      onSubmit(email).then(onClose);
    }
  };
  return (
    <DesktopModalContainer fullWidth={false} open={isOpened}>
      <div className="reset-password-modal">
        <DesktopModalHeader
          onClose={onClose}
          title="Indiquez votre adresse email pour réinitialiser votre mot de passe"
        />
        <div>
          <TextField
            fullWidth
            autoFocus
            type="email"
            autoComplete="username"
            name="username"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        {error && <span className="form-input-error">{error}</span>}
        <ButtonFab onClick={submit}>Confirmer</ButtonFab>
        <ButtonText onClick={onClose}>Annuler</ButtonText>
      </div>
    </DesktopModalContainer>
  );
}

interface LoginPageProps {
  username: string;
  password: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit(): void;
  onResetPassword(email: string): Promise<void>;
  isLoading: boolean;
}

function LoginPage(props: LoginPageProps) {
  const {
    username,
    password,
    onChange,
    onSubmit,
    isLoading,
    onResetPassword,
  } = props;
  const [isResetPasswordOpened, setIsResetPasswordOpened] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.name === "username") {
      if (
        event.target.value.length === 0 ||
        EmailValidator.validate(event.target.value)
      ) {
        setUsernameErrorMessage("");
      }
    }
    onChange(event);
  };

  const submit = () => {
    let isValid = true;

    if (!username || username.length === 0) {
      isValid = false;
      setUsernameErrorMessage("Veuillez saisir votre adresse email");
    } else if (!EmailValidator.validate(username)) {
      isValid = false;
      setUsernameErrorMessage(
        "Le format de votre adresse email n'est pas valide"
      );
    } else {
      setUsernameErrorMessage("");
    }

    if (!password || password.length === 0) {
      isValid = false;
      setPasswordErrorMessage("Veuillez saisir votre mot de passe");
    } else {
      setPasswordErrorMessage("");
    }

    if (isValid) {
      onSubmit();
    }
  };

  useKey("Enter", submit, undefined, [submit]);
  return (
    <React.Fragment>
      <div className="login-page-container">
        <div>
          <div className="login-page-header">
            <div className="login-page-header__logo">
              <PhaseTwo />
            </div>
            <div className="login-page-header__description">
              <span>
                L’application pour les professionnels de la beauté qui
                souhaitent remplir leur établissement avec de nouveaux clients à
                la dernière minute et en heures creuses.
              </span>
            </div>
          </div>
          <form onSubmit={submit} className="login-page-header__form">
            <div className="input-group">
              <TextField
                fullWidth
                label="Email"
                type="email"
                autoComplete="username"
                name="username"
                value={username}
                onChange={onChangeHandler}
                error={usernameErrorMessage.length > 0}
                helperText={usernameErrorMessage}
              />
            </div>
            <div className="input-group">
              <PasswordField
                fullWidth
                label="Mot de passe"
                name="password"
                value={password}
                onChange={onChangeHandler}
                error={passwordErrorMessage.length > 0}
                helperText={passwordErrorMessage}
              />
            </div>
            <span
              className="login-form_password-reset"
              onClick={() => setIsResetPasswordOpened(true)}
            >
              Mot de passe oublié ?
            </span>
          </form>
        </div>
        <div className="login-page-header__submit">
          <ButtonFab onClick={submit} isLoading={isLoading}>
            Se Connecter
          </ButtonFab>
          <a href="https://pro.kiute.com/fr/demo/ ">
            <span className="login-page_signup-salon_button">
              Inscrire mon salon
            </span>
          </a>
        </div>
      </div>
      <ResetPasswordModal
        isOpened={isResetPasswordOpened}
        onClose={() => setIsResetPasswordOpened(false)}
        onSubmit={onResetPassword}
      />
    </React.Fragment>
  );
}

export default LoginPage;
