import React, { memo } from "react";

const SmallAddCircle = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="#fff" d="M0 0h15v15H0z" />
        <path
          d="M7.5.25a7.25 7.25 0 107.25 7.25A7.255 7.255 0 007.5.25zm4.49 7.77H8.01V12a.51.51 0 01-1.02 0V8.02H3.01a.51.51 0 110-1.02h3.98V3.01a.51.51 0 011.02 0v3.98h3.98a.516.516 0 01.05 1.03.045.045 0 00-.05 0z"
          fill="#ababab"
        />
      </g>
    </svg>
  );
};

export default memo(SmallAddCircle);
