import React, { HTMLProps } from "react";
import styles from "./Variant.module.scss";
import { displayableVariantName } from "./utils";
import classnames from "classnames";

export interface IVariant {
  id: number;
  name: string;
}

interface IVariantProps extends Pick<HTMLProps<HTMLDivElement>, "className"> {
  variant: IVariant;
}

export default function Variant({ variant, className }: IVariantProps) {
  const { name: rawName } = variant;
  const name = displayableVariantName(rawName);
  return (
    <>
      {name.length > 0 && (
        <div className={classnames(styles.container, className)}>
          <span>{name}</span>
        </div>
      )}
    </>
  );
}
