import React from "react";
import SelectAutocomplete, {
  ISelectAutocomplete,
  IOptionType,
} from "./inputs/SelectAutocomplete/SelectAutocomplete";
import { searchSalonsByName } from "api/salon";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "reducers";
import { selectSalonById, fetchSalons } from "actions/SalonActions";
import useCurrentUser from "hooks/useCurrentUser";

export interface SalonBasic {
  id: number;
  name: string;
  status: string;
}

interface SalonSelectorProps {
  onSalonChange?(newSalonId: number): void;
}

const salonPayloadSelector = (state: AppState) => state.salon.payload;
const authPayloadSelector = (state: AppState) => state.auth.payload;

const salonBasicToOption = (salon: SalonBasic): IOptionType => ({
  value: salon.id.toString(),
  label: salon.name,
});

const searchSalonsForAdmins = async (salonName: string) => {
  const salons = await searchSalonsByName<SalonBasic>(salonName, [
    "id",
    "name",
    "status",
  ]);
  return salons.map(salonBasicToOption);
};

function SalonSelector({ onSalonChange }: SalonSelectorProps) {
  const { salonById, selectedSalonId } = useSelector(salonPayloadSelector);
  const dispatch = useDispatch();

  const selectSalon = (salonId: number) =>
    ((dispatch(selectSalonById(salonId)) as unknown) as Promise<any>).then(
      () => {
        onSalonChange && onSalonChange(salonId);
      }
    );

  const selectedSalon =
    selectedSalonId !== undefined ? salonById[selectedSalonId] : undefined;

  const options = Object.values(salonById).map(salonBasicToOption);
  const value = selectedSalon
    ? {
        value: selectedSalon.id.toString(),
        label: selectedSalon.name,
      }
    : undefined;

  const { isAdmin, isSalonManager } = useCurrentUser();
  // Live search of salons via ES for admins so they always have
  // the most up to date salons in db
  const loadOptions = isAdmin ? searchSalonsForAdmins : undefined;

  const auth = useSelector(authPayloadSelector);
  const refetchAuthSalons = () => {
    console.log("refetchAuthSalons");
    dispatch(fetchSalons({ auth }));
  };

  // Makes sure to have the latest list of Salons for the current salon manager
  const onMenuOpen = isSalonManager ? refetchAuthSalons : undefined;
  return (
    <SalonSelectorTemplate
      cacheOptions
      loadOptions={loadOptions}
      selectedSalon={selectedSalon}
      onSelectSalon={selectSalon}
      options={options}
      value={value}
      onMenuOpen={onMenuOpen}
    />
  );
}

interface ISalonSelectorTemplateProps {
  value?: IOptionType;
  onSelectSalon?(id: number): void;
  options: Array<IOptionType>;
}

function SalonSelectorTemplate({
  onSelectSalon,
  ...otherProps
}: ISalonSelectorTemplateProps & Partial<ISelectAutocomplete>) {
  const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
    inputValue?.length === 0
      ? "Tapez le nom de l'établissement ..."
      : "Pas d'établissement";
  return (
    <SelectAutocomplete
      onChange={({ value }: IOptionType) =>
        onSelectSalon && onSelectSalon(parseInt(value))
      }
      placeholder={"Selectionner un établissement..."}
      noOptionsMessage={noOptionsMessage}
      {...otherProps}
    />
  );
}

export default SalonSelector;
