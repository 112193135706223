import React from "react";
import { WHITE, TEALISH } from "../../constants/Style";

const AddIconWithCircle = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, ...otherProps } = props;
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...otherProps}>
      <g fillRule="nonzero" fill="none">
        <circle fill={color || TEALISH} cx={11} cy={11} r={11} />
        <path
          d="M6 11.5h9.505M11 6.5V17"
          stroke={WHITE}
          strokeWidth={2}
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
};

export default AddIconWithCircle;
