import * as yup from "yup";

import { unavailabilitySchema } from "reducers/unavailability";
import {
  callWithPagination,
  objToQueryArgs,
  query,
  safeError,
} from "utils/graphqlClient";

const unavailabilitiesFields = `
    id,
    description,
    createdAt,
    updatedAt,
    hairdresserStringId,
    hairdresser {
        id,
    },
    range {
        start,
        end,
    },
    partnerRef {
        id,
        name,
    },
    recurrence {
        id,
        rules,
        description,
        occurrences
    },
`;

export const fetchByHairdresserIds = callWithPagination(
  (
    hairdresserIds: Array<number>,
    hairdresserStringIds: Array<string>,
    opts: any = {}
  ) => async (offset: number, limit: number) => {
    const args = objToQueryArgs({
      limit,
      offset,
      ...opts,
    });
    const res = await query(
      `unavailabilities(${args}, ${
        hairdresserIds.length
          ? `hairdresserIds: "${hairdresserIds.toString()}", `
          : ""
      }${
        hairdresserStringIds.length
          ? `hairdresserStringIds: "${hairdresserStringIds.join(",")}", `
          : ""
      }){${unavailabilitiesFields}}`
    );
    const body = await res.json();
    if (res.ok) {
      try {
        const unavailabilities = await yup
          .array()
          .of(unavailabilitySchema)
          .validate(
            body?.data?.unavailabilities.map(
              ({ hairdresser, ...values }: any) => ({
                ...values,
                ...(hairdresser
                  ? { hairdresserId: Number(hairdresser?.id) }
                  : {}),
              })
            )
          );
        return Promise.resolve(unavailabilities);
      } catch (error) {
        console.error({ error });
      }
    }
    return Promise.reject(safeError(body));
  }
);
