import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchBookingsBySalonId } from "actions/BookingActions";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import BookingsPage from "components/bookings/BookingsPage";
import BookingForm from "containers/Agenda/BookingForm";
import { AppState } from "reducers";
import { Booking } from "reducers/booking";
import { Hairdresser } from "reducers/salon";
import { getBookingsByHairdressers } from "utils/booking";
import useCurrentSalon from "hooks/useCurrentSalon";

function BookingsPageContainer() {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [bookingToEdit, setBookingToEdit] = useState<Booking | undefined>();
  const [date, setDate] = useState<moment.Moment>(moment().startOf("month"));
  const [showCanceled, setShowCanceled] = useState(false);

  const [selectedHairdresserId, setSelectedHairdresserId] = useState<
    number | null
  >(null);

  const bookingState = useSelector(bookingStateSelector);
  const { salon, hairdressers } = useCurrentSalon();
  const dispatch = useDispatch();
  const fetchAllBookings = () => {
    if (bookingState.loading) {
      return;
    }
    dispatch(
      fetchBookingsBySalonId(salon.id, moment(date).toISOString(), {
        range: "MONTH",
      })
    );
  };

  useEffect(() => {
    fetchAllBookings();
    const handle = setInterval(fetchAllBookings, 60000);
    return () => clearInterval(handle);
  }, [date]);

  const getBookings = useCallback(
    (selectedHairdresserId: number | null) => {
      return getBookingsByHairdressers(
        selectedHairdresserId
          ? hairdressers.filter(
              (h) =>
                h.id === selectedHairdresserId ||
                Number(h.stringId) === selectedHairdresserId
            )
          : hairdressers,
        bookingState
      ).filter((booking) => {
        return date.isSame(moment(booking.dateStart), "month");
      });
    },
    [salon.id, date, bookingState]
  );

  const [bookings, setBookings] = useState(getBookings(selectedHairdresserId));

  useEffect(() => {
    setBookings(getBookings(selectedHairdresserId));
  }, [selectedHairdresserId, getBookings]);

  const editBooking = useCallback((booking: Booking) => {
    setBookingToEdit(booking);
    setIsModalOpened(true);
  }, []);

  const closeModal = () => {
    setIsModalOpened(false);
    setBookingToEdit(undefined);
  };

  const hairdressersWithBookings = Object.values(
    getBookings(null).reduce(
      (hairdressersById: Record<number, Hairdresser>, booking) => {
        const hairdresserId = booking.snapshotPackages[0].hairdresser.id;
        const hairdresserStringId =
          booking.snapshotPackages[0].hairdresser.stringId;
        const hairdresser = hairdresserId
          ? hairdressers.find((h) => h.id && h.id === hairdresserId)
          : hairdressers.find((h) => h.stringId == String(hairdresserStringId));
        return hairdresser
          ? {
              ...hairdressersById,
              [hairdresserId || Number(hairdresserStringId)]: hairdresser,
            }
          : hairdressersById;
      },
      {}
    )
  );

  useEffect(() => {
    const hairdressersWithBookingsIds = hairdressersWithBookings.map(
      (h) => h.id || Number(h.stringId)
    );
    if (
      selectedHairdresserId !== null &&
      !hairdressersWithBookingsIds.includes(selectedHairdresserId)
    ) {
      setSelectedHairdresserId(null);
    }
  }, [hairdressersWithBookings]);
  return (
    <React.Fragment>
      <BookingsPage
        date={date}
        updateDate={(date) => setDate(moment(date).startOf("month"))}
        hairdressers={hairdressersWithBookings}
        selectedHairdresserId={selectedHairdresserId}
        setSelectedHairdresserId={setSelectedHairdresserId}
        bookings={bookings}
        editBooking={editBooking}
        showCanceled={showCanceled}
        setShowCanceled={setShowCanceled}
      />
      {bookingState.loading && (
        <CircularProgress className="ui-loader-centered" />
      )}
      <ResponsiveModalContainer
        fullScreenOnMobile
        open={isModalOpened}
        direction="up"
      >
        <>
          {bookingToEdit && (
            <BookingForm
              withDetails
              bookingToEdit={bookingToEdit}
              onClose={closeModal}
            />
          )}
        </>
      </ResponsiveModalContainer>
    </React.Fragment>
  );
}

function bookingStateSelector(state: AppState) {
  return state.booking;
}

export default BookingsPageContainer;
