import * as types from "constants/ActionTypes";
import {
  fetchSalons as fetchSalonsFromServer,
  fetchInvoicesBySalonId,
  updatePackageById,
  createSalonHairdresser,
  updateHairdresserById,
  getHairdressersById,
  updateSalonById,
  fetchBroadcastListsBySalonIds,
  fetchStripeCapabilitiesRequest,
  updateBroadcastList as updateBroadcastListFromServer,
} from "utils/salon";
import { errorToString } from "utils/string";
import {
  SalonPayload,
  Invoice,
  Package,
  Price,
  Hairdresser,
  Access,
  BroadcastList,
  Salon,
  ApprovalRequest,
  StripeCapabilitiesRequirement,
  CapabilitiesPayload,
  PartnerRef,
} from "reducers/salon";
import { AuthPayload } from "reducers/auth";
import { Dispatch } from "redux";
import { AppState } from "reducers";
import { pushMessage } from "./SnackbarActions";
import { AuthLogoutAction } from "./AuthActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { setSalonPublic } from "api/salon";
import * as ApprovalRequestAPI from "api/approvalRequest";

interface SalonFetchSalonsAction {
  type: typeof types.FETCH_SALONS;
}

interface SalonFetchSuccessAction {
  type: typeof types.FETCH_SALONS_SUCCESS;
  payload: SalonPayload;
}

interface SalonFetchFailureAction {
  type: typeof types.FETCH_SALONS_FAILURE;
  error: string;
}

interface SalonFetchInvoicesAction {
  type: typeof types.FETCH_INVOICES;
}

export interface SalonFetchInvoicesSuccessAction {
  type: typeof types.FETCH_INVOICES_SUCCESS;
  payload: {
    salonId: number;
    invoices: Array<Invoice>;
  };
}
interface SalonFetchInvoicesFailureAction {
  type: typeof types.FETCH_INVOICES_FAILURE;
  error: string;
}

interface SalonUpdateAction {
  type: typeof types.UPDATE_SALON;
}

export interface SalonUpdateSuccessAction {
  type: typeof types.UPDATE_SALON_SUCCESS;
  payload: {
    id: number;
    name: string;
    description: string;
    address: any;
    recommendation: string;
    access: Array<Access>;
    backgrounds: Array<string>;
    carouselImages: Array<string>;
  };
}
interface SalonUpdateFailureAction {
  type: typeof types.UPDATE_SALON_FAILURE;
  error: string;
}

interface BroadcastListUpdateAction {
  type: typeof types.UPDATE_BROADCAST_LIST;
}

export interface BroadcastListUpdateSuccessAction {
  type: typeof types.UPDATE_BROADCAST_LIST_SUCCESS;
  payload: BroadcastList & { salon: { id: number } };
}
interface BroadcastListUpdateFailureAction {
  type: typeof types.UPDATE_BROADCAST_LIST_FAILURE;
  error: string;
}

interface HairdresserCreateAction {
  type: typeof types.CREATE_HAIRDRESSER;
}

export interface HairdresserCreateSuccessAction {
  type: typeof types.CREATE_HAIRDRESSER_SUCCESS;
  payload: Hairdresser;
}
interface HairdresserCreateFailureAction {
  type: typeof types.CREATE_HAIRDRESSER_FAILURE;
  error: string;
}

interface HairdresserUpdateAction {
  type: typeof types.UPDATE_HAIRDRESSER;
}

export interface HairdresserUpdateSuccessAction {
  type: typeof types.UPDATE_HAIRDRESSER_SUCCESS;
  payload: Hairdresser;
}
interface HairdresserUpdateFailureAction {
  type: typeof types.UPDATE_HAIRDRESSER_FAILURE;
  error: string;
}

interface PackageUpdateAction {
  type: typeof types.UPDATE_PACKAGE;
}

export interface PackageUpdateSuccessAction {
  type: typeof types.UPDATE_PACKAGE_SUCCESS;
  payload: {
    salonId: number;
    pack: Package;
    hairdressers?: Array<Hairdresser>;
  };
}

interface PackageUpdateFailureAction {
  type: typeof types.UPDATE_PACKAGE_FAILURE;
  error: string;
}

interface SalonSetPublicAction {
  type: typeof types.SET_PUBLIC_SALON;
}

export interface SalonSetPublicSuccessAction {
  type: typeof types.SET_PUBLIC_SALON_SUCCESS;
  payload: { id: number };
}

interface SalonSetPublicFailureAction {
  type: typeof types.SET_PUBLIC_SALON_FAILURE;
  error: string;
}

interface FetchApprovalRequestsAction {
  type: typeof types.FETCH_APPROVAL_REQUESTS;
}

export interface FetchApprovalRequestsSuccessAction {
  type: typeof types.FETCH_APPROVAL_REQUESTS_SUCCESS;
  payload: {
    type?: string;
    salonId: number;
    approvalRequests: Array<ApprovalRequest>;
  };
}

interface FetchApprovalRequestsFailureAction {
  type: typeof types.FETCH_APPROVAL_REQUESTS_FAILURE;
  error: string;
}

interface FetchStripeCapabilitiesAction {
  type: typeof types.FETCH_SALON_STRIPE_CAPABILITIES;
}

export interface FetchStripeCapabilitiesSuccessAction {
  type: typeof types.FETCH_SALON_STRIPE_CAPABILITIES_SUCCESS;
  payload: {
    requirements: StripeCapabilitiesRequirement;
    status: string;
  };
}

interface FetchStripeCapabilitiesFailureAction {
  type: typeof types.FETCH_SALON_STRIPE_CAPABILITIES_FAILURE;
  error: string;
}

export type SalonActions =
  | FetchStripeCapabilitiesAction
  | FetchStripeCapabilitiesSuccessAction
  | FetchStripeCapabilitiesFailureAction
  | SalonFetchSalonsAction
  | SalonFetchSuccessAction
  | SalonFetchFailureAction
  | SalonFetchInvoicesAction
  | SalonFetchInvoicesSuccessAction
  | SalonFetchInvoicesFailureAction
  | SalonUpdateAction
  | SalonUpdateSuccessAction
  | SalonUpdateFailureAction
  | BroadcastListUpdateAction
  | BroadcastListUpdateSuccessAction
  | BroadcastListUpdateFailureAction
  | HairdresserCreateAction
  | HairdresserCreateSuccessAction
  | HairdresserCreateFailureAction
  | HairdresserUpdateAction
  | HairdresserUpdateSuccessAction
  | HairdresserUpdateFailureAction
  | AuthLogoutAction
  | PackageUpdateAction
  | PackageUpdateSuccessAction
  | PackageUpdateFailureAction
  | SalonSetPublicAction
  | SalonSetPublicSuccessAction
  | SalonSetPublicFailureAction
  | FetchApprovalRequestsAction
  | FetchApprovalRequestsSuccessAction
  | FetchApprovalRequestsFailureAction;

export const selectSalonById = (id: number) => (dispatch: Dispatch) =>
  dispatch(fetchSalons({ ids: [id], selectedSalonId: id }) as any);

export const fetchSuccess = (data: SalonPayload) => ({
  type: types.FETCH_SALONS_SUCCESS,
  payload: data,
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_SALONS_FAILURE,
  error,
});

export const fetchStripeCapabilities = (id: number) => (dispatch: Dispatch) => {
  fetchStripeCapabilitiesRequest(id)
    .then((data: CapabilitiesPayload) => {
      dispatch({
        type: types.FETCH_SALON_STRIPE_CAPABILITIES_SUCCESS,
        payload: data,
      });
    })
    .catch((error: string) => {
      dispatch({
        type: types.FETCH_SALON_STRIPE_CAPABILITIES_FAILURE,
        error,
      });
    });
  return dispatch({ type: types.FETCH_SALON_STRIPE_CAPABILITIES });
};

export const fetchSalons = ({
  auth,
  ids,
  selectedSalonId,
}: {
  auth?: AuthPayload;
  ids?: Array<number>;
  selectedSalonId?: number;
}) => (
  dispatch: Dispatch,
  getState: { (): AppState }
): Promise<{ (): AppState }> => {
  if ((auth && "authId" in auth) || ids) {
    const authId = auth && "authId" in auth ? auth?.authId : undefined;
    dispatch({ type: types.FETCH_SALONS });
    return fetchSalonsFromServer({ authId, ids })
      .then((salons: SalonPayload) => {
        const salonIds = Object.keys(salons.salonById).map((id) =>
          parseInt(id)
        );
        return fetchBroadcastListsBySalonIds(salonIds).then(
          (
            broadcastListsBySalonIds: Record<
              number,
              { broadcastList?: BroadcastList; error?: string }
            >
          ) => {
            Object.entries(broadcastListsBySalonIds).forEach(
              ([salonId, data]) => {
                if (data.broadcastList) {
                  salons.salonById[parseInt(salonId)].broadcastList =
                    data.broadcastList;
                } else {
                  console.error(data.error);
                }
              }
            );
            dispatch(fetchSuccess({ ...salons, selectedSalonId }));
          }
        );
      })
      .then(() => getState)
      .catch((err: string) => {
        const error = errorToString(err);
        dispatch(fetchFailure(error));
        dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
        return Promise.reject(error);
      });
  } else {
    return Promise.reject(
      "Erreur: Informations manquantes sur l'établissement"
    );
  }
};

export const updateSalon = (salonId: number, salonInfos: Salon) => (
  dispatch: Dispatch,
  getState: { (): AppState }
) => {
  dispatch({ type: types.UPDATE_SALON });
  return updateSalonById(salonId, salonInfos)
    .then((updatedSalonInfos) => {
      return dispatch({
        type: types.UPDATE_SALON_SUCCESS,
        payload: updatedSalonInfos,
      });
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch({ type: types.UPDATE_SALON_FAILURE, error });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const createHairdresser = (
  salonId: number,
  hairdresserInfos: {
    id: number;
    firstName: string;
    lastName: string;
    image?: string;
    color: string;
    packageIds: Array<number>;
    status: string;
    description: string;
    gender: string;
    partnerRefs: Array<PartnerRef>;
  }
) => (dispatch: Dispatch) => {
  dispatch({ type: types.CREATE_HAIRDRESSER });
  return createSalonHairdresser(salonId, hairdresserInfos)
    .then((createdHairdresserInfos) => {
      const createdHairdresser = { ...createdHairdresserInfos, salonId };
      dispatch({
        type: types.CREATE_HAIRDRESSER_SUCCESS,
        payload: createdHairdresser,
      });
      return createdHairdresser;
    })
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch({ type: types.CREATE_HAIRDRESSER_FAILURE, error });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const updateHairdresser = (
  salonId: number,
  hairdresserInfos: {
    id: number;
    firstName: string;
    lastName: string;
    // image: string;
    color: string;
    packageIds: Array<number>;
    status: string;
    description: string;
    gender: string;
    partnerRefs: Array<PartnerRef>;
  }
) => (dispatch: Dispatch, getState: { (): AppState }) => {
  dispatch({ type: types.UPDATE_HAIRDRESSER });
  return updateHairdresserById(hairdresserInfos.id, hairdresserInfos)
    .then((updatedHairdresserInfos) => {
      return dispatch({
        type: types.UPDATE_HAIRDRESSER_SUCCESS,
        payload: { ...updatedHairdresserInfos, salonId },
      });
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch({ type: types.UPDATE_HAIRDRESSER_FAILURE, error });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const updateBroadcastList = (broadcastListInfos: BroadcastList) => (
  dispatch: Dispatch,
  getState: { (): AppState }
) => {
  dispatch({ type: types.UPDATE_BROADCAST_LIST });
  return updateBroadcastListFromServer(broadcastListInfos)
    .then((updatedbroadcastListInfos) => {
      return dispatch({
        type: types.UPDATE_BROADCAST_LIST_SUCCESS,
        payload: updatedbroadcastListInfos,
      });
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch({ type: types.UPDATE_BROADCAST_LIST_FAILURE, error });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchInvoicesSuccess = (data: {
  salonId: number;
  invoices: Array<Invoice>;
}) => ({
  type: types.FETCH_INVOICES_SUCCESS,
  payload: data,
});

export const fetchInvoicesFailure = (error: string) => ({
  type: types.FETCH_INVOICES_FAILURE,
  error,
});

export const fetchInvoices = (salonId: number) => (
  dispatch: Dispatch,
  getState: { (): AppState }
): Promise<any> => {
  dispatch({ type: types.FETCH_INVOICES });
  return fetchInvoicesBySalonId(salonId)
    .then((invoices: Array<Invoice>) => {
      dispatch(fetchInvoicesSuccess({ salonId, invoices }));
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchInvoicesFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const updatePackage = (
  salonId: number,
  packId: number,
  packInfos: {
    name: string;
    description: string;
    prices: Array<Price>;
    allowPromo: boolean;
    category: {
      id: number;
    };
    status: string;
    partnerRefs: Array<PartnerRef>;
  },
  packHairdresserIds: {
    beforeUpdate: Array<number>;
    afterUpdate: Array<number>;
  }
) => (dispatch: Dispatch, getState: { (): AppState }): Promise<any> => {
  dispatch<PackageUpdateAction>({ type: types.UPDATE_PACKAGE });
  const salonHairdressers = getHairdressersById(getState().salon);
  return Promise.all([
    updatePackageById(packId, packInfos),
    updatePackageHairdressers(packId, packHairdresserIds, salonHairdressers),
  ])
    .then(([updatedPackage, updatedHairdressers]) => {
      return dispatch<PackageUpdateSuccessAction>({
        type: types.UPDATE_PACKAGE_SUCCESS,
        payload: {
          salonId,
          pack: updatedPackage,
          hairdressers: updatedHairdressers,
        },
      });
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch<PackageUpdateFailureAction>({
        type: types.UPDATE_PACKAGE_FAILURE,
        error,
      });
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

const updatePackageHairdressers = (
  packId: number,
  packHairdresserIds: {
    beforeUpdate: Array<number>;
    afterUpdate: Array<number>;
  },
  salonHairdressersInfos: { [id: number]: Hairdresser }
) => {
  const hairdresserIdsToRemove = packHairdresserIds.beforeUpdate.filter(
    (beforeId) => !packHairdresserIds.afterUpdate.includes(beforeId)
  );

  const hairdresserIdsToAdd = packHairdresserIds.afterUpdate.filter(
    (afterId) => !packHairdresserIds.beforeUpdate.includes(afterId)
  );

  const updatePromises: Array<Promise<Hairdresser>> = [];

  hairdresserIdsToRemove.forEach((hairdresserId) => {
    if (hairdresserId !== null) {
      const hairdresserPackageIds = salonHairdressersInfos[hairdresserId]
        ? salonHairdressersInfos[hairdresserId].packageIds
        : [];
      const packToRemoveIndex = hairdresserPackageIds.findIndex(
        (id) => id === packId
      );
      packToRemoveIndex > -1 &&
        updatePromises.push(
          updateHairdresserById(hairdresserId, {
            ...salonHairdressersInfos[hairdresserId],
            packageIds: [
              ...hairdresserPackageIds.slice(0, packToRemoveIndex),
              ...hairdresserPackageIds.slice(packToRemoveIndex + 1),
            ],
          })
        );
    }
  });

  hairdresserIdsToAdd.forEach((hairdresserId) => {
    if (hairdresserId !== null) {
      const hairdresserPackageIds = salonHairdressersInfos[hairdresserId]
        ? salonHairdressersInfos[hairdresserId].packageIds
        : [];
      const packToAddIndex = hairdresserPackageIds.findIndex(
        (id) => id === packId
      );
      packToAddIndex === -1 &&
        updatePromises.push(
          updateHairdresserById(hairdresserId, {
            ...salonHairdressersInfos[hairdresserId],
            packageIds: [...hairdresserPackageIds, packId],
          })
        );
    }
  });

  return Promise.all(updatePromises);
};

export const setPublic = () => (
  dispatch: Dispatch,
  getState: { (): AppState }
): Promise<any> => {
  dispatch({ type: types.SET_PUBLIC_SALON });
  const {
    salon: {
      payload: { selectedSalonId },
    },
  } = getState();

  if (!selectedSalonId) {
    console.debug("No Salon Id selected");
    return Promise.reject(dispatch({ type: types.SET_PUBLIC_SALON_FAILURE }));
  }

  return setSalonPublic(selectedSalonId)
    .then(() =>
      Promise.resolve(
        dispatch({
          type: types.SET_PUBLIC_SALON_SUCCESS,
          payload: { id: selectedSalonId },
        })
      )
    )
    .catch((error) =>
      Promise.resolve(
        dispatch({
          type: types.SET_PUBLIC_SALON_FAILURE,
          error,
        })
      )
    );
};

export const fetchWaitingApprovalRequests = (
  salonId: number,
  options: { type?: string }
) => async (dispatch: Dispatch, _getState: { (): AppState }) => {
  dispatch({ type: types.FETCH_APPROVAL_REQUESTS });
  return ApprovalRequestAPI.fetchBySalonId(salonId, options?.type)
    .then((approvalRequests) => {
      dispatch({
        type: types.FETCH_APPROVAL_REQUESTS_SUCCESS,
        payload: {
          ...options,
          salonId,
          approvalRequests,
        },
      });
    })
    .catch(() => {
      dispatch({ type: types.FETCH_APPROVAL_REQUESTS_FAILURE });
    });
};
