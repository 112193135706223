import moment from "moment";

const now = moment();
const endOfLockDown = moment({
  day: 11,
  month: 4, // 0 based so month 4 is May
  year: 2020,
});

export const earliestPossibleBookingCreation = moment.max(now, endOfLockDown);
