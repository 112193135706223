import React from "react";
import { MuiPickersUtilsProvider, DatePickerProps } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { capitalize } from "../../../utils/string";
import { defaultCalendarFormat } from "../../../constants/Input";
import classnames from "classnames";
import PickerArrow from "../../svgs/PickerArrow";
import { DARK } from "../../../constants/Style";
import styles from "./DatePicker.module.scss";

interface ICalendarDesktopDatePickerProps
  extends Omit<DatePickerProps, "onChange"> {
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  classes?: {
    container?: string;
    popover?: string;
  };
  noIcon?: boolean;
  calendarFormat?: moment.CalendarSpec;
}

const getLabelFunc = (calendarFormat: moment.CalendarSpec) => (
  date: moment.Moment | null
) => {
  return date ? capitalize(date.calendar(undefined, calendarFormat)) : "";
};

function DatePickerDesktop({
  value,
  name,
  onChange,
  classes,
  calendarFormat = defaultCalendarFormat,
  labelFunc = getLabelFunc(calendarFormat),
  InputProps,
  noIcon,
  disabled,
  ...otherProps
}: ICalendarDesktopDatePickerProps) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div
        className={classnames(styles.container, classes && classes.container)}
      >
        <DatePicker
          variant="inline"
          disableToolbar
          labelFunc={labelFunc}
          autoOk
          fullWidth
          PopoverProps={{
            classes: {
              paper: classnames(classes && classes.popover)
            }
          }}
          value={value}
          onChange={date =>
            date &&
            onChange({
              target: { value: date.toISOString(), name }
            } as React.ChangeEvent<HTMLInputElement>)
          }
          InputProps={{
            ...InputProps,
            className: "input_input",
            fullWidth: true
          }}
          InputLabelProps={{
            className: "input_label"
          }}
          disabled={disabled}
          {...otherProps}
        />
        {!noIcon && !disabled && (
          <PickerArrow className={styles.icon} large color={DARK} />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerDesktop;
