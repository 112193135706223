import React from "react";
import { ModalHeaderProps } from "./Header";
import CloseIcon from "../../svgs/CloseIcon";
import PreviousIcon from "../../svgs/PreviousIcon";
import LargeClickable from "../../uiElements/LargeClickable";
import { PARENT_LINKS } from "constants/Menu";
import { Link } from "react-router-dom";
import { isIFrame } from "utils/helpers";

export default function ModalHeaderMobile({
  closeIconVariant,
  onClose,
  title,
  parent,
}: ModalHeaderProps) {
  const CloseIconComponent =
    closeIconVariant === "previous" ? PreviousIcon : CloseIcon;
  return (
    <div className="modal_header modal_header-mobile">
      <LargeClickable onClick={onClose} className="modal_header_icon-mobile">
        <CloseIconComponent onClick={onClose} />
      </LargeClickable>
      <div className="modal_header_title-mobile">
        {isIFrame() && parent && PARENT_LINKS[parent] && (
          <Link style={{ color: "white" }} to={PARENT_LINKS[parent].link}>
            {PARENT_LINKS[parent].text} &gt;{" "}
          </Link>
        )}
        {title}
      </div>
    </div>
  );
}
