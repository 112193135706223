import React, { useRef } from "react";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import useClickAway from "react-use/lib/useClickAway";

/**
 * Fake Input
 * Used to replace <input> by <div>
 * and prevent iOs input behavior
 * @param props
 */
function FakeInput(props: InputBaseComponentProps) {
  const { value, className, onFocus, onBlur } = props;
  const inputRef = useRef(null);
  useClickAway(inputRef, onBlur as any);
  return (
    <div
      ref={inputRef}
      className={`fake-input ${className ? className : ""}`}
      tabIndex={-1}
      onClick={onFocus as any}
    >
      {value}
    </div>
  );
}

export default FakeInput;
