import * as Sentry from "@sentry/browser";

const isProduction = process.env.REACT_APP_ENV === "production";

export const initSentry = () => {
  if (isProduction) {
    Sentry.init({
      dsn: "https://599db4bdaae54de18023728c7999ab73@sentry.io/1843074",
      environment: process.env.REACT_APP_ENV,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded", // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
        /.+(Failed to fetch).+/g,
        /.+(NetworkError).+/g,
        /.+(pg: no rows).+/g
      ]
    });
  }
};

export const identifySentryUser = (user: Sentry.User) => {
  if (isProduction) {
    Sentry.configureScope(function(scope) {
      scope.setUser(user);
    });
  }
};

export const captureException = (error: any, extra?: any) => {
  Sentry.withScope(scope => {
    extra && scope.setExtras(extra);
    Sentry.captureException(error);
  });
};
