import React, { useState } from "react";
import TextField from "./Text";
import { TextFieldProps } from "@material-ui/core/TextField";
import IconVisibilityON from "@material-ui/icons/Visibility";
import IconVisibilityOFF from "@material-ui/icons/VisibilityOff";

function PasswordInput(props: TextFieldProps) {
  const [isVisible, setVisibility] = useState(false);
  return (
    <div className="input-password-container">
      <TextField
        {...props}
        autoComplete="current-password"
        type={isVisible ? "text" : "password"}
        inputProps={{
          style: {
            height: "1.1875em" // Fixes graphic bug of Mui when switching type
          }
        }}
      />
      <span
        onClick={() => setVisibility(!isVisible)}
        className="input-password_visibility-icon"
      >
        {isVisible ? <IconVisibilityOFF /> : <IconVisibilityON />}
      </span>
    </div>
  );
}

export default PasswordInput;
