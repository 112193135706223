import { Week, Availability } from "reducers/week";
import { query, mutation, safeError } from "utils/graphqlClient";

const weekFields = `
    id,
    hairdresserIds,
    hairdresserStringIds,
    salonId,
    discount,
    duration,
    createdAt,
    status,
    days {
        monday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        tuesday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        wednesday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        thursday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        friday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        saturday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
        sunday {
            start,
            end,
            unavailabilities {
                start,
                end
            }
        },
    }
`;

const availabilitiesFields = `
    hairdresserId,
    hairdresserStringId,
    range {
        start,
        end
    },
    discount,
`;

// This will only get the open hours
export const fetchBySalonId = async (salonId: number) => {
  const res = await query(
    `weeks(salonId: ${salonId}, sort: "id", limit: -1){${weekFields}}`
  );

  const body = await res.json();

  if (res.ok) {
    const weeksByHairdresserId = body?.data?.weeks.reduce(
      (acc: Record<number, Array<Week>>, week: Week) => {
        // We only need normal weeks by hairdresserId
        if (
          week.discount !== 0 ||
          week.status === "DELETED" ||
          (!week.hairdresserIds && !week.hairdresserStringIds)
        ) {
          return acc;
        }
        const hairdresserId =
          week.hairdresserStringIds && week.hairdresserStringIds.length > 0
            ? Number(week.hairdresserStringIds[0].split("|")[1])
            : week.hairdresserIds[0];
        return {
          ...acc,
          [hairdresserId]: week,
        };
      },
      {}
    );

    return {
      weeks:
        body?.data?.weeks.filter(
          (week: Week) =>
            week.discount > 0 &&
            week.status !== "DELETED" &&
            (week.hairdresserIds || week.hairdresserStringIds)
        ) || [],
      weeksByHairdresserId,
    };
  }

  return Promise.reject(safeError(body));
};

const getWeekInputHairdressers = (week: Week) => ({
  ...(week.hairdresserIds
    ? {
        hairdresserIds: {
          type: "[Int]",
          value: week.hairdresserIds,
        },
      }
    : {}),
  ...(week.hairdresserStringIds
    ? {
        hairdresserStringIds: {
          type: "[String]",
          value: week.hairdresserStringIds,
        },
      }
    : {}),
});

export const createWeek = async (week: Week) => {
  const res = await mutation(
    "createWeek",
    {
      ...getWeekInputHairdressers(week),
      salonId: {
        type: "Int",
        value: week.salonId,
      },
      discount: {
        type: "Int",
        value: week.discount,
      },
      duration: {
        type: "Int",
        value: week.duration,
      },
      status: {
        type: "String",
        value: week.status,
      },
      days: {
        type: "DaysInput",
        value: week.days,
      },
    },
    weekFields
  );
  const body = await res.json();

  if (res.ok) {
    return true;
  }

  return Promise.reject(safeError(body));
};

export const updateWeek = async (week: Week) => {
  const res = await mutation(
    "updateWeek",
    {
      id: {
        type: "Int",
        value: week.id,
      },
      ...getWeekInputHairdressers(week),
      salonId: {
        type: "Int",
        value: week.salonId,
      },
      discount: {
        type: "Int",
        value: week.discount,
      },
      duration: {
        type: "Int",
        value: week.duration,
      },
      status: {
        type: "String",
        value: week.status,
      },
      days: {
        type: "DaysInput",
        value: week.days,
      },
    },
    weekFields
  );
  const body = await res.json();

  if (res.ok) {
    return true;
  }

  return Promise.reject(safeError(body));
};

// This will only get the open hours
export const getSalonAvailabilities = async (salonId: number) => {
  const res = await query(
    `weekAvailabilities(salonId: ${salonId}){${availabilitiesFields}}`
  );

  const body = await res.json();
  if (res.ok) {
    const weeks = body?.data?.weekAvailabilities.reduce(
      (
        acc: Record<string, Array<Availability>>,
        availability: Availability
      ) => {
        return {
          ...acc,
          ...(availability.hairdresserId
            ? {
                [availability.hairdresserId]: [
                  ...(acc[availability.hairdresserId] || []),
                  availability,
                ],
              }
            : {}),
          ...(availability.hairdresserStringId != ""
            ? {
                [availability.hairdresserStringId]: [
                  ...(acc[availability.hairdresserStringId] || []),
                  {
                    ...availability,
                    hairdresserId: 0,
                  },
                ],
              }
            : {}),
        };
      },
      {}
    );

    return weeks;
  }

  return Promise.reject(safeError(body));
};
