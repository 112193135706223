import React from "react";
import MaterialSelect, {
  SelectProps as MaterialSelectProps
} from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import PickerArrow from "../../svgs/PickerArrow";
import { DARK } from "constants/Style";

export interface SelectProps extends MaterialSelectProps {
  label?: string;
  helperText?: string;
  labelProps?: {
    shrink?: boolean;
    className?: string;
  };
}

export default function Select(props: SelectProps) {
  const {
    fullWidth,
    label,
    children,
    error,
    helperText,
    disabled,
    labelProps = {},
    variant,
    ...otherProps
  } = props;
  const { shrink = true, className: labelClassName } = labelProps;
  return (
    <FormControl
      fullWidth={fullWidth}
      className="input-group"
      variant={variant}
    >
      {label && (
        <InputLabel shrink={shrink} className={labelClassName ?? "input_label"}>
          {label}
        </InputLabel>
      )}
      <MaterialSelect
        autoWidth
        className="input_input"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          }
        }}
        IconComponent={() => (
          <>{!disabled && <PickerArrow large color={DARK} />}</>
        )}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </MaterialSelect>
      {helperText && helperText.length > 0 && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
