import { Reducer } from "redux";
import moment from "moment";
import * as yup from "yup";

import {
  UnavailabilityActions,
  FetchUnavailabilitySuccessAction,
} from "actions/UnavailabilityActions";
import { ResetAction } from "actions/ResetAction";
import { AuthLogoutAction } from "actions/AuthActions";
import * as types from "constants/ActionTypes";
import { recurrenceSchema } from "reducers/slot";
import { getFromCache } from "utils/cache";

const UNAVAILABILITY_CACHE_KEY = "unavailability";

export const unavailabilitySchema = yup.object().shape({
  id: yup.number().required(),
  hairdresserId: yup.number().required(),
  hairdresserStringId: yup.string().notRequired(),
  recurrence: recurrenceSchema.nullable().notRequired(),
  description: yup.string().ensure(),
  range: yup
    .object()
    .shape({
      start: yup.string().required(),
      end: yup.string().required(),
    })
    .required(),
  partnerRef: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string().required(),
    })
    .required(),
});
export type Unavailability = yup.InferType<typeof unavailabilitySchema>;

export interface UnavailabilityPayload {
  unavailabilityById: Record<number, Unavailability>;
}

interface UnavailabilityState {
  loading: boolean;
  error: string | null;
  payload: UnavailabilityPayload;
}

const getUnavailabilityCache = () => {
  return getFromCache(UNAVAILABILITY_CACHE_KEY);
};

const deleteUnavailabilityInDateRange = (
  unavailabilityById: Record<number, Unavailability>,
  dateRange: {
    endGte: string;
    startLte: string;
  }
) => {
  const startLte = moment(dateRange.startLte);
  const endGte = moment(dateRange.endGte);
  return Object.values(unavailabilityById).reduce(
    (newUnavailibilityById, unavailability) => {
      if (
        moment(unavailability.range.start).isSameOrBefore(startLte) &&
        moment(unavailability.range.end).isSameOrAfter(endGte)
      ) {
        return newUnavailibilityById;
      }
      return {
        ...newUnavailibilityById,
        [unavailability.id]: unavailability,
      };
    },
    {}
  );
};

const getInitialState: { (): UnavailabilityState } = () => ({
  loading: false,
  error: null,
  payload: {
    unavailabilityById: getUnavailabilityCache().unavailabilityById ?? {},
  },
});

const fetchUnavailabilitySuccessReducer = (
  state: UnavailabilityState,
  action: FetchUnavailabilitySuccessAction
) => {
  const newUnavailibilityById = deleteUnavailabilityInDateRange(
    state.payload.unavailabilityById,
    action.payload.dateRange
  );

  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      unavailabilityById: {
        ...newUnavailibilityById,
        ...action.payload.unavailabilityById,
      },
    },
  };
};

const unavailability: Reducer<
  UnavailabilityState,
  UnavailabilityActions | ResetAction | AuthLogoutAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case types.FETCH_UNAVAILABILITIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_UNAVAILABILITIES_SUCCESS:
      const newState = fetchUnavailabilitySuccessReducer(state, action);
      localStorage.setItem(
        UNAVAILABILITY_CACHE_KEY,
        JSON.stringify(newState.payload)
      );
      return newState;
    case types.FETCH_UNAVAILABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem(UNAVAILABILITY_CACHE_KEY, "");
      return getInitialState();
    default:
      return state;
  }
};

export default unavailability;
