import React from "react";
import BookingItem from "../BookingItem/BookingItem";
import moment from "moment";
import { Booking } from "../../../reducers/booking";
import styles from "./BookingsByDate.module.scss";
import useResponsiveLayout from "../../ResponsiveLayout/useResponsiveLayout";

interface BookingsByDateProps {
  bookings: Array<Booking>;
  onBookingClick(booking: Booking): void;
}

function BookingsByDate({ bookings, onBookingClick }: BookingsByDateProps) {
  const bookingsByDate = bookings.reduce((listByDate, booking: Booking) => {
    const normalizedDate = moment(booking.dateStart)
      .startOf("day")
      .valueOf();
    listByDate[normalizedDate]
      ? listByDate[normalizedDate].push(booking)
      : (listByDate[normalizedDate] = [booking]);
    return listByDate;
  }, {} as { [dateStr: string]: Array<Booking> });
  const sortedDates = Object.keys(bookingsByDate).sort(
    (a, b) => parseInt(a) - parseInt(b)
  );
  return (
    <>
      {sortedDates.map(date => (
        <BookingsList
          key={date}
          date={moment(parseInt(date))}
          bookings={bookingsByDate[date]}
          onBookingClick={onBookingClick}
        />
      ))}
    </>
  );
}

interface BookingsListProps extends BookingsByDateProps {
  date: moment.Moment;
}

const sortBookingsByDate = (bookings: Array<Booking>) =>
  bookings.sort(
    (a, b) =>
      moment(a.dateStart).valueOf() - moment(b.dateStart).valueOf() ||
      moment(a.dateEnd).valueOf() - moment(b.dateEnd).valueOf()
  );

const dateNameFormatMobile = "ddd";
const dateNameFormatDesktop = "dddd D MMMM YYYY";
const dateNumFormat = "D";

function BookingsList({ date, bookings, onBookingClick }: BookingsListProps) {
  const { isMobile } = useResponsiveLayout();
  const bookingsSortedByDates = sortBookingsByDate(bookings);
  const dateNameFormat = isMobile
    ? dateNameFormatMobile
    : dateNameFormatDesktop;
  const dateName = date.format(dateNameFormat);
  const dateNum = date.format(dateNumFormat);
  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        <div className={styles.dateName}>{dateName}</div>
        {isMobile && <div className={styles.dateNum}>{dateNum}</div>}
      </div>
      <div className={styles.dateSlotsContainer}>
        {bookingsSortedByDates.map(booking => (
          <BookingItem
            key={booking.id}
            booking={booking}
            onClick={() => onBookingClick(booking)}
          />
        ))}
      </div>
    </div>
  );
}

export default BookingsByDate;
