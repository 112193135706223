import { query, safeError } from "utils/graphqlClient";

export const fetchCategories = async (): Promise<
  Array<{
    id: number;
    name: string;
  }>
> => {
  try {
    const res = await query(`categories(limit: -1, status: "SEARCHABLE"){
        id
        name
      }`);
    const body = await res.json();
    if (res.ok) {
      return Promise.resolve(body.data.categories);
    }
    return Promise.reject(safeError(body));
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const variantKeys = [
  "performance.variant.cheveuxCourts",
  "performance.variant.cheveuxMiLongs",
  "performance.variant.cheveuxLongs",
  "performance.variant.cheveuxTrèsLongs",
];

export const fetchVariants = async (): Promise<
  Array<{
    id: number;
    name: string;
    key: string;
  }>
> => {
  try {
    const res = await query(`variants(limit: -1){
      id
      name
      key
      sort
    }`);
    const body = await res.json();
    if (res.ok) {
      return Promise.resolve(
        body.data.variants
          .filter((variant: any) => variantKeys /*.includes(variant.key)*/)
          .sort((a: any, b: any) => a.sort - b.sort)
      );
    }
    return Promise.reject(safeError(body));
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getOpacityStyle = (isOneCatalog: boolean) =>
  isOneCatalog ? { opacity: 0.4 } : {};
