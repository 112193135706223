import React, { useEffect, useState } from "react";
import DesktopButton from "components/button/Desktop/Button.desktop";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import { Package } from "reducers/salon";
import { getIncreasePriceLogs } from "api/salon";

interface CovidAddPriceModalProps {
  isCovidModalOpened: boolean;
  setIsCovidModalOpened(isCovidModalOpened: boolean): void;
  showCovidValidation: boolean;
  setShowCovidValidation(showCovidValidation: boolean): void;
  getAuthInfos(): any;
  updateAllPackagesPrices(amount: number, author: string): Promise<boolean>;
  packages: Package[];
  salonId: number;
}

const loadPriceLogs = (
  setIncreasePriceLogs: (logs: any) => void,
  setLoading: (loading: boolean) => void,
  salonId: number
) => {
  setLoading(true);
  getIncreasePriceLogs(salonId).then((logs: any) => {
    setLoading(false);
    setIncreasePriceLogs(logs);
  });
};

export const CovidAddPriceModal = ({
  isCovidModalOpened,
  setIsCovidModalOpened,
  showCovidValidation,
  setShowCovidValidation,
  getAuthInfos,
  updateAllPackagesPrices,
  packages,
  salonId,
}: CovidAddPriceModalProps) => {
  // I preferred putting things here because this thing is temporary
  // Cleanup will be easier that way
  const [increasePriceLogs, setIncreasePriceLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [covidAmount, setCovidAmount] = useState("");

  useEffect(() => {
    loadPriceLogs(setIncreasePriceLogs, setLoading, salonId);
  }, []);

  const totalCovid = increasePriceLogs
    .reduce((acc, item: any) => acc + item.amount, 0)
    .toFixed(2);

  return (
    <ResponsiveModalContainer
      fullScreenOnMobile
      open={isCovidModalOpened}
      direction="left"
    >
      <div style={{ padding: "20px", textAlign: "center" }}>
        <div style={{ display: showCovidValidation ? "none" : "block" }}>
          <DesktopButton
            text="Fermer"
            onClick={() => setIsCovidModalOpened(false)}
          />
          <br />
          <div
            style={{ borderBottom: "1px solid black", paddingBottom: "20px" }}
          >
            {loading && "Chargement..."}
            {!loading && (
              <div style={{ textAlign: "center" }}>
                Supplément covid total actuel: {totalCovid}€
                <br />
                <br />
                <div style={{ display: "inline-block" }}>
                  <DesktopButton
                    primary
                    text="Remettre à zéro"
                    onClick={async () => {
                      const { username } = getAuthInfos();
                      setLoading(true);
                      try {
                        await updateAllPackagesPrices(-totalCovid, username);
                      } catch (err) {
                        console.log(err);
                      }
                      loadPriceLogs(setIncreasePriceLogs, setLoading, salonId);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <br />
          Augmenter le prix de toutes les prestations de l'établissement.
          <br />
          <br />
          Montant du supplément covid (en euros):{" "}
          <input
            type="number"
            value={covidAmount}
            min="0"
            step="1"
            onChange={(e) => setCovidAmount(e.target.value.replace("-", ""))}
          />
          <br />
          <br />
          <div style={{ display: "inline-block" }}>
            <DesktopButton
              primary
              text="Valider"
              onClick={async () => {
                const { username } = getAuthInfos();
                await updateAllPackagesPrices(
                  Number(covidAmount.replace(",", ".")),
                  username
                );
                setShowCovidValidation(true);
                loadPriceLogs(setIncreasePriceLogs, setLoading, salonId);
              }}
            />
          </div>
        </div>
        <div style={{ display: showCovidValidation ? "block" : "none" }}>
          <span style={{ fontFamily: "Montserrat-Bold" }}>
            {packages.length}
          </span>{" "}
          prestations ont été augmentés de{" "}
          <span style={{ fontFamily: "Montserrat-Bold" }}>{covidAmount}</span>{" "}
          euros !<h3>Ancien message Covid :</h3>
          <p>
            😷 Veuillez noter que certains de nos partenaires sont susceptibles
            de vous
            <br />
            demander le règlement en salon d’un supplément correspondant à
            l’achat et
            <br />
            l’utilisation de kits d’hygiène.
          </p>
          <h3>Nouveau message Covid :</h3>
          <p>
            😷 Cet établissement intègre dans son prix de vente les frais liés à
            l'achat et l'utilisation
            <br />
            de kits d'hygiène pour assurer votre protection (
            {Number(totalCovid)} €).
          </p>
          <br />
          <br />
          <div style={{ display: "inline-block" }}>
            <DesktopButton
              text="Fermer"
              onClick={() => {
                setIsCovidModalOpened(false);
                setShowCovidValidation(false);
              }}
            />
          </div>
        </div>
      </div>
    </ResponsiveModalContainer>
  );
};

export default CovidAddPriceModal;
