import React from "react";
import StarIcon from "../svgs/StarIcon";
import { SALMON, VERY_LIGHT_BLUE } from "../../constants/Style";

interface RatingStarsProps {
  rate: number;
}

function RatingStars(props: RatingStarsProps) {
  const { rate } = props;
  if (rate < 0 || rate > 5) {
    console.warn(
      `Out of range rating value of ${rate} passed to RatingStars component.\nValue must be between 0 and 5`
    );
  }
  const starColor = (starIndex: number): string => {
    return rate >= starIndex + 1 ? SALMON : VERY_LIGHT_BLUE;
  };
  return (
    <div className="rating-stars-group">
      {Array.from({ length: 5 }).map((_, index) => (
        <StarIcon key={index} color={starColor(index)} />
      ))}
    </div>
  );
}

export default RatingStars;
