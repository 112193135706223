import React from "react";
import { WHITE } from "../../constants/Style";

type WarningIconProps = {
  color?: string;
  className?: string;
};

const WarningIcon = ({ color = WHITE, ...props }: WarningIconProps) => {
  return (
    <svg width={4} height={13} {...props}>
      <path
        d="M.392.8h2.624v7.328H.392V.8zM.04 10.608c0-.224.043-.435.128-.632a1.66 1.66 0 0 1 .872-.872c.197-.085.413-.128.648-.128.235 0 .453.043.656.128s.381.203.536.352a1.597 1.597 0 0 1 .36 1.784 1.69 1.69 0 0 1-.896.888 1.673 1.673 0 0 1-.656.128 1.597 1.597 0 0 1-1.168-.488 1.76 1.76 0 0 1-.352-.528 1.575 1.575 0 0 1-.128-.632z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default WarningIcon;
