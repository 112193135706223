/**
 * Date
 */

export const defaultDisplayFormat = "DD/MM/YYYY";
export const defaultCalendarFormat = {
  lastDay: "[Hier]",
  sameDay: "[Aujourd'hui]",
  nextDay: "[Demain]",
  lastWeek: defaultDisplayFormat,
  nextWeek: defaultDisplayFormat,
  sameElse: defaultDisplayFormat
};
export const dateInputFormat = "YYYY-MM-DD";
export const defaultDisplayFormatWithFullDay = "dddd DD/MM/YYYY";
export const monthInputFormat = "YYYY-MM";

export const timePickerLocaleFr = {
  DatePickerLocale: {
    year: "",
    month: "",
    day: "",
    hour: "h",
    minute: "min"
  },
  dismissText: "Annuler",
  okText: "Valider",
  extra: ""
};
