import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";

import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { BLUE_GREY } from "constants/Style";
import CloseIcon from "components/svgs/CloseIcon";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import PlusIcon from "components/svgs/PlusIcon";
import { useImageManager, SalonPictureType } from "hooks/useImageManager";
import styles from "./SalonPicturesList.module.scss";

interface IPicture {
  filename: string;
  alt: string;
}

interface SalonPicturesListProps {
  title: string;
  type: SalonPictureType;
  pictures: IPicture[];
  single?: boolean;
}

function SalonPicturesList({
  title,
  type,
  pictures,
  single,
}: SalonPicturesListProps) {
  const { getSalonImageUrl, getSalonFileDeleter } = useImageManager();
  const deleteSalonFile = getSalonFileDeleter(type);
  return (
    <div className="input-group">
      <span className={styles.title}>{title}</span>
      <div className={styles.container}>
        {pictures.map((picture) => (
          <SalonPictureItem
            key={picture.filename}
            alt={picture.alt}
            url={getSalonImageUrl(picture.filename, type)}
            onDelete={() => deleteSalonFile(picture.filename)}
          />
        ))}
        <SalonPictureUpload
          type={type}
          displayPlus={!single || pictures.length === 0}
          multiple={!single}
        />
      </div>
    </div>
  );
}

interface SalonPictureItemProps {
  onDelete(): Promise<void>;
  url: string;
  alt: string;
}

function SalonPictureItem({ url, alt, onDelete }: SalonPictureItemProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useResponsiveLayout();
  const deleteIconHeight = isMobile ? 7 : 12;

  const deletePicture = () => {
    setIsLoading(true);
    onDelete();
  };
  const icon = isLoading ? (
    <div className={styles.loaderContainer}>
      <CircularProgress size={15} />
    </div>
  ) : (
    <CloseIcon
      onClick={deletePicture}
      height={deleteIconHeight}
      className={styles.deleteIcon}
    />
  );
  return (
    <div className={styles.imageContainer}>
      {icon}
      <img alt={alt} src={url} />
    </div>
  );
}

function SalonPictureUpload({
  type,
  multiple = true,
  displayPlus = true,
}: {
  type: SalonPictureType;
  multiple?: boolean;
  displayPlus?: Boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { getSalonFileUploader } = useImageManager();
  const uploadFiles = getSalonFileUploader(type);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { target } = event;
    const { files } = target;
    if (isLoading) {
      target.value = "";
      return;
    }
    if (files?.length && files.length > 5) {
      dispatch(
        pushMessage(
          "Vous ne pouvez envoyer que 5 fichiers à la fois.",
          MESSAGE_TYPES.ERROR
        )
      );
      target.value = "";
      return;
    }
    setIsLoading(true);
    uploadFiles(files).then(() => {
      target.value = "";
      setIsLoading(false);
    });
  };
  const inputId = `add-file-${type}`;
  const icon = isLoading ? (
    <CircularProgress size={15} />
  ) : (
    <PlusIcon color={BLUE_GREY} />
  );
  return (
    <div
      className={styles.imageContainer}
      style={{ display: displayPlus ? "block" : "none" }}
    >
      <label htmlFor={inputId}>{icon}</label>
      <input
        id={inputId}
        multiple={multiple}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChange}
      />
    </div>
  );
}

export default SalonPicturesList;
