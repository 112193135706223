import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { useSSO } from "./useSSO";
import useCurrentUser from "hooks/useCurrentUser";
import { AppLoading } from "components/AppLoading/AppLoading";

function RestoreSessionPage() {
  const { signin } = useSSO();
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useCurrentUser();

  signin()
    .catch(error => {
      logout();
      dispatch(
        pushMessage(
          "Nous avons rencontrés une erreur durant le chargement de votre compte, veuillez vous reconnecter",
          MESSAGE_TYPES.ERROR
        )
      );
      console.error(error);
    })
    .finally(() => {
      // Redirect to Homepage
      history.replace("/");
    });
  return <AppLoading />;
}

export { RestoreSessionPage };
