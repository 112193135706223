import React from "react";
import TextInput from "../../inputs/Text";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import ButtonFab from "../../button/Fab";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";
import MenuDesktop from "../../Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "../../layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import styles from "./SettingsPage.module.scss";
import ResponsiveModalContent from "../../modal/Content/ResponsiveContent";
import MobilePageContainer from "../../page/Container.mobile";
import { ACCOUNT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import { isIFrame } from "utils/helpers";
import PreviousIcon from "components/svgs/PreviousIcon";
import MobilePageHeader from "components/page/Header.mobile";
import LargeClickable from "components/uiElements/LargeClickable";
import IFrameContainer from "components/iframe/IFrameContainer";
import MultipleEmailForm from "./MultipleEmailForm";

interface AccountSettingsPageProps {
  formData: {
    connexionEmail: string;
    emailCopy: string;
    emailCsv: string;
    phone: string;
    mobile: string;
    hairdresserCopyEmails: Array<string>;
    invoiceCopyEmails: Array<string>;
    reportingCopyEmails: Array<string>;
  };
  updateFormData(event: React.ChangeEvent<HTMLInputElement>): void;
  addItem(name: string, item: any): void;
  removeItemByIndex(name: string, index: number): void;
  onClose(): void;
  onSubmit(): void;
  isSubmitting: boolean;
}

function AccountSettingsPage({
  formData,
  updateFormData,
  addItem,
  removeItemByIndex,
  onClose,
  onSubmit,
  isSubmitting,
}: AccountSettingsPageProps) {
  const submitButton = (
    <ButtonFab isLoading={isSubmitting} onClick={onSubmit}>
      Enregistrer
    </ButtonFab>
  );

  const content = (
    <ResponsiveModalContent
      desktopProps={{ classes: { container: styles.desktopContentContainer } }}
    >
      <Desktop>
        <div className={styles.header}>
          <span className={styles.title}>Paramètres du compte</span>
        </div>
      </Desktop>
      <TextInput
        disabled // cannot update auth username
        fullWidth
        name="connexionEmail"
        label="Email de connexion"
        value={formData.connexionEmail || ""}
        onChange={updateFormData}
      />
      <div className="input-group input-group-inline flex">
        <TextInput
          fullWidth
          name="phone"
          label="Téléphone"
          value={formData.phone || ""}
          onChange={updateFormData}
        />
        <TextInput
          fullWidth
          name="mobile"
          label="Mobile (alerte rdv par sms)"
          value={formData.mobile || ""}
          onChange={updateFormData}
        />
      </div>
      <TextInput
        fullWidth
        name="emailCopy"
        label="Email copie client"
        value={formData.emailCopy || ""}
        onChange={updateFormData}
      />
      <MultipleEmailForm
        title="Email professionnel (alerte rdv par email)"
        emails={formData.hairdresserCopyEmails}
        indexName="hairdresserCopyEmails"
        updateFormData={updateFormData}
        addItem={addItem}
        removeItemByIndex={removeItemByIndex}
      />
      <MultipleEmailForm
        title="Email copie facturation"
        emails={formData.invoiceCopyEmails}
        indexName="invoiceCopyEmails"
        updateFormData={updateFormData}
        addItem={addItem}
        removeItemByIndex={removeItemByIndex}
      />
      <MultipleEmailForm
        title="Email copie reporting"
        emails={formData.reportingCopyEmails}
        indexName="reportingCopyEmails"
        updateFormData={updateFormData}
        addItem={addItem}
        removeItemByIndex={removeItemByIndex}
      />
      <TextInput
        fullWidth
        name="emailCsv"
        label="Email envoi csv"
        value={formData.emailCsv || ""}
        onChange={updateFormData}
      />
      <Desktop>{submitButton}</Desktop>
    </ResponsiveModalContent>
  );

  return (
    <>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={
                  isIFrame() ? undefined : (
                    <LargeClickable onClick={onClose}>
                      <PreviousIcon />
                    </LargeClickable>
                  )
                }
                center="Paramêtres du compte"
              />
            }
            body={content}
            footer={submitButton}
          />
        </MobilePageContainer>
      </Mobile>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
          >
            <div className={styles.desktopContainer}>{content}</div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
    </>
  );
}

export default AccountSettingsPage;
