import React from "react";
import Menu from "../components/Menu/Mobile/Menu.mobile";
import { MENU_ITEMS_MOBILE } from "../constants/Menu";
import useCurrentUser from "../hooks/useCurrentUser";

function MenuContainerMobile() {
  const { logout } = useCurrentUser();
  return <Menu items={MENU_ITEMS_MOBILE} onLogout={logout} />;
}

export default MenuContainerMobile;
