import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loginSuccess } from "actions/AuthActions";
import useCurrentUser from "hooks/useCurrentUser";
import * as AuthAPI from "api/auth";
import { AuthInfos } from "reducers/auth";

const sessionStorageKey = "sso";

const useSSO = () => {
  const { isLoggedIn, logout, getAuthInfos } = useCurrentUser();
  const dispatch = useDispatch();
  const urlSsoParams = getUrlSSOparams();

  const sessionParams =
    urlSsoParams ??
    JSON.parse(sessionStorage.getItem(sessionStorageKey) ?? "{}");
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(sessionParams));

  const isValidSessionParams = AuthAPI.singleSignOnSchema.isValidSync(
    sessionParams
  );

  const flexySign = async () => {
    const res = await AuthAPI.flexySso();
    if (!res) {
      return;
    }
    AuthAPI.singleSignOnSchema.validateSync(res);
    AuthAPI.singleSignOnSchema.cast(res);

    logout(); // Make sure we logout with a clean state before logging in
    // Reset session storage because is it cleared on logout
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(res));
    dispatch(loginSuccess(res));
    return true;
  };

  const signin = async () => {
    if (!isValidSessionParams) {
      AuthAPI.singleSignOnSchema.validateSync(sessionParams);
      throw new Error("Session parameters are invalid");
    }
    AuthAPI.singleSignOnSchema.cast(sessionParams);
    if (isLoggedIn) {
      const authInfos = getAuthInfos();
      if (authInfos.token === sessionParams.token) {
        // The expected user is already logged in
        // no need to restore
        return;
      }
    }

    const authPayload = await AuthAPI.sso(sessionParams);
    logout(); // Make sure we logout with a clean state before logging in
    // Reset session storage because is it cleared on logout
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(sessionParams));
    dispatch(
      loginSuccess({ ...authPayload, username: sessionParams.username })
    );
    return true;
  };

  return {
    signin,
    flexySign,
    canRestoreSession: !isLoggedIn && isValidSessionParams,
  };
};

const getUrlSSOparams = (): AuthAPI.ISingleSignOn | void => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const username = urlParams.get("username");
  if (token && token.length > 0 && username && username.length > 0) {
    return {
      token,
      username,
    };
  }
};

export { useSSO };
