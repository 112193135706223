import React, { memo } from "react";
import { Week } from "reducers/week";
import { Hairdresser } from "reducers/salon";
import styles from "./HairdresserList.module.scss";
import Button from "../../button/Desktop/Button.desktop";
import PlusCircle from "components/svgs/PlusCircle";
import { hairdresser } from "components/rating/RatingList/RatingList.stories";
import { isHairdresserInWeek, matchHairdresserWeek } from "utils/week";

interface HairdresserListProps {
  index: number;
  week: Week;
  saveWeek(index: number, week: Week): void;
  setCurrentIndex(index: number): void;
  setHairdresserSelectorOpen(open: boolean): void;
  hairdressers: Array<Hairdresser>;
}

const HairdresserList = ({
  index,
  week,
  saveWeek,
  setCurrentIndex,
  setHairdresserSelectorOpen,
  hairdressers,
}: HairdresserListProps) => (
  <div>
    Professionnels concernés:
    <br />
    <PlusCircle
      className={styles.plusButton}
      onClick={() => {
        setHairdresserSelectorOpen(true);
        setCurrentIndex(index);
      }}
    />
    {hairdressers.map(
      (hairdresser) =>
        isHairdresserInWeek(week, hairdresser) && (
          <Button
            key={hairdresser.id || Number(hairdresser.stringId)}
            className={styles.hairdresserBtn}
            style={{
              backgroundColor: hairdresser?.color || "darkgrey",
              borderColor: hairdresser?.color || "darkgrey",
              color: hairdresser?.color ? "white" : "darkgrey",
            }}
            onClick={() =>
              saveWeek(index, {
                ...week,
                hairdresserIds: week.hairdresserIds.filter(
                  (id) => id !== hairdresser.id
                ),
                hairdresserStringIds: week.hairdresserStringIds.filter(
                  (stringId) => stringId.indexOf(hairdresser.stringId) === -1
                ),
              })
            }
            text={`${hairdresser?.firstName || ""} ${
              hairdresser?.lastName || ""
            } x`}
          />
        )
    )}
  </div>
);

export default memo(HairdresserList);
