import React, { useEffect, useRef, useCallback } from "react";
import { RATING_GREY, PINK } from "../../../constants/Style";
import {
  useWheelPickerContext,
  ITEM_HEIGHT,
  SELECTED_ITEM_FONT_SIZE,
  FONT_SIZE_UNIT,
  ITEMS_PADDING,
  FONT_SIZE_COEF,
  OPACITY_COEF,
  ITEM_HEIGHT_WHIT_UNIT,
  SELECTED_ITEM_FONT_SIZE_WITH_UNIT,
} from ".";
import useScroll from "react-use/lib/useScroll";
import { debounce } from "throttle-debounce";
import { useSpring, animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import { isIFrame } from "utils/helpers";

interface WheelProps {
  name: string;
  values: Array<string>;
  onChange?(value: string): void;
  renderValue?(value: string): string;
}

/**
 * WheelPicker.Wheel
 * Represent a scrollable selection Wheel of the WheelPicker
 * @param props
 */
function Wheel(props: WheelProps) {
  const {
    wheelsValue,
    onChange,
    registerWheel,
    isWheelsOpened,
  } = useWheelPickerContext();
  const { values, name, onChange: controlledOnChange, renderValue } = props;

  /**
   * Reference of the index of the currently selected value
   * It is a reference instead of a state to prevent unnecessary rerenders
   */
  const selectedIndexRef = useRef(
    values.findIndex((value) => value === wheelsValue[name])
  );

  /**
   * Reference of the Wheel container node
   * it is used to get the wheels scrolling position
   */
  const containerRef = useRef<HTMLDivElement>(null);
  const { y: scrollTop } = useScroll(containerRef);
  const [{ springScrollTop }, set] = useSpring(() => ({
    springScrollTop: 0,
    config: {
      clamp: true,
      friction: 1,
      tension: 250,
    },
  }));

  /**
   * Register the Wheel (called on first render only)
   */
  useEffect(() => {
    registerWheel && registerWheel(name, values);
  }, []);

  /**
   * Programmatically scroll to a value
   */
  const scrollTo = useCallback(
    (
      target:
        | { value: string; index?: undefined }
        | { index: number; value?: undefined },
      config: { immediate?: boolean; from?: number } = {}
    ) => {
      const { immediate = false, from } = config;
      const selectedIndex =
        target.index === undefined
          ? values.findIndex((value) => value === target.value)
          : target.index;
      const newScrollTop = Math.max(0, selectedIndex * ITEM_HEIGHT);
      set({
        springScrollTop: newScrollTop,
        immediate,
        ...(from ? { from: { springScrollTop: from }, reset: true } : {}),
      });
    },
    [values]
  );

  const bind = useGesture({
    onScrollEnd: ({ dragging, wheeling, touches, down }) => {
      if (dragging || wheeling || down || touches > 0) return;
      if (scrollTop % ITEM_HEIGHT > 2) {
        const index = Math.round(scrollTop / ITEM_HEIGHT);
        scrollTo({ index }, { from: scrollTop });
      }
    },
  });

  /**
   * On Wheels Opening => scroll to the current value
   */
  useEffect(() => {
    if (!isWheelsOpened) return;

    scrollTo({ value: wheelsValue[name] }, { immediate: true });
  }, [isWheelsOpened]);

  /**
   * Updates the selectedIndex on scrollTop change
   * and trigger the onChange functions when the selected value changes
   */
  useEffect(() => {
    if (!isWheelsOpened) return;

    const newSelectedIndex = Math.round(scrollTop / ITEM_HEIGHT);
    if (newSelectedIndex !== selectedIndexRef.current) {
      navigator.vibrate && navigator.vibrate(1);
      selectedIndexRef.current = newSelectedIndex;
    }
    // Triggers the onChange props
    // It is debounced to prevent excessive calls when scrolling fast
    const debounced = debounce(150, (selectedIndex) => {
      const selectedValue = values[selectedIndex];
      onChange && onChange({ [name]: selectedValue });
      controlledOnChange && controlledOnChange(selectedValue);
    });
    debounced(newSelectedIndex);
    return debounced.cancel;
  }, [
    scrollTop,
    selectedIndexRef,
    values,
    onChange,
    controlledOnChange,
    name,
    isWheelsOpened,
  ]);

  /**
   * Get the fontSize, opacity and color of the item at the specified index
   * the values are calculated according to the current scroll position
   * and the selectedIndexRef
   * @param index
   */
  const getMeta = (index: number) => {
    const distanceToSelected = index - ITEMS_PADDING - scrollTop / ITEM_HEIGHT;
    const absoluteDistanceToSelected = Math.abs(distanceToSelected);
    const fontSize = `${
      Math.max(0, 1 - absoluteDistanceToSelected * FONT_SIZE_COEF) *
      SELECTED_ITEM_FONT_SIZE
    }${FONT_SIZE_UNIT}`;
    const opacity = Math.max(0, 1 - absoluteDistanceToSelected * OPACITY_COEF);
    const pink = isIFrame() ? "#ffffff" : PINK;
    const color =
      selectedIndexRef.current === index - ITEMS_PADDING ? pink : RATING_GREY;
    return {
      color,
      fontSize,
      opacity,
    };
  };

  return (
    <div className="wheel-picker_wheel_outer_container">
      <div className="wheel-ghost-items-container">
        <GhostValues values={values} name={name} renderValue={renderValue} />
      </div>
      <animated.div
        className="wheel-picker_wheel_container"
        ref={containerRef}
        {...bind()}
        //@ts-ignore
        scrollTop={springScrollTop}
      >
        {withPadding(values).map((value, index) => (
          <div
            key={`${name}_${value || `null_${index}`}`}
            style={{
              height: ITEM_HEIGHT_WHIT_UNIT,
              ...getMeta(index),
            }}
            onClick={() => value && scrollTo({ value }, { from: scrollTop })}
          >
            {renderValue ? renderValue(value) : value}
          </div>
        ))}
      </animated.div>
    </div>
  );
}

interface IGhostValues {
  name: string;
  values: Array<string>;
  renderValue?(value: string): string;
}

/**
 * The GhostValues allows to set the container width
 * to the maximum width of the list otherwise the list width grows and shrink
 * depending on the current largest item
 */
function GhostValues(props: IGhostValues) {
  const { values, name, renderValue } = props;
  return (
    <React.Fragment>
      {withPadding(values).map((value, index) => (
        <div
          key={`ghost${name}_${value || `null_${index}`}`}
          style={{
            height: ITEM_HEIGHT_WHIT_UNIT,
            fontSize: SELECTED_ITEM_FONT_SIZE_WITH_UNIT,
          }}
        >
          {renderValue ? renderValue(value) : value}
        </div>
      ))}
    </React.Fragment>
  );
}

/**
 * Adds empty items in values list, so when scroll to top or bottom
 * the first or last element is at the correct position
 * @param values
 */
function withPadding(values: Array<string>): Array<string> {
  return [
    ...Array(ITEMS_PADDING).fill(null),
    ...values,
    ...Array(ITEMS_PADDING).fill(null),
  ];
}

/**
 * WheelPicker.Separator
 * Allow to add text between wheels
 * @param props
 */
const Separator: React.FC = (props) => {
  const { children } = props;
  return <div className="wheel-picker_separator">{children}</div>;
};

export { Wheel, Separator };
