import React from "react";
import moment from "moment";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { DataList } from "components/uiElements/DataList/DataList";
import { Booking } from "reducers/booking";
import { priceFormatter } from "utils/payments";
import { getBookingDiscountedPrice } from "utils/booking";
import styles from "./BookingForm.module.scss";

export interface IBookingFormDetailsProps {
  booking: Booking;
}

const valueUndefined = "Non renseigné";
const reductionPercentage = 0.5;

export function BookingFormDetails({ booking }: IBookingFormDetailsProps) {
  const phoneNumber = booking.user?.mobile ?? booking.user?.phone;
  const parsedPhoneNumber =
    phoneNumber && parsePhoneNumberFromString(phoneNumber, "FR");
  const phoneNumberString = parsedPhoneNumber
    ? parsedPhoneNumber.formatInternational()
    : valueUndefined;

  const fullPrice = priceFormatter.format(
    booking.snapshotPackages[0].price.price
  );
  const reducedPrice = priceFormatter.format(
    getBookingDiscountedPrice(booking)
  );
  const list = [
    {
      id: "date",
      label: "Date",
      value: moment(booking.dateStart).format("dddd DD MMMM YYYY"),
    },
    {
      id: "time",
      label: "Heure",
      value: `${moment(booking.dateStart).format("HH[h]mm")} - ${moment(
        booking.dateEnd
      ).format("HH[h]mm")}`,
    },
    {
      id: "reference",
      label: "Référence de validation",
      value: booking.id,
    },
    {
      id: "email",
      label: "Email client",
      value: booking.user?.username ?? valueUndefined,
    },
    {
      id: "phone",
      label: "Téléphone client",
      value: phoneNumberString,
    },
    {
      id: "price",
      label: "Prix de la prestation",
      value: `${fullPrice}€`,
    },
    {
      id: "total",
      label: "Total",
      value: (
        <div className={styles.detailsPriceContainer}>
          <span className={styles.detailsPriceFull}>{fullPrice}€</span>
          <span className={styles.detailsPriceReduced}>
            {reducedPrice}€ TTC
          </span>
        </div>
      ),
    },
    {
      id: "isNewClient",
      label: "Client",
      value: (
        <span>
          {booking.commissionConditions.isNewClient
            ? "Nouveau client"
            : "Client fidelisé"}
        </span>
      ),
    },
    {
      id: "paymentType",
      label: "Type de paiement",
      value: (
        <span>
          {booking.paymentType === "IN_SALON" ? "En salon" : "En ligne"}
        </span>
      ),
    },
  ];
  return (
    <>
      <span className={styles.detailsTitle}>Détails</span>
      <DataList list={list} />
    </>
  );
}
