import React from "react";
import styles from "./SidebarLinks.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import classnames from "classnames";
import useCurrentSalon from "hooks/useCurrentSalon";
import { SublinksConditions } from "constants/Menu";

export interface ISidebarLink {
  label: React.ReactNode;
  route: string;
  conditions?(conditions: SublinksConditions): boolean;
}

export interface ISidebarLinksProps {
  links: Array<ISidebarLink>;
  showSynchroSaas?: boolean;
}

function SidebarLinks({ links }: ISidebarLinksProps) {
  const { salon } = useCurrentSalon();
  const linksConditions = {
    isFlexySalon: !!salon?.partnerRefs?.find((ref) => ref.name === "flexy"),
    autoCalendar: salon.options?.autoCalendar || false,
  };
  const location = useLocation();
  const history = useHistory();
  const isSelected = (route: string) => location.pathname === route;
  const goToRoute = (route: string) => () => {
    if (!isSelected(route)) {
      history.push(route);
    }
  };
  return (
    <>
      {links
        .filter((link) => !link.conditions || link.conditions(linksConditions))
        .map((link) => (
          <div
            key={link.route}
            className={classnames(styles.link, {
              [styles.selected]: isSelected(link.route),
            })}
            onClick={goToRoute(link.route)}
          >
            {link.label}
          </div>
        ))}
    </>
  );
}

export default SidebarLinks;
