import React from "react";
import MaterialCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CheckIcon from "../svgs/Check";

function Check(props: CheckboxProps) {
  return (
    <MaterialCheckbox
      disableRipple
      icon={<span></span>}
      checkedIcon={<CheckIcon />}
      {...props}
    />
  );
}

export default Check;
