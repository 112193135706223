import React, { useRef, useEffect } from "react";
import moment from "moment";
import classnames from "classnames";

export interface CalendarRulerProps {
  timeSteps: Array<moment.Moment>;
  showResourceIcon?: boolean;
}

export default function CalendarRuler({
  timeSteps,
  showResourceIcon
}: CalendarRulerProps) {
  return (
    <div
      className={classnames("calendar_ruler-container", {
        "calendar_ruler-container--with-icon": showResourceIcon
      })}
    >
      {timeSteps.map(time => (
        <div key={time.valueOf()} className="calendar_ruler_item">
          {time.minutes() === 0 && (
            <span className="calendar_ruler_item-hour">
              {time.format("HH")}
            </span>
          )}
          <span className="calendar_ruler_item-minutes">
            {time.format("mm")}
          </span>
        </div>
      ))}
    </div>
  );
}
