import React from "react";
import * as Calendar from "../../../constants/Calendar";
import classnames from "classnames";
import "./mode-toggle.scss";

export interface IModeToggleProps {
  selectedMode: keyof typeof Calendar.VIEWS;
  selectMode(mode: keyof typeof Calendar.VIEWS): void;
}

const modeNames = {
  [Calendar.VIEWS.DAY]: "Jour",
  [Calendar.VIEWS.WEEK]: "Semaine"
};

function ModeToggle({ selectedMode, selectMode }: IModeToggleProps) {
  return (
    <div className="calendar-mode-toggle">
      {Object.entries(modeNames).map(([mode, modeName]) => (
        <div
          key={mode}
          className={classnames({ selected: selectedMode === mode })}
          onClick={() => selectMode(mode as keyof typeof Calendar.VIEWS)}
        >
          <span>{modeName}</span>
        </div>
      ))}
    </div>
  );
}

export default ModeToggle;
