export const publicRoutes = {
  LOGIN_PAGE: "LOGIN_PAGE",
  SIGNUP_PAGE: "SIGNUP_PAGE",
  RESTORE_SESSION: "RESTORE_SESSION",
};

export const privateRoutes = {
  TERM_AGREEMENT_PAGE: "TERM_AGREEMENT_PAGE",
  AGENDA_PAGE: "AGENDA_PAGE",
  RATING_PAGE: "RATING_PAGE",
  RATING_PAGE_HAIRDRESSERS: "RATING_PAGE_HAIRDRESSERS",
  RATING_PAGE_SALON: "RATING_PAGE_SALON",
  PAYMENT_PAGE: "PAYMENT_PAGE",
  PAYMENT_LIST_PAGE: "PAYMENT_LIST_PAGE",
  PAYMENT_TURNOVER_PAGE: "PAYMENT_TURNOVER_PAGE",
  PAYMENT_INVOICES_PAGE: "PAYMENT_INVOICES_PAGE",
  PAYMENT_INVOICE_PREVIEW_PAGE: "PAYMENT_INVOICE_PREVIEW_PAGE",
  PAYMENT_INFORMATIONS_PAGE: "PAYMENT_INFORMATIONS_PAGE",
  ACCOUNT_PAGE: "ACCOUNT_PAGE",
  ACCOUNT_SALON_PAGE: "ACCOUNT_SALON_PAGE",
  ACCOUNT_HAIRDRESSERS_PAGE: "ACCOUNT_HAIRDRESSERS_PAGE",
  ACCOUNT_SETTINGS_PAGE: "ACCOUNT_SETTINGS_PAGE",
  PACKAGES_PAGE: "PACKAGES_PAGE",
  BOOKINGS_PAGE: "BOOKINGS_PAGE",
  ACCOUNT_WEEKS_PAGE: "ACCOUNT_WEEKS_PAGE",
  ACCOUNT_HAPPY_HOURS_PAGE: "ACCOUNT_HAPPY_HOURS_PAGE",
  ACCOUNT_LAST_MINUTE_PAGE: "ACCOUNT_LAST_MINUTE_PAGE",
};

export const routeList: { [id: string]: string } = {
  ...publicRoutes,
  ...privateRoutes,
};

export const paramList = {
  RATING: {
    HAIRDRESSERS: "hairdressers",
    SALON: "salon",
  },
};

export const routes: any = {
  FR: {
    TERM_AGREEMENT_PAGE: "/terms",
    AGENDA_PAGE: "/agenda",
    LOGIN_PAGE: "/login",
    SIGNUP_PAGE: "/signup",
    RESTORE_SESSION: "/restoreSession",
    RATING_PAGE: `/rating/:tab(${paramList.RATING.HAIRDRESSERS}|${paramList.RATING.SALON})`,
    RATING_PAGE_HAIRDRESSERS: `/rating/${paramList.RATING.HAIRDRESSERS}`,
    RATING_PAGE_SALON: `/rating/${paramList.RATING.SALON}`,
    PAYMENT_PAGE: "/payment",
    PAYMENT_LIST_PAGE: "/payment/list",
    PAYMENT_TURNOVER_PAGE: "/payment/turnover",
    PAYMENT_INVOICES_PAGE: "/payment/invoices",
    PAYMENT_INVOICE_PREVIEW_PAGE: "/payment/invoices/:invoiceSlug",
    PAYMENT_INFORMATIONS_PAGE: "/payment/informations",
    ACCOUNT_PAGE: "/account",
    ACCOUNT_SALON_PAGE: "/account/salon",
    ACCOUNT_HAIRDRESSERS_PAGE: "/account/hairdressers",
    ACCOUNT_SETTINGS_PAGE: "/account/settings",
    PACKAGES_PAGE: "/packages",
    BOOKINGS_PAGE: "/bookings",
    ACCOUNT_WEEKS_PAGE: "/account/weeks",
    ACCOUNT_HAPPY_HOURS_PAGE: "/account/happy-hours",
    ACCOUNT_LAST_MINUTE_PAGE: "/account/last-minute",
  },
};
