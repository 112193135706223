import { Hairdresser } from "../reducers/salon";
import { GENDERS } from "../constants/Account";
import { IMAGES_CDN } from "../constants/Images";
import useCurrentSalon from "./useCurrentSalon";
import {
  uploadBackgrounds,
  uploadCarousel,
  deleteBackgrounds,
  deleteCarousel,
  uploadPhoto,
  uploadLogo,
  deleteLogo,
} from "api/salon";
import moment from "moment";
import useCurrentUser from "./useCurrentUser";

export type SalonPictureType = "background" | "carousel" | "logo";

const getHairdresserImageUrlOld = (hairdresser: Hairdresser): string => {
  const defaultImage =
    hairdresser.gender === GENDERS.M ? "avatar_homme.jpg" : "avatar_femme.jpg";
  return `${IMAGES_CDN}/hairdressers/${hairdresser.image || defaultImage}`;
};

const getSalonBackgroundImageUrlOld = (image: string): string => {
  const defaultImage = "background-salon.jpg";
  return `${IMAGES_CDN}/salons/background/${image || defaultImage}`;
};

const getSalonLogoImageUrlOld = (image: string): string => {
  const defaultImage = "logo-salon.jpg";
  return `${IMAGES_CDN}/salons/logo/${image || defaultImage}`;
};

const getSalonImageUrlOld = (image: string, type: SalonPictureType): string => {
  return `${IMAGES_CDN}/salons/${type}/${image}`;
};

const ASSETS_PREFIX = "LeCiseau_ASSETS_";
const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL;
const ASSETS_ENV = process.env.REACT_APP_ASSETS_ENV;

/**
 * All images in production now uses Assets
 * On staging images created with the ASSETS_PREFIX are on assets
 * older images are not migrated to Assets yet
 * Finally, on staging, we don't control the filename of hairdresser images
 * uploaded during createHairdresser request, but the filename returned from the server
 * doesn't include the extension so we can infer that filename without extension must be
 * on assets (because the feature of uploading hairdresser images during hairdresser creation
 * was available after assets)
 */
const acceptedExtensions = [".jpg", ".jpeg", ".png"];
const imageUsesAssetsURL = (filename: string) =>
  process.env.NODE_ENV === "production" ||
  filename.includes(ASSETS_PREFIX) ||
  acceptedExtensions.every((ext) => !filename.endsWith(ext));

const assetURLgetter = (id: string | number) => (filename: string) => {
  if (!ASSETS_BASE_URL || !ASSETS_ENV) {
    throw new Error(
      "Some env keys are missing : ASSETS_BASE_URL and ASSETS_ENV"
    );
  }
  return `${ASSETS_BASE_URL}/${ASSETS_ENV}/${id}/${filename}`;
};

const fileToBase64async = (file: File) => {
  const fileReader = new FileReader();

  const promise = new Promise<string>((resolve) => {
    fileReader.addEventListener(
      "load",
      function () {
        const base64File = fileReader.result;
        if (base64File && typeof base64File === "string") {
          resolve(base64File);
        }
      },
      false
    );
  });

  fileReader.readAsDataURL(file);

  return promise;
};

const removeBase64prefix = (base64: string) => {
  const split = base64.split(",");
  return split[1];
};

const splitExtension = (filename: string) => {
  const dotSplit = filename.split(".");
  const ext = dotSplit.length > 1 ? dotSplit[dotSplit.length - 1] : "";
  const name = dotSplit.slice(0, -1).join("_");
  return [name, ext];
};

const normalizeFilename = (filename: string) => {
  const [name, ext] = splitExtension(filename);
  return `${name
    .replace(/\s+/g, "_")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")}.${ext}`;
};

const randomId = () => Math.round(moment().valueOf() / 100);

export function useImageManager() {
  const { salon, refetchSalon } = useCurrentSalon();
  const { getAuthInfos } = useCurrentUser();
  const { authId, token } = getAuthInfos();
  const canUseAssestURL = (filename?: string) =>
    Boolean(authId && token && token.length > 0) &&
    filename &&
    imageUsesAssetsURL(filename);

  const salonId = salon.id;
  const getAssetURL = assetURLgetter(salonId);

  const getHairdresserImageUrl = (hairdresser: Hairdresser): string => {
    if (hairdresser.imageUrl && hairdresser.imageUrl !== "") {
      return hairdresser.imageUrl;
    }
    if (canUseAssestURL(hairdresser.image)) {
      return getAssetURL(hairdresser.image as string);
    }
    return getHairdresserImageUrlOld(hairdresser);
  };

  const getSalonBackgroundImageUrl = (image: string): string => {
    if (canUseAssestURL(image)) {
      return getAssetURL(image);
    }
    return getSalonBackgroundImageUrlOld(image);
  };

  const getSalonLogoImageUrl = (image: string): string => {
    if (canUseAssestURL(image)) {
      return getAssetURL(image);
    }
    return getSalonLogoImageUrlOld(image);
  };

  const getSalonImageUrl = (image: string, type: SalonPictureType): string => {
    if (canUseAssestURL(image)) {
      return getAssetURL(image);
    }
    return getSalonImageUrlOld(image, type);
  };

  const getSalonFileUploader = (type: SalonPictureType) => {
    const fileUploader = async (files: FileList | null) => {
      if (files) {
        let apiCalls = null;
        switch (type) {
          case "background":
            apiCalls = [...files].map(async (file) => {
              const fileName = `${ASSETS_PREFIX}_background__${randomId()}_${normalizeFilename(
                file.name
              )}`;
              const base64file = removeBase64prefix(
                await fileToBase64async(file)
              );
              await uploadBackgrounds(salonId, fileName, base64file);
            });
            break;
          case "carousel":
            apiCalls = [...files].map(async (file) => {
              const fileName = `${ASSETS_PREFIX}_carousel__${randomId()}_${normalizeFilename(
                file.name
              )}`;
              const base64file = removeBase64prefix(
                await fileToBase64async(file)
              );
              await uploadCarousel(salonId, fileName, base64file);
            });
            break;
          case "logo":
            apiCalls = [...files].map(async (file) => {
              const fileName = `${ASSETS_PREFIX}_logo__${randomId()}_${normalizeFilename(
                file.name
              )}`;
              const base64file = removeBase64prefix(
                await fileToBase64async(file)
              );
              await uploadLogo(salonId, fileName, base64file);
            });
            break;
        }
        if (apiCalls) {
          await Promise.all(apiCalls).finally(async () => {
            await refetchSalon();
          });
        }
      }
    };
    return fileUploader;
  };

  const hairdresserFileUploader = async (
    hairdresserId: number,
    files: FileList | null
  ) => {
    if (files) {
      const file = files[0];
      const fileName = `${ASSETS_PREFIX}_hairdresser_image_${hairdresserId}_${randomId()}_${normalizeFilename(
        file.name
      )}`;
      const base64file = removeBase64prefix(await fileToBase64async(file));
      const result = await uploadPhoto(
        hairdresserId,
        salonId,
        fileName,
        base64file
      );
      await refetchSalon();
      return result;
    }
  };

  const getSalonFileDeleter = (type: SalonPictureType) => {
    const fileDeleter = async (filename: string) => {
      switch (type) {
        case "background":
          await deleteBackgrounds(salonId, filename);
          break;
        case "carousel":
          await deleteCarousel(salonId, filename);
          break;
        case "logo":
          await deleteLogo(salonId);
          break;
      }
      await refetchSalon();
    };
    return fileDeleter;
  };

  return {
    getHairdresserImageUrl,
    getSalonBackgroundImageUrl,
    getSalonLogoImageUrl,
    getSalonImageUrl,
    getAssetURL,
    getSalonFileUploader,
    getSalonFileDeleter,
    hairdresserFileUploader,
  };
}
