import { mutation, safeError } from "utils/graphqlClient";
import { Package, Price } from "reducers/salon";
import { packageFields } from "utils/salon";

export const createPackagesApprovalRequest = async (
  salonId: number,
  files: { name: string; data: string }[],
  packages: Partial<Package>[]
) => {
  const res = await mutation(
    "createApprovalRequest",
    {
      salonId: {
        type: "Int",
        value: salonId,
      },
      type: {
        type: "String",
        value: "PACKAGES",
      },
      status: {
        type: "String",
        value: "WAITING",
      },
      filesInput: {
        type: "[FileInput]",
        value: files,
      },
      payload: {
        type: "[PayloadInput]",
        value: packages.map((pack) => ({
          variables: pack,
          status: "WAITING",
          type: "PACKAGE",
        })),
      },
    },
    "id, type, status"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.createApprovalRequest)
    : Promise.reject(safeError(body));
};

export const updatePackageStatus = async (
  packageId: number,
  status: string
) => {
  const res = await mutation(
    "updatePackage",
    {
      id: {
        type: "Int",
        value: packageId,
      },
      status: {
        type: "String",
        value: status,
      },
    },
    "id, status"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updatePackage)
    : Promise.reject(safeError(body));
};

export const updatePackageAllowPromo = async (
  packageId: number,
  allowPromo: boolean
) => {
  const res = await mutation(
    "updatePackage",
    {
      id: {
        type: "Int",
        value: packageId,
      },
      allowPromo: {
        type: "Boolean",
        value: allowPromo,
      },
    },
    "id, allowPromo"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updatePackage)
    : Promise.reject(safeError(body));
};

export const createPackage = async (pack: Package) => {
  try {
    const res = await mutation(
      "createPackage",
      {
        name: {
          type: "String",
          value: pack.name,
        },
        description: {
          type: "String",
          value: pack.description,
        },
        status: {
          type: "String",
          value: pack.status ?? "ENABLED",
        },
        prices: {
          type: "[PriceInput]",
          value: pack.prices.map((price) => ({
            ...price,
            price: parseFloat(price.price as any),
            variant: price?.variant && {
              id: price?.variant?.id,
              key: price?.variant?.key,
              name: price?.variant?.name,
            },
          })),
        },
        allowPromo: {
          type: "Boolean",
          value: pack.allowPromo,
        },
        mainCategoryId: {
          type: "Int",
          value: pack.category.id,
        },
      },
      packageFields
    );
    const body = await res.json();
    return res.ok && body.data.createPackage
      ? Promise.resolve(body.data.createPackage)
      : Promise.reject(safeError(body));
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const updatePackagePricesAndDesc = async (
  id: number,
  prices: Price[],
  description: string
) => {
  const res = await mutation(
    "updatePackage",
    {
      id: {
        type: "Int",
        value: id,
      },
      description: {
        type: "String",
        value: description,
      },
      prices: {
        type: "[PriceInput]",
        value: prices,
      },
    },
    "id"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updatePackage)
    : Promise.reject(safeError(body));
};
