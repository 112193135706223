import React from "react";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";

const IFrameContainer = ({ children }: { children: React.ReactNode }) => (
  <div
    className={classnames("container-no-iframe", {
      "container-iframe-no-toolbar": isIFrame(),
    })}
  >
    {children}
  </div>
);

export default IFrameContainer;
