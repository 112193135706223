import React, { useState, useEffect } from "react";

import * as APIRatings from "api/ratings";
import SearchRatingPage from "components/rating/SearchRatingPage/SearchRatingPage";
import ModalContainer from "components/modal/Container/Container.mobile_old";
import Mobile from "components/ResponsiveLayout/Mobile";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Rating } from "reducers/rating";
import RatingReplyFormContainer from "./RatingReplyForm";

interface SearchRatingPageContainerProps {
  onClose(): void;
}

export type Event = React.ChangeEvent<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
>;

function SearchRatingPageContainer({
  onClose
}: SearchRatingPageContainerProps) {
  const [query, setQuery] = useState("");
  const [filteredRatings, setFilteredRatings] = useState<Rating[]>([]);

  const { salon } = useCurrentSalon();
  useEffect(() => {
    async function updateFilteredRatings(query: string) {
      const ratings = await APIRatings.searchSalonRatingsByUsernameQuery(
        salon.id,
        query
      );
      setFilteredRatings(ratings as Rating[]);
    }

    updateFilteredRatings(query);
  }, [query, salon.id]);

  const [ratingToReply, setRatingToReply] = useState<Rating | null>(null);
  const [isReplying, setIsReplying] = useState(false);
  return (
    <React.Fragment>
      <SearchRatingPage
        query={query}
        onChange={(event: Event) => setQuery(event.target.value)}
        ratings={filteredRatings}
        onClose={onClose}
        openReplyModal={(rating: Rating) => {
          setRatingToReply(rating);
          setIsReplying(true);
        }}
      />
      <Mobile>
        <ModalContainer open={isReplying} direction="left">
          <React.Fragment>
            {ratingToReply && ratingToReply.type && (
              <RatingReplyFormContainer
                type={ratingToReply.type}
                rating={ratingToReply}
                onClose={() => setIsReplying(false)}
                onReplySuccess={onClose}
              />
            )}
          </React.Fragment>
        </ModalContainer>
      </Mobile>
    </React.Fragment>
  );
}

export default SearchRatingPageContainer;
