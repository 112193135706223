import React, { useState, useEffect } from "react";
import ModalHeader from "../../modal/Header/Header";
import ResponsiveModalContent from "../../modal/Content/ResponsiveContent";
import ModalFooter from "../../modal/Footer/Footer";
import ButtonFab from "../../button/Fab";
import MobileSelect from "../../inputs/Select/Mobile";
import WarningBox from "../../uiElements/WarningBox";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";
import ModalLayoutDesktop from "../../modal/Layout/Layout.desktop";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styles from "./BookingCancellationForm.module.scss";
import TextInput from "../../inputs/Text";

interface BookingCancellationFormProps {
  reasonsList: Array<string>;
  formValues: {
    cancellationReason: string;
  };
  onChange(event: React.ChangeEvent<any>): void;
  onClose(): void;
  onSubmit(): Promise<any>;
}

const title = "Annuler le rendez-vous";
const selectLabel = "Choisir un motif";
const buttonText = "Rembourser le client";

function BookingCancellationForm(props: BookingCancellationFormProps) {
  const { reasonsList, formValues, onChange, onClose, onSubmit } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const submit = () => {
    if (formValues.cancellationReason.length > 0) {
      setIsLoading(true);
      onSubmit().catch(() => setIsLoading(false));
    } else {
      setError("Veuillez choisir un motif");
    }
  };

  useEffect(() => {
    if (formValues.cancellationReason.length > 0) {
      setError(undefined);
    }
  }, [formValues.cancellationReason]);
  return (
    <React.Fragment>
      <Mobile>
        <ModalHeader
          onClose={onClose}
          closeIconVariant="previous"
          title={title}
        />
        <ResponsiveModalContent extended>
          <form onSubmit={submit}>
            <MobileSelect
              fullWidth
              label={selectLabel}
              name="cancellationReason"
              value={formValues.cancellationReason}
              listTitle="Choisir un motif d'annulation"
              list={reasonsList.map(reason => ({
                value: reason,
                display: reason
              }))}
              onChange={onChange}
              allowUserInput
              error={!!error}
              helperText={error}
            />
            <div className="input-group">
              <WarningBox text="Le client sera averti de l'annulation par sms et sera remboursé." />
            </div>
          </form>
        </ResponsiveModalContent>
        <ModalFooter>
          <ButtonFab warning onClick={submit} isLoading={isLoading}>
            {buttonText}
          </ButtonFab>
        </ModalFooter>
      </Mobile>
      <Desktop>
        <ModalLayoutDesktop
          header={title}
          onClose={onClose}
          body={
            <Grid
              container
              direction="column"
              justify="space-between"
              className={styles.desktopContainer}
            >
              <Box>
                <TextInput
                  fullWidth
                  label={selectLabel}
                  name="cancellationReason"
                  onChange={onChange}
                  inputProps={{
                    list: "reasons"
                  }}
                  error={!!error}
                  helperText={error}
                />
                <datalist id="reasons">
                  {reasonsList.map(reason => (
                    <option key={reason} value={reason} />
                  ))}
                </datalist>
                <div className="input-group">
                  <WarningBox text="Le client sera averti de l'annulation par sms et sera remboursé." />
                </div>
              </Box>
              <Box>
                <ButtonFab warning onClick={submit} isLoading={isLoading}>
                  {buttonText}
                </ButtonFab>
              </Box>
            </Grid>
          }
        />
      </Desktop>
    </React.Fragment>
  );
}

export default BookingCancellationForm;
