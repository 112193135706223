import { getFromCache } from "./cache";
import {
  query,
  safeError,
  callWithPagination,
  objToQueryArgs,
} from "./graphqlClient";
import { PAYOUT_LIMIT, TURNOVER_PERIOD_TYPES } from "../constants/Payment";
import { Booking } from "../reducers/booking";
import moment from "moment";
import { Payout } from "../reducers/payments";
import { getBookingDiscountedPrice } from "./booking";
import { DEFAULT_BOOKING_DISCOUNT } from "constants/Booking";

const getPaymentsCache = () => {
  return getFromCache("payments");
};

const paidBookingFields = `
id,
status,
createdAt,
discount,
commissionAmount,
commissionConditions{isNewClient},
dateStart,
dateEnd,
user{
    id,
    firstName,
    lastName,
    gender,
    phone,
    mobile,
    username
},
snapshotPackages{
    id,
    status,
    name,
    description,
    hairdresser{
      id,
      stringId,
    }
    category{
        id,
        name
    },
    price{
        price,
        currency,
        variant{
            id,
            name
        }
    }
}
`.replace(/\s+/g, "");

const fetchPaidBookings = callWithPagination(
  (salonId: number, range: { start: string; end: string }) => async (
    offset: number,
    limit: number
  ) => {
    const args = objToQueryArgs({
      salonId,
      status: "PAID",
      dateMin: range.start,
      dateMax: range.end,
      limit,
      offset,
    });
    const res = await query(`bookings(${args}){${paidBookingFields}}`);
    const body = await res.json();
    return res.ok
      ? Promise.resolve(body.data.bookings)
      : Promise.reject(safeError(body));
  },
  undefined,
  50
);

export const payoutFields = `
  id,
  amount,
  status,
  arrival_date,
  created
`.replace(/\s+/g, "");

const fetchPayouts = async (salonId: number, lastId?: string) => {
  const res = await query(
    `payouts(salonId: ${salonId}, limit: ${PAYOUT_LIMIT}${
      (lastId && `, lastId: "${lastId}"`) || ""
    }){${payoutFields}}
        `.replace(/\s+/g, "")
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve((body.data.payouts || []).map(grapQlToPayout))
    : Promise.reject(safeError(body));
};

const grapQlToPayout = (grapQlPayout: any): Payout => {
  return {
    id: grapQlPayout.id,
    amount: grapQlPayout.amount,
    status: grapQlPayout.status,
    arrivalDate: grapQlPayout.arrival_date,
    createdAt: grapQlPayout.created,
    bookings: grapQlPayout.bookings,
  };
};

const bookingToPrestation = (
  booking: Booking,
  hairdresserId?: number
): Prestation => {
  const { user = { firstName: "", lastName: "" } } = booking;
  return {
    id: booking.id,
    clientName:
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : "Régularisation de la commission",
    prestationName: `${booking.snapshotPackages[0].name}`,
    price: getBookingDiscountedPrice(booking),
    date: moment(booking.dateStart).valueOf(),
    hairdresserId: hairdresserId || booking.snapshotPackages[0].hairdresser.id,
    booking: {
      ...booking,
      discount: booking.discount ?? DEFAULT_BOOKING_DISCOUNT,
    },
  };
};

function isBookingInRange(filters: TurnoverFilters, booking: Booking): boolean {
  switch (filters.period.type) {
    case TURNOVER_PERIOD_TYPES.SINGLE:
      return (
        moment().year() === moment(booking.dateStart).year() &&
        filters.period.month === moment(booking.dateStart).month()
      );
    case TURNOVER_PERIOD_TYPES.RANGE:
      return (
        moment(filters.period.range.from).isSameOrBefore(
          moment(booking.dateStart)
        ) &&
        moment(filters.period.range.to).isSameOrAfter(moment(booking.dateStart))
      );
    default:
      return false;
  }
}

const getPrestations = (
  allHairdressersIds: Array<number>,
  bookingByHairdresserId: { [id: string]: { [id: number]: Booking } },
  filters?: TurnoverFilters
): Array<Prestation> => {
  let allHairdressersIdsHash: { [id: string]: boolean } = {};
  if (!filters || filters.hairdresser === "ALL") {
    allHairdressersIds.forEach((id) => {
      allHairdressersIdsHash[id] = true;
    });
  }
  return Object.entries(bookingByHairdresserId).reduce(
    (prestations: Array<Prestation>, [hairdresserId, bookings]) => {
      if (
        ((!filters || filters.hairdresser === "ALL") &&
          allHairdressersIdsHash[hairdresserId]) ||
        (filters && parseInt(hairdresserId) === filters.hairdresser)
      ) {
        return [
          ...prestations,
          ...Object.values(bookings)
            .filter((booking) =>
              filters ? isBookingInRange(filters, booking) : true
            )
            .map((booking) =>
              bookingToPrestation(booking, parseInt(hairdresserId))
            ),
        ];
      }
      return prestations;
    },
    []
  );
};

const formatMonth = (month: number): string => {
  return moment().month(month).format("MMMM");
};

const formatDay = (day: number): string => {
  return moment(day).format("D MMMM YYYY");
};

const periodToString = (
  period: {
    type: string;
    month?: number;
    range?: {
      from: number;
      to: number;
    };
  },
  forceType?: string
): string => {
  if ((forceType || period.type) === TURNOVER_PERIOD_TYPES.SINGLE) {
    return period.month ? formatMonth(period.month) : "";
  } else if ((forceType || period.type) === TURNOVER_PERIOD_TYPES.RANGE) {
    return period.range && period.range.from && period.range.to
      ? `du ${formatDay(period.range.from)} au ${formatDay(period.range.to)}`
      : "";
  }
  return "";
};

const priceFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export type Prestation = {
  id: number;
  clientName: string;
  prestationName: string;
  price: number;
  date: number;
  hairdresserId: number;
  booking: Booking;
};

export type Period = {
  type: string;
  month: number;
  range: {
    from: number;
    to: number;
  };
};

export type TurnoverFilters = {
  hairdresser: string | number;
  period: Period;
};

const leCiseauCommissionPercentage = 0.2;
// TODO: remove that ugly thing with the commission update
const leCiseauMay2020CommissionPercentage = 0.1;
const getCommissionPercentage = (booking: Booking) => {
  const createdAt = moment(booking.createdAt);
  if (createdAt.format("M") === "5" && createdAt.format("YYYY") === "2020") {
    return leCiseauMay2020CommissionPercentage;
  }
  return leCiseauCommissionPercentage;
};

const getIsOldBooking = (booking: Booking) => {
  const createdAt = moment(booking.createdAt);
  if (
    (Number(createdAt.format("M")) <= 5 &&
      createdAt.format("YYYY") === "2020") ||
    Number(createdAt.format("YYYY")) < 2020
  ) {
    return true;
  }

  return false;
};

const getPriceCommission = (prestation: Prestation) => {
  if (!prestation || !prestation.booking) {
    return 0;
  }
  if (getIsOldBooking(prestation.booking)) {
    return prestation.price * (1 - getCommissionPercentage(prestation.booking));
  }

  return prestation.price - (prestation.booking.commissionAmount || 0) / 100;
};

const addCurrency = (price: string): string => `${price}€`;

export {
  getPaymentsCache,
  fetchPaidBookings,
  fetchPayouts,
  grapQlToPayout,
  bookingToPrestation,
  getPrestations,
  periodToString,
  priceFormatter,
  getCommissionPercentage,
  getIsOldBooking,
  getPriceCommission,
  addCurrency,
};
