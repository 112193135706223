import React, { useEffect, memo } from "react";
import useCurrentSalon from "hooks/useCurrentSalon";
import LastMinutePage from "components/account/LastMinute/LastMinutePage";
import { useHistory } from "react-router-dom";

const AccountLastMinutePage = () => {
  const { salon, lastMinutes, fetchCurrentLastMinutes } = useCurrentSalon();

  useEffect(() => {
    fetchCurrentLastMinutes();
  }, []);

  const history = useHistory();

  return (
    <LastMinutePage
      onClose={() => history.goBack()}
      salon={salon}
      lastMinutes={lastMinutes}
      fetchCurrentLastMinutes={fetchCurrentLastMinutes}
    />
  );
};

export default memo(AccountLastMinutePage);
