import React, { ReactNode } from "react";
import classnames from "classnames";

interface ModalLayoutMobileProps {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  plainBackground?: boolean;
  refs?: {
    header?: React.MutableRefObject<any>;
    body?: React.MutableRefObject<any>;
    footer?: React.MutableRefObject<any>;
  };
}

function ModalLayoutMobile(props: ModalLayoutMobileProps) {
  const { header, body, footer, plainBackground, refs } = props;
  const bodyWrapperClassNames = classnames("modal-layout-body_wrapper-mobile", {
    plainBackground
  });
  const bodyContentClassNames = classnames("modal-layout-body_content-mobile", {
    dense: plainBackground
  });
  return (
    <div className="modal-layout-wrapper-mobile">
      <div
        className="modal-layout-head_wrapper-mobile"
        ref={refs && refs.header}
      >
        {header}
      </div>
      <div className={bodyWrapperClassNames} ref={refs && refs.body}>
        <div className={bodyContentClassNames}>{body}</div>
        <div
          className="modal-layout-footer_wrapper-mobile"
          ref={refs && refs.footer}
        >
          {footer}
        </div>
      </div>
    </div>
  );
}

export default ModalLayoutMobile;
