import { Dispatch } from "redux";

import * as types from "constants/ActionTypes";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { LOGIN_ERRORS } from "constants/Login";
import { AuthPayload, IAuthLogin, AuthInfos } from "reducers/auth";
import { classicLogin, AuthLogin } from "utils/auth";
import { errorToString } from "utils/string";
import { pushMessage } from "./SnackbarActions";

interface AuthClassicFormloginAction {
  type: typeof types.CLASSIC_LOGIN;
}

interface AuthLoginSuccessAction {
  type: typeof types.LOGIN_SUCCESS;
  payload: AuthPayload;
}

interface AuthLoginFailureAction {
  type: typeof types.LOGIN_FAILURE;
  error: string;
}

export interface AuthLogoutAction {
  type: typeof types.LOGOUT;
}

export type AuthActions =
  | AuthClassicFormloginAction
  | AuthLoginSuccessAction
  | AuthLoginFailureAction
  | AuthLogoutAction;

export const loginSuccess = (data: AuthInfos) => ({
  type: types.LOGIN_SUCCESS,
  payload: data
});

export const loginFailure = (err: string) => ({
  type: types.LOGIN_FAILURE,
  error: err
});

export type AuthLoginActionCreators = {
  loginSuccess: typeof loginSuccess;
  loginFailure: typeof loginFailure;
};

export const classicFormlogin = (data: AuthLogin) => (
  dispatch: Dispatch
): Promise<boolean> => {
  const { username } = data;
  // LOGOUT first to make sure we have a clean state
  dispatch({ type: types.LOGOUT });
  dispatch({ type: types.CLASSIC_LOGIN });
  return classicLogin(data)
    .then((auth: IAuthLogin) => {
      if (
        !auth.roles ||
        !auth.roles.some(role =>
          ["SALON", "ADMIN", "SALON_MANAGER"].includes(role)
        )
      ) {
        dispatch(logout());
        return Promise.reject(LOGIN_ERRORS.WRONG_CREDENTIALS);
      }
      const authPayload = { ...auth, username };
      dispatch(loginSuccess(authPayload));
      return true;
    })
    .catch((rawError: any) => {
      const errorMessage = errorToString(rawError);
      dispatch(loginFailure(errorMessage));
      dispatch(pushMessage(errorMessage, MESSAGE_TYPES.ERROR) as any);
      if (Object.values(LOGIN_ERRORS).includes(errorMessage)) {
        return Promise.resolve(false);
      }
      return Promise.reject(errorMessage);
    });
};

export const logout = () => ({
  type: types.LOGOUT
});
