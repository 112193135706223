import { useState, useEffect, useCallback } from "react";
import * as serviceWorker from "utils/serviceWorker";
import { useHistory } from "react-router-dom";
import { useDebuggingContext } from "DebuggingProvider";

interface UseServiceWorkerProps {
  onEvent?(event: string, registration?: ServiceWorkerRegistration): void;
}

/**
 * Enables PWA
 */
export function useServiceWorker(props?: UseServiceWorkerProps) {
  const [forceSkipWaiting, setForceSkipWaiting] = useState(false);
  const [currentRegistration, setCurrentRegistration] = useState<
    ServiceWorkerRegistration
  >();

  const { consoleLog } = useDebuggingContext();

  // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
  const skipWaitingAndReload = useCallback(
    (registration: ServiceWorkerRegistration) => {
      consoleLog("skipWaitingAndReload");
      registration.waiting?.addEventListener("controlling", () => {
        window.location.reload(true);
      });
      registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    },
    []
  );

  function onUpdate(registration: ServiceWorkerRegistration) {
    consoleLog("onUpdate");
    props?.onEvent && props.onEvent("update", registration);
  }

  function onRegister(registration: ServiceWorkerRegistration) {
    consoleLog("onRegister");
    setCurrentRegistration(registration);
    props?.onEvent && props.onEvent("register", registration);
  }

  function onSuccess(registration: ServiceWorkerRegistration) {
    consoleLog("onSuccess");
    props?.onEvent && props.onEvent("success", registration);
  }

  const history = useHistory();
  useEffect(() => {
    consoleLog("forceSkipWaiting || currentRegistration effect", {
      forceSkipWaiting,
      currentRegistration,
    });
    // The recommended way to update Service Worker is to display an actionable snackbar
    // to the user who will click to Reload.
    // As our users are not very tech savy I choose to automatically update on page change
    // This way we can assume their a not doing any critical action and that they already expect a page reload anyway...
    if (currentRegistration?.waiting && forceSkipWaiting) {
      skipWaitingAndReload(currentRegistration);
      return;
    }

    const unlisten = history.listen(() => {
      consoleLog("consoleLog useServiceWorker : History event", {
        currentRegistration,
      });
      if (currentRegistration?.waiting) {
        skipWaitingAndReload(currentRegistration);
      }
    });
    return unlisten;
  }, [forceSkipWaiting, currentRegistration, currentRegistration?.waiting]);

  useEffect(() => {
    serviceWorker.register({
      onRegister,
      onUpdate,
      onSuccess,
    });
  }, []);

  useEffect(() => {
    consoleLog("currentRegistration effect", {
      currentRegistration,
    });
    let interval: NodeJS.Timeout | null;
    if (currentRegistration) {
      // Manually check for update regularly
      // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
      consoleLog("CHECKING UPDATE");
      currentRegistration.update();
      interval = setInterval(() => {
        consoleLog("CHECKING UPDATE");
        currentRegistration.update();
      }, 60_000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentRegistration]);

  return {
    forceSkipWaiting: () => setForceSkipWaiting(true),
  };
}
