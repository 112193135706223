import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { fetchInvoices } from "actions/SalonActions";
import { routeList } from "constants/Routes";
import MobilePageList, { IListItem } from "components/MobilePageList";
import PreviousIcon from "components/svgs/PreviousIcon";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import PaymentPageDesktopLayout from "components/payment/PaymentPageDesktopLayout/PaymentPageDesktopLayout";
import FileDownloadIcon from "components/svgs/FileDownloadIcon";
import useCurrentSalon from "hooks/useCurrentSalon";
import { AppState } from "reducers";
import { Invoice } from "reducers/salon";
import { parseRoute } from "utils/route";
import styles from "./styles.module.scss";
import { isIFrame } from "utils/helpers";

const invoicesToListItems = (invoices: Array<Invoice>): Array<IListItem> =>
  invoices
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    )
    .map((invoice) => ({
      title: `Facture ${invoice.slug}`,
      subtitle: moment(invoice.createdAt).format("MMMM YYYY"),
      link: `/payment/invoices/${invoice.slug}`,
    }));

function InvoicesPage() {
  const { salon } = useCurrentSalon();
  const isSalonStateLoading = useSelector(
    (state: AppState) => state.salon.loading
  );
  const dispatch = useDispatch();
  const invoices = salon.invoices || [];
  const listItems = invoicesToListItems(invoices);

  useEffect(() => {
    dispatch(fetchInvoices(salon.id));
  }, [salon.id]);
  return (
    <InvoicesPageTemplate
      listItems={listItems}
      isLoading={isSalonStateLoading}
    />
  );
}

interface InvoicesPageTemplate {
  listItems: Array<IListItem>;
  isLoading: boolean;
}

export function InvoicesPageTemplate({
  listItems,
  isLoading,
}: InvoicesPageTemplate) {
  const noInvoice = (
    <span className={styles.noInvoice}>
      Aucune facture disponible pour l'instant
    </span>
  );
  return (
    <>
      <Mobile>
        <MobilePageList
          left={
            isIFrame() ? undefined : (
              <Link to={parseRoute(routeList.PAYMENT_PAGE)}>
                <PreviousIcon />
              </Link>
            )
          }
          title="Factures"
          listItems={listItems}
          emptyListComponent={noInvoice}
          isLoading={isLoading}
          parent="payment"
        />
      </Mobile>
      <Desktop>
        <PaymentPageDesktopLayout>
          {isLoading ? (
            <CircularProgress className="ui-loader-centered" />
          ) : listItems.length > 0 ? (
            <List classes={{ root: styles.desktopListContainer }}>
              {listItems.map((item) => (
                <ListItem
                  key={item.title}
                  classes={{ root: styles.desktopListItem }}
                >
                  <div className={styles.itemInfos}>
                    <span className={styles.title}>{item.title}</span>
                    {item.subtitle && (
                      <span className={styles.subtitle}>{item.subtitle}</span>
                    )}
                  </div>
                  <Link to={item.link}>
                    <FileDownloadIcon className={styles.fileDownloadIcon} />
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : (
            noInvoice
          )}
        </PaymentPageDesktopLayout>
      </Desktop>
    </>
  );
}

export default InvoicesPage;
