import React, { memo } from "react";
import moment from "moment";
import { Week } from "reducers/week";
import styles from "./HourSelector.module.scss";
import { getDisabledHoursMinMax } from "utils/week";
import DesktopTimePicker from "components/inputs/TimePicker/TimePicker.desktop";

interface HourSelectorProps {
  index: number;
  week: Week;
  saveWeek(index: number, week: Week): void;
}

const HourSelector = ({ index, week, saveWeek }: HourSelectorProps) => {
  const weekMoment = {
    start:
      week.start &&
      moment()
        .hours(parseInt(week.start.split(":")[0]))
        .minutes(parseInt(week.start.split(":")[1])),
    end:
      week.end &&
      moment()
        .hours(parseInt(week.end.split(":")[0]))
        .minutes(parseInt(week.end.split(":")[1])),
  };

  const minStart = moment().hours(parseInt("00:00")).minutes(parseInt("00:00"));
  const maxEnd = moment().hours(parseInt("23:59")).minutes(parseInt("23:59"));

  return (
    <div>
      De{" "}
      <DesktopTimePicker
        className={styles.shortPicker}
        label=""
        name="start"
        value={weekMoment.start || undefined}
        onChange={(event: any) =>
          saveWeek(index, {
            ...week,
            start: event.target.value,
            end: week.end,
          })
        }
        minuteStep={30}
        format="HH:mm"
        disabledHours={() =>
          getDisabledHoursMinMax(minStart, weekMoment.end || maxEnd)
        }
      />{" "}
      à{" "}
      <DesktopTimePicker
        className={styles.shortPicker}
        label=""
        name="end"
        value={weekMoment.end || undefined}
        onChange={(event: any) =>
          saveWeek(index, {
            ...week,
            start: week.start,
            end: event.target.value,
          })
        }
        minuteStep={30}
        format="HH:mm"
        disabledHours={() =>
          getDisabledHoursMinMax(weekMoment.start || minStart, maxEnd)
        }
      />{" "}
      -{" "}
      <input
        type="number"
        step="1"
        min="0"
        value={week.duration - 1}
        onChange={(event: any) =>
          saveWeek(index, {
            ...week,
            duration: Number(event.target.value) + 1,
          })
        }
        className={styles.dayInput}
      />{" "}
      jours
    </div>
  );
};

export default memo(HourSelector);
