import React, { useEffect, memo } from "react";
import useCurrentSalon from "hooks/useCurrentSalon";
import HappyHoursPage from "components/account/HappyHours/HappyHoursPage";
import { useHistory } from "react-router-dom";

const AccountHappyHoursPage = () => {
  const {
    salon,
    hairdressers,
    weeks,
    fetchCurrentWeeks,
    fetchCurrentAvailabilities,
  } = useCurrentSalon();

  useEffect(() => {
    fetchCurrentWeeks();
  }, []);

  useEffect(() => {
    fetchCurrentWeeks();
  }, [salon]);

  const history = useHistory();

  return (
    <HappyHoursPage
      onClose={() => history.goBack()}
      salon={salon}
      hairdressers={hairdressers.filter(
        (hairdresser) => hairdresser.status === "ENABLED"
      )}
      weeks={weeks}
      fetchCurrentWeeks={fetchCurrentWeeks}
      fetchCurrentAvailabilities={fetchCurrentAvailabilities}
    />
  );
};

export default memo(AccountHappyHoursPage);
