import React from "react";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import { DARK, OPACITY } from "../../../constants/Style";
import classnames from "classnames";

export default function ModalContainer(
  props: ModalProps & { fullWidth?: boolean }
) {
  const { children, open, fullWidth = true, ...otherProps } = props;
  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropProps={{
        style: {
          width: "100%",
          height: "100%",
          backgroundColor: `${DARK}${OPACITY[50]}`
        }
      }}
      {...otherProps}
    >
      <div
        className={classnames("modal_outter-container-desktop", {
          "modal_outter-container-desktop--full-width": fullWidth
        })}
      >
        <div className="modal_container-desktop">{children}</div>
      </div>
    </Modal>
  );
}
