import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import packageJson from "../package.json";

declare global {
  interface Window {
    LE_CISEAU_PRO_DEBUG: {
      VERSION: string;
      isDebugging: boolean;
      enableDebug(): void;
      disableDebug(): void;
    };
  }
}

const DebuggingContext = createContext({
  isDebugging: false,
  consoleLog: (message?: any, ...optionalParams: any[]) => {},
});

/**
 * Utils to Debug the App
 * It's a work in progress
 * Right now it only allows to check the app version in the console
 */
function DebuggingProvider({ children }: { children: React.ReactNode }) {
  const [isDebugging, setIsDebugging] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.LE_CISEAU_PRO_DEBUG = {
        VERSION: packageJson.version,
        isDebugging,
        enableDebug: () =>
          setIsDebugging((isEnabled) => {
            if (isEnabled) {
              console.log("Debug Mode is already Enabled");
            } else {
              console.log("Debug Mode Enabled");
            }
            return true;
          }),
        disableDebug: () =>
          setIsDebugging((isEnabled) => {
            if (isEnabled) {
              console.log("Debug Mode Disabled");
            } else {
              console.log("Debug Mode is already Disabled");
            }
            return false;
          }),
      };
    }
  }, [window, isDebugging]);

  const context = useMemo(
    () => ({
      isDebugging,
      get consoleLog() {
        const getIsDebbugingEnabled = () => {
          let isDebuggingEnabled;
          setIsDebugging((isDebuggingCurrentValue) => {
            isDebuggingEnabled = isDebuggingCurrentValue;
            return isDebuggingCurrentValue;
          });
          return isDebuggingEnabled;
        };
        return (message?: any, ...optionalParams: any[]) => {
          const isDebbugingEnabled = getIsDebbugingEnabled();
          if (isDebbugingEnabled) {
            console.log(message, ...optionalParams);
          }
        };
      },
    }),
    [isDebugging]
  );

  return (
    <DebuggingContext.Provider value={context}>
      {children}
    </DebuggingContext.Provider>
  );
}

function useDebuggingContext() {
  const context = useContext(DebuggingContext);

  if (!context) {
    throw new Error(
      `DebuggingContext must be used inside a DebuggingContext.Provider`
    );
  }

  return context;
}

export { DebuggingProvider, useDebuggingContext };
