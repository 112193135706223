import moment from "moment";

import * as CALENDAR from "constants/Calendar";
import { CalendarEvent, CalendarResource } from "components/Calendar";
import { CALENDAR_COLUMN_DATA_ID } from "components/Calendar/CalendarColumn";

const getRulerTimeSteps = (
  dayStart: moment.Duration,
  dayEnd: moment.Duration,
  timeStepDuration: moment.Duration
): Array<moment.Moment> => {
  const midnight = moment().startOf("day");
  const startTime = moment(midnight).add(dayStart);
  const endTime = moment(midnight).add(dayEnd);
  const timeStepDurationMs = timeStepDuration.asMilliseconds();
  return Array.from({
    length: endTime.diff(startTime) / timeStepDurationMs + 1,
  }).map((_, stepIndex) =>
    moment(startTime.valueOf() + stepIndex * timeStepDurationMs)
  );
};

const withinTimegridBoundaries = (min: number, max: number) => (
  offset: number
): number => Math.min(Math.max(offset, min), max);

const getOffsetByTime = (
  dayStart: moment.Duration,
  timeStepDuration: moment.Duration
) => (time: moment.Moment): number => {
  const calendarStartTime = moment(time).startOf("day").add(dayStart);
  const timeDiff = time.diff(calendarStartTime);
  const timeStepOffset = timeDiff / +timeStepDuration;
  return timeStepOffset * CALENDAR.CELL_HEIGHT;
};

const getTimeByOffset = (
  dayStart: moment.Duration,
  timeStepDuration: moment.Duration,
  date: string,
  offset: number
) => {
  const timeSteps = offset / CALENDAR.CELL_HEIGHT;
  const timeDiff = timeSteps * +timeStepDuration;
  const flooredTimeDiff =
    Math.floor(timeDiff / +timeStepDuration) * +timeStepDuration;
  const time = moment(date)
    .startOf("day")
    .add(dayStart)
    .add(flooredTimeDiff)
    .toISOString();
  return time;
};

const getCalendarColumnElement = (element: HTMLDivElement) => {
  let calendarColumnElement: HTMLElement | null = null;
  let currentElement: HTMLElement | null = element;
  while (currentElement) {
    const isCalendarColumn = Boolean(
      currentElement.dataset[CALENDAR_COLUMN_DATA_ID]
    );
    if (isCalendarColumn) {
      calendarColumnElement = currentElement;
      break;
    }
    currentElement = currentElement.parentElement;
  }
  return calendarColumnElement;
};

const sumOffsetToRootElement = (
  element: HTMLElement | null,
  property: "offsetHeight" | "offsetLeft" | "offsetTop" | "offsetWidth",
  initialValue = 0
): number => {
  if (!element) {
    return initialValue;
  }
  return sumOffsetToRootElement(
    element.offsetParent as HTMLElement,
    property,
    initialValue + element[property]
  );
};

const getColumnMouseEventDatetime = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  scrollTop: number,
  tableContainerRef: React.RefObject<HTMLDivElement>,
  dayStart: moment.Duration,
  timeStepDuration: moment.Duration,
  day: moment.Moment
) => {
  const { pageY: offsetY, target } = event;
  const calendarColumnElement = getCalendarColumnElement(
    target as HTMLDivElement
  );
  if (!calendarColumnElement) return "";

  const { offsetTop: targetOffset } = calendarColumnElement;
  const tableOffset = sumOffsetToRootElement(
    tableContainerRef.current,
    "offsetTop"
  );
  const relativeOffset = offsetY + scrollTop - (targetOffset + tableOffset);
  const datetime = getTimeByOffset(
    dayStart,
    timeStepDuration,
    day.toISOString(),
    relativeOffset
  );

  return datetime;
};

function getEventHeight(timeStepDuration: moment.Duration) {
  return function <T>(event: CalendarEvent<T>) {
    const eventDuration = moment.duration(event.end.diff(event.start));
    return (+eventDuration / +timeStepDuration) * CALENDAR.CELL_HEIGHT;
  };
}

function getEventPositionInfos(
  dayStart: moment.Duration,
  dayEnd: moment.Duration,
  timeStepDuration: moment.Duration
) {
  const dayDuration = dayEnd.subtract(dayStart);
  const timeGridSize =
    (+dayDuration / +timeStepDuration) * CALENDAR.CELL_HEIGHT;
  const offsetByTime = getOffsetByTime(dayStart, timeStepDuration);
  const timegridBounded = withinTimegridBoundaries(0, timeGridSize);
  const eventHeight = getEventHeight(timeStepDuration);

  return function <T>(event: CalendarEvent<T>) {
    const unboundedTopOffset = offsetByTime(event.start);
    const topOffset = timegridBounded(unboundedTopOffset);
    const height = timegridBounded(
      timegridBounded(unboundedTopOffset + eventHeight(event)) - topOffset
    );
    return { topOffset, height };
  };
}

const getMonthDaysOf = (day: moment.Moment) => {
  const rangeStart = moment(day).startOf("month");
  const rangeEnd = moment(day).endOf("month");
  return Array.from({
    length: rangeEnd.diff(rangeStart, "days") + 1,
  }).map((_, index) => moment(rangeStart).add(index, "days"));
};

function filterColumnEvents<T>(
  events: CalendarEvent<T>[],
  columnResource: CalendarResource,
  columnDate: moment.Moment
) {
  return events.filter(
    (event) =>
      event.resourceId === columnResource.id &&
      moment(event.start).isSame(moment(columnDate), "day")
  );
}

const getById = (id: string) => (document || {}).getElementById(id);

export {
  getRulerTimeSteps,
  getEventPositionInfos,
  filterColumnEvents,
  getMonthDaysOf,
  getById,
  getColumnMouseEventDatetime,
};
