import React from "react";
import { LIGHT_BLUE_GREY } from "constants/Style";
import { Event } from "containers/Rating/SearchRatingPage";
import { useRatingReplier } from "containers/Rating/useRatingReplier";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import { Rating } from "reducers/rating";
import RatingComment from "../RatingComment/RatingComment";
import HeaderMobile from "../../page/Header.mobile";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import TextInput from "../../inputs/Text";
import CloseIcon from "../../svgs/CloseIcon";
import styles from "./SearchRatingPage.module.scss";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import { isIFrame } from "utils/helpers";

interface SearchRatingPageProps {
  query: string;
  ratings: Array<Rating>;
  onChange(event: Event): void;
  onClose(): void;
  openReplyModal(rating: Rating): void;
}

function SearchRatingPage({
  query,
  onChange,
  ratings,
  onClose,
  openReplyModal,
}: SearchRatingPageProps) {
  const placeholder = "Recherche par prénom ou nom";
  const { replyToRating } = useRatingReplier();
  const isSearching = query.length > 0;
  const ratingsWithType = ratings.filter((rating) => rating.type);
  return (
    <>
      <Mobile>
        <ModalLayoutMobile
          header={
            <HeaderMobile
              classes={{
                left: "autocomplete-input_container",
                right: "autocomplete-input_right-container",
              }}
              left={
                <TextInput
                  autoFocus
                  fullWidth
                  className="autocomplete-input"
                  value={query}
                  onChange={onChange}
                  placeholder={placeholder}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  // The material-ui API has duplicate props, differing only in casing
                  // eslint is not happy with it...
                  // eslint-disable-next-line
                  inputProps={{
                    style: {
                      minWidth: "300px",
                      padding: "unset",
                      minHeight: "21px", // Fixes M-UI design issue where scrollbar is shown when text empty
                    }, // https://github.com/mui-org/material-ui/pull/15331
                  }}
                />
              }
              right={<CloseIcon onClick={onClose} />}
            />
          }
          body={
            <React.Fragment>
              {ratingsWithType.map(
                (rating) =>
                  rating.type && (
                    <RatingComment
                      key={`${rating.id}_${rating.type}`}
                      type={rating.type}
                      rating={rating}
                      openReplyModal={openReplyModal}
                      onSubmitReply={replyToRating(rating)}
                    />
                  )
              )}
            </React.Fragment>
          }
        />
      </Mobile>
      <Desktop>
        <div className={styles.container}>
          {!isIFrame() && <MenuDesktop noShadow />}
          <div className={styles.searchBar}>
            <div className={styles.inputContainer}>
              <TextField
                autoFocus
                fullWidth
                placeholder={placeholder}
                value={query}
                onChange={onChange}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className={styles.closeContainer} onClick={onClose}>
              <CloseIcon color={LIGHT_BLUE_GREY} />
            </div>
          </div>
          <div
            className={classnames(styles.searchResultsContainer, {
              [styles.searching]: isSearching,
            })}
            onClick={() => !isSearching && onClose()}
          >
            {isSearching && (
              <>
                <span className={styles.return} onClick={onClose}>
                  &larr; Retour
                </span>
                <div className={styles.title}>
                  Avis{" "}
                  <span>{`(${ratingsWithType.length}${
                    ratingsWithType.length >= 20 ? "+" : ""
                  })`}</span>
                </div>
                {ratingsWithType.map(
                  (rating) =>
                    rating.type && (
                      <RatingComment
                        key={`${rating.id}_${rating.type}`}
                        type={rating.type}
                        rating={rating}
                        openReplyModal={openReplyModal}
                        onSubmitReply={replyToRating(rating)}
                      />
                    )
                )}
              </>
            )}
          </div>
        </div>
      </Desktop>
    </>
  );
}

export default SearchRatingPage;
