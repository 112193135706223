import React from "react";
import moment from "moment";

import FreeSlotFormContainer from "containers/Agenda/FreeSlotForm";
import BookingFormContainer from "containers/Agenda/BookingForm";
import { CalendarEvent } from "components/Calendar";
import { AGENDA_EVENT_KINDS } from "components/agenda/AgendaPage/AgendaPage";
import {
  ICreatingEventMeta,
  ISlotEventMeta,
  IBookingEventMeta,
} from "components/agenda/EventRenderer/EventRenderer";
import { earliestPossibleBookingCreation } from "./utils";

interface EventFormProps {
  eventToEdit?: CalendarEvent<
    ICreatingEventMeta | ISlotEventMeta | IBookingEventMeta
  >;
  date: moment.Moment;
  onClose(): void;
  refetchSlots(): void;
}

function EventForm({
  eventToEdit,
  date,
  onClose,
  refetchSlots,
}: EventFormProps) {
  if (eventToEdit && eventToEdit.meta.kind === AGENDA_EVENT_KINDS.BOOKING) {
    return (
      <BookingFormContainer
        onClose={onClose}
        bookingToEdit={eventToEdit.meta.booking}
      />
    );
  }
  let slotToEdit = undefined;
  const eventMeta = eventToEdit?.meta;
  switch (eventMeta?.kind) {
    case AGENDA_EVENT_KINDS.CREATING:
      slotToEdit = { ...eventMeta.partialSlot, discount: -1 };
      break;
    case AGENDA_EVENT_KINDS.SLOT:
      slotToEdit = eventMeta.slot;
      break;
  }
  return (
    <FreeSlotFormContainer
      onClose={onClose}
      slotToEdit={slotToEdit}
      date={moment.max(date, earliestPossibleBookingCreation)}
      refetchSlots={refetchSlots}
    />
  );
}

export default EventForm;
