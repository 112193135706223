import moment from "moment";

export const FIRST_HOUR = moment.duration(0, "hours");
export const LAST_HOUR = moment.duration({
  hours: 23,
  minutes: 59
});
export const DEFAULT_HOUR = moment.duration({
  hours: 9,
  minutes: 0
});
export const TIME_STEP_DURATION = moment.duration(15, "minutes");

export const WEEK_DAYS = [
  {
    value: 1,
    label: "Lundi"
  },
  {
    value: 2,
    label: "Mardi"
  },
  {
    value: 3,
    label: "Mercredi"
  },
  {
    value: 4,
    label: "Jeudi"
  },
  {
    value: 5,
    label: "Vendredi"
  },
  {
    value: 6,
    label: "Samedi"
  },
  {
    value: 0,
    label: "Dimanche"
  }
];
