import { mutation, safeError } from "utils/graphqlClient";

export const updateHaidresserPackages = async (
  hairdresserId: number,
  packageIds: Array<number>
) => {
  const res = await mutation(
    "updateHairdresser",
    {
      id: {
        type: "Int",
        value: Number(hairdresserId),
      },
      packageIds: {
        type: "[Int]",
        value: packageIds,
      },
    },
    "id, packageIds"
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.updateHairdresser)
    : Promise.reject(safeError(body));
};
