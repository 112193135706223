import { useEffect, useRef } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { History } from "history";
import moment from "moment";

import { RESET, LOGOUT } from "constants/ActionTypes";
import useCurrentUser from "hooks/useCurrentUser";
import * as yup from "yup";

const RECOVERY_STEPS_NAMES = {
  HARD_RELOAD: "HARD_RELOAD" as const,
  SKIP_WAITING_SERVICE_WORKER: "SKIP_WAITING_SERVICE_WORKER" as const,
  HISTORY_BACK: "HISTORY_BACK" as const,
  RESET_STORE: "RESET_STORE" as const,
  STORE_LOGOUT: "STORE_LOGOUT" as const,
  FAIL: "FAIL" as const,
};

interface ICrashRecovery {
  error?: string;
}

export function useCrashRecovery(props?: ICrashRecovery) {
  const error = props?.error ?? "";
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useCurrentUser();
  const isRecoveringRef = useRef(false);

  const tryToRecover = async () => {
    isRecoveringRef.current = true;
    const currentStep = getCurrentStep({ isLoggedIn, error });
    if (currentStep.name === RECOVERY_STEPS_NAMES.FAIL) {
      setTimeout(() => {
        /**
         * Clear the recovery state if the recovery procedure failed
         * so it can be restarted when reloading the page
         */
        clearRecoveryState();
      }, 1_000);
      return {
        recovering: false,
        failed: true,
      };
    }

    await performAction(currentStep, {
      dispatch,
      history,
    });
    return {
      recovering: true,
      failed: false,
    };
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isRecoveringRef.current) {
        /**
         * If we are not recovering it means the app was able to run successfully
         * We clear any recovery state that may exist
         */
        clearRecoveryState();
      }
    }, 2_000);
    return () => clearTimeout(timeout);
  });

  return {
    tryToRecover,
  };
}

const RECOVERY_KEY = "LeCiseau_Recovery_Procedure";

const INITIAL_RECOVERY_STEP_NAME = RECOVERY_STEPS_NAMES.HARD_RELOAD;

type IRecoveryStepName = keyof typeof RECOVERY_STEPS_NAMES;

interface IActionProps {
  dispatch: Dispatch<any>;
  history: History<any>;
}

interface IRecoveryStep {
  name: IRecoveryStepName;
  action?(props: IActionProps): void;
  getNextStep?({
    isLoggedIn,
    error,
  }: {
    isLoggedIn: boolean;
    error: string;
  }): IRecoveryStep;
}

const recoveryStateSchema = yup.object().shape({
  currentStepName: yup
    .mixed<IRecoveryStepName>()
    .required()
    .oneOf(Object.values(RECOVERY_STEPS_NAMES)),
  triedCurrentStepRecoveryProcedure: yup.boolean().required(),
  lastActionAt: yup.number().required(),
});

type IRecoveryState = yup.InferType<typeof recoveryStateSchema>;

/**
 *
 * Crash Recovery Steps :
 * --- Recovery Stops as soon as a step is successful
 * --- We Hard reload the page after each step
 * 1. Hard Reload Only
 * 2. Skip Waiting if waiting Service Worker is available
 * 3. only if error === 404 :=> history.back()
 * 4. only if user is logged in -> Reset Redux Store without loging out
 * 5. Reset all Redux Store data (LOGOUT action)
 * 6. Recovery Failed
 *
 */

const recoverySteps: Record<IRecoveryStepName, IRecoveryStep> = {
  [RECOVERY_STEPS_NAMES.HARD_RELOAD]: {
    name: RECOVERY_STEPS_NAMES.HARD_RELOAD,
    action: () => {},
    getNextStep: () =>
      recoverySteps[RECOVERY_STEPS_NAMES.SKIP_WAITING_SERVICE_WORKER],
  },
  [RECOVERY_STEPS_NAMES.SKIP_WAITING_SERVICE_WORKER]: {
    name: RECOVERY_STEPS_NAMES.SKIP_WAITING_SERVICE_WORKER,
    action: () => {
      navigator?.serviceWorker?.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.waiting?.postMessage({ type: "SKIP_WAITING" });
          registration.unregister();
        });
      });
    },
    getNextStep: ({ isLoggedIn, error }) =>
      error === "404"
        ? recoverySteps[RECOVERY_STEPS_NAMES.HISTORY_BACK]
        : isLoggedIn
        ? recoverySteps[RECOVERY_STEPS_NAMES.RESET_STORE]
        : recoverySteps[RECOVERY_STEPS_NAMES.STORE_LOGOUT],
  },
  [RECOVERY_STEPS_NAMES.HISTORY_BACK]: {
    name: RECOVERY_STEPS_NAMES.HISTORY_BACK,
    action: ({ history }) => {
      history.goBack();
    },
    getNextStep: ({ isLoggedIn }) =>
      isLoggedIn
        ? recoverySteps[RECOVERY_STEPS_NAMES.RESET_STORE]
        : recoverySteps[RECOVERY_STEPS_NAMES.STORE_LOGOUT],
  },
  [RECOVERY_STEPS_NAMES.RESET_STORE]: {
    name: RECOVERY_STEPS_NAMES.RESET_STORE,
    action: ({ dispatch }) => {
      dispatch({ type: RESET });
    },
    getNextStep: () => recoverySteps[RECOVERY_STEPS_NAMES.STORE_LOGOUT],
  },
  [RECOVERY_STEPS_NAMES.STORE_LOGOUT]: {
    name: RECOVERY_STEPS_NAMES.STORE_LOGOUT,
    action: ({ dispatch }) => {
      dispatch({ type: LOGOUT });
    },
    getNextStep: () => recoverySteps[RECOVERY_STEPS_NAMES.FAIL],
  },
  [RECOVERY_STEPS_NAMES.FAIL]: {
    name: RECOVERY_STEPS_NAMES.FAIL,
  },
};

async function performAction(step: IRecoveryStep, props: IActionProps) {
  setRecoveryState({
    triedCurrentStepRecoveryProcedure: true,
  });
  step.action && step.action(props);

  return new Promise((resolve) => {
    /**
     * We reload after a timeout to let the app the time to run normally
     * if we don't wait all the steps gets triggered until fail ...
     */
    setTimeout(() => {
      resolve();
      hardReload();
    }, 1_000);
  });
}

function hardReload() {
  window.location.reload(true);
}

function getDefaultRecoveryState() {
  const state: IRecoveryState = {
    currentStepName: INITIAL_RECOVERY_STEP_NAME,
    triedCurrentStepRecoveryProcedure: false,
    lastActionAt: moment().valueOf(),
  };
  return state;
}

/**
 * The recovery state is stored in localStorage
 * to be persisted between page reloads
 */
function getRecoveryState() {
  try {
    const state = JSON.parse(localStorage.getItem(RECOVERY_KEY) ?? "");
    const isValid = recoveryStateSchema.isValidSync(state);
    if (isValid) {
      return state as IRecoveryState;
    }
  } catch {}

  const defaultState = getDefaultRecoveryState();
  localStorage.setItem(RECOVERY_KEY, JSON.stringify(defaultState));
  return defaultState;
}

function setRecoveryState(newValues: Partial<IRecoveryState>) {
  const oldState = getRecoveryState();
  const newState: IRecoveryState = {
    ...oldState,
    ...newValues,
    lastActionAt: moment().valueOf(),
  };
  localStorage.setItem(RECOVERY_KEY, JSON.stringify(newState));
}

function clearRecoveryState() {
  localStorage.removeItem(RECOVERY_KEY);
}

function getCurrentStep({
  isLoggedIn,
  error,
}: {
  isLoggedIn: boolean;
  error: string;
}) {
  const currentRecoveryState: IRecoveryState = getRecoveryState();
  if (currentRecoveryState.triedCurrentStepRecoveryProcedure) {
    const currentStep = recoverySteps[currentRecoveryState.currentStepName];
    const nextStep = currentStep.getNextStep
      ? currentStep.getNextStep({ isLoggedIn, error })
      : currentStep;
    setRecoveryState({
      currentStepName: nextStep.name,
      triedCurrentStepRecoveryProcedure: false,
    });
  }
  return recoverySteps[getRecoveryState().currentStepName];
}
