import React, { useState, useEffect, useCallback } from "react";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import PickerArrow from "../../svgs/PickerArrow";
import { BLUE_GREY } from "../../../constants/Style";
import { capitalize } from "../../../utils/string";
import { defaultCalendarFormat } from "../../../constants/Input";
import moment from "moment";
import WheelPicker from "../WheelPicker";
import FakeInput from "../FakeInput";

const NUMBER_OF_YEARS_IN_WHEEL = 10;

interface IDateValues {
  day?: string;
  month?: string;
  year?: string;
}

interface DatePickerMobileProps extends StandardTextFieldProps {
  value?: string;
  calendarFormat?: moment.CalendarSpec;
  min?: string;
  max?: string;
  noDay?: boolean;
  noMonth?: boolean;
  noYear?: boolean;
  renderValues?(values: IDateValues): string;
  renderInput?(props: { value: any; onClick(): void }): React.ReactNode;
  floatingWheels?: boolean;
}

const defaultRenderValues = (calendarFormat?: moment.CalendarSpec) => (
  values: any
) => {
  const dateString = `${values.year}-${values.month}-${values.day}`;
  const date = moment(dateString);
  return capitalize(
    date.calendar(undefined, calendarFormat || defaultCalendarFormat)
  );
};

const DatePickerMobile: React.FC<DatePickerMobileProps> = props => {
  const {
    name,
    className,
    disabled,
    value: controlledValue,
    onChange: controlledOnChange,
    calendarFormat,
    min,
    max,
    noDay,
    noMonth,
    noYear,
    renderInput,
    renderValues = defaultRenderValues(calendarFormat),
    floatingWheels,
    ...otherProps
  } = props;

  const today = moment();

  const defaultDay = today.format("DD");
  const defaultMonth = today.format("MM");
  const defaultYear = today.format("YYYY");

  const [minYear, minMonth, minDay] = (min || "--").split("-");

  const [selectedYear, setSelectedYear] = useState(defaultYear);

  const startYear =
    parseInt(minYear) ||
    parseInt(today.format("YYYY")) - NUMBER_OF_YEARS_IN_WHEEL / 2;

  /**
   * Initialize the Year Wheel values
   */
  const yearValues = Array.from({
    length: NUMBER_OF_YEARS_IN_WHEEL
  }).map((_, index) => (startYear + index).toString());

  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);

  const getMonthValues = useCallback(() => {
    if (noMonth) return [];

    const minMonthNum = selectedYear === minYear ? parseInt(minMonth) : 1;
    return Array.from({ length: 12 - minMonthNum + 1 }).map((_, index) =>
      (index + minMonthNum).toString().padStart(2, "0")
    );
  }, [selectedYear, minMonth, minYear, noMonth]);

  const getDayValues = useCallback(() => {
    if (noDay) return [];

    const daysInMonth = moment(
      `${selectedYear}-${selectedMonth}`
    ).daysInMonth();
    const minDayNum =
      selectedMonth === minMonth && selectedYear === minYear
        ? parseInt(minDay)
        : 1;
    return Array.from({
      length: daysInMonth + 1 - minDayNum
    }).map((_, index) => (index + minDayNum).toString());
  }, [selectedMonth, selectedYear, minDay, minMonth, minYear, noDay]);

  const controlledToInternalValue = useCallback((value, defaultValues = {}) => {
    const [year, month, day] = (value || "").split("-");
    return {
      year: year || defaultValues.year,
      month: month || defaultValues.month,
      day: day || defaultValues.day
    };
  }, []);

  const isControlled = controlledValue !== undefined;
  const [internalValues, setInternalValues] = useState<IDateValues>(
    controlledToInternalValue(controlledValue, {
      day: defaultDay,
      month: defaultMonth,
      year: defaultYear
    })
  );

  useEffect(() => {
    setInternalValues(controlledToInternalValue(controlledValue));
  }, [controlledValue, controlledToInternalValue]);

  const onChange = (values: any) => {
    if (isControlled) {
      const { year, month, day } = values;
      const daysInMonth = moment(
        `${year}-${month.toString().padStart(2, "0")}`
      ).daysInMonth();
      const value = `${year}-${month.toString().padStart(2, "0")}-${Math.min(
        daysInMonth,
        day
      )
        .toString()
        .padStart(2, "0")}`;
      controlledOnChange &&
        controlledOnChange({
          target: {
            name,
            value
          }
        } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setInternalValues(values);
    }
  };

  return (
    <WheelPicker
      renderValues={renderValues}
      values={internalValues}
      onChange={onChange}
      className={className}
      disabled={disabled}
      floatingWheels={floatingWheels}
    >
      <WheelPicker.Input
        render={({ value, onClick }) =>
          renderInput ? (
            renderInput({ value, onClick })
          ) : (
            <div className="input-group">
              <TextField
                variant="standard"
                type="text"
                disabled={disabled}
                {...otherProps}
                value={value}
                onClick={onClick}
                InputLabelProps={{
                  shrink: true,
                  className: "input_label",
                  ...props.InputLabelProps
                }}
                InputProps={{
                  className: "input_input",
                  inputComponent: FakeInput,
                  ...props.InputProps
                }}
              />
              {!disabled && (
                <PickerArrow
                  large
                  className="input_date-picker-arrow"
                  color={BLUE_GREY}
                />
              )}
            </div>
          )
        }
      />
      <WheelPicker.Wheels>
        {!noDay && <WheelPicker.Wheel name="day" values={getDayValues()} />}
        {!noMonth && (
          <WheelPicker.Wheel
            name="month"
            values={getMonthValues()}
            renderValue={value =>
              value
                ? capitalize(
                    moment()
                      .month(parseInt(value) - 1)
                      .format("MMMM")
                  )
                : ""
            }
            onChange={setSelectedMonth}
          />
        )}
        {!noYear && (
          <WheelPicker.Wheel
            name="year"
            values={yearValues}
            onChange={setSelectedYear}
          />
        )}
      </WheelPicker.Wheels>
    </WheelPicker>
  );
};

export default DatePickerMobile;
