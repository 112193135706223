import { ERRORS } from "constants/SnackBar";

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const titleize = (str: string) =>
  str
    .toLowerCase()
    .split(" ")
    .map(capitalize)
    .join(" ");

const errorToString = (error: any): string => {
  if (typeof error === "string") {
    return error;
  }
  if (typeof error === "object" && error.message) {
    return error.message;
  }
  return ERRORS.UNKNOWN;
};

const pluralize = (singular: string, plural: string, num: number): string => {
  return num <= 1 ? singular : plural;
};

export { capitalize, titleize, errorToString, pluralize };
