import { Reducer } from "redux";
import { getPaymentsCache } from "../utils/payments";
import {
  PaymentsActions,
  FetchPaidBookingsSuccessAction,
  FetchPayoutsSuccessAction,
} from "../actions/PaymentsActions";
import * as types from "../constants/ActionTypes";
import { Booking } from "./booking";
import { ResetAction } from "../actions/ResetAction";

export interface Payout {
  id: string;
  amount: number;
  status: string;
  arrivalDate: number;
  createdAt: number;
  bookings?: Array<Booking>;
}

export interface PaymentsPayload {
  bookingsByHairdresserId: { [id: number]: { [id: number]: Booking } };
  payoutsBySalonId: { [id: number]: { [id: string]: Payout } };
}

export interface PaymentsState {
  loading: boolean;
  payload: PaymentsPayload;
  error: string | null;
}

const fetchPaidBookingsSuccessReducer = (
  state: PaymentsState,
  action: FetchPaidBookingsSuccessAction
): PaymentsState => {
  const bookings = action.payload;
  const newState = {
    ...state,
    loading: false,
    payload: {
      ...state.payload,
      bookingsByHairdresserId: {
        ...state.payload.bookingsByHairdresserId,
        ...bookings.reduce(
          (
            byHairdresserId: { [id: number]: { [id: number]: Booking } },
            booking: Booking
          ) => {
            const { hairdresser } = booking.snapshotPackages[0];
            return {
              ...byHairdresserId,
              [hairdresser.id || Number(hairdresser.stringId)]: {
                ...byHairdresserId[
                  hairdresser.id || Number(hairdresser.stringId)
                ],
                [booking.id]: booking,
              },
            };
          },
          {}
        ),
      },
    },
    error: null,
  };
  localStorage.setItem("payments", JSON.stringify(newState.payload));
  return newState;
};

const fetchPayoutsSuccessReducer = (
  state: PaymentsState,
  action: FetchPayoutsSuccessAction
): PaymentsState => {
  const { salonId, payouts } = action.payload;
  const newState = {
    ...state,
    loading: false,
    payload: {
      ...state.payload,
      payoutsBySalonId: {
        ...state.payload.payoutsBySalonId,
        [salonId]: {
          ...state.payload.payoutsBySalonId[salonId],
          ...payouts.reduce(
            (byId, payout) => ({ ...byId, [payout.id]: payout }),
            {}
          ),
        },
      },
    },
    error: null,
  };
  localStorage.setItem("payments", JSON.stringify(newState.payload));
  return newState;
};

const getInitialState = (): PaymentsState => ({
  loading: false,
  payload: {
    bookingsByHairdresserId: getPaymentsCache().bookingsByHairdresserId || {},
    payoutsBySalonId: getPaymentsCache().payoutsBySalonId || {},
  },
  error: null,
});

const salon: Reducer<PaymentsState, PaymentsActions | ResetAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case types.FETCH_PAID_BOOKINGS:
    case types.FETCH_PAYOUTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_PAID_BOOKINGS_SUCCESS:
      return fetchPaidBookingsSuccessReducer(state, action);
    case types.FETCH_PAYOUTS_SUCCESS:
      return fetchPayoutsSuccessReducer(state, action);
    case types.FETCH_PAID_BOOKINGS_FAILURE:
    case types.FETCH_PAYOUTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem("payments", "");
      return getInitialState();
    default:
      return state;
  }
};

export default salon;
