import React, { memo, useState } from "react";
import { LastMinute } from "reducers/lastMinute";
import styles from "./styles.module.scss";
import Switch from "@material-ui/core/Switch";
import Delete from "components/svgs/Delete";
import PromoSelector from "../PromoSelector/PromoSelector";
import { MenuItem, Select } from "@material-ui/core";
import {
  hoursToMinutes,
  minutesToHours,
  allowedHoursBefore,
} from "utils/lastMinute";

interface LastMinuteFormProps {
  index: number;
  lastMinute: LastMinute;
  saveLastMinute(index: number, lastMinute: LastMinute): void;
}

const LastMinuteForm = ({
  index,
  lastMinute,
  saveLastMinute,
}: LastMinuteFormProps) => {
  const [
    selectedMinutesBeforeInHours,
    setSelectedMinutesBeforeInHours,
  ] = useState<number>(minutesToHours(lastMinute.minutesBefore));
  return (
    <div className={styles.lastMinuteContainer} key={index}>
      <div className={styles.hhTitle}>
        <Switch
          checked={lastMinute.status === "ENABLED"}
          onChange={() =>
            saveLastMinute(index, {
              ...lastMinute,
              status: lastMinute.status === "ENABLED" ? "DISABLED" : "ENABLED",
            })
          }
        />
        <h3>Promotion last minute #{index + 1}</h3>
        <div
          className={styles.inlineBtn}
          onClick={() =>
            saveLastMinute(index, { ...lastMinute, status: "DELETED" })
          }
        >
          <Delete />
        </div>
      </div>
      <div className={styles.promoSelector}>
        <PromoSelector
          object={lastMinute}
          index={index}
          saveObject={saveLastMinute}
        />
      </div>
      <div>
        <div className={styles.minutesBeforeLabel}>
          pour les créneaux vides dans les prochaines
        </div>
        <Select
          name="minutesBefore"
          value={selectedMinutesBeforeInHours}
          onChange={(event: any) => {
            setSelectedMinutesBeforeInHours(event.target.value);
            saveLastMinute(index, {
              ...lastMinute,
              minutesBefore: hoursToMinutes(event.target.value),
            });
          }}
        >
          {allowedHoursBefore.map((ref, index) => (
            <MenuItem key={index} value={ref}>
              {ref}H
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default memo(LastMinuteForm);
