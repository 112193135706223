import * as types from "../constants/ActionTypes";
import { Dispatch } from "redux";
import { SnackbarMessage } from "../reducers/snackbar";
import { translateSnackbarMessage, shouldHideMessage } from "../utils/snackbar";

type PushMessageAction = {
  type: typeof types.PUSH_MESSAGE;
  payload: SnackbarMessage;
};

type closeMessageAction = {
  type: typeof types.CLOSE_MESSAGE;
  payload: number;
};

export type SnackbarActions = PushMessageAction | closeMessageAction;

export const closeMessage = (messageId: number) => ({
  type: types.CLOSE_MESSAGE,
  payload: messageId,
});

export const pushMessage = (message: string, type: string) => (
  dispatch: Dispatch
) => {
  if (shouldHideMessage(message, type)) {
    return;
  }
  const id = new Date().getTime();
  const onClose = () => dispatch(closeMessage(id));
  const translatedMessage = translateSnackbarMessage(message);
  dispatch({
    type: types.PUSH_MESSAGE,
    payload: {
      id,
      type,
      text: translatedMessage,
      onClose,
      timeoutHandle: setTimeout(onClose, 2500),
    },
  });

  if (message === "403 Forbidden") {
    dispatch({
      type: types.LOGOUT,
    });
  }
};
