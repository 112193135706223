import React from "react";
import { ModalHeaderProps } from "./Header";
import CloseIcon from "../../svgs/CloseIcon";
import { LIGHT_BLUE_GREY } from "../../../constants/Style";
import LargeClickable from "../../uiElements/LargeClickable";

export default function ModalHeaderMobile({
  onClose,
  title
}: ModalHeaderProps) {
  return (
    <div className="modal_header modal_header-desktop">
      <LargeClickable
        onClick={onClose}
        className="modal_header_close-icon-desktop"
      >
        <CloseIcon onClick={onClose} color={LIGHT_BLUE_GREY} />
      </LargeClickable>
      {title && <div className="modal_header_title-desktop">{title}</div>}
    </div>
  );
}
