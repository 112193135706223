import { Reducer } from "redux";
import * as yup from "yup";

import {
  RatingActions,
  FetchSalonRatingsStatsSuccessAction,
  FetchHairdresserRatingsStatsSuccessAction,
} from "actions/RatingActions";
import { ResetAction } from "actions/ResetAction";
import * as types from "constants/ActionTypes";
import { getRatingCache } from "utils/ratings";
import { userSchema, bookingSchema } from "./booking";

const ratingCommentReplySchema = yup.object().shape({
  id: yup.number().required(),
  status: yup.string().required(),
  content: yup.string().required(),
  role: yup.string().required(),
  respondentId: yup.number().required(),
  createdAt: yup.string().required(),
  updatedAt: yup.string().required(),
});
export type RatingCommentReply = yup.InferType<typeof ratingCommentReplySchema>;

// export interface Rating {
//   id: number;
//   salonId: number;
//   type?: "USER" | "HAIRDRESSER" | "SALON";
//   comment?: string;
//   hairdresserComment?: string;
//   userComment?: string;
//   salonComment?: string;
//   rate?: number;
//   userRate?: number;
//   hairdresserRate?: number;
//   salonRate?: number;
//   booking: Booking;
//   replies: Array<RatingCommentReply>;
//   user?: User;
//   hairdresser: {
//     id: number;
//     firstName: string;
//     lastName: string;
//   };
//   createdAt: string;
//   updatedAt: string;
// }

const RATING_TYPES = {
  USER: "USER" as const,
  HAIRDRESSER: "HAIRDRESSER" as const,
  SALON: "SALON" as const,
};

export const ratingSchema = yup.object().shape({
  id: yup.number().required(),
  salonId: yup.number().required(),
  type: yup
    .mixed<keyof typeof RATING_TYPES>()
    .notRequired()
    .oneOf(Object.values(RATING_TYPES)),
  comment: yup.string().nullable().notRequired(),
  hairdresserComment: yup.string().nullable().notRequired(),
  userComment: yup.string().nullable().notRequired(),
  salonComment: yup.string().nullable().notRequired(),
  rate: yup.number().nullable().notRequired(),
  userRate: yup.number().nullable().notRequired(),
  hairdresserRate: yup.number().nullable().notRequired(),
  salonRate: yup.number().nullable().notRequired(),
  booking: bookingSchema.required(),
  replies: yup.array().required().of(ratingCommentReplySchema),
  user: userSchema.notRequired(),
  hairdresser: yup
    .object()
    .shape({
      id: yup.number().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      stringId: yup.string().required(),
    })
    .required(),
  createdAt: yup.string().required(),
  updatedAt: yup.string().required(),
});
export type Rating = yup.InferType<typeof ratingSchema>;

export interface RatingStats {
  sum?: number;
  count?: number;
}

type StatsKeys = "bySalonId" | "byHairdresserId";

export interface RatingPayload {
  ratingById: { [id: number]: Rating };
  stats: {
    [K in StatsKeys]: { [id: number]: RatingStats };
  };
}

export interface RatingState {
  loading: boolean;
  payload: RatingPayload;
  error: string | null;
}

const getInitialState = (): RatingState => ({
  loading: false,
  payload: {
    ratingById: getRatingCache().ratingById || {},
    stats: getRatingCache().stats || {
      bySalonId: {},
      byHairdresserId: {},
    },
  },
  error: null,
});

const fetchStatsReducer = (
  state: RatingState,
  action:
    | FetchSalonRatingsStatsSuccessAction
    | FetchHairdresserRatingsStatsSuccessAction,
  key: StatsKeys
) => {
  const newState = {
    ...state,
    loading: false,
    payload: {
      ...state.payload,
      stats: {
        ...state.payload.stats,
        [key]: {
          ...state.payload.stats[key],
          ...action.payload,
        },
      },
    },
    error: null,
  };
  localStorage.setItem("rating", JSON.stringify(newState.payload));
  return newState;
};

const salon: Reducer<RatingState, RatingActions | ResetAction> = (
  state = getInitialState(),
  action
) => {
  let newState;
  switch (action.type) {
    case types.FETCH_SALON_RATINGS:
    case types.REPLY_TO_RATING:
    case types.FETCH_HAIRDRESSER_RATINGS:
    case types.FETCH_HAIRDRESSER_RATINGS_STATS:
    case types.FETCH_SALON_RATINGS_STATS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.FETCH_RATINGS_SUCCESS:
      newState = {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ratingById: {
            ...state.payload.ratingById,
            ...action.payload,
          },
        },
        error: null,
      };
      localStorage.setItem("rating", JSON.stringify(newState.payload));
      return newState;

    case types.FETCH_SALON_RATINGS_STATS_SUCCESS:
      return fetchStatsReducer(state, action, "bySalonId");

    case types.FETCH_HAIRDRESSER_RATINGS_STATS_SUCCESS:
      return fetchStatsReducer(state, action, "byHairdresserId");

    case types.REPLY_SUCCESS:
      newState = {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ratingById: {
            ...state.payload.ratingById,
            [action.payload.ratingId]: {
              ...state.payload.ratingById[action.payload.ratingId],
              replies: [
                ...(state.payload.ratingById[action.payload.ratingId]
                  ? state.payload.ratingById[action.payload.ratingId].replies
                  : []),
                action.payload.reply,
              ],
            },
          },
        },
        error: null,
      };
      localStorage.setItem("rating", JSON.stringify(newState.payload));
      return newState;

    case types.FETCH_RATINGS_FAILURE:
    case types.REPLY_FAILURE:
    case types.FETCH_HAIRDRESSER_RATINGS_STATS_FAILURE:
    case types.FETCH_SALON_RATINGS_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.LOGOUT:
    case types.RESET:
      localStorage.setItem("rating", "");
      return getInitialState();
    default:
      return state;
  }
};

export default salon;
