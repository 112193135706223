import React from "react";
import { useWheelPickerContext } from ".";

interface InputProps {
  render(props: { value: string; onClick(): void }): React.ReactNode;
}

const Input: React.FC<InputProps> = props => {
  const { render } = props;
  const {
    isWheelsOpened,
    toggleIsWheelsOpened,
    inputValue,
    floatingWheels: floating
  } = useWheelPickerContext();

  return (
    <div
      className={`wheel-picker_input-container ${
        floating && isWheelsOpened ? "floating" : ""
      }`}
    >
      {render &&
        render({
          value: inputValue as string,
          onClick: toggleIsWheelsOpened as () => {}
        })}
    </div>
  );
};
export { Input };
