import { Dispatch } from "redux";

import * as API from "api/lastMinute";
import * as types from "constants/ActionTypes";
import { MESSAGE_TYPES } from "constants/SnackBar";
import { LastMinute, LastMinutePayload } from "reducers/lastMinute";
import { errorToString } from "utils/string";
import { pushMessage } from "./SnackbarActions";

interface FetchLastMinutesAction {
  type: typeof types.FETCH_LAST_MINUTES;
}

export interface FetchLastMinutesSuccessAction {
  type: typeof types.FETCH_LAST_MINUTES_SUCCESS;
  payload: LastMinutePayload;
}

interface FetchLastMinutesFailureAction {
  type: typeof types.FETCH_LAST_MINUTES_FAILURE;
  error: string;
}

export type LastMinuteActions =
  | FetchLastMinutesAction
  | FetchLastMinutesSuccessAction
  | FetchLastMinutesFailureAction;

export const fetchSuccess = (data: LastMinutePayload) => ({
  type: types.FETCH_LAST_MINUTES_SUCCESS,
  payload: { ...data },
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_LAST_MINUTES_FAILURE,
  error,
});

export const fetchLastMinutesBySalonId = (salonId: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: types.FETCH_LAST_MINUTES });
  return API.fetchBySalonId(salonId)
    .then(({ lastMinutes }: { lastMinutes: Array<LastMinute> }) =>
      dispatch(fetchSuccess({ lastMinutes }))
    )
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
