import React from "react";
import { TEALISH } from "../../constants/Style";

const CameraIconLarge = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={27} height={21} {...props}>
    <g fill={props.color || TEALISH} fillRule="evenodd">
      <path d="M2.563 2.47h6.315l.183-1.146C9.153.529 9.793 0 10.617 0h5.858c.823 0 1.464.53 1.556 1.324l.091 1.147h6.407C25.902 2.47 27 3.618 27 4.94V18.53C27 19.853 25.902 21 24.529 21H2.563C1.098 21 0 19.853 0 18.53V4.94c0-1.323 1.098-2.47 2.563-2.47zm10.983 2.383c3.935 0 7.139 3.088 7.139 6.882 0 3.794-3.204 6.883-7.14 6.883-3.935 0-7.23-3.089-7.23-6.883s3.295-6.882 7.23-6.882z" />
      <path d="M13.53 8C15.444 8 17 9.615 17 11.53A3.473 3.473 0 0113.53 15C11.556 15 10 13.444 10 11.53A3.536 3.536 0 0113.53 8z" />
    </g>
  </svg>
);

export default CameraIconLarge;
