import React from "react";
import Button from "../../button/Desktop/Button.desktop";
import PlusIcon from "../../svgs/PlusIcon";
import FiltersIcon from "../../svgs/FiltersIcon";
import CalendarDesktopDatePicker from "../../Calendar/DatePickers/CalendarDatePicker.desktop";
import * as Calendar from "../../../constants/Calendar";
import "./toolbar.scss";
import ModeToggle from "../../Calendar/ModeToggle/ModeToggle";
import PageToolbarDesktop from "../../PageToolbar/PageToolbar.desktop";
import { isIFrame } from "utils/helpers";

export interface IAgendaToolbarProps {
  mode: keyof typeof Calendar.VIEWS;
  date: string;
  onChangeDay(event: React.ChangeEvent<HTMLInputElement>): void;
  onSelectMode(mode: keyof typeof Calendar.VIEWS): void;
  onOpenFilters(): void;
  onCreateSlot(): void;
}

function AgendaToolbar({
  mode,
  date,
  onChangeDay,
  onSelectMode,
  onOpenFilters,
  onCreateSlot,
}: IAgendaToolbarProps) {
  return (
    <PageToolbarDesktop>
      <div className="agenda-toolbar-slots-buttons">
        <Button
          primary
          text="Ajouter une disponibilité"
          icon={PlusIcon}
          onClick={() => onCreateSlot()}
        />
        {/* <Button text="Dupliquer les disponibilités" /> */}
      </div>
      <div>
        <CalendarDesktopDatePicker
          mode={mode}
          value={date}
          onChange={onChangeDay}
        />
      </div>
      <div className="agenda-toolbar-filters-group">
        <Button text="Filtres" icon={FiltersIcon} onClick={onOpenFilters} />
        <ModeToggle selectedMode={mode} selectMode={onSelectMode} />
      </div>
    </PageToolbarDesktop>
  );
}

export default AgendaToolbar;
