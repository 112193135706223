import React, { useState, useEffect, useRef } from "react";
import Collapse from "@material-ui/core/Collapse";
import { useToggle } from "react-use";
import { useHistory } from "react-router-dom";

import { ACCOUNT_SUBLINKS } from "constants/Menu";
import useResponsiveLayout from "components/ResponsiveLayout/useResponsiveLayout";
import HairdresserMenuItem from "components/account/HairdresserMenuItem/HairdresserMenuItem";
import Mobile from "components/ResponsiveLayout/Mobile";
import MobilePageContainer from "components/page/Container.mobile";
import ModalLayoutMobile from "components/modal/Layout/Layout.mobile";
import MobilePageHeader from "components/page/Header.mobile";
import PreviousIcon from "components/svgs/PreviousIcon";
import PlusIcon from "components/svgs/PlusIcon";
import MobileModalContainer from "components/modal/Container/Container.mobile_old";
import HairdresserForm from "components/account/HairdresserForm/HairdresserForm";
import Desktop from "components/ResponsiveLayout/Desktop";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "components/layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import CloseIcon from "components/svgs/CloseIcon";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import AddIconWithCircle from "components/svgs/AddIconWithCircle";
import useCurrentSalon from "hooks/useCurrentSalon";
import { Hairdresser } from "reducers/salon";
import styles from "./styles.module.scss";
import { isIFrame } from "utils/helpers";
import { Card, Select, MenuItem } from "@material-ui/core";
import IFrameContainer from "components/iframe/IFrameContainer";

function AccountHairdressersPage() {
  const history = useHistory();
  const [hairdresserToEdit, setHairdresserToEdit] = useState<
    Hairdresser | undefined
  >(undefined);

  const { hairdressers, salon } = useCurrentSalon();
  const onClose = history.goBack;

  const { isDesktop } = useResponsiveLayout();
  useEffect(() => {
    if (isDesktop || isIFrame()) {
      setHairdresserToEdit(hairdressers[0]);
    } else {
      setHairdresserToEdit(undefined);
    }
  }, [isDesktop, salon.id]);
  return (
    <AccountHairdressersPageTemplate
      hairdressers={hairdressers}
      hairdresserToEdit={hairdresserToEdit}
      setHairdresserToEdit={setHairdresserToEdit}
      onClose={onClose}
    />
  );
}

interface AccountHairdressersPageProps {
  hairdressers: Array<Hairdresser>;
  hairdresserToEdit: Hairdresser | undefined;
  setHairdresserToEdit(hairdresser: Hairdresser | undefined): void;
  onClose(): void;
}

const vowels = ["a", "e", "i", "o", "u", "y"];

const DropDown = ({
  enabledHairdressers,
  hairdresserToEdit,
  editHairdresser,
}: {
  enabledHairdressers: Array<Hairdresser>;
  hairdresserToEdit?: Hairdresser;
  editHairdresser: (hairdresser: Hairdresser) => void;
}) => (
  <Select
    disableUnderline
    value={hairdresserToEdit && hairdresserToEdit.id}
    classes={{ root: styles.selectRoot }}
    IconComponent={(): JSX.Element => <></>}
    defaultValue={enabledHairdressers[0]}
    renderValue={(): JSX.Element => (
      <HairdresserMenuItem
        key={hairdresserToEdit?.id || hairdresserToEdit?.stringId}
        hairdresser={hairdresserToEdit || enabledHairdressers[0]}
        onClick={() => {}}
        isSelected={hairdresserToEdit === hairdresserToEdit}
      />
    )}
  >
    {enabledHairdressers.map((hairdresser) => (
      <MenuItem
        value={hairdresser.id || hairdresser.stringId}
        key={hairdresser.id || hairdresser.stringId}
      >
        <HairdresserMenuItem
          key={hairdresser.id || hairdresser.stringId}
          hairdresser={hairdresser}
          onClick={() => editHairdresser(hairdresser)}
          isSelected={hairdresser === hairdresserToEdit}
          inSelect={true}
        />
      </MenuItem>
    ))}
  </Select>
);

export function AccountHairdressersPageTemplate({
  hairdressers,
  hairdresserToEdit,
  setHairdresserToEdit,
  onClose,
}: AccountHairdressersPageProps) {
  const { isMobile, isDesktop } = useResponsiveLayout();
  const [isHairdresserFormOpened, setIsHairdresserFormOpened] = useState(false);

  const enabledHairdressers = hairdressers.filter(
    (h) => h.status === "ENABLED"
  );
  const disabledHairdressers = hairdressers.filter(
    (h) => h.status === "DISABLED"
  );

  const [showDisabled, toggleShowDisabled] = useToggle(false);
  const showDisabledTitle = showDisabled
    ? `▲ Cacher les professionnels désactivés`
    : `▼ Afficher les professionnels désactivés`;

  const editHairdresser = (hairdresser: Hairdresser | undefined) => {
    if (hairdresser?.status === "DISABLED" && !showDisabled) {
      toggleShowDisabled();
    }

    setHairdresserToEdit(hairdresser);
    isMobile && setIsHairdresserFormOpened(true);
  };

  const hairdresserFormMode = hairdresserToEdit ? "UPDATE" : "CREATE";

  const onHairdresserCreated = (hairdresser: Hairdresser) => {
    setIsHairdresserFormOpened(false);
    editHairdresser(hairdresser);
  };

  const hairdressersSelectionList = (
    <div className={styles.listContainer}>
      <div>
        {isIFrame() && isMobile ? (
          <DropDown
            enabledHairdressers={enabledHairdressers}
            hairdresserToEdit={hairdresserToEdit}
            editHairdresser={editHairdresser}
          />
        ) : (
          enabledHairdressers.map((hairdresser) => (
            <HairdresserMenuItem
              key={hairdresser.id || hairdresser.stringId}
              hairdresser={hairdresser}
              onClick={() => editHairdresser(hairdresser)}
              isSelected={hairdresser === hairdresserToEdit}
            />
          ))
        )}
      </div>
      {disabledHairdressers.length > 0 && (
        <>
          <div onClick={toggleShowDisabled} className={styles.toggleDisabled}>
            {showDisabledTitle}
          </div>
          <Collapse in={showDisabled}>
            {disabledHairdressers.map((hairdresser) => (
              <HairdresserMenuItem
                key={hairdresser.id || hairdresser.stringId}
                hairdresser={hairdresser}
                onClick={() => editHairdresser(hairdresser)}
                isSelected={hairdresser === hairdresserToEdit}
              />
            ))}
          </Collapse>
        </>
      )}
      {isDesktop && (
        <div className={styles.addButtonContainer}>
          <span onClick={() => setHairdresserToEdit(undefined)}>
            <AddIconWithCircle /> Ajouter un professionnel
          </span>
        </div>
      )}
      {isIFrame() && isMobile && hairdresserToEdit && (
        <Card style={{ padding: "15px", margin: "10px" }}>
          <HairdresserForm
            hairdresser={hairdresserToEdit}
            onHairdresserCreated={onHairdresserCreated}
            mode={hairdresserFormMode}
          />
        </Card>
      )}
    </div>
  );

  const hairdresserFormContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hairdresserFormContainerRef.current) {
      hairdresserFormContainerRef.current.scrollTop = 0;
    }
  }, [hairdresserToEdit]);

  const hairdresserForm = (
    <HairdresserForm
      hairdresser={hairdresserToEdit}
      onHairdresserCreated={onHairdresserCreated}
      mode={hairdresserFormMode}
    />
  );

  const hairdresserFormMobilePageTitle =
    hairdresserFormMode === "UPDATE"
      ? `Modifier le profil ${
          vowels.includes(
            (hairdresserToEdit?.firstName || [""])[0].toLowerCase()
          )
            ? "d'"
            : "de "
        }${hairdresserToEdit?.firstName || ""}`
      : "Ajouter un professionnel";
  return (
    <>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={
                  isIFrame() ? undefined : <PreviousIcon onClick={onClose} />
                }
                center="Les professionnels"
                right={
                  <PlusIcon
                    height={21}
                    onClick={() => {
                      setIsHairdresserFormOpened(true);
                      setHairdresserToEdit(undefined);
                    }}
                  />
                }
                parent="account"
              />
            }
            body={hairdressersSelectionList}
          />
        </MobilePageContainer>
        {(!isIFrame() || hairdresserFormMode === "CREATE") && (
          <MobileModalContainer open={isHairdresserFormOpened} direction="up">
            <ModalLayoutMobile
              header={
                <MobilePageHeader
                  left={
                    <CloseIcon
                      onClick={() => {
                        setIsHairdresserFormOpened(false);
                        setHairdresserToEdit(undefined);
                      }}
                    />
                  }
                  center={hairdresserFormMobilePageTitle}
                  parent="account"
                />
              }
              body={hairdresserForm}
            />
          </MobileModalContainer>
        )}
      </Mobile>
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
          >
            <div className={styles.container} style={{ gridArea: "content" }}>
              {hairdressersSelectionList}
              <div
                className={styles.hairdresserForm}
                ref={hairdresserFormContainerRef}
              >
                {hairdresserForm}
              </div>
            </div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
    </>
  );
}

export default AccountHairdressersPage;
