import MobileHeader from "./Header.mobile";

export interface ModalHeaderProps {
  onClose(): void;
  closeIconVariant?: "previous" | "default";
  title?: React.ReactNode;
  parent?: string;
}

export default MobileHeader;
