import React, { createContext, useEffect, useContext } from "react";
import {
  onReactNativeMessageType,
  REACT_NATIVE_MESSAGE_TYPES,
  getReactNativeMessageHandler,
} from "utils/reactNative";

const ReactNativeAppContext = createContext({
  isReactNativeApp: false,
});

export function ReactNativeAppSyncProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  /**
   * The App Pro Wrapper webview injects this object on the window
   */
  const reactNativeWebView = (window as any).ReactNativeWebView;
  const isReactNativeApp = Boolean(reactNativeWebView);
  /**
   * Sends the app version to the ReactNative App
   * The ReactNative App is configured to track the website version and reload
   * when a new version is available
   * TODO : better to pass a onReload callback to manipulate the webview ?
   * if not possible, let's have as well a RELOAD message so it easy to
   * reload the webview imperatively
   */
  const postVersionToReactNativeWrapper = () => {
    const version = process.env.REACT_APP_VERSION;
    reactNativeWebView &&
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ version })
      );
  };

  const onReactNativeMessage: onReactNativeMessageType = (message) => {
    // const { snackbarActionsDispatcher } = props;
    switch (message.type) {
      case REACT_NATIVE_MESSAGE_TYPES.GO_BACK:
        //TODO
        // snackbarActionsDispatcher.pushMessage(
        //   "GO BACK FROM REACT NATIVE !",
        //   MESSAGE_TYPES.INFO
        // );
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const ReactNativeMessageHandler = getReactNativeMessageHandler(
      onReactNativeMessage
    );

    (window as any).ReactNativeMessageHandler = ReactNativeMessageHandler;

    return () => {
      (window as any).ReactNativeMessageHandler = undefined;
    };
  }, []);

  /**
   * It would be interesting to give a way to register on messages callback :
   * addMessageListener
   * removeMessageListener
   */
  return (
    <ReactNativeAppContext.Provider
      value={{
        isReactNativeApp,
      }}
    >
      {children}
    </ReactNativeAppContext.Provider>
  );
}

export function useReactNativeAppContext() {
  const context = useContext(ReactNativeAppContext);

  if (!context) {
    throw new Error(
      `ReactNativeAppContext must be used inside a ReactNativeAppContext.Provider`
    );
  }

  return context;
}
