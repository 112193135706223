import React from "react";
import {
  Prestation,
  priceFormatter,
  getCommissionPercentage,
  getPriceCommission,
  getIsOldBooking,
} from "utils/payments";
import moment from "moment";
import { capitalize } from "utils/string";
import MobileBottomDrawer from "../PaymentListPage/MobileBottomDrawer/MobileBottomDrawer";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import ModalContainer from "components/modal/Container/Container.desktop";
import ModalLayoutDesktop from "components/modal/Layout/Layout.desktop";
import styles from "./PrestationDetails.module.scss";
import { DataList, ListItem } from "components/uiElements/DataList/DataList";

interface PrestationDetailsProps {
  open: boolean;
  prestation: Prestation;
  onClose(): void;
}

const getCommissionText = (prestation: Prestation) => {
  const isOldBooking = getIsOldBooking(prestation.booking);
  const isNewClient = prestation?.booking?.commissionConditions?.isNewClient;
  const isPaymentInSalon = prestation?.booking?.paymentType === "IN_SALON";

  console.log(prestation?.booking);
  if (isOldBooking) {
    return (
      <span>
        Le montant net correspond à votre chiffre d'affaires TTC (le prix de la
        prestation) moins notre commission de{" "}
        {getCommissionPercentage(prestation.booking) * 100}% TTC
      </span>
    );
  }

  if (isNewClient) {
    return (
      <span>
        {`Le montant net correspond à votre chiffre d'affaires TTC (le prix de la
        prestation) moins notre commission “Nouveau client” de ${
          prestation?.booking?.snapshotFees?.commission / 100
        }% HT
        ${
          isPaymentInSalon ? "" : " et les frais de paiements (1,8% + 0,18€ HT)"
        }`}
      </span>
    );
  }
  return (
    <span>
      {`Le montant net correspond à votre chiffre d'affaires TTC (le prix de la prestation)
      ${
        isPaymentInSalon
          ? ""
          : " moins les frais de paiements (1,8% + 0,18€ HT)"
      }`}
    </span>
  );
};

const PrestationDetails: React.FC<PrestationDetailsProps> = (props) => {
  const { open, prestation, onClose } = props;
  const fullPrice =
    prestation && prestation.booking.snapshotPackages[0].price.price;
  const discountedPrice = prestation.price;
  const priceAfterCommission = getPriceCommission(prestation);
  const isOldBooking = getIsOldBooking(prestation.booking);

  const detailsList: ListItem[] = prestation
    ? [
        {
          id: "date",
          label: "Date",
          value: capitalize(moment(prestation.date).format("dddd D MMM YYYY")),
        },
        {
          id: "time",
          label: "Heure",
          value: `${moment(prestation.booking.dateStart).format(
            "H[h]mm"
          )} - ${moment(prestation.booking.dateEnd).format("H[h]mm")}`,
        },
        {
          id: "reference",
          label: "Référence de validation",
          value: prestation.id,
        },
        {
          id: "price-paid-by-client",
          label: "Prix de la prestation payé par le client",
          value: `${priceFormatter.format(discountedPrice)}€ TTC`,
        },
        {
          id: "payout-after-commission",
          label:
            "Montant versé après commission" +
            (isOldBooking ? "" : " et frais de paiement"),
          value: (
            <span className={styles.priceReduced}>
              {priceFormatter.format(priceAfterCommission)}€ TTC
            </span>
          ),
        },
      ]
    : [];

  const content = prestation && (
    <div role="presentation" className={styles.container}>
      <div className={styles.priceRow}>
        <span className={styles.clientName}>{prestation.clientName}</span>
        <div>
          <span className={styles.priceFull}>
            {priceFormatter.format(fullPrice)}€
          </span>
          <span className={styles.priceReduced}>
            {priceFormatter.format(discountedPrice)}€ TTC
          </span>
        </div>
      </div>
      <div className={styles.descriptionRow}>{prestation.prestationName}</div>
      <div className={styles.details}>Détails</div>
      <DataList
        list={detailsList}
        classes={{ listContainer: styles.detailsList }}
      />
      <div className={styles.infoMessage}>{getCommissionText(prestation)}</div>
    </div>
  );

  return (
    <>
      <Mobile>
        <MobileBottomDrawer open={open} onClose={onClose}>
          {content}
        </MobileBottomDrawer>
      </Mobile>
      <Desktop>
        <ModalContainer open={open}>
          <ModalLayoutDesktop onClose={onClose} body={content} />
        </ModalContainer>
      </Desktop>
    </>
  );
};

export default PrestationDetails;
