import * as types from "../constants/ActionTypes";
import {
  createRatingReply,
  fetchRatingsBySalonId,
  fetchRatingsByHairdresserId,
  fetchSalonRatingsStats as fetchSalonRatingsStatsFromServer,
  fetchHairdresserRatingsStats as fetchHairdresserRatingsStatsFromServer,
} from "../utils/ratings";
import { errorToString } from "../utils/string";
import { RatingCommentReply, Rating } from "../reducers/rating";
import { Dispatch } from "redux";
import { AppState } from "../reducers";
import { pushMessage } from "./SnackbarActions";
import { Salon, Hairdresser } from "../reducers/salon";
import { AuthLogoutAction } from "./AuthActions";
import { MESSAGE_TYPES } from "../constants/SnackBar";
import { IPaginationOptions } from "../utils/graphqlClient";

interface FetchSalonRatingsAction {
  type: typeof types.FETCH_SALON_RATINGS;
}

interface FetchHairdresserRatingsAction {
  type: typeof types.FETCH_HAIRDRESSER_RATINGS;
}

interface FetchRatingsSuccessAction {
  type: typeof types.FETCH_RATINGS_SUCCESS;
  payload: { [id: number]: Rating };
}

interface FetchRatingsFailureAction {
  type: typeof types.FETCH_RATINGS_FAILURE;
  error: string;
}

interface FetchSalonRatingsStatsAction {
  type: typeof types.FETCH_SALON_RATINGS_STATS;
}

export interface FetchSalonRatingsStatsSuccessAction {
  type: typeof types.FETCH_SALON_RATINGS_STATS_SUCCESS;
  payload: {
    [id: number]: {
      sum: number;
      count: number;
    };
  };
}

interface FetchSalonRatingsStatsFailureAction {
  type: typeof types.FETCH_SALON_RATINGS_STATS_FAILURE;
  error: string;
}

interface FetchHairdresserRatingsStatsAction {
  type: typeof types.FETCH_HAIRDRESSER_RATINGS_STATS;
}

export interface FetchHairdresserRatingsStatsSuccessAction {
  type: typeof types.FETCH_HAIRDRESSER_RATINGS_STATS_SUCCESS;
  payload: {
    [id: number]: {
      sum: number;
      count: number;
    };
  };
}

interface FetchHairdresserRatingsStatsFailureAction {
  type: typeof types.FETCH_HAIRDRESSER_RATINGS_STATS_FAILURE;
  error: string;
}

interface ReplyToRatingsAction {
  type: typeof types.REPLY_TO_RATING;
}

interface ReplySuccessAction {
  type: typeof types.REPLY_SUCCESS;
  payload: { ratingId: number; reply: RatingCommentReply };
}

interface ReplyFailureAction {
  type: typeof types.REPLY_FAILURE;
  error: string;
}

export type RatingActions =
  | FetchSalonRatingsAction
  | FetchHairdresserRatingsAction
  | FetchRatingsSuccessAction
  | FetchRatingsFailureAction
  | FetchSalonRatingsStatsAction
  | FetchSalonRatingsStatsSuccessAction
  | FetchSalonRatingsStatsFailureAction
  | FetchHairdresserRatingsStatsAction
  | FetchHairdresserRatingsStatsSuccessAction
  | FetchHairdresserRatingsStatsFailureAction
  | ReplyToRatingsAction
  | ReplySuccessAction
  | ReplyFailureAction
  | AuthLogoutAction;

export const fetchSuccess = (data: { [id: number]: Rating }) => ({
  type: types.FETCH_RATINGS_SUCCESS,
  payload: data,
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_RATINGS_FAILURE,
  error,
});

export const fetchSalonRatings = (
  salon: Salon,
  options: IPaginationOptions
) => (dispatch: Dispatch, getState: { (): AppState }): Promise<any> => {
  dispatch({ type: types.FETCH_SALON_RATINGS });
  return fetchRatingsBySalonId(salon.id, options)
    .then((ratings: { [id: number]: Rating }) => {
      dispatch(fetchSuccess(ratings));
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchSalonRatingsStats = (salon: Salon) => (
  dispatch: Dispatch,
  getState: { (): AppState }
) => {
  dispatch({ type: types.FETCH_SALON_RATINGS_STATS });
  return fetchSalonRatingsStatsFromServer(salon)
    .then((stats) =>
      dispatch({
        type: types.FETCH_SALON_RATINGS_STATS_SUCCESS,
        payload: stats,
      })
    )
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchHairdresserRatingsStats = (
  companyCode: string,
  hairdresser: Hairdresser
) => (dispatch: Dispatch, getState: { (): AppState }) => {
  dispatch({ type: types.FETCH_HAIRDRESSER_RATINGS_STATS });
  return fetchHairdresserRatingsStatsFromServer(companyCode, hairdresser)
    .then((stats) =>
      dispatch({
        type: types.FETCH_HAIRDRESSER_RATINGS_STATS_SUCCESS,
        payload: stats,
      })
    )
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const fetchHairdresserRatings = (
  companyCode: string,
  hairdresser: Hairdresser,
  options: IPaginationOptions
) => (dispatch: Dispatch, getState: { (): AppState }): Promise<any> => {
  dispatch({ type: types.FETCH_HAIRDRESSER_RATINGS });
  return fetchRatingsByHairdresserId(
    companyCode || "",
    hairdresser?.id,
    hairdresser?.stringId,
    options
  )
    .then((ratings: { [id: number]: Rating }) =>
      dispatch(fetchSuccess(ratings))
    )
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(fetchFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};

export const replySuccess = (data: {
  ratingId: number;
  reply: RatingCommentReply;
}) => ({
  type: types.REPLY_SUCCESS,
  payload: data,
});

export const replyFailure = (error: string) => ({
  type: types.REPLY_FAILURE,
  error,
});

export const replyToRating = (
  ratingId: number,
  reply: string,
  respondentId: number,
  role: string
) => (dispatch: Dispatch, getState: { (): AppState }): Promise<any> => {
  dispatch({ type: types.REPLY_TO_RATING });
  return createRatingReply(ratingId, reply, respondentId, role)
    .then((reply: RatingCommentReply) => {
      dispatch(replySuccess({ ratingId, reply }));
    })
    .then(() => getState)
    .catch((err: string) => {
      const error = errorToString(err);
      dispatch(replyFailure(error));
      dispatch(pushMessage(error, MESSAGE_TYPES.ERROR) as any);
      return Promise.reject(error);
    });
};
