import { parseRoute } from "../utils/route";
import { routeList } from "./Routes";

export const TURNOVER_PERIOD_TYPES = {
  SINGLE: "SINGLE",
  RANGE: "RANGE",
};

export const PAYOUT_LIMIT = 100;

export const PAYMENT_PAGES = [
  {
    title: "Liste des paiements",
    link: parseRoute(routeList.PAYMENT_LIST_PAGE),
  },
  {
    title: "Chiffre d'affaires par professionnel",
    link: parseRoute(routeList.PAYMENT_TURNOVER_PAGE),
  },
  {
    title: "Factures",
    link: parseRoute(routeList.PAYMENT_INVOICES_PAGE),
  },
  {
    title: "Informations de facturation",
    link: parseRoute(routeList.PAYMENT_INFORMATIONS_PAGE),
  },
];
