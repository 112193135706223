import { Reducer } from "redux";
import * as yup from "yup";

import {
  WeekActions,
  FetchWeeksSuccessAction,
  FetchAvailabilitiesSuccessAction,
} from "actions/WeekActions";
import * as types from "constants/ActionTypes";

export const daySchema = yup.object().shape({
  start: yup.string().required(),
  end: yup.string().required(),
  unavailabilities: yup.array().of(
    yup.object().shape({
      start: yup.string().required(),
      end: yup.string().required(),
    })
  ),
});

export const daysSchema = yup.object().shape({
  monday: daySchema.nullable(),
  tuesday: daySchema.nullable(),
  wednesday: daySchema.nullable(),
  thursday: daySchema.nullable(),
  friday: daySchema.nullable(),
  saturday: daySchema.nullable(),
  sunday: daySchema.nullable(),
});

export const weekSchema = yup.object().shape({
  id: yup.number().notRequired(),
  createdAt: yup.string().notRequired(),
  hairdresserIds: yup.array().of(yup.number()).required(),
  hairdresserStringIds: yup.array().of(yup.string()).required(),
  salonId: yup.number().required(),
  discount: yup.number().required(),
  duration: yup.number().required(),
  status: yup.string().required(),
  days: daysSchema.required(),
  // Used for happy hours form only
  start: yup.string().notRequired(),
  end: yup.string().notRequired(),
});
export type Week = yup.InferType<typeof weekSchema>;
export type Day = yup.InferType<typeof daySchema>;
export type Days = yup.InferType<typeof daysSchema>;

export const availabilitySchema = yup.object().shape({
  hairdresserId: yup.number().required(),
  hairdresserStringId: yup.string().required(),
  discount: yup.number().required(),
  range: yup.object().shape({
    start: yup.string().required(),
    end: yup.string().required(),
  }),
});
export type Availability = yup.InferType<typeof availabilitySchema>;

export interface WeekPayload {
  weeks?: Array<Week>;
  weeksByHairdresserId?: Record<number, Week>;
  availabilitiesByHairdresserId?: Record<number, Array<Availability>>;
}

interface WeekState {
  loading: boolean;
  error: string | null;
  payload: WeekPayload;
}

const getInitialState: { (): WeekState } = () => ({
  loading: false,
  error: null,
  payload: {
    weeks: [],
    weeksByHairdresserId: {},
    availabilitiesByHairdresserId: {},
  },
});

const fetchWeeksSuccessReducer = (
  state: WeekState,
  action: FetchWeeksSuccessAction
) => {
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      weeksByHairdresserId: {
        ...action.payload.weeksByHairdresserId,
      },
      weeks: [...action.payload.weeks],
    },
  };
};

const fetchAvailabilitiesSuccessReducer = (
  state: WeekState,
  action: FetchAvailabilitiesSuccessAction
) => {
  return {
    ...state,
    loading: false,
    error: null,
    payload: {
      ...state.payload,
      availabilitiesByHairdresserId: {
        ...action.payload.availabilitiesByHairdresserId,
      },
    },
  };
};

const week: Reducer<WeekState, WeekActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case types.FETCH_WEEKS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_WEEKS_SUCCESS:
      return fetchWeeksSuccessReducer(state, action);
    case types.FETCH_WEEKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.FETCH_AVAILABILITIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_AVAILABILITIES_SUCCESS:
      return fetchAvailabilitiesSuccessReducer(state, action);
    case types.FETCH_AVAILABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default week;
