import React from "react";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import styles from "./ModalContainer.module.scss";

export default function ModalContainer({
  children,
  open,
  ...otherProps
}: ModalProps) {
  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropProps={{
        className: styles.mobileBackdrop
      }}
      {...otherProps}
    >
      <div className={styles.outterContainer}>
        <div className={styles.mobileInnerContainer}>{children}</div>
      </div>
    </Modal>
  );
}
