import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import RemoveIconWithCircle from "../../svgs/RemoveIconWithCircle";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import AddIconWithCircle from "../../svgs/AddIconWithCircle";
import * as EmailValidator from "email-validator";
import Mobile from "../../ResponsiveLayout/Mobile";
import Desktop from "../../ResponsiveLayout/Desktop";
import styles from "./SettingsPage.module.scss";
import useResponsiveLayout from "../../ResponsiveLayout/useResponsiveLayout";
import classnames from "classnames";

interface MultipleEmailFormProps {
  title: string;
  emails: Array<string>;
  indexName: string;
  updateFormData(event: React.ChangeEvent<HTMLInputElement>): void;
  addItem(name: string, item: any): void;
  removeItemByIndex(name: string, index: number): void;
}

const MultipleEmailForm = ({
  title,
  emails,
  indexName,
  updateFormData,
  addItem,
  removeItemByIndex,
}: MultipleEmailFormProps) => {
  const [emailToAdd, setEmailToAdd] = useState("");
  const emailInputClassnames = classnames("input_input", styles.emailInput);

  const { isMobile, isDesktop } = useResponsiveLayout();

  return (
    <div className="input-group">
      <InputLabel shrink className="input_label">
        {title}
      </InputLabel>
      {emails.map((email, emailIndex) => (
        <div key={emailIndex} className="salon-form-access-container">
          <Mobile>
            <RemoveIconWithCircle
              onClick={() => removeItemByIndex(indexName, emailIndex)}
            />
          </Mobile>
          <Input
            name={`${indexName}.${emailIndex}`}
            className={emailInputClassnames}
            value={email}
            onChange={updateFormData}
            endAdornment={
              isDesktop ? (
                <RemoveIconWithCircle
                  className="clickable"
                  onClick={() => removeItemByIndex(indexName, emailIndex)}
                />
              ) : undefined
            }
          />
        </div>
      ))}
      <div className="salon-form-access-container">
        <Mobile>
          <AddIconWithCircle
            onClick={() => {
              if (EmailValidator.validate(emailToAdd)) {
                addItem(indexName, emailToAdd);
                setEmailToAdd("");
              }
            }}
          />
        </Mobile>
        <TextField
          name="name"
          className={classnames(emailInputClassnames, {
            [styles.addEmail]: isDesktop,
          })}
          variant={isDesktop ? "outlined" : ("standard" as any)}
          placeholder={isMobile ? "Ajouter un mail" : undefined}
          label={isDesktop ? "Ajouter un email" : undefined}
          value={emailToAdd}
          onChange={(event) => setEmailToAdd(event.target.value)}
        />
        <Desktop>
          <AddIconWithCircle
            onClick={() => {
              if (EmailValidator.validate(emailToAdd)) {
                addItem(indexName, emailToAdd);
                setEmailToAdd("");
              }
            }}
          />
        </Desktop>
      </div>
    </div>
  );
};

export default MultipleEmailForm;
