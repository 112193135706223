import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as bookingActions from "../../actions/BookingActions";
import BookingCancellationForm from "../../components/bookings/BookingCancellationForm/BookingCancellationForm";
import { mergeFormValues } from "../../utils/form";
import { Booking } from "../../reducers/booking";

export const cancellationReasonList = [
  "L'établissement est exceptionnellement fermé",
  "Après diagnostic, la prestation n'est pas réalisable",
  "Le client s'est trompé de forfait",
  "Le client n'a pas sélectionné la bonne longueur",
  "Le professionnel qui devait prendre en charge le client est absent / pas libre",
  "Le client est arrivé trop en retard",
  "Le client ne souhaite pas payer le supplément",
];

interface BookingCancellationFormContainerProps {
  booking: Booking;
  onClose(): void;
  onCanceled(): void;
  bookingActionsDispatcher: typeof bookingActions;
}

function BookingCancellationFormContainer({
  booking,
  onClose,
  bookingActionsDispatcher,
  onCanceled,
}: BookingCancellationFormContainerProps) {
  const [formValues, setFromValues] = useState({ cancellationReason: "" });

  const changeHandler = (event: React.ChangeEvent<any>) => {
    const name = event.target.name as "cancellationReason";
    const eventValue = event.target.value;
    const currentValue = formValues[name];
    const newValue = mergeFormValues(currentValue, eventValue);
    setFromValues((oldFormValues) => ({
      ...oldFormValues,
      [name]: newValue,
    }));
  };

  const cancelBooking = () => {
    return (bookingActionsDispatcher.cancelBooking(
      booking,
      formValues.cancellationReason
    ) as any).then(() => {
      onClose();
      onCanceled();
    });
  };

  return (
    <BookingCancellationForm
      reasonsList={cancellationReasonList}
      formValues={formValues}
      onChange={changeHandler}
      onSubmit={cancelBooking}
      onClose={onClose}
    />
  );
}

function mapDispatch(dispatch: Dispatch) {
  return {
    bookingActionsDispatcher: bindActionCreators(bookingActions, dispatch),
  };
}

export default connect(null, mapDispatch)(BookingCancellationFormContainer);
