import React, { useState, useEffect, memo } from "react";
import { Week } from "reducers/week";
import { Salon, Hairdresser } from "reducers/salon";
import Desktop from "components/ResponsiveLayout/Desktop";
import Mobile from "components/ResponsiveLayout/Mobile";
import MenuDesktop from "components/Menu/Desktop/Menu.desktop";
import DesktopLayoutWithSidebarLinks from "components/layouts/DesktopLayoutWithSidebarLinks/DesktopLayoutWithSidebarLinks";
import styles from "./styles.module.scss";
import { ACCOUNT_SUBLINKS } from "constants/Menu";
import { desktopMenuSublinkToSidebarLink } from "components/layouts/DesktopLayoutWithSidebarLinks/SidebarLinks/utils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../button/Desktop/Button.desktop";
import { updateWeek, createWeek as createWeekAPI } from "api/week";
import { useDispatch } from "react-redux";
import { pushMessage } from "actions/SnackbarActions";
import { MESSAGE_TYPES } from "constants/SnackBar";
import LargeClickable from "../../uiElements/LargeClickable";
import CloseIcon from "components/svgs/CloseIcon";
import PlusCircle from "components/svgs/PlusCircle";
import MobilePageContainer from "../../page/Container.mobile";
import ModalLayoutMobile from "../../modal/Layout/Layout.mobile";
import WeekForm from "./WeekForm";
import WeekDayList from "./WeekDayList";
import { createEmptyDays, createWeek, matchHairdresserWeek } from "utils/week";
import IFrameContainer from "components/iframe/IFrameContainer";
import MobilePageHeader from "../../page/Header.mobile";
import { isIFrame } from "utils/helpers";
import useCurrentSalon from "hooks/useCurrentSalon";

interface WeeksPageProps {
  salon: Salon;
  onClose(): void;
  isDesktop: boolean;
  hairdressers: Array<Hairdresser>;
  weeksByHairdresserId: Record<number, Week>;
  fetchCurrentWeeks(): void;
  fetchCurrentAvailabilities(): void;
}

const WeeksPage = ({
  salon,
  onClose,
  isDesktop,
  hairdressers,
  weeksByHairdresserId,
  fetchCurrentWeeks,
  fetchCurrentAvailabilities,
}: WeeksPageProps) => {
  const { companyCode } = useCurrentSalon();
  // When on this page, weeks are editable until clicking on the "Submit" button
  // So we make a copy
  const [weeksToSubmit, setWeeksToSubmit] = useState<
    Record<number, Week | null>
  >({});
  const [formOpened, setFormOpened] = useState(false);
  const [weekToEdit, setWeekToEdit] = useState(createWeek([0], [""], 0));
  const [duration, setDuration] = useState(8);
  const [isHairdresserSelectorOpen, setHairdresserSelectorOpen] = useState(
    false
  );
  const [hairdresserName, setHairdresserName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setWeeksToSubmit(weeksByHairdresserId);
    if (Object.values(weeksByHairdresserId).length > 0) {
      setDuration(Object.values(weeksByHairdresserId)[0].duration);
    }
  }, [weeksByHairdresserId]);

  const editWeek = (week: Week) => {
    setWeekToEdit(week);
    const hairdresser = matchHairdresserWeek(week, hairdressers);
    if (hairdresser) {
      setHairdresserName(`${hairdresser.firstName} ${hairdresser.lastName}`);
    }
    setFormOpened(true);
  };

  const saveWeek = (week: Week) => {
    setWeeksToSubmit({
      ...weeksToSubmit,
      [!week.hairdresserStringIds || !week.hairdresserStringIds.length
        ? week.hairdresserIds[0]
        : week.hairdresserStringIds[0]?.split("|")[1]]: week,
    });
  };

  const saveWeeks = () => {
    // DELETE and replace each week if needed
    Promise.all(
      Object.entries(weeksToSubmit).map(([hairdresserId, newWeek]) => {
        const found = weeksByHairdresserId[parseInt(hairdresserId)];
        if (newWeek === null && found) {
          return updateWeek({ ...found, status: "DELETED" });
        }

        if (found && newWeek) {
          return updateWeek({ ...found, status: "DELETED" }).then(() =>
            createWeekAPI({ ...newWeek, duration })
          );
        }

        return newWeek && createWeekAPI({ ...newWeek, duration });
      })
    ).then(() => {
      dispatch(pushMessage("Horaires sauvegardés", MESSAGE_TYPES.SUCCESS));
      fetchCurrentWeeks();
      fetchCurrentAvailabilities();
    });
  };

  const content = (
    <div className={styles.container}>
      <div>
        <p className={styles.lightBorder}>
          Définissez les règles de la réservation en ligne sur kiute.fr
        </p>
        <h3>RÉSERVATION À L'AVANCE</h3>
        <p className={styles.lightBorder}>
          Un client peut prendre rendez-vous jusqu'à
          <input
            type="number"
            step="1"
            min="0"
            value={duration - 1 > 0 ? duration - 1 : ""}
            onFocus={() => setDuration(0)}
            onChange={(event: any) =>
              setDuration(Number(event.target.value) + 1)
            }
            className={styles.dayInput}
          />
          jours à l'avance
        </p>
      </div>
      <div>
        <Dialog
          maxWidth={"sm"}
          fullWidth
          open={isHairdresserSelectorOpen}
          onClose={() => setHairdresserSelectorOpen(false)}
        >
          <DialogTitle>Ajouter un coiffeur</DialogTitle>
          <DialogContent>
            <LargeClickable
              className={styles.stickTopRight}
              onClick={() => setHairdresserSelectorOpen(false)}
            >
              <CloseIcon
                onClick={() => setHairdresserSelectorOpen(false)}
                color={"#000000"}
              />
            </LargeClickable>
            {hairdressers.map(
              (hairdresser) =>
                !weeksByHairdresserId[
                  hairdresser.id || Number(hairdresser.stringId)
                ] && (
                  <Button
                    onClick={() => {
                      saveWeek({
                        hairdresserIds: [
                          ...(hairdresser.id ? [hairdresser.id] : []),
                        ],
                        hairdresserStringIds: [
                          ...(!hairdresser.id &&
                          hairdresser.stringId &&
                          companyCode
                            ? [`${companyCode}|${hairdresser.stringId}`]
                            : []),
                        ],
                        duration,
                        salonId: salon.id,
                        status: "ENABLED",
                        discount: 0,
                        days: createEmptyDays(),
                      });
                      setHairdresserSelectorOpen(false);
                    }}
                    text={`${hairdresser.firstName} ${hairdresser.lastName}`}
                    className={styles.hairdresserNamePopin}
                    style={{
                      color: hairdresser.color ? "white" : "darkgrey",
                      backgroundColor: hairdresser.color || "white",
                      borderColor: hairdresser.color || "white",
                    }}
                  />
                )
            )}
          </DialogContent>
        </Dialog>
        <h3>HORAIRES D'OUVERTURE PAR COLLABORATEUR</h3>
        <br />
        <div className={styles.hButtons}>
          <PlusCircle
            className={styles.plusButton}
            onClick={() => setHairdresserSelectorOpen(true)}
          />
          {Object.values(weeksToSubmit).map(
            (weekToSubmit) =>
              weekToSubmit && (
                <Button
                  className={styles.hairdresserBtn}
                  style={{
                    backgroundColor:
                      matchHairdresserWeek(weekToSubmit, hairdressers)?.color ||
                      "darkgrey",
                    borderColor:
                      matchHairdresserWeek(weekToSubmit, hairdressers)?.color ||
                      "darkgrey",
                  }}
                  onClick={() =>
                    setWeeksToSubmit({
                      ...weeksToSubmit,
                      ...(weekToSubmit.hairdresserIds[0]
                        ? { [weekToSubmit.hairdresserIds[0]]: null }
                        : {}),
                      ...(weekToSubmit.hairdresserStringIds[0]
                        ? {
                            [weekToSubmit.hairdresserStringIds[0].split(
                              "|"
                            )[1]]: null,
                          }
                        : {}),
                    })
                  }
                  text={`${
                    matchHairdresserWeek(weekToSubmit, hairdressers)
                      ?.firstName || ""
                  } ${
                    matchHairdresserWeek(weekToSubmit, hairdressers)
                      ?.lastName || ""
                  } x`}
                />
              )
          )}
        </div>
        <br />
        <WeekDayList
          hairdressers={hairdressers}
          weeksByHairdresserId={weeksToSubmit}
          editWeek={editWeek}
        />
      </div>
      <Button
        secondary
        style={{
          margin: "30px auto",
        }}
        className={styles.saveButton}
        onClick={() => saveWeeks()}
        text="Enregistrer les modifications"
      />
    </div>
  );

  return (
    <div>
      <WeekForm
        week={weekToEdit}
        saveWeek={saveWeek}
        isOpened={formOpened}
        close={() => setFormOpened(false)}
        hairdresserName={hairdresserName}
      />
      <Desktop>
        <IFrameContainer>
          <MenuDesktop />
          <DesktopLayoutWithSidebarLinks
            links={ACCOUNT_SUBLINKS.map(desktopMenuSublinkToSidebarLink)}
          >
            <div className={styles.mainContainer}>
              <h2>Réservation en ligne</h2>
              {content}
            </div>
          </DesktopLayoutWithSidebarLinks>
        </IFrameContainer>
      </Desktop>
      <Mobile>
        <MobilePageContainer>
          <ModalLayoutMobile
            header={
              <MobilePageHeader
                left={isIFrame() ? undefined : <CloseIcon onClick={onClose} />}
                center="Réservation en ligne"
                parent="account"
              />
            }
            body={content}
          />
        </MobilePageContainer>
      </Mobile>
    </div>
  );
};

export default memo(WeeksPage);
