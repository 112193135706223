import { Price, PartnerRef, Hairdresser, Package } from "reducers/salon";

const getErrorsInPackage = (prices: Price[]) => {
  const nbErrorPrice = prices.filter((price) => {
    if (Number(price.price) === 0 || price.duration === "0") {
      return true;
    }

    return false;
  }).length;

  const variants = prices.reduce((variants: any, price: Price) => {
    if (price.variant && price.variant.name === "undefined") {
      return { ...variants, empty: 2 };
    }
    const variant = price.variant ? price.variant.name : "any";

    return { ...variants, [variant]: 1 + (variants[variant] || 0) };
  }, {});
  const nbErrorVariant = Object.keys(variants).filter(
    (key: string) => variants[key] > 1
  ).length;

  const hasAnyLength = prices.find((price: Price) => !price.variant);
  const hasMoreThanAnyLength = hasAnyLength && prices.length > 1;

  return {
    price: nbErrorPrice,
    variant: nbErrorVariant,
    anyLength: !!hasMoreThanAnyLength,
  };
};

const getPartnerRefsAsKeyValue = (partnerRefs: Array<PartnerRef>) =>
  partnerRefs.reduce((acc, ref: PartnerRef) => {
    return {
      ...acc,
      [ref.name || ""]: {
        name: ref.name,
        id: ref.id,
      },
    };
  }, {});

const getNewPartnerRefList = (
  partnerRefList: Array<PartnerRef>,
  name: string,
  id: string,
  noFilter?: boolean
) => {
  const oldPartnerRefs = getPartnerRefsAsKeyValue(partnerRefList);
  const newList = Object.values({
    ...oldPartnerRefs,
    [name]: {
      name,
      id,
    },
  }) as Array<PartnerRef>;

  return noFilter
    ? newList
    : // Remove the empty ids from partnerRef
      newList.filter((item: PartnerRef) => item.id !== "");
};

const doesHairdresserDoVariant = (
  serviceIds: Array<string>,
  prices: Array<Price>
) =>
  prices.find(
    (price) =>
      price.partnerRefs &&
      serviceIds?.indexOf(
        price.partnerRefs.find((pr: any) => pr.name === "flexy")?.id || ""
      ) !== -1
  );

const getAssignedPackageList = (
  hairdresser: Hairdresser,
  packages: Array<Package>
) =>
  packages.filter((pack) =>
    doesHairdresserDoVariant(hairdresser.serviceIds, pack.prices)
  );

export {
  getErrorsInPackage,
  getPartnerRefsAsKeyValue,
  getNewPartnerRefList,
  doesHairdresserDoVariant,
  getAssignedPackageList,
};
