import React, { useState } from "react";
import ModalHeader from "../modal/Header/Header";
import ResponsiveModalContent from "../modal/Content/ResponsiveContent";
import ModalLayoutMobile from "../modal/Layout/Layout.mobile";
import Collapse from "@material-ui/core/Collapse";
import Switch from "../inputs/Switch";
import Grid from "@material-ui/core/Grid";
import { CalendarFormat } from "../../constants/Date";
import ButtonFab from "../button/Fab";
import { Period } from "../../utils/payments";
import moment from "moment";
import { dateInputFormat } from "../../constants/Input";
import { BLUE_GREY, WHITE } from "../../constants/Style";
import { TURNOVER_PERIOD_TYPES } from "../../constants/Payment";
import DatePickerMobile from "../inputs/DatePicker/DatePicker.mobile";
import NextIcon from "../svgs/NextIcon";

interface TurnoverPeriodFormProps {
  onClose(): void;
  period: Period;
  onChange(perion: Period): void;
}

function TurnoverPeriodForm(props: TurnoverPeriodFormProps) {
  const { onClose, period, onChange } = props;
  const [localPeriod, setlocalPeriod] = useState(period);
  const isTypeRange = localPeriod.type === TURNOVER_PERIOD_TYPES.RANGE;
  const submit = () => {
    onChange(localPeriod);
    onClose();
  };
  return (
    <ModalLayoutMobile
      header={<ModalHeader onClose={onClose} title="Choisir la période" />}
      body={
        <React.Fragment>
          <ResponsiveModalContent dense>
            <div className="input-group">
              <DatePickerMobile
                noDay
                noYear
                name="periodMonth"
                value={moment()
                  .month(localPeriod.month)
                  .format(dateInputFormat)}
                onChange={e =>
                  setlocalPeriod({
                    ...localPeriod,
                    month: moment(e.target.value).month()
                  })
                }
                renderValues={values =>
                  moment()
                    .month(parseInt(values.month || "1") - 1)
                    .format("MMMM")
                }
                disabled={isTypeRange}
                renderInput={({ value, onClick }) => (
                  <div
                    className={`ui-space_between-line ${
                      isTypeRange ? "disabled" : ""
                    }`}
                  >
                    <span>Choisir un mois</span>
                    <div
                      onClick={onClick}
                      className="dense-list_item-right_container-mobile"
                    >
                      <span>{value}</span>
                      <NextIcon color={isTypeRange ? WHITE : BLUE_GREY} />
                    </div>
                  </div>
                )}
              />
            </div>
          </ResponsiveModalContent>
          <div className="payment_turnover-period_form-extra-text-mobile">
            <span>ou</span>
          </div>
          <ResponsiveModalContent dense>
            <React.Fragment>
              <div className="ui-space_between-line">
                <span>Choisir une période</span>
                <Switch
                  name="typeRange"
                  value={isTypeRange}
                  onChange={() =>
                    setlocalPeriod({
                      ...localPeriod,
                      type: isTypeRange
                        ? TURNOVER_PERIOD_TYPES.SINGLE
                        : TURNOVER_PERIOD_TYPES.RANGE
                    })
                  }
                />
              </div>
              <Collapse in={isTypeRange}>
                <div className="wide-list-underline" />
                <Grid
                  container
                  wrap="nowrap"
                  className="input-group input-group-inline payment_turnover-period_date_select_group-mobile"
                >
                  <div className="input-group input-group-inline-item-grow">
                    <DatePickerMobile
                      floatingWheels
                      label="Date de début"
                      name="date"
                      calendarFormat={CalendarFormat}
                      value={moment(localPeriod.range.from).format(
                        dateInputFormat
                      )}
                      onChange={event =>
                        setlocalPeriod({
                          ...localPeriod,
                          range: {
                            ...localPeriod.range,
                            from: moment(event.target.value).valueOf()
                          }
                        })
                      }
                    />
                  </div>
                  <div className="input-group input-group-inline-item-grow right-floating-wheel-picker">
                    <DatePickerMobile
                      floatingWheels
                      label="Date de fin"
                      name="date"
                      calendarFormat={CalendarFormat}
                      value={moment(localPeriod.range.to).format(
                        dateInputFormat
                      )}
                      onChange={event =>
                        setlocalPeriod({
                          ...localPeriod,
                          range: {
                            ...localPeriod.range,
                            to: moment(event.target.value).valueOf()
                          }
                        })
                      }
                    />
                  </div>
                </Grid>
              </Collapse>
            </React.Fragment>
          </ResponsiveModalContent>
        </React.Fragment>
      }
      footer={<ButtonFab onClick={submit}>Enregistrer</ButtonFab>}
    />
  );
}

export default TurnoverPeriodForm;
