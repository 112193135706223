import React from "react";
import styles from "./Content.module.scss";
import classnames from "classnames";

export interface IDesktopModalContentProps {
  children: React.ReactNode;
  withPadding?: boolean;
  classes?: {
    container?: string;
  };
}
export default function DesktopModalContent({
  children,
  withPadding,
  classes
}: IDesktopModalContentProps) {
  return (
    <div
      className={classnames(styles.desktopContainer, classes?.container, {
        [styles.withPadding]: withPadding
      })}
    >
      {children}
    </div>
  );
}
