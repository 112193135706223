import React from "react";
import { CalendarEvent } from ".";

export interface CalendarEventRendererProps<T> {
  event: CalendarEvent<T>;
  color: string;
}

export interface CalendarColumnEventProps<T> {
  color: string;
  event: CalendarEvent<T>;
  eventRenderer?: { (props: CalendarEventRendererProps<T>): JSX.Element };
  getEventPositionInfos(
    event: CalendarEvent<T>
  ): {
    topOffset: number;
    height: number;
  };
  onClickEvent(event: CalendarEvent<T>): void;
}

export default function CalendarColumnEvent<T>(
  props: CalendarColumnEventProps<T>
) {
  const {
    color,
    event,
    eventRenderer: EventRenderer,
    onClickEvent,
    getEventPositionInfos,
  } = props;
  const eventPosition = getEventPositionInfos(event);
  const eventMeta = event?.meta || {};
  return (
    <div
      onClick={(e) => {
        onClickEvent(event);
        e.stopPropagation();
      }}
      className="calendar_event-item"
      style={{
        top: eventPosition.topOffset,
        height: eventPosition.height,
        pointerEvents:
          "availability" in eventMeta || "lastMinute" in eventMeta
            ? "none"
            : "initial",
      }}
    >
      {EventRenderer && <EventRenderer event={event} color={color} />}
    </div>
  );
}
