import React from "react";
import { WHITE } from "../../constants/Style";

interface CloseIconProps extends React.SVGProps<SVGSVGElement> {
  height?: number;
  dark?: boolean;
}

const CloseIcon = (props: CloseIconProps) => {
  const { height: heightProps, color: colorProps, dark, ...rest } = props;
  const height = heightProps || 17;
  const width = height * (18 / 17);
  const color = colorProps || (dark ? "#D8D8D8" : WHITE);
  return (
    <svg width={width} height={height} viewBox="0 0 18 17" {...rest}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={2}
      >
        <path d="M16.395 15.855L8.942 8.402 16.2 1.145" />
        <path d="M1.605 15.855l7.453-7.453L1.8 1.145" />
      </g>
    </svg>
  );
};

export default CloseIcon;
